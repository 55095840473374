import { makeStyles } from '@material-ui/core/styles';
import images from "../../../utility/images";

export default makeStyles((theme) => ({
  empty: {
    alignItems    : 'center',
    background    : `transparent url(${images.EMPTY_TABLE}) no-repeat center center`,
    display       : 'block',
    height        : '100%',
    justifyContent: 'center',
    margin        : '5% 0% 0% 0%',
    minHeight     : '35vh',
    position      : 'relative',
  },
  nodata: {
    display       : 'flex',
    flexDirection : 'column',
    flexWrap      : 'nowrap',
    alignContent  : 'center',
    justifyContent: 'center',
  },
  emptyTitle: {
    color       : theme.palette.text.secondary,
    fontWeight  : '600',
    marginBottom: theme.spacing(10),
    marginTop   : theme.spacing(-6),
    textAlign   : 'center',
  },
}));