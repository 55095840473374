import { Box, Button, Container, Grid, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Form, Formik } from 'formik';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectItems from '../../components/select-items';
import Title from '../../components/title';
import { AuthContext } from '../../context/authContext';
import { UserFederationContext } from '../../context/userFederationContext';
import { FULL_SYNC_USER_FEDERATION, UPDATE_SYNC_USER_FEDERATION } from '../../reducer/userFederationReducer';
import { createUserSyncConfiguration, getUserSyncConfiguration, TestConnection, updateUserSyncConfiguration } from '../../service/userSyncConfigurationAPI';
import { ENTRA_ID_STATUS, LDAP_REGEX, SESAMSEC_PORTAL_USER, SYNC_STATUS, USER_SYNC_FORMAT } from '../../utility/constants';
import { formatMSEntraDomainServicesName, getLocalStorageItem } from '../../utility/helper';
import { GetInitialLocationId, GetInitialLocationObject } from '../../utility/location';
import { entraIdSchema } from '../../validation/schema';
import UserFederationSkeleton from './entra-id-skeleton';
import EntraSyncUsers from './entra-id-sync';
import useStyles from './styles';
import { useHistory } from 'react-router';

const Content = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [ showPassword, setShowPassword ] = useState(false);
  const { disabled, handleSelectedLocation, selectedLocation, showToaster, handlePermissions, initialValues, handleSubmit,federationId, 
    hasMessage, testStatus, alertMessage, setConnectionURL, setUsersDN, setBindDN, setBindCredential, handleTestConnection, connectionStatus,
    disableTestConnection, handleOnBlur, isTesting, hasChanges, hasFederation, connectionMessage, setHasMessage, setTestStatus
   } = props;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  }
  
  const { state : authState } = useContext(AuthContext);
  const administrator         = authState.administrator;
  const showLocation          = administrator.locations?.length !== 1;

  const handleChange = (setter, formik) => {
    return(setter, formik);
  }

  const handleStyle = (formik) => {
    if (formik.errors.connectionURL) {
      return classes.textFieldStyle;
    }

    return connectionStatus === ENTRA_ID_STATUS.SUCCESS ? classes.textFieldSuccessStyle : classes.textFieldStyle;
  };

  const handleErrorMessage = (formik) => {
    if (formik.errors.connectionURL === 'entra-id-page.invalidErrorMessage' || formik.errors.connectionURL === 'entra-id-page.failedErrorMessage') {
      return;
    }

    if ((formik.touched.connectionURL && Boolean(formik.errors.connectionURL) && formik.errors.connectionURL === "entra-id-page.connectionURLHelperText") ||
      (formik.touched.bindDN && Boolean(formik.errors.bindDN) && formik.errors.bindDN === "entra-id-page.bindDNHelperText") ||
      (formik.touched.bindCredential && Boolean(formik.errors.bindCredential) && formik.errors.bindCredential === "entra-id-page.bindCredentialHelperText") ||
      (formik.touched.usersDN && Boolean(formik.errors.usersDN) && formik.errors.usersDN === "entra-id-page.usersDNHelperText") ||
      (formik.touched.location && Boolean(formik.errors.location) && formik.errors.location === "fieldIsRequired")
    ) {
      setHasMessage(false);
      setTestStatus(false);
      return <Box className={classes.alertError}>{t('entra-id-page.requiredFieldErrorMessage')}</Box>
    }

    if (hasMessage) {
      return <Box className={testStatus ? classes.alertSuccess : classes.alertError}>{alertMessage}</Box>
    }
  }

  const handleConnectionMessage = (formik) => {
    if (connectionStatus === ENTRA_ID_STATUS.SUCCESS) {
      return connectionMessage;
    } else {
      if (formik.touched.connectionURL && Boolean(formik.errors.connectionURL)) {
        return t(formik.errors.connectionURL);
      }

      return t('entra-id-page.connectionURLHelperText');
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={hasChanges || federationId === undefined ? entraIdSchema : ''}
      validateOnBlur={true}
      onSubmit={handleSubmit}
    >
      {
        formik => (
          <Form>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  inputProps={{
                    readOnly: disabled,
                  }}
                  disabled={isTesting}
                  id="connectionURL"
                  label={t('entra-id-page.connectionURL')}
                  name="connectionURL"
                  className={handleStyle(formik)}
                  value={formik.values.connectionURL}
                  onBlur={(e) => handleOnBlur(e, formik)}
                  onChange={e => handleChange(setConnectionURL(e.target.value), formik.handleChange(e))}
                  error={formik.touched.connectionURL && Boolean(formik.errors.connectionURL)}
                  helperText={handleConnectionMessage(formik)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  inputProps={{
                    readOnly: disabled,
                  }}
                  disabled={isTesting}
                  id="usersDN"
                  label={t('entra-id-page.usersDN')}
                  name="usersDN"
                  className={classes.textFieldStyle}
                  value={formik.values.usersDN}
                  onChange={e => handleChange(setUsersDN(e.target.value), formik.handleChange(e))}
                  error={formik.touched.usersDN && Boolean(formik.errors.usersDN)}
                  helperText={`${t('entra-id-page.usersDNHelperText')}: ${formatMSEntraDomainServicesName(formik.values.usersDN)}`}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  inputProps={{
                    readOnly: disabled,
                  }}
                  disabled={isTesting}
                  id="bindDN"
                  label={t('entra-id-page.bindDN')}
                  name="bindDN"
                  className={classes.textFieldStyle}
                  value={formik.values.bindDN}
                  onChange={e => handleChange(setBindDN(e.target.value), formik.handleChange(e))}
                  error={formik.touched.bindDN && Boolean(formik.errors.bindDN)}
                  helperText={t('entra-id-page.bindDNHelperText')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowPassword()}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  id="bindCredential"
                  label={t('entra-id-page.bindCredential')}
                  name="bindCredential"
                  type={showPassword ? 'text' : 'password'}
                  className={classes.textFieldStyle}
                  value={formik.values.bindCredential}
                  onChange={e => handleChange(setBindCredential(e.target.value), formik.handleChange(e))}
                  error={formik.touched.bindCredential && Boolean(formik.errors.bindCredential)}
                  helperText={t('entra-id-page.bindCredentialHelperText')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  inputProps={{
                    readOnly: disabled,
                  }}
                  id="customUserLDAP"
                  label={t('entra-id-page.customUserLDAP')}
                  name="customUserLDAP"
                  value={SESAMSEC_PORTAL_USER}
                  disabled={true}
                  className={classes.textFieldStyle}
                  helperText={t('entra-id-page.customUserLDAPHelperText')}
                />
              </Grid>
              {
                showLocation ?
                <Grid item xs={9}>
                  <SelectItems
                    helperText={t('entra-id-page.locationHelperText')}
                    isValid={formik.touched.location && Boolean(formik.errors.location)}
                    name="Locations"
                    onChange={handleSelectedLocation}
                    disabled={disabled}
                    selectedItems={selectedLocation}
                    showToaster={showToaster}
                    single={true}
                    required={true}
                    handlePermissions={handlePermissions}
                  />
                </Grid> : <></>
              }
            </Grid>
            <Grid container className={classes.noteStyle}>
              <Grid item xs={12}>
                <Button
                  onClick={(event) => handleTestConnection(event, formik)}
                  variant="outlined"
                  color="primary"
                  disabled={disableTestConnection}
                  className={`${classes.buttonStyle} ${classes.buttonBorderStyle}`}
                  >
                    {isTesting ? t('entra-id-page.testing') : t('entra-id-page.testAuthentication')}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.buttonStyle}
                >
                  {t('entra-id-page.syncUsers')}
                </Button>
              </Grid>
            </Grid>
            {
              handleErrorMessage(formik)
            }
            {
              !hasFederation ? <Typography className={classes.noteStyle}>{<strong>{t('entra-id-page.note')}</strong>} : {t('entra-id-page.noteContent')}</Typography> : <></>
            }
          </Form>
        )
      }
    </Formik>
  )
}

const SyncingUsers = (props) => {
  const classes = useStyles();
  const { t }   = useTranslation();
  const history = useHistory();

  const { showToaster,  handlePermissions } = props;
  
  const initialLocationId     = GetInitialLocationId();
  const initialLocationObject = GetInitialLocationObject();
  
  const { state: federationState, dispatch: federationDispatch } = useContext(UserFederationContext);

  const [location, setLocation]                    = useState(federationState.federationLocation);
  const [selectedLocation, setSelectedLocation]    = useState(initialLocationId);
  const [locationObject, setLocationObject]        = useState(initialLocationObject);
  const [connectionURL, setConnectionURL]          = useState(federationState.connectionURL);
  const [usersDN, setUsersDN]                      = useState(federationState.usersDN);
  const [bindDN, setBindDN]                        = useState(federationState.bindDN);
  const [bindCredential, setBindCredential]        = useState('');
  const [hasMessage, setHasMessage]                = useState(false);
  const [isTesting, setItTesting]                  = useState(false);
  const [isSyncing, setIsSyncing]                  = useState(false);
  const [hasFederation, setHasFederation]          = useState(false);
  const [testStatus, setTestStatus]                = useState(false);
  const [alertMessage, setAlertMessage]            = useState('');
  const [connectionStatus, setConnectionStatus]    = useState('');
  const [connectionMessage, setConnectionMessage]  = useState('');
  const [syncStatus, setSyncStatus]                = useState(SYNC_STATUS.IN_PROGRESS);
  const [hasChanges, setHasChanges]                = useState(false);
  const [isLoading, setIsLoading]                  = useState(false);
  
  const [ disableTestConnection, setDisableTestConnection ] = useState(true);

  const initialValues = useMemo(() => {
    return {
      connectionURL   : connectionURL,
      usersDN         : usersDN,
      bindDN          : bindDN,
      bindCredential  : bindCredential,
      location        : selectedLocation || selectedLocation === undefined ? selectedLocation : location
    }
  }, [connectionURL, usersDN, bindDN, bindCredential, selectedLocation, location]);

  const handleSelectedLocation = (value) => {
    setSelectedLocation(value[0]?.locationId);
    setLocationObject(value);
  }

  const handleChanges = useCallback(() => {
    const savedConnectionURL = getLocalStorageItem('connectionURL');
    const savedUsersDN = getLocalStorageItem('usersDN');
    const savedBindDN = getLocalStorageItem('bindDN');

    if (connectionURL !== savedConnectionURL ||
        usersDN !== savedUsersDN ||
        bindDN !== savedBindDN
        ) {
        
      setHasChanges(true);
      return;
    }

    setHasChanges(false);
  }, [bindDN, connectionURL, usersDN]);
  
  const getMsEntraID = useCallback(async () => {
    setIsLoading(true);
    
    const federationData = await getUserSyncConfiguration();
    
    if (federationData?.data) {
      const { ldapMappers, userFederation, userCount } = federationData.data;
      
      const locationData = ldapMappers.find(item => item.name === 'location');
      setLocation(locationData.config["attribute.value"][0]);
      
      const { id, config } = userFederation;
      const { usersDn, bindDn, connectionUrl, lastSync, changedSyncPeriod } = config;

      const lastUpdate = moment.unix(lastSync).format(USER_SYNC_FORMAT);
      const nextSync = moment.unix(lastSync).add(changedSyncPeriod, 'seconds').format(USER_SYNC_FORMAT);
      
      const payload = {
        usersDN            : usersDn[0],
        connectionURL      : connectionUrl[0],
        bindDN             : bindDn[0],
        federationLocation : locationData.config["attribute.value"][0],
        lastSync           : lastUpdate,
        nextSync           : nextSync,
        userCount          : userCount,
        federationId       : id,
      }

      federationDispatch({ type: FULL_SYNC_USER_FEDERATION, payload: payload});

      setUsersDN(usersDn[0]);
      setBindDN(bindDn[0]);
      setConnectionURL(connectionUrl[0]);
      setHasFederation(true);
      setSyncStatus(SYNC_STATUS.SUCCESS);
      setIsLoading(false);
      return;
    }
    
    setIsLoading(false);
    setHasFederation(false);
  }, [federationDispatch]);

  useEffect(() => {
    setBindCredential('');
    getMsEntraID();
  }, [getMsEntraID])

  useEffect(() => {
    if (federationState.federationId !== undefined) {
      handleChanges();
    }
  }, [handleChanges, federationState])

  const handleOnBlur =  async (e, formik) => {
    const { setErrors } = formik;
    const { value } = e.target;
    formik.handleBlur(e);

    if (value.trim === '') {
      setConnectionStatus(ENTRA_ID_STATUS.ERROR);
      setErrors({
        connectionURL: 'entra-id-page.connectionURLHelperText'
      })
      return
    }
    
    const isValidConnectionURL = LDAP_REGEX.test(value);
    if (!isValidConnectionURL) {
      setConnectionStatus(ENTRA_ID_STATUS.ERROR);
      return
    }

    const testParams = {
      action: "testConnection",
      connectionUrl: value,
      authType: "simple",
      bindDn: "",
      bindCredential: "",
      useTruststoreSpi: "ldapsOnly",
      connectionTimeout: "",
      startTls: ""
    }

    const responseCode = await TestConnection(testParams);

    if (responseCode === 400) {
      setConnectionStatus(ENTRA_ID_STATUS.ERROR);
      setErrors({
        connectionURL: 'entra-id-page.failedErrorMessage'
      })
      return
    }

    setConnectionMessage(t('entra-id-page.successMessage'));
    setConnectionStatus(ENTRA_ID_STATUS.SUCCESS);
  }

  useEffect(() => {
    if (connectionURL && bindDN && bindCredential && connectionStatus === ENTRA_ID_STATUS.SUCCESS) {
      setDisableTestConnection(false);
      return;
    }

    setDisableTestConnection(true);
  }, [connectionURL, usersDN, bindDN, bindCredential, connectionStatus]);

  const handleTestConnection = async (event, formik) => {
    setItTesting(true);
    const { values } = formik;
    const { connectionURL, bindDN, bindCredential } = values;

    const parameters = {
      action: "testAuthentication",
      connectionUrl: connectionURL,
      authType: "simple",
      bindDn: bindDN,
      bindCredential: bindCredential,
      useTruststoreSpi: "ldapsOnly",
      connectionTimeout: "",
      startTls: "",
    }
    
    const responseCode = await TestConnection(parameters);

    setHasMessage(true);
    setItTesting(false);

    if (responseCode === 400) {
      setTestStatus(false);
      setAlertMessage(t('entra-id-page.bindErrorMessage'));
      return;
    }

    setTestStatus(true);
    setAlertMessage(t('entra-id-page.connectionSuccessMessage'));
  }

  const handleSubmit = async () => {
    let parameters = {};
    if (federationState.federationId !== undefined) {
      if (hasChanges) {
        parameters = {
          usersDn      : usersDN,
          connectionUrl: connectionURL,
          bindDn       : bindDN,
        }
      } else {
        setHasFederation(true);
        return;
      }
    }

    parameters = {
      locationId    : selectedLocation,
      usersDn       : formatMSEntraDomainServicesName(usersDN),
      connectionUrl : connectionURL,
      bindDn        : bindDN,
      bindCredential: bindCredential
    }

    setIsSyncing(true);
    setHasFederation(true);
    setHasMessage(false);

    try {
      let type = UPDATE_SYNC_USER_FEDERATION;
      let payload = {
        usersDN       : usersDN,
        connectionURL : connectionURL,
        bindDN        : bindDN
      }

      if (federationState.federationId !== undefined) {
        await updateUserSyncConfiguration(parameters);
      } else {
        const federationData = await createUserSyncConfiguration(parameters);

        const { userFederation, userCount } = federationData.data;

        const { id, config } = userFederation;
        const { lastSync, changedSyncPeriod } = config;

        const lastUpdate = moment.unix(lastSync).format(USER_SYNC_FORMAT);
        const nextSync = moment.unix(lastSync).add(changedSyncPeriod, 'seconds').format(USER_SYNC_FORMAT);

        type = FULL_SYNC_USER_FEDERATION;
        payload = {
          ...payload,
          federationLocation : selectedLocation,
          lastSync           : lastUpdate,
          nextSync           : nextSync,
          userCount          : userCount,
          federationId       : id,
        };
      }

      federationDispatch({
        type    : type,
        payload : payload
      });

      setSyncStatus(SYNC_STATUS.SUCCESS);
      setHasFederation(true);
    } catch (e) {
      setSyncStatus(SYNC_STATUS.ERROR);
    }
    
    setIsSyncing(false);
  }

  const handleNavigateToGuide = () => {
    history.push('/syncing-users/user-guide')
  }

  const handleView = () => {
    if (hasFederation) {
      return (
        <EntraSyncUsers
          connectionURL={connectionURL}
          isSyncing={isSyncing}
          setHasFederation={setHasFederation}
          syncStatus={syncStatus}
          setSyncStatus={setSyncStatus}
          setIsSyncing={setIsSyncing}
        />
      )
    } else {
      return (
        <Content
          initialValues={initialValues}
          disabled={isTesting}
          handleSelectedLocation={handleSelectedLocation}
          selectedLocation={locationObject}
          handlePermissions={handlePermissions}
          showToaster={showToaster}
          setConnectionURL={setConnectionURL}
          setUsersDN={setUsersDN}
          setBindDN={setBindDN}
          setBindCredential={setBindCredential}
          handleSubmit={handleSubmit}
          connectionStatus={connectionStatus}
          connectionMessage={connectionMessage}
          handleTestConnection={handleTestConnection}
          disableTestConnection={disableTestConnection}
          handleOnBlur={handleOnBlur}
          isTesting={isTesting}
          hasChanges={hasChanges}
          federationId={federationState.federationId}
          hasFederation={hasFederation}
          hasMessage={hasMessage}
          setHasMessage={setHasMessage}
          testStatus={testStatus}
          setTestStatus={setTestStatus}
          alertMessage={alertMessage}
          setConnectionMessage={setConnectionMessage}
        />
      )
    }
          
  }

  return (
    <Container className={classes.container}>
      <Title title={t('entra-id-page.syncingUsers')} />
      <Typography className={classes.reminderStyle}>
        {t('entra-id-page.reminder')}
        <span className={classes.guideButton} onClick={() => handleNavigateToGuide()}>
          {' '}{t('entraIdUserGuide.title')}
        </span>
      </Typography>

      {
        isLoading ? <UserFederationSkeleton/> : handleView()
      }
    </Container>
  )
};

export default SyncingUsers;