import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  chip: {
    borderRadius  : theme.spacing(0.4),
    height        : 'inherit',
    justifyContent: 'start',
    maxWidth      : 185,
    paddingBottom : theme.spacing(0.4),
    paddingLeft   : theme.spacing(1),
    paddingRight  : theme.spacing(1),
    paddingTop    : theme.spacing(0.4),
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
    },
    
    '& .MuiTypography-root': {
      lineHeight: 1,
    },
    '& .MuiChip-label': {
      paddingRight: 0,
      width       : '100%',
    },
    '& .MuiChip-deleteIcon': {
      marginLeft  : theme.spacing(0.5),
      marginRight : 0
    },
    '& .MuiChip-icon': {
      marginLeft  : 0,
      marginRight : theme.spacing(0.5),
    },
    '& .MuiChip-labelSmall': {
      paddingLeft: 0,
    },
  },
  UnsyncChip: {
    paddingBottom : theme.spacing(-0.8),
    paddingLeft   : theme.spacing(-2),
    paddingRight  : theme.spacing(-2),
    paddingTop    : theme.spacing(-0.8),
  },
  fullWidth: {
    maxWidth: '100%',
    width   : '100%'
  },
  light: {
    backgroundColor: theme.palette.primary.light,
    color          : theme.palette.primary.main,

    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.light,
    }
  },
  online: {
    backgroundColor: theme.palette.success.main,

    '&:hover, &:focus': {
      backgroundColor: theme.palette.success.main,
    }
  },
  offline: {
    backgroundColor: theme.palette.text.secondary,

    '&:hover, &:focus': {
      backgroundColor: theme.palette.text.secondary,
    }
  },
  neutral: {
    backgroundColor: theme.palette.secondary.light,
    color          : theme.palette.text.primary,

    '&:hover, &:focus': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  label: {
    alignContent  : 'center',
    alignItems    : 'center',
    display       : 'flex',
    justifyContent: 'space-between',
    minHeight     : theme.spacing(3),
    width         : '100%',
    '& .MuiBox-root': {
      width: 'inherit'
    }
  },
  outline: {
    backgroundColor : 'transparent',
    color           : colors.SECONDARY,
    fontWeight      : 1,
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
    }
  },
  plain: {
    backgroundColor: colors.WHITE,
    color          : colors.CHIP_PLAIN,
    '&:hover, &:focus': {
      backgroundColor: colors.WHITE,
    },
    padding        : theme.spacing(1.25)
  },
  readerChip: {
    marginBottom  : theme.spacing(1),
    borderRadius  : theme.spacing(1),
    paddingBottom : theme.spacing(1),
    paddingTop    : theme.spacing(1)
  },
  accessPointChip: {
    color: colors.CHIP_PLAIN,
    '& .MuiChip-icon': {
      color    : colors.CHIP_ICON_PLAIN,
      fontSize : theme.spacing(3)
    }
  },
}))