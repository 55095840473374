import {
  Container
} from "@material-ui/core";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import DeleteModal from "../../components/delete-modal";
import Table, { createColumn } from '../../components/table';
import Title from "../../components/title";
import { LocationContext } from '../../context/locationContext';
import api from "../../service/api";
import { multipleRequest, request } from "../../service/requests";
import { detachAssocAndDeleteUser, exportUsers, getAllUsersPage, getCredentialsByUserId } from '../../service/usersApi';
import {
  ACTION_DELETE,
  ALL,
  API_REQUEST_ERROR_MESSAGE,
  ASCENDING,
  DELETE,
  MULTIPLE,
  SINGLE,
  UPDATE,
  USERS,
  USERS_MODULE,
  USER_MODULE
} from "../../utility/constants";
import { formatAssociated, parseIntegerParam, updateURLParams } from "../../utility/helper";
import useStyles from "./styles";
import CsvModal from './users-csv-modal';

export const columns = [
  createColumn('username', 'personalNumber', true, 'numberic', false, true),
  createColumn("id", "User Id", false, "string", true),
  createColumn('lastName', 'name', true, 'string'),
  createColumn('validFrom', 'validFrom', true, 'date'),
  createColumn('validUntil', 'validUntil', true, 'date'),
  createColumn("location", 'location', true, "string"),
  createColumn('roles', 'roles', false, 'component')
];

const Users = (props) => {
  const { t } = useTranslation();
  const { showToaster, showLoading, handlePermissions } = props;
  const classes  = useStyles();
  const history  = useHistory();

  const { search, pathname } = history.location;

  const params     = new URLSearchParams(search);
  const query      = params.get('keyword') ? params.get('keyword') : '';
  const pageSize   = params.get('size') ? parseIntegerParam(params.get('size'), 10) : 10;
  const pageNumber = params.get('page') ? parseIntegerParam(params.get('page'), 1) : 1;
  const pageType   = params.get('users') ? params.get('users') : ALL.toLowerCase();
  const totalSize  = pageSize > 100 ? 100 : pageSize;

  const { state }                                                           = useContext(LocationContext);
  const { selectedLocationIds }                                             = state;
  const [forDelete, setForDelete]                                           = useState([]);
  const [forDeleteName, setForDeleteName]                                   = useState([]);
  const [forDeleteCred, setForDeleteCred]                                   = useState([]);
  const [isLoading, setIsLoading]                                           = useState(false);
  const [keyword, setKeyword]                                               = useState(query);
  const [order, setOrder]                                                   = useState(ASCENDING);
  const [orderBy, setOrderBy]                                               = useState(columns[0].id);
  const [page, setPage]                                                     = useState(pageNumber === 0 ? 1 : pageNumber);
  const [users, setUsers]                                                   = useState([]);
  const [remove, setRemove]                                                 = useState(false);
  const [rowsPerPage, setRowsPerPage]                                       = useState(totalSize);
  const [showModal, setShowModal]                                           = useState(false);
  const [totalUsers, setTotalUsers]                                         = useState(0);
  const [type, setType]                                                     = useState(SINGLE);
  const [assocValues, setAssocValues]                                       = useState('');
  const [showCSVModal, setShowCSVModal]                                     = useState(false);
  const [title, setTitle]                                                   = useState('');
  const [listType, setListType]                                             = useState(pageType);
  const [isFetchingAssociatedCredential, setIsFetchingAssociatedCredential] = useState(false);
  const [exportData, setExportData]                                         = useState('');
  const csvInstance                                                         = useRef(null);

  const urlParams = useMemo(() => ({
    keywordParam :  keyword ? `&keyword=${keyword}` : '',
    entityParam  : `users=${listType}`,
    pageParam    : `&page=${page}`,
    sizeParam    : `&size=${rowsPerPage}`,
    history      : history,
    pathname     : pathname
  }), [keyword, listType, page, rowsPerPage, history, pathname]);

  useEffect(() => {
    if (exportData && csvInstance && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setExportData();
      });
    }
  }, [exportData]);

  const getParams = useCallback(() => {
    return {
      search     : keyword,
      sort       : `${orderBy},${order}`,
      size       : rowsPerPage,
      page       : page,
    }
  }, [keyword, order, orderBy, page, rowsPerPage]);

  const getUsers = useCallback(async () => {
    remove ? setIsLoading(false) : setIsLoading(true);

    const params = getParams();

    try {
      const response = await getAllUsersPage(params);
      const { number, size, totalElements, totalPages } = response.page;
      
      const totalNumber = number > totalPages ? totalPages : number;

      if (response.users.length || number === 0) {
        setPage(totalNumber + 1);
        setUsers(response.users);
      } else {
        setPage(totalNumber);
      }

      setRowsPerPage(size);
      setTotalUsers(totalElements);
    } catch (error) {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setIsLoading(false);
    }
  }, [getParams, remove, showToaster, t]);

  const getData = useCallback(async () => {
    if (listType === ALL.toLowerCase()) {
      setTitle(USERS);
      await getUsers();
    } else {
      setListType(ALL.toLowerCase());
    }
  }, [listType, getUsers]);

  useEffect(() => {
    let delayDebounce;
    
    if (keyword) {
      delayDebounce = setTimeout(() => {
        updateURLParams(urlParams);
        getData();
      }, 1000);
    } else {
      updateURLParams(urlParams);
      getData();
    }

    return () => {
      delayDebounce && clearTimeout(delayDebounce);
    }
  }, [page, rowsPerPage, order, orderBy, keyword, listType, selectedLocationIds, urlParams, getData]);

  useEffect(() => {
    if (remove) {
      getData();
    }
    setRemove(false);
  }, [remove, getData]);

  const handleSearch = (value) => {
    setPage(1);
    setKeyword(value);
  }

  const handleClearSearch = () => {
    setPage(1);
    setKeyword('');
  }

  const handleSort = (newOrderBy, newOrder) => {
    setPage(1);
    setOrderBy(newOrderBy);
    setOrder(newOrder);
  }

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setPage(1);
    setRowsPerPage(newRowsPerPage);
  }

  const handleChangePage = (newPage) => {
    if (isLoading) {
      return;
    }

    setPage(newPage + 1);
  }

  const handleView = (value) => {
    const { userId } = value;

    history.push(`/users/view/${userId}`);
  }

  const handleCreate = () => {
    history.push("/users/create");
  }

  const handleUpdate = (value) => {
    const { userId } = value;

    history.push(`/users/update/${userId}`);
  }

  const getAllAssociatedCred = async (values) => {
    setIsFetchingAssociatedCredential(true);

    try {
      const responses = await getCredentialsByUserId(values);
      
      const credentials = responses.flatMap(response => response.data._embedded.credentials.map(credential => credential.credentialId));
      setForDeleteCred(credentials);
      
      const credentialsDisplay = responses.map(response => {
        return {
          credentials: response.data._embedded.credentials.map(credential => {return {credentialNumber: credential.credentialNumber}})
        }
      });
      
      return credentialsDisplay;
    } catch (error) {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setIsFetchingAssociatedCredential(false);
    }
  }

  const deleteCredential = async (values, action) => {
    try {
      await multipleRequest(
        forDeleteCred.map(id =>
          request({
            url     : `${api.CREDENTIALS}/${id}`,
            method  : DELETE,
          })
        )
      );
    } catch (error) {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      action(values);
    }
  }

  const handleDelete = async (value) => {
    const userCredential = await getAllAssociatedCred([{userId: value.userId}]);
    
    if ((userCredential[0].credentials).length === 0) {
      handleOpenDeleteModal(value, value.name, SINGLE);
    } else {
      setAssocValues(`${formatAssociated((userCredential[0].credentials).map(credential => {return (' ' + credential.credentialNumber)}), t)}`);
      handleOpenDeleteModal(value, value.name, 'singleWithAssociated');
    }
  }

  const singleDelete = async (value) => {
    const { userId } = value;
    
    try {
      await detachAssocAndDeleteUser(userId);
      
      showToaster(t(`success`), `${value.name} ${t('hasBeenDeleted')}`, 'success');
      setRemove(true);
    } catch (error) {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
      setRemove(true);
    } finally {
      setRemove(true);
    }
  }

  const handleMultipleDelete = async (values) => {
    let userCredential;
    if (values.length === 1) {
      userCredential = await getAllAssociatedCred([{userId: values[0].userId}]);
    } else {
      userCredential = await getAllAssociatedCred(values);
    }

    const hasAssoc = userCredential.some((user) => user.credentials.length > 0);
    if (hasAssoc) {
      if (values.length === 1) {
        setAssocValues(formatAssociated((userCredential.map(user => {
          return (user.credentials.map(credential => ` ${credential.credentialNumber}`));
        })).flat(), t));
        handleOpenDeleteModal(values[0], values[0].name, 'singleWithAssociated');
      } else {
        setAssocValues(formatAssociated((userCredential.map(user => {
          return (user.credentials.map(credential => ` ${credential.credentialNumber}`));
        })).flat(), t));
        handleOpenDeleteModal(values, '', 'multipleWithAssociated');
      }
    }
    else if (values.length === 1) {
      handleOpenDeleteModal(values[0], values[0].name, SINGLE);
    }
    else {
      handleOpenDeleteModal(values, '', MULTIPLE);
    }
  }

  const multipleDelete = async (values) => {
    const ids       = values.map(value => value.userId);
    const names     = values.map(value => value.name);
    const lastName  = names.pop();

    try {
      await multipleRequest(
        ids.map(async (id) =>
          await detachAssocAndDeleteUser(id)
        )
      );

      showToaster(t('success'), `${names.join('; ')}, ${t('and')} ${lastName} ${t('hasBeenDeleted')}`, 'success');
      setRemove(true);
    } catch (error) {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
      setRemove(true);
    }
  }

  const multipleOptions = async (values, option) => {
    if (option === UPDATE) {
      await multipleDelete(values);
    } else {
      await deleteCredential(values, multipleDelete);
    }
  }

  const singleOptions = async (value, option) => {
    if (option === UPDATE) {
      await singleDelete(value);
    } else {
      await deleteCredential(value, singleDelete);
    }
  }

  const removeAssign = async (values) => {
    (type.includes(MULTIPLE) ? await multipleOptions(values, UPDATE) : await singleOptions(values, UPDATE));
  }

  const uniqueAction = async (values) => {
    (type.includes(MULTIPLE) ? await multipleOptions(values, ACTION_DELETE) : await singleOptions(values, ACTION_DELETE));
  }

  const handleOpenDeleteModal = (value, name, paramType) => {
    setForDelete(value);
    setForDeleteName(name);
    setType(paramType);
    setShowModal(true);
  }

  const handleCloseDeleteModal = () => {
    setShowModal(false);
  }

  const onOpenModal = () => {
    setShowCSVModal(true);
  }

  const handleType = () => {
    setListType(ALL.toLowerCase());
  }

  const handleExport = async () => {
    const data = await exportUsers();
    setExportData(data);
  }

  const csvExportHeader = [
    {label: '#personalnumber', key: 'personalNumber'},
    {label: 'firstname', key: 'firstName'},
    {label: 'lastname', key: 'lastName'},
    {label: 'credential', key: 'credential'},
    {label: 'validfrom', key: 'validFrom'},
    {label: 'validuntil', key: 'validUntil'},
    {label: 'role-name', key: 'roleName'},
    {label: 'location', key: 'location'}
  ]

  const getFilename = () => {
    return (`${t('exportedUsers')}.csv`);
  }
  
  return (
    <Container maxWidth="xl" className={classes.container}>
      <Title title={t(title)} listType={listType}/>
      { exportData ? <CSVLink data={exportData} ref={csvInstance} headers={csvExportHeader} filename={getFilename()} /> : undefined }
      <CsvModal
        showToaster={showToaster}
        showModal={showCSVModal}
        setShowModal={setShowCSVModal}
        setRemove={setRemove}
        showLoading={showLoading}
      />
      <DeleteModal
        assocValues={assocValues}
        forDeleteName={forDeleteName}
        initialValues={forDelete}
        module={USERS_MODULE}
        multipleDelete={removeAssign}
        onClose={handleCloseDeleteModal}
        open={showModal}
        showToaster={showToaster}
        singleDelete={removeAssign}
        type={type}
        uniqueAction={uniqueAction}
        isLoading={isFetchingAssociatedCredential}
      />
      <Table
        columns={columns}
        data={users}
        isLoading={isLoading}
        keyword={keyword}
        label={USER_MODULE}
        module={USERS_MODULE}
        onChangePage={handleChangePage}
        onClearSearch={handleClearSearch}
        onCreate={handleCreate}
        onDelete={handleDelete}
        onMultipleDelete={handleMultipleDelete}
        onRowsPerPageChange={handleRowsPerPageChange}
        onSearch={handleSearch}
        onSort={handleSort}
        onUpdate={handleUpdate}
        onView={handleView}
        orderBy={orderBy}
        order={order}
        page={page}
        rowsPerPage={rowsPerPage}
        totalItems={totalUsers}
        viewKey={'personalNumber'}
        onOpenModal={onOpenModal}
        listType={listType}
        onViewAll={handleType}
        handleExport={handleExport}
        handlePermissions={handlePermissions}
      />
    </Container>
  );
}

export default Users;