import { getLocalStorageItem } from "../utility/helper";

export const PARTIAL_SYNC_USER_FEDERATION   = 'PARTIAL_SYNC_USER_FEDERATION';
export const FULL_SYNC_USER_FEDERATION      = 'FULL_SYNC_USER_FEDERATION';
export const UPDATE_SYNC_USER_FEDERATION    = 'UPDATE_SYNC_USER_FEDERATION';

export const initialState = {
  usersDN            : getLocalStorageItem('usersDN'),
  connectionURL      : getLocalStorageItem('connectionURL'),
  bindDN             : getLocalStorageItem('bindDN'),
  federationLocation : getLocalStorageItem('federationLocation'),
  lastSync           : getLocalStorageItem('lastSync'),
  nextSync           : getLocalStorageItem('nextSync'),
  userCount          : getLocalStorageItem('userCount'),
  federationId       : getLocalStorageItem('federationId'),
}

export const userFederationReducer = (state, action) => {
  switch (action.type) {
    case PARTIAL_SYNC_USER_FEDERATION:
      return {
        ...state,
        lastSync: action.payload.lastSync,
        nextSync: action.payload.nextSync,
        userCount: action.payload.userCount,
        federationId: action.payload.federationId
      }
    case FULL_SYNC_USER_FEDERATION:
      return {
        ...state,
        usersDN: action.payload.usersDN,
        bindDN: action.payload.bindDN,
        connectionURL: action.payload.connectionURL,
        federationLocation: action.payload.federationLocation,
        lastSync: action.payload.lastSync,
        nextSync: action.payload.nextSync,
        userCount: action.payload.userCount,
        federationId: action.payload.federationId
      }
    case UPDATE_SYNC_USER_FEDERATION:
      return {
        ...state,
        usersDN: action.payload.usersDN,
        bindDN: action.payload.bindDN,
        connectionURL: action.payload.connectionURL
      }
    default:
      return state
  }
}