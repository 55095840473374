import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../theme/colors';

export default makeStyles((theme) => ({
  tableCell: {
    border            : '1px solid #727272 !important',
    height            : '31px',
    '&:hover': {
      backgroundColor : colors.PRIMARY_LIGHT
    }
  },
  timeScaleLabel:{
    '& .MuiTableCell-body':{
      borderBottom       : 'none',
      height             : '31px',
      paddingTop         : '4px',
      paddingBottom      : '4px',
    }
  },
  headerScaleLabel: {
    '& .MuiTableCell-root':{
      border        : '1px solid #727272',
      borderBottom  : 'none',
      padding       : theme.spacing(1),
      paddingBottom : theme.spacing(2),
      paddingTop    : theme.spacing(2),
    },
    '& .MuiToggleButtonGroup-root': {
      width         : '100%'
    },
    '& .MuiToggleButton-root': {
      border        : 'none',
      borderRadius  : '0',
      color         : colors.SECONDARY,
      fontWeight    : 'bold',
      width         : '100%',
      '&:hover': {
        width       :'100%'
      },
      '&:focus': {
        colors      : colors.PRIMARY,
      },
    },
    '& .Mui-disabled':{
      border        : 'none !important',
      borderRadius  : '0',
      color         : colors.SECONDARY + '!important',
    },
    '& .MuiToggleButton-root.Mui-selected': {
      backgroundColor : colors.PRIMARY_LIGHT,
      color           : colors.PRIMARY + '!important',
      },
  },
  appointmentContainer: {
    alignItems        : 'end',
    backgroundColor   : colors.WHITE,
    borderRadius      : '0',
    display           : 'flex',
    flexDirection     : 'column',
    opacity           : '0.8',
  },

  appointment: {
    backgroundColor   : colors.TIME_TABLE,
    display           : 'flex',
    height            : '100%',
    justifyContent    : 'center',
    width             : '100%',
  },

  minHeight: {
    paddingTop        : theme.spacing(0),
    alignItems        : 'center',
  },

  normal: {
    paddingTop        : theme.spacing(2),
    alignItems        : 'flex-start',
  },

  disable: {
    '&:hover':{
      backgroundColor :  colors.WHITE
    },
  },

  hover : {
    '&:hover':{
      backgroundColor :  '#2fb56a'
    },
  },

  appointmentButton: {
    position          : 'absolute',
    '& .MuiIconButton-sizeSmall': {
      padding         : theme.spacing(0),
      '&:active': {
        backgroundColor: 'transparent'
      },
    }
  },
  multiDuplicate: {
    alignItems        : 'center',
    display           : 'flex',
    justifyContent    : 'space-between',
    padding           : theme.spacing(1),
    '& .MuiPopover-root': {
      alignItems      : 'center',
      display         : 'flex',
    },
    '& .MuiTextField-root': {
      margin          : '0',
      width           : '50px',
    },
    '& .MuiOutlinedInput-inputMarginDense': {
      padding         : theme.spacing(1)
    },
    '& .MuiButton-root': {
      backgroundColor : colors.PRIMARY_LIGHT,
      margin          : '0',
      marginLeft      : theme.spacing(1),
    }
  },

}));
