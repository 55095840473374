import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  container: {
    paddingTop   : theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(1),
    
    '& .MuiTextField-root': {
      marginTop: theme.spacing(0)
    },
    '& input#roleName.Mui-disabled':{
      color: "black"
    },
    '& textarea#roleDescription.Mui-disabled':{
      color: "black"
    }
  },
  editIcon: {
    backgroundColor: theme.palette.primary.main,
    borderRadius   : theme.spacing(0.5),
    color          : theme.palette.common.white,
    display        : 'inline',
  },
  form: {
    padding     : theme.spacing(1),
    paddingLeft : theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  containerProfiles:{
    border      : 'thin',
    borderStyle : 'solid',
    borderRadius: '4px',
    borderColor : '#c4c4c4',
    height      : '300px',
    marginTop   : theme.spacing(-1),
  },
  dropdown: {
    zIndex: 10,
  },
  fieldsetLegend:{
    color: '#767779'
  },
  hrDivider:  {
    border   : 0,
    borderTop: '1px dotted #767779',
    float    : 'right',
    width    : '100%',
  },
  containerAddProfiles:{
    width       : '100%',
    border      : 'thin',
    borderStyle : 'solid',
    borderRadius: '4px',
    borderColor : '#c4c4c4',
  },
  action: {
    backgroundColor: theme.palette.grey[100],
    padding        : theme.spacing(2),
    textAlign      : 'right',

    '& .MuiButton-root:first-of-type': {
      marginRight: theme.spacing(2)
    }
  },
  characterLimit: {
    color         : colors.SECONDARY,
    float         : 'right',
    fontSize      : '12px',
    paddingBottom : '5px',
    position      : 'relative',
    top           : '-0.3em'
  },
  characterLimitError: {
    color         : colors.SECONDARY,
    float         : 'right',
    fontSize      : '12px',
    paddingBottom : '5px',
    position      : 'relative',
    top           : '-2.205em'
  },
  grid: {
    paddingBottom: '0 !important'
  }
}));