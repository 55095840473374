import React from 'react'
import useStyles from "./styles";
import { Box } from '@material-ui/core';
import { formatDate } from '../../utility/helper';

const TimeColumn = (props) => {
  const { value } = props;
  const classes = useStyles();

  const formattedDate = formatDate(value);
  // example: 17:20:39, Jul 05
  const timeDate = formattedDate.split(', ');
  if (timeDate.length > 1) {
    return (
      <Box>
        <Box><span className={classes.time}>{timeDate[0]}</span></Box>
        <Box><span className={classes.date}>{timeDate[1]}</span></Box>
      </Box>
    )
  }

  return <span className={classes.time}>{formattedDate}</span>
}

export default TimeColumn