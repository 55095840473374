import { Box } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import React, { forwardRef, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WizardContext } from '../../../../context/wizardContext';
import { WizardCredentialContext } from '../../../../context/wizardCredentialContext';
import { SET_NEXT_PAYLOAD } from '../../../../reducer/wizardReducer';
import useStyles from './styles';

const CreateOptions = (page, content) => {
  return {
    page   : page,
    content: content
  }
}

const credentialOptions = [
  CreateOptions(3, 'assignExistingCredTitle'),
  CreateOptions(4, 'assignNewCredentialTitle'),
]

const personCredentialOptions = [
  CreateOptions(2, 'assignExistingCredTitle'),
  CreateOptions(3, 'assignNewCredentialTitle'),
]

const CredentialOption = (props) => {
  const { handleAlignment, alignment, options } = props;

  const wizardContext   = useContext(WizardContext);
  const { currentPage } = wizardContext.state;
  const isCredentialOption = currentPage === 1;

  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    const payload = {
      prevPage      : isCredentialOption ? 0 : 1,
      checkPage     : isCredentialOption ? 2 : 3,
      isNextHidden  : true
    }
    wizardContext.dispatch({ type: SET_NEXT_PAYLOAD, payload: payload });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    options.map((option, index) => {
      return (
        <ToggleButtonGroup
          key={`toggle-button-${index}`}
          value={alignment}
          exclusive
          onChange={handleAlignment}
          aria-label="text alignment"
        >
          <ToggleButton id={`${option.content}Button`} value={option.page} className={classes.optionButton} color="primary" variant="outlined">
            {t(option.content)}
          </ToggleButton>
        </ToggleButtonGroup>
      )
    })
  )
}

const ContentOptions = forwardRef((props, ref) => {
  const wizardContext     = useContext(WizardContext);
  const credentialContext = useContext(WizardCredentialContext);

  const classes = useStyles();
  const [alignment, setAlignment] = useState('');

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment) {
      setAlignment(newAlignment);
      const payload = {
        isNextShow    : true,
        currentPage   : newAlignment,
        isNextHidden  : false,
        prevPage      : credentialContext ? 2 : 1
      }
      wizardContext.dispatch({ type: SET_NEXT_PAYLOAD, payload: payload });
    }
  };

  return (
    <Box className={classes.optionContainer}>
      <CredentialOption
        handleAlignment={handleAlignment}
        alignment={alignment}
        options={credentialContext ? credentialOptions : personCredentialOptions}
      />
    </Box>
  )
});

export default ContentOptions