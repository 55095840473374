import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../theme/colors';

export default makeStyles((theme) => ({
  deleteModal: {
    padding   : theme.spacing(2),
    paddingTop: theme.spacing(5),
  },
  dialogImage: {
    display       : 'flex',
    justifyContent: 'center',
    paddingBottom : theme.spacing(2),
  },
  image: {
    height      : 120,
    width       : 163,
  },
  dialogTitle: {
    fontWeight: 'bold',
    textAlign : 'center',
  },
  dialogActions: {
    alignItems     : 'center',
    backgroundColor: colors.LIGHT_GRAY,
    direction      : 'row',
    display        : 'flex',
    height         : theme.spacing(8),
    justifyContent : 'space-around',
    padding        : theme.spacing(2),
    width          : 600
  }
}));