import { Menu, MenuItem, Typography } from '@material-ui/core';
import { default as React, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import images from '../../utility/images';
import useStyles from "./styles";

const LocationMenu = (props) => {
  const { level, handleCreateLocation, handleCreateArea, handleCloseCreateButton, open, anchorEl, areaLabel } = props;
  const { t } = useTranslation();
  const classes  = useStyles();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (anchorEl && !anchorEl.contains(event.target)) {
        handleCloseCreateButton();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [anchorEl, handleCloseCreateButton]);

  return (
    <Menu
      id="menu"
      className={classes.menu}
      anchorEl={anchorEl}
      onClose={handleCloseCreateButton}
      open={open}
      MenuListProps={{
        'aria-labelledby': 'button',
        onMouseLeave: handleCloseCreateButton
      }}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <MenuItem 
        id="createArea" 
        onClose={handleCloseCreateButton} 
        className={level > 5 && 'hidden'}
        onClick={handleCreateArea}>
          <img src={images.AREA_ICON} className={classes.icon} alt="area-icon"/> 
          <Typography className={classes.menuItem}>{areaLabel}</Typography>
      </MenuItem>
      <MenuItem 
        id="createLocation" 
        onClose={handleCloseCreateButton} 
        onClick={handleCreateLocation}>
          <img src={images.LOCATION_ICON} className={classes.icon} alt="location-icon"/> 
          <Typography className={classes.menuItem}>{t('locations-page.addLocation')}</Typography>
      </MenuItem>
    </Menu>
  )
}

export default LocationMenu;