import { Backdrop, Box, Button, Container, Fade, FormControlLabel, Grid, IconButton, Modal, Switch, TextField, Tooltip } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { Form, Formik } from 'formik';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from '../../../components/date-picker';
import SelectItems from '../../../components/select-items';
import UnsavedModal from '../../../components/unsaved-modal';
import { AuthContext } from '../../../context/authContext';
import { LocationContext } from '../../../context/locationContext';
import api from '../../../service/api';
import { multipleRequest, request } from '../../../service/requests';
import { API_REQUEST_ERROR_MESSAGE, DATE_FORMAT, DATE_FORMAT_YYYY_MM_DD, GET, LOCATIONS_MODULE, MAX_CHARACTER_LIMIT, POST } from '../../../utility/constants';
import { GetInitialLocationId, GetInitialLocationObject, assignLocation, hasLocationChange, unassignLocation } from '../../../utility/location';
import { userCredentialSchema } from '../../../validation/schema';
import { CreateCredentialsLoadingState } from '../user-skeleton';
import useStyles from './styles';

const Content = (props) => {
  const classes  = useStyles();
  const { t }    = useTranslation();
  const { disabled, handleClose, initialValues, onSubmit, setValidFrom, setValidUntil, setCredentialNumber, setDescription, setCompanyCode, setActive, setIsNotValid, showLoading, handleSelectedLocation, selectedLocation, showToaster, handlePermissions } = props

  const handleChange = (setter, formik) => {
    return(setter, formik);
  }

  return(
    <Formik
      enableReinitialize
      initialValues    = {initialValues}
      onSubmit         = {onSubmit}
      validationSchema = {userCredentialSchema}
    >
      {
        formik => (
          <Form>
            {showLoading(formik.isSubmitting)}
            <Grid id="userCredentialModalBodyContainer" container spacing={2} className={classes.form}>
              <Grid item xs={12} sm={5} md={4} lg={3} className={classes.grid}>
                <TextField
                  id="userCredentialModalCredentialNumber"
                  label={`${t('credentialNumber')}*`}
                  name="credentialNumber"
                  fullWidth
                  multiline
                  inputProps={{
                    maxlength: MAX_CHARACTER_LIMIT.TEXT_FIELD
                  }}
                  value={formik.values.credentialNumber}
                  onChange={e => handleChange(setCredentialNumber(e.target.value), formik.handleChange(e))}
                  error={formik.touched.credentialNumber && Boolean(formik.errors.credentialNumber)}
                  helperText={t(formik.touched.credentialNumber) && t(formik.errors.credentialNumber)}
                />
              </Grid>
              <Box component={Grid} item lg display={{ xs: 'none', sm: 'none', md: 'none' }}></Box>
              <Grid item xs={12} sm md lg>
                <Box className={classes.switchContainer}>
                  <FormControlLabel
                    labelPlacement="start"
                    control={<Switch id="userCredentialModalActiveSwitch" color="primary"/>}
                    label={formik.values.active ? `${t('active')}` : `${t('inactive')}`}
                    focusVisibleClassName=".Mui-focusVisible"
                    checked={formik.values.active}
                    onChange={e => handleChange(setActive(!formik.values.active), formik.handleChange(e))}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} className={classes.grid}>
                <TextField
                  id="userCredentialModalDescription"
                  label={t('description')}
                  name="description"
                  placeholder={t('noDescription')}
                  fullWidth
                  inputProps={{
                    maxlength: MAX_CHARACTER_LIMIT.TEXT_FIELD
                  }}
                  value={formik.values.description}
                  onChange={e => handleChange(setDescription(e.target.value), formik.handleChange(e))}
                  multiline
                  rows={6}
                />
                <span
                  className={formik.errors.description && formik.touched.description ? classes.characterLimitError : classes.characterLimit}>
                  { 
                    t('controller-page.characterLimit', { 
                      currentCharacterCount: formik.values.description.length, maxCharacterCount: MAX_CHARACTER_LIMIT.TEXT_FIELD 
                    })
                  }
                </span>
              </Grid>
              {
                handlePermissions(LOCATIONS_MODULE, GET) &&
                <Grid item xs={12}>
                  <SelectItems
                    id="userCredentialModalLocation"
                    disabled={disabled}
                    helperText={t(formik.touched.location) && t(formik.errors.location)}
                    isValid={formik.touched.location && Boolean(formik.errors.location)}
                    name="Locations"
                    onChange={handleSelectedLocation}
                    selectedItems={selectedLocation}
                    showToaster={showToaster}
                    single={true}
                    required={true}
                    handlePermissions={handlePermissions}
                  />
                </Grid>
              }
              <Grid item xs={12} md={6} lg={4}>
                <DatePicker
                  name="userCredentialModalValidFrom"
                  disabled={false}
                  label={t('validFrom')}
                  until={formik.values.validUntil}
                  value={formik.values.validFrom}
                  handleChange={setValidFrom}
                  touched={formik.touched.validFrom}
                  error={formik.errors.validFrom}
                  setIsNotValid={setIsNotValid}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <DatePicker
                  name="userCredentialModalValidUntil"
                  disabled={false}
                  label={t('validUntil')}
                  min={formik.values.validUntil}
                  from={formik.values.validFrom}
                  value={formik.values.validUntil}
                  handleChange={setValidUntil}
                  touched={formik.touched.validUntil}
                  error={formik.errors.validUntil}
                  setIsNotValid={setIsNotValid}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  id="userCredentialModalCompanyCode"
                  label={t('companyCode')}
                  name="companyCode"
                  fullWidth
                  inputProps={{
                    maxlength: MAX_CHARACTER_LIMIT.TEXT_FIELD
                  }}
                  value={formik.values.companyCode}
                  onChange={e => handleChange(setCompanyCode(e.target.value), formik.handleChange(e))}
                />
              </Grid>
            </Grid>
            <Grid id="userCredentialModalButtonsContainer" container className={classes.action}>
              <Grid item xs={12}>
              <Button
                  id="userCredentialModalSubmitButton"
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  {t('create')}
                </Button>
                <Button
                  id="userCredentialModalCancelButton"
                  onClick={handleClose}
                  variant="outlined"
                  color="primary"
                >
                  {t('cancel')}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )
      }
    </Formik>
  );
}

const ModalCreateCredentials = (props) => {
  const classes         = useStyles();
  const { t }           = useTranslation();
  const { showToaster, setSelectedCredentials, showLoading, userValidFrom, userValidUntil, setHasSelected, handlePermissions } = props;
  
  // TODO: Get administrator details from keycloak
  const { state : authContext }                                = useContext(AuthContext);
  const { defaultValidFrom, defaultValidUntil, administrator } = authContext;
  const { locations }                                          = administrator;
  
  const { state }  = useContext(LocationContext);

  const initialLocationId = GetInitialLocationId();
  const initialLocationObject = GetInitialLocationObject();

  const [active, setActive]                         = useState(true);
  const [companyCode, setCompanyCode]               = useState('');
  const [credentialNumber, setCredentialNumber]     = useState('');
  const [description, setDescription]               = useState('');
  const [validFrom, setValidFrom]                   = useState(userValidFrom);
  const [validUntil, setValidUntil]                 = useState(userValidUntil);
  const [isLoading]                                 = useState(false);
  const [isNotValid, setIsNotValid]                 = useState(false);
  const [open, setOpen]                             = useState(false);
  const [prevValues, setPrevValues]                 = useState([]);
  const [showModal, setShowModal]                   = useState(false);
  const [location]                                  = useState('');
  const [selectedLocation, setSelectedLocation]     = useState(initialLocationId);
  const [locationObject, setLocationObject]         = useState(initialLocationObject);

  const initialValues = useMemo(() => {
    return {
      credentialNumber: credentialNumber,
      description     : description,
      validFrom       : validFrom,
      validUntil      : validUntil,
      companyCode     : companyCode,
      active          : active,
      location        : selectedLocation || selectedLocation === undefined ? selectedLocation : location
    }
  }, [credentialNumber, description, validFrom, validUntil, companyCode, active, selectedLocation, location]);

  useEffect(() => {
    setPrevValues({
      credentialNumber: '',
      description     : '',
      validFrom       : userValidFrom,
      validUntil      : userValidUntil,
      companyCode     : '',
      active          : true,
    });
  }, [userValidFrom, userValidUntil]);

  const handleOpen = () => {
    setOpen(true);
    handleClear();
  };

  const handleClear = () => {
    setCredentialNumber('');
    setDescription('');
    setValidFrom((userValidFrom) ? userValidFrom : defaultValidFrom);
    setValidUntil((userValidUntil) ? userValidUntil : defaultValidUntil);
    setCompanyCode('');
    setSelectedLocation(initialLocationId);
    setLocationObject(initialLocationObject);
    setActive(true);
  }

  const handleClose = () => {
    const initialValidUntil = (value) => {
      return (value) ? moment(value).format(DATE_FORMAT_YYYY_MM_DD) : '';
    }

    if (prevValues.credentialNumber === initialValues.credentialNumber
    && prevValues.description       === initialValues.description
    && prevValues.companyCode       === initialValues.companyCode
    && prevValues.active            === initialValues.active
    && initialValidUntil(prevValues.validUntil) === initialValidUntil(initialValues.validUntil)
    && moment(prevValues.validFrom).format(DATE_FORMAT_YYYY_MM_DD) === moment(initialValues.validFrom).format(DATE_FORMAT_YYYY_MM_DD)
    && !hasLocationChange(location, locationObject[0]?.locationId, locations.length, state)) {
        setShowModal(false);
        setOpen(false);
      } else {
        setShowModal(true);
      }
  };

  const handleSubmit = async (values, formik) => {
    const { setSubmitting } = formik;

    if (isNotValid) {
      setSubmitting(false);
      return;
    }

    if (!values.credentialNumber.includes(',')) {
      await handleMultipleCredentials(values, formik);
    } else {
      await checkExisting(values, formik);
    }
  }

  const handleMultipleCredentials = async (values, formik) => {
    const { setErrors, setSubmitting } = formik;

    let separatedCredentials = (values.credentialNumber.split(",")).filter((value => { 
      return value.trim() !== '';
    })).map((value) => value.trim());

    separatedCredentials = [...new Set(separatedCredentials)];

    try {
      const responses = await multipleRequest(
        separatedCredentials.map(separatedCredential =>
          request({
            url     : api.CREDENTIALS,
            method  : POST,
            data    : {
              credentialNumber  : separatedCredential.trim(),
              description       : values.description,
              companyCode       : values.companyCode,
              validFrom         : values.validFrom,
              validUntil        : values.validUntil,
              active            : values.active ? 1 : 0,
            }
          })
        )
      );

      responses.map(async (response) => {
        const link = response.data._links.self.href;

        await assignLocationToCredential(link);
      });

      const credentialData = responses.map((response) => {
        const { data } = response;
        const url  = data._links.self.href;

        const id = url.replace(`${api.CREDENTIALS}/`, '');
        return {
          id               : id,
          name             : data.credentialNumber,
          description      : moment(data.validFrom).format(DATE_FORMAT) + '-' + moment(data.validUntil).format(DATE_FORMAT),
          endContent       : data.active === 1,
        }
      });

      setSelectedCredentials(credential => [...credential, ...credentialData]);
      setHasSelected(true);

      setOpen(false);
      setShowModal(false);
      showToaster(t('success'), `${values.credentialNumber} ${t('hasBeenCreated')}`, ('success'));
    } catch (error) {
      if (error?.response?.status === 409) {
        setErrors({
          credentialNumber: t('credentialNumberAlreadyExists')
        });
      } else {
        showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
      }
    } finally {
      setSubmitting(false);
    }
  }

  const checkExisting = async (values, formik) => {
    try {
      const responses = await request({
        url     : `${api.CREDENTIALS_FIND_BY_CREDENTIAL_NUMBER_IN}`,
        method  : GET,
        params: {
          credentialNumbers : values.credentialNumber
        }
      });
      
      if (responses.data._embedded.credentials.length > 0) {
        formik.setErrors({
          credentialNumber: t('credentialNumberAlreadyExistsMultiple')
        });
        formik.setSubmitting(false);
      } else {
        await handleMultipleCredentials(values, formik);
      }
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }
  }

  const handleSelectedLocation = (value) => {
    setSelectedLocation(value[0]?.locationId);
    setLocationObject(value);
  }

  const assignLocationToCredential = async (link) => {
    if (location === locationObject[0]?.locationId) {
      return;
    }

    if (location && selectedLocation) {
      await unassignLocation(link);
    } 

    await assignLocation(link, selectedLocation);
  }

  const handleCloseModal = () => {
    setShowModal(false);
  }

  const handleModalSubmit = () => {
    setShowModal(false);
    setOpen(false);
  }

  const handleModalCancel = () => {
    setShowModal(false);
  }

  return (
    <Box>
      <Box onClick={handleOpen}>
        <Tooltip title={t('createCredential')}>
          <IconButton id="userCredentialModalCreateButton" className={classes.iconButton}>
            <AddIcon className={classes.addIconButton}/>
          </IconButton>
        </Tooltip>
      </Box>
      <UnsavedModal
        open={showModal}
        onClose={handleCloseModal}
        handleModalSubmit={handleModalSubmit}
        handleModalCancel={handleModalCancel}
      />
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout : 500,
        }}
        className={classes.createProfileModal}
      >
        <Fade in={open}>
          <Container className={`${classes.modalContent}`}>
            <Box variant="body1" className={`${classes.titleModalCreateProfile} ${'bold'} ${classes.form}`}> {t('createCredential')} </Box>
            <hr className={classes.hrDividerProfile} />
            {
              isLoading ?
                <CreateCredentialsLoadingState />
              :
                <Content
                  handleClose={handleClose}
                  onSubmit={handleSubmit}
                  initialValues={initialValues}
                  setValidFrom={setValidFrom}
                  setValidUntil={setValidUntil}
                  setCredentialNumber={setCredentialNumber}
                  setDescription={setDescription}
                  setCompanyCode={setCompanyCode}
                  setActive={setActive}
                  showLoading={showLoading}
                  setIsNotValid={setIsNotValid}
                  selectedLocation={locationObject}
                  handleSelectedLocation={handleSelectedLocation}
                  handlePermissions={handlePermissions}
                />
            }
          </Container>
        </Fade>
      </Modal>
    </Box>
  );
}

export default ModalCreateCredentials;