import React, { useState, useEffect, useCallback } from 'react';
import { API_REQUEST_ERROR_MESSAGE, ASCENDING, DELETE, GET, GROUPS_MODULE } from '../../utility/constants';
import { Container } from '@material-ui/core';
import { multipleRequest, request } from '../../service/requests';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import api from '../../service/api';
import Table, { createColumn } from '../../components/table';
import Title from '../../components/title';
import useStyles from './styles';
import DeleteModal from '../../components/delete-modal';
import { formatAssociated } from '../../utility/helper';

const columns = [
  createColumn('name', 'groupName', true, 'string', false, true),
  createColumn('description', 'description', false, 'string'),
  createColumn('accessPoints', 'accessPoints', false, 'component'),
  createColumn('groupID', 'Group Id', false, 'string', true),
  createColumn('profiles', 'Profiles', false, 'string', true)
];

const Groups = (props) => {
  const { showToaster } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading]               = useState(false);
  const [rowsPerPage, setRowsPerPage]           = useState(10);
  const [page, setPage]                         = useState(0);
  const [groups, setGroups]                     = useState([]);
  const [totalGroups, setTotalGroups]           = useState(0);
  const [orderBy, setOrderBy]                   = useState(columns[0].id);
  const [order, setOrder]                       = useState(ASCENDING);
  const [keyword, setKeyword]                   = useState('');
  const [remove, setRemove]                     = useState(false);
  const [forDelete, setForDelete]               = useState([]);
  const [forDeleteName, setForDeleteName]       = useState([]);
  const [showModal, setShowModal]               = useState(false);
  const [type , setType]                        = useState('single');
  const [assocValues, setAssocValues]           = useState('');

  const getGroups = useCallback((status) => {
    remove ? setIsLoading(false) : setIsLoading(status);
    request({
      url     : keyword === '' ? api.GROUPS : api.GROUPS_SEARCH,
      method  : GET,
      params  : {
        name  : keyword,
        size  : rowsPerPage,
        page  : page,
        sort  : `${orderBy},${order}`
      }
    }).then(response => {

      const { data } = response;
      const { number, size, totalElements } = data.page;
      const newGroups = data._embedded.groups.map(group => {
        const { name, description, accessPoints, groupId, profiles } = group;

        const newAccesspoints = accessPoints.map(access => {
          return {
            name : access.name
          }
        });

        return {
          groupName         : name,
          description       : description,
          accessPoints      : newAccesspoints,
          groupId           : groupId,
          profiles          : profiles
        }
      });

      if (newGroups.length || number === 0) {
        setPage(number);
        setGroups(newGroups);
      } else {
        setPage(number - 1);
      }
      setRowsPerPage(size);
      setTotalGroups(totalElements);
    }).catch(() => {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }).finally(() => {
      setIsLoading(false);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remove]);

  useEffect(() => {
    let delayDebounce;

    if (keyword) {
      setIsLoading(!Boolean(remove));
      delayDebounce = setTimeout(() => {
        getGroups(true);
      }, 1000);
    } else {
      getGroups(true);
    }

    return () => {
      delayDebounce && clearTimeout(delayDebounce);
    }
  }, [page, rowsPerPage, order, orderBy, keyword, getGroups, remove]);

  useEffect(() => {
    if (remove) {
      getGroups(true);
    }
    setRemove(false);
  }, [remove, getGroups])

  const handleSearch = (value) => {
    setPage(0);
    setKeyword(value);
  }

  const handleSort = (newOrderBy, newOrder) => {
    setPage(0);
    setOrderBy(newOrderBy);
    setOrder(newOrder);
  }

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setPage(0);
    setRowsPerPage(newRowsPerPage);
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
  }

  const handleView = (value) => {
    const { groupId } = value;

    history.push(`/groups/view/${groupId}`);
  }

  const handleCreate = () => {
    history.push("/groups/create");
  }

  const handleUpdate = (value) => {
    const { groupId } = value;

    history.push(`/groups/update/${groupId}`);
  }

  const handleDelete = (value) => {
    if ((value.profiles).length === 0) {
      handleOpenDeleteModal(value, value.groupName, 'single');
    } else {
      setAssocValues(formatAssociated(value.profiles.map(val => { return (` ${val.name}`)}), t));
      handleOpenDeleteModal(value, value.groupName, 'singleWithAssociated');
    }
  }

  const singleDelete = (value) => {
    const { groupId, groupName } = value;
    request({
      url     : `${api.GROUPS}/${groupId}`,
      method  : DELETE
    }).then(() => {
      setIsLoading(false);
      setRemove(true);
      showToaster(t(`success`), `${groupName} ${t('hasBeenDeleted')}`, 'success');
    }).catch(error => {
      setRemove(true);
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    });
  }

  const handleMultipleDelete = (values) => {
    const hasAssoc = values.some((value) => (value.profiles).length > 0);
    if (hasAssoc) {
      setAssocValues(formatAssociated(
        [...new Set(values.map(value => value.profiles.map(profile => {
            return (` ${profile.name}`)
          })).flat())
        ], t
      ));
      if (values.length === 1) {
        handleOpenDeleteModal(values[0], values[0].groupName, 'singleWithAssociated');
      } else {
        handleOpenDeleteModal(values, '', 'multipleWithAssociated');
      }
    } else if (values.length === 1) {
      handleOpenDeleteModal(values[0], values[0].groupName, 'single');
    } else {
      handleOpenDeleteModal(values, '', 'multiple');
    }
  }

  const multipleDelete = (values) => {
    const ids = values.map(value => value.groupId);
    const groupNames = values.map(value => value.groupName);
    const lastGroupName = groupNames.pop();

    multipleRequest(
      ids.map(id =>
        request({
          url     : `${api.GROUPS}/${id}`,
          method  : DELETE
        })
      )
    ).then(() => {
      setIsLoading(false);
      showToaster(t(`success`), `${groupNames.join(', ')}, ${t('and')} ${lastGroupName} ${t('hasBeenDeleted')}`, 'success');
      setRemove(true);
    }).catch(error => {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
      setRemove(true);
    });
  }

  const handleOpenDeleteModal = (value, name, paramType) => {
    setForDelete(value);
    setForDeleteName(name);
    setType(paramType)
    setShowModal(true);
  }

  const handleCloseDeleteModal = () => {
    setShowModal(false);
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Title title={t('groups')}/>
      <DeleteModal
        assocValues={assocValues}
        forDeleteName={forDeleteName}
        initialValues={forDelete}
        module={GROUPS_MODULE}
        multipleDelete={multipleDelete}
        onClose={handleCloseDeleteModal}
        open={showModal}
        showToaster={showToaster}
        singleDelete={singleDelete}
        type={type}
      />
      <Table
        columns={columns}
        data={groups}
        isLoading={isLoading}
        keyword={keyword}
        label={'group'}
        module={GROUPS_MODULE}
        onChangePage={handleChangePage}
        onClearSearch={() => handleSearch('')}
        onCreate={handleCreate}
        onDelete={handleDelete}
        onMultipleDelete={handleMultipleDelete}
        onRowsPerPageChange={handleRowsPerPageChange}
        onSearch={handleSearch}
        onSort={handleSort}
        onUpdate={handleUpdate}
        onView={handleView}
        orderBy={orderBy}
        order={order}
        page={page}
        rowsPerPage={rowsPerPage}
        totalItems={totalGroups}
        viewKey={'groupName'}
      />
    </Container>
  );
}

export default Groups;