import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  eventsContainer: {
    height   : '100%',
    minWidth : '100%',
    '& .MuiTableRow-root': {
      display       : 'flex',
      justifyContent: 'space-between',
    },
    '& .MuiTableFooter-root': {
      display       : 'flex',
      justifyContent: 'flex-end'
    }
  },
  contents: {
    '& .MuiTableCell-root': {
      borderBottom    : 'none',
      fontSize        : theme.spacing(2),
      fontFamily      : 'Arial',
      paddingTop      : theme.spacing(2),
      textTransform   : 'uppercase'
    },
    '& td': {
      wordSpacing     : theme.spacing(2),
    }
  },
  pagination: {
    borderBottom      : 'none'
  },
  table:  {
    height: '400px'
  },
  empty: {
    padding    : theme.spacing(8),
    paddingTop : theme.spacing(11)
  }
}));