import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    display    : 'flex',
    padding    : theme.spacing(1.5),
    paddingLeft: theme.spacing(1),
  },
  date: {
    width: theme.spacing(25),
  },
  logText: {
    width: theme.spacing(125),
    marginLeft: theme.spacing(3)
  }
}));