import { GET } from "../utility/constants";
import api from "./api";
import { request } from "./requests";

export const getUsersByRoleId = async (id) => {
  const response = await request({
    url    : api.ROLE_USERS.replace(':id', id),
    method : GET,
  });

  return response.data
}