import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  iconBox: {
    marginRight: theme.spacing(2)
  },
  deleteModal: {
    paddingTop: theme.spacing(3),
    width     : theme.spacing(62.5),
  },
  dialogImage: {
    display       : 'flex',
    justifyContent: 'center',
    paddingBottom : theme.spacing(5),
    '& img': {
      width: theme.spacing(8.75),
    }
  },
  dialogTitle: {
    fontWeight   : 'bold',
    textAlign    : 'center',
  },
  dialogContent: {
    padding      : theme.spacing(3),
    paddingBottom: theme.spacing(2),
    paddingTop   : theme.spacing(2),
  },
  dialogEmphasis: {
    fontStyle: 'italic'
  },
  dialogActions: {
    alignItems     : 'center',
    backgroundColor: colors.LIGHT_GRAY,
    display        : 'flex',
    height         : theme.spacing(8),
    justifyContent : 'space-around',
    padding        : theme.spacing(2),
  },
  dialogButton: {
    display : 'flex',
    margin  : theme.spacing(0.5),
    padding : theme.spacing(0.5),
    width   : theme.spacing(37.5),
  },
}));