import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    height        : '350px',
    width         : '55%',
  },
  form: {
    padding       : theme.spacing(1),
    '& .MuiFormControl-root': {
      pointerEvents   : 'none',
      '& .MuiBox-root': {
        paddingTop    : 0,
        paddingBottom : 0
      },
    },
    '& .MuiFormControl-marginNormal': {
      marginTop       : theme.spacing(0)
    },
  },
  inputChips: {
    alignContent      : 'center',
    alignItems        : 'center',
    display           : 'flex',
    flexWrap          : 'wrap',
    justifyContent    : 'flex-start',
    marginBottom      : theme.spacing(0.5),
    marginTop         : theme.spacing(1),
    minWidth          : '95%',
  },
  chips: {
    color : 'white',
    margin: theme.spacing(0.2),
  },
}));
