import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Container, Grid, IconButton, InputAdornment, Paper, TextField, Tooltip, Typography, Checkbox } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { Prompt, useHistory } from 'react-router';
import UnsavedModal from '../../components/unsaved-modal';
import Title from '../../components/title';
import { Edit, Visibility, VisibilityOff} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import AdministratorSkeleton from './administrator-skeleton';
import { createAdministratorSchema, updateAdministratorSchema } from '../../validation/schema';
import clsx from 'clsx';
import api from '../../service/api';
import { API_REQUEST_ERROR_MESSAGE, GET, STATUS_ACTIVE, STATUS_NEW, ACTION_CREATE, ACTION_VIEW, ACTION_UPDATE } from '../../utility/constants';
import axios from 'axios';
import Select from '../../components/select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { isArrayEqual } from '../../utility/helper';
import { request } from '../../service/requests';
import { LocationContext } from '../../context/locationContext';
import {
  GetSystemRolesForAdministrator,
  CreateAdministrator,
  GetAdministratorById,
  UpdateAdministrator,
  AssignAdministratorSystemRoles,
  DeleteAdministratorSystemRoles,
  GetAllSystemRoles,
  GetWebClientID
} from "../../service/administratorsApi";

const Content = (props) => {
  const { id, path, handleSubmit, initialValues, disabled, showLoading, handleCancel, setFirstName, setLastName, setEmail, setPassword, setStatus, passwordShown, handleClickShowPassword, role, showToaster, handleSelectChange, isAdministratorStatusDisabled } = props;
  const { t }                 = useTranslation();
  const classes               = useStyles();
  const lockStatus            = initialValues.status === STATUS_ACTIVE || initialValues.status === STATUS_NEW;
  const [checked, setChecked] = useState(false);

  const getButtonLabel = () => {
    return id ? `${t('update')}` : `${t('create')}`;
  }
  
  const handleChange = (setter, formik) => {
    return (setter, formik);
  }

  const handleChangeStatus = () => {
    if (isAdministratorStatusDisabled) {
      return;
    }
    setStatus(lockStatus ? 'BLO' : 'ACT');
  }

  const handleAutoGenerate = () => {
    setChecked(!checked);
  }

  useEffect(() => {
    autoGeneratePassowrd(checked);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  const autoGeneratePassowrd = (isChecked) => {
    if (isChecked) {
      const randomstring = Math.random().toString(36).slice(-8);
      setPassword(randomstring);
    } else {
      setPassword('');
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={path.includes(ACTION_UPDATE) ? updateAdministratorSchema : createAdministratorSchema }
      onSubmit={handleSubmit}
    >
      {
        formik => (
          <Form>
            {showLoading(formik.isSubmitting)}
            <Paper className={classes.paper} elevation={3}>
              <Grid container spacing={2} className={classes.form}>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    inputProps={{
                      readOnly: disabled
                    }}
                    disabled={disabled}
                    id="firstName"
                    label={`${t('firstName')}*`}
                    name="firstName"
                    variant="outlined"
                    fullWidth
                    value={formik.values.firstName}
                    onChange={e => handleChange(setFirstName(e.target.value), formik.handleChange(e))}
                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                    helperText={t(formik.touched.firstName) && t(formik.errors.firstName)}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    inputProps={{
                      readOnly: disabled
                    }}
                    disabled={disabled}
                    id="lastName"
                    label={`${t('lastName')}*`}
                    name="lastName"
                    variant="outlined"
                    fullWidth
                    value={formik.values.lastName}
                    onChange={e => handleChange(setLastName(e.target.value), formik.handleChange(e))}
                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                    helperText={t(formik.touched.lastName) && t(formik.errors.lastName)}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    inputProps={{
                      readOnly: disabled || path.includes(ACTION_UPDATE)
                    }}
                    disabled={disabled || path.includes(ACTION_UPDATE)}
                    id="email"
                    label={`${t('emailAddress')}*`}
                    name="email"
                    variant="outlined"
                    fullWidth
                    value={formik.values.email}
                    onChange={e => handleChange(setEmail(e.target.value), formik.handleChange(e))}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={t(formik.touched.email) && t(formik.errors.email)}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <SelectProfiles handleSelectChange={handleSelectChange} disabled={disabled} role={role} showToaster={showToaster} />
                </Grid>
                {
                  path.includes(ACTION_CREATE) ?
                  (
                    <>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => handleClickShowPassword()}
                                edge="end"
                              >
                                {passwordShown ? <Visibility /> : <VisibilityOff /> }
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        disabled={disabled}
                        id="password"
                        label={`${t('password')}*`}
                        name="password"
                        type={passwordShown ? 'text' : 'password'}
                        variant="outlined"
                        fullWidth
                        value={formik.values.password}
                        onChange={e => handleChange(setPassword(e.target.value), formik.handleChange(e))}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={t(formik.touched.password) && t(formik.errors.password)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <Box className={classes.alignCheckbox}>
                        <Checkbox
                          color="primary"
                          size="small"
                          checked={checked}
                          onChange={() => handleAutoGenerate()}
                        />
                        {t('autoGenerate')}
                      </Box>
                    </Grid>
                    </>
                  ) : (<></>)
                }
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    inputProps={{
                      readOnly: true
                    }}
                    disabled={isAdministratorStatusDisabled}
                    className={!isAdministratorStatusDisabled && classes.status}
                    id="status"
                    label={t('status')}
                    name="status"
                    variant="outlined"
                    placeholder={t('status')}
                    fullWidth
                    value={t((formik.values.status === STATUS_ACTIVE || formik.values.status === STATUS_NEW) ? 'active' : 'blocked')}
                    onClick={handleChangeStatus}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <FontAwesomeIcon icon={faLockOpen} className={clsx(!lockStatus && 'hidden')} />
                          <FontAwesomeIcon icon={faLock} className={clsx(lockStatus && 'hidden')} />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </Grid>
              <Grid className={clsx(disabled ? 'hidden' : classes.action)}>
                <Grid item xs={12}>
                  <Button
                    className={classes.button}
                    onClick={handleCancel}
                    variant="outlined"
                    color="primary"
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    className={classes.button}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {getButtonLabel()}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Form>
        )
      }
    </Formik>
  )
}

const SelectProfiles = (props) => {
  const { handleSelectChange, role, showToaster, disabled } = props;

  const { t } = useTranslation();
  const { state : locationState }                      = useContext(LocationContext);
  const { selectedLocationId }                         = locationState;
  
  const [error, setError]                     = useState(false);
  const [items, setItems]                     = useState([]);
  const [hasMore, setHasMore]                 = useState(false);
  const [isLoadingSelect, setIsLoadingSelect] = useState(true);

  useEffect(() => {
    if (selectedLocationId) {
      setItems([]);
      setIsLoadingSelect(true);
      setError(false);
    }
  }, [selectedLocationId]);

  const useSearch = (query, pageNumber) => {
    useEffect(() => {
      setItems([]);
    }, [query]);

    useEffect(() => {
      setIsLoadingSelect(true);
      setError(false);

      const getItems = async () => {
        try {
          const items = await GetAllSystemRoles(query)
          setItems(items);
          setHasMore(false);
          setIsLoadingSelect(false);
        } catch (e) {
          if (axios.isCancel(e)) {
            return;
          }
          setError(true);
          showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
        }
      }

      getItems();
      return;
    }, [query, pageNumber]);

    return {
      isLoadingSelect,
      error,
      items,
      hasMore
    }
  }

  return (
    <Select
      disabled={disabled}
      useSearch={useSearch}
      selectFrom={'Role'}
      label={'role'}
      onChange={handleSelectChange}
      defaultValue={role}
    />
  )
}

const Administrator = (props) => {
  const { showToaster, match, showLoading } = props;
  const { t }                               = useTranslation();
  const { id }                              = match.params;
  const path                                = match.path;

  const classes                             = useStyles();
  const history                             = useHistory();

  const [firstName, setFirstName]     = useState('');
  const [lastName, setLastName]       = useState('');
  const [email, setEmail]             = useState('');
  const [role, setRole]               = useState([]);
  const [status, setStatus]           = useState('ACT');
  const [showModal, setShowModal]     = useState(false);
  const [withChanges, setWithChanges] = useState(false);
  const [toRedirect, setToRedirect]   = useState('');
  const [isLoading, setIsLoading]     = useState(false);
  const [prevValues, setPrevValues]   = useState();
  
  const [passwordShown, setPasswordShown] = useState(false);
  const [password, setPassword]           = useState('');
  
  const initialValues = {
    firstName: firstName,
    lastName : lastName,
    email    : email,
    password : password,
    role     : role,
    status   : status
  }

  useEffect(() => {
    setPrevValues(initialValues);
    if (id) {
      getAdministrator();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleChanges();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstName, lastName, email, password, role, status, prevValues]);

  const getAdministrator = async () => {
    setIsLoading(true);
    try {
      const administrator = await GetAdministratorById(id);
      await getSystemRoles(administrator);
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }
  }

  const getSystemRoles = async (data) => {
    try {
      const clientId = await GetWebClientID();
      const roles = await GetSystemRolesForAdministrator(id, clientId);

      const prevValueData = {
        firstName: data.firstName,
        lastName : data.lastName,
        email    : data.email,
        company  : data.company,
        role     : roles,
        status   : data.status,
      }

      setPrevValues(prevValueData);
      setRole(roles);
      setFirstName(data.firstName);
      setLastName(data.lastName);
      setEmail(data.email);
      setStatus(data.status);
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  const updateSuccess = (values, setSubmitting) => {
    setSubmitting(false);
    setWithChanges(false);
    history.push('/administrators');
    if (path.includes(ACTION_CREATE)) {
      showToaster(t('success'), `${values.lastName}, ${values.firstName} ${t('hasBeenCreated')}`, 'success');
    } else {
      showToaster(t('success'), `${values.lastName}, ${values.firstName} ${t('hasBeenUpdated')}`, 'success');
    }
  }

  const handleSubmit = async (values, formik) => {
    const { setSubmitting } = formik;
    if (!withChanges) {
      setSubmitting(false);
      setWithChanges(false);
      history.push('/administrators');
      return;
    }
    if (path.includes(ACTION_CREATE)) {
      await validateEmail(values, formik)
    } else {
      await updateAdministrator(values, formik);
    }
  }

  const validateEmail = async (values, formik) => {
    try {
      const response = await request({
        url     : api.ADMINISTRATORS_BY_EMAIL,
        method  : GET,
        params  : {
          email : values.email
        }
      });
        
      const { data } = response;
      if (data) {
        formik.setErrors({
          email: t('emailAlreadyExists')
        });
      }
      formik.setSubmitting(false);
    } catch (error) {
      if (error.response.status === 404) {     
        await createAdministrator(values, formik);
      } else {
        showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
      }
    }
  }

  const updateAdministrator = async (values, formik) => {
    const updatedValues = values;
    
    delete updatedValues.password;

    const administratorObject = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      enabled: true,
      username: values.email
    }

    try {
      await UpdateAdministrator(id, administratorObject);
      
      const hasRoleChanges = !hasChanges(initialValues.role, prevValues.role);

      if (hasRoleChanges) {
        const toAdd = initialValues.role.filter(
          ({ id: id1 }) => !prevValues.role.some(({ id: id2 }) => id2 === id1)
        );
        const toRemove = prevValues.role.filter(
          ({ id: id1 }) =>
            !initialValues.role.some(({ id: id2 }) => id2 === id1)
        );

        if (toAdd.length > 0) {
          await AssignAdministratorSystemRoles(id, toAdd);
        }

        if (toRemove.length > 0) {
          await DeleteAdministratorSystemRoles(id, toRemove);
        }
      }
      updateSuccess(updatedValues, formik.setSubmitting);
    } catch {
      formik.setSubmitting(false);
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }
  }
  
  const createAdministrator = async (values, formik) => {
    try {
      const administratorID = await CreateAdministrator(values);
      const updatedValues = { 
        ...values, 
        id: administratorID 
      }
      updateSuccess(updatedValues, formik.setSubmitting);
    } catch {
      formik.setSubmitting(false);
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }
  }

  const handleCancel = () => {
    history.push('/administrators');
  }

  const handleSelectChange = (values) => {
    setRole(values);
  }

  const handleCloseModal = () => {
    setShowModal(false);
  }

  const handleModalSubmit = () => {
    setWithChanges(false);
    history.push(toRedirect);
  }

  const handleModalCancel = () => {
    handleChanges();
    setShowModal(false);
  }

  const hasChanges = (current, previous) => {
    const currentIds  = current ?  current.map(item => item.id) : [];
    const previousIds = previous ? previous.map(item => item.id) : [];
    return isArrayEqual(currentIds, previousIds);
  }

  const handleClickShowPassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handleChanges = () => {
    if (prevValues?.firstName === initialValues.firstName
      && prevValues?.lastName === initialValues.lastName
      && prevValues?.email === initialValues.email
      // && prevValues.company === initialValues.company
      && prevValues?.status === initialValues.status
      && hasChanges(initialValues.role, prevValues?.role)) {
      setWithChanges(false);
    } else {
      setWithChanges(true);
    }
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <UnsavedModal
        open={showModal}
        onClose={handleCloseModal}
        handleModalSubmit={handleModalSubmit}
        handleModalCancel={handleModalCancel}
      />
      <Prompt
        when={withChanges}
        message={(location, action) => {
          if (action === 'PUSH') {
            setShowModal(true)
          }
          setWithChanges(false);
          setToRedirect(location.pathname);
          return location.pathname === '/' || location.pathname.startsWith('/administrators/update')
        }}
      />
      <Title title={t('administrators')} subtitle={`${lastName}, ${firstName}`} />
      <Box className={classes.details}>
        <Grid container>
          <Grid item xs={6}>
            <Typography className={'bold'} color="secondary">{t('details')}</Typography>
          </Grid>
          <Grid item xs={6} align="right" className={clsx(path.includes(ACTION_VIEW) ? '' : 'hidden')}>
            <Tooltip title={t('update')} className={path.includes(ACTION_VIEW) ? '' : 'hidden'}>
              <IconButton aria-label="Update" onClick={() => history.push(`../update/${id}`)}>
                <Edit className={classes.editIcon} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        {
          isLoading ?
            <AdministratorSkeleton disabled={path.includes(ACTION_VIEW)} />
            :
            <Content
              id={id}
              disabled={path.includes(ACTION_VIEW)}
              isAdministratorStatusDisabled={(path.includes(ACTION_VIEW)) || path.includes(ACTION_CREATE)}
              initialValues={initialValues}
              handleCancel={handleCancel}
              handleSubmit={handleSubmit}
              handleSelectChange={handleSelectChange}
              showToaster={showToaster}
              path={path}
              role={role}
              setFirstName={setFirstName}
              setLastName={setLastName}
              setEmail={setEmail}
              setStatus={setStatus}
              showLoading={showLoading}
              passwordShown={passwordShown}
              setPassword={setPassword}
              handleClickShowPassword={handleClickShowPassword}
            />
        }
      </Box>
    </Container>
  )
}

export default Administrator;