import React, { memo } from 'react';
import clsx from 'clsx';
import { Paper, Grid, Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import useStyles from './styles';
import { DESCRIPTION_SKELETON, TEXT_FIELD_SKELETON } from '../../../utility/constants';

const GroupSkeleton = (props) => {
  const { disabled } = props;
  const classes = useStyles();

  return (
    <Box>
      <Paper className={classes.paper} elevation={3}>
        <Grid container justifyContent="space-between" spacing={2} className={classes.form}>
          <Grid item xs={12} sm={5} md={4} lg={6}>
            <Skeleton height={TEXT_FIELD_SKELETON}/>
            <Skeleton height={TEXT_FIELD_SKELETON}/>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Skeleton height={DESCRIPTION_SKELETON}/>
          </Grid>
        </Grid>
        <Grid container className={clsx(disabled ? 'hidden' : classes.action)}>
          <Grid item xs={12} className={`${classes.buttonContainer}`}>
            <Skeleton variant="rect" className={classes.button} />
            <Skeleton variant="rect" className={classes.button} />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default memo(GroupSkeleton)