import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Select from '../../components/select'
import axios from 'axios';
import { GET } from '../../utility/constants';
import { request } from '../../service/requests';
import api from '../../service/api';

const EnhancedSelect = (props) => {
  const { setAccessPointLink, accessPoints, disabled} = props;
  const [isLoadingSelect, setIsLoadingSelect] = useState(true)
  const [error, setError]                     = useState(false)
  const [items, setItems]                     = useState([])
  const [hasMore, setHasMore]                 = useState(false)

  useEffect(() => {
    setItems([])
    setIsLoadingSelect(true)
    setError(false)
  },[])

  const useSearch = (
    query,
    pageNumber,
    ) => {

    useEffect(() => {
      setItems([])
    }, [query])

    useEffect(() => {
      setIsLoadingSelect(true)
      setError(false)
      let cancel
      const params = {
        name            : query,
        size            : 30,
        page            : pageNumber,
      }
      
      request({
        url     : query === '' ? api.ACCESS_POINTS : api.ACCESS_POINTS_SEARCH,
        method  : GET,
        params  : params,
        cancelToken : new axios.CancelToken(c => cancel = c),
      }).then( response => {
        const { data } = response;
        const { totalElements, totalPages } = data.page;
        setItems (prevItems => {
          return [...new Set([...prevItems, ...data._embedded.accessPoints.map( accessP => {
            return {
              name       : `${accessP.controller[0].deviceId}:${accessP.name}`,
              link       : accessP._links.self.href
            }})
          ])]
        })
        setHasMore(totalElements > 0 && pageNumber < totalPages - 1)
        setIsLoadingSelect(false)
      }).catch((e) => {
        if (axios.isCancel(e)) return
        setError(true)
      })
      return(() => cancel())
    },[query, pageNumber])
    return { isLoadingSelect, error, items, hasMore }
  }

  const handleChange = (newItems) => {
    setAccessPointLink(newItems);
  }
  return (
      <Grid item>
        <Select
          disabled   = {disabled}
          useSearch  = {useSearch}
          selectFrom = {'Access Points'}
          label      = {'accessPoints'}
          onChange   = {handleChange}
          defaultValue = {accessPoints}
        />
      </Grid>
  );
}

export default EnhancedSelect;