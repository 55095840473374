import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react'
import { Grid } from '@material-ui/core';
import { WizardContext } from '../../../../context/wizardContext';
import { WizardPersonContext } from '../../../../context/wizardPersonContext';
import useStyles from './styles';
import { SET_SELECTED_ROLES } from '../../../../reducer/wizardPersonReducer';
import { SET_ERROR, SET_NEXT_PAYLOAD } from '../../../../reducer/wizardReducer';
import SelectItems from '../../../select-items';
import { PUT, ROLES_MODULE } from '../../../../utility/constants';
import clsx from 'clsx';

const WizardAssignRoles = forwardRef((props, ref) => {
  const { showToaster, handlePermissions } = props;
  const classes = useStyles();

  const wizardContext = useContext(WizardContext);
  const personContext = useContext(WizardPersonContext);

  const { selectedRoles } = personContext.state;
  const { bufferPage }    = wizardContext.state;

  const [roles, setRoles] = useState([]);

  useEffect(() => {
    wizardContext.dispatch({ type: SET_NEXT_PAYLOAD, payload: { prevPage: bufferPage, checkPage: 4 } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setRoles(selectedRoles);
  }, [selectedRoles]);

  useImperativeHandle(ref, () => ({
    onNextPage() {
      wizardContext.dispatch({ type: SET_ERROR, payload: false });
      wizardContext.dispatch({ type: SET_NEXT_PAYLOAD, payload: { nextPage: 5, isNextShow: true, prevPage: 4, checkPage: 5, isEndPage: true } });
      personContext.dispatch({ type: SET_SELECTED_ROLES, payload: roles });
    }
  }));

  const handleSelectRoles = (value) => {
    setRoles(value);
  }

  return (
    <Grid container className={clsx(!handlePermissions(ROLES_MODULE, PUT) ? 'hidden' : classes.select)}>
      <SelectItems
        id="wizardAssignRolesRoles"
        name="Roles"
        onChange={handleSelectRoles}
        selectedItems={roles}
        showToaster={showToaster}
        handlePermissions={handlePermissions}
      />
    </Grid>
  )
});

export default WizardAssignRoles