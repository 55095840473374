import { GET, PATCH, POST } from "../utility/constants";
import api from "./api";
import { request } from "./requests";

const AUTH_BASE_PATH = window.KEYCLOAK_URL;
const TEST_CONNECTION_API = AUTH_BASE_PATH + "/admin/realms/" + window.KEYCLOAK_REALM + "/testLDAPConnection";

export const TestConnection = async (data) => {
  try {
    const connectionResponse = await request({
      url   : TEST_CONNECTION_API,
      method: POST,
      data
    });
    
    return connectionResponse.status;
  } catch (error) {
    return 400;
  }
}

export const triggerFullSync = async (providerId) => {
  const syncResponse = await request({
    url   : `${api.USER_SYNC_CONFIGURATIONS}/${providerId}`,
    method: POST,
  });
  
  return syncResponse;
}

export const createUserSyncConfiguration = async (data) => {
  const syncResponse = await request({
    url   : api.USER_SYNC_CONFIGURATIONS,
    method: POST,
    data
  });

  return syncResponse;
}

export const updateUserSyncConfiguration = async (data) => {
  const syncResponse = await request({
    url   : api.USER_SYNC_CONFIGURATIONS,
    method: PATCH,
    data
  });

  return syncResponse;
}

export const getUserSyncConfiguration = async () => {
  try {
    const syncResponse = await request({
      url   : api.USER_SYNC_CONFIGURATIONS,
      method: GET
    });
  
    return syncResponse;
  } catch (e) {
    return;
  }
}