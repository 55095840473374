import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  navDetails: {
    padding      : 0,
    margin       : 0,
    paddingLeft  : theme.spacing(5),
  },
  navSummary: {
    overflow: 'hidden',
    '& .MuiAccordionSummary-root': {
      display        : 'flex',
      height         : theme.spacing(7),
      position       : 'relative !important',
    },
    '& .MuiIconButton-root': {
      bottom         : theme.spacing(0),
      right          : theme.spacing(1.7),
      position       : 'absolute',
      top            : theme.spacing(0),
      visibility     : 'visible',
      width          : 'fit-content',
    },
    '& .MuiAccordionSummary-content': {
      left           : theme.spacing(2),
      position       : 'absolute',
      right          : theme.spacing(2),
    },
    '& .MuiAccordionSummary-root:hover': {
      backgroundColor: theme.palette.primary.main,
      color          : theme.palette.common.white,
      '& .MuiListItemIcon-root': {
        color        : theme.palette.common.white,
      },
      '& .MuiAccordionSummary-expandIcon': {
        color        : theme.palette.common.white,
      }
    },
  },
  subMenuFlex: {
    '& .MuiAccordionSummary-root': {
      display        : 'inline-flex',
      width          : 'fit-content'
    },
    '& .MuiIconButton-root': {
      visibility     : 'hidden'
    }
  },
  accordionSelected: {
    backgroundColor  : theme.palette.primary.main,
    color            : theme.palette.common.white,
    transition       : 'background-color 0.1s',
    transitionDelay  : '0.1s',
    '& .MuiListItemIcon-root': {
      color          : theme.palette.common.white + '!important',
      transition     : 'color 0.1s',
      transitionDelay: '0.1s',
    },
    '& .MuiAccordionSummary-expandIcon': {
      color          : theme.palette.common.white,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color          : theme.palette.common.white,
      '& .MuiListItemIcon-root': {
        color        : theme.palette.common.white,
      },
      '& .MuiAccordionSummary-expandIcon': {
        color        : theme.palette.common.white,
      }
    }
  }
}));