import { faClock, faDoorOpen, faIdCard, faIdCardAlt, faUserCog, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Checkbox, CircularProgress, ClickAwayListener, FormControl, IconButton, InputAdornment, NoSsr, Paper, Popper, Radio, TextField, Typography } from '@material-ui/core';
import { ArrowDropDown as ArrowDropDownIcon, ArrowDropUp as ArrowDropUpIcon, CheckBox as CheckBoxIcon, CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon, RadioButtonChecked as RadioButtonIconChecked, RadioButtonUnchecked as RadioButtonIconUnchecked, SearchOutlined as SearchOutlinedIcon } from '@material-ui/icons';
import clsx from 'clsx';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../context/authContext';
import { LocationContext } from '../../context/locationContext';
import { LOCATION, LOCATION_FILTER, SYSTEM_ROLES_MODULE } from '../../utility/constants';
import Chip from '../chip';
import NestedList from '../nested-list';
import useStyles from './styles';

const TruncatedChips = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {selectedItem, tagIcon, handleDelete, id, maxOverFlowNumber } = props

  const remainingLength = selectedItem.slice(maxOverFlowNumber).length;
  const firstElements = selectedItem.slice(0, maxOverFlowNumber);
  return (
    <Box id={id} className={classes.inputChips}>
      {
        firstElements.map((option, index) => (
            <Box className={classes.chips} key={'Enhanced-Selected-Chip-Box-' + index}>
              {
                <Chip
                  id={id}
                  recordId={option.id}
                  key={'Enhanced-Chip-Tag-' + index}
                  icon={tagIcon}
                  title={option.name}
                  description={option.description}
                  onDelete={() => handleDelete(selectedItem.filter(entry => entry !== option))}
                />
              }
            </Box>
        ))
      }
      <Chip
        key={'Enhanced-Chip-More'}
        icon={tagIcon}
        title={`+${remainingLength} ${t('more')}`}
        color='outline'
      />
    </Box>
  )
}

const Chips = (props) => {
  const classes = useStyles();
  const {single, selectedItem, tagIcon, handleDelete, id, filterArea, isLocationFilter } = props

  return (
    single || isLocationFilter ?
        <Box id={id} className={isLocationFilter ? classes.locationFilterSingleOption : classes.singleOption}>
          <Typography variant="body2">
            {
              isLocationFilter && filterArea.length > 0 ? 
                filterArea[0].name
              : 
                selectedItem[0].name
            }
          </Typography>
          <Typography variant="caption">{selectedItem[0].description}</Typography>
        </Box>
      :
        <Box id={id} className={classes.inputChips}>
          {
            selectedItem.map((option, index) => (
              <Box className={classes.chips} key={'Enhanced-Selected-Chip-Box-' + index}>
                {
                  <Chip
                    id={id}
                    recordId={option.id}
                    key={'Enhanced-Chip-Tag-' + index}
                    icon={tagIcon}
                    title={option.name}
                    description={option.description}
                    onDelete={() => handleDelete(selectedItem.filter(entry => entry !== option))}
                  />
                }
              </Box>
            ))
          }
        </Box>
  )
}

const EnhancedInput = (props) => {
  const classes = useStyles();
  const { selectedItem, label, handleDelete, tagIcon, handleShowOptions, showOptions, callbackRef, single, disabled, isValid, helperText, isLocationFilter, required, id, truncate, filterArea } = props;
  const { t } = useTranslation();

  const maxOverFlowNumber = 10;
  const overflow = selectedItem.length > maxOverFlowNumber;

  return (
    <FormControl className={`${(clsx(disabled && classes.disableCloseBtn))} ${isLocationFilter ? classes.locationFilterSelect : classes.selectForm}`} ref={callbackRef}>
      <TextField
        error={isValid}
        helperText={helperText}
        id={id}
        label={required ? `${t(label)}*` : t(label)}
        onClick={handleShowOptions}
        variant="outlined"
        size={isLocationFilter ? "small" : ""}
        disabled
        InputProps={{
          disabled: disabled,
          startAdornment:
            truncate && overflow ?
              <TruncatedChips
                id={id}
                selectedItem={selectedItem}
                tagIcon={tagIcon}
                handleDelete={handleDelete}
                maxOverFlowNumber={maxOverFlowNumber}
              />
            :
              selectedItem?.length > 0?
                <Chips
                  id={id}
                  single={single}
                  selectedItem={selectedItem}
                  tagIcon={tagIcon}
                  handleDelete={handleDelete}
                  filterArea={filterArea}
                  isLocationFilter={isLocationFilter}
                />
              :
                null
          ,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton id={`${id}SelectButton`} disabled={disabled} edge="end" size="small">
                <ArrowDropDownIcon className={clsx({ [classes.visuallyHidden]: showOptions || disabled })} />
                <ArrowDropUpIcon   className={clsx({ [classes.visuallyHidden]: !showOptions || disabled })} />
              </IconButton>
            </InputAdornment>
          ),
          max: 100,
          min: 0,
          step: 2,
          onKeyDown: (event) => {
            event.preventDefault();
          },
        }}
      />
    </FormControl>
  )
}

const EnhancedSelection = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { query, items, handleSearch, lastItemElement, handleChange, isLoading, error, selectedItem, showOptions, setShowOptions, anchor, disabled, single, module, id } = props;

  const handleClickAway = () => {
    if (showOptions) setShowOptions(false);
  }

  return (
    <FormControl disabled={disabled}>
      <Popper
        open={showOptions}
        anchorEl={anchor}
        modifiers={{
          flip: {
            enabled: false,
          }
        }}
        popperOptions={{
          placement:'bottom',
        }}
        disablePortal={disabled}
        className={classes.popper}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper className={classes.paper}>
            <FormControl
              className={clsx({
                [classes.selectForm]: true,
                [classes.visuallyHidden] : !showOptions,
              })}
            >
            <Box className={module === SYSTEM_ROLES_MODULE ? 'hidden' : classes.loadField}
            >
              <TextField
                autoFocus={true}
                className={classes.searchBox}
                id={`${id}SearchBar`}
                onChange={handleSearch}
                placeholder={t('search')}
                size="small"
                value={query}
                variant="outlined"
                InputProps={{
                  autoComplete: 'off',
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlinedIcon
                        color="secondary"
                        fontSize={"medium"}
                      />
                    </InputAdornment>
                  )
                }}
              />
              <Box>{isLoading && <CircularProgress size={24} className={classes.loading} />}</Box>
            </Box>
            <NoSsr>
              <Box>{error && 'ERROR'}</Box>
              <Box className={clsx(classes.options, classes.wordBreak)}>
              {
                items.map((item, index) => {
                  let elementRecordId = item.id;
                  if (elementRecordId.toString().includes('projection')) {
                    elementRecordId = elementRecordId.toString().replace('{?projection}','');
                  }
                  
                  if (items.length === index + 1) {
                    return (
                      <Box id={`${id}${elementRecordId}`} ref={lastItemElement} key={`Select-Last-Item-` + index } className={classes.optionBox} onClick={() => handleChange(item)}>
                        <Checkbox
                          id={`${id}${elementRecordId}Checkbox`}
                          checked={selectedItem?.some(value => value.name === item.name)}
                          checkedIcon={<CheckBoxIcon />}
                          className={clsx({ [classes.invisible]: single })}
                          color="primary"
                          icon={<CheckBoxOutlineBlankIcon />}
                          onChange={() => handleChange(item)}
                          value={item}
                        />
                        <Radio
                          id={`${id}${elementRecordId}Radio`}
                          checked={selectedItem?.some(value => value.id === item.id)}
                          checkedIcon={<RadioButtonIconChecked />}
                          className={clsx({ [classes.invisible]: !single })}
                          color="primary"
                          icon={<RadioButtonIconUnchecked  />}
                          onChange={() => handleChange(item)}
                          value={item}
                        />
                        <Box onClick={() => handleChange(item)}>
                          <Box className={clsx({ [classes.optionTitle]: item.description })}>
                            <span id={`${id}${elementRecordId}Name`}>{item.name}</span>
                          </Box>
                          <Box className={classes.optionSub}>
                            <span id={`${id}${elementRecordId}Description`}>{item.description}</span>
                          </Box>
                        </Box>
                      </Box>
                    )
                  } else {
                    return (
                      <Box id={`${id}${elementRecordId}`} key={`Select-Item-` + index} className={classes.optionBox} onClick={() => handleChange(item)}>
                        <Checkbox
                          id={`${id}${elementRecordId}Checkbox`}
                          checked={selectedItem.some(value => value.name === item.name)}
                          checkedIcon={<CheckBoxIcon />}
                          className={clsx({ [classes.invisible]: single })}
                          color="primary"
                          icon={<CheckBoxOutlineBlankIcon />}
                          onChange={() => handleChange(item)}
                          value={item}
                        />
                        <Radio
                          id={`${id}${elementRecordId}Radio`}
                          checked={selectedItem.some(value => value.id === item.id)}
                          checkedIcon={<RadioButtonIconChecked />}
                          className={clsx({ [classes.invisible]: !single })}
                          color="primary"
                          icon={<RadioButtonIconUnchecked  />}
                          onChange={() => handleChange(item)}
                          value={item}
                        />
                        <Box onClick={() => handleChange(item)}>
                          <Box className={clsx({ [classes.optionTitle]: item.description })}>
                            <span id={`${id}${elementRecordId}Name`}>{item.name}</span>
                          </Box>
                          <Box className={classes.optionSub}>
                            <span id={`${id}${elementRecordId}Description`}>{item.description}</span>
                          </Box>
                        </Box>
                      </Box>
                    )
                  }
                })
              }
              </Box>
            </NoSsr>
          </FormControl>
        </Paper>
      </ClickAwayListener>
    </Popper>
  </FormControl>
  )
}

const EnhancedListSelection = (props) => {
  const classes = useStyles();
  const { items, handleChange, isLoading, error, name, showOptions, setShowOptions, anchor, disabled, single, id, isLocationFilter, selectedAreas, selectedLocations, setSelectedAreas, setSelectedLocations } = props;

  const handleClickAway = () => {
    if (showOptions) setShowOptions(false);
  }

  return (
    <FormControl disabled={disabled}>
      <Popper
        open={showOptions}
        anchorEl={anchor}
        placement="bottom-end"
        disablePortal={disabled}
        className={isLocationFilter ? classes.locationFilterPopper : classes.popper}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper className={isLocationFilter ? '' : classes.paper}>
            <FormControl
              className={clsx({
                [classes.selectForm]: true,
                [classes.visuallyHidden] : !showOptions,
              })}
            >
            <Box className={classes.loadField}>{isLoading && <CircularProgress size={24} className={classes.loading} />}</Box>
            <NoSsr>
              <Box>{error && 'ERROR'}</Box>
              <Box className={isLocationFilter ? classes.locationOptions : classes.options}>
                <NestedList
                  data={items}
                  disabled={disabled}
                  id={id}
                  handleSelectedItems={handleChange}
                  single={single}
                  field={name}
                  isLoading={isLoading}
                  selectedAreas={selectedAreas}
                  selectedLocations={selectedLocations}
                  setSelectedAreas={setSelectedAreas}
                  setSelectedLocations={setSelectedLocations}
                />
              </Box>
            </NoSsr>
          </FormControl>
        </Paper>
      </ClickAwayListener>
    </Popper>
  </FormControl>
  )
}

const Select = (props) => {
  const { search, selectFrom, label, onChange, defaultValue, single = false, disabled, isValid, helperText, value, limitChips,
          required = false, module, id, isLoadingSelect, error, items, hasMore, searchQuery, currentPageNumber, truncate = false } = props;

  const { t }          = useTranslation();
  const { state }      = useContext(AuthContext);
  const { locations }  = state.administrator;

  const { state: locationState } = useContext(LocationContext);

  const filteredArea = locationState.selectedArea ? locationState.selectedArea : [];

  const isLocationFilter = selectFrom === LOCATION_FILTER;
  const hasSingleLocation = label === LOCATION && locations.length === 1;

  const [selectedItem, setSelectedItem]           = useState(defaultValue ? defaultValue : []);
  const [filterArea, setFilterArea]               = useState(filteredArea);
  const [showOptions, setShowOptions]             = useState(false)
  const [tagIcon, setTagIcon]                     = useState(null);
  const [anchor, setAnchor]                       = useState(null);
  const [selectedAreas, setSelectedAreas]         = useState(isLocationFilter ? filteredArea : []);
  const [selectedLocations, setSelectedLocations] = useState(defaultValue ? defaultValue : []);

  useEffect(() => {
    switch (selectFrom) {
      case 'AccessPoints': return setTagIcon(<FontAwesomeIcon icon={faDoorOpen}/>)
      case 'Credentials' : return setTagIcon(<FontAwesomeIcon icon={faIdCardAlt}/>);
      case 'Profiles'    : return setTagIcon(<FontAwesomeIcon icon={faIdCard}/>);
      case 'Roles'       : return setTagIcon(<FontAwesomeIcon icon={faUserCog}/>);
      case 'Role'        : return setTagIcon(<FontAwesomeIcon icon={faUserCog}/>);
      case 'Schedules'   : return setTagIcon(<FontAwesomeIcon icon={faClock}/>);
      default            : return (<></>); // TODO: Add Tag Icon for Locations
    }
  }, [selectFrom])

  useEffect(() => {
    setSelectedLocations(defaultValue);
  }, [defaultValue])
 
  const callbackRef = useCallback(domNode => {
    if (domNode) setAnchor(domNode)
  }, []);

  const observer = useRef()
  const lastItemElement = useCallback(node => {
    if (isLoadingSelect) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        search(searchQuery, currentPageNumber + 1);
      }
    })
    if (node) observer.current.observe(node)
  }, [isLoadingSelect, hasMore, search, searchQuery, currentPageNumber] )

  useEffect(() => {
    setSelectedItem(defaultValue ? defaultValue : []);
  }, [defaultValue]);

  const handleSearch = (e) => {
    let query = e.target.value;
    search(query, 0);
  }

  const handleChange = (item) => {
    if (single) {
      if (selectedItem.some(value => value.id === item.id)) {
        onChange([]);
      } else {
        onChange([...new Set([ item ])]);
      }
    } else {
      const index = selectedItem.indexOf(item)

      if (selectedItem.some(value => value.name === item.name)) {
        onChange(selectedItem.filter(value => value.name !== item.name));
      }
      else {
        if (index > -1) {
          handleDelete(selectedItem.filter(value => value !== item));
        } else {
          onChange([...new Set([ ...selectedItem, item ])]);
        }
      }
    }
    if (single) {
      handleShowOptions();
    }
  };

  const handleShowOptions = useCallback(() => {
    if (!disabled && !hasSingleLocation){
      setShowOptions(prevState => !prevState);
    }
  }, [disabled, hasSingleLocation]);

  const handleLocationChange = (location, area) => {
    onChange(location, area);
    
    if (area) {
      setFilterArea(area);
    }

    if (isLocationFilter || single) {
      handleShowOptions();
    }
  };

  const handleDelete = (newValue) => {
    onChange(newValue);
  }

  return (
    <>
      <EnhancedInput
        id={id}
        handleDelete={handleDelete}
        handleShowOptions={handleShowOptions}
        label={`${t(label)}`}
        selectedItem={selectedItem}
        showOptions={showOptions}
        tagIcon={tagIcon}
        callbackRef={callbackRef}
        single={single}
        disabled= {disabled || hasSingleLocation}
        isValid={isValid}
        helperText={helperText}
        value={value}
        required={required}
        module={module}
        selectFrom={selectFrom}
        truncate={truncate}
        limitChips={limitChips}
        filterArea={filterArea}
        isLocationFilter={isLocationFilter}
      />
      {
        label === LOCATION ?
          <EnhancedListSelection
            id={id}
            name={selectFrom}
            anchor={anchor}
            disabled={disabled || hasSingleLocation}
            error={error}
            handleChange={handleLocationChange}
            handleSearch={handleSearch}
            isLoading={isLoadingSelect}
            items={items}
            lastItemElement={lastItemElement}
            query={searchQuery}
            selectFrom={selectFrom}
            setShowOptions={setShowOptions}
            showOptions={showOptions}
            single={single}
            module={module}
            isLocationFilter={isLocationFilter}
            selectedAreas={selectedAreas}
            selectedLocations={selectedLocations}
            setSelectedAreas={setSelectedAreas}
            setSelectedLocations={setSelectedLocations}
          />
        :
          <EnhancedSelection
            id={id}
            anchor={anchor}
            disabled={disabled || hasSingleLocation}
            error={error}
            handleChange={handleChange}
            handleSearch={handleSearch}
            isLoading={isLoadingSelect}
            items={items}
            lastItemElement={lastItemElement}
            query={searchQuery}
            selectedItem={selectedItem}
            selectFrom={selectFrom}
            setShowOptions={setShowOptions}
            showOptions={showOptions}
            single={single}
            module={module}
          />
      }
    </>
  )
}


export default Select;