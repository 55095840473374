import { makeStyles } from '@material-ui/core/styles';
import images from "../../utility/images";

const useStyles = makeStyles(theme => ({
	container: {
		paddingTop	 	: theme.spacing(4),
		paddingBottom	: theme.spacing(4),
	},
	root: {
		width	: '100%',
	},
  comingsoon: {
    paddingTop   : theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
	paper: {
		width			: '100%',
		marginBottom	: theme.spacing(2),
		marginTop		: theme.spacing(4),
	},
	image: {
		backgroundImage	  : `url(${images.COMING_SOON})`,
		backgroundPosition: 'center', 
		backgroundSize	  : 'contain', 
		backgroundRepeat  : 'no-repeat',
		height			  : '49vh',
    	width 			  : '81vh'
	},
	center: {
		alignItems		: 'center',
		display			: 'flex',
		fontSize  		: '5.5vh',
		justifyContent	: 'center',
	},
}))

export default useStyles;