import {
  Box,
  Container,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import axios from "axios";
import clsx from "clsx";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import DeleteModal from "../../components/delete-modal";
import SearchWithAction from "../../components/search-with-action";
import Title from "../../components/title";
import UnsavedModal from "../../components/unsaved-modal";
import { AuthContext } from "../../context/authContext";
import {
  DeleteAdministrator,
  DeleteMultipleAdministrators,
  GetAllAdministrators,
  SearchAdministrators,
  createOrDeleteAuthorizations
} from "../../service/administratorsApi";
import {
  // STATUS_ACTIVE,
  // STATUS_NEW,
  // STATUS_INACTIVE,
  // STATUS_DISABLE,
  // ADMINISTRATOR_ACTIVE,
  // ADMINISTRATOR_NEW,
  // ADMINISTRATOR_INACTIVE,
  // ADMINISTRATOR_DISABLE,
  // ADMINISTRATOR_BLOCKED,
  ACTION_CREATE,
  ACTION_DELETE,
  ACTION_EXIT,
  ACTION_UPDATE,
  ACTION_VIEW,
  ADMINISTRATORS,
  ADMINISTRATORS_MODULE,
  API_REQUEST_ERROR_MESSAGE,
  POST
} from "../../utility/constants";
import { formatAssociated, updateURLParams } from "../../utility/helper";
import { GetInitialLocationObject } from "../../utility/location";
import AdministratorsModal from "./administrators-modal";
import { AdministratorsSkeleton } from "./administrators-skeleton";
import useStyles from "./styles";

const Content = (props) => {
  const {
    handleItemClick,
    handleUpdateClick,
    selectedItem,
    totalElements,
    handleDelete,
    useSearch,
    query,
    setQuery,
    pageNumber,
    setPageNumber,
    setSelectedItem,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { id: selectedItemId } = selectedItem;

  return (
    <Grid container spacing={2} className={classes.form}>
      <Grid item xs={12}>
        <Box className={classes.administratorsContainer}>
          <Box className={classes.boxContainer}>
            <Typography color="secondary">
              {t("allAdministrators")} &#40;
              <span id="administratorsAllAdministratorsCount">{totalElements}</span>&#41;
            </Typography>
            <Typography className={"bold"} color="secondary">
              {t("actions")}
            </Typography>
          </Box>
          <SearchWithAction
            id="administrators"
            withSearch={true}
            handleItemClick={handleItemClick}
            selectedItem={selectedItemId}
            handleOpenDeleteModal={handleDelete}
            handleUpdateClick={handleUpdateClick}
            withChips={true}
            useSearch={useSearch}
            query={query}
            setQuery={setQuery}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            setSelectedItem={setSelectedItem}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

const Administrators = (props) => {
  const { t }      = useTranslation();
  const classes    = useStyles();
  const history    = useHistory();
  const { search } = history.location;

  const params = new URLSearchParams(search);
  const query  = params.get("keyword") ? params.get("keyword") : "";

  const { state: authState } = useContext(AuthContext);
  const { administrator }             = authState;

  const { showToaster, showLoading, handlePermissions } = props;

  const initialLocationObject = GetInitialLocationObject();

  const [ forDelete, setForDelete ]                                     = useState([]);
  const [ forDeleteName, setForDeleteName ]                             = useState([]);
  const [ isLoading, setIsLoading ]                                     = useState(false);
  const [ keyword, setKeyword ]                                         = useState(query);
  const [ page, setPage ]                                               = useState(0);
  const [ remove, setRemove ]                                           = useState(false);
  const [ administrators, setAdministrators ]                           = useState([]);
  const [ showModal, setShowModal ]                                     = useState(false);
  const [ totalAdministrators, setTotalAdministrators ]                 = useState(0);
  const [ type, setType ]                                               = useState("single");
  const [ assocValues, setAssocValues ]                                 = useState("");
  const [ title, setTitle ]                                             = useState("");
  const [ administratorModalOpenState, setAdministratorModalOpenState ] = useState(false);
  const [ selectedAdministrator, setSelectedAdministrator ]             = useState("");
  const [ actionType, setActionType ]                                   = useState("");
  const [ withModalChanges, setWithModalChanges ]                       = useState(false);
  const [ showUnsavedModal, setShowUnsavedModal ]                       = useState(false);
  const [ toRedirect, setToRedirect ]                                   = useState("");
  const [ isRedirect, setIsRedirect ]                                   = useState(false);
  const [ reload, setReload ]                                           = useState(false);
  const [ isDataLoading, setIsDataLoading ]                             = useState(false);
  const [ pageLoad, setPageLoad ]                                       = useState(true);
  const [ isShowLoading, setIsShowLoading ]                             = useState(false);
  const [ error, setError ]                                             = useState(false);
  const [ hasMore, setHasMore ]                                         = useState(false);
  const [ deleteTempValue, setDeleteTempValue ]                         = useState();
  const [ unsavedModalAction, setUnsavedModalAction ]                   = useState("");
  const [ locations, setLocations ]                                     = useState(initialLocationObject);

  const pathname = history.location.pathname;

  const urlParams = useMemo(() => ({
    keywordParam : keyword ? `keyword=${keyword}` : '',
    history      : history,
    pathname     : pathname
  }), [keyword, history, pathname]);

  const setLoadingType = useCallback(() => {
    if (pageLoad) {
      setIsLoading(true);
      setPageLoad(false);
    } else {
      setIsDataLoading(!remove);
    }
  }, [pageLoad, remove]);

  const getAdministrators = useCallback(async () => {
    setIsDataLoading(!remove);
    setTitle(ADMINISTRATORS);
    setLoadingType();
    setError(false);
    setSelectedAdministrator("");
    setAdministrators([]);
    setReload(false);

    let administrators = [];
    let administratorsCount = 0;
    const pageSize = 100; //default for keycloack
    const pageOffset = page * pageSize;
    const sort = `lastName, asc`;

    try {
      if (keyword) {
        const searchAdministrators = await SearchAdministrators(keyword, pageOffset, sort);
        administrators = searchAdministrators.administrators;
        administratorsCount = searchAdministrators.administratorsCount;
      } else {
        const allAdministrators = await GetAllAdministrators(pageOffset, sort);
        administrators = allAdministrators.administrators;
        administratorsCount = allAdministrators.administratorsCount;
      }

      //set pagination data
      const totalPages =
        administratorsCount < pageSize ? 1 : Math.ceil(administratorsCount / pageSize);
      setTotalAdministrators(administratorsCount);
      const updatedAdministrators = page === 0 ? administrators : administrators.concat(administrators);
      setAdministrators(updatedAdministrators);
      setHasMore(administratorsCount > 0 && page < totalPages - 1);
    } catch (administratorsError) {
      if (axios.isCancel(administratorsError)) {
        return;
      }
      setError(true);
      showToaster(t("error"), t(API_REQUEST_ERROR_MESSAGE), "error");
    } finally {
      setIsDataLoading(false);
      setIsShowLoading(false);
      setIsLoading(false);
    }
    return;
  }, [
    page,
    remove,
    keyword,
    setLoadingType,
    showToaster,
    t,
  ]);

  //TODO: display status
  // const getStatus = (value) => {
  //   if (value === STATUS_ACTIVE) {
  //     return ADMINISTRATOR_ACTIVE;
  //   } else if (value === STATUS_DISABLE) {
  //     return ADMINISTRATOR_DISABLE;
  //   } else if (value === STATUS_INACTIVE) {
  //     return ADMINISTRATOR_INACTIVE;
  //   } else if (value === STATUS_NEW) {
  //     return ADMINISTRATOR_NEW;
  //   } else {
  //     return ADMINISTRATOR_BLOCKED;
  //   }
  // };

  const useSearch = (keyword, page) => {
    return {
      isLoadingSelect: isDataLoading,
      error,
      items: administrators,
      hasMore,
    };
  };

  const handleDelete = (event, value) => {
    if (value.systemRoles.length > 0) {
      setAssocValues(
        formatAssociated(
          value.systemRoles.map((val) => {
            return ` ${val.name}`;
          }),
          t
        )
      );
      handleOpenDeleteModal(value, value.name, "singleWithAssociated");
    } else {
      handleOpenDeleteModal(value, value.name, "single");
    }
  };

  const singleDelete = async (value) => {
    setIsShowLoading(true);

    try {
      await createOrDeleteAuthorizations([], value.id);
      await DeleteAdministrator(value.id);

      setSelectedAdministrator("");
      setRemove(true);
      showToaster(
        t("success"),
        `${value.name} ${t("hasBeenDeleted")}`,
        "success"
      );
    } catch {
      showToaster(t("error"), t(API_REQUEST_ERROR_MESSAGE), "error");
      setRemove(true);
    } finally {
      setReload(true);
    }
  };

  const multipleDelete = async (values) => {
    const ids = values.map((value) => value.id);
    const administratorNames = values.map((value) => value.name);
    const lastAdministratorName = administratorNames.pop();
    try {
      await DeleteMultipleAdministrators(ids);
      setRemove(true);
      showToaster(
        t("success"),
        `${administratorNames.join(", ")}, ${t("and")} ${lastAdministratorName} ${t(
          "hasBeenDeleted"
        )}`,
        "success"
      );
    } catch {
      showToaster(t("error"), t(API_REQUEST_ERROR_MESSAGE), "error");
      setRemove(true);
    }
  };

  const handleOpenDeleteModal = (value, name, paramType) => {
    setForDelete(value);
    setForDeleteName(name);
    setType(paramType);
    setShowModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowModal(false);
  };

  const handleCreateAdministratorModal = () => {
    setActionType(ACTION_CREATE);
    setSelectedAdministrator('');
    setAdministratorModalOpenState(true);
  };

  const handleCloseAdministratorsModal = () => {
    setActionType("");
    setUnsavedModalAction("");
    setAdministratorModalOpenState(false);
    setSelectedAdministrator('');
    setLocations([]);
  };

  // handle unsaved modal state ***********************************************
  const handleCancelUnsavedModal = () => {
    setIsRedirect(false);
    setUnsavedModalAction("");
    setShowUnsavedModal(false);
    setToRedirect("");
  };

  const handleCloseUnsavedModal = () => {
    setShowUnsavedModal(false);
  };

  const handleSubmitUnsavedModal = async () => {
    //leaving page action
    if (isRedirect) {
      setWithModalChanges(false);
      setIsRedirect(false);
      history.push(toRedirect);
    }

    setShowUnsavedModal(false);
    setSelectedAdministrator('');

    //non leaving page actions
    if (unsavedModalAction === ACTION_CREATE) {
      //Proceed to open create administrator modal
      setUnsavedModalAction("");
      setActionType(ACTION_CREATE);
      setAdministratorModalOpenState(true);
    } else if (unsavedModalAction === ACTION_UPDATE) {
      //Proceed to open update administrator modal
      setUnsavedModalAction("");
      setActionType(ACTION_UPDATE);
      setAdministratorModalOpenState(true);
    } else if (unsavedModalAction === ACTION_DELETE) {
      //Proceed to open delete modal
      setUnsavedModalAction("");
      const temporaryDeleteValue = deleteTempValue;
      setDeleteTempValue("");
      if (temporaryDeleteValue.systemRoles.length > 0) {
        setAssocValues(
          formatAssociated(
            temporaryDeleteValue.systemRoles.map((val) => {
              return ` ${val.name}`;
            }),
            t
          )
        );
        handleOpenDeleteModal(
          temporaryDeleteValue,
          temporaryDeleteValue.name,
          "singleWithAssociated"
        );
      } else {
        handleOpenDeleteModal(
          temporaryDeleteValue,
          temporaryDeleteValue.name,
          "single"
        );
      }
    } else if (unsavedModalAction === ACTION_EXIT) {
      //Proceed to close administrator modal
      setUnsavedModalAction("");
      setActionType("");
      setAdministratorModalOpenState(false);
      setLocations([]);
    }
  };

  const handleSelectAdministrator = async (id) => {
    const foundAdministrator = administrators.find((administratorList) => administratorList.id === id);
    setSelectedAdministrator(foundAdministrator);
    setAdministratorModalOpenState(true);
    setActionType(ACTION_VIEW);
  };

  const handleUpdateAdministrator = async (administrator) => {
    setSelectedAdministrator(administrator);
    setActionType(ACTION_UPDATE);
    setAdministratorModalOpenState(true);
  };

  useEffect(() => {
    let delayDebounce;
    
    if (keyword && keyword !== '') {
      delayDebounce = setTimeout(() => {
        updateURLParams(urlParams);
        getAdministrators();
      }, 1000);
    } else {
      updateURLParams(urlParams);
      getAdministrators();
    }

    return () => {
      delayDebounce && clearTimeout(delayDebounce);
    };
  }, [page, keyword, urlParams, getAdministrators]);

  useEffect(() => {
    if (remove) {
      getAdministrators();
      setRemove(false);
    }
  }, [remove, getAdministrators]);

  useEffect(() => {
    if (page > 0) {
      setPage(0);
    } else {
      getAdministrators();
    }
  }, [reload, getAdministrators, page]);

  return (
    <Container maxWidth="xl" className={classes.container}>
      <UnsavedModal
        open={showUnsavedModal}
        onClose={handleCloseUnsavedModal}
        handleModalSubmit={handleSubmitUnsavedModal}
        handleModalCancel={handleCancelUnsavedModal}
        withRedirect={isRedirect}
      />
      <AdministratorsModal
        showToaster={showToaster}
        showLoading={showLoading}
        handleCloseAdministratorsModal={handleCloseAdministratorsModal}
        administratorModalOpenState={administratorModalOpenState}
        id={selectedAdministrator.id}
        actionType={actionType}
        setActionType={setActionType}
        setAdministratorModalOpenState={setAdministratorModalOpenState}
        withModalChanges={withModalChanges}
        setWithModalChanges={setWithModalChanges}
        setShowUnsavedModal={setShowUnsavedModal}
        setReload={setReload}
        setIsShowLoading={setIsShowLoading}
        isShowLoading={isShowLoading}
        setUnsavedModalAction={setUnsavedModalAction}
        selectedAdministrator={selectedAdministrator}
        handlePermissions={handlePermissions}
        setSelectedAdministrator={setSelectedAdministrator}
        handleUpdateClick={handleUpdateAdministrator}
        locations={locations}
        setLocations={setLocations}
      />
      <DeleteModal
        assocValues={assocValues}
        forDeleteName={forDeleteName}
        initialValues={forDelete}
        module={"Administrators"}
        multipleDelete={multipleDelete}
        onClose={handleCloseDeleteModal}
        open={showModal}
        showToaster={showToaster}
        singleDelete={singleDelete}
        type={type}
      />
      <Title title={t(title)} />
      <Box className={classes.iconBox}>
        <Tooltip
          title={t("create")}
          className={clsx(!handlePermissions(ADMINISTRATORS_MODULE, POST) && "hidden")}
        >
          <IconButton
            id="administratorsCreateAdministratorsButton"
            aria-label="Create"
            onClick={(_e) => handleCreateAdministratorModal()}
          >
            <AddIcon className={classes.iconButton} />
          </IconButton>
        </Tooltip>
      </Box>
      {isLoading ? (
        <AdministratorsSkeleton handlePermissions={handlePermissions} />
      ) : (
        <Content
          administrators={administrators}
          handleItemClick={handleSelectAdministrator}
          handleUpdateClick={handleUpdateAdministrator}
          selectedItem={selectedAdministrator}
          totalElements={totalAdministrators}
          useSearch={useSearch}
          query={keyword}
          setQuery={setKeyword}
          pageNumber={page}
          setPageNumber={setPage}
          handleDelete={handleDelete}
          isDataLoading={isDataLoading}
          error={error}
          setSelectedItem={setSelectedAdministrator}
          administrator={administrator}
          handlePermissions={handlePermissions}
          selectedAdministrator={selectedAdministrator}
        />
      )}
    </Container>
  );
};

export default Administrators;
