import DateFnsUtils from '@date-io/date-fns';
import { faCalendarDay, faClock, faDoorOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Container, Grid, IconButton, Paper, TextField, Tooltip, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import clsx from 'clsx';
import deLocale from "date-fns/locale/de";
import enLocale from 'date-fns/locale/en-US';
import { Form, Formik } from 'formik';
import cookies from 'js-cookie';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory } from 'react-router';
import Chips from '../../components/chips';
import DatePicker from '../../components/date-picker';
import SelectItems from '../../components/select-items';
import Title from '../../components/title';
import UnsavedModal from '../../components/unsaved-modal';
import { AuthContext } from '../../context/authContext';
import { LocationContext } from '../../context/locationContext';
import { getAccessPointByProfileId } from '../../service/accessPointsApi';
import api from '../../service/api';
import { request } from '../../service/requests';
import { ACCESS_POINTS_MODULE, ACTION_VIEW, API_REQUEST_ERROR_MESSAGE, CHIP_COLOR, DATE_FORMAT_YYYY_MM_DD, GET, HOLIDAYS_MODULE, LANGUAGE_DE, LANGUAGE_EN, LOCATIONS_MODULE, MAX_CHARACTER_LIMIT, PATCH, POST, PROFILES_MODULE, PROJECTION, PUT, SCHEDULES_MODULE } from '../../utility/constants';
import { GetInitialLocationId, GetInitialLocationObject, assignLocation, getLocation, hasLocationChange, unassignLocation } from '../../utility/location';
import { convertToUTC } from '../../utility/helper';
import { profileSchema } from '../../validation/schema';
import { ProfileSkeleton } from './profile-skeleton';
import useStyles from './styles';

const Content = (props) => {
  const classes = useStyles();
  const { t }   = useTranslation();
  const { path, initialValues, handleSubmit, id, disabled, setValidFrom, setValidUntil, setName, setDescription, handleSelectChange, selectedSchedules, selectedAccess, selectedHolidays, showToaster, setIsNotValid, showLoading, handleCancel, handleSelectedLocation, selectedLocation, handlePermissions } = props;

  const isViewPage = path.includes(ACTION_VIEW);

  const getButtonLabel = () => {
    return id ? `${t('update')}` : `${t('create')}`;
  }

  const handleChange = (setter, formik) => {
    return(setter, formik);
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={profileSchema}
      onSubmit={handleSubmit}
      >
      {
      formik => {
        return (
          <Form>
            {showLoading(formik.isSubmitting)}
            <Paper className={classes.paper} elevation={3}>
              <Grid id="profileContainer" container spacing={2} className={classes.grid}>
                <Grid item xs={12} className={classes.gridItem}>
                  <TextField
                    inputProps={{
                      readOnly : disabled,
                      maxlength: MAX_CHARACTER_LIMIT.TEXT_FIELD
                    }}
                    disabled={disabled}
                    fullWidth
                    multiline
                    id="profileName"
                    label="Name*"
                    name="name"
                    value={formik.values.name}
                    onChange={e => handleChange(setName(e.target.value), formik.handleChange(e))}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={t(formik.touched.name) && t(formik.errors.name)}
                  />
                </Grid>
                <Grid item xs={12} className={classes.gridItem}>
                  <TextField
                    inputProps={{
                      readOnly : disabled,
                      maxlength: MAX_CHARACTER_LIMIT.TEXT_FIELD
                    }}
                    disabled={disabled}
                    fullWidth
                    id="profileDescription"
                    label={t('description')}
                    placeholder={t('noDescription')}
                    name="description"
                    rows={6}
                    value={formik.values.description}
                    onChange={e => handleChange(setDescription(e.target.value), formik.handleChange(e))}
                    multiline
                  />
                  {
                    !isViewPage &&
                      <span
                        className={formik.errors.description && formik.touched.description ? classes.characterLimitError : classes.characterLimit}>
                        { 
                          t('controller-page.characterLimit', { 
                            currentCharacterCount: formik.values.description.length, maxCharacterCount: MAX_CHARACTER_LIMIT.TEXT_FIELD 
                          })
                        }
                      </span>
                  }
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <DatePicker
                    name="profileValidFrom"
                    disabled={disabled}
                    label={t('validFrom')}
                    until={formik.values.validUntil}
                    value={formik.values.validFrom}
                    handleChange={setValidFrom}
                    touched={formik.touched.validFrom}
                    error={formik.errors.validFrom}
                    setIsNotValid={setIsNotValid}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <DatePicker
                    name="profileValidUntil"
                    disabled={disabled}
                    label={t('validUntil')}
                    min={formik.values.validUntil}
                    from={formik.values.validFrom}
                    value={formik.values.validUntil}
                    handleChange={setValidUntil}
                    touched={formik.touched.validUntil}
                    error={formik.errors.validUntil}
                    setIsNotValid={setIsNotValid}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} className={clsx((!handlePermissions(SCHEDULES_MODULE, PUT) || disabled) && 'hidden')}>
                    <SelectItems
                      id="profileSchedules"
                      onChange={event => handleSelectChange(event, 'schedules')}
                      name="Schedules"
                      showToaster={showToaster}
                      selectedItems={selectedSchedules}
                      disabled={disabled}
                      handlePermissions={handlePermissions}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={6} className={clsx((!handlePermissions(HOLIDAYS_MODULE, PUT) || disabled) && 'hidden')}>
                    <SelectItems
                      id="profileHolidays"
                      onChange={event => handleSelectChange(event, 'holidays')}
                      name="Holidays"
                      showToaster={showToaster}
                      selectedItems={selectedHolidays}
                      disabled={disabled}
                      handlePermissions={handlePermissions}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={6} className={clsx((!handlePermissions(ACCESS_POINTS_MODULE, PUT) || disabled) && 'hidden')}>
                  <SelectItems
                    id="profileAccessPoints"
                    onChange={event => handleSelectChange(event, 'access')}
                    name="Access Points"
                    showToaster={showToaster}
                    selectedItems={selectedAccess}
                    disabled={disabled}
                    handlePermissions={handlePermissions}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} className={clsx(!handlePermissions(LOCATIONS_MODULE, GET) && 'hidden')}>
                  <SelectItems
                    id="profileLocation"
                    disabled={disabled}
                    onChange={handleSelectedLocation}
                    name="Locations"
                    showToaster={showToaster} 
                    selectedItems={selectedLocation}
                    single={true}
                    isValid={formik.touched.location && Boolean(formik.errors.location)}
                    helperText={t(formik.touched.location) && t(formik.errors.location)}
                    required={true}
                    handlePermissions={handlePermissions}
                  />
                </Grid>
              </Grid>
              <Grid className={clsx(disabled ? 'hidden' : classes.action)}>
                <Button
                  id="profileCancelButton"
                  onClick={handleCancel}
                  variant="outlined"
                  color="primary"
                >
                  {t('cancel')}
                </Button>
                <Button
                  id="profileSubmitButton"
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  { getButtonLabel() }
                </Button>
              </Grid>
            </Paper>
          </Form>
        )
      }}
    </Formik>
  )
}

const Profile = (props) => {
  const { t }                                       = useTranslation();

  const { showToaster, showLoading, handlePermissions } = props;
  const path                                        = props.match.path.replace('profiles/','');
  const { id }                                      = props.match.params;

  const classes                                     = useStyles();
  const history                                     = useHistory();
  
  const { state : authState }                       = useContext(AuthContext);
  const { defaultValidFrom, defaultValidUntil }     = authState;
  const { administrator }                           = authState;
  
  const { state }  = useContext(LocationContext);

  const initialLocationId = GetInitialLocationId();
  const initialLocationObject = GetInitialLocationObject();

  const [name, setName]                             = useState('');
  const [description, setDescription]               = useState('');
  const [validFrom, setValidFrom]                   = useState(defaultValidFrom);
  const [validUntil, setValidUntil]                 = useState(defaultValidUntil);
  const [isLoading, setIsLoading]                   = useState(false);
  const [schedules, setSchedules]                   = useState([]);
  const [holidays, setHolidays]                     = useState([]);
  const [accessPoints, setAccessPoints]             = useState([]);
  const [group]                                     = useState([]);
  const [isNotValid, setIsNotValid]                 = useState(false);
  const [showModal, setShowModal]                   = useState(false);
  const [prevValues, setPrevValues]                 = useState({});
  const [withChanges, setWithChanges]               = useState(false);
  const [toRedirect, setToRedirect]                 = useState('');
  const [prevSchedules, setPrevSchedules]           = useState([]);
  const [prevHolidays, setPrevHolidays]             = useState([]);
  const [prevAccessPoints, setPrevAccessPoints]     = useState([]);
  const [location, setLocation]                     = useState('');
  const [selectedLocation, setSelectedLocation]     = useState(initialLocationId);
  const [objectLocation, setObjectLocation]         = useState(initialLocationObject);
  
  const language = cookies.get('i18next') || LANGUAGE_EN;

  const initialValues = useMemo(() => {
    return {
      name          : name,
      description   : description,
      validFrom     : validFrom,
      validUntil    : validUntil,
      location      : selectedLocation || selectedLocation === undefined ? selectedLocation : location
    }
  }, [name, description, validFrom, validUntil, selectedLocation, location]);

  const handleCloseModal = () => {
    setShowModal(false);
  }

  const handleCancel = () => {
    history.push('/profiles');
  }

  const handleModalSubmit = () => {
    setWithChanges(false);
    history.push(toRedirect);
  }

  const handleModalCancel = () => {
    handleChanges();
    setShowModal(false);
  }

  const getProfile = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await request({
        url     : `${api.PROFILES}/${id}`,
        method  : GET,
        params  : {
          projection: PROJECTION.PROFILE
        }
      });
      
      const { data } = response;
      
      setName(data.name);
      setDescription(data.description);
      setValidFrom(convertToUTC(data.validFrom).format(DATE_FORMAT_YYYY_MM_DD));
      setValidUntil(convertToUTC(data.validUntil).format(DATE_FORMAT_YYYY_MM_DD));
      setPrevValues({
        name          : data.name,
        description   : data.description,
        validFrom     : convertToUTC(data.validFrom).format(DATE_FORMAT_YYYY_MM_DD),
        validUntil    : convertToUTC(data.validUntil).format(DATE_FORMAT_YYYY_MM_DD),
      });
      
      getLocation(data, setLocation, setObjectLocation);

      const accessPointResponse = await getAccessPointByProfileId(id);
      getAccessPointData(accessPointResponse);

      getScheduleData(data.schedules);

      getHolidayData(data.holidays);
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setIsLoading(false);
    }
  }, [id, showToaster, t]);

  const getAccessPointData = (accessPoints) => {
    if (!accessPoints) {
      return;
    }

    const accessPointData = accessPoints.map(item => {
      return {
        name         : `${item.controller.name}:${item.name}`,
        link         : `${api.ACCESS_POINTS}/${item.id}`,
        id           : item.id,
        controllerId : item.controllerId,
        serialNumber : item.controller.serialNumber
      }
    });  

    setAccessPoints(accessPointData);
    setPrevAccessPoints(accessPointData);
  }

  const getScheduleData = (schedules) => {
    if (schedules.length === 0) {
      return;
    }

    const formattedSchedules = schedules.map(schedule => {
      return {
        id      : schedule.scheduleId,
        name    : schedule.name,
      }
    });

    setSchedules(formattedSchedules);
    setPrevSchedules(formattedSchedules);
  }

  const getHolidayData = (holidays) => {
    if (holidays.length === 0) {
      return;
    }

    const formattedHolidays = holidays.map(holiday => {
      return {
        id   : holiday.holidayId,
        name : holiday.name,
      }
    });

    setHolidays(formattedHolidays);
    setPrevHolidays(formattedHolidays);
  }

  const getToastMessage = () => {
    return id ? t('updated') : t('created');
  }

  const handleSelectChange = (newItems, module) => {
    switch (module) {
      case 'access':
        return setAccessPoints(newItems ? newItems : accessPoints);
      case 'holidays':
        return setHolidays(newItems ? newItems : holidays);
      default:
        return setSchedules(newItems ? newItems : schedules);
    }
  }

  const handleSubmit = async (values, formik) => {
    const { setErrors, setSubmitting} = formik;
    if (isNotValid) {
      setSubmitting(false);
      return;
    }

    if (!withChanges) {
      setSubmitting(false);
      setWithChanges(false);
      history.push('/profiles');
      return;
    }

    try {
      const response = await request({
        url    : id ? `${api.PROFILES}/${id}`: api.PROFILES,
        method : id ? PATCH                  : POST,
        data   : values,
      });

      await assignLocationToProfile(response.data._links.self.href);

      await syncController(response);

      showToaster(t('success'), `${ values.name } ${t('hasBeen')} ${ getToastMessage() }`, 'success');
      
      setSubmitting(false); 
      setWithChanges(false);
      history.push('/profiles');
    } catch (error) {
      if (error?.response?.status === 409) {
        setErrors({
          name: t(`nameAlreadyExists`)
        });
      } else {
        showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
      }
      setWithChanges(true);
    }
  }

  const syncController = async (response) => {
    if (!hasProfileSyncChange()) {
      return;
    }

    const data      = response.data;
    const url       = data._links.self.href;
    const profileId = url.replace(`${api.PROFILES}/`,'');

    // Serial Number
    const previousSerialNumber = prevAccessPoints.map(accessPoint => accessPoint.serialNumber);
    const currentSerialNumber  = accessPoints.map(accessPoint => accessPoint.serialNumber);

    const filteredPreviousSerialNumber = [...new Set(previousSerialNumber)];
    const filteredCurrentSerialNumber = [...new Set(currentSerialNumber)];

    const addedSerialNumber   = filteredCurrentSerialNumber.filter(currentId => !filteredPreviousSerialNumber.includes(currentId));

    // Access Points
    const previousAPIds = prevAccessPoints.map(accessPoint => accessPoint.id);
    const currentAPIds  = accessPoints.map(accessPoint => accessPoint.id);

    const addedAPIds    = currentAPIds.filter(currentId => !previousAPIds.includes(currentId));
    const deletedAPIds  = previousAPIds.filter(previousId => !currentAPIds.includes(previousId));

    // Schedules
    const previousScheduleIds = prevSchedules.map(schedule => schedule.id);
    const currentScheduleIds  = schedules.map(schedule => schedule.id);

    const addedScheduleIds    = currentScheduleIds.filter(currentId => !previousScheduleIds.includes(currentId));
    const deletedScheduleIds  = previousScheduleIds.filter(previousId => !currentScheduleIds.includes(previousId));

    // Holidays
    const previousHolidayIds = prevHolidays.map(holiday => holiday.id);
    const currentHolidayIds  = holidays.map(holiday => holiday.id);

    const addedHolidayIds    = currentHolidayIds.filter(currentId => !previousHolidayIds.includes(currentId));
    const deletedHolidayIds  = previousHolidayIds.filter(previousId => !currentHolidayIds.includes(previousId));

    //unsync delected serialnumber
    unsyncProfile(profileId, previousScheduleIds, previousHolidayIds);
    
    //check if there's current serial number
    if (!checkLength(filteredCurrentSerialNumber)) {
      await assignScheduleAndHoliday(profileId);
      return;
    }
                         
    //serial numbers then map
    for await (const serialNumber of filteredCurrentSerialNumber) {
      
      if (!checkLength(deletedHolidayIds) && !checkLength(deletedScheduleIds) && !checkLength(addedHolidayIds) && !checkLength(addedScheduleIds) && !checkLength(addedAPIds) && !checkLength(deletedAPIds) ) {
        //sync only if theres no changes on schedule, holiday, and access points but profile data
        await syncRequest(`${api.PROFILES}/${profileId}/syncProfiles`, AccessPointScheduleHolidayDTO(currentAPIds, currentScheduleIds, currentHolidayIds, serialNumber));
        continue;
      }

      await unsyncHolidays(deletedHolidayIds, profileId, serialNumber, previousSerialNumber);

      //unsync deleted schedule per serial number if included in previous serial numbers
      await unsyncSchedules(deletedScheduleIds, profileId, serialNumber, previousSerialNumber);
      
      // if serial is in added serial numbers, get current holidays
      await syncHolidays(addedHolidayIds, profileId, serialNumber, addedSerialNumber, currentHolidayIds);

      // if serial is in added serial numbers, get current schedules
      await syncSchedules(addedScheduleIds, profileId, serialNumber, addedSerialNumber, currentScheduleIds);

      const serialCurrentAPs = accessPoints.filter(accessPoint => accessPoint.serialNumber === serialNumber);
      const serialCurrentAPIds = serialCurrentAPs.map(accessPoint => accessPoint.id);

      const serialPreviousAPs = prevAccessPoints.filter(accessPoint => accessPoint.serialNumber === serialNumber);
      const serialPreviousAPIds = serialPreviousAPs.map(accessPoint => accessPoint.id);
      
      // sync profile  get all current
      if (checkLength(deletedHolidayIds) || checkLength(deletedScheduleIds) || checkLength(addedHolidayIds) || checkLength(addedScheduleIds)) {
        await syncRequest(`${api.PROFILES}/${profileId}/syncProfiles`, AccessPointScheduleHolidayDTO(serialCurrentAPIds, currentScheduleIds, currentHolidayIds, serialNumber));
        continue;
      }
      
      if (checkLength(addedAPIds) || checkLength(deletedAPIds)) {
        const isAdded = addedAPIds.find(addedAPId => serialCurrentAPIds.includes(addedAPId));
        const isDeleted = deletedAPIds.find(deletedAPId => serialPreviousAPIds.includes(deletedAPId));
                
        if (isAdded || isDeleted) {
          await syncRequest(`${api.PROFILES}/${profileId}/syncProfiles`, AccessPointScheduleHolidayDTO(serialCurrentAPIds, currentScheduleIds, currentHolidayIds, serialNumber));
        }
      }
    }
  }

  const unsyncProfile = async (profileId, previousScheduleIds, previousHolidayIds) => {
    const url = `${api.PROFILES}/${profileId}/unsyncProfiles`;
    
    const previousSerialNumber = prevAccessPoints.map(accessPoint => accessPoint.serialNumber);
    const currentSerialNumber  = accessPoints.map(accessPoint => accessPoint.serialNumber);

    const filteredCurrentSerialNumber = [...new Set(currentSerialNumber)];
    const filteredPreviousSerialNumber = [...new Set(previousSerialNumber)];
    
    const deletedSerialNumber          = filteredPreviousSerialNumber.filter(previousId => !filteredCurrentSerialNumber.includes(previousId));

    if (checkLength(deletedSerialNumber)) {
      for await (const serialNumber of deletedSerialNumber) {
        const accessPoints = prevAccessPoints.filter(ap => ap.serialNumber === serialNumber).map(ap => ap.id);
        await syncRequest(url, AccessPointScheduleHolidayDTO(accessPoints, previousScheduleIds, previousHolidayIds, serialNumber));
      }
    }
  }

  const unsyncHolidays = async (deletedHolidayIds, profileId, serialNumber, previousSerialNumber) => {
    const url        = `${api.PROFILES}/${profileId}/unsyncHolidays`;
    const holidayDto = HolidayDTO(deletedHolidayIds, serialNumber);

    if (checkLength(deletedHolidayIds) && previousSerialNumber.includes(serialNumber)) {
      await syncRequest(url, holidayDto);
    }
  }

  const unsyncSchedules = async (deletedScheduleIds, profileId, serialNumber, previousSerialNumber) => {
    const url         = `${api.PROFILES}/${profileId}/unsyncSchedules`;
    const scheduleDto = ScheduleDTO(deletedScheduleIds, serialNumber);

    if (checkLength(deletedScheduleIds) && previousSerialNumber.includes(serialNumber)) {
      await syncRequest(url, scheduleDto);
    }
  }
  
  const syncHolidays = async (addedHolidayIds, profileId, serialNumber, addedSerialNumber, currentHolidayIds) => {
    const url        = `${api.PROFILES}/${profileId}/syncHolidays`;
    const holidayDto = HolidayDTO(addedSerialNumber.includes(serialNumber) ? currentHolidayIds : addedHolidayIds, serialNumber);

    if (checkLength(addedHolidayIds) || addedSerialNumber.includes(serialNumber)) {
      await syncRequest(url, holidayDto);
    }
  }

  const syncSchedules = async (addedScheduleIds, profileId, serialNumber, addedSerialNumber, currentScheduleIds) => {
    const url         = `${api.PROFILES}/${profileId}/syncSchedules`;
    const scheduleDto = ScheduleDTO(addedSerialNumber.includes(serialNumber) ? currentScheduleIds : addedScheduleIds, serialNumber);

    if (checkLength(addedScheduleIds) || addedSerialNumber.includes(serialNumber)) {
      await syncRequest(url, scheduleDto);
    }
  }

  const assignScheduleAndHoliday = async (profileId) => {
    const scheduleUrls = schedules.map(schedule => `${api.SCHEDULES}/${schedule.id}`).join(`\r\n`);

    await request({
      url: `${api.PROFILES}/${profileId}/schedules`,
      method: PUT,
      headers: {
        'Content-Type': 'text/uri-list',
      },
      data: scheduleUrls
    });

    const holidayUrls = holidays.map(holiday => `${api.HOLIDAYS}/${holiday.id}`).join(`\r\n`);

    await request({
      url: `${api.PROFILES}/${profileId}/holidays`,
      method: PUT,
      headers: {
        'Content-Type': 'text/uri-list',
      },
      data: holidayUrls
    });
  }

  const AccessPointScheduleHolidayDTO = (accessPointIds, scheduleIds, holidayIds, serialNumber) => {
    return {
      accessPointIds : accessPointIds,
      scheduleIds    : scheduleIds,
      holidayIds     : holidayIds,
      serialNumber   : serialNumber
    }
  }

  const ScheduleDTO = (scheduleIds, serialNumber) => {
    return {
      scheduleIds    : scheduleIds,
      serialNumber   : serialNumber
    }
  }

  const HolidayDTO = (holidayIds, serialNumber) => {
    return {
      holidayIds    : holidayIds,
      serialNumber  : serialNumber
    }
  }

  const checkLength = (array) => {
    return Boolean(array.length);
  }

  const syncRequest = (url, data) => {
    return request({
      url    : url,
      method : PUT,
      data   : data
    });
  }
  
  const initialValidUntil = (value) => {
    if(value){
      if(value.toString().includes('.')){
        return value;
      } else {
        return (value) ? moment(value).format(DATE_FORMAT_YYYY_MM_DD) : '';
      }
    } else {
      return value
    }
  }

  const checkValues = (preValues, newValues) => {
    const removeAPIString = (string) => {
      return parseInt((string).replace(`${api.SCHEDULES}/`,'').replace(`${api.ACCESS_POINTS}/`,'').replace(`${api.HOLIDAYS}/`,'').replace('{?projection}', ''));
    }
    
    return preValues.map(prevValue => {
      return newValues.some(newValue => {
        let newId = (newValue?.link) ? removeAPIString(newValue.link) : newValue.id;
        let prevId  = (prevValue?.link) ? removeAPIString(prevValue.link) : prevValue.id;
        return (newId === prevId);
      });
    });
  }
  
  const checkChipsValue = useCallback((values, newValues) => {
    if (values.length > newValues.length) {
      return checkValues(values, newValues);
    } else {
      return checkValues(newValues, values);
    }
  }, [])

  // Return true when profile details has changes
  const isProfileChange = useCallback(() => {
    const checkChips = (current, previous) => checkChipsValue(current, previous);
  
    const checkSchedules = checkChips(schedules, prevSchedules);
    const checkAccessPoints = checkChips(accessPoints, prevAccessPoints);
    const checkHolidays = checkChips(holidays, prevHolidays);
  
    const isNameChanged = prevValues.name !== initialValues.name;
    const isDescriptionChanged = prevValues.description !== initialValues.description;
    const isValidUntilChanged = prevValues.validUntil !== initialValidUntil(initialValues.validUntil);
    const isValidFromChanged = 
      initialValidUntil(prevValues.validFrom) !== moment(initialValues.validFrom).format(DATE_FORMAT_YYYY_MM_DD);
  
    const areSchedulesChanged = checkSchedules.every(sched => sched);
    const areAccessPointsChanged = checkAccessPoints.every(access => access);
    const areHolidaysChanged = checkHolidays.every(calendar => calendar);
  
    return (
      isNameChanged ||
      isDescriptionChanged ||
      isValidUntilChanged ||
      isValidFromChanged ||
      !areSchedulesChanged ||
      !areAccessPointsChanged ||
      !areHolidaysChanged
    );
  }, [
    accessPoints,
    holidays,
    initialValues,
    prevAccessPoints,
    prevHolidays,
    prevSchedules,
    prevValues,
    schedules,
    checkChipsValue
  ]);

  // Return true when any of the following has changes
  // name, validUntil, validFrom, holidays, schedules, and access points
  const hasProfileSyncChange = () => {
    const checkChips = (current, previous) => checkChipsValue(current, previous);
  
    const checkSchedules = checkChips(schedules, prevSchedules);
    const checkAccessPoints = checkChips(accessPoints, prevAccessPoints);
    const checkHolidays = checkChips(holidays, prevHolidays);
  
    const isNameChanged = prevValues.name !== initialValues.name;
    const isValidUntilChanged = prevValues.validUntil !== initialValidUntil(initialValues.validUntil);
    const isValidFromChanged = 
      initialValidUntil(prevValues.validFrom) !== moment(initialValues.validFrom).format(DATE_FORMAT_YYYY_MM_DD);
  
    const areSchedulesChanged = checkSchedules.every(sched => sched);
    const areAccessPointsChanged = checkAccessPoints.every(access => access);
    const areHolidaysChanged = checkHolidays.every(calendar => calendar);
  
    return (
      isNameChanged ||
      isValidUntilChanged ||
      isValidFromChanged ||
      !areSchedulesChanged ||
      !areAccessPointsChanged ||
      !areHolidaysChanged
    );
  };

  const handleChanges = useCallback(() => {
    const hasChanges = isProfileChange() || 
      hasLocationChange(location, objectLocation[0]?.locationId, administrator.locations.length, state);
    
      setWithChanges(hasChanges);
  }, [location, administrator, objectLocation, isProfileChange, state]);

  useEffect(() => {
    handleChanges();
  }, [handleChanges]);

  const handleSelectedLocation = (value) => {
    setSelectedLocation(value[0]?.locationId);
    setObjectLocation(value);
  }

  const assignLocationToProfile = async (link) => {
    if (location === objectLocation[0]?.locationId) {
      return;
    }

    if (location && selectedLocation) {
      await unassignLocation(link);
    }

    await assignLocation(link, selectedLocation);
  }

  useEffect(() => {
    setPrevValues({
      name          : '',
      description   : '',
      validFrom     : defaultValidFrom,
      validUntil    : defaultValidUntil
    });

    if (id) {
      getProfile();
    }
  }, [getProfile, id, defaultValidFrom, defaultValidUntil]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={language === LANGUAGE_DE ? deLocale : enLocale}>
      <Container maxWidth="xl" className={classes.container}>
        <Prompt
          when={withChanges}
          message={(location, action) => {
            if (action === 'PUSH') {
              setShowModal(true);
            }
            setWithChanges(false);
            setToRedirect(location.pathname);
            return location.pathname === '/' || location.pathname === '/profiles/update' || location.pathname === '/profiles/create'
          }}
        />
        <UnsavedModal
          open={showModal}
          onClose={handleCloseModal}
          handleModalSubmit={handleModalSubmit}
          handleModalCancel={handleModalCancel}
        />
        <Title title={t('profiles')} subtitle={`${name}`}/>
        <Box className={classes.details} fullWidth>
          <Grid item className={classes.detailsHeader}>
            <Typography className={'bold'} color="secondary">{t('details')}</Typography>
            <Tooltip title={t('update')}  className={(path.includes('view') && handlePermissions(PROFILES_MODULE, PATCH)) ? '' : 'hidden'}>
              <IconButton id="profileUpdateButton" aria-label="Update" onClick={() => history.push(`../update/${id}`)}>
                <Edit className={classes.iconButton} />
              </IconButton>
            </Tooltip>
          </Grid>
          {
            isLoading ?
              <ProfileSkeleton id={id} disabled={path.includes('view')} handlePermissions={handlePermissions}/>
            :
              <Content
                id={id}
                path={path}
                disabled={path.includes('view')}
                initialValues={initialValues}
                handleCancel={handleCancel}
                handleSubmit={handleSubmit}
                setValidFrom={setValidFrom}
                setValidUntil={setValidUntil}
                setName={setName}
                handleSelectChange={handleSelectChange}
                handleSelectedLocation={handleSelectedLocation}
                selectedLocation={objectLocation}
                setDescription={setDescription}
                selectedSchedules={schedules}
                selectedHolidays={holidays}
                selectedAccess={accessPoints}
                selectedGroup={group}
                showToaster={showToaster}
                showLoading={showLoading}
                setIsNotValid={setIsNotValid}
                handlePermissions={handlePermissions}
              />
          }
          <Grid container spacing={2} className={path.includes('view') ? classes.view : 'hidden'}>
            <Grid item xs={12} md={6} lg={4} className={clsx(handlePermissions(SCHEDULES_MODULE, PUT) ? '' : 'hidden')}>
              <Chips
                id="profileSchedulesPanel"
                color={CHIP_COLOR.LIGHT}
                icon={<FontAwesomeIcon icon={faClock} size="lg" />}
                header={t('schedules')}
                data={schedules}
                isLoading={isLoading}
                type={'Schedules'}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} className={clsx(handlePermissions(HOLIDAYS_MODULE, PUT) ? '' : 'hidden')}>
              <Chips
                id="profileHolidaysPanel"
                color={CHIP_COLOR.LIGHT}
                icon={<FontAwesomeIcon icon={faCalendarDay} size="lg" />}
                header={t('holidays')}
                data={holidays}
                isLoading={isLoading}
                type={'Holidays'}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} className={clsx(handlePermissions(ACCESS_POINTS_MODULE, PUT) ? '' : 'hidden')}>
              <Chips
                id="profileAccessPointsPanel"
                color={CHIP_COLOR.LIGHT}
                icon={<FontAwesomeIcon icon={faDoorOpen} size="lg" />}
                header={t('accessPoints')}
                data={accessPoints}
                isLoading={isLoading}
                type={'Access Points'}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </MuiPickersUtilsProvider>
  );
}

export default Profile;