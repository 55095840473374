import { createContext, useReducer } from "react";
import { credentialInitialState, WizardCredentialReducer } from "../reducer/wizardCredentialReducer";

export const WizardCredentialContext = createContext();

const WizardCredentialProvider = ({ children }) => {
  const [state, dispatch] = useReducer(WizardCredentialReducer, credentialInitialState);

  return (
    <WizardCredentialContext.Provider value={{ state, dispatch }}>
      {children}
    </WizardCredentialContext.Provider>
  )
}
export default WizardCredentialProvider;