import { TableBody, TableCell, TableRow } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

const SkeletonCol = (props) => {
  const { x, y } = props;

  return (
    <TableCell key={'empty-' + x + '-' + y}>
      <Skeleton variant='text' />
    </TableCell>
  )
}

const SkeletonRow = (props) => {
  const { columns, rowsPerPage } = props;

  const skeletonColumns = columns.filter(column => !column.hidden);
  const columnKeys      = skeletonColumns.map(column => column.id)

  return (
    <TableBody>
      {
        [...Array(rowsPerPage)].map((row, x) =>
          < TableRow key={'empty-tr-' + row + x} >
            {
              columnKeys.map((_, y) =>
                <SkeletonCol
                  key={y}
                  x={x}
                  y={y}
                />
              )
            }
          </TableRow >
        )
      }
    </TableBody>
  )
}

export default SkeletonRow;