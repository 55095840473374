import { lighten, makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  chartContainer: {
    borderRadius    : '4px',
    boxShadow       : theme.shadows[5],
    maxWidth        : '100%',
    minWidth        : '100%',
    height          : '100%',
    padding         : theme.spacing(0),
    '& .highcharts-credits': {
      display       : 'none'
    },
    '& .highcharts-axis-title': {
      fill          : colors.PRIMARY_TEXT +'!important',
      fontSize      : '14px'
    },
    '& .highcharts-legend-item': {
      '& b': {
        fontSize        : '16px !important',
        textTransform   : 'capitalize'
      },
    }
  },
  paper: {
    height          : '100%',
    padding         : theme.spacing(2),
  },
  chipDetailsContainer: {
    height   : 'calc(100% + 24px)',
    minWidth : '33%'
  },
  chartName: {
    borderBottom    : '3px solid' + colors.PRIMARY,
    paddingTop      : theme.spacing(1),
    paddingBottom   : theme.spacing(2),
    '& .MuiTypography-root': {
      fontSize        : theme.spacing(2.5),
      fontWeight      : 'bold',
    }
  },
  header: {
    display         : 'flex',
    alignItems      : 'center',
    justifyContent  : 'space-between',
    marginRight     : '-16px',
  },
  chipDetailsNumberCount: {
    display         : 'flex',
    alignItems      : 'center',
    justifyContent  : 'flex-start',
    paddingRight    : theme.spacing(5),
  },
  chipListContainer: {
    maxHeight       : '620px',
    minHeight       : '620px',
    overflowY       : 'scroll',
  },
  chipDetailsTable: {
    maxHeight       : '230px',
    minHeight       : '230px',
  },
  chipDetailsContent: {
    marginTop : '15px'
  },
  paperEnhancedList: {
    paddingTop: theme.spacing(1),
  },
  loading: {
    marginLeft: theme.spacing(-0.5),
    marginTop : theme.spacing(1.5),
  },
  items: {
    display      : 'flex',
    flexDirection: 'column',
    height       : '25vh',
    marginBottom : theme.spacing(2),
    overflowX    : 'hidden',
    overflowY    : 'auto',
    paddingLeft  : theme.spacing(1.5),
    paddingRight : theme.spacing(1),
  },
  itemBox: {
    alignItems: 'center',
    cursor    : 'pointer',
    fontWeight: 400,
    fontSize  : '1rem',
  },
  hoverBold: {
    '&:hover' : {
      cursor          : 'pointer',
      backgroundColor : lighten(theme.palette.primary.main, 0.85),
      color           : 'black'
    }
  },
}));