import { GET, PATCH } from "../utility/constants";
import api from "./api";
import { request } from "./requests";

export const getValidityDateConfigurations = async () => {
  const response = await request({
    url: api.VALIDITY_DATE_CONFIGURATIONS,
    method: GET
  });

  return response.data[0];
}

export const updateValidityDateConfiguration = async (validFrom, validUntil) => {
  await request({
    url    : `${api.VALIDITY_DATE_CONFIGURATIONS}/1`,
    method : PATCH,
    data   : {
      validFrom  : validFrom,
      validUntil : validUntil,
    }
  });
}