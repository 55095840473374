import React from 'react';
import useStyles from './styles';
import {Box, Typography, Grid} from '@material-ui/core'

const ComingSoon = (props) => {
  const classes = useStyles();
  
  return (
    <Box className={classes.comingsoon}>
      <Grid container spacing={2} className={classes.form}>
        <Grid item xs={12} className={classes.center}>
          <Box className={classes.image}/>
        </Grid> 
        <Grid item xs={12}>
          <Typography  className={classes.center} color="secondary"> 
            Coming Soon 
          </Typography>
        </Grid> 
      </Grid> 
    </Box>
  );
}

export default ComingSoon;