import { makeStyles } from '@material-ui/core/styles';
import images from "../../../utility/images";
import colors from '../../../theme/colors';

export default makeStyles((theme) => ({
  iconBox: {
    marginRight: theme.spacing(2)
  },
  credentialModal: {
    display   : 'block',
    padding   : theme.spacing(2),
    paddingTop: theme.spacing(5),
  },
  dialogImage: {
    alignItems: 'center',
    margin    : '0 auto',
    textAlign : 'center',
  },
  dialogTitle: {
    fontWeight: 'bold',
    textAlign : 'center',
  },
  dialogContent: {
    padding  : theme.spacing(0.5),
    textAlign: 'center',
  },
  dialogEmphasis: {
    fontStyle: 'italic'
  },
  dialogActions: {
    alignItems     : 'center',
    backgroundColor: colors.LIGHT_GRAY,
    direction      : 'row',
    display        : 'flex',
    height         : theme.spacing(8),
    justifyContent : 'space-around',
    padding        : theme.spacing(2),
  },
  dialogSubmitButton: {
    display : 'flex',
    float   : 'left',
    margin  : theme.spacing(0.5),
    padding : theme.spacing(0.5),
    width   : "200px",
  },
  dialogCancelButton: {
    display : 'flex',
    float   : 'right',
    margin  : theme.spacing(0.5),
    padding : theme.spacing(0.5),
    width   : "200px",
  },
  imageUpdate: {
    backgroundImage    : `url(${images.UPDATE_CONTROLLER_SVG})`,
    alignItems         : 'center',
    margin             : '0 auto',
    textAlign          : 'center',
    backgroundPosition : 'center', 
    backgroundSize     : 'cover', 
    backgroundRepeat   : 'no-repeat',
    height             : theme.spacing(15),
    width              : theme.spacing(15)
  },
  imageDelete: {
    backgroundImage    : `url(${images.DELETE_MODAL})`,
    margin             : '0 auto',
    backgroundSize     : 'contain', 
    backgroundRepeat   : 'no-repeat',
    height             : theme.spacing(16),
    width              : theme.spacing(25)
  },
}));