import { Box, Button, Dialog, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import images from "../../utility/images";
import useStyles from './styles';

const LocationDeleteModal = (props) => {
  const { openModal, title, description, handleDelete, handleCancel } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Dialog
      id="locationDeleteModal"
      modal="false"
      open={openModal}
      onClose={() => {}}
      aria-labelledby="row-delete-dialog"
      aria-describedby="row-delete-dialog-table"
      className={classes.dialog}
    >
      <Paper id="deleteModalBodyContainer" className={classes.deleteModal}>
        <Box className={classes.dialogImage}>
          <img src={images.DELETE_MODAL_ICON} alt='delete-dialog-img' />
        </Box>
        <Box className={classes.dialogTitle}>
          <Typography><b>{ title }</b></Typography>
        </Box>
        <Box className={classes.dialogContent}>
          <Typography>
            { description }
          </Typography>
        </Box>
        <Box id="deleteModalFooterContainer" className={classes.dialogActions}>
          <Button 
            data-testid="submit-delete-button"
            id="deleteModalRemoveNoButton" 
            size='medium' 
            color="primary" 
            variant="outlined" 
            className={classes.dialogButton} 
            onClick={handleDelete}
          >
            {t('locations-page.deleteButton')}
          </Button>
          <Button
            data-testid="cancel-delete-button"
            id="deleteModalRemoveYesButton"
            color="primary" 
            variant="contained"
            size='medium'
            className={classes.dialogButton }
            onClick={handleCancel}
            >
            {t('locations-page.cancelButton')}
          </Button>
        </Box>
      </Paper>
    </Dialog>
  );
}

export default LocationDeleteModal;