import { Box, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { CREDENTIALS_MODULE, DATE_FORMAT, PUT } from '../../../../utility/constants';
import useStyles from './styles';
import clsx from 'clsx';
import { WizardPersonContext } from '../../../../context/wizardPersonContext';
import { WizardContext } from '../../../../context/wizardContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { WizardCredentialContext } from '../../../../context/wizardCredentialContext';
import { useTranslation } from 'react-i18next';
import { SET_SELECT_CREDENTIALS } from '../../../../reducer/wizardCredentialReducer';
import { SET_ERROR, SET_NEXT_PAYLOAD } from '../../../../reducer/wizardReducer';
import SelectItems from '../../../select-items';

const formatTime = (time) => {
  return moment(time).format(DATE_FORMAT);
}

const WizardExistingCredentials = forwardRef((props, ref) => {
  const { showToaster, handlePermissions } = props;
  const { t } = useTranslation();

  const classes = useStyles();

  const wizardContext     = useContext(WizardContext);
  const personContext     = useContext(WizardPersonContext);
  const credentialContext = useContext(WizardCredentialContext);

  const [credentials, setCredentials] = useState([]);

  const context = personContext ? personContext : credentialContext;
  const { state } = context;

  const validFrom = personContext ? state.user.validFrom : state.credentialValues.user.validFrom;
  const validUntil = personContext ? state.user.validUntil : state.credentialValues.user.validUntil;

  useEffect(() => {
    const payload = {
      checkPage     : 3,
      isNextDisabled: Boolean(!state.selectedCredentials)
    }
    wizardContext.dispatch({ type: SET_NEXT_PAYLOAD, payload: payload });
    setCredentials(state.selectedCredentials);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => ({
    onNextPage() {
      const payload = {
        nextPage  : personContext ? 4 : 5,
        isEndPage : credentialContext,
        isNextShow: true,
        bufferPage: 2,
        prevPage  : 3
      }
      wizardContext.dispatch({ type: SET_NEXT_PAYLOAD, payload: payload });
      wizardContext.dispatch({ type: SET_ERROR, payload: false });
      context.dispatch({ type: SET_SELECT_CREDENTIALS, payload: formatCredentials(credentials) });
    }
  }));
  
  const formatCredentials = (credentials) => {
    return credentials.map(credential => {
      return {
        ...credential,
        description: formatTime(validFrom) + '-' + formatTime(validUntil)
      }
    });
  }

  const handleSelectCredentials = (values) => {
    setCredentials(values);
    wizardContext.dispatch({ type: SET_NEXT_PAYLOAD, payload: { isNextDisabled: !values.length } });
  }

  return (
    <Grid container className={clsx(!handlePermissions(CREDENTIALS_MODULE, PUT) ? 'hidden' : classes.select)} >
      <SelectItems
        id="wizardExistingCredentialsCredentials"
        name="Credentials"
        onChange={handleSelectCredentials}
        selectedCredentials={credentials}
        showToaster={showToaster}
        handlePermissions={handlePermissions}
      />
      <Box className={classes.description}>
        <FontAwesomeIcon className={classes.icon} icon={faInfoCircle}/>
        <Typography className={classes.text}>{t('credentialPersonDates')}:
          <span>{formatTime(validFrom) + ' - ' + formatTime(validUntil)}</span>
        </Typography>
      </Box>
    </Grid>
  )
});

export default WizardExistingCredentials