import { getLocalStorageItem } from "../utility/helper";

export const SET_LOCATION_FILTER = 'SET_LOCATION_FILTER';
export const LOGOUT              = 'LOGOUT';
export const INCREMENT           = 'INCREMENT';
export const SET_COUNT           = 'SET_COUNT';
export const DECREMENT           = 'DECREMENT';
export const CLEAR_REDUCER       = 'CLEAR_REDUCER';

export const initialState = {
  locationCount : 0,
  selectedLocationIds: getLocalStorageItem('selectedLocationIds') ?? [],
  selectedLocations: getLocalStorageItem('selectedLocations') ?? [],
  selectedArea: getLocalStorageItem('selectedArea')
}

export const LocationReducer = (state, action) => {
  switch (action.type) {
    case SET_LOCATION_FILTER:
      return {
        ...state,
        selectedLocationIds: action.payload.selectedLocationIds,
        selectedLocations: action.payload.selectedLocations,
        selectedArea: action.payload.selectedArea
      };
    case LOGOUT:
      return {
        ...state,
        selectedLocationIds: undefined,
        selectedLocations: undefined,
        selectedArea: undefined,
        locationCount: 0
      }
    case SET_COUNT:
      return {
        ...state,
        locationCount: action.payload
      }
    case INCREMENT:
      return {
        ...state,
        locationCount: state.locationCount + 1
      }
    case DECREMENT:
      return {
        ...state,
        locationCount: state.locationCount - 1
      }
    case CLEAR_REDUCER:
      return {
        ...initialState
      }
    default:
      return state
  }
}