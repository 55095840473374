import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors'

export default makeStyles((theme) => ({
  container: {
    paddingTop    : theme.spacing(2),
    paddingBottom : theme.spacing(2),
  },
  // updatedstyles----------------------------
  form: {
    height       :'100%',
    paddingBottom: theme.spacing(3),
  },
  administratorsContainer: {
    border           : `1px solid ${colors.GRAY}`,
    borderRadius     : theme.spacing(1),
    height           : '100%',
    padding          : theme.spacing(1),
    paddingTop       : theme.spacing(2),
    paddingLeft      : theme.spacing(2),
    paddingRight     : theme.spacing(2),

  },
  boxContainer: {
    borderBottom     : `1px solid ${colors.GRAY}`,
    display          : 'flex',
    justifyContent   : 'space-between',
    paddingBottom    : theme.spacing(2),
  },
  iconBox: {
    alignItems    : 'center',
    display       : 'flex',
    justifyContent: 'flex-end',
    marginRight   : theme.spacing(1),
    marginTop     : theme.spacing(1)
  },
  iconButton: {
    backgroundColor: theme.palette.primary.main,
    borderRadius   : theme.spacing(0.5),
    color          : theme.palette.common.white,
    display        : 'inline',
  },
}));