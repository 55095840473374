import { Accordion, AccordionDetails, AccordionSummary, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DASHBOARD, SETTINGS, SYNCING_USERS, SYSTEM_ROLES } from '../../utility/constants';
import useStyles from './styles';

const SubMenu = (props) => {
  const { 
    menu, 
    index, 
    handleListItemClick, 
    sidebar, 
    setSidebar, 
    selectedIndex, 
    setSelectedIndex, 
    menuList, 
    pathIndex,
    isCurrentPage,
  } = props;

  const classes = useStyles();
  const history = useHistory();
  const { t }   = useTranslation();

  const [selectedSubIndex, setSelectedSubIndex] = useState(pathIndex);
  const [openAccordion, setOpenAccordion] = useState(pathIndex > -1);
  
  const ref = useRef(null);
  const listRef = useRef(null);

  const handleAccordion = () => {
    setSidebar(false);
    setOpenAccordion(!openAccordion);
  }
  
  const handleSubListItemClick = (subIndex) => {
    if (selectedIndex === index) {
      if (index === 5) {
        return
      }
    }
   
    const pathname = menuList[index].subMenu[subIndex].path
    const basePath = pathname.replace('/', '');
    
    const pages = {
      DASHBOARD,
      SETTINGS,
      SYSTEM_ROLES,
      SYNCING_USERS
    };
    
    const currentPage = Object.values(pages).find(page => pathname.includes(page));
    const search = currentPage ? '' : `?${basePath}=all&page=0&size=10`;
    
    const location = {
      pathname  : pathname,
      search    : search
    }

    history.push(location);
    setSelectedIndex(null);
    setSelectedSubIndex(subIndex);
    setOpenAccordion(true);
  };

  useEffect(() => {
    setSelectedSubIndex(pathIndex);
  }, [selectedSubIndex, history.location.pathname, pathIndex]);

  const selectedClassName = ((selectedSubIndex > -1 || isCurrentPage) && !openAccordion) && classes.accordionSelected;
  
  const handleListItem = (event) => {
    const { target } = event;
    const iconRef = ref.current?.contains(target);
    const itemRef = listRef.current?.contains(target);
    
    if (!menu.path || iconRef || itemRef) {
      handleAccordion();
      return;
    }
    handleListItemClick(index);
  }

  return (
    <Accordion 
      expanded={openAccordion && !sidebar} 
      onClick={(e) => handleListItem(e)}
      className={clsx(`${selectedClassName} ${ sidebar ? classes.subMenuFlex : classes.navSummary}`)} 
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon innerRef={ref} onClick={handleAccordion}/>} >
        <ListItemIcon>
          {
            menu.icon
          }
        </ListItemIcon>
        <ListItemText primary={t(`main-layout-component.${menu.name}`)}/>
      </AccordionSummary>
      {
        menu.subMenu.map((subMenu, subIndex) => {
          return (
            <AccordionDetails ref={listRef} key={subIndex} className={classes.navDetails} >
              <ListItem
                id={subMenu.name}
                button
                selected={selectedSubIndex === subIndex}
                onClick={() => handleSubListItemClick(subIndex)}
                >
                <ListItemText primary={t(`main-layout-component.${subMenu.name}`)}/>
              </ListItem>
            </AccordionDetails>
          )
        })
      }
    </Accordion>
  )
}

export default SubMenu