import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

const useStyles = makeStyles(theme => ({
  locationItemContainer: {
    alignItems: 'center',
    padding     : theme.spacing(0.5),
    paddingLeft : theme.spacing(3.125),
    paddingRight: theme.spacing(3),
    '& .MuiBox-root': {
      marginLeft : theme.spacing(0),
    },
    '&:hover': {
      backgroundColor: colors.PRIMARY_LIGHT,
      boxShadow: '0 0 0 100vmax ' + colors.PRIMARY_LIGHT,
      clipPath: 'inset(0 -100vmax)'
    },
  },
  name: { 
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(5),
    '& .MuiLink-root:hover': {
      color: colors.PRIMARY,
    }
  },
  link: {
    color     : colors.BLACK,
    cursor    : 'pointer',
    fontSize  : theme.spacing(2),
    fontWeight: '400',
  },
  locationIcon: {
    marginRight: theme.spacing(1.25),
    marginBottom: theme.spacing(1),
  },
  icon: {
    width : theme.spacing(2.5),
    height : theme.spacing(2.5),
  },
  actionIcons: {
    display       : 'flex',
    height        : theme.spacing(4.5),
    justifyContent: 'flex-end',
    width         : theme.spacing(4.5),
  },
  actionButton: {
    padding: theme.spacing(1)
  }
}))

export default useStyles;