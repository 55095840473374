import { Box, Grid, Paper } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import React, { memo } from 'react';
import { DESCRIPTION_SKELETON, GET, LOCATIONS_MODULE, PUT, TEXT_FIELD_SKELETON, USERS_MODULE } from '../../../utility/constants';
import useStyles from './styles';

const CredentialSkeleton = (props) => {
  const { disabled, handlePermissions } = props;
  const classes = useStyles();

  return (
    <Box>
      <Paper className={classes.paper} elevation={3}>
        <Grid container justifyContent="space-between" spacing={2} className={classes.form}>
          <Grid item xs={12} sm={5} md={4} lg={3}>
            <Skeleton height={TEXT_FIELD_SKELETON}/>
          </Grid>
          <Grid item xs={6} sm={3} md={1}>
            <Skeleton height={TEXT_FIELD_SKELETON}/>
          </Grid>
        </Grid>
        <Grid container className={classes.form}>
          <Grid item xs={12}>
            <Skeleton height={DESCRIPTION_SKELETON}/>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.form}>
          <Grid item xs={12} md={6} lg={3}>
            <Skeleton height={TEXT_FIELD_SKELETON}/>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Skeleton height={TEXT_FIELD_SKELETON}/>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <Skeleton height={TEXT_FIELD_SKELETON}/>
          </Grid>
          <Grid item xs={12} md={12} lg={6} className={clsx(!handlePermissions(USERS_MODULE, PUT) && 'hidden')}>
            <Skeleton height={TEXT_FIELD_SKELETON}/>
          </Grid>
          <Grid item xs={12} md={12} lg={6} className={clsx(!handlePermissions(LOCATIONS_MODULE, GET) && 'hidden')}>
            <Skeleton height={TEXT_FIELD_SKELETON}/>
          </Grid>
        </Grid>
        <Grid container className={clsx(disabled ? 'hidden' : classes.action)}>
          <Grid item xs={12} className={`${classes.buttonContainer}`}>
            <Skeleton variant="rect" className={classes.button} />
            <Skeleton variant="rect" className={classes.button} />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default memo(CredentialSkeleton)