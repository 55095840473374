import { faIdCardAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid, TextField } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { forwardRef, useContext, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { WizardContext } from '../../../../context/wizardContext';
import { WizardCredentialContext } from '../../../../context/wizardCredentialContext';
import { SET_NEXT_PAYLOAD } from '../../../../reducer/wizardReducer';
import { createCredentials, deactivateCredentials, getCredentialByCredentialNumber, updateCredentials } from '../../../../service/credentialApi';
import { API_REQUEST_ERROR_MESSAGE } from '../../../../utility/constants';
import Chip from '../../../chip';
import useStyles from './styles';

const Chips = (props) => {
  const classes = useStyles();
  const { items, tagIcon } = props

  return (
    <Box className={classes.inputChips}>
      {
        items.map((option, index) => (
          <Box className={classes.chips} key={'selected-Chip-Box-' + index} >
            <Chip
              key={'Enhanced-Chip-Tag-' + index}
              icon={tagIcon}
              title={option.name}
              description={option.description}
            />
          </Box>
        ))
      }
    </Box>
  )
}

const ChipsTextField = (props) => {
  const { items, label, tagIcon } = props;

  return (
    <TextField
      id="chips-textfield"
      label={label}
      variant="outlined"
      fullWidth
      inputProps={{
        readOnly: true
      }}
      InputProps={{
        startAdornment:
          items.length > 0 ?
            <Chips items={items} tagIcon={tagIcon} />
            :
            null
        ,
        max: 100,
        min: 0,
        step: 2,
      }}
    />
  )
}

const Content = (props) => {
  const { values, formRef, handleSubmit, credentials } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      onSubmit={handleSubmit}
      innerRef={formRef}
    >
      {
        formik => (
          <Form className={classes.container}>
            <Grid container spacing={1} className={classes.form}>
              <Grid item xs={12}>
                <TextField
                  id="wizardCredentialsSummaryPerson"
                  label={t('user')}
                  name="user"
                  size="medium"
                  fullWidth
                  value={formik.values.label}
                  inputProps={{
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <ChipsTextField
                  id="wizardCredentialsSummaryCredentials"
                  label={t('credentials')}
                  items={credentials}
                  tagIcon={<FontAwesomeIcon icon={faIdCardAlt} />}
                />
              </Grid>
            </Grid>
          </Form>
        )
      }
    </Formik>
  )
}

const WizardCredentialsSummary = forwardRef((props, ref) => {
  const { showToaster } = props;

  const { t } = useTranslation();

  const wizardContext     = useContext(WizardContext);
  const credentialContext = useContext(WizardCredentialContext);

  const formRef = useRef();

  const { credentialValues, credentialUpdate, selectedCredentials, newCredentials } = credentialContext.state;
  const { user } = credentialValues;

  useImperativeHandle(ref, () => ({
    onNextPage() {
      formRef.current.handleSubmit();
    }
  }));

  const handleSubmit = async () => {
    if (credentialUpdate.length) {
      try {
        const filterResponse = await getCredentialByCredentialNumber(credentialUpdate, null);
        const activeCredentials = filterResponse.filter(response => response.data.active)
        const urls = activeCredentials.map(response => response.data._links.self.href);

        await updateUserCredentials(urls);
      } catch {
        showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
      }
    } else {
      await updateUserCredentials([]);
    }
  }

  const updateUserCredentials = async (urls) => {
    if (selectedCredentials.length) {
      try {
        await updateCredentials(selectedCredentials, user);

        await createUserCredentials(urls);
      } catch {
        showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
      }
    } else {
      await createUserCredentials(urls);
    }
  }

  const handleCredentialStatus = async (urls) => {
    try {
      await deactivateCredentials(urls, user);
      
      showToaster(t('success'), `${user.name} ${t('hasBeen')} ${t('updated')}`, 'success');
      wizardContext.dispatch({ type: SET_NEXT_PAYLOAD, payload: { isNextHidden: true, isNextShow: true, isEndPage: false } });
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }
  }

  const createUserCredentials = async (urls) => {
    if (newCredentials.length) {
      const credentialData = newCredentials.map(credential => {
        delete credential.name
        return credential
      });

      try {
        await createCredentials(credentialData, user, newCredentials)
        
        await handleCredentialStatus(urls);
      } catch {
        showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
      }
    } else {
      await handleCredentialStatus(urls);
    }
  }

  return (
    <Content
      values={user}
      credentials={selectedCredentials}
      formRef={formRef}
      handleSubmit={handleSubmit}
    />
  )
});

export default WizardCredentialsSummary;