import { Box, Container, Paper } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import AreaItem from '../../components/area-item';
import AuditLogs from '../../components/audit-log';
import Empty from '../../components/empty';
import LocationItem from '../../components/location-item';
import Title from '../../components/title';
import { getAreaAuditLogs } from '../../service/auditLogsApi';
import { getAreasAndLocations } from '../../service/locationsAPI';
import { AUDIT_LOG_ERROR_TOASTER, DESCENDING, LOCATIONS_ERROR_TOASTER, LOCATION_PROJECTIONS } from '../../utility/constants';
import { formatLocationsSubAreas } from '../../utility/helper';
import AreaSkeleton from './area-skeleton';
import useStyles from "./styles";
import { formatAuditLogs } from '../../utility/audit-logs';

const Area = (props) => {
  const { t } = useTranslation();
  const { match, showToaster, showLoading } = props;
  const { id } = match.params;
  const classes  = useStyles();

  const history = useHistory();

  const [levels, setLevels] = useState([]);
  const [name, setName] = useState('');
  const [data, setData] = useState([]);
  const [auditLogs, setAuditLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAuditLogLoading, setIsAuditLogLoading] = useState(true);

  const [page, setPage] = useState(0);
  const [sort, setSort] = useState(DESCENDING);
  const [hasMore, setHasMore] = useState(false);

  const getAreaParents = useCallback((parentLevel, parent) => {
    const { areaId, name: areaName, level } = parent;
    if (!level) {
      setLevels(parentLevel);
      return;
    }
    const newParentLevel = [{ id: areaId, name: areaName } ,...parentLevel];
    getAreaParents(newParentLevel, parent.parent);
  }, []);

  const handleGetArea = useCallback(async() => {
    setIsLoading(true);

    try {
      const response = await getAreasAndLocations(id, LOCATION_PROJECTIONS.NESTER_PARENT_CHILDREN);
      const { locations, subAreas, name: areaName, parent } = response;
      
      const locationsSubAreas = locations.concat(subAreas);
      
      locationsSubAreas.sort((a, b) => a.name.localeCompare(b.name));
      const formattedData = formatLocationsSubAreas(locationsSubAreas);
      
      getAreaParents([{ name: areaName, id: id }], parent);

      setData(formattedData);
      setName(areaName);
    } catch (error){
      showToaster(
        t(LOCATIONS_ERROR_TOASTER.TITLE), 
        t(LOCATIONS_ERROR_TOASTER.GET_AREA_DESCRIPTION), 
        LOCATIONS_ERROR_TOASTER.SEVERITY
      );
    } finally {
      setIsLoading(false);
    }
  }, [id, t, showToaster, getAreaParents]);

  const handleGetAuditLogs = useCallback(async () => {
    const params = {
      size: page === 0 ? 20 : 10,
      page: page,
      sort: `timestamp,${sort}`
    }
    try {
      const auditLogResponse =  await getAreaAuditLogs(id, params);

      const { content, number, totalElements, totalPages }  = auditLogResponse;
      const formattedAuditLogs = formatAuditLogs(content);

      setAuditLogs(prevData => [...prevData, ...formattedAuditLogs]);
      setHasMore(totalElements > 0 && number < totalPages - 1);
    } catch {
      showToaster(
        t(AUDIT_LOG_ERROR_TOASTER.TITLE), 
        t(AUDIT_LOG_ERROR_TOASTER.DESCRIPTION), 
        AUDIT_LOG_ERROR_TOASTER.SEVERITY
      );
    } finally {
      setIsAuditLogLoading(false);
    }
  }, [id, sort, page, t, showToaster]);

  const handleSort = (value) => {
    setIsAuditLogLoading(true);
    setPage(0);
    setAuditLogs([]);
    setSort(value);
  }

  const handleNextList = () => {
    setPage(prevPage => page === 0 ? 2 : prevPage + 1);
  }

  // Refresh auditlog list when navigated to another area or location. Trigger Get API
  useEffect(() => {
    setIsAuditLogLoading(true);
    setAuditLogs([]);
    setPage(0);
    setSort(DESCENDING);
  }, [id])

  useEffect(() => {
    handleGetAuditLogs();
  }, [sort, page, handleGetAuditLogs]);

  useEffect(() => {
    const pathname = history.location.pathname;
    const location = {
      pathname: pathname
    };

    history.replace(location);

    handleGetArea();
  }, [handleGetArea, id, history]);

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Title title={t('area-page.title')} subtitle={name} levels={levels}/>
      <Paper className={classes.paper} elevation={3}>
        {
          isLoading ?
            <AreaSkeleton />
          :
          
          <>
            {
              data.length ?
                <Box className={classes.locationContainer}>
                  {
                    data.map((item, index) => (
                      item.areaId ?
                        <AreaItem
                          key={`${item.areaId}-${item.name}-${index}`} 
                          name={item.name}
                          showToaster={showToaster}
                          showLoading={showLoading}
                          id={item.areaId}
                          data={item.data}
                          level={item.level}
                        />
                      :
                        <LocationItem 
                          key={`${item.locationId}-${item.name}-${index}`} 
                          id={item.locationId}
                          name={item.name}
                          showToaster={showToaster}
                          showLoading={showLoading}
                        />
                      )
                    )
                  }
                </Box>
              :   
                <Empty variant={'h6'}/>
            }
          </>
        }
      </Paper>
      <AuditLogs
        handleGetData={handleGetAuditLogs}
        handleNextList={handleNextList}
        handleSort={handleSort}
        data={auditLogs} 
        isAuditLogLoading={isAuditLogLoading}
        sort={sort}
        hasMore={hasMore}
      />
    </Container>
  )
}

export default Area;