import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  event: {
    display : 'flex',
  },
  contentSpacing: {
    marginBottom: theme.spacing(1),
    marginRight : theme.spacing(2),
    marginTop   : theme.spacing(1),
  },
  centerText: {
    alignItems    : 'center',
    display       : 'flex',
    justifyContent: 'center',
  },
  selectSkeleton: {
    height: '90px',
    width : '250px',
  },
  textFieldSkeleton: {
    height: '90px',
    width : '100px',
  },
  textTitleSkeleton: {
    height: '32px',
    width : '100px',
  },
  textDescriptionSkeleton: {
    height: '32px',
    width : '480px',
  }
}));