import { Container } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import AuditLogs from '../../components/audit-log';
import Title from '../../components/title';
import { getAreasAndLocations, getLocation } from '../../service/locationsAPI';
import { AUDIT_LOG_ERROR_TOASTER, DESCENDING, LOCATIONS_ERROR_TOASTER, LOCATION_PROJECTIONS } from '../../utility/constants';
import useStyles from "./styles";
import { formatAuditLogs } from '../../utility/audit-logs';
import { getLocationAuditLogs } from '../../service/auditLogsApi';

const Location = (props) => {    
  const { match, showToaster } = props;
  const { id } = match.params;

  const { t } = useTranslation();
  const classes  = useStyles();
  
  const history = useHistory();

  const [levels, setLevels] = useState([]);
  const [name, setName] = useState('');
  const [isAuditLogLoading, setIsAuditLogLoading] = useState(true);
  const [auditLogs, setAuditLogs] = useState([]);

  const [page, setPage] = useState(0);
  const [sort, setSort] = useState(DESCENDING);
  const [hasMore, setHasMore] = useState(false);

  const getAreaParents = useCallback((parentLevel, parent) => {
    const { areaId, name: areaName, level } = parent;

    if (!level) {
      let locationLevels = parentLevel.filter(locationLevel => locationLevel.level);
      
      locationLevels = [...locationLevels, { name } ];
      setLevels(locationLevels);
      return;
    }

    const isNewArea = !parentLevel.some(locationLevel => locationLevel.id === areaId);
    const newParentLevel = isNewArea ? [{ id: areaId, name: areaName, level }, ...parentLevel] : parentLevel;

    getAreaParents(newParentLevel, parent.parent);
  }, [name]);

  const handleGetLocation = useCallback(async() => {
    try {
      const response = await getLocation(id);
      const { area } = response;

      const areaResponse = await getAreasAndLocations(area.areaId, LOCATION_PROJECTIONS.NESTER_PARENT_CHILDREN);
      const { name: areaName, level } = areaResponse;
      
      setName(response.name);

      getAreaParents([{ name: areaName, id: areaResponse?.areaId, level }], areaResponse);

    } catch (error){
      showToaster(
        t(LOCATIONS_ERROR_TOASTER.TITLE), 
        t(LOCATIONS_ERROR_TOASTER.GET_LOCATION_DESCRIPTION), 
        LOCATIONS_ERROR_TOASTER.SEVERITY
      );
    }
  }, [t, showToaster, id, getAreaParents]);

  const handleGetAuditLogs = useCallback(async () => {
    const params = {
      size: page === 0 ? 20 : 10,
      page: page,
      sort: `timestamp,${sort}`
    }
    try {
      const auditLogResponse = await getLocationAuditLogs(id, params);

      const { content, number, totalElements, totalPages }  = auditLogResponse;
      const formattedAuditLogs = formatAuditLogs(content);

      setAuditLogs(prevData => [...prevData, ...formattedAuditLogs]);
      setHasMore(totalElements > 0 && number < totalPages - 1);
    } catch {
      showToaster(
        t(AUDIT_LOG_ERROR_TOASTER.TITLE), 
        t(AUDIT_LOG_ERROR_TOASTER.DESCRIPTION), 
        AUDIT_LOG_ERROR_TOASTER.SEVERITY
      );
    } finally {
      setIsAuditLogLoading(false);
    }
  }, [id, sort, page, showToaster, t]);

  const handleSort = (value) => {
    setIsAuditLogLoading(true);
    setAuditLogs([]);
    setPage(0);
    setSort(value);
  }

  const handleNextList = () => {
    setPage(prevPage => page === 0 ? 2 : prevPage + 1);
  }

  // Refresh auditlog list when navigated to another area or location. Trigger Get API
  useEffect(() => {
    setIsAuditLogLoading(true);
    setAuditLogs([]);
    setPage(0);
    setSort(DESCENDING);
  }, [id])

  useEffect(() => {
    handleGetAuditLogs();
  }, [sort, page, handleGetAuditLogs]);

  useEffect(() => {
    const location = {
      pathname: history.location.pathname
    };

    history.replace(location);

    handleGetLocation();
  }, [handleGetLocation, history]);

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Title title={t('location-page.title')} subtitle={name} levels={levels}/>
      <AuditLogs
        handleSort={handleSort}
        handleNextList={handleNextList}
        data={auditLogs} 
        isAuditLogLoading={isAuditLogLoading}
        sort={sort}
        hasMore={hasMore}
      />
    </Container>
  )
}

export default Location;