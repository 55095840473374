import { SnackbarProvider } from 'notistack';
import React, { useContext, useState } from 'react';
import { Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../components/loading';
import Toaster from '../components/toaster';
import { AuthContext } from '../context/authContext';
import Dashboard from '../pages/dashboard';
import { ACCESSPOINTS_MODULE, ACCESS_POINTS_MODULE, ADMINISTRATORS_MODULE, ADMINISTRATOR_ROLES_MODULE, CONTROLLERS_MODULE, CREDENTIALS_MODULE, EVENTS_MODULE, GET, HOLIDAYS_MODULE, PROFILES_MODULE, ROLES_MODULE, SCHEDULES_MODULE, USERS_MODULE } from '../utility/constants';

const PrivateRoute = (props) => {
  const { exact, component: Component, layout: Layout, path, ...rest } = props;

  const { state }         = useContext(AuthContext);
  const { administrator } = state;
  const { permissions }   = administrator;

  const [isSaving, setIsSaving] = useState(false);

  const regulatedModules  = [
    USERS_MODULE,
    CREDENTIALS_MODULE,
    ROLES_MODULE,
    PROFILES_MODULE,
    ACCESS_POINTS_MODULE,
    SCHEDULES_MODULE,
    HOLIDAYS_MODULE,
    EVENTS_MODULE,
    CONTROLLERS_MODULE,
    ADMINISTRATORS_MODULE,
    ADMINISTRATOR_ROLES_MODULE,
  ]

  const showLoading = (status) => {
    setIsSaving(status);
  }
  
  const handlePermissions = (name, scope) => {
    if (!name) {
      return true;
    }
    
    const names = permissions.map(permission => permission.name);

    const hasPermission = names.includes(name);
    if (hasPermission) {
      const hasActionPermission = handleActionPermission(name, scope);
      return hasActionPermission;
    }

    return hasPermission;
  }

  const handleActionPermission = (name, scope) => {
    const findNameScopes = permissions.find(permission => permission.name === name);

    return findNameScopes.scopes.includes(scope);
  }

  const getPermittedComponent = () => {

    const moduleName  = getModuleName();

    const isComponentRegulated = regulatedModules.includes(moduleName);
    if (!isComponentRegulated) {
      return Component;
    }
    
    const isComponentPermitted = handlePermissions(moduleName, GET);
    if (!isComponentPermitted) {
      return Dashboard;
    }

    return Component;
  }

  const getModuleName = () => {

    let trimmedPath = path.replace('/', '');

    trimmedPath = trimmedPath.charAt(0).toUpperCase() + trimmedPath.slice(1);

    const nextSlashIndex = trimmedPath.indexOf('/');
    if (nextSlashIndex !== -1) {
      trimmedPath = trimmedPath.substring(0, nextSlashIndex);
    }

    if (trimmedPath === ACCESSPOINTS_MODULE) {
      trimmedPath = ACCESS_POINTS_MODULE;
    }

    return trimmedPath;
  }

  const PermittedComponent = getPermittedComponent();

  return (
    <SnackbarProvider
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'top'
      }}
      Components={{
        toaster: Toaster
      }}
    > 
      <Route
        {...rest}
        exact={exact}
        path={path}
        render={(routeProps) =>
          <Layout
            {...props}
            handlePermissions={handlePermissions}
            >
            <ToastContainer/>
            <Loading isOpen={isSaving} />
            <PermittedComponent
              {...routeProps}
              {...rest}
              showLoading={showLoading}
              handlePermissions={handlePermissions}
            />
          </Layout>
        }
      />
    </SnackbarProvider>
  );
};

export default PrivateRoute;