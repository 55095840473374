import { Box, Grid, Paper } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import { Form } from 'formik';
import React from 'react';
import { DESCRIPTION_SKELETON, GET, LOCATIONS_MODULE, PROFILES_MODULE, PUT, TEXT_FIELD_SKELETON } from '../../../utility/constants';
import useStyles from './styles';

const RoleSkeleton = (props) => {
  const classes = useStyles()
  const { disabled, handlePermissions } = props;

  return (
    <Box>
      <Paper className={classes.paper} elevation={3}>
        <Grid container spacing={2} className={classes.form}>
          <Grid item xs={12}>
            <Skeleton height={TEXT_FIELD_SKELETON}/>
          </Grid>
          <Grid item xs={12} lg={disabled ? 12 : 6}>
            <Skeleton height={DESCRIPTION_SKELETON} />
            <Skeleton height={TEXT_FIELD_SKELETON} className={clsx(!handlePermissions(LOCATIONS_MODULE, GET) && 'hidden')}/>
          </Grid>
          <Grid hidden={disabled} item xs={12} lg={6} className={clsx(!handlePermissions(PROFILES_MODULE, PUT) && 'hidden')}>
            <Skeleton variant="rect" className={classes.containerProfiles} />
          </Grid>
        </Grid>
        <Grid container className={clsx(disabled ? 'hidden' : classes.action)}>
          <Grid item xs={12} className={`${classes.buttonContainer}`}>
            <Skeleton variant="rect" className={classes.button} />
            <Skeleton variant="rect" className={classes.button} />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

const CreateProfileLoadingState = () => {
  const classes  = useStyles();

  return(
    <Form>
      <Grid container spacing={2} className={`${classes.paper} ${classes.form}`} elevation={3}>
        <Grid item md={12} sm={12}>
          <Skeleton height={TEXT_FIELD_SKELETON} />
        </Grid>
        <Grid item md={12} sm={12}>
          <Skeleton height={DESCRIPTION_SKELETON} />
        </Grid>
          <Grid item md={6} sm={6}>
            <Skeleton height={TEXT_FIELD_SKELETON} />
          </Grid>
          <Grid item md={6} sm={6}>
            <Skeleton height={TEXT_FIELD_SKELETON} />
          </Grid>
          <Grid item md={12} sm={12}>
            <Skeleton height={TEXT_FIELD_SKELETON} />
          </Grid>
          <Grid item md={12} sm={12}>
            <fieldset className={classes.containerAccessPoint}>
                <legend className={classes.fieldsetLegend}>Access Points And Groups</legend>
                  <Grid container spacing={2} className={`${classes.groupContainer}`}>
                    <Grid item md={6} sm={6}>
                    <Skeleton height={TEXT_FIELD_SKELETON} />
                    </Grid>
                    <Grid item md={6} sm={6}>
                    <Skeleton height={TEXT_FIELD_SKELETON} />
                    </Grid>
                  </Grid>
              </fieldset>
            </Grid>
          </Grid>
        <Grid container className={classes.action}>
          <Grid item xs={12} className={`${classes.buttonContainer}`}>
            <Skeleton variant="rect" className={classes.button} />
            <Skeleton variant="rect" className={classes.button} />
          </Grid>
        </Grid>
    </Form>
  );
}

export {
  CreateProfileLoadingState, RoleSkeleton
};

