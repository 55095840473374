import React, { useState, useContext, useEffect } from "react";
import { KeyboardDatePicker } from '@material-ui/pickers';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useStyles from './styles';
import moment from "moment";
import { DATE_FORMAT_YYYY_MM_DD, INVALID_DATE, LANGUAGE_EN } from '../../utility/constants';
import { useTranslation } from "react-i18next";
import { Button, Toolbar } from "@material-ui/core";
import cookies from 'js-cookie';
import { AuthContext } from "../../context/authContext";
import { parseISO } from "date-fns";

const DatePicker = (props) => {
  const { t }   = useTranslation();
  const classes = useStyles();
  const { name, disabled, label, value, handleChange, touched, error, min, until, from, setIsNotValid, NotRequired = false } = props;

  const { state }                               = useContext(AuthContext);
  const { defaultValidFrom, defaultValidUntil } = state;

  let isValid;
  let customLabel = (NotRequired) ? `${label}` : `${label}*`;
  
  useEffect(() => {
    handleChange(getValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const duration = (start, end) => {
    const startTime = moment(start);
    const endTime   = moment(end);

    return moment.duration(endTime.diff(startTime));
  }
  isValid = duration(from, min)._data.days < 0;
  setIsNotValid(isValid);

  const getValue = () => {
    const validFrom = defaultValidFrom ?? null;
    const validUntil = defaultValidUntil ?? null;

    if (name === 'validFrom') {
      return value ? value :  validFrom;
    } else {
      return value ? value :  validUntil;
    }
  }

  return (
    <KeyboardDatePicker
      InputProps={{
        readOnly: disabled
      }}
      autoComplete="off"
      className={`${(disabled && classes.hover)} ${classes.calendarTextField}`}
      margin="normal"
      id={name}
      variant="inline"
      name={name}
      label={customLabel}
      format="dd.MM.yyyy"
      value={parseISO(value)}
      fullWidth
      minDate={from}
      maxDate={until}
      onChange={e => handleChange(moment(e).format(DATE_FORMAT_YYYY_MM_DD))}
      autoOk={true}
      keyboardIcon={disabled ? '' : <FontAwesomeIcon size="xs" icon={faCalendar}/>}
      error={isValid || ((touched && Boolean(error)))}
      helperText={(t(touched) && t(error)) || (isValid ? t(INVALID_DATE) : '')}
      InputLabelProps = {{
        shrink: Boolean(value)
      }}
      disabled={disabled}
      ToolbarComponent={(event) => <ToolbarComponent event={event}/>}
    />
  )
}

const ToolbarComponent = (props) => {
  const { event } = props;

  const { t }   = useTranslation();
  const classes = useStyles();

  const [active, setActive]         = useState(false);
  const [activeYear, setActiveYear] = useState(true);

  const year  = moment(event.date).format('YYYY');
  const dates = moment(event.date).format('D');
  const month = moment(event.date).format('MMM');
  const day   = moment(event.date).format('ddd');

  const handleChangeView = (view) => {
    if (view === 'year') {
      setActive(true)
      setActiveYear(false)
    } else {
      setActive(false)
      setActiveYear(true)
    }
    event.setOpenView(view);
  }

  const language = cookies.get('i18next') || LANGUAGE_EN;

  const monthFormatted = t(month).length === 4 ? t(month) : `${t(month).slice(0,3)}.`;
  const label = language === LANGUAGE_EN ? `${t(day)}, ${t(month)} ${t(dates)}` : `${t(day).slice(0,2)}., ${t(dates)}. ${monthFormatted}`;

  return (
    <Toolbar className={classes.toolbar}>
      <Button className={`${activeYear && classes.activeLabel} ${classes.yearLabel}`} onClick={() => handleChangeView('year')}>{year}</Button>
      <Button className={`${active && classes.activeLabel } ${classes.dateLabel}`} onClick={() => handleChangeView('date')}>{label}</Button>
    </Toolbar>
  )
}

export default DatePicker;