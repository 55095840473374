import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

const useStyles = makeStyles(theme => ({
  areaItemcontainer: {
    alignItems  : 'center',
    padding     : theme.spacing(0.5),
    paddingLeft : theme.spacing(2),
    paddingRight: theme.spacing(3),
    '&:hover': {
      backgroundColor: colors.PRIMARY_LIGHT,
      boxShadow: '0 0 0 100vmax ' + colors.PRIMARY_LIGHT,
      clipPath: 'inset(0 -100vmax)'
    },
  },
  subContainer: {
    paddingLeft: theme.spacing(3),
    '&:hover': {
      backgroundColor: 'transparent !important',
      boxShadow: 'none !important',
      clipPath: 'none !important'
    },
    '& .MuiAccordion-root': {
      width: '100%',
      margin: theme.spacing(0)
    }
  },
  locationTextField: {
    paddingLeft: theme.spacing(4),
  },
  name: { 
    alignItems: 'center',
    display: 'flex',
    '& .MuiLink-root:hover': {
      color: colors.PRIMARY,
    }
  },
  areaLink: {
    color     : colors.BLACK,
    cursor    : 'pointer',
    fontSize  : theme.spacing(2),
    fontWeight: '400',
  },
  icon: {
    width : theme.spacing(2.5),
    height : theme.spacing(2.5),
    '& svg': {
      color: colors.PRIMARY
    }
  },
  collapseIcon: {
    height: theme.spacing(5),
    width : theme.spacing(5),
  },
  actionIcons: {
    display       : 'flex',
    height        : theme.spacing(4.5),
    justifyContent: 'flex-end',
    width         : theme.spacing(4.5),
  },
  actionButton: {
    padding: theme.spacing(1)
  }
}))

export default useStyles;