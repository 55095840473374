import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import makeStyles , { labelStyles, xAxis, yAxis } from './styles';
import { Box } from '@material-ui/core';
import { EventChartSkeleton } from '../dashboard-skeleton';
import Empty from '../../../components/empty';

const EventsChart = (props) => {
  const { eventData, eventContentIsLoading, handleBarClick, id } = props;
  const classes = makeStyles();

  const categories = eventData.map(value => value.label)[0];
  const isNotEmpty = eventData[0].data.some(data => data);

  const options = {
    chart: {
    type: 'column',
      margin: [45, 85, 130, 85]
    },
    title: {
        text: ''
    },
    xAxis: {
      ...xAxis,
      min: 0,
      thickInterval: 0,
      labels: {
        style: labelStyles,
        rotation: 315,
        formatter: function () {
          return categories[this.value]
        }
      },
      crosshair: true
    },
    legend: {
      symbolHeight: 12,
      symbolWidth: 12,
      symbolRadius: 0,
    },
    yAxis: {
      ...yAxis,
      min: 0,
      allowDecimals: false,
      tickInterval: 2,
      labels: {
        style: labelStyles,
      },
        title: {
            text: ''
        }
    },
    tooltip: {
      formatter: function () {
        return `<b>${categories[this.x]}</b><br>${this.series.name}: ${this.y}`
      },
    },
    plotOptions: {
      column: {
          pointPadding: 0.2,
          borderWidth: 0,
          point: {
            events: {
              click: function() {
                handleBarClick(this);
              }
            }
          }
      },
      series: {
        ignoreNulls: 'normal',
        maxPointWidth: 20
      }
    },
    boost: {
      enabled: true,
      useGPUTranslations: true
    },
    series: eventData,
    options: {
      maintainAspectRatio: false,
      responsive: false
    }
  }

  return (
    <Box className={classes.eventChart}>
     {
        eventContentIsLoading ?
          <EventChartSkeleton />
        :
          <ChartContent
            id={id}
            highcharts={Highcharts}
            options={options}
            isNotEmpty={isNotEmpty}
          />
      }
    </Box>
  )
}

const ChartContent = (props) => {
  const { highcharts, options, isNotEmpty } = props;
  const classes = makeStyles();
  return (
    <>
      {
        isNotEmpty ?
          <HighchartsReact
            highcharts={highcharts}
            options={options}
          />
        :
          <Box className={classes.empty}>
            <Empty variant={'h6'}/>
          </Box>
      }
    </>
  )
}

export default EventsChart