import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    paddingTop   : theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '& .MuiFormControl-root': {
      margin: theme.spacing(0.5),
      width: '90%'
    },
    '& .Mui-focusVisible': {
      backgroundColor: 'transparent'
    }
  },
  paper: {
    marginTop   : theme.spacing(1),
    padding     : theme.spacing(3),
    paddingRight: theme.spacing(0),
    paddingLeft : theme.spacing(0),
    position    : 'relative',
  },
  locationContainer: {
    maxHeight: '70vh',
    minHeight: '70vh',
    overflowY: 'scroll',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    '& .MuiAccordionDetails-root': {
      padding: theme.spacing(0),
      flexDirection: 'column'
    },
    '& .MuiAccordionSummary-root': {
      padding: theme.spacing(0),
    },
    '& .MuiAccordionSummary-content': {
      margin: theme.spacing(0),
    }
  },
  iconBox: {
    alignItems    : 'center',
    display       : 'flex',
    justifyContent: 'flex-end',
    marginRight   : theme.spacing(1),
    marginTop     : theme.spacing(1)
  },
  iconButton: {
    backgroundColor: theme.palette.primary.main,
    borderRadius   : theme.spacing(0.5),
    color          : theme.palette.common.white,
    display        : 'inline',
  }
}));