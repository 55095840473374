import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  categoryStyle: {
    color        : theme.palette.secondary.main,
    fontWeight   : 'bold',
    lineHeight   : 1,
    paddingLeft  : theme.spacing(1)
  },
  categoryTypographStyle: {
    color       : theme.palette.common.black,
    '& .MuiTypography-root' : {
      fontWeight  : ' bold !important',
    }
  },
  categoryCheckbox: {
    marginLeft  : theme.spacing(1.25),
  },
  parentStyle: {
    color        : theme.palette.secondary.main,
    fontWeight   : 'bold',
    lineHeight   : 1,
    paddingLeft  : theme.spacing(4.5)
  },
  parentTypographStyle: {
    color       : theme.palette.common.black,
    '& .MuiTypography-root' : {
      fontWeight  : ' bold !important'
    }
  },
  parentCheckbox: {
    marginLeft  : theme.spacing(1.25)
  },
  childTypographStyle: {
    paddingLeft: theme.spacing(10)
  },
  childStyle: {
    alignContent : 'center',
    alignItems   : 'center',
    display      : 'flex',
    flexDirection: 'row',
    paddingLeft  : theme.spacing(1),
    paddingRight : theme.spacing(1)
  }
}));