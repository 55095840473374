import { ImportExportOutlined, InputOutlined, MeetingRoom, MoreHoriz, NotificationsActiveOutlined, Settings, SettingsRemote, SurroundSound, SyncAltOutlined, Update, WifiOutlined } from "@material-ui/icons";
import { ACCESS_CHECK, CERTIFICATE_IMPORT, DEVICE_REQUEST, DOOR, EMA, ETHERNET, IMPORT_EXPORT, READER, REMOTE_CONTROL, SDDL, SERVICE, UPDATE_DAEMON_TYPE } from "./constants";
import images from "./images";
import { ShieldOutlined } from "@mui/icons-material";

export const getEventTypeIcon = (event) => {
  switch (event) {
    case DOOR:
      return <MeetingRoom fontSize="small"/>
    case READER:
      return <SurroundSound fontSize="small"/>
    case ACCESS_CHECK:
      return <img src={images.ACCESS_CHECK_EVENT} alt="access-check-icon" />
    case EMA:
      return <NotificationsActiveOutlined fontSize="small"/>
    case SERVICE:
      return <Settings fontSize="small"/>
    case REMOTE_CONTROL:
      return <SettingsRemote fontSize="small"/>
    case IMPORT_EXPORT:
      return <ImportExportOutlined fontSize="small"/>
    case SDDL:
      return <InputOutlined fontSize="small"/>
    case UPDATE_DAEMON_TYPE:
      return <Update fontSize="small"/>
    case DEVICE_REQUEST:
      return <SyncAltOutlined fontSize="small"/>
    case ETHERNET:
      return <WifiOutlined fontSize="small"/>
    case CERTIFICATE_IMPORT:
      return <ShieldOutlined fontSize="small"/>
    default:
      return <MoreHoriz fontSize="small"/>;
  }
}