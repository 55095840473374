import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import useStyles from './styles';

export const TitleSkeleton = () => {
  const classes = useStyles();

  return (
    <Box>
      <Skeleton variant='rect' className={classes.title} />
    </Box>
  );
}

export const SubTitleSkeleton = () => {
  const classes = useStyles();

  return (
    <Box>
      <Skeleton variant='rect' className={classes.subTitle} />
    </Box>
  );
}