import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  skeletonLong: {
    height      : 24,
    width       : '75%',
    marginBottom: theme.spacing(4),
  },
  skeletonShort: {
    height      : 24,
    width       : '35%',
    marginBottom: theme.spacing(4),
  }
}));