import PropTypes from 'prop-types';

export const EnhancedTableHeadPropTypes = {
  values: PropTypes.shape({
    order     : PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy   : PropTypes.string.isRequired,
    rowCount  : PropTypes.number.isRequired,
    tableHeads: PropTypes.array.isRequired
  }).isRequired,
  functions: PropTypes.shape({
    onRequestSort: PropTypes.func.isRequired
  }).isRequired,
};

export const EnhancedTableToolbarPropTypes = {
  values: PropTypes.shape({
    rowsPerPage  : PropTypes.number.isRequired,
    page         : PropTypes.number.isRequired,
    isLoading    : PropTypes.bool.isRequired,
    keyword      : PropTypes.string.isRequired,
    data         : PropTypes.array.isRequired,
    totalItems   : PropTypes.number.isRequired,
  }).isRequired,
  functions: PropTypes.shape({
    onPageChange     : PropTypes.func.isRequired,
    handleSearch     : PropTypes.func.isRequired,
    handleClearSearch: PropTypes.func.isRequired,
    handleFilter     : PropTypes.func.isRequired,
    onFirstPage      : PropTypes.func.isRequired
  }).isRequired,
};

export const EnhancedTableRowPropTypes = {
  values: PropTypes.shape({ 
    rowsPerPage: PropTypes.number.isRequired,
    page       : PropTypes.number.isRequired, 
    columns    : PropTypes.array.isRequired,
    tableHeads : PropTypes.array.isRequired,
    data       : PropTypes.array.isRequired,
    module     : PropTypes.string.isRequired,
  }).isRequired,
  functions: PropTypes.shape({
    onPageChange   : PropTypes.func.isRequired, 
    handleRowClick : PropTypes.func.isRequired,
  }).isRequired,
  tableHeadProps: PropTypes.shape({EnhancedTableHeadPropTypes}).isRequired
};

export const EnhancedTableProptypes = {
  columns      : PropTypes.array.isRequired,
  page         : PropTypes.number.isRequired,
  rowsPerPage  : PropTypes.number.isRequired,
  data         : PropTypes.array.isRequired,
  isLoading    : PropTypes.bool.isRequired,
  onChangePage : PropTypes.func.isRequired,
  onClearSearch: PropTypes.func.isRequired,
  onSearch     : PropTypes.func.isRequired,
  onSort       : PropTypes.func.isRequired,
  keyword      : PropTypes.string.isRequired,
  totalItems   : PropTypes.number.isRequired,
  order        : PropTypes.string.isRequired,
  orderBy      : PropTypes.string.isRequired,
  onFilter     : PropTypes.func.isRequired,
}