import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  paper: {
    marginTop   : theme.spacing(5),
    padding     : theme.spacing(3),
    paddingRight: theme.spacing(0),
    paddingLeft : theme.spacing(0),
  },
  auditContainer: {
    display       : 'flex',
    justifyContent: 'center',
    position      : 'relative',
    '& .MuiFormControl-root': {
      position: 'absolute',
      right   : theme.spacing(3),
      top     : '0',
      width   : 'fit-content !important'
    }
  },
  auditLogContainer: {
    width       : '100%',
    paddingLeft : theme.spacing(3),
    paddingRight: theme.spacing(3),
    maxHeight   : theme.spacing(42),
    minHeight   : theme.spacing(42),
    overflowY   : 'scroll',
  },
  loader: {
    display       : 'flex',
    justifyContent: 'center',
    width         : '100%',
  },
  logs: {
    overflow: 'hidden !important'
  }
  
}));