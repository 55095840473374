import { faClock, faCopy, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Checkbox, Container, FormControlLabel, Grid, IconButton, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { KeyboardTimePicker } from '@material-ui/pickers';
import clsx from 'clsx';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../service/api';
import { REQUIRED_FIELD } from '../../../utility/constants';
import { DefaultEndDateFormat, endDateFormat, getNextDayItem, isExist, startDateFormat } from '../schedule-utils';
import useStyles from './styles';

const TimePicker = (props) => {
  const { t } = useTranslation();
  const { name, label, value, handleChange, isEmpty, touched, id } = props;

  return (
    <KeyboardTimePicker
      ampm={false}
      id={id}
      keyboardIcon={ <FontAwesomeIcon id="addTime" icon={faClock} size="sm"/>}
      label={`${label}*`}
      name={name}
      onChange={handleChange}
      variant="dialog"
      value={value}
      InputProps={{
        readOnly : true
      }}
      error={isEmpty && (touched && Boolean(REQUIRED_FIELD))}
      helperText={isEmpty && (touched  && t(REQUIRED_FIELD))}
      cancelLabel={t('cancel')}
    />
  )
}

const AddTimePicker = (props) => {
  const { timeID, item, weekItem, setWeekItem, setDefaultStartDate, setDefaultEndDate, touched, setDeleteItemList } = props;
  const classes = useStyles();
  const { t }   = useTranslation();
  const [startDate, setStartDate]   = useState(item.timeFrom);
  const [endDate, setEndDate]       = useState(item.timeUntil);
  const [nextDayEndDate, setNextDayEndDate] = useState('');

  const duration = (start, end) => {
    const startTime = moment(start);
    const endTime   = moment(end);

    return moment.duration(endTime.diff(startTime));
  }

  const defaultDuration = duration(item.timeFrom, item.timeUntil);
  const newDuration     = duration(startDate, endDate);
  
  const updateWeekItem = (updatedTime, nextDayItem) => {    
    setWeekItem(prevItems => {
      const updatedItems = prevItems.map(item => (item.id === timeID ? { ...item, ...updatedTime } : item));
      return nextDayItem ? [...updatedItems, nextDayItem] : updatedItems;
    });
  };

  const minMaxHandler = (timeDuration) => {
    return timeDuration < 60000;
  }

  const handleAddTimePicker = () => {
    const nextDayItem = getNextDayItem(nextDayEndDate, item);
    const updatedItem = {
      ...item,
      id: Math.random()
    }

    setWeekItem(items => {
      if (isExist(items, item)) {
        return items;
      }
      const updatedItems = nextDayItem ? [...items, updatedItem, nextDayItem] : [...items, updatedItem];
      return updatedItems;
    });
    
    setDefaultStartDate(startDateFormat);
    setDefaultEndDate(endDateFormat);
    setNextDayEndDate('');
  }

  const handleRemoveTimePicker = () => {
    if (item.scheduleItemId) {
      const url = `${api.SCHEDULES_ITEMS}/${item.scheduleItemId}`;
      setDeleteItemList(items => [...items, url]);
    }
    
    setWeekItem(items => items.filter(element => element.id !== timeID));
  }

  const updateTimeFrom = (value) => {
    const newDuration = duration(value, endDate);
    const isGreaterThanTimeUntil = minMaxHandler(newDuration);
    const updatedTime = {
      timeFrom: value,
      timeUntil: isGreaterThanTimeUntil ? DefaultEndDateFormat : item.timeUntil
    };

    if (isGreaterThanTimeUntil) {
      const nextDayItem = getNextDayItem(item.timeUntil, item);
      updateWeekItem(updatedTime, nextDayItem);
      setEndDate(DefaultEndDateFormat);
    } else {
      updateWeekItem(updatedTime, null);
    }
  }

  const createTimeFrom = (value) => {
    const defaultDuration = duration(value, item.timeUntil);
    const isGreaterThanTimeUntil = minMaxHandler(defaultDuration);
    if (isGreaterThanTimeUntil) {
      setNextDayEndDate(item.timeUntil);
      setDefaultEndDate(DefaultEndDateFormat);
    }
    setDefaultStartDate(value);
  }

  const handleTimeFrom = (value) => {
    if (timeID) {
      updateTimeFrom(value, newDuration);
      setStartDate(value);
    } else {
      createTimeFrom(value)
    }
  }

  const createTimeUntil = (value) => {
    const defaultDuration = duration(item.timeFrom, value);
    const isLessThanTimeFrom = minMaxHandler(defaultDuration);
    if (isLessThanTimeFrom) {
      setDefaultEndDate(DefaultEndDateFormat);
      setNextDayEndDate(value);
    } else {
      setDefaultEndDate(value);
    }
  }

  const updateTimeUntl = (value) => {
    const newDuration = duration(startDate, value);
    const isLessThanTimeFrom = minMaxHandler(newDuration);
    const updatedTime = {
      timeFrom: startDate,
      timeUntil: isLessThanTimeFrom ? DefaultEndDateFormat : moment(value).seconds(59).format("ddd MM DD YYYY HH:mm:ss")
    };

    if (isLessThanTimeFrom) {
      const nextDayItem = getNextDayItem(value, item);
      updateWeekItem(updatedTime, nextDayItem);
    } else {
      setEndDate(updatedTime.timeUntil);
      updateWeekItem(updatedTime, null);
    }
  }

  const handleTimeUntil = (value) => {
    if (timeID) {
      updateTimeUntl(value);
    } else {
      createTimeUntil(value);
    }
  }

  return (
    <Box className={classes.timePickerContainer}>
      <Grid container>
        <Grid item xs={4}>
          <TimePicker
            id={`scheduleTime${item.id}TimeFrom`}
            label={t('from')}
            name="timeFrom"
            value={timeID ? startDate : item.timeFrom}
            handleChange={handleTimeFrom}
            isEmpty={!weekItem.length}
            touched={touched}
          />
        </Grid>
        <Grid item xs={4} className={classes.timePicker}>
          <TimePicker
            id={`scheduleTime${item.id}TimeUntil`}
            label={t('until')}
            name="timeUntil"
            value={timeID ? endDate : item.timeUntil}
            handleChange={handleTimeUntil}
            isEmpty={!weekItem.length}
            touched={touched}
          />
        </Grid>
        <Grid item xs={3} className={classes.iconContainer}>
          <Tooltip title={t('remove')} className={`${classes.actionTools} ${classes.deleteIcon}`}>
            <span>
              <IconButton id={`scheduleTime${item.id}RemoveButton`} aria-label="delete" disabled={!timeID} onClick={handleRemoveTimePicker}>
                <FontAwesomeIcon icon={faTrash} size="sm" />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={t('addTime')} className={clsx(timeID ? 'hidden' : classes.actionTools)}>
            <IconButton id="addTimeButton" aria-label="copy" onClick={handleAddTimePicker}>
              <FontAwesomeIcon icon={faPlus} size="sm" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12} className={classes.timePickerDuration}>
          <Typography color="secondary">{`${timeID ? newDuration._data.hours : defaultDuration._data.hours} ${t('hours')}`}</Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

const ActionMenuItems = (props) => {
  const { label, checked, state, setState, handleChange, name } = props;
  const elementId = name.charAt(0).toLowerCase() + name.slice(1);

  return (
    <MenuItem id={`${elementId}MenuItem`}>
      <FormControlLabel
        id={`${label}Item`}
        label={label}
        control={
          <Checkbox
            id={`${elementId}Checkbox`}
            checked={checked}
            onClick={event => handleChange(event, setState, state)}
            name={name}
          />
        }
      />
    </MenuItem>
  )
}

const ActionMenu = (props) => {
  const { open, anchorEl, handleClose, setAnchorEl, day, setWeekItem, weekItem} = props;
  const classes = useStyles();
  const { t }   = useTranslation();

  const [isWeekendSelected, setIsWeekendSelected]   = useState(false);
  const [isWeekdaysSelected, setIsWeekdaysSelected] = useState(false)

  const [weekends, setWeekends] = useState({
    Sunday   : false,
    Saturday : false,
  })

  const [weekdays, setWeekdays] = useState({
    Monday    : false,
    Tuesday   : false,
    Wednesday : false,
    Thursday  : false,
    Friday    : false,
  })

  const weekendsObject = Object.keys(weekends);
  const weekdaysObject = Object.keys(weekdays);

  useEffect(() => {
    setIsWeekdaysSelected(Object.values(weekdays).every(select => select))
    setIsWeekendSelected(Object.values(weekends).every(select => select))
  }, [weekends, weekdays])

  const handleChange = (event, setOption, select) => {
    const { name, checked } = event.target;
    setOption({
      ...select,
      [name]: checked,
    });
  };

  const handleChangeAll = (event) => {
    if (event.target.checked) {
      setIsWeekendSelected(true);
      setIsWeekdaysSelected(true);
      weekendsObject.forEach(key => weekends[key] = true);
      weekdaysObject.forEach(key => weekdays[key] = true);

    } else {
      setIsWeekendSelected(false);
      setIsWeekdaysSelected(false);
      weekendsObject.forEach(key => weekends[key] = false);
      weekdaysObject.forEach(key => weekdays[key] = false);
    }
  }

  const handleChangeSelected = (event, setChange, objectSelected, select) => {
    if (event.target.checked) {
      setChange(true);
      objectSelected.forEach(key => select[key] = true);
    } else {
      setChange(false);
      objectSelected.forEach(key => select[key] = false);
    }
  }

  const handleApplyClone = () => {
    const cloneWeekdays = Object.keys(weekdays).filter(item => weekdays[item]);
    const cloneWeekends = Object.keys(weekends).filter(item => weekends[item]);

    const cloneDays = cloneWeekdays.concat(cloneWeekends);

    const selectedDayItem = weekItem.filter(filter => filter.day === day);
    const copyToAllArray = [];
    if (cloneDays) {
      cloneDays.map(list => {
        selectedDayItem.forEach(data => {
          let newObj = {
            id        : Math.random(),
            day       : list,
            timeFrom  : data.timeFrom,
            timeUntil : data.timeUntil
          }
          if (isExist(weekItem, newObj)) return;
          else copyToAllArray.push(newObj);
        })
        return null;
      });
      setWeekItem(weekItem.concat(copyToAllArray));
      weekdaysObject.forEach(key => weekdays[key] = false);
      weekendsObject.forEach(key => weekends[key] = false);
      setAnchorEl(null);
    }
  }

  return (
    <Menu
      id="menu"
      className={classes.menu}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby' : 'button',
      }}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical   : 'bottom',
        horizontal : 'right',
      }}
      transformOrigin={{
        vertical   : 'top',
        horizontal : 'right',
      }}
    >
      <Box className={classes.option}>
        <MenuItem id="dailyMenuItem">
          <FormControlLabel
            label={t('Daily')}
            control={
              <Checkbox
                id="dailyCheckbox"
                checked={isWeekendSelected && isWeekdaysSelected}
                indeterminate={!isWeekendSelected || !isWeekdaysSelected}
                onClick={handleChangeAll}
                name="Daily"/>
              }
          />
        </MenuItem>
        <MenuItem id="weekdaysMenuItem">
          <FormControlLabel
            label={t('Weekdays')}
            control={
              <Checkbox
                id="weekdaysCheckbox"
                checked={isWeekdaysSelected}
                onClick={event => handleChangeSelected(event, setIsWeekdaysSelected, weekdaysObject, weekdays)}
                name="Daily"/>
              }
          />
        </MenuItem>
          <MenuItem id="weekendsMenuItem">
          <FormControlLabel
            label={t('Weekends')}
            control={
              <Checkbox
                id="weekendsCheckbox"
                checked={isWeekendSelected}
                onClick={event => handleChangeSelected(event, setIsWeekendSelected, weekendsObject, weekends)}
                name="Daily"/>
              }
          />
        </MenuItem>
        <hr className={classes.hrDivider} />
        <ActionMenuItems
          id={Object.keys(weekends)[0]}
          label={t(Object.keys(weekends)[0])}
          name={Object.keys(weekends)[0]}
          checked={Object.values(weekends)[0]}
          state={weekends}
          setState={setWeekends}
          handleChange={handleChange}
        />
        {
          Object.entries(weekdays).map((option, index) => (
            <ActionMenuItems
              id={option[0]}
              key={index}
              label={t(option[0])}
              name={option[0]}
              checked={option[1]}
              state={weekdays}
              setState={setWeekdays}
              handleChange={handleChange}
            />
          ))
        }
        <ActionMenuItems
          id={Object.keys(weekends)[1]}
          label={t(Object.keys(weekends)[1])}
          name={Object.keys(weekends)[1]}
          checked={Object.values(weekends)[1]}
          state={weekends}
          setState={setWeekends}
          handleChange={handleChange}
        />
      </Box>
      <Box className={classes.buttonContainer}>
        <Button id="scheduleTimeApplyButton" fullWidth className={classes.button} onClick={handleApplyClone} type="submit" variant="contained" color="primary">{t('apply')}</Button>
      </Box>
    </Menu>
  );
}

const TimeContainer = (props) => {
  const { day, setWeekItem, weekItem, touched, setDeleteItemList } = props;
  const classes = useStyles();
  const { t }   = useTranslation();
  const [anchorEl, setAnchorEl]                 = useState(null);
  const [defaultEndDate, setDefaultEndDate]     = useState(endDateFormat);
  const [defaultStartDate, setDefaultStartDate] = useState(startDateFormat);
  const [filterDay, setFilterDay]               = useState([]);

  const open = Boolean(anchorEl);
  useEffect(() => {
    const filterSelectedDay = () => {
      setFilterDay([]);
      setTimeout(() => {
        setFilterDay(weekItem.filter(filter => filter.day === day));
      }, 1);
    }
    filterSelectedDay();

    return () => {
      setFilterDay([]);
    }
  }, [day, weekItem]);

  const defaultValues = {
    day       : day,
    id        : Math.random(),
    timeFrom  : defaultStartDate,
    timeUntil : defaultEndDate,
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRemoveAllTimePicker = () => {
    const timePickerList = weekItem.filter(element => element.day === day);
    
    timePickerList.map(item => {
      if (item.scheduleItemId) {
        const url = `${api.SCHEDULES_ITEMS}/${item.scheduleItemId}`;
        setDeleteItemList(items => [...items, url]);
      }
      return null;
    });

    setWeekItem(item => item.filter(element => element.day !== day));
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Box className={classes.header}>
        <Grid container spacing={2} className={classes.headerContent}>
          <Grid item xs={6}>
            <Typography className={'bold'} color="secondary">{t(day)}</Typography>
          </Grid>
          <Grid item xs={6} align="right">
            <Tooltip title={t('removeAll')} className={`${classes.actionTools} ${classes.deleteIcon}`}>
              <IconButton id="removeAllButton" aria-label="delete" onClick={handleRemoveAllTimePicker}>
                <FontAwesomeIcon icon={faTrash} size="sm" />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('copy')} className={classes.actionTools}>
              <IconButton aria-label="copy" 
                id="copyButton"
                aria-controls="menu"
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <FontAwesomeIcon icon={faCopy} size="sm" />
              </IconButton>
            </Tooltip>
            <ActionMenu
              open={open}
              anchorEl={anchorEl}
              handleClose={handleClose}
              setWeekItem={setWeekItem}
              weekItem={weekItem}
              setAnchorEl={setAnchorEl}
              day={day}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
      {
        filterDay.map((item, index) => {
          return (
            <AddTimePicker
              key={index}
              day={day}
              item={item}
              setDefaultStartDate={setDefaultStartDate}
              setDefaultEndDate={setDefaultEndDate}
              setWeekItem={setWeekItem}
              timeID={item.id}
              weekItem={weekItem}
              setDeleteItemList={setDeleteItemList}
            />
          )
        })
        }
        <AddTimePicker
          timeID={0}
          day={day}
          item={defaultValues}
          setDefaultStartDate={setDefaultStartDate}
          setDefaultEndDate={setDefaultEndDate}
          setWeekItem={setWeekItem}
          weekItem={weekItem}
          touched={touched}
          setDeleteItemList={setDeleteItemList}
        />
      </Box>
    </Container>
  );
}
export default TimeContainer;