import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, IconButton, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { LOCATION_FILTER } from '../../utility/constants';
import useStyles from './styles';

const AreaItem = (props) => {
  const { items, handleAreaClick, selectedAreas, id, handleLocationClick, selectedLocations, name, area } = props;
  const classes = useStyles();
  
  const [ isSelected, setIsSelected ] = useState(false);
  const [ isCollapsed, setIsCollapsed ] = useState(true);

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  }

  useEffect(() => {
    const selected = selectedAreas.some(selectedArea => selectedArea.areaId === area.areaId);
    setIsSelected(selected);
  }, [selectedAreas, area]);

  return (
    <Accordion expanded={isCollapsed}>
      <AccordionSummary>
        <Grid className={isSelected || area.selected ? classes.selectedAreaContainer : classes.areaItemcontainer}>
          <IconButton
            data-testid="accordion-button"
            id={`${id}CollapseButton`}
            size="small"
            onClick={handleCollapse}
            className={classes.icon}
          >
            <FontAwesomeIcon data-testid={`area-collapse-icon`} icon={isCollapsed ? faAngleDown : faAngleRight}/>
          </IconButton>
          <Grid className={classes.areaName} onClick={() => handleAreaClick(area)}>
            <Typography className={classes.highlightedLabel}>{name}</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {
          items.map(item => (
            item.areaId ?
              <Grid container className={classes.subContainer}>
                <AreaItem
                  area={item}
                  name={item.name}
                  items={item.data}
                  selectedAreas={selectedAreas}
                  selectedLocations={selectedLocations}
                  handleAreaClick={handleAreaClick}
                  handleLocationClick={handleLocationClick}
                />
              </Grid>
            :
              <Grid container className={classes.subContainer}>
                <LocationItem
                  location={item}
                  selectedLocations={selectedLocations}
                  handleLocationClick={handleLocationClick}
                />
              </Grid>
          ))
        }
      </AccordionDetails>
    </Accordion>
  )
}

const LocationItem = (props) => {
  const { handleLocationClick, location, selectedLocations} = props;
  const classes = useStyles();
  
  const [ isSelected, setIsSelected ] = useState(false);

  useEffect(() => {
    const selected = selectedLocations.some(selectedLocation => selectedLocation.locationId === location.locationId);
    setIsSelected(selected);
  }, [selectedLocations, location]);

  return (
    <Grid 
      container
      className={isSelected ? classes.selectedLocationContainer : classes.locationItemContainer}
      onClick={() => handleLocationClick(location)}
    >
      <Grid className={classes.locationName}>
        <Typography>{location.name}</Typography>
      </Grid>
    </Grid>
  )
}

const NestedList = (props) => {
  const { data, handleSelectedItems, single, field, selectedAreas, selectedLocations, setSelectedAreas, setSelectedLocations } = props;
  const classes = useStyles();

  const [ list, setList ] = useState(data);

  const isLocationFilter = field === LOCATION_FILTER;
  
  const flatten = (list) => {
    let children = [];
  
    return list.map(listItem => {
      const item = {...listItem};
      if (item.locationId) {
        return undefined;
      }
      if (item.data?.length) {
        children = [...children, ...item.data];
      }
      return item;
    }).concat(children.length ? flatten(children) : children);
  };

  const areAllSelected = useCallback((data) => {
    for (const item of data) {
      if (item.locationId && !item.selected) {
        return false;
      }
      if (item.data) {
        if (!areAllSelected(item.data)) {
          return false;
        }
      }
    }
    return true;
  }, []);

  const updateAreaState = useCallback((arr) => {
    if (single || isLocationFilter) {
      return;
    }
  
    if (!selectedLocations.length) {
      setSelectedAreas([]);
      return;
    }

    const areaLists = arr.filter(areaList => !areaList.locationId);
    if (!areaLists.length) {
      return;
    }

    for (const areaList of areaLists) {
      const isAreaSelected = areAllSelected(areaList.data);

      setSelectedAreas(prevAreas => {
        if (isAreaSelected) {
          return [...prevAreas, areaList];
        } else {
          return prevAreas.filter(ar => ar.areaId !== areaList.areaId);
        }
      });

      updateAreaState(areaList.data)
    }
  }, [isLocationFilter, setSelectedAreas, single, areAllSelected, selectedLocations]);

  const updateLocationState = useCallback((arr) => {
    if (!arr) {
      return;
    }

    const selectedLocationIds = selectedLocations.map(item => item.locationId);

    Object.keys(arr).some(index => 
      arr[index].data?.length ?
        updateLocationState(arr[index].data)
      : 
        (arr[index].selected = selectedLocationIds.includes(arr[index].locationId), '')
    );
  }, [selectedLocations]);

  const getLocations = (arr) => {
    return arr.data.map(item => {
      if (item.locationId) {
        return item;
      } else {
        return getLocations(item);
      }
    })
  }

  const handleLocationClick = (location) => {
    let newLocations = [];
    let newAreas = isLocationFilter ? [] : selectedAreas;

    const existingLocationIndex = selectedLocations.map(selectedLocation => selectedLocation.locationId).indexOf(location.locationId);

    if ((isLocationFilter || single) &&
        JSON.stringify(selectedLocations[0]) === JSON.stringify(location)) {
      clearSelected();
    }
    
    if (existingLocationIndex > -1) {
      newLocations = selectedLocations.filter(loc => {
        return loc.locationId !== location.locationId;
      })

      if (isLocationFilter && selectedAreas.length > 0) {
        newLocations = [location];
      }
    } else {
      newLocations = isLocationFilter || single ? [location] : [...selectedLocations, location];
    }
    const flattenLocation = newLocations?.flat();
    
    setSelectedLocations(flattenLocation);
    setSelectedAreas(newAreas);
    handleSelectedItems(flattenLocation, newAreas);
  }

  const handleAreaClick = (area) => {
    if (single) {
      return;
    }
    
    const existingAreaIndex = selectedAreas.map(selectedArea => selectedArea.areaId).indexOf(area.areaId);
    
    const areas = flatten([area]).filter(item => item !== undefined);
    const locations = getLocations(area).flat(7);

    let newAreas = [];
    let newLocations = [];
    if (selectedAreas.length > 0 && isLocationFilter) {
      clearSelected();
      if (existingAreaIndex === 1) {
        newAreas = areas;
        newLocations = locations;
      }
    } else if (existingAreaIndex > -1) {
      newAreas = selectedAreas.filter(ar => {
        return !areas.some(area => ar.areaId === area.areaId);
      })

      newLocations = selectedLocations.filter(loc => {
        return !locations.some(location => loc.locationId === location.locationId);
      });
    }
    
    if (existingAreaIndex === -1) {
      const ids = new Set(selectedLocations.map(location => location.locationId));
      const filteredLocations = [...selectedLocations, ...locations.filter(location => !ids.has(location.locationId))];

      newAreas = isLocationFilter ? areas : [...selectedAreas, ...areas];
      newLocations = isLocationFilter ? locations : filteredLocations;
    }

    const flattenLocation = newLocations?.flat();
    setSelectedLocations(flattenLocation);
    setSelectedAreas(newAreas);
    handleSelectedItems(flattenLocation, newAreas);
  }

  const clearSelected = () => {
    setSelectedAreas([]);
    setSelectedLocations([]);
  }

  useEffect(() => {
    updateLocationState(list);
    updateAreaState(list);
  }, [list, updateLocationState, updateAreaState]);

  useEffect(() => {
    setList(data);
  }, [data])

  return (
    list.length > 0 ?
      <Box className={classes.container}>
        {
          list.map(item => (
            item.areaId ?
              <AreaItem
                name={item.name}
                area={item}
                items={item.data}
                selectedAreas={selectedAreas}
                selectedLocations={selectedLocations}
                handleAreaClick={handleAreaClick}
                handleLocationClick={handleLocationClick}
              />
            :
              <LocationItem
                location={item}
                handleLocationClick={handleLocationClick}
                selectedLocations={selectedLocations}
              />
            )
          )
        }
      </Box>
    :
      <></>
  );
}

export default NestedList;