import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    paddingTop    : theme.spacing(2),
    paddingBottom : theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(1),

    '& .MuiTextField-root': {
      marginTop: theme.spacing(0)
    }
  },
  editIcon: {
    direction : 'rtl'
  },
  form: {
    padding       : theme.spacing(1),
    paddingLeft   : theme.spacing(2),
    paddingRight  : theme.spacing(2),
  },
  action: {
    backgroundColor : theme.palette.grey[100],
    padding: theme.spacing(2),
    textAlign: 'right',

    '& .MuiButton-root:first-of-type': {
      marginRight: theme.spacing(2)
    }
  }
}));