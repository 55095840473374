import React from 'react';
import { Paper, Box, Typography, Grid} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import useStyles from './styles';

const LoadingState = () => {
const classes = useStyles()
  return (
    <Box>
      <Paper className={classes.paper} elevation={3}>
        <Grid container spacing={2} className={classes.form}  >
          <Grid item md={2} sm={2}>
            <Typography variant="caption" className={'bold'} color="secondary">Type</Typography>
          </Grid>
          <Grid item md={3} sm={3}>
            <Typography variant="caption" className={'bold'} color="secondary">Sub Type</Typography>
          </Grid>
          <Grid item md={3} sm={3}>
            <Typography variant="caption" className={'bold'} color="secondary">Info 1</Typography>
          </Grid>
          <Grid item md={3} sm={3}>
            <Typography variant="caption" className={'bold'} color="secondary">Info 2</Typography>
          </Grid>
          <Grid item md={1} sm={1}>
            <Typography variant="caption" className={'bold'} color="secondary">Date Created</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.form}  >
          <Grid item md={2} sm={2} className={classes.boxBackground}>
            <Skeleton height={20}/>
          </Grid>
          <Grid item md={3} sm={3} className={classes.boxBackground}>
            <Skeleton height={20}/>
          </Grid>
          <Grid item md={3} sm={3} className={classes.boxBackground}>
            <Skeleton height={20}/>
          </Grid>
          <Grid item md={3} sm={3} className={classes.boxBackground}>
            <Skeleton height={20}/>
          </Grid>
          <Grid item md={1} sm={1} className={classes.boxBackground}>
            <Skeleton height={20}/>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}


export default LoadingState;