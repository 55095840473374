import { createContext, useReducer } from "react";
import { initialState, WizardReducer } from "../reducer/wizardReducer";

export const WizardContext = createContext();

const WizardProvider = ({ children }) => {
  const [state, dispatch] = useReducer(WizardReducer, initialState);

  return (
    <WizardContext.Provider value={{ state, dispatch }}>
      { children }
    </WizardContext.Provider>
  )
}
export default WizardProvider;