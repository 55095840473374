import { FormControl, Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { SETTINGS_CONFIGURATION } from '../../../utility/constants';
import useStyles from './styles';


const SettingsSkeleton = (props) => {
    const { chip } = props;
    const classes = useStyles();
  
    switch (chip) {
      case SETTINGS_CONFIGURATION.VALIDITY_DATES :
        return (
          <>
            <Skeleton className={classes.textTitleSkeleton}/>
            <Skeleton className={classes.textDescriptionSkeleton}/>
            <Grid className={classes.event} item xs={12} sm={12} md={12} lg={12}>
              <FormControl variant="outlined" className={classes.contentSpacing}>
                <Skeleton className={classes.selectSkeleton}/>
              </FormControl>
              <FormControl variant="outlined" className={classes.contentSpacing}>
                <Skeleton className={classes.selectSkeleton}/>
              </FormControl>
            </Grid>
          </>
        )
      case SETTINGS_CONFIGURATION.EVENT_CONFIGURATION :
        return (
          <>
            <Skeleton className={classes.textTitleSkeleton}/>
            <Skeleton className={classes.textDescriptionSkeleton}/>
            <Grid item className={classes.event} xs={9} sm={9} md={9} lg={9}>
              <Typography className={`${classes.contentSpacing} ${classes.centerText}`}>
                <Skeleton className={classes.textTitleSkeleton}/>  
              </Typography>
              <FormControl variant="outlined" className={classes.contentSpacing}>
                <Skeleton className={classes.textFieldSkeleton}/>
              </FormControl>
              <FormControl variant="outlined" className={classes.contentSpacing}>
                <Skeleton className={classes.selectSkeleton}/>
              </FormControl>
            </Grid>
          </>
        )
      case SETTINGS_CONFIGURATION.CONTROLLER_CONFIGURATION :
        return (
          <>
            <Skeleton className={classes.textTitleSkeleton}/>
            <Skeleton className={classes.textDescriptionSkeleton}/>
            <Grid item className={classes.event} xs={9} sm={9} md={9} lg={9}>
              <FormControl variant="outlined" className={classes.contentSpacing}>
                <Skeleton className={classes.textFieldSkeleton}/>
              </FormControl>
              <Typography className={`${classes.contentSpacing} ${classes.centerText}`}>
                <Skeleton className={classes.textTitleSkeleton}/>
              </Typography>
            </Grid>
          </>
        )
      default :
        return (
          <>
            <Skeleton className={classes.textTitleSkeleton}/>
            <Skeleton className={classes.textDescriptionSkeleton}/>
            <Skeleton className={classes.selectSkeleton}/>
          </>
        )
    }
  }

  export default SettingsSkeleton;