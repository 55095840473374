import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, TextField, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import moment from 'moment';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WizardContext } from '../../../../context/wizardContext';
import { WizardCredentialContext } from '../../../../context/wizardCredentialContext';
import { WizardPersonContext } from '../../../../context/wizardPersonContext';
import { SET_NEW_CREDENTIALS } from '../../../../reducer/wizardCredentialReducer';
import { SET_ERROR, SET_NEXT_PAYLOAD } from '../../../../reducer/wizardReducer';
import api from '../../../../service/api';
import { request } from '../../../../service/requests';
import { API_REQUEST_ERROR_MESSAGE, DATE_FORMAT, GET, LOCATIONS_MODULE, MAX_CHARACTER_LIMIT } from '../../../../utility/constants';
import { isArrayEqual } from '../../../../utility/helper';
import { GetInitialLocationId, GetInitialLocationObject } from '../../../../utility/location';
import { wizardCredentialSchema } from '../../../../validation/schema';
import SelectItems from '../../../select-items';
import useStyles from './styles';

const PersonCreateCredentialContent = forwardRef((props, ref) => {
  const classes = useStyles();
  const { showToaster, handlePermissions } = props;

  const { t } = useTranslation();

  const wizardContext     = useContext(WizardContext);
  const personContext     = useContext(WizardPersonContext);
  const credentialContext = useContext(WizardCredentialContext);

  const { prevPage, currentPage } = wizardContext.state;

  const { state } = personContext;
  const { selectedCredentials, credentials, newCredentials } = state;

  const validFrom  = state.user.validFrom;
  const validUntil = state.user.validUntil;

  const initialLocationId = GetInitialLocationId();
  const initialLocationObject = GetInitialLocationObject();

  const locationName = initialLocationObject[0]?.name ? initialLocationObject[0].name : '';

  const [credentialNumber, setCredentialNumber]   = useState('');
  const [description, setDescription]             = useState('');
  const [name, setName]                           = useState('');
  const [newCredential, setNewCredential]         = useState([]);
  const [selectedLocation, setSelectedLocation]   = useState(locationName);
  const [locationObject, setLocationObject]       = useState(initialLocationObject);
  const [locationId, setLocationId]               = useState(initialLocationId);

  const formRef = useRef();

  const initialValues = useMemo(() => {
    return {
      credentialNumber: credentialNumber,
      description     : description,
      name            : name,
      location        : {
        name  : selectedLocation,
        id    : locationId
      }
    }
  }, [credentialNumber, description, name, selectedLocation, locationId]);

  useEffect(() => {
    if (initialValues.location.name) {
      setLocationObject([initialValues.location]);
    }
  }, [initialValues]);

  useEffect(() => {
    const user    = state.user;
    const userName = `${user.lastName}, ${user.firstName}`;
    const isFieldReset = prevPage > 1;
    if (isFieldReset) {
      const seperatedCredentials = credentials.map(credential => credential.name).join(',')
      setCredentialNumber(seperatedCredentials);
      setNewCredential(credentials);
    }

    const payload = {
      checkPage: 3,
      bufferPage: currentPage,
      prevPage: 1
    }

    wizardContext.dispatch({ type: SET_NEXT_PAYLOAD, payload: payload });

    setName(userName);
    
    if (newCredentials[0]) {
      setCredentialNumber(newCredentials[0].credentialNumber);
      setDescription(newCredentials[0].description);
      setSelectedLocation(newCredentials[0].location.name);
      setLocationId(newCredentials[0].location.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => ({
    onNextPage() {
      formRef.current.handleSubmit();
      handleSubmit();
    }
  }));

  const handleSubmit = async () => {
    const { values } = formRef.current
    const isValid = Boolean(values.location.name && values.credentialNumber);
    if (isValid) {
      await handleCheckCredentials(formRef.current.values);
    }
  }

  const handleSelectedLocation = (value) => {
    setSelectedLocation(value[0]?.name);
    setLocationId(value[0]?.locationId);
    setLocationObject(value);
  }

  const handleCheckCredentials = async (formik) => {
    const { isValid } = formRef.current;
    if(!isValid) {
      return;
    }

    const separatedCredentials = formik.credentialNumber.split(",");

    try {
      const response = await request({
        url     : `${api.CREDENTIALS_FIND_BY_CREDENTIAL_NUMBER_IN}`,
        method  : GET,
        params  : {
          credentialNumbers: formik.credentialNumber
        }
      });
      
      const data = response.data._embedded.credentials.length;

      const filterCredentials = (filterValue, values) => {
        return filterValue.filter(item => !values.map(credential => credential.name).includes(item.name));
      }

      const newSelectedCredentials = newCredential.length ? filterCredentials(selectedCredentials, credentials) : selectedCredentials;
      const newSetCredentials = newCredential.length ? filterCredentials(newCredentials, credentials) : newCredentials;
      const isExist = newSelectedCredentials.find(item => separatedCredentials.includes(item.name));

      if (data > 0) {
        formRef.current.setErrors({
          credentialNumber: separatedCredentials.length > 1 ? t('credentialNumberAlreadyExistsMultiple') : t('credentialNumberAlreadyExists')
        });
      } else if (isExist) {
        wizardContext.dispatch({ type: SET_NEXT_PAYLOAD, payload: { nextPage: 4, isNextShow: true, isEndPage : credentialContext } });
        wizardContext.dispatch({ type: SET_ERROR, payload: false });
      } else {
        const selectedCredential = separatedCredentials.map(credential => {
          return {
            id         : Math.random(),
            name       : credential,
            description: formatTime(validFrom) + '-' + formatTime(validUntil),
          }
        });

        const newCredentialsValue = separatedCredentials.map((credential, index) => {
          return {
            name            : credential,
            credentialNumber: credential.trim(),
            description     : description,
            validFrom       : validFrom,
            validUntil      : validUntil,
            active          : 1,
            location        : {
              name: selectedLocation,
              id  : locationId
            }
          }
        }).concat(newSetCredentials);

        const payload = {
          newCredentials    : newCredentialsValue,
          selectedCredential: selectedCredential.concat(newSelectedCredentials),
          credentials       : selectedCredential
        }

        if (hasChanges(selectedCredential, credentials)) {
          showToaster(t('success'), `${formik.credentialNumber} ${t('hasBeen')} ${getToasterMessage()}`, ('success'));
        }

        personContext.dispatch({ type: SET_NEW_CREDENTIALS, payload: payload });
        wizardContext.dispatch({ type: SET_NEXT_PAYLOAD, payload: { nextPage: 4, isNextShow: true, isEndPage : credentialContext } });
        wizardContext.dispatch({ type: SET_ERROR, payload: false });
      }
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }
  }

  const hasChanges = (current, previous) => {
    const currentIds = current.map(item => item.id);
    const previousIds = previous ? previous.map(item => item.id) : [];
    return isArrayEqual(currentIds, previousIds);
  }

  const getToasterMessage = () => {
    return credentials ? t('updated') : t('created');
  }

  const handleChange = (setter, formik) => {
    return (setter, formik);
  }

  const formatTime = (time) => {
    return moment(time).format(DATE_FORMAT);
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={wizardCredentialSchema}
      innerRef={formRef}
    >
      {
        formik => (
          <Form className={classes.formContent}>
            <Grid container spacing={1} className={classes.form}>
              <Grid item xs={12} sm={5}>
                <TextField
                  id="wizardCreateCredentialCredentialNumber"
                  label={`${t('credentialNumber')}*`}
                  name="credentialNumber"
                  fullWidth
                  multiline
                  inputProps={{
                    maxlength: MAX_CHARACTER_LIMIT.TEXT_FIELD
                  }}
                  size="medium"
                  value={formik.values.credentialNumber}
                  onChange={e => handleChange(setCredentialNumber(e.target.value), formik.handleChange(e))}
                  error={formik.touched.credentialNumber && Boolean(formik.errors.credentialNumber)}
                  helperText={t(formik.touched.credentialNumber) && t(formik.errors.credentialNumber)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="wizardCreateCredentialDescription"
                  label={t('description')}
                  name="description"
                  placeholder={t('noDescription')}
                  fullWidth
                  inputProps={{
                    maxlength: MAX_CHARACTER_LIMIT.TEXT_FIELD
                  }}
                  size="medium"
                  value={formik.values.description}
                  onChange={e => handleChange(setDescription(e.target.value), formik.handleChange(e))}
                  multiline
                  rows={4}
                  InputLabelProps={{
                    shrink: Boolean(formik.values.description)
                  }}
                />
                <span
                  className={formik.errors.credentialNumber && formik.touched.credentialNumber ? classes.characterLimitError : classes.characterLimit}>
                  { 
                    t('controller-page.characterLimit', { 
                      currentCharacterCount: formik.values.credentialNumber.length, maxCharacterCount: MAX_CHARACTER_LIMIT.TEXT_FIELD 
                    })
                  }
                </span>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="wizardCreateCredentialPerson"
                  label={t('user')}
                  name="user"
                  fullWidth
                  size="medium"
                  value={formik.values.name}
                  error={formik.touched.user && Boolean(formik.errors.user)}
                  helperText={t(formik.touched.user) && t(formik.errors.user)}
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{
                    readOnly: true
                  }}
                  className={classes.nameTextField}
                />
              </Grid>
              <Grid item xs={12} className={clsx(!handlePermissions(LOCATIONS_MODULE, GET) && 'hidden')}>
                <SelectItems 
                  id="wizardCreateCredentialLocations"
                  name="Locations"
                  onChange={handleSelectedLocation}
                  selectedItems={locationObject}
                  showToaster={showToaster}
                  single={true}
                  helperText={t(formik.touched.location?.name) && t(formik.errors.location?.name)}
                  isValid={formik.touched.location && Boolean(formik.errors.location)}
                  required={true}
                  handlePermissions={handlePermissions}
                />
              </Grid>
              <Grid item xs={12} className={classes.description}>
                <FontAwesomeIcon className={classes.icon} icon={faInfoCircle} />
                <Typography className={classes.text}>{t('credentialPersonDates')}:
                  <span>{formatTime(validFrom) + ' - ' + formatTime(validUntil)}</span>
                </Typography>
              </Grid>
            </Grid>
          </Form>
        )
      }
    </Formik>
  )
});

const CredentialCreateCredentialContent = forwardRef((props, ref) => {
  const classes = useStyles();
  const { showToaster, handlePermissions } = props;

  const { t } = useTranslation();

  const wizardContext     = useContext(WizardContext);
  const credentialContext = useContext(WizardCredentialContext);

  const { prevPage, currentPage } = wizardContext.state;

  const { state } = credentialContext;
  const { selectedCredentials, credentials, newCredentials } = state;

  const validFrom  = state.credentialValues.user.validFrom;
  const validUntil = state.credentialValues.user.validUntil;

  const initialLocationId = GetInitialLocationId();
  const initialLocationObject = GetInitialLocationObject();

  const locationName = initialLocationObject[0]?.name ? initialLocationObject[0].name : '';

  const [credentialNumber, setCredentialNumber]   = useState('');
  const [description, setDescription]             = useState('');
  const [name, setName]                           = useState('');
  const [newCredential, setNewCredential]         = useState([]);
  const [selectedLocation, setSelectedLocation]   = useState(locationName);
  const [locationObject, setLocationObject]       = useState(initialLocationObject);
  const [locationId, setLocationId]               = useState(initialLocationId);

  const formRef = useRef();

  const initialValues = useMemo(() => {
    return {
      credentialNumber: credentialNumber,
      description     : description,
      name            : name,
      location        : {
        name  : selectedLocation,
        id    : locationId
      }
    }
  }, [credentialNumber, description, name, selectedLocation, locationId]);

  useEffect(() => {
    if (initialValues.location.name) {
      setLocationObject([initialValues.location]);
    }
  }, [initialValues]);

  useEffect(() => {
    const user    = state.credentialValues.user;
    const userName = user.name

    const isFieldReset = prevPage > 2;
    if (isFieldReset) {
      const seperatedCredentials = credentials.map(credential => credential.name).join(',')
      setCredentialNumber(seperatedCredentials);
      setNewCredential(credentials);
    }

    const payload = {
      bufferPage: currentPage,
      checkPage : 3,
      prevPage  : 2
    }

    wizardContext.dispatch({ type: SET_NEXT_PAYLOAD, payload: payload });

    setName(userName);
    
    if (newCredentials[0]) {
      setCredentialNumber(newCredentials[0].credentialNumber);
      setDescription(newCredentials[0].description);
      setSelectedLocation(newCredentials[0].location.name);
      setLocationId(newCredentials[0].location.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => ({
    onNextPage() {
      formRef.current.handleSubmit();
      handleSubmit();
    }
  }));

  const handleSubmit = async () => {
    const values = formRef.current.values;
    const isValid = Boolean(values.location.name && values.credentialNumber);
    if (isValid) {
      await handleCheckCredentials(formRef.current.values);
    }
  }

  const handleSelectedLocation = (item) => {
    setSelectedLocation(item[0]?.name);
    setLocationId(item[0]?.locationId);
    setLocationObject(item);
  }

  const handleCheckCredentials = async (formik) => {
    const { isValid } = formRef.current;
    if (!isValid) {
      return;
    }

    const separatedCredentials = formik.credentialNumber.split(",");

    try {
      const response = await request({
        url     : `${api.CREDENTIALS_FIND_BY_CREDENTIAL_NUMBER_IN}`,
        method  : GET,
        params  : {
          credentialNumbers: formik.credentialNumber
        }
      });
      
      const data = response.data._embedded.credentials.length;

      const filterCredentials = (filterValue, values) => {
        return filterValue.filter(item => !values.map(credential => credential.name).includes(item.name));
      }

      const newSelectedCredentials = newCredential.length ? filterCredentials(selectedCredentials, credentials) : selectedCredentials;
      const newSetCredentials = newCredential.length ? filterCredentials(newCredentials, credentials) : newCredentials;
      const isExist = newSelectedCredentials.find(item => separatedCredentials.includes(item.name));

      if (data > 0) {
        formRef.current.setErrors({
          credentialNumber: separatedCredentials.length > 1 ? t('credentialNumberAlreadyExistsMultiple') : t('credentialNumberAlreadyExists')
        });
      } else if (isExist) {
        wizardContext.dispatch({ type: SET_NEXT_PAYLOAD, payload: { nextPage: 5, isNextShow: true, isEndPage : credentialContext } });
        wizardContext.dispatch({ type: SET_ERROR, payload: false });
      } else {
        const selectedCredential = separatedCredentials.map(credential => {
          return {
            id         : Math.random(),
            name       : credential,
            description: formatTime(validFrom) + '-' + formatTime(validUntil),
          }
        });

        const newCredentialsValue = separatedCredentials.map((credential, index) => {
          return {
            name            : credential,
            credentialNumber: credential.trim(),
            description     : description,
            validFrom       : validFrom,
            validUntil      : validUntil,
            active          : 1,
            location        : {
              name: selectedLocation,
              id  : locationId
            }
          }
        }).concat(newSetCredentials);

        const payload = {
          newCredentials    : newCredentialsValue,
          selectedCredential: selectedCredential.concat(newSelectedCredentials),
          credentials       : selectedCredential
        }

        if (hasChanges(selectedCredential, credentials)) {
          showToaster(t('success'), `${formik.credentialNumber} ${t('hasBeen')} ${getToasterMessage()}`, ('success'));
        }

        credentialContext.dispatch({ type: SET_NEW_CREDENTIALS, payload: payload });
        wizardContext.dispatch({ type: SET_NEXT_PAYLOAD, payload: { nextPage: 5, isNextShow: true, prevPage: 4, isEndPage : credentialContext } });
        wizardContext.dispatch({ type: SET_ERROR, payload: false });
      }
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }
  }

  const hasChanges = (currentValue, previousValue) => {
    const currentIds = currentValue.map(item => item.id);
    const previousIds = previousValue ? previousValue.map(item => item.id) : [];
    return isArrayEqual(currentIds, previousIds);
  }

  const getToasterMessage = () => {
    return credentials ? t('updated') : t('created');
  }

  const handleChange = (setter, formik) => {
    return (setter, formik);
  }

  const formatTime = (time) => {
    return moment(time).format(DATE_FORMAT);
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={wizardCredentialSchema}
      innerRef={formRef}
    >
      {
        formik => (
          <Form className={classes.formContent}>
            <Grid container spacing={1} className={classes.form}>
              <Grid item xs={12} sm={5}>
                <TextField
                  id="wizardCreateCredentialCredentialNumber"
                  label={`${t('credentialNumber')}*`}
                  name="credentialNumber"
                  fullWidth
                  inputProps={{
                    maxlength: MAX_CHARACTER_LIMIT.TEXT_FIELD
                  }}
                  size="medium"
                  value={formik.values.credentialNumber}
                  onChange={e => handleChange(setCredentialNumber(e.target.value), formik.handleChange(e))}
                  error={formik.touched.credentialNumber && Boolean(formik.errors.credentialNumber)}
                  helperText={t(formik.touched.credentialNumber) && t(formik.errors.credentialNumber)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="wizardCreateCredentialDescription"
                  label={t('description')}
                  name="description"
                  placeholder={t('noDescription')}
                  fullWidth
                  inputProps={{
                    maxlength: MAX_CHARACTER_LIMIT.TEXT_FIELD
                  }}
                  size="medium"
                  value={formik.values.description}
                  onChange={e => handleChange(setDescription(e.target.value), formik.handleChange(e))}
                  multiline
                  rows={4}
                  InputLabelProps={{
                    shrink: Boolean(formik.values.description)
                  }}
                />
                <span
                  className={formik.errors.description && formik.touched.description ? classes.characterLimitError : classes.characterLimit}>
                  { 
                    t('controller-page.characterLimit', { 
                      currentCharacterCount: formik.values.description.length, maxCharacterCount: MAX_CHARACTER_LIMIT.TEXT_FIELD 
                    })
                  }
                </span>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="wizardCreateCredentialPerson"
                  label={t('user')}
                  name="user"
                  fullWidth
                  size="medium"
                  value={formik.values.name}
                  error={formik.touched.user && Boolean(formik.errors.user)}
                  helperText={t(formik.touched.user) && t(formik.errors.user)}
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{
                    readOnly: true
                  }}
                  className={classes.nameTextField}
                />
              </Grid>
              <Grid item xs={12} className={clsx(!handlePermissions(LOCATIONS_MODULE, GET) && 'hidden')}>
                <SelectItems 
                  id="wizardCreateCredentialLocations"
                  name="Locations"
                  onChange={handleSelectedLocation}
                  selectedItems={locationObject}
                  showToaster={showToaster}
                  single={true}
                  helperText={t(formik.touched.location?.name) && t(formik.errors.location?.name)}
                  isValid={formik.touched.location && Boolean(formik.errors.location)}
                  required={true}
                  handlePermissions={handlePermissions}
                />
              </Grid>
              <Grid item xs={12} className={classes.description}>
                <FontAwesomeIcon className={classes.icon} icon={faInfoCircle} />
                <Typography className={classes.text}>{t('credentialPersonDates')}:
                  <span>{formatTime(validFrom) + ' - ' + formatTime(validUntil)}</span>
                </Typography>
              </Grid>
            </Grid>
          </Form>
        )
      }
    </Formik>
  )
});

export { CredentialCreateCredentialContent, PersonCreateCredentialContent };

