import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  appBarFixed: {
    backgroundColor  : theme.palette.text.secondary,
    bottom           : '0',
    position         : 'fixed',
    top              : 'auto'
  }, 
  appBarAbsolute: {
    backgroundColor  : theme.palette.text.secondary,
    bottom           : 'auto',
    position         : 'absolute',
    top              : 'auto'
  }, 
  link: {
    color: 'white',
    textDecoration  : 'none',
    '&:hover': {
      textDecoration  : 'underline',
      cursor          : 'pointer',
    },
  },
  grow: {
    flexGrow: 1,
  }
}));