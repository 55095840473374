import { lighten, makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  iconButton: {
    backgroundColor: theme.palette.primary.main,
    borderRadius   : theme.spacing(0.5),
    color          : theme.palette.common.white,
    display        : 'inline',
  },
  iconButton2: {
    backgroundColor: colors.WHITE,
    borderRadius   : theme.spacing(0.5),
    color          : colors.PRIMARY,
    display        : 'inline',
    '& .Mui-disabled':{
      color: colors.SECONDARY
    }
  },
  iconButton3: {
    backgroundColor: colors.WHITE,
    borderRadius   : theme.spacing(0.5),
    color          : colors.PRIMARY,
    display        : 'inline',
  },
  blockIcon: {
    height   : theme.spacing(2.5),
  },
  import: {
    marginLeft: theme.spacing(0),
    justifyContent: 'end'
  },
  label: {
    backgroundColor: 'black',
  },
  paper: {
    marginBottom: theme.spacing(5),
    width       : '100%',
  },
  paper2: {
    marginBottom: theme.spacing(5),
    width       : '100%',
    border      : `1px solid ${colors.GRAY}`
  },
  table: {
    '& .Mui-selected, .Mui-selected:hover, .MuiTableRow-hover:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.85),
      color          : theme.palette.secondary.main,
    },
  },
  select: {
    marginLeft    : theme.spacing(-2),
    '& .MuiFilledInput-input': {
      padding     : theme.spacing(1),
      paddingRight: theme.spacing(2.5),
      fontSize    : theme.spacing(1.75),
      width       : theme.spacing(12.5)
    },
    '& .MuiSelect-iconFilled': {
      right: 0,
      color: 'inherit',
    },
    '& .MuiMenuItem-root': {
      fontSize: theme.spacing(1.75)
    },
  },
  light: {
    backgroundColor: theme.palette.primary.light,
    color          : theme.palette.primary.main,
    '&:hover, &:focus, &:visited, &:active': {
      backgroundColor: theme.palette.primary.light,
    }
  },
  info: {
    backgroundColor: '#34ACBB19',
    color          : '#35ACBB',
    '&:hover, &:focus, &:visited, &:active': {
      backgroundColor: '#34ACBB19',
    }
  },
  success: {
    backgroundColor: '#2FB56A19',
    color          : theme.palette.success.main,
    '&:hover, &:focus, &:visited, &:active': {
      backgroundColor: '#2FB56A19',
    }
  },
  tableCard: {
    width:'30%',
    '& .MuiPaper-root, & .MuiPaper-rounded, & .MuiPaper-elevation1': {
      backgroundColor: 'transparent',
      borderRadius   : 0,
      boxShadow      : 'none',
    },
  },
  card: {
    alignContent  : 'center',
    alignItems    : 'center',
    display       : 'flex',
    flexWrap      : 'nowrap',
    justifyContent: 'flex-start',
    '& .MuiCardMedia-img': {
      objectFit: 'contain'
    }
  },
  cardMedia: {
    width : theme.spacing(10),
    height: theme.spacing(10)
  },
  online: {
    alignContent  : 'center',
    alignItems    : 'center',
    color         : theme.palette.success.main,
    cursor        : 'pointer',
    display       : 'flex',
    justifyContent: 'flex-start',
  },
  offline: {
    alignContent  : 'center',
    alignItems    : 'center',
    color         : theme.palette.text.secondary,
    cursor        : 'pointer',
    display       : 'flex',
    justifyContent: 'flex-start',
  },
  invisible: {
    display      : 'none',
  },
  visible: {
    display      : 'block',
  },
  markAllOffline: {
    alignContent  : 'center',
    alignItems    : 'center',
    color         : theme.palette.text.secondary,
    display       : 'flex',
    justifyContent: 'flex-start',
    marginRight   : theme.spacing(1)
  },
  hoverLabel: {
    fontSize      : theme.spacing(2),
    color         : colors.SECONDARY_TEXT,
    '&:hover':{
      cursor      : 'pointer',
      color       : colors.PRIMARY
    }
  },
  pagination: {
    marginTop: theme.spacing(-8)
  },
  tableSize: {
    minHeight: '40vh',
    width    : '100%',
  },
  actionButton: {
    color       : colors.DARK_GRAY_ICON,
    marginLeft  : theme.spacing(1.5),
    marginRight : theme.spacing(1.5),
    padding     : 0
  },
  visuallyHidden: {
    border  : 0,
    clip    : 'rect(0 0 0 0)',
    height  : 1,
    margin  : -1,
    overflow: 'hidden',
    padding : 0,
    position: 'absolute',
    top     : 20,
    width   : 1,
  },
  toolView: {
    display       : 'flex',
    justifyContent: 'space-between',
    margin        : '0 auto',
    position      : 'relative',
    top           : theme.spacing(-8),
    width         : '100%',
  },
  searchField: {
    display : 'block',
    float   : 'left',
    left    : theme.spacing(-0.5),
    margin  : 0,
    position: 'relative',
    top     : theme.spacing(-0.5),
  },
  selectionToolbar: {
    display       : 'flex',
    fontWeight    : 600,
    justifyContent: 'flex-start',
    padding       : theme.spacing(1),
  },
  cutBar: {
    marginTop: theme.spacing(1.5),
    position : 'relative',
    top      : theme.spacing(6),
  },
  actionTools: {
    alignItems    : 'center',
    display       : 'flex',
    justifyContent: 'flex-end',
    marginTop     : theme.spacing(1),
  },
  markAsRead: {
    alignItems    : 'center',
    display       : 'flex',
    justifyContent: 'space-between',
    marginTop     : theme.spacing(1),
    marginRight   : theme.spacing(1),
    width         : 'auto',
    whiteSpace    : 'nowrap'
  },
  markAsReadLabel: {
    fontSize      : theme.spacing(2),
    color         : colors.SECONDARY_TEXT,
    whiteSpace    : 'nowrap',
    userSelect    : 'none',
    '&:hover':{
      cursor      : 'pointer'
    }
  },
  chipsLayout: {
    margin: theme.spacing(0.15)
  },
  chipset: {
    display   : 'flex',
    flexWrap  : 'wrap',
    alignItems: 'center',
  },
  tableIcon: {
    height      : `${theme.spacing(2)} !important`,
    marginRight : theme.spacing(0.75),
    width       : `${theme.spacing(2)} !important`
  },
  nowrapbox: {
    display   : 'table-cell',
    whiteSpace: 'nowrap',
  },
  actionsRow: {
    paddingBottom: 0,
    paddingRight : theme.spacing(1),
    paddingTop   : 0,
    right        : 0,
    width        : '7vw'
  },
  newActions: {
    right      : 0,
    paddingLeft: theme.spacing(4)
  },
  rowChip: {
    padding: theme.spacing(0.25),
  },
  cellStyle: {
    paddingLeft : theme.spacing(2),
    paddingRight: 0,
    textAlign   : 'left'
  },
  tag: {
    borderRadius: theme.spacing(0.2),
    margin      : theme.spacing(0.2),
  },
  tagBody: {
    display      : 'flex',
    flexDirection: 'row',
    padding      : theme.spacing(0.2),
    position     : 'relative',
    width        : 'fit-content',
  },
  tagIcon: {
    alignItems    : 'center',
    display       : 'flex',
    justifyContent: 'center',
    left          : theme.spacing(-0.25),
    margin        : '0 auto',
    position      : 'relative',
  },
  tagTitle: {
    marginTop : theme.spacing(0.25),
    fontWeight: 800
  },
  tagContent: {
    position: 'relative',
    top     : theme.spacing(-0.2),
  },
  root: {
    paddingLeft : theme.spacing(0.4),
    paddingRight: theme.spacing(1),
  },
  highlight: {
    backgroundColor: lighten(theme.palette.primary.main, 0.85),
    color          : theme.palette.secondary.main,
  },
  switch: {
    width  : 52,
    height : 22,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding        : 0,
      margin         : 1.29,
      '&.Mui-checked': {
        color     : theme.palette.common.white,
        marginLeft: theme.spacing(-0.34),
        transform : 'translateX(34px)',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.primary.main,
          border         : 0,
          opacity        : 1,
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width    : 19,
      height   : 19,
    },
    '& .MuiSwitch-track': {
      borderRadius   : 26 / 2,
      backgroundColor: theme.palette.grey[500],
      opacity        : 1,
      transition     : theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  },
  columnHeader: {
    alignContent  : 'center',
    alignItems    : 'center',
    display       : 'contents',
    justifyContent: 'center',
  },
  columnItem: {
    color     : colors.LIGHT_GRAY_TEXT,
    flexWrap  : 'nowrap',
    fontSize  : theme.spacing(1.75),
    fontWeight: '400'
  },
  activeIcon: {
    color     : theme.palette.primary.main,
    flexWrap  : 'nowrap',
    fontSize  : theme.spacing(2),
    fontWeight: '800',
    marginTop : theme.spacing(-0.2),
  },
  inactiveIcon: {
    color     : theme.palette.secondary.main,
    flexWrap  : 'nowrap',
    fontSize  : theme.spacing(2),
    fontWeight: '800',
    marginTop : theme.spacing(0.2),
  },
  eventAction: {
    cursor      : 'pointer',
    marginBottom: theme.spacing(0.5),
    marginLeft  : theme.spacing(2),
    marginTop   : theme.spacing(0.5)
  },
  approveButton: {
    marginBottom      : theme.spacing(1),
    backgroundColor   : theme.palette.success.main,
    color             : theme.palette.common.white,
    marginRight       : theme.spacing(2),
    '&:hover, &:focus' : {
      backgroundColor : theme.palette.success.dark,
    },
  },
  button: {
    marginBottom    : theme.spacing(1),
    marginRight     : theme.spacing(1),
    backgroundColor : theme.palette.error.light,
    '&:hover, &:focus' : {
      backgroundColor : theme.palette.error.dark,
    },
  },
  textNewController: {
    fontWeight: 'bold'
  },
  searchFieldContainer: {
    alignItems        : 'center',
    display           : 'flex',
    width             : '100%'
  },
  unfilteredLink: {
    fontSize          : theme.spacing(2),
    marginBottom      : theme.spacing(0.5),
    marginLeft        : theme.spacing(2)
  },
  moveCellHead: {
    textAlign: 'right'
  },
  text: {
    color   : colors.PRIMARY,
    fontSize: '0.75rem'
  },
  textDisabled: {
    color   : colors.SECONDARY_TEXT,
    fontSize: '0.75rem'
  },
  wordBreak: {
    display   : 'flex',
    wordBreak : 'break-word'
  },
  flexLabel: {
    flexShrink: 0
  },
  selectRow: {
    cursor       : 'pointer',
    maxWidth     : '20vw',
    minWidth     : '10vw',
    overflow     : 'hidden',
    padding      : `${theme.spacing(1.5)} 0 ${ theme.spacing(1.5)} ${ theme.spacing(2)}`,
    textOverflow : 'ellipsis',
    whiteSpace   : 'nowrap'
  },
  row: {
    maxWidth     : '20vw',
    minWidth     : '10vw',
    overflow     : 'hidden',
    padding      : `${theme.spacing(1.5)} 0 ${ theme.spacing(1.5)} ${ theme.spacing(2)}`,
    textOverflow : 'ellipsis',
    whiteSpace   : 'nowrap'
  },
  iconContainer: {
    paddingLeft : 0,
    paddingRight: 0
  },
  icon: {
    height: `${theme.spacing(3)} !important`,
    width : `${theme.spacing(3)} !important`
  },
  emptyHeader: {
    paddingLeft : theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    width       : theme.spacing(6.25)
  },
  dateColumn: {
    minWidth : '5vw',
    padding  : `${theme.spacing(1.5)} 0 ${ theme.spacing(1.5)} ${ theme.spacing(2)}`,
  },
  iconTooltip: {
    color          : colors.DARK_GRAY,
    display        : 'flex',
    gap            : theme.spacing(1),
    justifyContent : 'center',
    marginRight    : theme.spacing(-1)
  },
  customTooltip: {
    backgroundColor: colors.TOOLTIP_DARK_GRAY,
    borderRadius   : theme.spacing(0.25),
    color          : colors.WHITE,
    fontSize       : theme.spacing(1.75),
    textAlign      : 'center'
  }
}));