import moment from 'moment';

export const currentDate = moment();

export const weekdays = moment.weekdays();

export const formatTimeSchedule = (day , hours) => {
  const dates = currentDate.day(day).format("ddd MM DD YYYY");
  return `${dates} ${hours}`
}
export const formatTimeToHour = (time) => {
  return moment(time, "ddd MM DD YYYY HH:mm").format("HH:mm");
}
export const formatTimeToSecond = (time) => {
  return moment(time, "ddd MM DD YYYY HH:mm:ss").format("HH:mm:ss");
}

export const isExist = (items , input) => {
  return  items.some(list =>
    list.day === input.day &&
    formatTimeToSecond(list.timeFrom) === formatTimeToSecond(input.timeFrom) &&
    formatTimeToSecond(list.timeUntil) === formatTimeToSecond(input.timeUntil)
  );
}

export const startDateFormat = moment().set({"hour": 8, "minute": 0, "second": 0, "millisecond": 0});

export const endDateFormat = moment().set({"hour": 18, "minute": 0, "second": 59, "millisecond": 59});

export const DefaultStartDateFormat = moment().set({"hour": 0, "minute": 0, "second": 0, "millisecond": 0});
export const DefaultEndDateFormat = moment().set({"hour": 23, "minute": 59, "second": 59, "millisecond": 59});

export const duplicateSchedule = (timeFrom, timeUntil, number) => {
  const duplicateDay = moment().day(timeFrom).add(number, 'days').format('dddd');
  return {
    day      : duplicateDay,
    id       : Math.random(),
    timeFrom : timeFrom,
    timeUntil: timeUntil,
  }
}

export const getNextDayItem = (nextDayEndDate, item) => {
  if (!nextDayEndDate) {
    return;
  }

  const nextDay = startDateFormat._locale._weekdays.indexOf(item.day);
  return {
    day      : startDateFormat._locale._weekdays[nextDay + 1],
    id       : Math.random(),
    timeFrom : DefaultStartDateFormat,
    timeUntil: nextDayEndDate
  }
}