import { Container } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import DeleteModal from '../../components/delete-modal';
import Table, { createColumn } from '../../components/table';
import Title from '../../components/title';
import { LocationContext } from '../../context/locationContext';
import api from '../../service/api';
import { getCredentialsApi } from '../../service/credentialApi';
import { multipleRequest, request } from '../../service/requests';
import { ALL, API_REQUEST_ERROR_MESSAGE, ASCENDING, CREDENTIAL, CREDENTIALS, CREDENTIALS_MODULE, DATE_FORMAT, DELETE, ENTITY, LOCATION, PATCH } from '../../utility/constants';
import { formatAssociated, convertToUTC, parseIntegerParam, updateURLParams } from '../../utility/helper';
import CredentialsModal from './credentials-modal';
import useStyles from './styles';

export const columns = [
  createColumn("credentialNumber", "credentialNumber", true, "string", false, true),
  createColumn("credentialId", "credentialId", false, "string", true),
  createColumn("user", "user", true, "string"),
  createColumn("validFrom", "validFrom", true, "date"),
  createColumn("validUntil", "validUntil", true, "date"),
  createColumn("location", 'location', true, "string"),
  createColumn("active", "active", true, "component"),
];

const Credentials = (props) => {

  const { t } = useTranslation();
  const { showToaster, handlePermissions } = props;
  const classes = useStyles();
  const history = useHistory();

  const { search, pathname } = history.location;
  const params      = new URLSearchParams(search);
  const query       = params.get('keyword') ? params.get('keyword') : '';
  const pageSize    = params.get('size') ? parseIntegerParam(params.get('size'), 10) : 10;
  const pageNumber  = params.get('page') ? parseIntegerParam(params.get('page'), 1) : 1;
  const pageType    = params.get('credentials') ? params.get('credentials') : ALL.toLowerCase();
  const totalSize   = pageSize > 100 ? 100 : pageSize;
  
  const { state : locationState }                       = useContext(LocationContext);
  const { selectedLocationIds }                         = locationState;
  const [credentials, setCredentials]                   = useState([]);
  const [forDelete, setForDelete]                       = useState([]);
  const [forDeleteName, setForDeleteName]               = useState([]);
  const [isLoading, setIsLoading]                       = useState(false);
  const [keyword, setKeyword]                           = useState(query);
  const [order, setOrder]                               = useState(ASCENDING);
  const [orderBy, setOrderBy]                           = useState(columns[0].id);
  const [page, setPage]                                 = useState(pageNumber === 0 ? 1 : pageNumber);
  const [remove, setRemove]                             = useState(false);
  const [rowsPerPage, setRowsPerPage]                   = useState(totalSize);
  const [showModal, setShowModal]                       = useState(false);
  const [totalCredentials, setTotalCredentials]         = useState(0);
  const [type , setType]                                = useState('single');
  const [assocValues, setAssocValues]                   = useState('');
  const [rowValues, setRowValues]                       = useState([]);
  const [showCredentialsModal, setShowCredentialsModal] = useState(false);
  const [title, setTitle]                               = useState('');
  const [listType, setListType]                         = useState(pageType);

  const urlParams = useMemo(() => ({
    keywordParam :  keyword ? `&keyword=${keyword}` : '',
    entityParam  : `credentials=${listType}`,
    pageParam    : `&page=${page}`,
    sizeParam    : `&size=${rowsPerPage}`,
    history      : history,
    pathname     : pathname
  }), [keyword, listType, page, rowsPerPage, history, pathname]);

  const getCredentialsResponse = async (data) => {
    const { number, size, totalElements, totalPages} = data.page;
    
    const totalNumber = number > totalPages ? totalPages : number;

    const formattedCredentials = data._embedded.credentials.map(credential => {
      const { credentialNumber, user, validFrom, validUntil, active, credentialId, location } = credential;
      return {
        credentialNumber  : credentialNumber,
        credentialId      : credentialId,
        person            : user ? `${user.lastName}, ${user.firstName}` : '-',
        validFrom         : convertToUTC(validFrom).format(DATE_FORMAT),
        validUntil        : convertToUTC(validUntil).format(DATE_FORMAT),
        location          : location ? location.name : '-',
        active            : active === 1,
      }
    });

    if (formattedCredentials.length || number === 0) {
      setPage(totalNumber + 1);
      setCredentials(formattedCredentials);
    } else {
      setPage(totalNumber);
    }
    
    setRowsPerPage(size);
    setTotalCredentials(totalElements);
  }

  const getUrl = useCallback(() => {
    if (orderBy === ENTITY.USER) {
      return (order === ASCENDING) ? api.CREDENTIALS_SEARCH_ORDER_BY_USER_ASC : api.CREDENTIALS_SEARCH_ORDER_BY_USER_DESC;
    } else if (orderBy === LOCATION) {
      return (order === ASCENDING) ? api.CREDENTIALS_SEARCH_ORDER_BY_LOCATION_NAME_ASC : api.CREDENTIALS_SEARCH_ORDER_BY_LOCATION_NAME_DESC;
    } else {
      return (keyword === '') ? api.CREDENTIALS : api.CREDENTIALS_SEARCH;
    }
  }, [keyword, order, orderBy])

  const getParams = useCallback(() => {
    return {
      keyword,
      rowsPerPage,
      page,
      orderBy,
      order,
    }
  }, [keyword, order, orderBy, page, rowsPerPage])

  const getCredentials = useCallback(async () => {
    remove ? setIsLoading(false) : setIsLoading(true);
    const params = getParams();
    const url = getUrl();
    try {
      const response = await getCredentialsApi(params, url);
      getCredentialsResponse(response);
    } catch (error) {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setIsLoading(false);
    }
  }, [getParams, getUrl, remove, showToaster, t])

  const getData = useCallback(async () => {
    if (listType === ALL.toLowerCase()) {
      setTitle(CREDENTIALS);
      await getCredentials();
    } else {
      setListType(ALL.toLowerCase());
    }
  }, [getCredentials, listType])

  useEffect(() => {
    if (remove) {
      getData();
    }
    setRemove(false);
  }, [remove, getData])

  useEffect(() => {
    let delayDebounce;

    if (keyword) {
      setIsLoading(true);
      delayDebounce = setTimeout(() => {
        updateURLParams(urlParams);
        getData();
      }, 1000);
    } else {
      updateURLParams(urlParams);
      getData();
    }

    return () => {
      delayDebounce && clearTimeout(delayDebounce);
    }
  }, [page, rowsPerPage, order, orderBy, keyword, listType, selectedLocationIds, urlParams, getData]);

  const handleSearch = (value) => {
    setPage(1);
    setKeyword(value);
  }

  const handleSort = (newOrderBy, newOrder) => {
    setPage(1);
    setOrderBy(newOrderBy);
    setOrder(newOrder);
  }

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setPage(1);
    setRowsPerPage(newRowsPerPage);
  }

  const handleChangePage = (newPage) => {
    if (isLoading) {
      return;
    }

    setPage(newPage + 1);
  }

  const handleView = (value) => {
    const { credentialId } = value;

    history.push(`/credentials/view/${credentialId}`);
  }

  const handleCreate = () => {
    history.push("/credentials/create");
  }

  const handleUpdate = (value) => {
    const { credentialId } = value;

    history.push(`/credentials/update/${credentialId}`);
  }

  const handleChangeAction = (row) => {
    setRowValues(row);
    setShowCredentialsModal(true);
  }

  const handleChangeActive = async () => {
    const { credentialId, active, credentialNumber } = rowValues;
    try {
      await request({
        url     : `${api.CREDENTIALS}/${credentialId}`,
        method  : PATCH,
        data    : {
          active : !active ? 1 : 0
        }
      });
      setRemove(true);
      setKeyword('');
      showToaster(t('success'), `${credentialNumber} ${t('hasBeenUpdated')}`, 'success');
    } catch (error) {
      setRemove(true);
      setKeyword('');
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      handleCloseCredentialsModal();
    }
  }

  const handleDelete = (value) => {
    if (value.person === '-') {
      handleOpenDeleteModal(value, value.credentialNumber, 'single')
    } else {
      setAssocValues(` ${value.person}`);
      handleOpenDeleteModal(value, value.credentialNumber, 'singleWithAssociated')
    }
  }

  const singleDelete = async (value) => {
    const { credentialId } = value;
    try {
      await request({
        url     : `${api.CREDENTIALS}/${credentialId}`,
        method  : DELETE
      });
      setIsLoading(false);
      setRemove(true);
      showToaster(t('success'), `${value.credentialNumber} ${t('hasBeenDeleted')}`, 'success');
    } catch (error) {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setRemove(true);
    }
  }

  const handleMultipleDelete = (values) => {
    const hasAssoc = values.some((value) => value.person !== '-')
    if (hasAssoc) {
      setAssocValues(` ${formatAssociated([...new Set(values.filter(value => {
        return (value.person === '-' ? false : true)}
        ).map(val => val.person))
      ], t)}`);
      if (values.length === 1) {
        handleOpenDeleteModal(values[0], values[0].credentialNumber, 'singleWithAssociated')
      } else {
        handleOpenDeleteModal(values, '', 'multipleWithAssociated')
      }
    } else if (values.length === 1) {
      handleOpenDeleteModal(values[0], values[0].credentialNumber, 'single');
    } else {
      handleOpenDeleteModal(values, '', 'multiple')
    }
  }

  const multipleDelete = async (values) => {
    const ids                  = values.map(value => value.credentialId);
    const credentialNumbers    = values.map(value => value.credentialNumber);
    const lastCredentialNumber = credentialNumbers.pop();

    try {
      await multipleRequest(
        ids.map(id =>
          request({
            url     : `${api.CREDENTIALS}/${id}`,
            method  : DELETE
          })
        )
      )
      setIsLoading(false);
      setRemove(true);
      showToaster(t(`success`), `${credentialNumbers.join(', ')}, ${t('and')} ${lastCredentialNumber} ${t('hasBeenDeleted')}`, 'success');
    } catch (error) {
      setRemove(true);
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }
  }

  const handleOpenDeleteModal = (value, name, paramType) => {
    setForDelete(value);
    setForDeleteName(name);
    setType(paramType)
    setShowModal(true);
  }

  const handleCloseDeleteModal = () => {
    setShowModal(false);
  }

  const handleCloseCredentialsModal = () => {
    setShowCredentialsModal(false);
  }

  const handleType = () => {
    setListType(ALL.toLowerCase());
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Title title={t(title)} listType={listType}/>
      <DeleteModal
        assocValues={assocValues}
        forDeleteName={forDeleteName}
        initialValues={forDelete}
        module={CREDENTIALS_MODULE}
        multipleDelete={multipleDelete}
        onClose={handleCloseDeleteModal}
        open={showModal}
        showToaster={showToaster}
        singleDelete={singleDelete}
        type={type}
      />
      <CredentialsModal
        handleChangeActive={handleChangeActive}
        initialValues={rowValues}
        onClose={handleCloseCredentialsModal}
        open={showCredentialsModal}
      />
      <Table
        columns={columns}
        data={credentials}
        isLoading={isLoading}
        keyword={keyword}
        label={CREDENTIAL}
        module={CREDENTIALS_MODULE}
        onChangePage={handleChangePage}
        onClearSearch={() => handleSearch('')}
        onCreate={handleCreate}
        onDelete={handleDelete}
        onMultipleDelete={handleMultipleDelete}
        onRowsPerPageChange={handleRowsPerPageChange}
        onSearch={handleSearch}
        onSort={handleSort}
        onUpdate={handleUpdate}
        onView={handleView}
        onChangeAction={handleChangeAction}
        orderBy={orderBy}
        order={order}
        page={page}
        rowsPerPage={rowsPerPage}
        totalItems={totalCredentials}
        viewKey={'credentialNumber'}
        listType={listType}
        onViewAll={handleType}
        handlePermissions={handlePermissions}
      />
    </Container>
  );
}

export default Credentials;