import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Container, Grid, IconButton, Menu, MenuItem, Paper, TextField, Tooltip, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import NestedMenuItem from 'material-ui-nested-menu-item';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory } from 'react-router';
import SelectItems from '../../components/select-items';
import Title from '../../components/title';
import UnsavedModal from '../../components/unsaved-modal';
import { AuthContext } from '../../context/authContext';
import { LocationContext } from '../../context/locationContext';
import api from '../../service/api';
import { multipleRequest, request } from '../../service/requests';
import { API_REQUEST_ERROR_MESSAGE, DELETE, GET, LOCATIONS_MODULE, MAX_CHARACTER_LIMIT, PATCH, POST, PROJECTION, PUT, SCHEDULES_MODULE } from '../../utility/constants';
import { GetInitialLocationId, GetInitialLocationObject, assignLocation, getLocation, hasLocationChange, unassignLocation } from '../../utility/location';
import { scheduleSchema } from '../../validation/schema';
import ScheduleSkeleton from './schedule-skeleton';
import ScheduleTable from './schedule-table';
import TimeContainer from './schedule-time';
import { currentDate, formatTimeSchedule, formatTimeToSecond } from './schedule-utils';
import useStyles from './styles';

const ActionMenu = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setWeekItem, weekItem, scheduleList, setDeleteItemList } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteItems = () => {
    weekItem.map(item => {
      if (item.scheduleItemId) {
        const url = `${api.SCHEDULES_ITEMS}/${item.scheduleItemId}`;
        setDeleteItemList(items => [...items, url]);
      }
      return null;
    });
  }

  const handleClearWeek = () => {
    handleDeleteItems();
    setWeekItem([]);
    setAnchorEl(null);
  }

  const handleUseTemplate = (_, itemLists) => {
    const template = itemLists.map(item => {
      return {
        id        : Math.random(),
        day       : item.day,
        timeFrom  : formatTimeSchedule(item.day, item.timeFrom),
        timeUntil : formatTimeSchedule(item.day, item.timeUntil)
      }
    });

    handleDeleteItems();
    setWeekItem(template);
  }

  return (
    <div>
      <Button
        id="actionButton"
        aria-controls="menu"
        aria-haspopup="true"
        aria-expanded={open}
        onClick={handleClick}
        className={classes.button}
        variant="outlined"
        color="primary"
      >
        {t('actions')}
      </Button>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        onClose={handleClose}
        open={open}
        MenuListProps={{
          'aria-labelledby': 'button',
          onMouseLeave: handleClose
        }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <NestedMenuItem
          label={t('useTemplate')}
          id="useTemplate"
          className={classes.nestedMenu}
          parentMenuOpen={Boolean(anchorEl)}
        >
          <Box>
            {
              scheduleList.map((list, index) => {
                return (
                  <MenuItem
                    id={`${list.name}Template`}
                    key={index}
                    onClose={handleClose}
                    onClick={event => handleUseTemplate(event, list.scheduleItems)}
                  >
                    {list.name}
                  </MenuItem>
                )
              })
            }
          </Box>
        </NestedMenuItem>
        <MenuItem id="clearWeek" onClose={handleClose} onClick={handleClearWeek}>{t('clearWeek')}</MenuItem>
      </Menu>
    </div>
  );
}

const Content = (props) => {
  const classes = useStyles();
  const { t }   = useTranslation();
  const { initialValues, id, handleSubmit, showToaster, setName, day, disabled, weekItem, setWeekItem, alignment, handleAlignment, scheduleList, setDeleteItemList, showLoading, formSubmitting, handleCancel, selectedLocation, handleSelectedLocation, handlePermissions } = props


  const getButtonLabel = () => {
    return id ? `${t('update')}` : `${t('create')}`;
  }

  const handleChange = (setter, formik) => {
    return (setter, formik);
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={scheduleSchema}
      onSubmit={handleSubmit}
    >
      {
        formik => (
          <Form>
            {showLoading(formik.isSubmitting)}
            <Paper className={clsx(disabled ? classes.paper : classes.restrictedPaper)} elevation={3}>
              <Grid id="scheduleForm" container spacing={2} className={classes.form}>
                <Grid item xs={disabled ? 12 : 8}>
                  <Grid container spacing={2}>
                    <Grid item xs={5} className={classes.name}>
                      <TextField
                        inputProps={{
                          readOnly: disabled,
                          maxlength: MAX_CHARACTER_LIMIT.TEXT_FIELD
                        }}
                        disabled={disabled}
                        fullWidth
                        multiline
                        id="scheduleName"
                        label={`${t('name')}*`}
                        name="name"
                        value={formik.values.name}
                        onChange={e => handleChange(setName(e.target.value), formik.handleChange(e))}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={t(formik.touched.name) && t(formik.errors.name)}
                      />
                    </Grid>
                    <Grid item className={clsx(disabled ? 'hidden' : classes.actions)} align="right" xs={6} >
                      <Tooltip title={t('clickAddTooltip')} className={classes.tooltip}>
                        <IconButton id="helpTooltip" aria-label="help">
                          <FontAwesomeIcon icon={faQuestionCircle} size="sm" />
                        </IconButton>
                      </Tooltip>
                      <ActionMenu
                        setWeekItem={setWeekItem}
                        weekItem={weekItem}
                        day={day}
                        scheduleList={scheduleList}
                        setDeleteItemList={setDeleteItemList}
                      />
                    </Grid>
                    <Grid item xs={5} className={clsx(!handlePermissions(LOCATIONS_MODULE, GET) ? 'hidden' : classes.name)}>
                      <SelectItems
                        id="scheduleLocation"
                        disabled={disabled}
                        helperText={t(formik.touched.location) && t(formik.errors.location)}
                        isValid={formik.touched.location && Boolean(formik.errors.location)}
                        name="Locations"
                        onChange={handleSelectedLocation}
                        selectedItems={selectedLocation}
                        showToaster={showToaster}
                        single={true}
                        required={true}
                        handlePermissions={handlePermissions}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <ScheduleTable
                        disabled={disabled}
                        weekItem={weekItem}
                        alignment={alignment}
                        setWeekItem={setWeekItem}
                        handleAlignment={handleAlignment}
                        setDeleteItemList={setDeleteItemList}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className={clsx(disabled && 'hidden')} item xs={4}>
                  <TimeContainer
                    day={day}
                    disabled={disabled}
                    weekItem={weekItem}
                    setWeekItem={setWeekItem}
                    touched={formik.touched.weekItem}
                    setDeleteItemList={setDeleteItemList}
                  />
                </Grid>
              </Grid>
              <Grid container className={clsx(disabled ? 'hidden' : classes.action)}>
                <Grid item xs={12}>
                  <Button
                    id="scheduleCancelButton"
                    className={classes.button}
                    onClick={handleCancel}
                    variant="outlined"
                    color="primary"
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    id="scheduleSubmitButton"
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => formSubmitting(true)}
                  >
                    { getButtonLabel() }
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Form>
        )
      }
    </Formik>
  )
}

const Schedule = (props) => {
  const { showToaster, match, showLoading, handlePermissions } = props;
  const { id }    = props.match.params;
  const path      = match.path;
  const disabled  = path.includes('view');

  const classes = useStyles();

  const history = useHistory();

  const { t } = useTranslation();

  const { state: authContext } = useContext(AuthContext);
  const { administrator }      = authContext;
  
  const { state }  = useContext(LocationContext);
  
  const initialLocationId = GetInitialLocationId();
  const initialLocationObject = GetInitialLocationObject();

  const [day, setDay]                             = useState(currentDate.format('dddd'));
  const [alignment, setAlignment]                 = useState(day);
  const [deleteItemList, setDeleteItemList]       = useState([]);
  const [isFormSubmitting, setIsFormSubmitting]   = useState(false);
  const [isLoading, setIsLoading]                 = useState(false);
  const [name, setName]                           = useState('');
  const [prevValues, setPrevValues]               = useState([]);
  const [scheduleList, setScheduleList]           = useState([]);
  const [showModal, setShowModal]                 = useState(false);
  const [weekItem, setWeekItem]                   = useState([]);
  const [withChanges, setWithChanges]             = useState(false);
  const [toRedirect, setToRedirect]               = useState('');
  const [location, setLocation]                   = useState('');
  const [selectedLocation, setSelectedLocation]   = useState(initialLocationId);
  const [locationObject, setLocationObject]       = useState(initialLocationObject);
  const [profiles, setProfiles]                   = useState([]);
  
  const initialValues = useMemo(() => {
    return {
      name      : name,
      weekItem  : weekItem,
      location  : selectedLocation || selectedLocation === undefined ? selectedLocation : location
    }
  }, [name, weekItem, selectedLocation, location]);

  const scheduleDataChanges = useCallback(() => {
    const initialValidUntil = (value) => {
      return (value) ? moment(value).format('HH:MM:ss') : null;
    }

    const newObj = (values) => {
      return values.map(item => {
        return {
          day       : item.day,
          timeFrom  : initialValidUntil(item.timeFrom),
          timeUntil : initialValidUntil(item.timeUntil)
        }
      })
    }
    const newPrev    = newObj(prevValues.weekItem);
    const newInitial = newObj(initialValues.weekItem);
    
    const changes = prevValues.name === initialValues.name 
    && JSON.stringify(newPrev) === JSON.stringify(newInitial);
    return changes;
  }, [initialValues, prevValues])

  const handleChanges = useCallback(() => {
    if (prevValues.weekItem) {
      if (scheduleDataChanges()
        && !hasLocationChange(location, locationObject[0]?.locationId, administrator.locations.length, state)) {
        setWithChanges(false)
      } else {
        setWithChanges(true)
      }
    }
  }, [location, prevValues, scheduleDataChanges, locationObject, administrator, state]);

  useEffect(() => {
    setDay(alignment);

    return () => {
      setDay();
    }
  }, [alignment]);

  const handleCloseModal = () => {
    setShowModal(false);
  }

  const handleCancel = () => {
    history.push('/schedules');
  }

  const handleModalSubmit = () => {
    setWithChanges(false)
    history.push(toRedirect);
  }

  const handleModalCancel = () => {
    handleChanges();
    setShowModal(false);
  }

  const getToastMessage = () => {
    return id ? t(`updated`) : t(`created`);
  }

  const getSchedules = useCallback(async () => {
    try {
      setIsLoading(true);

      const response = await request({
        url    : api.SCHEDULES,
        method : GET,
        params : {
          projection : PROJECTION.SCHEDULES
        }
      });
      
      const { data } = response;
      const scheduleLists = data._embedded.schedules.map(list => {
        return {
          name          : list.name,
          scheduleItems : list.scheduleItems,
        }
      });

      setScheduleList(scheduleLists);
    } catch (error) {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setIsLoading(false);
    }
  }, [showToaster, t]);

  const getScheduleByIdAndScheduleItems = useCallback(async () => {
    try {
      setIsLoading(true);

      const response = await request({
        url    : api.SCHEDULES_BY_ID,
        method : GET,
        params : {
          scheduleId : id,
          projection : PROJECTION.SCHEDULES
        }
      });

      const { data } = response;
      const scheduleItems = data.scheduleItems;
      const scheduleName  = data.name;
      const profileIds    = data.profiles.map(profile => profile.profileId);

      getLocation(data, setLocation, setLocationObject);

      scheduleItems.forEach(item => {
        item.id        = Math.random();
        item.timeFrom  = formatTimeSchedule(item.day, item.timeFrom);
        item.timeUntil = formatTimeSchedule(item.day, item.timeUntil);
      });

      setPrevValues({
        name     : scheduleName,
        weekItem : scheduleItems
      });

      setWeekItem(scheduleItems);
      setName(scheduleName);
      setProfiles(profileIds);
    } catch (error) {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setIsLoading(false);
    }
  }, [id, showToaster, t]);

  const handleSubmit = (values, formik) => {
    const { setSubmitting } = formik;
    
    if (!withChanges) {
      setSubmitting(false);
      setWithChanges(false);
      history.push('/schedules');
      return;
    }

    if (values.weekItem.length) {
      if (deleteItemList.length) {
        deleteScheduleItem(deleteItemList, values, formik);
      } else {
        saveSchedule(values, formik);
      }
    } else {
      formSubmitting(false);
      setSubmitting(false);
    }
  }

  const saveScheduleItems = async (values, link) => {
    if (id) {
      await updateScheduleItems(values)
    } else {
      await createScheduleItems(values, link);
    }
  }

  const createScheduleItems = async (values, link) => {
    const scheduleItem = values.weekItem.map(value => {
      delete value['id'];
      return {
        ...value,
        timeFrom   : formatTimeToSecond(value.timeFrom),
        timeUntil  : formatTimeToSecond(value.timeUntil),
        scheduleId : link.replace(`${api.SCHEDULES}/`, '')
      }
    });

    return multipleRequest(
      scheduleItem.map(item =>
        request({
          url    : api.SCHEDULES_ITEMS,
          method : POST,
          data   : {
            day       : item.day,
            scheduleId: item.scheduleId,
            timeFrom  : item.timeFrom,
            timeUntil : item.timeUntil,
          }
        })
      )
    );
  }

  const updateScheduleItems = async (values) => {
    return multipleRequest(
      values.weekItem.map(item =>
        request({
          url    : item.scheduleItemId ? `${api.SCHEDULES_ITEMS}/${item.scheduleItemId}` : api.SCHEDULES_ITEMS,
          method : item.scheduleItemId ? PATCH : POST,
          data   : {
            day       : item.day,
            scheduleId: id,
            timeFrom  : formatTimeToSecond(item.timeFrom),
            timeUntil : formatTimeToSecond(item.timeUntil),
          }
        })
      )
    );
  }

  const assignLocationToSchedule = async (link) => {
    if (location === locationObject[0]?.locationId) {
      return;
    }

    if (location && selectedLocation) {
      await unassignLocation(link);
    } 

    await assignLocation(link, selectedLocation);
  }

  const assignProfileToSchedule = async (link) => {
    if (profiles.length === 0) {
      return
    }

    const profileUrl = profiles.map(profile => `${api.PROFILES}/${profile}`).join(`\r\n`);

    return request({
      url     : `${link}/profiles`,
      method  : PUT,
      headers : {
        'Content-Type' : 'text/uri-list',
        'x-type'       : true
      },
      data    : profileUrl
    });
  }

  const deleteScheduleItem = async (deleteItems, values, formik) => {
    const { setSubmitting } = formik;

    try {
      await multipleRequest(
        deleteItems.map(url =>
          request({
            url    : url,
            method : DELETE,
          })
        )
      );
      
      saveSchedule(values, formik);
    } catch (error) {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setWithChanges(false);
      setSubmitting(true);
      formSubmitting(true);
    }
  }

  const saveSchedule = async (values, formik) => {
    const { setErrors, setSubmitting } = formik;

    try {
      const response = await request({
        url    : id ? `${api.SCHEDULES}/${id}` : api.SCHEDULES,
        method : id ? PATCH : POST,
        data   : {
          name : values.name
        }
      });

      const link  = response.data._links.self.href;    

      await saveScheduleItems(values, link);
      await assignLocationToSchedule(link);

      if (!scheduleDataChanges()) {
        await assignProfileToSchedule(link);
      }

      showToaster(t(`success`), `${values.name} ${t('hasBeen')} ${getToastMessage()}`, 'success');
      setWithChanges(false);
      history.push('/schedules');
    } catch (error) {
      if (error?.response?.status === 409) {
        setErrors({
          name: t(`nameAlreadyExists`)
        });
      } else {
        showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
      }
    } finally {
      formSubmitting(false);
      setSubmitting(false);
    }
  }

  const handleAlignment = (_, newAlignment) => {
    setAlignment(newAlignment ? newAlignment : alignment);
  };

  const handleSelectedLocation = (value) => {
    setSelectedLocation(value[0]?.locationId);
    setLocationObject(value);
  }

  const formSubmitting = (value) => {
    setIsFormSubmitting(value);
  }

  useEffect(() => {
    const setScheduleValues = async() => {
      setPrevValues({
        name      : '',
        weekItem  : []
      });

      if (id) {
        await getScheduleByIdAndScheduleItems();
      }

      await getSchedules();
    }

    setScheduleValues();
    
    return () => {
      setWeekItem([]);
      setDay();
      setName();
      setIsLoading();
    };
  }, [getScheduleByIdAndScheduleItems, getSchedules, id]);

  useEffect(() => {
    handleChanges();
  }, [handleChanges]);

  return (
    <Container maxWidth="xl" className={classes.container}>
      <UnsavedModal
        open={showModal}
        onClose={handleCloseModal}
        handleModalSubmit={handleModalSubmit}
        handleModalCancel={handleModalCancel}
      />
      <Prompt
        when={withChanges}
        message={(location, action) => {
          if (action === 'PUSH') {
            setShowModal(true)
          }
          setWithChanges(false);
          setToRedirect(location.pathname);
          return location.pathname === '/' || location.pathname.startsWith('/schedules/update') || location.pathname.startsWith('/schedules/create')
        }}
      />
      <Box>
        <Title title={t(`schedules`)} subtitle={name} />
        <Grid container>
          <Grid item xs={6}>
            <Typography className={'bold'} color="secondary">{t('details')}</Typography>
          </Grid>
          <Grid item xs={6} align="right" className={clsx((path.includes('view') && handlePermissions(SCHEDULES_MODULE, PATCH)) ? '' : 'hidden')}>
            <Tooltip title={t('update')}  className={clsx(disabled ? '' : 'hidden')}>
              <IconButton id={`scheduleUpdateButton`} aria-label="Update" onClick={() => history.push(`../update/${id}`)}>
                <Edit className={classes.editIcon} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        {
          isLoading ?
            <ScheduleSkeleton id={id} disabled={disabled} />
            :
            <Content
              alignment={alignment}
              day={day}
              disabled={disabled}
              formSubmitting={formSubmitting}
              handleAlignment={handleAlignment}
              handleCancel={handleCancel}
              handleSubmit={handleSubmit}
              id={id}
              initialValues={initialValues}
              isFormSubmitting={isFormSubmitting}
              scheduleList={scheduleList}
              setDay={setDay}
              setDeleteItemList={setDeleteItemList}
              setName={setName}
              setWeekItem={setWeekItem}
              showLoading={showLoading}
              weekItem={weekItem}
              selectedLocation={locationObject}
              handleSelectedLocation={handleSelectedLocation}
              showToaster={showToaster}
              handlePermissions={handlePermissions}
            />
        }
      </Box>
    </Container>
  );
}

export default Schedule;