import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  eventTypeContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center'
  },
  eventSubTypeContainer: {
    whiteSpace   : 'nowrap',
    overflow     : 'hidden',
    textOverflow : 'ellipsis'
  },
  eventIcon: {
    backgroundColor : colors.LIGHT_GRAY_ICON,
    borderRadius    : theme.spacing(1),
    maxWidth        : theme.spacing(5),
    minWidth        : theme.spacing(5),
    maxHeight       : theme.spacing(4),
    minHeight       : theme.spacing(4),
    padding         : theme.spacing(1),
    display         : 'flex',
    justifyContent  : 'center',
    alignItems      : 'center'
  },
  iconTooltip: {
    backgroundColor: colors.TOOLTIP_DARK_GRAY,
    borderRadius   : theme.spacing(0.25),
    color          : colors.WHITE,
    cursor         : 'pointer',
    fontSize       : theme.spacing(1.75),
    textAlign      : 'center'
  },
}));