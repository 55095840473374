import { createContext, useReducer } from "react";
import { EventsFilterReducer, initialState } from "../reducer/eventsFilterReducer";

export const EventsFilterContext = createContext();

const EventsFilterProvider = ({ children }) => {
  const [state, dispatch] = useReducer(EventsFilterReducer, initialState);

  return (
    <EventsFilterContext.Provider value={{ state, dispatch }}>
      {children}
    </EventsFilterContext.Provider>
  )
}
export default EventsFilterProvider;