import { makeStyles, lighten } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  container: {
    paddingTop   : theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  root: {
    display: 'flex',
  },
  chartContainer: {
    borderRadius    : '4px',
    boxShadow       : theme.shadows[5],
    maxWidth        : '100%',
    minWidth        : '100%',
    height          : '100%',
    padding         : theme.spacing(0),
    '& .highcharts-credits': {
      display       : 'none'
    },
    '& .highcharts-axis-title': {
      fill          : colors.PRIMARY_TEXT +'!important',
      fontSize      : '14px'
    },
    '& .highcharts-legend-item': {
      '& b': {
        fontSize        : '16px !important',
        textTransform   : 'capitalize'
      },
    }
  },
  charts: {
    height          : '400px'
  },
  paper: {
    height          : '100%',
    padding         : theme.spacing(2),
  },
  moduleContainer: {
    '&:hover': {
      cursor        : 'pointer'
    }
  },
  event: {
    display         : 'flex',
    alignItems      : 'center',
    justifyContent  : 'flex-start',
    minWidth        : '100%'
  },
  header: {
    display         : 'flex',
    alignItems      : 'center',
    justifyContent  : 'space-between',
    marginRight     : '-16px',
  },
  chartName: {
    borderBottom    : '3px solid' + colors.PRIMARY,
    paddingTop      : theme.spacing(1),
    paddingBottom   : theme.spacing(2),
    '& .MuiTypography-root': {
      fontSize        : theme.spacing(2.5),
      fontWeight      : 'bold',
    }
  },
  datalabels: {
    '& text':{
      fontSize      : '16px !important',
      fontStyle     : 'Normal',
      fontFamily    : 'Arial',
    },
    '& .highcharts-text-outline': {
      strokeWidth   : 0,
    }
  },
  chipDetailsNumberCount: {
    display         : 'flex',
    alignItems      : 'center',
    justifyContent  : 'flex-start',
    paddingRight    : theme.spacing(5),
  },
  chipDetailsTable: {
    maxHeight       : '230px',
    minHeight       : '230px',
    overflowY       : 'scroll',
  },
  chipListContainer: {
    maxHeight       : '620px',
    minHeight       : '620px',
    overflowY       : 'scroll',
  },
  chipDetailsContent: {
    marginTop : '15px'
  },
  panelTablePaper: {
    height          : '100%',
    padding         : theme.spacing(2),
  },
  panelTableBox: {
    height: 'calc(100% - 83px)'
  },
  hoverBold: {
    '&:hover': {
      cursor          : 'pointer',
      backgroundColor : lighten(theme.palette.primary.main, 0.85),
      color           : 'black'
    }
  },
  empty: {
    alignContent    : 'center',
    alignItems      : 'center',
    display         : 'flex',
    flexDirection   : 'column',
    justifyContent  : 'center',
    paddingTop      : theme.spacing(5)
  },
  chipDetailsContainer: {
    height   : 'calc(100% + 24px)',
    minWidth : '33%'
  },
  textAlignment: {
    textAlign       : 'center'
  }
}));