import { Box, Grid, Paper } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import React from 'react';
import { ACCESS_POINTS_MODULE, DESCRIPTION_SKELETON, GET, HOLIDAYS_MODULE, LOCATIONS_MODULE, PUT, SCHEDULES_MODULE, TEXT_FIELD_SKELETON } from '../../../utility/constants';
import useStyles from './styles';

const AccessGroupSkeleton = (props) => {
  const classes = useStyles();
  
  return (
    <Grid item xs={12} className={classes.chipBody}>
      <Paper className={classes.chipContainer} elevation={3}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
          {
            [...Array(2)].map((i, index) => {
              return (
                <Grid container spacing={2} key={i + '-skeleton-id-' + index}>
                  <Grid item xs={12}>
                    <Skeleton variant="rect" className={classes.loading}/>
                  </Grid>
                </Grid>
              )
            })
          }
          </Grid>
          <Grid item xs={6}>
          {
            [...Array(2)].map((i, index) => {
              return (
                <Grid container spacing={2} key={i + '-skeleton-id-' + index}>
                  <Grid item xs={12}>
                    <Skeleton variant="rect" className={classes.loading}/>
                  </Grid>
                </Grid>
              )
            })
          }
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

const ProfileSkeleton = (props) => {
  const { disabled, handlePermissions } = props;
  const classes = useStyles();

  return (
    <Box>
      <Paper className={classes.paper} elevation={3}>
        <Grid container spacing={2} className={classes.form}>
          <Grid item xs={12}>
            <Skeleton height={TEXT_FIELD_SKELETON}/>
          </Grid>
          <Grid item xs={12}>
           <Skeleton height={DESCRIPTION_SKELETON}/>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Skeleton height={TEXT_FIELD_SKELETON}/>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Skeleton height={TEXT_FIELD_SKELETON}/>
          </Grid>
          <Grid item xs={12}  md={6} lg={6} className={clsx((!handlePermissions(SCHEDULES_MODULE, PUT) || disabled) && 'hidden')}>
            <Skeleton height={TEXT_FIELD_SKELETON}/>
          </Grid>
          <Grid item xs={12} md={6} lg={6} className={clsx((!handlePermissions(HOLIDAYS_MODULE, PUT) || disabled) && 'hidden')}>
            <Skeleton height={TEXT_FIELD_SKELETON}/>
          </Grid>
          <Grid item xs={12} md={6} lg={6} className={clsx((!handlePermissions(ACCESS_POINTS_MODULE, PUT) || disabled) && 'hidden')}>
            <Skeleton height={TEXT_FIELD_SKELETON}/>
          </Grid>
          <Grid item xs={12} md={6} lg={6} className={clsx((!handlePermissions(LOCATIONS_MODULE, GET) || disabled) && 'hidden')}>
            <Skeleton height={TEXT_FIELD_SKELETON}/>
          </Grid>
          {/* <Grid item xs={12} md={12} lg={12} className={clsx(disabled && 'hidden')}>
            <Skeleton height={TEXT_FIELD_SKELETON + 48}/>
          </Grid>   */}
        </Grid>
        <Grid container className={clsx(disabled ? 'hidden' : classes.action)}>
          <Grid item xs={12} className={`${classes.buttonContainer}`}>
            <Skeleton variant="rect" className={classes.button} />
            <Skeleton variant="rect" className={classes.button} />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export { AccessGroupSkeleton, ProfileSkeleton };

