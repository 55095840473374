import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../theme/colors';

export default makeStyles((theme) => ({
  chipContainer: {
    backgroundColor : colors.PRIMARY,
    borderRadius    : theme.spacing(0.5),
    boxShadow       : theme.shadows[5],
    display         : 'flex',
    justifyContent  : 'space-between',
    height          : '98px',
    minWidth        : '33%',
    padding         : '0'
  },
  chipContainerGray: {
    backgroundColor : colors.GRAY,
    borderRadius    : theme.spacing(0.5),
    boxShadow       : theme.shadows[5],
    display         : 'flex',
    justifyContent  : 'space-between',
    height          : '98px',
    minWidth        : '33%',
    padding         : '0'
  },
  module: {
    display         : 'flex',
    padding         : theme.spacing(2),
    paddingRight    : theme.spacing(0)
  },
  icon: {
    alignItems      : 'center',
    backgroundColor : colors.WHITE,
    borderRadius    : '50%',
    color           : colors.PRIMARY,
    display         : 'flex',
    height          : '38px',
    justifyContent  : 'center',
    width           : '38px',
  },
  text: {
    color           : colors.WHITE,
    marginTop       : theme.spacing(1),
    marginLeft      : theme.spacing(1)
  },
  name: {
    fontSize        : theme.spacing(2),
    fontWeight      : 'bold'
  },
  description: {
    fontSize        : '12px',
  },
  count: {
    alignSelf       : 'flex-end',
    fontSize        : '40px',
    height          : 'fit-content',
    color           : colors.WHITE,
    paddingBottom   : theme.spacing(0.5),
    paddingRight    : theme.spacing(2)
  },
  chipsClicked: {
    border          : '4px solid #E48B13',
    backgroundColor : colors.PRIMARY_DARK
  },
  openSyncHover: {
    cursor          : 'default'
  }
}));

