import moment from 'moment';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '../../context/authContext';
import { SESSION_EVENTS, SESSION_ITEM } from '../../utility/constants';
import { setLocalStorageItem } from '../../utility/helper';
import SessionTimeoutModal from '../session-timeout-modal';

const SessionHandler = (props) => {
  const { children, signOut } = props;
  
  const { logout }                                = useContext(AuthContext); 
  const [ isLogout, setIsLogout ]                 = useState(false);
  const [ isOpen, setIsOpen ]                     = useState(false);
  const [ secondsRemaining, setSecondsRemaining ] = useState(SESSION_ITEM.SESSION_TIMEOUT);

  const events = Object.values(SESSION_EVENTS);

  useEffect(() => {
    addEvents();

    return (() => {
      removeEvents();
    });
  });

  const useSessionCountDown = (callback) => {
    const savedCallback = useRef();
    const delay = 1000;

    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      if (delay !== null) {
        let timer = setInterval(() => {
          savedCallback.current();
        }, delay);

        return () => clearInterval(timer);
      }
    }, [delay]);
  }

  const eventHandler = useCallback(() => {
    if (isLogout) {
      return;
    }

    setSecondsRemaining(SESSION_ITEM.SESSION_TIMEOUT);
    setLocalStorageItem(SESSION_ITEM.SESSION_TIME_KEY, moment());
  }, [isLogout]);

  useSessionCountDown(() => {
    // Open the modal when the remaining time is 30s
    if (secondsRemaining <= SESSION_ITEM.SESSION_WARNING_TIME) {
      setIsOpen(true);
    }

    if (secondsRemaining > 0) {
      setSecondsRemaining(secondsRemaining - 1);
    } else {
      handleCloseModal();
      sessionTimeout();
    }
  }); 

  const addEvents = () => {
    // Prevent events to reset the session when the modal is open
    if (secondsRemaining <= SESSION_ITEM.SESSION_WARNING_TIME) {
      return;
    }

    let newEvents = events;

    // Check if app is onFocus, otherwise remove mousemove event and start countdown
    if (!document.hasFocus()) {
      newEvents = events.filter(item => item !== SESSION_EVENTS.MOUSE_MOVER);
    } else {
      newEvents.push(SESSION_EVENTS.MOUSE_MOVER);
    }

    newEvents.forEach(eventName => {
      window.addEventListener(eventName, eventHandler);
    });
  }

  const removeEvents = useCallback(() => {
    events.forEach(eventName => {
      window.removeEventListener(eventName, eventHandler);
    });
  }, [eventHandler, events]);

  const clearSessionInteractionTime = () => {
    localStorage.removeItem(SESSION_ITEM.SESSION_TIME_KEY);
    sessionStorage.setItem(SESSION_ITEM.SESSION, SESSION_ITEM.EXPIRED);
  }

  const sessionTimeout = useCallback(() => {
    removeEvents();
    setIsLogout(true);
    clearSessionInteractionTime();
    setIsOpen(false);

    logout();
  }, [removeEvents, logout]);

  const handleCloseModal = () => {
    setIsOpen(false);
    setSecondsRemaining(SESSION_ITEM.SESSION_TIMEOUT);
  }

  return (
    <>
      <SessionTimeoutModal
        isOpen={isOpen}
        handleCloseModal={handleCloseModal}
        secondsRemaining={secondsRemaining}
        sessionTimeout={sessionTimeout}
        signOut={signOut}
      />
      {
        children
      }
    </>
  )
}

export default SessionHandler