import { Box, Button, Dialog, Paper, Typography } from '@material-ui/core';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

const UserPinValidationModal = (props) => {
  const { open, handleSubmit, handleCancel } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      id="userPinValidationModal"
      data-testid="userPinValidationModal"
      modal="false"
      open={open}
      {...props}
      aria-labelledby="user-pin-validation-modal"
      className={classes.dialog}
    >
      <Paper id="userPinValidationModalMessageContainer" className={classes.dialogModal}>
        <WarningAmberRoundedIcon className={classes.icon}/>
        <Box className={classes.dialogTitle}>
          <Typography className={classes.title}>{t('user-page.pinModalTitle')}</Typography>
        </Box>
        <Box className={classes.dialogContent}>
          <Typography className={classes.message}>{t('user-page.pinModalMessage')}</Typography> 
        </Box>
      </Paper>
      <Box id="userPinValidationModalButtonsContainer" className={classes.dialogActions}>
        <Button id="userPinValidationModalChangeButton" color="primary" variant="contained" className={classes.dialogButton} onClick={handleCancel} >
          {t('user-page.pinModalChangeButton')}
        </Button>
        <Button id="userPinValidationModalSaveButton" color="primary" variant="outlined" className={classes.dialogButton} onClick={handleSubmit} >
          {t('user-page.pinModalSaveButton')}
        </Button>
      </Box>
    </Dialog>
  );
}

export default UserPinValidationModal;