import { MenuItem, TextField } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

const DropDown = (props) => {
  const { t } = useTranslation();
  const { name, value, items, handleChange, id } = props;
  const classes = useStyles();

  return (
    <TextField
      id={id}
      className={classes.dropDown}
      select
      defaultValue={name}
      value={value}
      onChange={handleChange}
    >
      {
        items.map((item, index) => {
          let menuItemId = item.charAt(0).toUpperCase() + item.slice(1);
          menuItemId = menuItemId.replace(/\s+/g, '');

          return (
            <MenuItem 
              id={`${id}${menuItemId}`} 
              key={index} 
              className={classes.item} 
              value={item}
            >
              <span id={`${id}${menuItemId}Name`}>
                {t(item)}
              </span>
            </MenuItem>
          )
        })
      }
    </TextField>
  )
}

export default DropDown;