import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  locationContainer: {
    maxHeight: theme.spacing(34.5),
    minHeight: theme.spacing(34.5),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    '& .MuiSkeleton-root': {
      transform     : 'none',
      marginBottom  : theme.spacing(1)
    },
  },
  loading: {
    borderRadius: theme.spacing(0.4),
    height      : theme.spacing(5),
    padding     : theme.spacing(1),
  },
}));