import { createContext, useEffect, useReducer } from "react";
import { LocationReducer, initialState } from "../reducer/locationReducer";
import { setLocalStorageItem } from "../utility/helper";

export const LocationContext = createContext();

const LocationContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(LocationReducer, initialState);

  useEffect(() => {
    setLocalStorageItem('selectedArea', state.selectedArea);
    setLocalStorageItem('selectedLocations', state.selectedLocations);
    setLocalStorageItem('selectedLocationIds', state.selectedLocationIds);
  }, [state])

  return (
    <LocationContext.Provider value={{ state, dispatch }}>
      { children }
    </LocationContext.Provider>
  )
}

export default LocationContextProvider;
