import React from 'react';
import { Box, Button, Paper, Dialog, Typography, Grid } from '@material-ui/core';
import useStyles from './styles';

const RedirectModal = (props) => {
  const { onClose, redirectToLogin, open, image, buttonText, description, title, span } = props;
  const classes = useStyles();

  const handlePositiveResponse = () => {
    redirectToLogin();
    onClose();
  }

  return (
    <>
    <Dialog
      modal="false"
      open={open}
      onClose={onClose}
      aria-labelledby="row-success-dialog"
      aria-describedby="row-success-dialog-table"
      className={classes.dialog}
    >
      <Paper elevation={0} className={classes.successModal}>
        <Box className={classes.dialogImage}>
          <img src={image} alt='redirect-dialog-img' />
        </Box>
        <Box className={classes.dialogImage}>
        </Box>
        <Box className={classes.dialogTitle}>
          <Typography><b>{title}</b></Typography>
        </Box>
        <Box className={classes.dialogContent}>
          <Grid container spacing={2} className={`${classes.form} ${classes.space}`}>
            <Grid item md={12}>
              <Typography >
                {description}
                <span>{span}</span>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Box className={classes.dialogActions}>
        <Button color="primary" variant="contained" className={classes.dialogSubmitButton} onClick={handlePositiveResponse}>
          {buttonText}
        </Button>
      </Box>
    </Dialog>
    </>
  );
}

export default RedirectModal;