import { Box, CircularProgress, Paper } from '@material-ui/core';
import 'moment/min/locales';
import React, { useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import AuditLogFilter from '../audit-log-filter';
import AuditLogItem from '../audit-log-item';
import Empty from '../empty';
import AuditLogSkeleton from './audit-log-skeleton';
import useStyles from "./styles";

const Loader = () => {
  const classes  = useStyles();
  return (
    <Box className={classes.loader}>
      <CircularProgress color="inherit" size={24}/>
    </Box>
  )
}

const AuditLogsContent = (props) => {
  const { sort, hasMore, data, handleFilter, handleNextList } = props;
  const classes  = useStyles();
  
  const dataLength = data.length;

  const ref = useRef(null);
  const scrollToTop = () => {
    ref?.current?.scroll({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    scrollToTop();
  }, [sort]);

  return (
    <>
      {
        dataLength ?
          <>
            <Box id="auditLogsContainer" className={classes.auditLogContainer} itemRef={ref}>
              <InfiniteScroll
                dataLength={dataLength}
                next={handleNextList}
                hasMore={hasMore}
                scrollableTarget={"auditLogsContainer"}
                loader={<Loader />}
                className={classes.logs}
                >
                {
                  data.map((item, index) => (
                    <AuditLogItem
                      key={`key-${index}`}
                      timestamp={item.timestamp}
                      value={item.value}
                      translationKey={item.key}
                    />
                  ))
                }
              </InfiniteScroll>
            </Box>
            <AuditLogFilter handleFilter={handleFilter} sort={sort}/>
          </>
        :
          <Empty variant={'h6'}/>
      }
    </>
  )
}

const AuditLogs = (props) => {
  const { data, isAuditLogLoading, handleSort, sort, hasMore, handleNextList } = props;
  const classes  = useStyles();

  const handleFilter = (element) => {
    handleSort(element.target.value);
  }

  return (
    <Paper className={classes.paper} elevation={3}>
      <Box className={classes.auditContainer}>
        {
          isAuditLogLoading ?
            <AuditLogSkeleton />
          :
            <AuditLogsContent 
              data={data}
              handleFilter={handleFilter}
              handleNextList={handleNextList}
              hasMore={hasMore}
              sort={sort}
            />
        }
      </Box>
    </Paper>
  )
}

export default AuditLogs