import React from 'react';
import { Grid, Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import useStyles from './styles';
import { TEXT_FIELD_SKELETON } from '../../../utility/constants';


const ImportResultSkeleton = (props) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item md={12} sm={12}>
        <Box className={classes.importItems}>
          <Skeleton height={24} className={classes.input} />
          <Skeleton height={24} className={classes.input} />
        </Box>
      </Grid>
    </Grid>
  )
}

const ImportActionSkeleton = (props) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.action}>
      <Grid item xs={12}>
        <Box className={classes.dialogActions}>
          <Skeleton height={64} className={classes.actionButton}/>
          <Skeleton height={64} className={classes.actionButton}/>
        </Box>
      </Grid>
    </Grid>
  )
}

const HolidaySkeleton = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={1} className={classes.form}>
      <Grid item xs={6}>
        <Skeleton height={TEXT_FIELD_SKELETON} className={classes.input} />
      </Grid>
      <Grid item xs={4}>
        <Skeleton height={TEXT_FIELD_SKELETON} className={classes.input} />
      </Grid>
    </Grid>
  )
}
export { ImportResultSkeleton, ImportActionSkeleton, HolidaySkeleton };