import { Skeleton } from "@material-ui/lab";
import React from "react";
import useStyles from "./styles";

const UserFederationSkeleton = () => {
  const classes = useStyles();

  return (
    <>
      <Skeleton className={classes.skeletonLong}/>
      <Skeleton className={classes.skeletonLong}/>
      <Skeleton className={classes.skeletonShort}/>
    </>
  )
}

export default UserFederationSkeleton;