import { Paper } from '@material-ui/core'
import React, { Children, useState } from 'react'
import SetupWizard from './setup-wizard';
import useStyles from './styles';
import { ToastContainer } from 'react-toastify';
import Toaster from '../toaster';
import ContentOptions from './wizard-pages/wizard-options';
import { CredentialCreateCredentialContent, PersonCreateCredentialContent }  from './wizard-pages/wizard-create-credential';
import { CredentialWizard } from './wizard-pages/wizard-credentials';
import WizardProvider from '../../context/wizardContext';
import WizardPersonProvider from '../../context/wizardPersonContext';
import WizardCredentialProvider from '../../context/wizardCredentialContext';
import WizardExistingCredentials from './wizard-pages/wizard-existing-credentials';
import WizardAssignRoles from './wizard-pages/wizard-assign-roles';
import WizardSearchPerson from './wizard-pages/wizard-search-person';
import WizardCredentialsSummary from './wizard-pages/wizard-credentials-summary';
import CreateUserWizard from './wizard-pages/wizard-create-user';
import WizardUserSummary from './wizard-pages/wizard-user-summary';

const createUserWizard = [
  {
    title      : 'userInfoTitle',
    description: 'userInfoDescription',
    body       : CreateUserWizard
  },
  {
    title      : 'assignCredentialTitle',
    description: 'assignCredentialDescription',
    body       : ContentOptions
  },
  {
    title      : 'assignExistingCredTitle',
    description: 'assignExistingCredDescription',
    body       : WizardExistingCredentials
  },
  {
    title      : 'assignNewCredentialTitle',
    description: 'assignNewCredentialDescription',
    body       : PersonCreateCredentialContent
  },
  {
    title      : 'assignRoleTitle',
    description: 'assignRoleDescription',
    body       : WizardAssignRoles
  },
  {
    title      : 'titleSummary',
    description: 'descriptionSummary',
    body       : WizardUserSummary
  },
]

const lostCredentialWizard = [
  {
    title      : 'searchUserTitle',
    description: 'searchUserDescription',
    body       : WizardSearchPerson
  },
  {
    title      : 'deactivateCredentialTitle',
    description: 'deactivateCredentialDescription',
    body       : CredentialWizard
  },
  {
    title      : 'assignCredentialTitle',
    description: 'assignCredentialDescription',
    body       : ContentOptions
  },
  {
    title      : 'assignExistingCredTitle',
    description: 'assignExistingCredDescription',
    body       : WizardExistingCredentials
  },
  {
    title      : 'assignNewCredentialTitle',
    description: 'assignNewCredentialDescription',
    body       : CredentialCreateCredentialContent
  },
  {
    title      : 'titleSummary',
    description: 'descriptionSummary',
    body       : WizardCredentialsSummary
  },
]

const HelpCenter = (props) => {
  const { handleClose, content, setContent, handlePermissions } = props;

  const [isToasterOpen, setIsToasterOpen] = useState(false);
  const [title, setTitle]                 = useState('');
  const [description, setDescription]     = useState('');
  const [severity, setSeverity]           = useState('');

  const showToaster = (toasterTitle, toasterDescription, toasterSeverity) => {
    setTitle(toasterTitle);
    setDescription(toasterDescription);
    setSeverity(toasterSeverity);
    setIsToasterOpen(true);
  }

  const handleCloseToaster = () => {
    setIsToasterOpen(false);
  }

  return (
    <WizardProvider>
      <ToastContainer />
      <Toaster isOpen={isToasterOpen} title={title} description={description} severity={severity} onClose={handleCloseToaster} />
      <HelpCenterContent content={content}>
        <WizardPersonProvider>
          <SetupWizard
            handleClose={handleClose}
            contents={createUserWizard}
            steps={5}
            setContent={setContent}
            showToaster={showToaster}
            handlePermissions={handlePermissions}
          />
        </WizardPersonProvider>
        <WizardCredentialProvider>
          <SetupWizard
            handleClose={handleClose}
            contents={lostCredentialWizard}
            steps={4}
            setContent={setContent}
            showToaster={showToaster}
            handlePermissions={handlePermissions}
          />
        </WizardCredentialProvider>
      </HelpCenterContent>
    </WizardProvider>
  )
}

const HelpCenterContent = (props) => {
  const classes               = useStyles();
  const { children, content } = props;
  const pages                 = Children.toArray(children);
  const currentPage           = pages[content];

  return (
    <Paper className={classes.paper}>
      {currentPage}
    </Paper>
  )
}

export default HelpCenter