import { FormControl, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { Trans } from 'react-i18next';
import { ASCENDING, DESCENDING } from '../../utility/constants';
import useStyles from "./styles";

const AuditLogFilter = (props) => {
  const { handleFilter, sort } = props;
  const classes = useStyles();

  return (
    <FormControl size="small">
      <Select
        defaultValue={sort}
        className={classes.filter}
        disableUnderline
        MenuProps={{
          anchorOrigin: {
            vertical  : "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical  : "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
        onChange={handleFilter}
      >
        <MenuItem value={DESCENDING} classes={{ selected: classes.selectedItem }}>
          <Trans i18nKey={'audit-log.newestActivity'}/>
        </MenuItem>
        <MenuItem value={ASCENDING} classes={{ selected: classes.selectedItem }}>
          <Trans i18nKey={'audit-log.oldestActivity'}/>
        </MenuItem>
      </Select>
    </FormControl>
  )
}

export default AuditLogFilter