import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../../theme/colors';

export default makeStyles((theme) => ({
  select: {
    width         : '55%',
  },
  description : {
    alignItems    : 'flex-start',
    display       : 'flex',
    paddingBottom : theme.spacing(8),
    width         : '100%',
  },
  icon: {
    color         : colors.PRIMARY,
    marginTop     : theme.spacing(0.5)
  },
  text: {
    fontSize      : theme.spacing(1.75),
    marginLeft    : theme.spacing(1),
    '& span': {
      fontWeight  : 'bold',
      marginLeft  : theme.spacing(1),
    }
  }
}));