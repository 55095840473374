import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  paper: {
    marginTop : theme.spacing(1),
  },
  form: {
    padding       : theme.spacing(1),
    paddingLeft   : theme.spacing(2),
    paddingRight  : theme.spacing(2),

    '& .MuiSkeleton-root': {
      transform     : 'none',
      marginBottom  : theme.spacing(1)
    },
  },
  containerProfiles:{
    border    : 'thin',
    height    : '300px',
    marginTop : '.5%'
  },
  action: {
    backgroundColor : theme.palette.grey[100],
    padding         : theme.spacing(2),
    textAlign       : 'right',

    '& .MuiButton-root:first-of-type': {
      marginRight : theme.spacing(2)
    }
  },
  buttonContainer:{
    backgroundColor : '#F5F5F5',
    display         : 'flex',
    justifyContent  : 'flex-end',
    width           : '100%'
  },
  button:{
    height      : '40px',
    marginLeft  : theme.spacing(1),
    width       : '120px'
  },
  fullWidth:{
    width : '100%'
  },
  space:{
    content : ''
  },
  groupContainer: {
    padding       : theme.spacing(1),
  },
  containerAccessPoint:{
    border        : 'thin',
    borderStyle   : 'solid',
    borderRadius  : '4px',
    borderColor   : '#c4c4c4',
    marginTop     : theme.spacing(-1),
  },
  fieldsetLegend:{
    color : '#767779'
  },
}));