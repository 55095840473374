import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  header: {
    display       : 'flex',
    justifyContent: 'space-between',
    paddingBottom : theme.spacing(0.5)
  },
  loadingHeader: {
    height     : theme.spacing(1.5),
    marginLeft : theme.spacing(1),
    marginRight: theme.spacing(1),
    width      : 50,
  },
  headerTitle: {
    color       : theme.palette.secondary.main,
    fontWeight  : 'bold',
    lineHeight  : 1,
    paddingLeft : theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  loading: {
    borderRadius: theme.spacing(0.4),
    height      : theme.spacing(3.8),
    padding     : theme.spacing(1),
  },
  visuallyHidden: {
    visibility: 'hidden'
  }
}));