import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  statusBarLabel: {
    display       : 'flex',
    justifyContent: 'space-between'
  },
  colorPrimary: {
    backgroundColor: '#707173B2',
  },
  colorNormal: {
    backgroundColor: theme.palette.success.main,
  },
  colorSafe: {
    backgroundColor: '#FFAB3B',
  },
  colorDanger: {
    backgroundColor: theme.palette.error.main,
  },
  spaceSmall: {
    paddingTop: theme.spacing(.1)
  },
  spaceMedium: {
    paddingTop: theme.spacing(1)
  },
  fontSizeSmall: {
    fontSize: theme.spacing(1.5)
  },
  fontSizeMedium: {
    fontSize: theme.spacing(2)
  },
  barHeightSmall: {
    height: theme.spacing(.6)
  },
  barHeightMedium: {
    height: theme.spacing(1.3)
  }
}));