import { lighten, makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  dialog: {
    '& .MuiPaper-rounded': {
      borderRadius: theme.spacing(1.25),
    }
  },
  diaglogContainer: {
    overflow : 'hidden',
    padding  : theme.spacing(2),
    position : 'relative',
    textAlign: 'center'
  },
  dialogImage: {
    display: 'inline',
    margin : 0,
    '& img': {
      marginBottom: theme.spacing(3),
      marginTop   : theme.spacing(3),
    },
  },
  dialogTitle: {
    color       : colors.SECONDARY_TEXT,
    marginBottom: theme.spacing(3)
  },
  title: {
    fontSize  : theme.spacing(2.5),
    fontWeight: 'bold'
  },
  dialogContent: {
    display  : 'inline-block',
    margin   : 0,
    textAlign: 'center',
    width    : theme.spacing(55.25),
  },
  dialogTimer: {
    fontSize    : theme.spacing(3.75),
    marginBottom: theme.spacing(3)
  },
  dialogDescription: {
    color       : lighten(colors.BLACK, 0.1),
    fontSize    : theme.spacing(2),
    marginBottom: theme.spacing(4)
  },
  dialogActions: {
    backgroundColor: colors.LIGHT_GRAY,
    display        : 'flex',
    justifyContent : 'center',
    padding        : theme.spacing(2),
    '& button': {
      width     : theme.spacing(29.75),
      height    : theme.spacing(7.625),
    }
  },
  dialogLogoutButton: {
    marginRight: theme.spacing(1)
  }
}));