export const INCREMENT        = 'INCREMENT';
export const DECREMENT        = 'DECREMENT';
export const SET_NEXT_BUTTON  = 'SET_NEXT_BUTTON';
export const SET_NEXT_PAYLOAD = 'SET_NEXT_PAYLOAD';
export const SET_ERROR        = 'SET_ERROR';
export const CLEAR_REDUCER    = 'CLEAR_REDUCER';

export const initialState = {
  currentPage: 0,
  checkPage  : 1,
  error      : true
}

export const WizardReducer = (state, action) => {
  switch (action.type) {
    case INCREMENT:
      return {
        ...state,
        prevPage    : state.prevPage ?? action.payload - 1,
        currentPage : action.payload,
        checkPage   : action.payload,
        error       : true
      }
    case DECREMENT:
      return {
        ...state,
        currentPage : action.payload ? action.payload : state.prevPage,
        prevPage    : state.prevPage - 1,
        checkPage   : state.checkPage - 1,
        error       : true
      }
    case SET_NEXT_BUTTON: {
      return {
        ...state,
        isNextShow  : action.payload,
        nextPage    : null,
      }
    }
    case SET_NEXT_PAYLOAD: {
      const { prevPage, nextPage, isNextShow, isEndPage, checkPage, currentPage, bufferPage, isNextDisabled, isNextHidden } = action.payload
      return {
        ...state,
        nextPage      : nextPage,
        isNextShow    : isNextShow,
        isEndPage     : isEndPage,
        prevPage      : prevPage ? prevPage : state.prevPage,
        checkPage     : checkPage ? checkPage : state.checkPage,
        currentPage   : currentPage ? currentPage : state.currentPage,
        bufferPage    : bufferPage ? bufferPage : state.bufferPage,
        isNextDisabled: isNextDisabled,
        isNextHidden  : isNextHidden ? isNextHidden : false
      }
    }
    case SET_ERROR: {
     return {
        ...state,
        error       : action.payload
      }
    }
    case CLEAR_REDUCER: {
      return initialState
    }
    default:
      return state
  }
}