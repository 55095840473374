import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../theme/colors';

export default makeStyles((theme) => ({
  container: {
    paddingLeft   : theme.spacing(2.5),
    paddingRight  : theme.spacing(2),
    paddingTop    : theme.spacing(2),
  },
  paper: {
    padding       : theme.spacing(1),
    paddingLeft   : theme.spacing(3),
    paddingRight  : theme.spacing(3),
    paddingTop    : theme.spacing(2)
  },
  info: {
    fontSize     : "13px",
  },
  form: {
    padding       : theme.spacing(1),
    paddingLeft   : theme.spacing(2),
    paddingRight  : theme.spacing(2),
    paddingTop    : theme.spacing(2)
  },
  action: {
    backgroundColor : theme.palette.grey[100],
    display         : 'flex',
    justifyContent  : 'flex-end',
    padding         : theme.spacing(2),
    
    '& .MuiButton-root:first-of-type': {
      marginRight : theme.spacing(2)
    }
  },
  button: {
    width     : theme.spacing(15),
  },
  filterModal: {
    alignItems      : 'center',
    display         : 'flex',
    justifyContent  : 'center'
  },
  modalContent: {
    backgroundColor : theme.palette.background.paper,
    boxShadow       : theme.shadows[5],
    padding         : theme.spacing(0),
    width           : '600px'
  },
  titleFilterModal:{
    alignItems  : 'center',
    display     : 'flex',
    fontSize    : '20px'
  },
  hrDividerProfile:{
    border        : 0,
    borderBottom  : '1px solid #006AB3',
    height        : theme.spacing(-1)
  },
  holidayImportForm: {
    width     : '100%',
  },
  holidayImportModal: {
    display   : 'block',
    paddingTop: theme.spacing(5),
  },
  dialogImage: {
    alignItems: 'center',
    margin    : '0 auto',
    textAlign : 'center',
    paddingTop : theme.spacing(2),
    paddingBottom : theme.spacing(2),
  },
  dialogContents: {
    alignItems     : 'center',
  },
  dialogTitle: {
    fontWeight   : 'bold',
    textAlign    : 'center',
    paddingLeft  : theme.spacing(1),
    paddingRight : theme.spacing(1),
    paddingTop   : theme.spacing(2),
  },
  dialogActions: {
    alignItems     : 'center',
    backgroundColor: colors.LIGHT_GRAY,
    direction      : 'row',
    display        : 'flex',
    height         : theme.spacing(8),
    padding        : theme.spacing(1.5),
    margin         : '0 auto',
  },
  dialogSubmitButton: {
    display : 'flex',
    float   : 'left',
    margin  : theme.spacing(0.5),
    padding : theme.spacing(0.5),
    width   : "50%",
  },
  holidayImportActions: {
    alignItems     : 'flex-start',
    display        : 'flex',
    height         : theme.spacing(8),
    padding        : theme.spacing(1.5),
    paddingLeft    : theme.spacing(2),
    paddingRight   : theme.spacing(1),
    margin         : '0 auto',
  },
  importButton: {
    display : 'flex',
    float   : 'left',
    width   : "32%",
    height  : '55px',
  },
  importField: {
    display       : 'flex',
    marginBottom  : theme.spacing(0.5),
    marginRight   : theme.spacing(1),
    marginTop     : 0,
    paddingBottom : theme.spacing(0.5),
    paddingTop    : '0',
    width         : "65%",
  },
  importItems: {
    marginLeft      : theme.spacing(4),
    marginRight     : theme.spacing(4),
    marginTop       : theme.spacing(4),
    marginBottom    : theme.spacing(5),
  },
  importText: {
    marginBottom    : theme.spacing(0.5),
    width           : '100%',
  },
  skipIcon: {
    color       : '#FFB300',
    paddingRight: theme.spacing(0.5),
  },
}));