import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  selectForm: {
    width: '100%',
  },
  locationFilterSelect: {
    width: '10vw',
  },
  paper: {
    paddingTop: theme.spacing(1),
  },
  popper: {
    width     : 'calc(100% - 60vw)',
    zIndex    : 1300,
  },
  locationFilterPopper: {
    width     : '23vw',
    zIndex    : 1300,
  },
  singleOption: {
    alignContent  : 'flex-start',
    alignItems    : 'flex-start',
    display       : 'flex',
    flexDirection : 'column',
    flexWrap      : 'wrap',
    justifyContent: 'flex-start',
    minWidth      : '95%',
    '& .MuiTypography-root.MuiTypography-body2': {
      color: "black"
    }
  },
  locationFilterSingleOption: {
    alignContent  : 'flex-start',
    alignItems    : 'flex-start',
    display       : 'flex',
    flexDirection : 'column',
    flexWrap      : 'wrap',
    justifyContent: 'flex-start',
    minWidth      : 'fit-content',
  },
  inputChips: {
    alignContent  : 'center',
    alignItems    : 'center',
    display       : 'flex',
    flexWrap      : 'wrap',
    justifyContent: 'flex-start',
    marginBottom  : theme.spacing(0.5),
    marginTop     : theme.spacing(1),
    minWidth      : '95%',
  },
  chips: {
    color : 'white',
    margin: theme.spacing(0.2),
  },
  optionBox: {
    alignItems: 'center',
    display   : 'flex',
    cursor    : 'pointer',
  },
  options: {
    display      : 'flex',
    flexDirection: 'column',
    height       : '25vh',
    marginBottom : theme.spacing(2),
    overflowX    : 'hidden',
    overflowY    : 'auto',
    paddingLeft  : theme.spacing(1.5),
    paddingRight : theme.spacing(1),
  },
  locationOptions: {
    display      : 'flex',
    flexDirection: 'column',
    height       : '200px',
    marginBottom : theme.spacing(2),
    marginTop    : theme.spacing(1),
    overflowY    : 'auto'
  },
  optionTitle: {
    fontWeight: 600,
  },
  optionSub : {
    fontSize: '75%',
  },
  loadField : {
    alignContent  : 'center',
    alignItems    : 'center',
    display       : 'flex',
    flexDirection : 'column',
    justifyContent: 'space-evenly',
    paddingRight  : theme.spacing(1),
  },
  searchBox: {
    width: '95%',
  },
  loading: {
    marginLeft: theme.spacing(-0.5),
    marginTop : theme.spacing(1.5),
  },
  visuallyHidden: {
    border  : 0,
    clip    : 'rect(0 0 0 0)',
    height  : 1,
    margin  : -1,
    overflow: 'hidden',
    padding : 0,
    position: 'absolute',
    top     : 20,
    width   : 1,
  },
  disableCloseBtn: {
    '& .MuiChip-deleteIcon': {
      display : 'none'
    }
  },
  invisible: {
    display      : 'none',
    marginBottom : theme.spacing(1),
  },
  wordBreak: {
    alignItems   : 'left',
    paddingLeft  : theme.spacing(0.7),
    paddingRight : theme.spacing(3.5),
    wordBreak    : 'break-word',
    '& .MuiTypography-body1': {
      letterSpacing: 0,
      lineHeight   : 0.7
    },
  },
}))

export default useStyles;