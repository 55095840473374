import React from 'react';
import { DESCRIPTION_SKELETON, TEXT_FIELD_SKELETON } from '../../../utility/constants';
import { Paper, Box, Grid} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import useStyles from './styles';

const LoadingState = (props) => {
  const classes = useStyles()
  return (
    <Box>
      <Paper className={classes.paper} elevation={3}>
        <Grid container spacing={2} className={`${classes.form} ${classes.formSkel}`}>
          <Grid item xs={12} md={6}>
            <Skeleton height={TEXT_FIELD_SKELETON}/>
            <Skeleton height={TEXT_FIELD_SKELETON}/>
          </Grid>
          <Grid item xs={12} md={6}>
            <Skeleton height={DESCRIPTION_SKELETON} />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}


export default LoadingState;