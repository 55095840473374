import { Box, Grid, Paper } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import { TEXT_FIELD_SKELETON } from '../../../utility/constants';
import useStyles from './styles';

const AdministratorsSkeleton = (props) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2} className={classes.form}>
        <Grid item xs={12}>
          <Skeleton height={600}/>
        </Grid>
      </Grid>
    </Paper>
  )
}

const ViewAdministratorsModalSkeleton = () => {
  const classes = useStyles();

  return (
    <Box>
      <Grid container spacing={2} className={`${classes.modalPaper} ${classes.form}`} elevation={3}>
        <Grid item xs={6} md={6}>
          <Skeleton height={TEXT_FIELD_SKELETON}/>
        </Grid>
        <Grid item xs={6} md={6}>
          <Skeleton height={TEXT_FIELD_SKELETON}/>
        </Grid>
        <Grid item xs={6} md={6}>
          <Skeleton height={TEXT_FIELD_SKELETON}/>
        </Grid>
        <Grid item xs={6} md={6}>
          <Skeleton height={TEXT_FIELD_SKELETON}/>
        </Grid>
      </Grid>
    </Box>
  )
}

const CreateUpdateAdministratorsModalSkeleton = () => {
  const classes = useStyles();

  return (
    <Box>
      <Grid container spacing={2} className={`${classes.modalPaper} ${classes.form}`} elevation={3}>
        <Grid item xs={6} md={6}>
          <Skeleton height={TEXT_FIELD_SKELETON}/>
        </Grid>
        <Grid item xs={6} md={6}>
          <Skeleton height={TEXT_FIELD_SKELETON}/>
        </Grid>
        <Grid item xs={6} md={6}>
          <Skeleton height={TEXT_FIELD_SKELETON}/>
        </Grid>
        <Grid item xs={6} md={6}>
          <Skeleton height={TEXT_FIELD_SKELETON}/>
        </Grid>
      </Grid>
      <Grid className={classes.action}>
        <Grid item xs={12} className={classes.actionBox}>
          <Skeleton variant="rect" className={classes.button} />
          <Skeleton variant="rect" className={classes.button} />
        </Grid>
      </Grid>
    </Box>
  )
}

export { CreateUpdateAdministratorsModalSkeleton, ViewAdministratorsModalSkeleton, AdministratorsSkeleton };

