import { Box, Button, Dialog, Grid, Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';
import cookies from 'js-cookie';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { HOLIDAYS_MODULE, LANGUAGE_EN, LOCATION_MODULE, MULTIPLE, ROLES_MODULE, SINGLE, USERS, USERS_MODULE } from '../../utility/constants';
import { inWords } from '../../utility/helper';
import images from "../../utility/images";
import { DeleteModalSkeleton } from './delete-modal-skeleton';
import useStyles from './styles';

const UniqueResponseButton = (props) => {
  const { module, type, handleUniqueResponse } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    ((module === USERS_MODULE || module === LOCATION_MODULE) && type.includes('Associated')) ?
      <Button id="deleteModalRemoveAllButton" color="primary" variant={module === LOCATION_MODULE ? "contained" : "outlined"} className={classes.dialogSubmitButton} onClick={handleUniqueResponse}>
        {t(`delete-modal-component.yesRemoveAll`)}
      </Button>
    :
      <></>
  )
}

const DeleteModal = (props) => {
  const { initialValues, onClose, module, forDeleteName, singleDelete, multipleDelete, uniqueAction, type, open, assocValues, isLoading } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  
  const language = cookies.get('i18next') || LANGUAGE_EN;

  const determineRemovedAllEnabled = () => {
    return ((module === USERS_MODULE || module === LOCATION_MODULE) && type.includes('Associated'));
  }

  const generateVerbiageForAssocValues = () => {
    if (assocValues.length > 0) {
      return assocValues;
    } else if (module === ROLES_MODULE) {
      return t(USERS).toLowerCase();
    } else {
      return t('delete-modal-component.otherRecords');
    }
  }

  const isModuleLocation     = module === LOCATION_MODULE;
  const willRemoveAllEnabled = determineRemovedAllEnabled();
  const assocValuesDisplay   = generateVerbiageForAssocValues();
  
  const handlePositiveResponse = () => {
    if (type.includes(MULTIPLE)) {
      multipleDelete(initialValues);
    } else {
      singleDelete(initialValues);
    }
    onClose();
  }

  const handleUniqueResponse = () => {
    uniqueAction(initialValues);
    onClose()
  }

  const getItemType = () => {
    return module === HOLIDAYS_MODULE ? t('delete-modal-component.holidayCalendar') : t(module.slice(0, -1)).toLowerCase();
  }

  return (
    <Dialog
      id="deleteModal"
      modal="false"
      open={open}
      onClose={onClose}
      aria-labelledby="row-delete-dialog"
      aria-describedby="row-delete-dialog-table"
      className={classes.dialog}
    >
      {
        isLoading ?
            <DeleteModalSkeleton id="delete-modal-skeleton"/>
          :
          <>
            <Paper id="deleteModalBodyContainer" className={classes.deleteModal}>
              <Box className={classes.dialogImage}>
                <img src={images.DELETE_MODAL} alt='delete-dialog-img' />
              </Box>
              <Box className={classes.dialogTitle}>
                <Typography><b>{t(`delete-modal-component.warning`)}</b></Typography>
              </Box>
              <Box className={classes.dialogContent}>
                <Grid container spacing={2} className={`${classes.form} ${classes.space}`}>
                  <Grid item md={12}>
                    <Typography className={clsx(classes.wordBreak, {'hidden': type !== SINGLE})}>
                      { t('delete-modal-component.singleDescription', { itemName : forDeleteName }) }
                    </Typography>
                    <Typography className={clsx(classes.wordBreak, {'hidden': type !== 'singleWithAssociated'})}>
                      <Trans i18nKey={'delete-modal-component.singleWithAssociatedDescription'} values={{
                          itemName        : forDeleteName,
                          associatedItems : assocValuesDisplay,
                          itemType        : getItemType()
                          }}
                      />
                      {
                        !isModuleLocation && module !== HOLIDAYS_MODULE &&
                        <Trans i18nKey={'delete-modal-component.singleRemoveAndDetachDescription'} values={{ itemType : getItemType() }}/>
                      }
                      {
                        willRemoveAllEnabled && module !== HOLIDAYS_MODULE &&
                        <Trans i18nKey={'delete-modal-component.singleRemoveAllDescription'} values={{ itemType : getItemType() }}/>
                      }
                    </Typography>
                    <Typography className={clsx({'hidden': type !== MULTIPLE})}>
                      {
                        t('delete-modal-component.multipleDescription', { 
                          countText : inWords(initialValues.length, language),
                          count     : initialValues.length
                        })
                      }
                    </Typography>
                    <Typography className={clsx({'hidden': type !== 'multipleWithAssociated'})}>
                      <Trans i18nKey={'delete-modal-component.multipleWithAssociatedDescription'} values={{
                          countText       : inWords(initialValues.length, language),
                          count           : initialValues.length,
                          associatedItems : assocValuesDisplay
                          }}
                      />
                      {
                        !isModuleLocation && module !== HOLIDAYS_MODULE &&
                        <Trans i18nKey={'delete-modal-component.multipleRemoveAndDetachDescription'}/>
                      }
                      {
                        willRemoveAllEnabled && module !== HOLIDAYS_MODULE &&
                        <Trans i18nKey={'delete-modal-component.multipleRemoveAllDescription'}/>
                      }
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            <Box id="deleteModalFooterContainer" className={classes.dialogActions}>
              <Button 
                id="deleteModalRemoveAndDetachButton"
                color="primary" variant="contained"
                className={clsx({
                    [classes.dialogSubmitButton] : (type.includes('Associated') && !isModuleLocation),
                    'hidden'                     : !(type.includes('Associated') && !isModuleLocation)
                  })
                }
                onClick={handlePositiveResponse}
                >
                { t(`delete-modal-component.yesRemoveAndDetach`) }
              </Button>
              <UniqueResponseButton
                module={module}
                type={type}
                handleUniqueResponse={handleUniqueResponse}
              />
              <Button
                id="deleteModalRemoveYesButton"
                color="primary" variant="contained"
                className={clsx({
                  [classes.dialogSubmitButton] : !(type.includes('Associated')),
                  'hidden'                     : type.includes('Associated')
                  })
                }
                onClick={handlePositiveResponse}
              >
                { t('delete-modal-component.yesRemove') }
              </Button>
              <Button id="deleteModalRemoveNoButton" color="primary" variant="outlined" className={classes.dialogCancelButton} onClick={onClose}>
                { t('delete-modal-component.noDontRemove') }
              </Button>
            </Box>
          </>
      }
    </Dialog>
  );
}

export default DeleteModal;