import { Box, Button, Grid, TextField, Modal, Fade, Container, Backdrop } from '@material-ui/core';
import React, { useState, useEffect } from 'react'
import { KeyboardDatePicker } from '@material-ui/pickers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { DATE_FORMAT, ACTION_UPDATE, DATE_FORMAT_YYYY_MM_DD, GET, EVENT_FILTER_FORMAT2, ACTION_CREATE, MAX_CHARACTER_LIMIT} from '../../../utility/constants';
import useStyles from './styles';
import moment from 'moment';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { holidayItemSchema } from '../../../validation/schema';
import { request } from '../../../service/requests';
import api from '../../../service/api';
import { parseISO } from 'date-fns';

const Content = (props) => {
  const classes             = useStyles();
  const { t }               = useTranslation();  
  const { initialValues, handleSubmit, closeModal, setName, setDate, type } = props;

  const handleChange = (setter, formik) => {
    return(setter, formik);
  }

  const getButtonLabel = () => {
    return type === ACTION_UPDATE ? `${t('update')}` : `${t('create')}`;
  }

  return(
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={holidayItemSchema}
      onSubmit={handleSubmit}
    >
      {
        formik => {
          return(
            <Form>
              <Grid container spacing={2} className={classes.paper}>
                <Grid item md={8} sm={6}>
                  <TextField
                    InputLabelProps = {{
                      shrink: Boolean(formik.values.name)
                    }}
                    fullWidth
                    inputProps={{
                      maxlength: MAX_CHARACTER_LIMIT.TEXT_FIELD
                    }}
                    id="holidayCreateModalName"
                    label={`${t('holidayName')}*`}
                    name="name"
                    value={formik.values.name}
                    onChange={event => handleChange(setName(event.target.value), formik.handleChange(event))}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={t(formik.touched.name) && t(formik.errors.name)}
                  />
                </Grid>
                <Grid item md={4} sm={6} className={classes.timePicker}>
                  <KeyboardDatePicker
                    id="holidayCreateModalDate"
                    autoComplete="off"
                    autoOk={true}
                    className={`${classes.calendarTextField}`}
                    margin="normal"
                    keyboardIcon={<FontAwesomeIcon icon={faCalendar} size="sm"/>}
                    label={`${t('date')}*`}
                    name="date"
                    onChange={event => setDate(moment(event).format(DATE_FORMAT_YYYY_MM_DD))}
                    format="dd.MM.yyyy"
                    variant="inline"
                    fullWidth
                    value={parseISO(formik.values.date)} 
                    InputProps={{
                      readOnly: true
                    }}
                    InputLabelProps = {{
                      shrink: formik.values.date
                    }}
                    error={formik.touched.date && Boolean(formik.errors.date)}
                    helperText={t(formik.touched.date) && t(formik.errors.date)}
                  />
                </Grid>
              </Grid>
              <Grid  className={classes.action}>
                  <Button
                    id="holidayCreateModalCancelButton"
                    className={classes.button}
                    color="primary"
                    onClick={closeModal}
                    variant="outlined"
                  >
                    {t(`cancel`)}
                  </Button>
                  <Button
                    id="holidayCreateModalSubmitButton"
                    className={classes.button}
                    color="primary"
                    type="submit"
                    variant="contained"
                  >
                    {getButtonLabel()}
                  </Button>
              </Grid>
            </Form>
          )
        }
      }
    </Formik>
  );
}

const CreateModal = (props) => {
  const classes   = useStyles();
  const { t }     = useTranslation();  
  const { showModal, setShowModal, holidayId, setHolidays, type, itemValue, tabSelected, setCreatedHolidays, holidays, createdHolidays, setChangesHolidayItem, changesHolidayItem, holidayItemIdParam, setHolidayItemIdEditParam } = props;
  const [ name, setName ]                   = useState('');
  const [ selectedDate, setSelectedDate ]   = useState(null);

  const initialValues = {
    name    : name,
    date    : selectedDate
  }

  useEffect(() => {
    setName(itemValue?.name);
    setSelectedDate(moment(itemValue?.date, 'DD.MM.YYYY').format(DATE_FORMAT_YYYY_MM_DD));
    if (type === ACTION_CREATE) {
      setName('');
      setSelectedDate(null);
    }
  }, [itemValue, type]);

  const handleSubmit = (values, formik) => {
    const { setErrors } = formik;
    const hasNoChanges  =  type === ACTION_UPDATE && (itemValue.name === values.name && itemValue.date === moment(values.date).format(DATE_FORMAT));
    if (hasNoChanges) {
      setShowModal(false);
      return;
    }
    const holiday = {
      name           : values.name,
      date           : moment(values.date).format(DATE_FORMAT),
      holidayItemId  : type === ACTION_UPDATE ? itemValue.holidayItemId : Math.random(),
      isChanged      : true,
    }
    if (type === ACTION_UPDATE) {
      if (tabSelected === 'NewHolidays') {
        const isDuplicate = findHoliday(holidays, values) || findHoliday(createdHolidays, values);
        if (isDuplicate) {
          setErrors({
            name: t('holidayItemAlreadyExist'),
            date: t('holidayItemAlreadyExist')
          })
        } else {
          setCreatedHolidays(item => supplimentHolidayState(item, holiday));
          setShowModal(false);
        }
      } else {
        findHoliday(changesHolidayItem, values) || findHoliday(createdHolidays, values) ?  
        setErrors({
          name: t('holidayItemAlreadyExist'),
          date: t('holidayItemAlreadyExist')
        })
        : checkDuplicate(values, setErrors, holiday)
      }
    } else {
      const isDuplicate = findHoliday(holidays, values) || findHoliday(createdHolidays, values);
      if (isDuplicate) {
        setErrors({
          name: t('holidayItemAlreadyExist'),
          date: t('holidayItemAlreadyExist')
        })
      } else {
        setCreatedHolidays(item => [...item, holiday]);
        setShowModal(false);
        setName('');
        setSelectedDate(null);
      }
    }
  }

  const checkDuplicate = async (values, setErrors, holiday) => {
    const response = await request({
      url   : api.HOLIDAY_ITEMS_IMPORT_UPDATE_CHECK,
      method: GET,
      params: {
        holidayId       : holidayId,
        dates           : moment(values.date).format(EVENT_FILTER_FORMAT2),
        names           : values.name,
        holidayItemIds  : holidayItemIdParam ? holidayItemIdParam.join() : 0
      }
    });
    
    if (response.data._embedded.holidayItems.length > 0) {
      setErrors({
        name: t('holidayItemAlreadyExist'),
        date: t('holidayItemAlreadyExist')
      });
    } else {
      setShowModal(false);
      setHolidays(item => supplimentHolidayState(item, holiday));
      changesHolidayItem.find(item => holiday.holidayItemId === item.holidayItemId) ?
      setChangesHolidayItem(supplimentHolidayState(changesHolidayItem, holiday))
      : setChangesHolidayItem(item => [...item, holiday])
      setHolidayItemIdEditParam(item => [...new Set(item.concat(holiday.holidayItemId))]);
    }
  }

  const findHoliday = (holidayData, values) => {
    return holidayData.find(holidayItem => (holidayItem.name === values.name && holidayItem.date === moment(values.date).format(DATE_FORMAT)));
  }

  const supplimentHolidayState = (item, updateHoliday) => {
    return item.map(value => {
      return (value.holidayItemId === updateHoliday.holidayItemId) ? updateHoliday : value
    })
  } 

  const closeModal = () => {
    setShowModal(false);
    if (type === ACTION_CREATE) {
      setName('');
      setSelectedDate(null);
    } else {
      setName(itemValue.name);
      setSelectedDate(moment(itemValue.date, 'DD.MM.YYYY').format(DATE_FORMAT_YYYY_MM_DD));
    }
  }

  return (
    <Box>
      <Modal
        disableEnforceFocus
        open={showModal}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className={classes.filterModal}
        autoFocus={false}
      >
        <Fade in={showModal}>
          <Container id="holidayCreateModalContainer" className={`${classes.modalContent}`}>
            <Box className={`${classes.titleFilterModal} ${'bold'} ${classes.form}`}> { type === 'create' ? `${t('createHoliday')}` : `${t('updateHoliday')}` }</Box>
            <hr className={classes.hrDividerProfile} />
              <Content
                type={type}
                setName={setName}
                setDate={setSelectedDate}
                initialValues={initialValues}
                handleSubmit={handleSubmit}
                closeModal={closeModal}
              />
          </Container>
        </Fade>
      </Modal>
    </Box>
  );

}

export default CreateModal;