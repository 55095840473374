import { makeStyles } from '@material-ui/core';
import colors from '../../../theme/colors';

export default makeStyles((theme) => ({
  container: {
    marginTop   : theme.spacing(4),
    paddingLeft : theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  blockQuoteContainer: {
    paddingLeft  : theme.spacing(1.25),
    marginBottom : theme.spacing(0.5),
    marginTop    : theme.spacing(0.5),
    marginLeft   : theme.spacing(0),
    borderLeft   : '2px solid #ccc',
  },
  blockQuoteContent: {
    color : colors.PRIMARY,
    cursor: 'pointer'
  },
  blockQuoteContentAdditive: {
    marginBottom: theme.spacing(1)
  },
  guideImg: {
    marginTop    : theme.spacing(1.5),
    marginBottom : theme.spacing(1.5),
    marginLeft   : theme.spacing(1),
    borderRadius : theme.spacing(.5),
    filter: 'brightness(0.9)',
    '&:hover': {
      filter: 'brightness(1)',
    },
  },
  backToSyncButton: {
    color           : colors.PRIMARY,
    backgroundColor : colors.WHITE,
    border          : `1px solid ${colors.BLACK}`,
    boxShadow       : 'none',
    borderRadius    : theme.spacing(1),
    minHeight       : theme.spacing(5),
    width           : 'auto', 
    whiteSpace      : 'nowrap',
    '&:hover': {
      boxShadow      : 'none',
      backgroundColor: colors.PRIMARY_LIGHT
    }
  },
  backToSyncButtonContainer: {
    marginTop    : theme.spacing(3),
    marginBottom : theme.spacing(4),
    marginLeft   : theme.spacing(1)
  },
  stepWithLink: {
    marginBottom: theme.spacing(1.5),
    marginLeft  : theme.spacing(2)
  },
  textToClipboard: {
    color : colors.PRIMARY,
    cursor: 'pointer'
  },
  tableOfContents: {
    marginTop  : theme.spacing(4),
    marginLeft : theme.spacing(1)
  },
  content: {
    marginTop: theme.spacing(4)
  },
  sectionContainer: {
    marginBottom: theme.spacing(2)
  },
  textWithLink: {
    color         : colors.BLACK,
    textDecoration: 'underline'
  }
}));
