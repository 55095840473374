import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  paper: {
    marginTop : theme.spacing(3),
    
    '& .MuiTextField-root': {
      marginTop : theme.spacing(0)
    }
  },
  form: {
    padding       : theme.spacing(3),
  },
  options:{
    display         : 'flex',
    justifyContent  : 'flex-end',
  },
  updateIcon:{
    height  : theme.spacing(3.5),
    width   : theme.spacing(2.5),
  },
  iconBox: {
    marginRight: theme.spacing(2)
  },
  statusBarLabel: {
    display       : 'flex',
    justifyContent: 'space-between'
  },
  image: {
    height          : theme.spacing(48),
    marginBottom    : theme.spacing(-7.625),
    marginTop       : theme.spacing(-11.625),
    width           : theme.spacing(34.3)
  },
  flexForm: {
    display : 'flex'
  },
  text: {
    marginBottom  : theme.spacing(0.2)
  },
  containerDetails: {
    paddingBottom : theme.spacing(2),
    paddingTop    : theme.spacing(4),

    '& .MuiSkeleton-root': {
      transform: 'none',
    },
  },
  details: {
    padding: theme.spacing(3)
  },
}));