import { Box, Grid, Paper, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import { CONTROLLERS_MODULE, DELETE, GET, PATCH, TEXT_FIELD_SKELETON } from '../../../utility/constants';
import useStyles from './styles';

const ControllerSkeleton = (props) => {
  const { handlePermissions, isCreatePage } = props;

  const classes = useStyles()
  return (
    <>
      {
        !isCreatePage &&
          <Box>
            <Paper className={classes.paper} elevation={3}>
              <Grid container spacing={2} className={`${classes.form} ${classes.flexForm}`}>
                <Grid item xs={8}>
                  <Skeleton className={classes.image}/>
                </Grid>
                <Grid item xs={4} className={classes.options}>
                  <Box className={handlePermissions(CONTROLLERS_MODULE, DELETE) ? classes.iconBox : 'hidden'} >
                    <Skeleton className={classes.updateIcon}/> 
                  </Box>
                  <Box className={!handlePermissions(CONTROLLERS_MODULE, PATCH) ? 'hidden' : classes.iconBox} >
                    <Skeleton className={classes.updateIcon}/> 
                  </Box>
                  <Box className={!handlePermissions(CONTROLLERS_MODULE, PATCH) ? 'hidden' : classes.iconBox} >
                    <Skeleton className={classes.updateIcon}/> 
                  </Box>
                  <Box className={!handlePermissions(CONTROLLERS_MODULE, PATCH) ? 'hidden' : classes.iconBox} >
                    <Skeleton className={classes.updateIcon}/> 
                  </Box>
                  <Box className={!handlePermissions(CONTROLLERS_MODULE, GET) ? 'hidden' : classes.iconBox} >
                    <Skeleton className={classes.updateIcon}/> 
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2} className={classes.form}>
                <Grid item xs={6}>
                    <Skeleton height={30} className={classes.text}/>
                    <Skeleton height={30} className={classes.text}/>
                    <Skeleton height={30} className={classes.text}/>
                    <Skeleton height={30} className={classes.text}/>
                    <Skeleton height={30} className={classes.text}/>
                    <Skeleton height={30} className={classes.text}/>
                    <Skeleton height={30} className={classes.text}/>
                </Grid>
                <Grid item xs={6}>
                  <Skeleton height={30}/>
                  <Box>
                    <Box className={classes.statusBarLabel}>
                      <Typography >
                        <Skeleton width={100}/>
                      </Typography>
                      <Typography >
                        <Skeleton width={30}/>
                      </Typography>
                    </Box>
                    <Box>
                      <Skeleton height={20}/>
                    </Box>
                  </Box>
                  <Box>
                    <Box className={classes.statusBarLabel}>
                      <Typography >
                        <Skeleton width={100}/>
                      </Typography>
                      <Typography >
                        <Skeleton width={30}/>
                      </Typography>
                    </Box>
                    <Box>
                      <Skeleton height={20}/>
                    </Box>
                  </Box>
                  <Box>
                    <Box className={classes.statusBarLabel}>
                      <Typography >
                        <Skeleton width={100}/>
                      </Typography>
                      <Typography >
                        <Skeleton width={30}/>
                      </Typography>
                    </Box>
                    <Box>
                      <Skeleton height={20}/>
                    </Box>
                  </Box>
                  <Box>
                    <Box className={classes.statusBarLabel}>
                      <Typography >
                        <Skeleton width={100}/>
                      </Typography>
                      <Typography >
                        <Skeleton width={30}/>
                      </Typography>
                    </Box>
                    <Box>
                      <Skeleton height={20}/>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Box>
      }
      <Box className={classes.containerDetails}>
        <Paper className={`${classes.paper} ${classes.details}`} elevation={3}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Skeleton
              height={TEXT_FIELD_SKELETON}
            />
          </Grid>
          <Grid item xs={6}>
            <Skeleton
              height={TEXT_FIELD_SKELETON}
            />
          </Grid>
        </Grid>
        </Paper>
      </Box>
    </>
  )
}

export default ControllerSkeleton;