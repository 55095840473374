import moment from "moment";
import { ALL, DESCENDING, FILTER_EVENT_FORMAT, TIME } from "../utility/constants";

export const ADD_FILTER        = 'ADD_FILTER';
export const CLEAR_FILTER      = 'CLEAR_FILTER';
export const ADD_ROWS_PER_PAGE = 'ADD_ROWS_PER_PAGE';
export const UPDATE_LIST_TYPE  = 'UPDATE_LIST_TYPE'; 

export const initialState = {
  listType        : ALL,
  types           : [],
  subTypes        : [],
  from            : null,
  deviceIds       : [],
  until           : moment().format(FILTER_EVENT_FORMAT),
  accessPoint     : '',
  credentialNumber: '',
  keyword         : '',
  size            : 100,
  page            : 1,
  sort            : `${TIME},${DESCENDING}`
}

export const EventsFilterReducer = (state, action) => {
  switch (action.type) {
    case ADD_FILTER:
      return {
        ...state,
        ...action.payload
      }
    case ADD_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage : action.payload
      }
    case CLEAR_FILTER: {
      return initialState
    }
    case UPDATE_LIST_TYPE: {
      return {
        ...state,
        listType : action.payload
      }
    }
    default:
      return state
  }
}