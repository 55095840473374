import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
  },
  form: {
    padding         : theme.spacing(1),
    paddingLeft     : theme.spacing(2),
    paddingRight    : theme.spacing(2),

    '& .MuiSkeleton-root': {
      transform     : 'none',
      marginBottom  : theme.spacing(1)
    },
  },
  name: {
    marginLeft      : '67px',
  },
  header: {
    marginLeft      : '67px',
  },
  headerCell:{
    height          : '79px',
    width           : '216.59px',
    border          : 'none',
    padding         : '4px',
    paddingBottom   : theme.spacing(2),
    paddingTop      : theme.spacing(2),
  },
}));