import { ViewState } from '@devexpress/dx-react-scheduler';
import { Appointments, Scheduler, WeekView } from '@devexpress/dx-react-scheduler-material-ui';
import { Box, Button, Menu, MenuItem, Table, TableBody, TableCell, TableRow, TextField, Typography } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import clsx from 'clsx';
import NestedMenuItem from 'material-ui-nested-menu-item';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../service/api';
import { currentDate, duplicateSchedule, formatTimeToHour, formatTimeToSecond, isExist, weekdays } from '../schedule-utils';
import useStyles from './styles';

const formatSchedules = (day, startDate, endDate) => {
  const currentDay = currentDate.day(day).format("ddd MM DD YYYY");
  let   startTime  = formatTimeToSecond(startDate);
  let   endTime    = formatTimeToSecond(endDate);
  return ({
    startDate : `${currentDay} ${startTime}`,
    endDate   : `${currentDay} ${endTime}`,
  });
};

const TimeTableCell = (props) => {
  const classes = useStyles();
  return <WeekView.TimeTableCell {...props} className={classes.tableCell}/>;
};

const WeekViewTimeScaleLayout = (props) => {
  const classes = useStyles();
  return (
    <Table>
      <TableBody
        labelcomponent="timeScaleLayout"
        tickcellcomponent="timeScaleLayout"
        cellsdata="timeScaleLayout"
        className={classes.timeScaleLabel}
      >
        {
          [...Array(24)].map((_, index) => {
            return(
              <TableRow key={index} >
                <TableCell component="th">{`${('0'+ index).slice(-2)}:00`}</TableCell>
              </TableRow>
            )
          })
        }
      </TableBody>
    </Table>
  );
};

const AppointmentsContainer = (props) => {
  const classes = useStyles();
  return <Appointments.Container {...props} className={classes.containerTable}/>;
};

const AppointmentsProps = (props, disabled, setWeekItem, setDeleteItemList) => {
  const { startDate, endDate, id, scheduleItemId } = props.data;
  const classes = useStyles();
  let startTime = formatTimeToHour(startDate);
  let endTime   = formatTimeToHour(endDate);

  const [inputNum, setInputNum] = useState(2);
  const [anchorEl, setAnchorEl] = useState(null);
  const [alignTime, setAlignTime] = useState('');

  const open = Boolean(anchorEl);

  const classSet = useCallback((start, end) => {
    const duration = moment.duration(moment(endDate).diff(moment(startDate)));
    if (duration._data.hours < 3) {
      setAlignTime(classes.minHeight)
    } else {
      setAlignTime(classes.normal)
    }
  }, [classes.minHeight, classes.normal, endDate, startDate])

  useEffect(() => {
    classSet();
    return () => setAlignTime()
  }, [startDate, endDate, classSet]);

  const handleClick = (event) => {
    if (disabled) return;
    open ? setAnchorEl(null) : setAnchorEl(event.target)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDuplicate = () => {
    const duplicate = duplicateSchedule(startDate, endDate, 1);

    setWeekItem(weekItem => {
      if (isExist(weekItem, duplicate)) {
        return weekItem
      } else {
        return [...weekItem, duplicate]
      }
    });
  }

  const handleMultipleDelete = () => {
    [...Array(parseInt(inputNum) + 1)].map((_, num) => {
      let multiDuplicate = duplicateSchedule(startDate, endDate, num);
      setWeekItem(weekItem => {
        if (isExist(weekItem, multiDuplicate)) {
          return weekItem
        }
        return [...weekItem, multiDuplicate];
      });
      return null;
    })
  }

  const handleChange = (event) => {
    const { value } = event.target;
    if (value > 6 || value < 2) return;
    setInputNum(value.replace(/\D/,''));
  }

  const handleDeleteAppointment = () => {
    const { scheduleItemId } = props.data;

    if (scheduleItemId) {
      const url = `${api.SCHEDULES_ITEMS}/${scheduleItemId}`;
      setDeleteItemList(items => [...items, url]);
    }
    setWeekItem(weekItem => weekItem.filter(element => element.id !== id))
  }

  return (
    <>
      <Box className={clsx(disabled ? 'hidden' : classes.appointmentButton)}>
        <AppointmentMenu
          id="appointmentMenu"
          handleDeleteAppointment={handleDeleteAppointment}
          handleDuplicate={handleDuplicate}
          handleMultipleDelete={handleMultipleDelete}
          handleChange={handleChange}
          inputNum={inputNum}
          handleClose={handleClose}
          anchorEl={anchorEl}
          open={open}
          setAnchorEl={setAnchorEl}
        />
      </Box>
      <Appointments.Appointment {...props} onClick={handleClick} className={`${clsx(disabled ? classes.disable : classes.hover)} ${classes.appointmentContainer}`}>
        <Box id={`scheduleItem${scheduleItemId}`} className={`${alignTime} ${classes.appointment}`}>
          <Typography id={`scheduleItem${scheduleItemId}Time`} className={classes.time}>{`${startTime} - ${endTime}`}</Typography>
        </Box>
      </Appointments.Appointment>
    </>
  );
};

const AppointmentContent = (props) => {
  const classes = useStyles();
  return <Appointments.AppointmentContent {...props} className={classes.appointmentContent}/>;
};

const DayScaleLayoutProps = (props) => {
  const classes = useStyles();
  return <WeekView.DayScaleCell {...props} className={classes.dayScaleLayout}/>
}

const AppointmentMenu = (props) => {
  const { handleDeleteAppointment, handleDuplicate, handleMultipleDelete, handleChange, inputNum, anchorEl, open, handleClose } = props;
  const classes = useStyles();
  const { t }   = useTranslation();

  return (
    <Menu
      id="scheduleMenu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'button',
      }}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <MenuItem id="duplicateButton" onClick={handleDuplicate}>{t('duplicate')}</MenuItem>
      <NestedMenuItem
        id="multiDuplicate"
        label={t('multiDuplicate')}
        parentMenuOpen={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Box className={classes.multiDuplicate} >
          <TextField
            id="duplicateField"
            size="small"
            type="number"
            InputProps={{ inputProps: { min: 2, max: 6 } }}
            value={inputNum}
            onChange={handleChange}
          />
          <Button id="duplicateButton" variant="outlined" onClick={handleMultipleDelete} color="primary">{t('duplicate')}</Button>
        </Box>
      </NestedMenuItem>
      <MenuItem id="deleteButton" onClick={handleDeleteAppointment}>{t('delete')}</MenuItem>
    </Menu>
  )
};

const SchedulerLabelComponent = (disabled, alignment, handleAlignment) => {
  const classes = useStyles();
  const { t }   = useTranslation();

  return (
    <WeekView.DayScaleRow className={classes.headerScaleLabel}>
      {
        weekdays.map((weekday, index) => {
          return (
            <TableCell key={index} align="center">
              <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleAlignment}
                aria-label="text alignment"
              >
                <ToggleButton id={`schedule${weekday}TableHeaderButton`} value={weekday} disabled={disabled} aria-label="left aligned">
                  {t(weekday)}
                </ToggleButton>
              </ToggleButtonGroup>
            </TableCell>
          )
        })
      }
    </WeekView.DayScaleRow>
    )
  };

const ScheduleTable = (props) => {
  const { weekItem, disabled, alignment, handleAlignment, setWeekItem, setDeleteItemList } = props

  const newAppointments = weekItem.map(({ day, timeFrom, timeUntil, ...restArgs }) => {
    return ({
      ...formatSchedules(day, timeFrom, timeUntil),
      ...restArgs,
    });
  });

  return(
    <Scheduler data={newAppointments}>
      <ViewState/>
      <WeekView
        cellDuration={60}
        dayScaleRowComponent={() => SchedulerLabelComponent(disabled, alignment, handleAlignment)}
        dayScaleCellComponent={DayScaleLayoutProps}
        endDayHour={24}
        startDayHour={0}
        timeScaleLayoutComponent={WeekViewTimeScaleLayout}
        timeTableCellComponent={TimeTableCell}
      />
      <Appointments
        id="appointmentContainer"
        containerComponent={AppointmentsContainer}
        appointmentComponent={(prop) => AppointmentsProps(prop, disabled, setWeekItem, setDeleteItemList)}
        appointmentContentComponent={AppointmentContent}
      />
    </Scheduler>
  );
 }
export default ScheduleTable;