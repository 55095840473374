import { createContext, useReducer } from "react";
import { userInitialState, WizardPersonReducer } from "../reducer/wizardPersonReducer";

export const WizardPersonContext = createContext();

const WizardPersonProvider = ({ children }) => {
  const [state, dispatch] = useReducer(WizardPersonReducer, userInitialState);

  return (
    <WizardPersonContext.Provider value={{ state, dispatch }}>
      {children}
    </WizardPersonContext.Provider>
  )
}
export default WizardPersonProvider;