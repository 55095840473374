import { Container } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import DeleteModal from '../../components/delete-modal';
import Table, { createColumn } from '../../components/table';
import Title from '../../components/title';
import { LocationContext } from '../../context/locationContext';
import api from '../../service/api';
import { deleteMultipleProfiles, deleteProfile, getProfileRoles } from '../../service/profilesApi';
import { request } from '../../service/requests';
import { ALL, API_REQUEST_ERROR_MESSAGE, ASCENDING, DATE_FORMAT, GET, LOCATION, PROFILE, PROFILES, PROFILES_MODULE, PROJECTION } from '../../utility/constants';
import { formatAssociated, convertToUTC, parseIntegerParam, updateURLParams } from '../../utility/helper';
import useStyles from './styles';

const columns = [
  createColumn("name", "profileName", true,"string", false, true),
  createColumn("profileId", "profileId", false, "string", true),
  createColumn("schedule", "schedule", false, "component"),
  createColumn("validFrom", "validFrom", true, "date"),
  createColumn("validUntil", "validUntil", true, "date"),
  createColumn("location", "location", true, "string")
];

const Profiles = (props) => {
  const { showToaster, handlePermissions } = props;
  const classes                               = useStyles();
  const history                               = useHistory();
  const { t }                                 = useTranslation();

  const { search, pathname } = history.location;

  const params      = new URLSearchParams(search);
  const query       = params.get('keyword') ? params.get('keyword') : '';
  const pageSize    = params.get('size') ? parseIntegerParam(params.get('size'), 10) : 10;
  const pageNumber  = params.get('page') ? parseIntegerParam(params.get('page'), 1) : 1;
  const pageType    = params.get('profiles') ? params.get('profiles') : ALL.toLowerCase();
  const totalSize   = pageSize > 100 ? 100 : pageSize;

  const { state : locationState }             = useContext(LocationContext);
  const { selectedLocationIds }               = locationState;

  const [forDelete, setForDelete]             = useState([]);
  const [forDeleteName, setForDeleteName]     = useState([]);
  const [isLoading, setIsLoading]             = useState(false)
  const [keyword, setKeyword]                 = useState(query);
  const [prevKeyword, setPrevKeyword]         = useState(query);
  const [order, setOrder]                     = useState(ASCENDING);
  const [orderBy, setOrderBy]                 = useState(columns[0].id);
  const [page, setPage]                       = useState(pageNumber === 0 ? 1 : pageNumber);
  const [profiles, setProfiles]               = useState([]);
  const [rowsPage, setRowsPage]               = useState(totalSize);
  const [showModal, setShowModal]             = useState(false);
  const [totalItems, setTotalItems]           = useState(0);
  const [type , setType]                      = useState('single');
  const [assocValues, setAssocValues]         = useState('');
  const [title, setTitle]                     = useState('');
  const [listType, setListType]               = useState(pageType);

  const urlParams = useMemo(() => ({
    keywordParam :  keyword ? `&keyword=${keyword}` : '',
    entityParam  : `profiles=${listType}`,
    pageParam    : `&page=${page}`,
    sizeParam    : `&size=${rowsPage}`,
    history      : history,
    pathname     : pathname
  }), [keyword, listType, page, rowsPage, history, pathname]);

  const getFormattedProfile = (item) => {
    const { name, validFrom, validUntil, profileId, schedules, location } = item;
    
    const newSchedules = schedules.map(schedule => {
      return {
        name : schedule.name
      }
    });

    return {
      name        : name,
      profileId   : profileId,
      schedules   : newSchedules,
      validFrom   : convertToUTC(validFrom).format(DATE_FORMAT),
      validUntil  : convertToUTC(validUntil).format(DATE_FORMAT),
      location   : location ? location.name : '-'
    }
  }

  const getFormattedProfiles = useCallback((items) => {
    return items.map(item => {
      return getFormattedProfile(item);
    })
  }, [])

  const setProfilesResponse = useCallback((response) => {
    const { data }                                   = response;
    const { size, totalElements, number, totalPages} = data.page;

    const newProfiles = getFormattedProfiles(data._embedded.profiles);
    const totalNumber = number > totalPages ? totalPages : number;

    if (newProfiles.length || number === 0) {
      setPage(totalNumber + 1);
      setProfiles(newProfiles);
    } else {
      setPage(totalNumber)
    }

    setRowsPage(size);
    setTotalItems(totalElements);
  }, [getFormattedProfiles])

  const getUrl = useCallback(() => {
    if (orderBy === LOCATION) {
      return (order === ASCENDING) ? api.PROFILES_SEARCH_ORDER_BY_LOCATION_NAME_ASC : api.PROFILES_SEARCH_ORDER_BY_LOCATION_NAME_DESC;
    } else {
      return (keyword === '') ? api.PROFILES : api.PROFILES_BY_NAME;
    }
  }, [keyword, order, orderBy])

  const getProfiles = useCallback(async (isRemove) => {
    setPrevKeyword(keyword);
    setIsLoading(!isRemove);

    try {
      const response = await request({
        url     : getUrl(),
        method  : GET,
        params  : {
          name      : keyword,
          size      : rowsPage,
          page      : page - 1,
          sort      : `${orderBy},${order}`,
          projection: PROJECTION.PROFILES,
        }
      });

      setProfilesResponse(response);
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setIsLoading(false);
    }
  }, [keyword, order, orderBy, page, rowsPage, getUrl, setProfilesResponse, showToaster, t]);

  const getData = useCallback(async () => {
    if (listType === ALL.toLowerCase()) {
      setTitle(PROFILES);
      await getProfiles();
    } else {
      setListType(ALL.toLowerCase());
    }
  }, [getProfiles, listType]);

  useEffect(() => {
    let delayDebounce;

    
    if (keyword && keyword !== prevKeyword) {
      delayDebounce = setTimeout(() => {
        updateURLParams(urlParams);
        getData();
      }, 1000);
    } else {
      updateURLParams(urlParams);
      getData();
    }

    return () => {
      delayDebounce && clearTimeout(delayDebounce);
    }
  }, [keyword, prevKeyword, page, rowsPage, order, orderBy, listType, selectedLocationIds, urlParams, getData]);

  const handleSearch = (value) => {
    setPage(1);
    setKeyword(value);
  }

  const handleClearSearch = () => {
    setPage(1);
    setKeyword('');
  }

  const handleSort = (sortby, prop) => {
    setPage(1);
    setOrderBy(sortby);
    setOrder(prop);
  }

  const handleRowsPerPage = (rowsPerPage) => {
    setRowsPage(rowsPerPage);
    setPage(1);
  }
  
  const handleChangePage = (newPage) => {
    if (isLoading) {
      return;
    }

    setPage(newPage + 1);
  }

  const handleView = (value) =>{
    const { profileId } = value;
    history.push(`profiles/view/${profileId}`);
  }

  const handleCreate = () => {
    history.push("/profiles/create");
  }

  const handleUpdate = (value) => {
    const { profileId } = value;
    history.push(`/profiles/update/${profileId}`);
  }

  const handleDelete = async (value) => {
    const response = await getProfileRoles([value.profileId]);

    const { roles } = response[0].data._embedded;

    if (roles.length) {
      setAssocValues(formatAssociated(roles.map(role => (` ${role.name}`)), t));
      handleOpenDeleteModal(value, value.name, 'singleWithAssociated');
    } else {
      handleOpenDeleteModal(value, value.name, 'single');
    }
  }

  const singleDelete = async (value) => {
    if (Array.isArray(value)) {
      value = value[0];
    }
    try {
      
      await deleteProfile(value.profileId);
      getProfiles(true);
      showToaster(t('success'), `${value.name} ${t('hasBeenDeleted')}`, 'success');  
    } catch (error) {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }
  }

  const handleMultipleDelete = async (values) => {
    const profileIds = values.map(value => value.profileId);

    const responses = await getProfileRoles(profileIds);
    
    const roles = responses.map(response => response.data._embedded.roles).flat();

    if (roles.length) {
      const formattedAssocValues = formatAssociated([...new Set(roles.map(role => (` ${role.name}`)))], t);
      
      setAssocValues(formattedAssocValues);

      if (values.length === 1) {
        handleOpenDeleteModal(values, values[0].name, 'singleWithAssociated');
      } else {
        handleOpenDeleteModal(values, '', 'multipleWithAssociated');
      }
    } else if (values.length === 1) {
      handleOpenDeleteModal(values[0], values[0].name, 'single');
    }
    else {
      handleOpenDeleteModal(values, '', 'multiple');
    }
  }

  const multipleDelete = async (values) => {
    const ids             = values.map(value => value.profileId);
    const profileName     = values.map(value => value.name);
    const lastProfileName = profileName.pop();

    try {
      await deleteMultipleProfiles(ids);

      if ((totalItems - profiles.length) / rowsPage === 1 && page > 0) {
        setPage(page - 1);
      }
      getProfiles(true);
      showToaster(t('success'), `${profileName.join(', ')}, ${t('and')} ${lastProfileName} ${t('hasBeenDeleted')}`, 'success');
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }
  }

  const handleOpenDeleteModal = (value, name, paramType) => {
    setForDelete(value);
    setForDeleteName(name);
    setType(paramType);
    setShowModal(true);
  }

  const handleCloseDeleteModal = () => {
    setShowModal(false);
  }

  const handleType = () => {
    setListType(ALL.toLowerCase());
  }

  return (

    <Container maxWidth="xl" className={classes.container}>
      <Title title={t(title)} listType={listType}/>
      <DeleteModal
        assocValues={assocValues}
        forDeleteName={forDeleteName}
        initialValues={forDelete}
        module={PROFILES_MODULE}
        multipleDelete={multipleDelete}
        onClose={handleCloseDeleteModal}
        open={showModal}
        showToaster={showToaster}
        singleDelete={singleDelete}
        type={type}
      />
      <Table
        columns={columns}
        data={profiles}
        isLoading={isLoading}
        keyword={keyword}
        label={PROFILE}
        module={PROFILES_MODULE}
        onChangePage={handleChangePage}
        onClearSearch={handleClearSearch}
        onCreate={handleCreate}
        onDelete={handleDelete}
        onMultipleDelete={handleMultipleDelete}
        onRowsPerPageChange={handleRowsPerPage}
        onSearch={handleSearch}
        onSort={handleSort}
        onUpdate={handleUpdate}
        onView={handleView}
        orderBy={orderBy}
        order={order}
        page={page}
        rowsPerPage={rowsPage}
        totalItems={totalItems}
        viewKey={'name'}
        listType={listType}
        onViewAll={handleType}
        handlePermissions={handlePermissions}
      />
    </Container>
  );
}

export default Profiles;