import { Box, Button, Container, Grid, IconButton, Paper, TextField, Tooltip, Typography } from '@material-ui/core';
import { Cancel as CancelIcon, CheckCircle as CheckCircleIcon, Edit, MeetingRoomOutlined as MeetingRoomOutlinedIcon } from '@material-ui/icons';
import { Form, Formik } from 'formik';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory } from 'react-router-dom';
import Chip from '../../components/chip';
import Chips from '../../components/chips';
import ProgressBar from '../../components/progress-bar';
import SelectItems from '../../components/select-items';
import Title from '../../components/title';
import UnsavedModal from '../../components/unsaved-modal';
import { AuthContext } from '../../context/authContext';
import { LocationContext } from '../../context/locationContext';
import { createController, getControllerById, updateController } from '../../service/controllersApi';
import { ACTION_CREATE, ACTION_VIEW, API_REQUEST_ERROR_MESSAGE, CHIP_COLOR, CONTROLLERS_MODULE, CONTROLLER_DEVICE_TYPE, CONTROLLER_HOUSING, CONTROLLER_PATH, CPU, DATA_DISK, DEVMAN_PATH, GET, HTTP, HTTPS, LOCATIONS_MODULE, MAX_CHARACTER_LIMIT, OFFLINE, OFFLINE_STATUS, ONLINE, ONLINE_STATUS, PATCH, RAM, READ_MODE, SYSTEM_DISK, UPDATE, WSS, WSTOPIC } from '../../utility/constants';
import images from '../../utility/images';
import { GetInitialLocationId, GetInitialLocationObject, getLocation, hasLocationChange } from '../../utility/location';
import { controllerSchema } from '../../validation/schema';
import { ControllerModal } from './controller-modal';
import ControllerSkeleton from './controller-skeleton';
import useStyles from './styles';

const Content = (props) => {
  const classes   = useStyles();
  const { t }     = useTranslation();
  const { id, administrator, initialValues, showToaster, overAllState, showLoading, handleOpenModal, handleCloseModal, setShowModal, showModal, setOption, option, controllerData, formikRef, handleSelectedLocation, selectedLocation, disabled, history, handleSubmit, handleCancel, handlePermissions, setName, isCreatePage, setUpdateUrl, setIsUrlField, deviceType, housing } = props

  const isControllerOnboarded = initialValues.deviceId !== '---';

  const getChip = (formik) => {
    if (formik.values.status.includes(ONLINE) || formik.values.status.includes(ONLINE_STATUS)) {
      return <Chip data-testid="controllerStatus" recorddata-testid={id} icon={<CheckCircleIcon fontSize="small"/>} description={t('controller-page.online')} color="online" variant="outlined" fontSize="small"/>
    }

    if (formik.values.status.includes(OFFLINE) || formik.values.status.includes(OFFLINE_STATUS)) {
      return <Chip data-testid="controllerStatus" recorddata-testid={id} icon={<CancelIcon fontSize="small" />} description={t('controller-page.offline')} color="offline" variant="outlined" fontSize="small"/>
    }

    return <Typography noWrap>{formik.values.status}</Typography>
  }

  const handleChange = (setter, formik) => {
    return (setter, formik);
  }

  const getControllerImage = () => {
    if (deviceType === CONTROLLER_DEVICE_TYPE.SECPASS) {
      return images.SECPASS_CONTROLLER;
    } else if (housing === CONTROLLER_HOUSING.NINETEENINCHRACK) {
      return images.X2CPASS_PLUS_CONTROLLER;
    } else {
      return images.X2CPASS_CONTROLLER;
    }
  }

  const getButtonLabel = () => {
    return id ? `${t('controller-page.update')}` : `${t('controller-page.add')}`;
  }

  return(
    <Formik
      enableReinitialize
      initialValues={initialValues}
      innerRef={formikRef}
      onSubmit={handleSubmit}
      validationSchema={controllerSchema}
    >
      {
        formik => (
          <Form>
            {
              !isCreatePage &&
                <>
                  <Paper data-testid="controllerContainer" className={classes.mainPaper} elevation={3}>
                    <Grid container spacing={2} className={`${classes.form} ${classes.flexForm}`}>
                      <Grid item xs={8}>
                        <img
                          src={getControllerImage()}
                          alt='controller'
                          className={housing === CONTROLLER_HOUSING.NINETEENINCHRACK ? classes.rectangularImage : classes.squareImage}
                        />
                      </Grid>
                      <Grid item xs={4} className={classes.options}>
                        <ControllerModal
                          formik={formik}
                          controllerId={id}
                          controllerData={controllerData}
                          initialValues={initialValues}
                          label={formik.values.name}
                          showToaster={showToaster}
                          showLoading={showLoading}
                          handleOpenModal={handleOpenModal}
                          handleCloseModal={handleCloseModal}
                          setShowModal={setShowModal}
                          setUpdateUrl={setUpdateUrl}
                          setIsUrlField={setIsUrlField}
                          showModal={showModal}
                          setOption={setOption}
                          option={option}
                          handlePermissions={handlePermissions}
                          isControllerOnboarded={isControllerOnboarded}
                          administrator={administrator}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.form}>
                      <Grid item className={classes.detailsForm}>
                        <Grid item data-testid='controllerDeviceType' className={`${classes.flexForm} ${classes.space}`}>
                          <Typography className={classes.text} noWrap>
                            { t(`controller-page.name`) }
                          </Typography> &nbsp;
                          <Box noWrap className={classes.flexValue}>{ formik.values.name }</Box>
                        </Grid>
                        <Grid item data-testid='controllerDeviceType' className={`${classes.flexForm} ${classes.space}`}>
                          <Typography className={isControllerOnboarded ? classes.text : classes.textDisabled} noWrap>
                            { t(`controller-page.deviceType`) }
                          </Typography> &nbsp;
                          <Box noWrap className={!isControllerOnboarded && classes.textDisabled}>{ formik.values.deviceType }</Box>
                        </Grid>
                        <Grid item data-testid='controllerDeviceId' className={`${classes.flexForm} ${classes.space}`}>
                          <Typography className={isControllerOnboarded ? classes.text : classes.textDisabled} noWrap>
                            { t(`controller-page.deviceId`) }
                          </Typography> &nbsp;
                          <Box noWrap className={!isControllerOnboarded && classes.textDisabled}>{ formik.values.deviceId }</Box>
                        </Grid>
                        <Grid item data-testid='controllerSerialNumber' className={`${classes.flexForm} ${classes.space}`}>
                          <Typography className={isControllerOnboarded ? classes.text : classes.textDisabled} noWrap>
                            { t(`controller-page.serialNumber`) }
                          </Typography> &nbsp;
                          <Box noWrap className={!isControllerOnboarded && classes.textDisabled}>{ formik.values.serialNo }</Box>
                        </Grid>
                        <Grid item data-testid='controllerOSVersion' className={`${classes.flexForm} ${classes.space}`}>
                          <Typography className={isControllerOnboarded ? classes.text : classes.textDisabled} noWrap>
                            { t(`controller-page.osVersion`) }
                          </Typography> &nbsp;
                          <Box noWrap className={!isControllerOnboarded && classes.textDisabled}>{ formik.values.osVersion }</Box>
                        </Grid>
                        <Grid item data-testid='controllerSWVersion' className={`${classes.flexForm} ${classes.space}`}>
                          <Typography className={isControllerOnboarded ? classes.text : classes.textDisabled} noWrap>
                            { t(`controller-page.swVersion`) }
                          </Typography> &nbsp;
                          <Box noWrap className={!isControllerOnboarded && classes.textDisabled}>{ formik.values.swVersion }</Box>
                        </Grid>                  
                        <Box data-testid='controllerStatus' className={`${classes.status} ${classes.space}`}>
                          <Typography className={isControllerOnboarded ? classes.text : classes.textDisabled} noWrap>
                            { t(`controller-page.status`) }
                          </Typography> &nbsp;
                          <Box noWrap className={isControllerOnboarded ? classes.chipStatus : classes.textDisabled}>
                            { getChip(formik) }
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item className={classes.detailsForm}>
                        {
                          isControllerOnboarded &&
                            <Box>
                              <Typography variant={'h6'} className={'bold'} color={'secondary'}>{t(`controller-page.overallStateMisc`)} </Typography>
                              <ProgressBar elementdata-testid="controller" data={overAllState} space={2}/>
                            </Box>
                        }
                      </Grid>
                    </Grid>
                  </Paper>
                  <Grid container>
                    <Grid item xs={6} className={classes.containerDetails}>
                      <Typography className={classes.header}>{t('controller-page.details')}</Typography>
                    </Grid>
                    {
                      disabled && handlePermissions(CONTROLLERS_MODULE, PATCH) &&
                        <Grid item xs={6} align="right" className={classes.containerButton}>
                          <Tooltip title={t('controller-page.update')}>
                            <IconButton data-testid="controllerUpdateButton" aria-label="Update" className={classes.editButton} onClick={() => history.push(`../update/${id}`)}>
                              <Edit className={classes.editIcon} />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                    }
                  </Grid>
                </>
            }
            <Paper className={classes.detailsPaper} elevation={3}>
              <Grid data-testid="controllerDetailsContainer" container spacing={2} className={isCreatePage ? classes.createForm : classes.form}>
                <Grid item xs={6} md={6} lg={6}>
                  <TextField
                    data-testid="controllerName"
                    fullWidth
                    multiline
                    inputProps={{
                      maxlength: MAX_CHARACTER_LIMIT.TEXT_FIELD
                    }}
                    label={`${t('controller-page.nameFieldLabel')}*`}
                    name="name"
                    variant="outlined"
                    value={formik.values.name}
                    onChange={e => handleChange(setName(e.target.value), formik.handleChange(e))}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && t(formik.errors.name)}
                    disabled={disabled}
                  />
                </Grid>
                {
                  handlePermissions(LOCATIONS_MODULE, GET) &&
                    <Grid item xs={6} md={6} lg={6}>
                      <SelectItems
                        data-testid="controllerLocation"
                        helperText={t(formik.touched.location) && t(formik.errors.location)}
                        isValid={formik.touched.location && Boolean(formik.errors.location)}
                        name="Locations"
                        onChange={handleSelectedLocation}
                        selectedItems={selectedLocation}
                        showToaster={showToaster}
                        single={true}
                        disabled={disabled}
                        required={true}
                        handlePermissions={handlePermissions}
                      />
                    </Grid>
                }
              </Grid>
              {
                !disabled &&
                  <Grid className={classes.action}>
                    <Grid item xs={12}>
                      <Button
                        data-testid="controllerCancelButton"
                        className={classes.button}
                        onClick={handleCancel}
                        variant="outlined"
                        color="primary"
                      >
                        {t('controller-page.cancel')}
                      </Button>
                      <Button
                        data-testid="controllerSubmitButton"
                        className={classes.button}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        { getButtonLabel() }
                      </Button>
                    </Grid>
                  </Grid>
              }
            </Paper>
          </Form>
        )
      }
    </Formik>
  );
}

const Controller = (props) => {
  const { showToaster, showLoading, match, handlePermissions, newTopic, setNewTopic, newMessage, setNewMessage } = props;
  
  const authContext       = useContext(AuthContext);
  const { administrator } = authContext.state;
  
  const { state }  = useContext(LocationContext);

  const classes   = useStyles();
  const path      = match.path;
  const { id }    = match.params;
  const { t }     = useTranslation();

  const initialLocationId = GetInitialLocationId();
  const initialLocationObject = GetInitialLocationObject();

  const history                                       = useHistory();
  const [isLoading, setIsLoading]                     = useState(false);
  const [conName, setConName]                         = useState('');
  const [conDeviceType, setConDeviceType]             = useState('---');
  const [conDeviceId, setConDeviceId]                 = useState('---');
  const [conSerialNo, setConSerialNo]                 = useState('---');
  const [conOsVersion, setConOsVersion]               = useState('---');
  const [conSwVersion, setConSwVersion]               = useState('---');
  const [conStatus, setConStatus]                     = useState('---');
  const [conHousing, setConHousing]                   = useState('');
  const [conUsername, setConUsername]                 = useState('');
  const [conPassword, setConPassword]                 = useState('');
  const [conControllerId, setConControllerId]         = useState('');
  const [overAllState, setOverAllState]               = useState([]);
  const [accessPoints, setAccessPoints]               = useState([]);
  const [showModal, setShowModal]                     = useState(false);
  const [option, setOption]                           = useState(UPDATE);
  const [isShowChameleon, setIsShowChameleon]         = useState(false);
  const formikRef                                     = useRef(null);
  const [withChanges, setWithChanges]                 = useState(false);
  const [toRedirect, setToRedirect]                   = useState('');
  const [showUnsavedModal, setShowUnsavedModal]       = useState(false);
  const [location, setLocation]                       = useState('');
  const [selectedLocation, setSelectedLocation]       = useState(initialLocationId);
  const [locationObject, setLocationObject]           = useState(initialLocationObject);
  const [updateUrl, setUpdateUrl]                     = useState('');
  const [prevValues, setPrevValues]                   = useState([]);
  const [isUrlField, setIsUrlField]                   = useState(false);

  const isCreatePage = path.includes(ACTION_CREATE);
  const isViewPage   = path.includes(ACTION_VIEW);

  const CONTROLLER_URL = window.DEVICE_MANAGEMENT_BASE_PATH.replace(HTTPS, WSS).replace(HTTP, WSS).replace(DEVMAN_PATH, CONTROLLER_PATH);
  const PORT = window.DEVICE_MANAGEMENT_PORT;

  const controllerData = t('controller-page.controllerData', {
    url     : CONTROLLER_URL,
    port    : PORT,
    username: conUsername,
    password: conPassword,
    interpolation: { escapeValue: false }
  })

  const getInitialLocation = useCallback(() => {
    return selectedLocation || selectedLocation === undefined ? selectedLocation : location;
  }, [location, selectedLocation]);

  const initialValues = useMemo(() => {
    return {
      name              : conName,
      username          : conUsername,
      password          : conPassword,
      url               : CONTROLLER_URL,
      port              : PORT,
      controllerId      : conControllerId,
      deviceType        : conDeviceType,
      deviceId          : conDeviceId,
      serialNo          : conSerialNo,
      osVersion         : conOsVersion,
      swVersion         : conSwVersion,
      status            : conStatus,
      location          : getInitialLocation(),
      updateUrl         : updateUrl,
      isUrlField        : isUrlField,
      housing           : conHousing
    }
  }, [conName, conUsername, conPassword, CONTROLLER_URL, PORT, conHousing, conControllerId, conDeviceType, conDeviceId, conSerialNo, conOsVersion, conSwVersion, conStatus, updateUrl, isUrlField, getInitialLocation]);

  const getStatus = (status) => {
    if (status === ONLINE) {
      return ONLINE_STATUS;
    } else if (status === OFFLINE) {
      return OFFLINE_STATUS;
    } else {
      return status;
    }
  }

  const handleUnsavedModalSubmit = () => {
    setWithChanges(false);
    setShowUnsavedModal(false);
    history.push(toRedirect);
  }

  const handleUnsavedModalCancel = () => {
    handleChanges();
    setShowUnsavedModal(false);
  }

  const handleCloseUnsavedModal = () => {
    setShowUnsavedModal(false);
  }

  const getAccessPointsData = useCallback((values) => {
    return values.map(accessPoint => {
      const { accessPointId, doorMode, name, readers } = accessPoint;

      let entries = null;

      if (readers != null) {
        entries = (
          readers.map(reader => {
            return ({
              name : reader.name,
              description: t(READ_MODE[reader.readMode]) ?? ''
            })
          })
        );
      }

      return {
        id        : accessPointId,
        name      : name,
        endContent: doorMode,
        details   : entries
      }
    });
  }, [t])

  const getController = useCallback(async () => {
    setIsLoading(true);
    setWithChanges(false);
    try {

      const response = await getControllerById(id);

      const { deviceId, deviceType, osVersion, serialNumber, status, swVersion, controllerId, name, username, password, dataDisk, systemDisk, ram, cpu, accessPoints, housing } = response;
      
      setPrevValues({
        name      : name,
        location : response.location.location_id
      });
      
      const overAllStateList = [
        getControllerState(1, DATA_DISK, dataDisk),
        getControllerState(2, SYSTEM_DISK, systemDisk),
        getControllerState(3, RAM, ram),
        getControllerState(4, CPU, cpu)
      ];

      setConControllerId(controllerId);
      setConName(name);
      setConUsername(username);
      setConPassword(password);
      setOverAllState(overAllStateList);
      getLocation(response, setLocation, setLocationObject);

      // Check if controller is onboarded with the presence of status
      // If status is empty, the controller is not yet provisioned
      // In 2.2.0 we added a serial_number UNIQUE constraint and every time we create a controller. We use NEWID() as its temp serial_number
      if (status.trim()) {
        setConStatus(status);
        setConDeviceType(deviceType);
        setConDeviceId(deviceId);
        setConSerialNo(serialNumber);
        setConOsVersion(osVersion);
        setConSwVersion(swVersion);
        setConHousing(housing);
      }

      if (accessPoints?.length) {
        const accessPointData = getAccessPointsData(accessPoints);
        setAccessPoints(accessPointData);
      }
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setIsLoading(false);
      setIsShowChameleon(false);
    }
  }, [id, showToaster, t, getAccessPointsData]);

  const getControllerState = (index, name, value) => {
    return {
      id    : index,
      name  : name,
      value : value
    }
  }

  const handleWSMessage = useCallback(() => {
    const { controllerOverallStateDTO, controllerStatusDTO, newControllerDTO } = newMessage;
    const topic = newTopic;
    setNewMessage({});
    setNewTopic('');

    switch (topic) {
      case WSTOPIC.OVERALL_MISC: {
        if (conSerialNo === controllerOverallStateDTO?.serialNumber) {
          const updatedOverallState = [
            getControllerState(1, 'dataDisk', controllerOverallStateDTO.dataDisk),
            getControllerState(2, 'systemDisk', controllerOverallStateDTO.systemDisk),
            getControllerState(3, 'Ram', controllerOverallStateDTO.ram),
            getControllerState(4, 'CPU', controllerOverallStateDTO.cpu),
          ];

          setOverAllState(updatedOverallState);
        }
        return
      }
      case WSTOPIC.OVERALL_STATE: {
        if (conSerialNo === controllerOverallStateDTO?.serialNumber) {
          const updatedOverallState = [
            getControllerState(1, 'dataDisk', controllerOverallStateDTO.dataDisk),
            getControllerState(2, 'systemDisk', controllerOverallStateDTO.systemDisk),
            getControllerState(3, 'Ram', controllerOverallStateDTO.ram),
            getControllerState(4, 'CPU', controllerOverallStateDTO.cpu),
          ];

          setOverAllState(updatedOverallState);
          setConOsVersion(controllerOverallStateDTO.osVersion);
          setConSwVersion(controllerOverallStateDTO.swVersion);
        }
        return
      }
      case WSTOPIC.CONTROLLER_STATUS: {
        if (conSerialNo === controllerStatusDTO?.serialNumber) {
          setConStatus(getStatus(controllerStatusDTO.status));
        }
        return
      }
      case WSTOPIC.NEW_CONTROLLER: {
        if (id === newControllerDTO.controllerId) {
          const updatedOverallState = [
            getControllerState(1, 'dataDisk', newControllerDTO.dataDisk),
            getControllerState(2, 'systemDisk', newControllerDTO.systemDisk),
            getControllerState(3, 'Ram', newControllerDTO.ram),
            getControllerState(4, 'CPU', newControllerDTO.cpu),
          ];
          
          setConDeviceType(newControllerDTO.deviceType);
          setConDeviceId(newControllerDTO.deviceId);
          setConSerialNo(newControllerDTO.serialNumber);
          setConOsVersion(newControllerDTO.osVersion);
          setConSwVersion(newControllerDTO.swVersion);
          setConStatus(newControllerDTO.status)
          setOverAllState(updatedOverallState);
        }
        return;
      }
      case WSTOPIC.NEW_ACCESSPOINT: {
        if (id === newControllerDTO.controllerId) {
          const accessPointData = getAccessPointsData(newControllerDTO?.accessPoints);
          setAccessPoints(accessPointData);
        }
        return;
      }
      case WSTOPIC.ERROR: {
        return null;
      }
      default: return null;
    }
  }, [conSerialNo, id, newMessage, setNewMessage, newTopic, setNewTopic, getAccessPointsData])

  const handleOpenModal = (value) => {   
    setShowModal(true);
    setOption(value);
  }

  const handleCloseModal = () => {
    setShowModal(false);
  }

  const handleSelectedLocation = (value) => {
    setSelectedLocation(value.length ? value[0]?.locationId : undefined);
    setLocationObject(value);
  }

  const handleCancel = () => {
    history.push('/controllers');
  }

  const getToasterMessage = (name) => {
    return id ? t('controller-page.updatedMessage', { name: name }) : t('controller-page.createdMessage', { name: name });
  }

  const getUpdateData = (value) => {
    if (value.name !== prevValues.name) {
      return {
        name       : value.name,
        locationId: value.location
      }
    } else {
      return {
        locationId: value.location
      }
    }
  }

  const handleSubmit = async (value, formik) => {
    const { setSubmitting, setErrors } = formik;
    setIsShowChameleon(true);
    
    if (!withChanges) {
      setSubmitting(false);
      setWithChanges(false);
      history.push('/controllers');
      return;
    }

    try {
      let response;
      if (id) {
        await updateController(id, getUpdateData(value));
      } else {
        response = await createController(value.name, value.location);
      }

      showToaster(t('success'), getToasterMessage(value.name), 'success');
      setWithChanges(false);
      setShowUnsavedModal(false);

      if (id) {
        history.push('/controllers');
      } else {
        history.push(`/controllers/view/${response.data.controllerId}`);
      }
    } catch (error) {
      const err = error.response;
      if (err.status === 400 && err.data.error === 'username') {
        setErrors({
          name: t('controller-page.emptyUsername')
        });
      } else if (err.status === 409) {
        if (err.data.error === 'username') {
          setErrors({
            name: t('controller-page.usernameAlreadyExists', { username: value.name.replace(/[^a-zA-Z0-9 ]/g, '') })
          });
        } else {
          setErrors({
            name: t('controller-page.nameAlreadyExists')
          });
        }
      } else {
        showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
        setWithChanges(true);
      }
    } finally {
      setSubmitting(false);
      setIsShowChameleon(false);
    }
  }

  const handleChanges = useCallback(() => {
    if (initialValues.name === prevValues.name &&
      !hasLocationChange(location, locationObject[0]?.locationId, administrator.locations.length, state)) {
      setWithChanges(false);
    } else {
      setWithChanges(true);
    }
  }, [initialValues, prevValues, location, locationObject, administrator, state]);

  useEffect(() => {
    setPrevValues({
      name: ''
    });

    if (id) {
      getController();
    }
  }, [id, getController]);

  useEffect(() => {
    handleChanges();
  }, [handleChanges]);

  useEffect(() => {
    if (newTopic !== '' && (Object.keys(newMessage).length > 0 && newMessage.constructor === Object)) {
      handleWSMessage();
    }
  }, [newTopic, newMessage, handleWSMessage]);

  return (
    <Container maxWidth="xl" className={classes.container}>
      {showLoading(isShowChameleon)}
      <Title title={t('controller-page.title')} subtitle={`${conName}`}/>
      <UnsavedModal
        open={showUnsavedModal}
        onClose={handleCloseUnsavedModal}
        handleModalSubmit={handleUnsavedModalSubmit}
        handleModalCancel={handleUnsavedModalCancel}
      />
      <Prompt
        when={withChanges}
        message={(location, action) => {
          if (action === 'PUSH') {
            setShowUnsavedModal(true);
          }
          setWithChanges(false);
          setToRedirect(location.pathname);
          return location.pathname.startsWith('/controllers/update')
        }}
      />
      <Box className={classes.details}>
        <Grid container spacing={2}>
          <Grid item xs={isCreatePage ? 12 : 8}>
            {
              isLoading ?
                <ControllerSkeleton initialValues={initialValues} handlePermissions={handlePermissions} isCreatePage={isCreatePage}/>
              :
                <Content
                  id={id}
                  disabled={isViewPage}
                  initialValues={initialValues}
                  showToaster={showToaster}
                  overAllState={overAllState}
                  showLoading={showLoading}
                  handleOpenModal={handleOpenModal}
                  handleCloseModal={handleCloseModal}
                  setShowModal={setShowModal}
                  setName={setConName}
                  showModal={showModal}
                  setOption={setOption}
                  option={option}
                  formikRef={formikRef}
                  handleSelectedLocation={handleSelectedLocation}
                  selectedLocation={locationObject}
                  history={history}
                  handleSubmit={handleSubmit}
                  handleCancel={handleCancel}
                  handlePermissions={handlePermissions}
                  isCreatePage={isCreatePage}
                  controllerData={controllerData}
                  setUpdateUrl={setUpdateUrl}
                  setIsUrlField={setIsUrlField}
                  deviceType={conDeviceType}
                  administrator={administrator}
                  housing={conHousing}
                />
            }
          </Grid>
          <Grid item xs={4} className={classes.accessPointList}>
            {
              !isCreatePage &&
                <Chips
                  data-testid="controllerAccessPoints"
                  color={CHIP_COLOR.PLAIN}
                  icon={<MeetingRoomOutlinedIcon />}
                  isLoading={isLoading}
                  header={t(`controller-page.accessPointsAndReaders`)}
                  data={accessPoints}
                  type={CONTROLLERS_MODULE}
                  floatingTitle={'Readers'}
                  viewAll={true}
                />
            }
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default Controller;