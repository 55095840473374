import React from 'react';
import { Typography } from '@material-ui/core';
import { version, buildNumber } from '../../../package.json';
import useStyles from './styles';

const Version = () => {
	const classes = useStyles();
	
	return (
    <Typography variant="body2" className={classes.version}>
      {version}.{buildNumber}
    </Typography>
	)
}

export default Version;