import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    alignContent   : 'center',
    alignItems     : 'center',
    display        : 'flex',
    flexDirection  : 'column',
    justifyContent : 'center',
  },
  emptyTitle: {
    color       : theme.palette.text.secondary,
    fontWeight  : 'bold'
  }
}))

export default useStyles;