import { Container } from '@material-ui/core';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import DeleteModal from '../../components/delete-modal';
import Table, { createColumn } from '../../components/table';
import Title from '../../components/title';
import { LocationContext } from '../../context/locationContext';
import api from '../../service/api';
import { multipleRequest, request } from '../../service/requests';
import { ALL, API_REQUEST_ERROR_MESSAGE, ASCENDING, LOCATION, DELETE, GET, HOLIDAYS, HOLIDAYS_MODULE, PROJECTION, UNREFERENCED, HOLIDAY } from '../../utility/constants';
import { formatAssociated, parseIntegerParam, updateURLParams } from '../../utility/helper';
import useStyles from './styles';

export const columns = [
  createColumn("name", "holidayCalendarName", true, "string", false, true),
  createColumn("holidayId", "Holiday Id", false, "string", true),
  createColumn("holidayCount", "holidayCount", false, "string"),
  createColumn("location", "location", true, "string", false, false),
];

const Holidays = (props) => {
  const history              = useHistory();
  const { t }                = useTranslation();

  const { search, pathname }               = history.location;
  const { showToaster, handlePermissions } = props;

  const classes    = useStyles();
  const params     = new URLSearchParams(search);
  const query      = params.get('keyword') ? params.get('keyword') : '';
  const pageSize   = params.get('size') ? parseIntegerParam(params.get('size'), 10) : 10;
  const pageNumber = params.get('page') ? parseIntegerParam(params.get('page'), 1) : 1;
  const pageType   = params.get('holidays') ? params.get('holidays') : ALL.toLowerCase();
  const totalSize  = pageSize > 100 ? 100 : pageSize;

  const { state : locationState }                       = useContext(LocationContext);
  const { selectedLocationIds }                         = locationState;
  const [holidays, setHolidays]                         = useState([]);
  const [forDelete, setForDelete]                       = useState([]);
  const [forDeleteName, setForDeleteName]               = useState([]);
  const [isLoading, setIsLoading]                       = useState(false);
  const [keyword, setKeyword]                           = useState(query);
  const [order, setOrder]                               = useState(ASCENDING);
  const [orderBy, setOrderBy]                           = useState(columns[0].id);
  const [page, setPage]                                 = useState(pageNumber === 0 ? 1 : pageNumber);
  const [remove, setRemove]                             = useState(false);
  const [rowsPerPage, setRowsPerPage]                   = useState(totalSize);
  const [showModal, setShowModal]                       = useState(false);
  const [totalHolidays, setTotalHolidays]               = useState(0);
  const [type , setType]                                = useState('single');
  const [assocValues, setAssocValues]                   = useState('');
  const [title, setTitle]                               = useState('');
  const [listType, setListType]                         = useState(pageType);

  const urlParams = useMemo(() => ({
    keywordParam :  keyword ? `&keyword=${keyword}` : '',
    entityParam  : `holidays=${listType}`,
    pageParam    : `&page=${page}`,
    sizeParam    : `&size=${rowsPerPage}`,
    history      : history,
    pathname     : pathname
  }), [keyword, listType, page, rowsPerPage, history, pathname]);

  useEffect(() => {
    let delayDebounce;

    if (keyword) {
      remove ? setIsLoading(false) : setIsLoading(true);
      delayDebounce = setTimeout(() => {
        updateURLParams(urlParams);
        getData();
      }, 1000);
    } else {
      updateURLParams(urlParams);
      getData();
    }

    return () => {
      delayDebounce && clearTimeout(delayDebounce);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, order, orderBy, keyword, listType, selectedLocationIds]);

  useEffect(() => {
    if (remove) {
      getHolidays();
    }
    setRemove(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remove])

  const getData = () => {
    if (listType === UNREFERENCED) {
      setTitle('unreferencedHolidays');
    } else if (listType === ALL.toLowerCase()){
      setTitle(HOLIDAYS);
      getHolidays();
    } else {
      setListType(ALL.toLowerCase());
    }
  }

  const getUrl = () => {
    if (orderBy === LOCATION) {
      return (order === ASCENDING) ? api.HOLIDAYS_SEARCH_ORDER_BY_LOCATION_NAME_ASC : api.HOLIDAYS_SEARCH_ORDER_BY_LOCATION_NAME_DESC
    } else {
      return (keyword === '') ? api.HOLIDAYS : api.HOLIDAYS_BY_NAME
    }
  }

  const getFormattedHoliday = (item) => {
    const { name, holidayId, holidayItems, location } = item;

    return {
      name          : name,
      holidayId     : holidayId,
      holidayCount  : holidayItems.length,
      location      : location ? location.name : '-'
    }
  }

  const getFormattedHolidays = (items) => {
    return items.map(item => {
      return getFormattedHoliday(item);
    });
  }

  const setHolidaysResponse = (response) => {
    const { data } = response;
    const { number, size, totalElements, totalPages} = data.page;
    
    const totalNumber = number > totalPages ? totalPages : number;
    const newHoliday = getFormattedHolidays(data._embedded.holidays);

    if (newHoliday.length || number === 0) {
      setPage(totalNumber + 1);
      setHolidays(newHoliday);
    } else {
      setPage(totalNumber);
    }

    setRowsPerPage(size);
    setTotalHolidays(totalElements);
  }

  const getHolidays = async () => {
    remove ? setIsLoading(false) : setIsLoading(true);

    try {
      const response = await request({
        url     : getUrl(),
        method  : GET,
        params  : {
          name       : keyword,
          size       : rowsPerPage,
          page       : page - 1,
          sort       : `${orderBy},${order}`,
          projection : PROJECTION.HOLIDAYS
        }
      });

      setHolidaysResponse(response);
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  const handleSearch = (value) => {
    setPage(1);
    setKeyword(value);
  }

  const handleSort = (newOrderBy, newOrder) => {
    setPage(1);
    setOrderBy(newOrderBy);
    setOrder(newOrder);
  }

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setPage(1);
    setRowsPerPage(newRowsPerPage);
  }

  const handleChangePage = (newPage) => {
    if (isLoading) {
      return;
    }

    setPage(newPage + 1);
  }

  const handleView = (value) => {
    const { holidayId } = value;

    history.push(`/holidays/view/${holidayId}`);
  }

  const handleCreate = () => {
    history.push("/holidays/create");
  }

  const handleUpdate = (value) => {
    const { holidayId } = value;

    history.push(`/holidays/update/${holidayId}`);
  }

  const handleDelete = async (value) => {
    const response = await request({
      url     : `${api.HOLIDAYS}/${value.holidayId}/profiles`,
      method  : GET,
    });
    const { data } = response;

    if (data._embedded.profiles.length > 0 ) {
      setAssocValues(formatAssociated(data._embedded.profiles.map(profile => { return (` ${profile.name}`)}), t));
      handleOpenDeleteModal(value, value.name, 'singleWithAssociated');
    } else {
      handleOpenDeleteModal(value, value.name, 'single');
    }
  }

  const singleDelete = async (value) => {
    const { holidayId } = value;
    try {
      await request({
        url     : `${api.HOLIDAYS}/${holidayId}`,
        method  : DELETE
      });
  
      setIsLoading(false);
      setRemove(true);
      showToaster(t('success'), `${value.name} ${t('hasBeenDeleted')}`, 'success');
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setRemove(true);
    }
  }

  const handleMultipleDelete = async (values) => {
    const responses = await multipleRequest(
      values.map(holiday => request({
        url     : `${api.HOLIDAYS}/${holiday.holidayId}/profiles`,
        method  : GET
      }))
    )
    const profiles = responses.map(response => response.data._embedded);
    const hasAssoc = profiles.filter(profile => profile.profiles.length > 0);
    
    if (hasAssoc.length > 0) {
      setAssocValues(formatAssociated(
        [...new Set(hasAssoc.map(value =>
          value.profiles.map(profiles => {
            return (` ${profiles.name}`)
          })).flat())
        ], t
      ));
      
      if (values.length === 1) {
        handleOpenDeleteModal(values[0], values[0].name, 'singleWithAssociated');
      } else {
        handleOpenDeleteModal(values, '', 'multipleWithAssociated');
      }
    } else if (values.length === 1) {
      handleOpenDeleteModal(values[0], values[0].name, 'single');
    } else {
      handleOpenDeleteModal(values, '', 'multiple');
    }
  }

  const multipleDelete = async (values) => {
    const ids      = values.map(value => value.holidayId);
    const names    = values.map(value => value.name);
    const lastName = names.pop();

    try {
      await multipleRequest(
        ids.map(id =>
          request({
            url     : `${api.HOLIDAYS}/${id}`,
            method  : DELETE
          })
        )
      );

      setIsLoading(false);
      setRemove(true);
      showToaster(t(`success`), `${names.join(', ')}, ${t('and')} ${lastName} ${t('hasBeenDeleted')}`, 'success');
    } catch {
      setRemove(true);
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }
  }

  const handleOpenDeleteModal = (value, name, paramType) => {
    setForDelete(value);
    setForDeleteName(name);
    setType(paramType)
    setShowModal(true);
  }
  
  const handleCloseDeleteModal = () => {
    setShowModal(false);
  }

  const handleType = () => {
    setListType(ALL.toLowerCase());
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Title title={t(title)} listType={listType}/>
      <DeleteModal
        assocValues={assocValues}
        forDeleteName={forDeleteName}
        initialValues={forDelete}
        module={HOLIDAYS_MODULE}
        multipleDelete={multipleDelete}
        onClose={handleCloseDeleteModal}
        open={showModal}
        showToaster={showToaster}
        singleDelete={singleDelete}
        type={type}
      />
      <Table
        columns={columns}
        data={holidays}
        isLoading={isLoading}
        keyword={keyword}
        label={HOLIDAY}
        module={HOLIDAYS_MODULE}
        onChangePage={handleChangePage}
        onClearSearch={() => handleSearch('')}
        onCreate={handleCreate}
        onDelete={handleDelete}
        onMultipleDelete={handleMultipleDelete}
        onRowsPerPageChange={handleRowsPerPageChange}
        onSearch={handleSearch}
        onSort={handleSort}
        onUpdate={handleUpdate}
        onView={handleView}
        orderBy={orderBy}
        order={order}
        page={page}
        rowsPerPage={rowsPerPage}
        totalItems={totalHolidays}
        viewKey={'name'}
        listType={listType}
        onViewAll={handleType}
        handlePermissions={handlePermissions}
      />
    </Container>
  );
}

export default Holidays;