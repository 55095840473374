export const SET_PERSON             = 'SET_PERSON';
export const SET_SELECTED_ROLES     = 'SET_SELECTED_ROLES';
export const SET_SELECT_CREDENTIALS = 'SET_SELECT_CREDENTIALS';
export const SET_NEW_CREDENTIALS    = 'SET_NEW_CREDENTIALS';
export const CLEAR_REDUCER          = 'CLEAR_REDUCER';
export const CLEAR_CREDENTIALS      = 'CLEAR_CREDENTIALS';

export const userInitialState = {
  user              : '',
  selectedRoles       : [],
  selectedCredentials : [],
  newCredentials      : [],
}

export const WizardPersonReducer = (state, action) => {
  switch (action.type) {
    case SET_PERSON:
      const { personalNumber, validFrom, validUntil, firstName, lastName, location } = action.payload
      return {
        ...state,
        user: {
          personalNumber  : personalNumber,
          firstName       : firstName,
          lastName        : lastName,
          validFrom       : validFrom,
          validUntil      : validUntil,
          location        : {
            name : location.name,
            id   : location.id
          }
        }
      }
    case SET_SELECTED_ROLES:
      return {
        ...state,
        selectedRoles: action.payload
      }
    case SET_SELECT_CREDENTIALS:
      return {
        ...state,
        selectedCredentials: action.payload
      }
    case SET_NEW_CREDENTIALS:
      return {
        ...state,
        newCredentials      : action.payload.newCredentials,
        selectedCredentials : action.payload.selectedCredential,
        credentials         : action.payload.credentials
      }
    case CLEAR_CREDENTIALS:
      return {
        ...state,
        selectedRoles       : [],
        selectedCredentials : [],
        newCredentials      : [],
      }
    case CLEAR_REDUCER:
      return {
        ...userInitialState
      }
    default:
      return state
  }
}