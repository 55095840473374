import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  areaItemcontainer: {
    alignItems    : 'center',
    display       : 'flex',
    padding       : 0,
    paddingBottom : theme.spacing(1),
    paddingTop    : theme.spacing(1),
    width         : '100%',
    '&:hover': {
      backgroundColor : colors.PRIMARY_LIGHT,
      boxShadow       : '0 0 0 100vmax ' + colors.PRIMARY_LIGHT,
      clipPath        : 'inset(0 -100vmax)'
    },
  },
  selectedAreaContainer: {
    alignItems      : 'center',
    backgroundColor : colors.BLUE,
    boxShadow       : '0 0 0 100vmax ' + colors.BLUE,
    clipPath        : 'inset(0 -100vmax)',
    display         : 'flex',
    padding         : 0,
    paddingBottom   : theme.spacing(1),
    paddingTop      : theme.spacing(1),
    width           : '100%',
  },
  subContainer: {
    paddingLeft: theme.spacing(3),
    '&:hover': {
      backgroundColor : 'transparent !important',
      boxShadow       : 'none !important',
      clipPath        : 'none !important'
    },
    '& .MuiAccordion-root': {
      width : '100%',
      margin: theme.spacing(0)
    }
  },
  areaName: { 
    alignItems: 'center',
    width     : '100%',
    '& .MuiLink-root:hover': {
      color: colors.PRIMARY,
    }
  },
  icon: {
    marginLeft : theme.spacing(2),
    marginRight: theme.spacing(1),
    '& svg': {
      color: colors.PRIMARY
    }
  },
  container: {
    width : '100%',
    '& .MuiAccordion-root': {
      boxShadow: 'none !important'
    },
    '& .MuiAccordionDetails-root': {
      flexDirection: 'column',
      padding      : theme.spacing(0)
    },
    '& .MuiAccordionSummary-root': {
      padding  : theme.spacing(0),
      minHeight: 0
    },
    '& .MuiAccordionSummary-content': {
      margin: theme.spacing(0),
    },
    '& .MuiAccordion-root:before':{
      content: 'none'
    },
    '&.MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 0
    },
    '& .MuiAccordion-root.Mui-expanded': {
      margin: 0
    }
  },
  locationItemContainer: {
    alignItems    : 'center',
    padding       : theme.spacing(0),
    paddingBottom : theme.spacing(1),
    paddingLeft   : theme.spacing(2),
    paddingTop    : theme.spacing(1),
    width         : '100%',
    '& .MuiBox-root': {
      marginLeft : theme.spacing(0),
    },
    '&:hover': {
      backgroundColor : colors.PRIMARY_LIGHT,
      boxShadow       : '0 0 0 100vmax ' + colors.PRIMARY_LIGHT,
      clipPath        : 'inset(0 -100vmax)'
    },
  },
  selectedLocationContainer: {
    alignItems      : 'center',
    backgroundColor : colors.BLUE,
    boxShadow       : '0 0 0 100vmax ' + colors.BLUE,
    clipPath        : 'inset(0 -100vmax)',
    padding         : theme.spacing(0),
    paddingBottom   : theme.spacing(1),
    paddingLeft     : theme.spacing(2),
    paddingTop      : theme.spacing(1),
    width         : '100%',
    '& .MuiBox-root': {
      marginLeft : theme.spacing(0),
    },
  },
  locationName: { 
    alignItems: 'center',
    cursor    : 'pointer',
    display   : 'flex',
    width     : '100%',
    '& .MuiLink-root:hover': {
      color: colors.PRIMARY,
    }
  },
  highlightedLabel: {
    fontWeight: 'bold'
  },
}));