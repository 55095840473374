import { assignLocation } from "../utility/location";
import { GET, PATCH, POST, PROJECTION } from "../utility/constants";
import api from "./api";
import { multipleRequest, request } from "./requests";

export const getCredentialsApi = async (params, url) => {
  const { keyword, rowsPerPage, page, orderBy, order,} = params;
  const response = await request({
    url     : url,
    method  : GET,
    params  : {
      credentialNumber  : keyword,
      size              : rowsPerPage,
      page              : page - 1,
      sort              : `${orderBy},${order}`,
      projection        : PROJECTION.CREDENTIALS
    }
  });

  return response.data;
}

export const deactivateCredentials = async (urls, user) => {
  let requests = [];
  requests.push(...urls.map(url =>
    request({
      url    : url,
      method : PATCH,
      data   : {
        active : 0,
        userId : user.id
      }
    }))
  );

  await multipleRequest(requests);
}

export const createCredentials = async (credentialData, user, newCredentials) => {
  const responses = await multipleRequest(
    credentialData.map(credential =>
      request({
        url   : api.CREDENTIALS,
        method: POST,
        data  : {
          ...credential,
          userId: user.id
        }
      })
    )
  );

  const credentialUrls = responses.map((response) => {
    return response.data._links.self.href;
  });

  for (const credentialUrl of credentialUrls) {
    await assignLocation(credentialUrl, newCredentials[0].location.id);
  }
}

export const updateCredentials = async (selectedCredentials, user) => {
  const selectedCredentialValues = selectedCredentials.filter(credential => credential.id >= 1);

  multipleRequest(
    selectedCredentialValues.map(credential =>
      request({
        url   : `${api.CREDENTIALS}/${credential.id}`,
        method: PATCH,
        data  : {
          active    : 1,
          userId    : user.id,
          validFrom : user.validFrom,
          validUntil: user.validUntil
        }
      })
    )
  )
}

export const getCredentialByCredentialNumber = async (credentialUpdate, projection) => {
  const responses = await multipleRequest(
    credentialUpdate.map(credentialNumber =>
      request({
        url   : api.CREDENTIALS_FIND_BY_CREDENTIAL_NUMBER,
        method: GET,
        params: {
          credentialNumber: credentialNumber,
          projection: projection
        }
      })
    )
  );
  return responses;
}