import colors from '../../../theme/colors';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    paddingBottom : theme.spacing(2),
    paddingTop    : theme.spacing(2),
  },
  paper: {
    paddingLeft   : theme.spacing(2),
    paddingRight  : theme.spacing(2),
    paddingBottom : theme.spacing(2),
    display         : 'flex',
    justifyContent  : 'space-between',
  },
  form: {
    padding       : theme.spacing(1),
    paddingLeft   : theme.spacing(2),
    paddingRight  : theme.spacing(2),
    paddingTop    : theme.spacing(2)
  },
  action: {
    backgroundColor : theme.palette.grey[100],
    display         : 'flex',
    justifyContent  : 'flex-end',
    padding         : theme.spacing(2),
    
    '& .MuiButton-root:first-of-type': {
      marginRight : theme.spacing(2)
    }
  },
  button: {
    width     : theme.spacing(15),
  },
  filterModal: {
    alignItems      : 'center',
    display         : 'flex',
    justifyContent  : 'center'
  },
  modalContent: {
    backgroundColor : theme.palette.background.paper,
    boxShadow       : theme.shadows[5],
    padding         : theme.spacing(0),
    width           : '50%'
  },
  titleFilterModal:{
    alignItems  : 'center',
    display     : 'flex',
    fontSize    : '20px'
  },
  hrDividerProfile:{
    border        : 0,
    borderBottom  : '1px solid' + colors.PRIMARY,
    height        : theme.spacing(-1)
  },
  fullWidth:{
    width : '100%',
  },
  timePicker: {
    paddingLeft        : theme.spacing(1)
  },
  calendarTextField: {
		'& .MuiOutlinedInput-adornedEnd':{
			paddingRight : '0'
		}
	},
}));