import React from 'react';
import { Grid, Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import useStyles from './styles';

const CsvResultSkeleton = (props) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item md={12} sm={12}>
        <Box className={classes.csvItems}>
          <Skeleton height={24} className={classes.input} />
          <Skeleton height={24} className={classes.input} />
          <Skeleton height={24} className={classes.input} />
        </Box>
      </Grid>
    </Grid>
  )
}

const CsvActionSkeleton = (props) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.action}>
      <Grid item xs={12}>
        <Box className={classes.dialogActions}>
          <Skeleton height={64} className={classes.actionButton}/>
          <Skeleton height={64} className={classes.actionButton}/>
        </Box>
      </Grid>
    </Grid>
  )
}

export { CsvResultSkeleton, CsvActionSkeleton };