import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    paddingTop   : theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '& .MuiFormControl-root': {
      margin: theme.spacing(0.5),
      width: '90%'
    }
  }
}));