import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { EMPTY_TABLE_MESSAGE } from "../../utility/constants";
import useStyles from "../enhanced-empty/styles";

const EnhancedEmptyTable = (props) => {
  const { module } = props;

  const translationKey = EMPTY_TABLE_MESSAGE[module] ?? 'no-result.title';

  const { t }   = useTranslation();
  const classes = useStyles();
  return (
    <Box className={classes.emptyTableContainer}>
        <Typography variant="h5" gutterBottom className={classes.emptyTableTitle}>
          {t(translationKey)}
        </Typography>
    </Box>
  );
}

export default EnhancedEmptyTable;