import { DELETE, GET, LOCATION_PROJECTIONS, PATCH, POST } from "../utility/constants"
import api from "./api"
import { request } from "./requests"

export const getAreasAndLocations = async (areaId, projection) => {
  const response = await request({
    url: `${api.AREAS}/${areaId}`,
    method: GET,
    params: {
      projection: projection
    }
  });

  return response.data;
}

export const getLocationCount = async () => {
  const response = await request({
    url: `${api.LOCATIONS_COUNT}`,
    method: GET,
  });
  
  return response.data;
}

export const getParentAreasAndLocations = async () => {
  const response = await request({
    url: `${api.AREAS}/search/areas-and-locations`,
    method: GET,
    params: {
      projection: LOCATION_PROJECTIONS.PARENT_AREA
    }
  });

  return response.data;
}

export const getLocation = async (locationId) => {
  const response = await request({
    url: `${api.LOCATIONS}/${locationId}`,
    method: GET,
    params: {
      projection: LOCATION_PROJECTIONS.NESTED_PARENT_LOCATION
    }
  });

  return response.data;
} 

export const createLocation = async (name, areaId, data) => {
  const response = await request({
    url: api.LOCATIONS,
    method: POST,
    data: {
      name: name,
      areaId: areaId
    }
  });

  const { locationId } = response.data;
  const responseValue = {
    locationId: locationId,
    name      : name
  }

  const locationsSubAreas = [...data, responseValue];
  locationsSubAreas.sort((a, b) => a.name.localeCompare(b.name));

  return locationsSubAreas;
}

export const createArea = async (name, areaParentId, data) => {
  const response = await request({
    url: api.AREAS,
    method: POST,
    data: {
      name        : name,
      areaParentId: areaParentId,
    }
  });

  const { areaId, level } = response.data;
  const responseValue = {
    areaId      : areaId,
    name        : name,
    areaParentId: areaParentId,
    level       : level,
    data        : []
  }

  const locationsSubAreas = [...data, responseValue];
  locationsSubAreas.sort((a, b) => a.name.localeCompare(b.name));
  return locationsSubAreas;
}

export const updateLocation = async (name, locationId) => {
  const response = await request({
    url:`${api.LOCATIONS}/${locationId}`,
    method: PATCH,
    data: {
      name: name
    }
  });

  return response.data;
}

export const updateArea = async (name, areaId) => {
  const response = await request({
    url:`${api.AREAS}/${areaId}`,
    method: PATCH,
    data: {
      name: name
    }
  });

  return response.data;
}

export const deleteArea = async (areaId) => {
  await request({
    url:`${api.AREAS}/${areaId}`,
    method: DELETE
  });
}

export const deleteLocation = async (locationId) => {
  await request({
    url:`${api.LOCATIONS}/${locationId}`,
    method: DELETE
  });
}