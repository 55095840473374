import { faEdit, faPowerOff, faTrash, faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, ClickAwayListener, Dialog, Grid, IconButton, List, ListItem, ListItemText, Paper, Popper, TextField, Tooltip, Typography } from '@material-ui/core';
import { FiberManualRecordRounded } from '@material-ui/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { useHistory } from 'react-router';
import IconWithHover from '../../../components/icon-with-hover';
import { deleteController, rebootController, rebuildController, updateControllerSoftware } from '../../../service/controllersApi';
import colors from '../../../theme/colors';
import { API_REQUEST_ERROR_MESSAGE, CONTROLLERS_MODULE, DELETE, DEVICE_ADMIN, OFFLINE, OFFLINE_STATUS, PATCH, REQUIRED_FIELD, SYSTEM_ADMIN } from '../../../utility/constants';
import images from "../../../utility/images";
import useStyles from './styles';

export const ONBOARD = 'Onboard';
export const REBOOT  = 'Reboot';
export const REBUILD = 'Rebuild';
const UPDATE  = 'Update';
const REMOVE  = 'Remove';

const ModalContent = (props) => {
  const { formik, option, label, initialValues, handleClose, handleSubmit, setUpdateUrl } = props;
  
  const classes  = useStyles();
  const { t }    = useTranslation();

  const handleChange = (setter, formik) => {
    return (setter, formik);
  }

  const getImage = () => {
    switch (option) {
      case REBOOT:
        return images.REBOOT_CONTROLLER_SVG;
      case UPDATE:
        return images.UPDATE_CONTROLLER_SVG;
      case REBUILD:
        return images.REBUILD_CONTROLLER_SVG;
      case REMOVE:
        return images.DELETE_MODAL;
      default:
        break;
    }
  }

  const getTitle = () => {
    switch (option) {
      case REBOOT:
        return t('controller-page.warning');
      case REMOVE:
        return t('controller-page.removeControllerTitle');
      default:
        return t(`controller-page.${option.toLowerCase()}Title`, { controllerName : label })
    }
  }

  return (
    <>
      <Paper data-testid="controllerModalBodyContainer" className={classes.deleteModal}>
        <Box className={classes.dialogImage}>
          <img src={getImage()} alt={`${option.toLowerCase()}-controller-img`} />
        </Box>
        <Box className={classes.dialogTitle}>
          <> { getTitle() } </>
        </Box>
        <Box className={classes.dialogContent}>
          {
            option === REBOOT &&
              <Trans i18nKey={'controller-page.rebootWarning'}/>
          }
          {
            option === REBUILD &&
              <Trans
                i18nKey={'controller-page.rebuildWarning'}
                values={{ controllerName: label }}
              />
          }
          {
            option === UPDATE &&
              <>
                <Trans
                  i18nKey={'controller-page.updateWarning'}
                  values={{ controllerName: initialValues.name }}
                />
                <TextField
                  className={classes.url}
                  data-testid="controllerUpdateModalUrl"
                  label={t('controller-page.urlOfUpdatePackage')}
                  name="updateUrl"
                  fullWidth
                  value={formik.values.updateUrl}
                  onChange={e => handleChange(setUpdateUrl(e.target.value), formik.handleChange(e))}
                  error={Boolean(formik.errors.updateUrl)}
                  helperText={t(formik.errors.updateUrl)}
                />
              </>
          }
          {
            option === REMOVE &&
              <Box className={classes.removeContainer}>
                <Trans
                  i18nKey={'controller-page.removeWarning'}
                  values={{ controllerName: initialValues.name }}
                />
                <List>
                  <ListItem className={classes.flexStart}>
                    <FiberManualRecordRounded className={`${classes.bulletSize} ${classes.marginBullet}`}/>
                    <ListItemText className={classes.bulletText}>
                      <Typography variant="subtitle2" className={classes.paragraph}>
                        { t('controller-page.removeAllAps') } 
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem className={classes.flexStart}>
                    <FiberManualRecordRounded className={`${classes.bulletSize} ${classes.marginBullet}`}/>
                    <ListItemText className={classes.bulletText}>
                      <Typography variant="subtitle2" className={classes.paragraph}>
                        { t('controller-page.deleteAllPermissionData') }
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem className={classes.flexStart}>
                    <FiberManualRecordRounded className={`${classes.bulletSize} ${classes.marginBullet}`}/>
                    <ListItemText className={classes.bulletText}>
                      <Typography variant="subtitle2" className={classes.paragraph}>
                        { t('controller-page.removeController') }
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </Box>
          }
        </Box>
      </Paper>
      <Box data-testid="controllerModalFooterContainer" className={classes.dialogActions}>
        <Button data-testid="controllerModalSubmitButton" color="primary" variant="contained" className={classes.dialogSubmitButton} onClick={() => handleSubmit(formik)}>
          { t(`controller-page.yes${option}`) }
        </Button>
        <Button data-testid="controllerModalCancelButton" color="primary"  variant="outlined" className={classes.dialogCancelButton} onClick={() => handleClose(formik)}>
          { t(`controller-page.no${option}`) }
        </Button>
      </Box>
    </>
  )
}

const ControllerModal = (props) => {
  const { formik, controllerId, administrator, initialValues, label, showToaster, showLoading, handleOpenModal, handleCloseModal, showModal, option, handlePermissions, controllerData, isControllerOnboarded, setUpdateUrl, setIsUrlField } = props;
  const history = useHistory();
  
  const classes  = useStyles();
  const { t }    = useTranslation();

  const [ anchorEl, setAnchorEl ]               = useState(null);
  const [ openPopper, setOpenPopper ]           = useState(null);
  const [ isPasswordShown, setIsPasswordShown ] = useState(false);
  const [ isClicked, setIsClicked ]             = useState(false);
  const [ isHovered, setIsHovered ]             = useState(false);
  
  const { roles } = administrator;

  const hasControllerCredentialPermissions = roles.includes(SYSTEM_ADMIN) || roles.includes(DEVICE_ADMIN);  

  const hideControllerButtons = (initialValues.status === OFFLINE_STATUS || initialValues.status === OFFLINE) ||
    !handlePermissions(CONTROLLERS_MODULE, PATCH);

  const handleRebuild = async (formik) => {
    const { setSubmitting } = formik;

    try {
      await rebuildController(controllerId);
      showToaster(t('success'), t('controller-page.rebuildSuccess', { controllerName : initialValues.name }), 'success');
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      showLoading(false);
      setSubmitting(false);
    }
  }

  const handleReboot = async (formik) => {
    const { setSubmitting } = formik;

    try {
      await rebootController(controllerId);
      showToaster(t('success'), t('controller-page.rebootSuccess', { controllerName : initialValues.name }), 'success');
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      showLoading(false);
      setSubmitting(false);
    }
  }

  const handleUpdate = async (formik, url) => {
    const { setSubmitting } = formik;
    showLoading(true);
    
    try {
      await updateControllerSoftware(controllerId, url);
      showToaster(t('success'), t('controller-page.updateSuccess', { controllerName : initialValues.name }), 'success');
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setUpdateUrl('');
      showLoading(false);
      setSubmitting(false);
    }
  }
  
  const handleRemove = async (formik) => {
    const { setSubmitting } = formik;
    showLoading(true);

    try {
      await deleteController(controllerId);

      history.push('/controllers');
      showToaster(t('success'), t('controller-page.removeSuccess', { controllerName : initialValues.name }), 'success');
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      showLoading(false);
      setSubmitting(false);
    }
  }

  const handleClose = (formik) => {
    const { setErrors } = formik;
    
    handleCloseModal();
    setUpdateUrl('');
    setErrors({
      updateUrl: ''
    });
  }

  const checkURL = (userURL = 'a') => {
    if (!userURL) {
      return true;
    }
    
    try {
      (new URL(userURL));
      return true;
    } catch (e) {
      return false;
    }
  }

  const getMethod = (values, formik) => {
    handleCloseModal();
    if (option === REBOOT) {
      return handleReboot(formik);
    } else if (option === REBUILD) {
      return handleRebuild(formik);
    } else if (option === UPDATE) {
      return handleUpdate(formik, values.updateUrl);
    } else if (option === REMOVE) {
      return handleRemove(formik);
    }
  }

  const handleSubmit = (formik) => {
    const { setErrors, setSubmitting } = formik;
    const optionList = [REBOOT, REBUILD, REMOVE]
    const isValid = optionList.some((val) => val === option) ? true : checkURL(formik.values.updateUrl);

    if (option === UPDATE && !formik.values.updateUrl) {
      setErrors({
        updateUrl: t(`controller-page.${REQUIRED_FIELD}`)
      });
      return;
    }

    if (isValid === true) {
      getMethod(formik.values, formik);
    } else {
      setSubmitting(false);
      setErrors({
        updateUrl: t('controller-page.enterValidUrl')
      });
    }
  }

  const handleSchema = useCallback(() => {
    if (option === UPDATE) {
      setIsUrlField(true);
    } else {
      setIsUrlField(false);
    }
  }, [option, setIsUrlField]);

  const handleCredentialClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const togglePasswordVisibility = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  const handleCopyClick = async () => {
    setIsClicked(true);
    navigator.clipboard.writeText(controllerData);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
    setIsPasswordShown(false);
  }

  useEffect(() => {
    setOpenPopper(Boolean(anchorEl));
  }, [anchorEl]);

  useEffect(() => {
    if (!isHovered) {
      setIsClicked(false);
    }
    setTimeout(() => {
      if (isClicked) {
        setIsClicked(false);
      }
    }, 2000);
  }, [isClicked, isHovered])

  useEffect(() => {
    handleSchema();
  }, [handleSchema])

  return (
    <>
      <Grid container>
        {
          handlePermissions(CONTROLLERS_MODULE, DELETE) &&
            <Grid item>
              <Tooltip title={t('controller-page.remove')}>
                <IconButton data-testid="controllerRemoveButton" size="small" className={classes.iconBox} onClick={() => handleOpenModal(REMOVE)}>
                  <FontAwesomeIcon aria-label="remove" icon={faTrash} className={classes.iconDesign} />
                </IconButton>
              </Tooltip>
            </Grid>
        }
        {
          hideControllerButtons ?
            null
          :
          <>
            <Grid item>
              <Tooltip title={t('controller-page.reboot')}>
                <IconButton disabled={!isControllerOnboarded} data-testid="controllerRebootButton" size="small" className={isControllerOnboarded ? classes.iconBox : classes.iconBoxDisabled} onClick={() => handleOpenModal(REBOOT)}>
                  <FontAwesomeIcon icon={faPowerOff} className={isControllerOnboarded ? classes.iconDesign : classes.iconDesignDisabled} title='Power' />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title={t('controller-page.rebuild')}>
                <IconButton disabled={!isControllerOnboarded} data-testid="controllerRebuildButton" size="small" className={isControllerOnboarded ? classes.iconBox : classes.iconBoxDisabled} onClick={() => handleOpenModal(REBUILD)}>
                  <FontAwesomeIcon icon={faWrench} className={isControllerOnboarded ? classes.iconDesign : classes.iconDesignDisabled} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title={t('controller-page.update')}>
                <IconButton disabled={!isControllerOnboarded} data-testid="controllerUpdateButton" size="small" className={isControllerOnboarded ? classes.iconBox : classes.iconBoxDisabled} onClick={() => handleOpenModal(UPDATE)}>
                  <FontAwesomeIcon icon={faEdit} className={isControllerOnboarded ? classes.iconDesign : classes.iconDesignDisabled} />
                </IconButton>
              </Tooltip>
            </Grid>
          </>
        }
        {
          hasControllerCredentialPermissions &&
          <>
            <Grid item>
              <Tooltip title={t('controller-page.getCredentials')}>
                <IconButton data-testid="controllerGetCredentialsButton" size="small" className={classes.iconBox} onClick={handleCredentialClick}>
                  <img alt="get-controller-credentials-button" src={images.CONTROLLER_CREDENTIALS_ICON} className={classes.img}/>
                </IconButton>
              </Tooltip>
            </Grid>
            <Popper
              data-testid={'controllerCredentialsPopper'}
              open={openPopper}
              anchorEl={anchorEl}
              placement="bottom-start"
              transition
            >
              <Box className={classes.arrow}/>
              <ClickAwayListener onClickAway={handleClickAway}>
                <Paper className={classes.popper}>
                  <Grid container className={classes.popperHeader}>
                    <Grid item className={classes.popperTitle}>
                      <Trans i18nKey={'controller-page.popperTitle'}/>
                    </Grid>
                    <Grid item className={classes.copyButton} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                      <IconWithHover
                        data-testid='controllerCredentialsCopyButton'
                        handleOnClick={handleCopyClick}
                        icon={images.COPY_ICON}
                        hoverIcon={images.COPY_ICON_HOVER}
                        altText={isClicked && isHovered ? t('controller-page.copied') : t('controller-page.copy')}
                        disabled={false}
                      />
                    </Grid>
                  </Grid>
                  <Grid container  className={classes.popperContent}>
                    <Grid item className={`${classes.flexForm} ${classes.qrContainer}`}>
                      <QRCode
                        data-testdata-testid='controllerQrCode'
                        value={controllerData}
                        fgColor={colors.PRIMARY}
                        size={150}
                        className={classes.qr}
                      />
                    </Grid>
                    <Grid item className={`${classes.flexForm} ${classes.space}`}>
                      <Grid container className={classes.flexRow}>
                        <Grid item>
                          <Trans
                            i18nKey={'controller-page.url'}
                            components={ <span data-testdata-testid='controllerUrl' className={classes.label}></span> }
                          />
                        </Grid>
                        <Grid item className={classes.wordBreak}>
                          <span>{ initialValues.url }</span>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item className={`${classes.flexForm} ${classes.space}`}>
                      <Trans
                        i18nKey={'controller-page.port'}
                        values={{ port: initialValues.port }}
                        components={ <span data-testdata-testid='controllerPort' className={classes.label}></span> }
                      />
                    </Grid>
                    <Grid item className={`${classes.flexForm} ${classes.space}`}>
                      <Trans
                        i18nKey={'controller-page.username'}
                        values={{ username: initialValues.username }}
                        components={ <span data-testdata-testid='controllerUsername' className={classes.label}></span> }
                      />
                    </Grid>
                    <Grid container className={`${classes.flexForm} ${classes.space} ${classes.passwordContainer}`}>
                      <Grid item>
                        <Grid container className={classes.flexRow}>
                          <Grid item>
                            <Trans
                              i18nKey={'controller-page.password'}
                              components={ <span data-testdata-testid='controllerPassword' className={classes.label}></span> }
                            />
                          </Grid>
                          <Grid item className={classes.wordBreak}>
                            <span>{ isPasswordShown ? initialValues.password : '*'.repeat(initialValues.password.length) }</span>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <IconButton
                            data-testdata-testid="controllerPasswordVisibilityIcon"
                            aria-label="toggle password visibility"
                            onClick={togglePasswordVisibility}
                            edge="end"
                            className={classes.iconButton}
                          >
                          {
                            isPasswordShown ?
                              <img src={images.VISIBILITY_ON} className={classes.visibilityIcon} alt="visibility-on"/> 
                            : 
                              <img src={images.VISIBILITY_OFF} className={classes.visibilityIcon} alt="visibility-off"/>
                          }
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </ClickAwayListener>
            </Popper>
          </>
        }
      </Grid>
      <Dialog
        modal="false"
        open={showModal}
        onClose={(_event, reason) => {
          if (reason !== 'backdropClick') {
            handleCloseModal();
          }
        }}
        aria-labelledby={`${option.toLowerCase()}-controller-modal`}
        aria-describedby={`${option.toLowerCase()}-controller-modal`}
      >
        <ModalContent
          formik={formik}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          initialValues={initialValues}
          label={label}
          option={option}
          setUpdateUrl={setUpdateUrl}
        />
      </Dialog>
    </>
  );
}

export {
  ControllerModal
};
