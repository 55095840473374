import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Container, Paper, TextField, Grid, Box, Button, Typography, IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { Formik, Form } from 'formik';
import { GET, PATCH, POST, PUT, API_REQUEST_ERROR_MESSAGE } from '../../utility/constants';
import { groupSchema } from '../../validation/schema'
import { request } from '../../service/requests';
import { Prompt, useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import api from '../../service/api';
import clsx from 'clsx';
import EnhancedSelect from './selection';
import GroupSkeleton from './group-skeleton';
import Title from '../../components/title';
import UnsavedModal from '../../components/unsaved-modal';
import useStyles from './styles';

const Content = (props) => {
  const { t }   = useTranslation();
  const classes = useStyles()
  const { initialValues, handleSubmit, id, disabled, setAccessPointLink, accessPoints, showLoading, handleCancel, setGroupName, setDescription } = props;

  const getButtonLabel = () => {
    return id ? `${t('update')}` : `${t('create')}`;
  }

  const handleChange = (setter, formik) => {
    return(setter, formik);
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={groupSchema}
      onSubmit={handleSubmit}
    >
      {
        formik => (
          <Form>
            {showLoading(formik.isSubmitting)}
            <Paper className={classes.paper} elevation={3}>
              <Grid container spacing={2} className={classes.form}>
                <Grid item xs={12} sm={5} md={4} lg={6}>
                  <TextField
                    inputProps={{
                      readOnly: disabled
                    }}
                    id="groupName"
                    label={`${t('name')}*`}
                    name="groupName"
                    fullWidth
                    value={formik.values.groupName}
                    onChange={e => handleChange(setGroupName(e.target.value), formik.handleChange(e))}
                    error={formik.touched.groupName && Boolean(formik.errors.groupName)}
                    helperText={t(formik.touched.groupName) && t(formik.errors.groupName)}
                  />
                  <EnhancedSelect
                    disabled={disabled}
                    id={id}
                    setAccessPointLink={setAccessPointLink}
                    accessPoints={accessPoints}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    inputProps={{
                      readOnly: disabled
                    }}
                    id="description"
                    label={t('description')}
                    name="description"
                    placeholder={t('noDescription')}
                    fullWidth
                    value={formik.values.description}
                    onChange={e => handleChange(setDescription(e.target.value), formik.handleChange(e))}
                    multiline
                    rows={6}
                  />
                </Grid>

              </Grid>
              <Grid container className={clsx(disabled ? 'hidden' : classes.action)}>
                <Grid item xs={12}>
                  <Button
                    onClick={handleCancel}
                    variant="outlined"
                    color="primary"
                    >
                    {t('cancel')}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    { getButtonLabel() }
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Form>
        )
      }
    </Formik>
  )
}

const Group = (props) => {
  const { showToaster, match, showLoading } = props;
  const path    = match.path;
  const { id }  = match.params;
  const { t }   = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [accessPoints, setAccessPoints] = useState([]);
  const [description, setDescription]   = useState('');
  const [groupName, setGroupName]       = useState('');
  const [isLoading, setIsLoading]       = useState(false);
  const [prevValues, setPrevValues]     = useState([]);
  const [showModal, setShowModal]       = useState(false);
  const [prevAccess, setPrevAccess]     = useState([]);
  const [withChanges, setWithChanges] = useState(false);
  const [toRedirect, setToRedirect]     = useState('');
  
  const initialValues = useMemo(() => {
    return {
      groupName   : groupName,
      description : description,
    }
  }, [groupName, description]);

  useEffect(() => {
    setPrevValues(initialValues);
    if (id) {
      getAccessPoint();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
  }

  const checkAccessPoints = (prev, newAccess) => {
    return prev.map(access => {
      return newAccess.some(selected => selected.name === access.name);
    })
  }

  const handleCancel = () => {
    history.push('/groups');
  }

  const handleModalSubmit = () => {
    setWithChanges(false);
    history.push(toRedirect);
  }

  const handleModalCancel = () => {
    handleChanges();
    setShowModal(false);
  }

  const getGroup = () => {
    request({
        url     : `${api.GROUPS}/${id}`,
        method  : GET,
      })
    .then(response => {
      setGroupName(response.data.name);
      setDescription(response.data.description);
      setPrevValues({
        groupName  : response.data.name,
        description: response.data.description,
      })
    }).catch(error => {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    })
  }

  const getAccessPoint = () => {
    setIsLoading(true);
    request({
      url     : `${api.GROUPS}/${id}/accessPoints`,
      method  : GET,
    }).then(response => {
      const resultName = response.data._embedded.accessPoints.map(access => {
        return {
          name       : `${access.controller[0].deviceId}:${access.name}`,
          link        : access._links.accessPoint.href
        }
      })
      setPrevAccess(resultName)
      setAccessPoints(resultName);
      getGroup();
    }).catch(error => {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }).finally(() => {
      setIsLoading(false);
    });
  }

  const getToasterMessage = () => {
    return id ? t('updated') : t('created');
  }

  const handleSubmit = (values, formik) => {
    const { setErrors, setSubmitting} = formik;

    if (!withChanges) {
      setSubmitting(false);
      setWithChanges(false);
      history.push('/groups');
      return;
    }

    request({
      url     : id ? `${api.GROUPS}/${id}` : api.GROUPS,
      method  : id ? PATCH : POST,
      data    : {
        name        : values.groupName,
        description : values.description
      }
    }).then(response => {
      const newUrl = response.data._links.self.href;
      assignAccessPointToGroup(newUrl, values, formik);
      showToaster(t('success'), `${values.groupName} ${t('hasBeen')} ${getToasterMessage()}`, 'success');
    }).catch(error => {
      if (error?.response?.status === 409) {
        setErrors({
          groupName: t(`groupNameAlreadyExists`)
        });
      } else {
        showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
      }
    }).finally(() => {
      setWithChanges(false);
      setSubmitting(false);
    });
  }

  const assignAccessPointToGroup = (url, values, formik) => {
    const accessPointUrl = accessPoints.map(item => `${item.link}`).join(`\r\n`);
    request({
      url: `${url}/accessPoints`,
      method: PUT,
      headers: {
        'Content-Type': 'text/uri-list'
      },
      data: accessPointUrl
    }).then(() => {
      history.push('/groups');
    }).catch(error => {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }).finally(() => {
      setWithChanges(false);
      formik.setSubmitting(false);
    })
  }

  const handleChanges = useCallback(() => {
    let checkSameValue = [];
    if (prevAccess.length > accessPoints.length) {
      checkSameValue = checkAccessPoints(prevAccess, accessPoints)
    } else {
      checkSameValue = checkAccessPoints(accessPoints, prevAccess);
    }

    if ( prevValues.groupName === initialValues.groupName
    && prevValues.description === initialValues.description
    && checkSameValue.every(access => access) ) {
      setWithChanges(false)
    }else {
      setWithChanges(true)
    }
  }, [prevValues, initialValues, accessPoints, prevAccess]);

  useEffect(() => {
    handleChanges();
  }, [groupName, description, accessPoints, prevValues, handleChanges])

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Prompt
        when={withChanges}
        message={(location, action) => {
          if (action === 'PUSH') {
            setShowModal(true)
          }
          setWithChanges(false);
          setToRedirect(location.pathname);
          return location.pathname === '/' || location.pathname === '/groups/update' || location.pathname === '/groups/create'
        }}
      />
      <UnsavedModal
        open={showModal}
        onClose={handleCloseModal}
        handleModalSubmit={handleModalSubmit}
        handleModalCancel={handleModalCancel}
      />
      <Title title={`Group`} subtitle={`${groupName}`}/>
        <Box>
          <Grid container>
            <Grid item xs={6}>
                <Typography className={'bold'} color="secondary">Details</Typography>
            </Grid>
            <Grid item xs={6} className={clsx(path.includes('view') ? classes.editIcon : 'hidden')}>
              <IconButton size="small" onClick={() => history.push(`../update/${id}`)}>
                <Edit />
              </IconButton>
            </Grid>
          </Grid>
          {
            isLoading ?
              <GroupSkeleton id={id} disabled={path.includes('view')}/>
            :
              <Content
                accessPoints={accessPoints}
                disabled={path.includes('view')}
                handleCancel={handleCancel}
                handleSubmit={handleSubmit}
                id={id}
                initialValues={initialValues}
                setAccessPointLink={setAccessPoints}
                setDescription={setDescription}
                setGroupName={setGroupName}
                showLoading={showLoading}
              />
          }
        </Box>
    </Container>
  );
}

export default Group;