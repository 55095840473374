import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    paddingBottom : theme.spacing(2),
    paddingTop    : theme.spacing(2),
  },
  root: {
    width: '100%',
  },
  paper: {
    marginTop     : theme.spacing(2),
    paddingLeft   : theme.spacing(2),
    paddingRight  : theme.spacing(2),
    paddingTop    : theme.spacing(4),
    position      : 'relative',
    '& .MuiTextField-root': {
      marginTop   : theme.spacing(0)
    },
    '& input#holidayCalendarName.Mui-disabled':{
      color       : "black"
    }
  },
  editIcon: {
    backgroundColor: theme.palette.primary.main,
    borderRadius   : theme.spacing(0.5),
    color          : theme.palette.common.white,
    display        : 'inline',
  },
  form: {
    paddingBottom  : theme.spacing(3),
    paddingLeft    : theme.spacing(1),
    paddingRight   : theme.spacing(1),
    paddingTop     : theme.spacing(1)
  },
  action: {
    backgroundColor: theme.palette.grey[100],
    bottom         : '0',
    left           : '0',
    padding        : theme.spacing(2),
    position       : 'absolute',
    right          : '0',
    textAlign      : 'right',
    width          : '100%',
    '& .MuiButton-root:first-of-type': {
      marginRight  : theme.spacing(2)
    }
  },
  hidden: {
    display        : 'none',
  },
  tab: {
    '& .MuiTab-root'  : {
      backgroundColor     : theme.palette.grey[100],
      borderTopLeftRadius : theme.spacing(0.5),
      borderTopRightRadius: theme.spacing(0.5),
      color               : theme.palette.common.black,
      marginRight         : theme.spacing(0.5),
    },
    '& .Mui-selected' : {
      backgroundColor : theme.palette.primary.main,
      color           : theme.palette.common.white,
      cursor          : 'default',
    },
  },
  tabLabel: {
    fontSize        : '16px',
    textTransform   : 'capitalize',
  },
  tabButton: {
    marginTop       : theme.spacing(4)
  },
  tabContainer: {
    position        : 'absolute',
    top             : '140px',
    width           : '50%',
    zIndex          : '1',
  },
  holidayTable: {
    marginTop       : '40px',
    paddingBottom   : '50px',
    zIndex          : '-1',
  },
  header: {
    justifyContent  : 'space-between',
  },
}));
