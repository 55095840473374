import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// Pages
import AccessPoint from '../pages/access-point';
import AccessPoints from '../pages/access-points';
import Administrator from '../pages/administrator';
import Administrators from '../pages/administrators';
import BackupAndRestore from '../pages/backup-and-restore';
import Controller from '../pages/controller';
import Controllers from '../pages/controllers';
import Credential from '../pages/credential';
import Credentials from '../pages/credentials';
import Dashboard from '../pages/dashboard';
import Event from '../pages/event';
import Events from '../pages/events';
import Group from '../pages/group';
import Groups from '../pages/groups';
import Health from '../pages/health';
import Holiday from '../pages/holiday';
import Holidays from '../pages/holidays';
import Imprint from '../pages/imprint';
import MyAccount from '../pages/my-account';
import PrivacyPolicy from '../pages/privacy-policy';
import Profile from '../pages/profile';
import Profiles from '../pages/profiles';
import Role from '../pages/role';
import Roles from '../pages/roles';
import Schedule from '../pages/schedule';
import Schedules from '../pages/schedules';
import Settings from '../pages/settings';
import SyncingUsers from '../pages/syncing-users';
import SystemRoles from '../pages/system-roles';
import User from '../pages/user';
import Users from '../pages/users';

// Routing
import PrivateRoute from './PrivateRoute';

// Layouts
import ProtectedResources from '../ProtectedResources';
import Websocket from '../Websocket';
import MainLayout from '../components/main-layout';
import Area from '../pages/area';
import Location from '../pages/location';
import Locations from '../pages/locations';
import EntraIdUserGuide from '../pages/settings/entra-id-user-guide';

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/health" component={Health}/>
      <Route exact path="/imprint" component={Imprint} />
      <Route exact path="/data-privacy" component={PrivacyPolicy} />
      <ProtectedResources>
        <Websocket>
          <Redirect from="/" to="/dashboard" />
          <PrivateRoute exact layout={MainLayout} path="/dashboard" component={Dashboard} />
          <PrivateRoute exact layout={MainLayout} path="/myaccount" component={MyAccount} />
          <PrivateRoute exact layout={MainLayout} path="/users/create" component={User} />
          <PrivateRoute exact layout={MainLayout} path="/users/update/:id" component={User} />
          <PrivateRoute exact layout={MainLayout} path="/users/view/:id" component={User} />
          <PrivateRoute exact layout={MainLayout} path="/users" component={Users} />
          <PrivateRoute exact layout={MainLayout} path="/credentials/create" component={Credential} />
          <PrivateRoute exact layout={MainLayout} path="/credentials/update/:id" component={Credential} />
          <PrivateRoute exact layout={MainLayout} path="/credentials/view/:id" component={Credential} />
          <PrivateRoute exact layout={MainLayout} path="/credentials" component={Credentials} />
          <PrivateRoute exact layout={MainLayout} path="/roles/create" component={Role} />
          <PrivateRoute exact layout={MainLayout} path="/roles/update/:id" component={Role} />
          <PrivateRoute exact layout={MainLayout} path="/roles/view/:id" component={Role} />
          <PrivateRoute exact layout={MainLayout} path="/roles" component={Roles} />
          <PrivateRoute exact layout={MainLayout} path="/profiles/create" component={Profile} />
          <PrivateRoute exact layout={MainLayout} path="/profiles/update/:id" component={Profile} />
          <PrivateRoute exact layout={MainLayout} path="/profiles/view/:id" component={Profile} />
          <PrivateRoute exact layout={MainLayout} path="/profiles" component={Profiles} />
          <PrivateRoute exact layout={MainLayout} path="/access-points/create" component={AccessPoint} />
          <PrivateRoute exact layout={MainLayout} path="/accessPoints/view/:id" component={AccessPoint} />
          <PrivateRoute exact layout={MainLayout} path="/accessPoints" component={AccessPoints} />
          <PrivateRoute exact layout={MainLayout} path="/groups/create" component={Group} />
          <PrivateRoute exact layout={MainLayout} path="/groups/update/:id" component={Group} />
          <PrivateRoute exact layout={MainLayout} path="/groups/view/:id" component={Group} />
          <PrivateRoute exact layout={MainLayout} path="/groups" component={Groups} />
          <PrivateRoute exact layout={MainLayout} path="/schedules/create" component={Schedule} />
          <PrivateRoute exact layout={MainLayout} path="/schedules/view/:id" component={Schedule} />
          <PrivateRoute exact layout={MainLayout} path="/schedules/update/:id" component={Schedule} />
          <PrivateRoute exact layout={MainLayout} path="/schedules" component={Schedules} />
          <PrivateRoute exact layout={MainLayout} path="/holidays/create" component={Holiday} />
          <PrivateRoute exact layout={MainLayout} path="/holidays/view/:id" component={Holiday} />
          <PrivateRoute exact layout={MainLayout} path="/holidays/update/:id" component={Holiday} />
          <PrivateRoute exact layout={MainLayout} path="/holidays" component={Holidays} />
          <PrivateRoute exact layout={MainLayout} path="/events/view/:id" component={Event} />
          <PrivateRoute exact layout={MainLayout} path="/events/access-granted" component={Events} />
          <PrivateRoute exact layout={MainLayout} path="/events" component={Events} />
          <PrivateRoute exact layout={MainLayout} path="/controllers/create" component={Controller} />
          <PrivateRoute exact layout={MainLayout} path="/controllers/view/:id" component={Controller} />
          <PrivateRoute exact layout={MainLayout} path="/controllers/update/:id" component={Controller} />
          <PrivateRoute exact layout={MainLayout} path="/controllers" component={Controllers} />
          <PrivateRoute exact layout={MainLayout} path="/backup-and-restore" component={BackupAndRestore} />
          <PrivateRoute exact layout={MainLayout} path="/settings" component={Settings} />
          <PrivateRoute exact layout={MainLayout} path="/administrators/view/:id" component={Administrator} />
          <PrivateRoute exact layout={MainLayout} path="/administrators/update/:id" component={Administrator} />
          <PrivateRoute exact layout={MainLayout} path="/system-roles" component={SystemRoles} />
          <PrivateRoute exact layout={MainLayout} path="/administrators/create" component={Administrator} />
          <PrivateRoute exact layout={MainLayout} path="/administrators" component={Administrators} />
          <PrivateRoute exact layout={MainLayout} path="/locations" component={Locations} />
          <PrivateRoute exact layout={MainLayout} path="/locations/view/area/:id" component={Area} />
          <PrivateRoute exact layout={MainLayout} path="/locations/view/:id" component={Location} />
          <PrivateRoute exact layout={MainLayout} path="/syncing-users" component={SyncingUsers} />
          <PrivateRoute exact layout={MainLayout} path="/syncing-users/user-guide" component={EntraIdUserGuide} />
        </Websocket>
      </ProtectedResources>
    </Switch>
  )
};

export default Routes;
