import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../theme/colors';

export default makeStyles((theme) => ({
  containers: {
    paddingLeft   : theme.spacing(2),
    paddingRight  : theme.spacing(2),
    paddingTop    : theme.spacing(2),
  },
  iconBox: {
    marginRight: theme.spacing(2)
  },
  locationField: {
    paddingLeft  : theme.spacing(2),
    paddingRight : theme.spacing(2),
    '& .MuiTextField-root' : {
      marginTop  : '0'
    }
  },
  csvForm: {
    width     : '100%',
  },
  csvModal: {
    alignItems    : 'center',
    padding       : theme.spacing(0),
    paddingTop    : theme.spacing(5),
    width         : 'fit-content'
  },
  personCount: {
    marginLeft: theme.spacing(2),
  },
  dialogImage: {
    alignItems: 'center',
    margin    : '0 auto',
    textAlign : 'center',
    paddingTop : theme.spacing(2),
    paddingBottom : theme.spacing(2),
  },
  dialogTitle: {
    fontWeight: 'bold',
    textAlign : 'center',
    paddingTop : theme.spacing(2),
  },
  dialogContent: {
    padding  : theme.spacing(0.5),
    textAlign: 'center',
  },
  dialogEmphasis: {
    fontStyle      : 'italic'
  },
  dialogActions: {
    alignItems     : 'center',
    backgroundColor: colors.LIGHT_GRAY,
    direction      : 'row',
    display        : 'flex',
    height         : theme.spacing(8),
    padding        : theme.spacing(1.5),
    margin         : '0 auto',
  },
  dialogContents: {
    alignItems     : 'center',
    width          : '100%',
  },
  dialogSubmitButton: {
    display : 'flex',
    float   : 'left',
    margin  : theme.spacing(0.5),
    padding : theme.spacing(0.5),
    width   : "50%",
    height  : theme.spacing(4.6)
  },
  chooseFileButton: {
    display : 'flex',
    float   : 'left',
    width   : '35%',
    height  : theme.spacing(5)
  },
  chooseFileField: {
    display     : 'flex',
    marginRight : theme.spacing(1),
    marginTop   : 0,
    width       : "65%",
  },
  skipIcon: {
    color       : '#FFB300',
    paddingRight: theme.spacing(0.5),
  },
  warningIcon: {
    color       : theme.palette.primary.main,
    paddingRight: theme.spacing(0.5),
  },
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'min-content !important'
    }
  },
  dialogCancelButton: {
    display : 'flex',
    float   : 'right',
    margin  : theme.spacing(0.5),
    padding : theme.spacing(0.5),
    width   : "50%",
  },
  csvActions: {
    alignItems     : 'flex-start',
    display        : 'flex',
    height         : theme.spacing(8),
    padding        : theme.spacing(2),
    paddingLeft    : theme.spacing(2),
    paddingRight   : theme.spacing(0),
    margin         : '0 auto',
  },
  csvButton: {
    display : 'flex',
    float   : 'right',
    margin  : theme.spacing(0.5),
    padding : theme.spacing(0.5),
  },
  csvItems: {
    marginLeft      : theme.spacing(4),
    marginRight     : theme.spacing(4),
    marginTop       : theme.spacing(4),
    marginBottom    : theme.spacing(5),
  },
  csvText: {
    marginBottom    : theme.spacing(0.5),
    width           : '100%',
  },
  warningText: {
    marginTop    : theme.spacing(2),
    width        : '100%',
    fontSize     : theme.spacing(1.8)
  },
  table: {
    borderCollapse: 'collapse'
  },
  th: {
    border      : '1px solid',
    fontSize    : theme.spacing(1.5),
    paddingRight: theme.spacing(2.6)
  },
  td: {
    border   : '1px solid',
    fontSize : theme.spacing(1.5)
  },
  sampleCsvLabel: {
    fontWeight    : 'bold',
    marginBottom  : theme.spacing(1)
  },
  displayNone: {
    display: 'none'
  },
  downloadModal: {
    display       : 'block',
    paddingBottom : theme.spacing(3),
    paddingLeft   : theme.spacing(4),
    paddingRight  : theme.spacing(4),
    paddingTop    : theme.spacing(4),
    width         : '450px'
  },
  downloadTitle: {
    fontWeight: 'bold',
    textAlign : 'center',
  },
  downloadContent: {
    paddingTop   : theme.spacing(1),
    textAlign    : 'center',
  },
  downloadActions: {
    alignItems     : 'center',
    backgroundColor: colors.LIGHT_GRAY,
    direction      : 'row',
    display        : 'flex',
    height         : theme.spacing(8),
    justifyContent : 'space-around',
    padding        : theme.spacing(1.5),
  },
  downloadSubmitButton: {
    display : 'flex',
    float   : 'left',
    margin  : theme.spacing(0.5),
    padding : theme.spacing(0.5),
    width   : "50%",
  },
  downloadCancelButton: {
    display : 'flex',
    float   : 'right',
    margin  : theme.spacing(0.5),
    padding : theme.spacing(0.5),
    width   : "50%",
  },
}));