import React, { useEffect } from 'react'
import { Box, Button, Dialog, Paper, Typography } from '@material-ui/core'
import { Trans } from 'react-i18next';
import images from '../../utility/images'
import useStyles from './styles';

const SessionTimeoutModal = (props) => {
  const { isOpen, handleCloseModal, secondsRemaining, signOut, sessionTimeout } = props;
  const classes = useStyles();

  const secondsToDisplay = secondsRemaining % 60;

  useEffect(() => {
    if (isOpen && secondsToDisplay === 0) {
      sessionTimeout();
    }
  }, [isOpen, sessionTimeout, secondsToDisplay]);

  return (
    <Dialog
      modal="false"
      open={isOpen}
      onClose={handleCloseModal}
      aria-labelledby="session-timeout-dialog"
      className={classes.dialog}
      data-testid="session-timeout-dialog"
    >
      <Paper elevation={0} className={classes.diaglogContainer}>
        <Box className={classes.dialogImage} data-testid="session-timeout-img">
          <img src={images.SESSION_TIMEOUT} alt='session-timeout-img' />
        </Box>
        <Box className={classes.dialogTitle} data-testid="session-timeout-title">
          <Typography className={classes.title}>
            <Trans i18nKey='session-timeout-modal.dialogTitle' />
          </Typography>
        </Box>
        <Box className={classes.dialogContent} data-testid="session-timeout-timer">
          <Typography className={classes.dialogTimer}>
            <Trans i18nKey='session-timeout-modal.dialogTimer' values={{ timer: String(secondsToDisplay).padStart(2, '0') }}/>
          </Typography>
          <Typography className={classes.dialogDescription} data-testid="session-timeout-description">
            <Trans i18nKey='session-timeout-modal.dialogDescription' />
          </Typography>
        </Box>
      </Paper>
      <Box className={classes.dialogActions} data-testid="session-timeout-action-box">
        <Button color="primary" variant="outlined" className={classes.dialogLogoutButton} onClick={signOut} data-testid="session-timeout-logOut-button">
          <Trans i18nKey='session-timeout-modal.logOutButton' />
        </Button>
        <Button color="primary" variant="contained" onClick={handleCloseModal} data-testid="session-timeout-stay-button">
          <Trans i18nKey='session-timeout-modal.stayLoginButton' />
        </Button>
      </Box>
    </Dialog>
  )
}

export default SessionTimeoutModal