import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

const useStyles = makeStyles(theme => ({
  formContainer: {
    display   : 'flex',
    marginLeft: theme.spacing(3),
    width     : theme.spacing(75),
    '&:hover': {
      backgroundColor: 'transparent !important'
    },
  },
  icon: {
    height : theme.spacing(2.5),
    width : theme.spacing(2.5),
    '& svg': {
      color: colors.PRIMARY
    }
  },
  actionButton: {
    height: 'fit-content',
    marginTop: theme.spacing(0.75),
    padding: theme.spacing(1),
  }
}))

export default useStyles;