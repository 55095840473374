import React, { useContext, useEffect, useRef, useState } from 'react';

import { Grid, IconButton, Link, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { AuthContext } from '../../context/authContext';
import { LocationContext } from '../../context/locationContext';
import { UPDATE_ACCOUNT } from '../../reducer/authReducer';
import { deleteLocation, updateLocation } from '../../service/locationsAPI';
import { ACTION_VIEW, LOCATIONS_ERROR_TOASTER, LOCATIONS_SUCCESS_TOASTER } from '../../utility/constants';
import { setLocalStorageItem } from '../../utility/helper';
import images from '../../utility/images';
import LocationDeleteModal from '../location-delete-modal';
import LocationTextField from '../location-text-field';
import useStyles from "./styles";
import { SET_LOCATION_FILTER } from '../../reducer/locationReducer';

const LocationItem = (props) => {
  const { id, name: locationName, showToaster, handleUpdateList } = props;
  
  const history  = useHistory();  
  const { pathname } = history.location;

  const isView = pathname?.includes(ACTION_VIEW);

  const { t } = useTranslation();
  const classes = useStyles();
  
  const [name, setName] = useState(locationName);
  const [showTextField, setShowTextField] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  
  const { state : administratorState, dispatch : dispatchAdministrator } = useContext(AuthContext);
  const { state : locationState, dispatch: locationDispatch } = useContext(LocationContext);

  const state = useContext(LocationContext)?.state;

  const initialValue = {
    name : name
  }

  const wrapperRef = useRef(null);
  
  const handleUpdateButton = () => {
    setShowTextField(!showTextField);
  }

  const handleDeleteButton = () => {
    setOpenModal(true);
  }

  const handleSubmit = async (values, formik) => {
    const { setErrors } = formik;
    if (name === values.name) {
      setShowTextField(false);
      return;
    }

    try {
      const response = await updateLocation(values.name, id);
      const { name } = response;
      
      setName(name);
      setShowTextField(false);
      showToaster(t('success'), 
        <Trans i18nKey={'locations-page.updateLocationSuccess'} values={{ name: values.name }}/>, 'success');
      await handleUpdateList({locationId: id, name: name });  
    } catch (error) {
      if (error?.response?.status === 409) {
        setErrors({
          name: t('locations-page.locationNameAlreadyExists')
        });
      }
    }
  }

  const handleDelete = async () => {
    setOpenModal(false);
    let title = LOCATIONS_SUCCESS_TOASTER.TITLE;
    let descrption = LOCATIONS_SUCCESS_TOASTER.DELETE_LOCATION_DESCRIPTION;
    let severity = LOCATIONS_SUCCESS_TOASTER.SEVERITY;
    let entities = {};
    let lastEntity = '';
    try {
      await deleteLocation(id);
      
      updateSelectedState(id);
      updateAdministratorState(id);

      await handleUpdateList({ locationId : id, isDelete: true });
    } catch (error) {
      title = LOCATIONS_ERROR_TOASTER.TITLE;
      severity = LOCATIONS_ERROR_TOASTER.SEVERITY;
      descrption = LOCATIONS_ERROR_TOASTER.DELETE_LOCATION_DESCRIPTION;

      if (error?.response?.status === 409) {
        descrption = LOCATIONS_ERROR_TOASTER.DELETE_LOCATION_SINGLE_ENTITY;

        const conflictedEntities = getConflictedEntities(error?.response?.data);
        entities = conflictedEntities.entities;

        if (conflictedEntities.lastEntity) {
          lastEntity = conflictedEntities.lastEntity;
          descrption = LOCATIONS_ERROR_TOASTER.DELETE_LOCATION_MULTIPLE_ENTITIES;
        }
      }
    } finally {
      showToaster(t(title), <Trans i18nKey={descrption} values={{ name, entities, lastEntity }}/>, severity);
    }
  }

  const updateAdministratorState = (locationId) => {
    const locations = administratorState.administrator.locations.filter(location => location.locationId !== locationId)

    const administrator = {
      ...administratorState.administrator,
      locations: locations
    }
    
    setLocalStorageItem("locations", administrator.locations);

    const administratorPayload = {
       ...administratorState,
       administrator: administrator
    }
     
    dispatchAdministrator({type: UPDATE_ACCOUNT, payload: administratorPayload});
  }

  const updateSelectedState = (locationId) => {
    const selectedLocations = locationState?.selectedLocations;
    if (!selectedLocations) {
      return;
    }

    const filterSelectedLocations = selectedLocations.filter(selectedLocation => locationId !== selectedLocation.locationId);

    const locationIds = filterSelectedLocations.map(location => location.locationId);

    const locationPayload = {
      ...locationState,
      selectedLocationIds: locationIds,
      selectedLocations: filterSelectedLocations
    } 
    
    locationDispatch({ type: SET_LOCATION_FILTER, payload: locationPayload});
  }

  const getConflictedEntities = (message) => {
    delete message.locationId;
    const filteredObject = Object.fromEntries(
      Object.entries(message).filter(([_key, value]) => value)
    );

    const resultArray = Object.keys(filteredObject).map(key => t(`locations-page.${key}`));
    const isEntities = resultArray.length > 1;
    return {
      entities: isEntities ? resultArray.slice(0, -1).join(', ') : resultArray[0],
      lastEntity: isEntities ? resultArray.slice(-1)[0] : ''
    };
  }

  const handleDeleteCancel = () => {
    setOpenModal(false);
  }

  const handleLocationView = () => {
    history.push(`/locations/view/${id}`);
  }

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showTextField]);

  return (
    <>
      <LocationDeleteModal 
        openModal={openModal} 
        title={t('locations-page.deleteLocationTitle')}
        description={t('locations-page.deleteLocationDescription')}
        handleDelete={handleDelete}
        handleCancel={handleDeleteCancel}
      />
      <Grid container className={classes.locationItemContainer}>
        <Grid className={classes.name} item md={isView ? 12: 11} xs={isView ? 12: 11}>
          {
          showTextField ?
              <LocationTextField
                wrapperRef={wrapperRef}
                label={t('locations-page.locationName')}
                handleSubmit={handleSubmit}
                initialValue={initialValue}
                handleHideTextFiled={handleUpdateButton}
              />
          : 
            <>
              <img src={images.LOCATION_ICON} className={clsx(`${classes.icon} ${classes.locationIcon}`)} alt="location-icon"/> 
              <Link
                data-testid='location-link' 
                onClick={() => handleLocationView()} 
                className={classes.link}>{name}</Link>
            </>
          }
        </Grid>
        {
          !isView ?
            <Grid className={classes.actionIcons} item md={1} xs={1}>
              <Tooltip title={t('locations-page.updateLocationTitle')}>
                <IconButton 
                  data-testid="edit-button" 
                  className={classes.actionButton} 
                  size="small" 
                  onClick={handleUpdateButton}
                >
                  <img src={images.EDIT_ICON} className={classes.icon} alt="edit-icon"/> 
                </IconButton>
              </Tooltip>
              <Tooltip title={t('locations-page.removeLocationTitle')}>
                <IconButton 
                  data-testid="delete-button"
                  className={state.locationCount === 1 ? 'hidden' : classes.actionButton} 
                  size="small" 
                  onClick={handleDeleteButton}
                >
                  <img src={images.DELETE_ICON} className={classes.icon} alt="delete-icon"/> 
                </IconButton>
              </Tooltip>
            </Grid>
          :
          <></>
        }
      </Grid >
    </>
  )
}

export default LocationItem