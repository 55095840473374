import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../theme/colors';

export default makeStyles((theme) => ({
  paper: {
    borderRadius: theme.spacing(1),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  typography: {
    position: 'relative',
    marginBottom: theme.spacing(3),
    ' &::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: -12,
      width: '100%',
      height: theme.spacing(0.125),
      backgroundColor: colors.GRAY,
    },
  },
  descriptionField: {
    marginLeft: theme.spacing(0.5),
    fontWeight: 'bold',
    fontSize: theme.spacing(1.75)
  },
  descriptionField2: {
    marginLeft: theme.spacing(0.5),
    fontSize: theme.spacing(1.75)
  },
  statusInProgress: {
    color: colors.IN_PROGRESS,
    fontWeight: 'bold'
  },
  statusError: {
    color: colors.ERROR,
    fontWeight: 'bold'
  },
  statusSuccess: {
    color: colors.SUCCESS,
    fontWeight: 'bold'
  },
  noteStyle: {
    marginTop: theme.spacing(2)
  },
  buttonStyle: {
    whiteSpace: 'nowrap',
    marginRight: theme.spacing(2),
    width: theme.spacing(20.625),
    height: theme.spacing(5),
    borderRadius: theme.spacing(1)
  },
  syncButtonStyle: {
    whiteSpace: 'nowrap',
    marginRight: theme.spacing(2),
    width: theme.spacing(27.5),
    height: theme.spacing(5),
    borderRadius: theme.spacing(1)
  },
  buttonBorderStyle: {
    borderColor: 'black'
  },
  boxStyle: {
    display: 'flex',
    alignItems: 'center',
  },
  boxContentStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 24,
    height: 24,
    borderRadius: '50%',
    backgroundColor: '#B27B2A',
    color: 'white',
    marginRight: 4,
  }
}));