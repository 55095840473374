import { Box, Tooltip } from '@material-ui/core';
import React from 'react';
import { getEventTypeIcon } from '../../utility/event';
import useStyles from "./styles";

const EventTypeColumn = (props) => {
  const { type, subType } = props;
  const classes = useStyles();

  return (
    <Box className={classes.eventTypeContainer}>
      <Tooltip
        title={type}
        placement="top"
        classes={{
          tooltip: classes.iconTooltip
        }}
      >
        <Box className={classes.eventIcon}>
          {getEventTypeIcon(type)}   
        </Box>
      </Tooltip>
      <Box className={classes.eventSubTypeContainer}>
        {subType}
      </Box>
    </Box>
  )
}

export default EventTypeColumn