import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../../theme/colors';

export default makeStyles((theme) => ({
  container: {
    paddingTop    : theme.spacing(2),
    paddingBottom : theme.spacing(2),
  },
  formContent: {
    alignItems    : 'flex-start',
    display       : 'flex',
    height        : '350px',
    justifyContent: 'center',
    padding       : theme.spacing(1),
    paddingTop    : theme.spacing(4),
    width         : '90%'
  },
  roleContainer : {
    width       : '50%',
  },
  select : {
    padding   : theme.spacing(2),
    paddingTop: theme.spacing(1)
  },
  credentialPaper: {
    width        : '80%',
    height       : 'fit-content',
    '& .MuiTextField-root': {
      marginTop: theme.spacing(0)
    }
  },
  editIcon: {
    direction     : 'rtl'
  },
  form: {
    '& .MuiFormControl-root': {
      '& .MuiBox-root': {
        paddingTop    : 0,
        paddingBottom : 0
      },
    },
    '& .MuiFormControl-marginNormal': {
      marginTop: theme.spacing(0)
    },
  },
  calendarTextField: {
    '& .MuiInputBase-root': {
      padding       : '0'
    },
  },
  containerCredentials: {
    border          : 'thin',
    borderStyle     : 'solid',
    borderRadius    : '4px',
    borderColor     : '#c4c4c4',
    height          : '198px',
    marginTop       : theme.spacing(-1),
    '& .MuiBox-root': {
      paddingTop    : '0 !important',
      height        : '0% !important'
    }
  },
  fieldsetLegend: {
    color           : '#767779'
  },
  switchContainer: {
    alignSelf       : 'center',
    display         : 'flex',
    justifyContent  : 'flex-end',
    paddingTop      : '0px !important',
    paddingBottom   : '0px !important'
  },
  showDates: {
    backgroundColor  : 'transparent',
    padding          : theme.spacing(0),
    paddingBottom    : theme.spacing(2),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    }
  },
  showIcon: {
    color            : colors.PRIMARY,
    marginRight      : theme.spacing(1),
  },
  showLabel: {
    fontSize         : theme.spacing(2),
    '&:hover': {
      textDecoration : 'underline'
    }
  },
}));