import React from 'react';
import { Box, Chip as MuiChip, Typography } from '@material-ui/core';
import { Cancel as CancelIcon } from '@material-ui/icons';
import clsx from 'clsx';
import useStyles from './styles';
import { CHIP_COLOR, CHIP_COMPONENT } from '../../utility/constants';

const Chip = (props) => {
  const classes = useStyles();
  const { icon, title, description, onDelete, endComponent, color, fullWidth = false, isFor = CHIP_COMPONENT.COMMON, id, recordId } = props;

  const handleDelete = () => {
    onDelete();
  }

  return (
    <MuiChip
      id={`${id}${recordId}Chip`}
      icon={icon}
      className={
        clsx(
          classes.chip,
          {
            [classes.readerChip]     : isFor === CHIP_COMPONENT.READER,
            [classes.accessPointChip]: isFor === CHIP_COMPONENT.ACCESS_POINT,
            [classes.fullWidth]      : fullWidth,
            [classes.light]          : color === CHIP_COLOR.LIGHT,
            [classes.neutral]        : color === CHIP_COLOR.NEUTRAL,
            [classes.offline]        : color === CHIP_COLOR.OFFLINE,
            [classes.online]         : color === CHIP_COLOR.ONLINE,
            [classes.outline]        : color === CHIP_COLOR.OUTLINE,
            [classes.plain]          : color === CHIP_COLOR.PLAIN,
          } 
        )
      }
      size={isFor === CHIP_COMPONENT.ACCESS_POINT ? "medium" : "small"}
      color={"primary"}
      label={
        <Box className={clsx({
          [classes.label]: isFor !== CHIP_COMPONENT.SYNC,
        })}>
          <Box>
            <Typography noWrap className={'bold'} variant="caption" display="block">
              <span id={`${id}${recordId}ChipTitle`}>{title}</span>
            </Typography>
            <Typography variant="caption" display="block">
              <span id={`${id}${recordId}ChipDescription`}>{description}</span>
            </Typography>
          </Box>
          <Box>
            { endComponent }
          </Box>
        </Box>
      }
      deleteIcon={
        (onDelete) ?
            <CancelIcon/>
          :
            <></>
      }
      onDelete={handleDelete}
    />
  )
}

export default Chip;