import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Chip, CircularProgress, IconButton, InputAdornment, NoSsr, TextField, Tooltip, Typography } from '@material-ui/core';
import { CloseOutlined as CloseOutlinedIcon, Edit, SearchOutlined as SearchOutlinedIcon } from '@material-ui/icons';
import clsx from 'clsx';
import React, { createRef, useCallback, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Empty from '../empty';
import useStyles from './styles';
import { DEFAULT_ROLES } from '../../utility/constants';
import { AuthContext } from '../../context/authContext';

const Chips = (props) => {
  const { recordItem, id } = props;
  const { systemRoles } = recordItem;
  const { t }   = useTranslation();
  const classes = useStyles();
  return (
    <Box>
      {
        systemRoles?.length <= 2 ?
          systemRoles.map((role, index) => {
            return(
            <Chip id={`${id}${recordItem.id}SystemRoles${index}Chip`} label={role.name} color="primary" className={classes.roleChip} size='small' />
            )
          })
        :
          <>
            <Chip id={`${id}${recordItem.id}SystemRoles0Chip`} label={systemRoles[0].name} color="primary" className={classes.roleChip} size='small' />
            <Chip id={`${id}${recordItem.id}SystemRoles1Chip`} label={systemRoles[1].name} color="primary" className={classes.roleChip} size='small' />
            <Chip id={`${id}${recordItem.id}MoreChip`} label={`+${systemRoles?.length - 2} ${t('more')}`} variant='outlined' className={classes.roleChipMore} size='small' />
          </>
      }
    </Box>
  )
}

const RecordChips = (props) => {
  const { id, item, withChips } = props;
  return (
    withChips ?
      <Chips id={id} recordItem={item}/>
    :
      <></>
  )
}

const SearchWithAction = (props) => {
  const { useSearch, query, setQuery, pageNumber, setPageNumber, handleOpenDeleteModal, selectedItem, handleItemClick, handleUpdateClick, withChips, hasChanges, isLoading, setHasChanges, id, withSearch } = props;
  const classes = useStyles();
  const { t }   = useTranslation();
  
  const { state }          = useContext(AuthContext);
  const { administrator }  = state;

  const isVisible = (role) => {
    const defaultRoles = Object.values(DEFAULT_ROLES);

    return !defaultRoles.includes(role);
  }

  const { isLoadingSelect, error, items, hasMore } = useSearch(query, pageNumber);

  const ref = useRef([]);
  useEffect(() => {
    if (isLoading || isLoadingSelect) {
      return;
    }
    ref.current = [...Array(items.length).keys()].map(
      (_, index) => ref.current[index] ?? createRef()
    );
    if (hasChanges) {
      const index = items.find(item => item.id === selectedItem);
      ref.current[items.indexOf(index)]?.current?.scrollIntoView({
        behavior: 'smooth',
        block   : 'center'
      });
      setHasChanges(false);
    }
  }, [hasChanges, isLoading, isLoadingSelect, items, selectedItem, setHasChanges]);

  const observer = useRef();
  const lastItemElement = useCallback(node => {
    if (isLoadingSelect) {
      return;
    }
    if (observer.current) {
      observer.current.disconnect();
    }
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPageNumber(prevPageNumber => prevPageNumber + 1)
      }
    })
    if (node) {
      observer.current.observe(node);
    }
  }, [isLoadingSelect, hasMore, setPageNumber]);

  const handleSearch = (e) => {
    setQuery(e.target.value);
    setPageNumber(0);
  }

  const handleClearSearch = () => {
    setQuery('');
    setPageNumber(0);
  }

  return (
    <>
      <TextField
        className={`${classes.searchField} ${withSearch || 'hidden'}`}
        id={`${id}SearchBar`}
        onChange={event => handleSearch(event)}
        placeholder={t('search')}
        value={query}
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlinedIcon color="secondary" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                id={`${id}ClearSearchButton`}
                edge="end"
                onClick={handleClearSearch}
                size="small"
                className={
                  clsx({
                    [classes.visuallyHidden]: (query === null || query === '')
                  })
                }
              >
                <CloseOutlinedIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />

      <Box>{isLoadingSelect && <CircularProgress size={24} className={classes.loading} />}</Box>
      <NoSsr>
        {
          items.length && !error ? 
            <Box className={classes.scrollable}>
              {
                items.map((item, index) => {
                  const hasDeletePermission = isVisible(item.name) && administrator.administratorId !== item.id;

                  if (items.length === index + 1) {
                    return (
                      <Box
                        id={`${id}${item.id}Cell`}
                        ref={lastItemElement} key={index}
                        className={clsx({
                            [classes.boxItemContainerHighlight] : selectedItem === item.id,
                            [classes.boxItemContainer]          : selectedItem !== item.id
                          })
                        }
                        >
                        <Box ref={ref.current[index]} className={classes.itemNameBox}>
                          <Typography
                            className={clsx({
                                [classes.typographStyleHightlight] : selectedItem === item.id,
                                [classes.typographStyle]           : selectedItem !== item.id
                              })
                            }
                            color="secondary"
                            onClick={() => handleItemClick(item.id, item.name)}
                            >
                            <span id={`${id}${item.id}Name`}>{item.name}</span>
                          </Typography>
                          <RecordChips
                            id={id}
                            item={item}
                            withChips={withChips}
                          />
                        </Box>
                        <Box>
                          <Tooltip
                            title={t('update')} 
                            className={ isVisible(item.name) ? classes.icon : 'hidden' }
                          >
                            <IconButton id={`${id}${item.id}UpdateButton`} aria-label="update" onClick={(event) => handleUpdateClick(item)}>
                              <Edit size="xs"/>
                            </IconButton>
                          </Tooltip>
                          {
                            hasDeletePermission &&
                              <Tooltip
                                title={t('remove')} 
                                className={classes.icon}
                              >
                                <IconButton id={`${id}${item.id}DeleteButton`} aria-label="delete" onClick ={(event) => handleOpenDeleteModal(event, item)}>
                                  <FontAwesomeIcon icon={faTrash} size="xs" />
                                </IconButton>
                              </Tooltip>
                          }
                        </Box>
                      </Box>
                    )
                  } else {
                    return (
                      <Box
                        id={`${id}${item.id}Cell`}
                        key={index}
                        className={clsx({
                            [classes.boxItemContainerHighlight] : selectedItem === item.id,
                            [classes.boxItemContainer]          : selectedItem !== item.id
                          })
                        } 
                        >
                        <Box ref={ref.current[index]} className={classes.itemNameBox}>
                          <Typography
                            className={clsx({
                                [classes.typographStyleHightlight] : selectedItem === item.id,
                                [classes.typographStyle]           : selectedItem !== item.id
                              })
                            }
                            color="secondary"
                            onClick={() => handleItemClick(item.id, item.name)}
                            >
                            <span id={`${id}${item.id}Name`}>{item.name}</span>
                          </Typography>
                          <RecordChips
                            id={id}
                            item={item}
                            withChips={withChips}
                          />
                        </Box>
                        <Box>
                          <Tooltip
                            title={t('update')} 
                            className={ isVisible(item.name) ? classes.icon : 'hidden' }
                          >
                            <IconButton id={`${id}${item.id}UpdateButton`} aria-label="update" onClick={() => handleUpdateClick(item)}>
                              <Edit size="xs"/>
                            </IconButton>
                          </Tooltip>
                          {
                            hasDeletePermission &&
                              <Tooltip
                                title={t('remove')} 
                                className={classes.icon}
                              >
                                <IconButton id={`${id}${item.id}DeleteButton`} aria-label="delete" onClick ={(event) => handleOpenDeleteModal(event, item)}>
                                  <FontAwesomeIcon icon={faTrash} size="xs" />
                                </IconButton>
                              </Tooltip>
                          }
                        </Box>
                      </Box>
                    )
                  }
                })
              }
            </Box>
          :
            <Box className={`${classes.emptyContainer}`}>
              <Empty />
            </Box>
        }
      </NoSsr>
    </>
  )
}

export default SearchWithAction;
