import { Container } from '@material-ui/core';
import cookies from 'js-cookie';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import DeleteModal from '../../components/delete-modal';
import Table, { createColumn } from '../../components/table';
import Title from '../../components/title';
import { LocationContext } from '../../context/locationContext';
import api from '../../service/api';
import { multipleRequest, request } from '../../service/requests';
import { ALL, API_REQUEST_ERROR_MESSAGE, API_REQUEST_ERROR_MESSAGE_DATA_USED, ASCENDING, DAILY, DELETE, GET, LANGUAGE_DE, LANGUAGE_EN, NAME, PROJECTION, SCHEDULE, SCHEDULES, SCHEDULES_MODULE, TIME_FROM, TIME_UNTIL, WEEKDAYS, WEEKENDS } from '../../utility/constants';
import { formatAssociated, parseIntegerParam, updateURLParams } from '../../utility/helper';
import useStyles from './styles';

const columns = [
  createColumn("name", "scheduleName", true, "string", false, true),
  createColumn("scheduleId", "Schedule Id", false, "string", true),
  createColumn("scheduleItem", "weekItem", false, "component"),
  createColumn("location", "location", true, "string")
];

const Schedules = (props) => {
  const { showToaster, showLoading, handlePermissions } = props;
  const { t } = useTranslation();

  const classes  = useStyles();
  const history  = useHistory();

  const { search, pathname } = history.location;

  const params     = new URLSearchParams(search);
  const query      = params.get('keyword') ? params.get('keyword') : '';
  const pageSize   = params.get('size') ? parseIntegerParam(params.get('size'), 10) : 10;
  const pageNumber = params.get('page') ? parseIntegerParam(params.get('page'), 1) : 1;
  const pageType   = params.get('schedules') ? params.get('schedules') : ALL.toLowerCase();
  const totalSize  = pageSize > 100 ? 100 : pageSize;

  const { state : locationState }             = useContext(LocationContext);
  const { selectedLocationIds }               = locationState;
  const [forDeleteName, setForDeleteName]     = useState('');
  const [forDelete, setForDelete]             = useState('');
  const [isLoading, setIsLoading]             = useState(false);
  const [isShowChameleon, setIsShowChameleon] = useState(false);
  const [keyword, setKeyword]                 = useState(query);
  const [prevKeyword, setPrevKeyword]         = useState(query);
  const [order, setOrder]                     = useState(ASCENDING);
  const [orderBy, setOrderBy]                 = useState(columns[0].id);
  const [openModal, setOpenModal]             = useState(false);
  const [page, setPage]                       = useState(pageNumber === 0 ? 1 : pageNumber);
  const [remove, setRemove]                   = useState(false);
  const [rowsPerPage, setRowsPerPage]         = useState(totalSize);
  const [schedules, setSchedules]             = useState([]);
  const [totalItems, setTotalItems]           = useState(0);
  const [type, setType]                       = useState('');
  const [assocValues, setAssocValues]         = useState('');
  const [listType, setListType]               = useState(pageType);
  const [title, setTitle]                     = useState('');

  const language = cookies.get('i18next') || LANGUAGE_EN;

  const momentWeekday = (day) => {
    if (day === 'Sonntag' ) {
      day = "Sunday";
    } else if (day === 'Samstag') {
      day = "Saturday";
    }
    return moment(day, 'dddd').weekday();
  };

  const chipDays = useCallback((list) => {
    switch (list.length) {
      case 7:
        return t(DAILY);
      case 2:
        const isWeekend = list.every(day => momentWeekday(day) % 6 === 0);
        return isWeekend ? `${t(WEEKENDS)}` : list.join(', ');
      case 5:
        const isWeekdays = list.every(day => momentWeekday(day) !== 0 && momentWeekday(day) !== 6);
        return isWeekdays ? `${t(WEEKDAYS)}` : list.join(', ');
      default:
        return list.join(', ');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const daysInOrder = {
    Sunday    : 1,
    Monday    : 2,
    Tuesday   : 3,
    Wednesday : 4,
    Thursday  : 5,
    Friday    : 6,
    Saturday  : 7,
  };

  const filterItems = (items, name) => {
    return items.map(item => {
      return items.filter(element => {
        if (name) {
          return element.name === item.name;
        }
        return element.timeFrom === item.timeFrom && element.timeUntil === item.timeUntil
      });
    });
  }

  const removeDuplicateObject = (items, firstKey, secondKey) => {
    return [...new Map(items.map(item => {
      if (secondKey) {
        return [[item[0][firstKey], item[0][secondKey]], item];
      }
      return [item[0][firstKey], item]
      })
    ).values()];
  }

  const getFormattedSchedule = useCallback((item) => {
    const { name, scheduleItems, scheduleId, location } = item;
    const filterSameSchedule = filterItems(scheduleItems, false);

    const removeDuplicateSchedule = removeDuplicateObject(filterSameSchedule, TIME_FROM, TIME_UNTIL);
    const createArrayObject = removeDuplicateSchedule.map(obj => {
      return {
        name: obj.map(key => t(key.day)),
        description: obj.map(key => `${key.timeFrom.slice(0, 5)} - ${key.timeUntil.slice(0, 5)}`)
      }
    });

    const weekItems = createArrayObject.map(item => {
      const sortedDay = item.name.sort((a, b) => {
        if (language === LANGUAGE_DE) {
          let translatedDaysInOrder = {};

          Object.entries(daysInOrder).map((key, value) => {
            const newObj = translatedDaysInOrder[t(key[0])] = value;

            translatedDaysInOrder = { ...translatedDaysInOrder, ...newObj };
            return null;
          });

          return translatedDaysInOrder[a] - translatedDaysInOrder[b] || a > b || b < a;
        }

        return daysInOrder[a] - daysInOrder[b] || a > b || b < a;
      });
      return {
        name: chipDays(sortedDay),
        description: item.description[0],
      }
    })

    const filterSameName = filterItems(weekItems, true);
    const removeDuplicateItem = removeDuplicateObject(filterSameName, NAME);

    const createArrayItem = removeDuplicateItem.map(item => {
      return {
        name: [...new Set(item.map(key => key.name))][0],
        description: [...new Set(item.map(key => key.description).sort())].join(', ')
      }
    });

    return {
      name         : name,
      scheduleId   : scheduleId,
      scheduleItems: createArrayItem,
      location    : location ? location.name : '-'
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chipDays, daysInOrder, language])

  const getFormattedSchedules = useCallback((items) => {
    return items.map(item => {
      return getFormattedSchedule(item);
    });
  }, [getFormattedSchedule]);

  const getSchedulesResponse = useCallback((response) => {
    const { data } = response;
    const { number, size, totalElements, totalPages } = data.page;
    
    const totalNumber = number > totalPages ? totalPages : number;
    const newSchedule = getFormattedSchedules(data._embedded.schedules);

    if (newSchedule.length || number === 0) {
      setPage(totalNumber + 1)
      setSchedules(newSchedule);
    } else {
      setPage(totalNumber)
    }

    setRowsPerPage(size);
    setTotalItems(totalElements);
  }, [getFormattedSchedules])

  const getUrl = () => {
    if (orderBy === 'location') {
      return (order === ASCENDING) ? api.SCHEDULES_SEARCH_ORDER_BY_LOCATION_NAME_ASC : api.SCHEDULES_SEARCH_ORDER_BY_LOCATION_NAME_DESC;
    } else {
      return (keyword === '') ? api.SCHEDULES : api.SCHEDULES_BY_NAME;
    }
  }

  const getSchedules = useCallback(async () => {
    remove ? setIsLoading(false) : setIsLoading(true);
    setPrevKeyword(keyword);

    try {
      const response = await request({
        url     : getUrl(),
        method  : GET,
        params  : {
          size       : rowsPerPage,
          page       : page - 1,
          sort       : `${orderBy},${order}`,
          name       : keyword,
          projection : PROJECTION.SCHEDULES
        }
      });
      console.log(`${response.config.method.toUpperCase()} ${response.config.url}`, response);

      getSchedulesResponse(response);
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, order, orderBy, page, remove, rowsPerPage]);

  useEffect(() => {
    if (remove) {
      getSchedules();
    }
    setRemove(false);
  }, [getSchedules, remove]);

  const getData = useCallback(async() => {
    if (listType === ALL.toLowerCase()) {
      setTitle(SCHEDULES);
      await getSchedules();
    } else {
      setListType(ALL.toLowerCase());
    }
  }, [getSchedules, listType]);

  const urlParams = useMemo(() => ({
    keywordParam :  keyword ? `&keyword=${keyword}` : '',
    entityParam  : `schedules=${listType}`,
    pageParam    : `page=${page}`,
    sizeParam    : `size=${rowsPerPage}`,
    history      : history,
    pathname     : pathname
  }), [keyword, listType, page, rowsPerPage, history, pathname]);

  useEffect(() => {
    let delayDebounce;

    if (keyword && keyword !== prevKeyword) {
      delayDebounce = setTimeout(() => {
        updateURLParams(urlParams);
        getData();
      }, 1000);
    } else {
      updateURLParams(urlParams);
      getData();
    }

    return () => {
      delayDebounce && clearTimeout(delayDebounce);
    }
  }, [page, prevKeyword, rowsPerPage, order, orderBy, keyword, listType, selectedLocationIds, getData, urlParams]);

  const handleSearch = (value) => {
    setPage(1);
    setKeyword(value);
  }

  const handleClearSearch = () => {
    setPage(1);
    setKeyword('')
  }

  const handleSort = (newOrderBy, newOrder) => {
    setPage(1);
    setOrderBy(newOrderBy);
    setOrder(newOrder);
  }

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setPage(1);
    setRowsPerPage(newRowsPerPage);
  }

  const handleChangePage = (newPage) => {
    if (isLoading) {
      return;
    }

    setPage(newPage + 1);
  }

  const handleView = (value) => {
    const { scheduleId } = value;
    history.push(`/schedules/view/${scheduleId}`);
  }

  const handleCreate = () => {
    history.push("/schedules/create");
  }

  const handleUpdate = (value) => {
    const { scheduleId } = value;
    history.push(`/schedules/update/${scheduleId}`);
  }

  const handleDelete = async (value) => {
    const response = await request({
      url     : `${api.SCHEDULES}/${value.scheduleId}/profiles`,
      method  : GET,
    });

    const { data } = response;
    if (data._embedded.profiles.length) {
      setAssocValues(formatAssociated(data._embedded.profiles.map(profile => {return (`${profile.name}`)}), t));
      handleOpenDeleteModal(value, value.name, 'singleWithAssociated')
    } else {
      handleOpenDeleteModal(value, value.name, 'single')
    }
  }

  const singleDelete = async (value) => {
    const { scheduleId, name } = value;

    try {
      await request({
        url     : `${api.SCHEDULES}/${scheduleId}`,
        method  : DELETE,
      });
      setIsLoading(false);
      showToaster(t(`success`), `${value.name} ${t('hasBeenDeleted')}`, 'success');
      setRemove(true);
    } catch (error) {
      if (error?.response?.status === 409) {
        showToaster(t('error'), name + t(API_REQUEST_ERROR_MESSAGE_DATA_USED), 'error');
      } else {
        showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
      }
      setRemove(true);
    } finally {
      setIsShowChameleon(false);
    }
  }

  const handleMultipleDelete = async (values) => {
    const responses = await multipleRequest(
      values.map(schedule => request({
        url     : `${api.SCHEDULES}/${schedule.scheduleId}/profiles`,
        method  : GET
      }))
    )

    const profiles = responses.map(response => response.data._embedded);
    const hasAssoc = profiles.filter(profile => profile.profiles.length > 0);
    if (hasAssoc.length > 0) {
      setAssocValues(formatAssociated(
        [...new Set(hasAssoc.map(value =>
          value.profiles.map(profile => {
            return (` ${profile.name}`)
          })).flat())
        ], t
      ));
      handleOpenDeleteModal(values, '', 'multipleWithAssociated');
    } else {
      handleOpenDeleteModal(values, '', 'multiple');
    }
  }

  const multipleDelete = async (values) => {
    const ids   = values.map(value => value.scheduleId);
    try {
      const responses = await multipleRequest(
        ids.map(id => request({
          url   : `${api.SCHEDULES}/${id}/scheduleItems`,
          method: GET,
        }))
      );
      const urls = responses.map(response => {
        const scheduleItems = response.data._embedded.scheduleItems;
        return scheduleItems.map(item => item._links.self.href);
      }).flat();

      handleMultipleDeleteScheduleItems(urls, values);
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  const handleMultipleDeleteScheduleItems = async (urls, values) => {
    try {
      await multipleRequest(
        urls.map(url => request({
          url   : url,
          method: DELETE
        }))
      );
      handleMultipleDeleteSchedules(values);
      setIsLoading(false);
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }
  }

  const handleMultipleDeleteSchedules = async (values) => {
    const ids   = values.map(value => value.scheduleId);
    const names = values.map(value => value.name);

    try {
      await multipleRequest(
        ids.map(id =>
          request({
            url     : `${api.SCHEDULES}/${id}`,
            method  : DELETE,
          })
        )
      );
      showToaster(t(`success`), `${names.join(', ')} ${t('hasBeenDeleted')}`, 'success');
      setRemove(true);
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
      setRemove(true);
    } finally {
      setIsShowChameleon(false);
    }
  }

  const handleOpenDeleteModal = (value, name, modalType) => {
    setOpenModal(true);
    setForDeleteName(name);
    setForDelete(value);
    setType(modalType);
  }

  const handleCloseDeleteModal = () => {
    setOpenModal(false);
    setIsShowChameleon(false);
  }

  const handleType = () => {
    setListType(ALL.toLowerCase());
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Title title={t(title)} listType={listType}/>
      <DeleteModal
        assocValues={assocValues}
        forDeleteName={forDeleteName}
        initialValues={forDelete}
        module={SCHEDULES_MODULE}
        multipleDelete={multipleDelete}
        onClose={handleCloseDeleteModal}
        open={openModal}
        showToaster={showToaster}
        singleDelete={singleDelete}
        type={type}
      />

      {showLoading(isShowChameleon)}
      <Table
        columns={columns}
        data={schedules}
        isLoading={isLoading}
        keyword={keyword}
        label={SCHEDULE}
        module= {SCHEDULES_MODULE}
        onChangePage={handleChangePage}
        onClearSearch={handleClearSearch}
        onCreate={handleCreate}
        onDelete={handleDelete}
        onMultipleDelete={handleMultipleDelete}
        onRowsPerPageChange={handleRowsPerPageChange}
        onSearch={handleSearch}
        onSort={handleSort}
        onUpdate={handleUpdate}
        onView={handleView}
        orderBy={orderBy}
        order={order}
        page={page}
        rowsPerPage={rowsPerPage}
        totalItems={totalItems}
        viewKey={'name'}
        listType={listType}
        onViewAll={handleType}
        handlePermissions={handlePermissions}
      />
    </Container>
  );
}

export default Schedules;