export const SET_SELECT_CREDENTIALS = 'SET_SELECT_CREDENTIALS';
export const SET_CREDENTIAL_VALUES  = 'SET_CREDENTIAL_VALUES';
export const SET_CREDENTIAL_UPDATES = 'SET_CREDENTIAL_UPDATES';
export const SET_NEW_CREDENTIALS    = 'SET_NEW_CREDENTIALS';
export const CLEAR_REDUCER          = 'CLEAR_REDUCER';

export const credentialInitialState = {
  credentialValues    : [],
  selectedCredentials : [],
  newCredentials      : []
}

export const WizardCredentialReducer = (state, action) => {
  switch (action.type) {
    case SET_SELECT_CREDENTIALS:
      return {
        ...state,
        selectedCredentials: action.payload,
      }
    case SET_CREDENTIAL_VALUES:
      return {
        ...state,
        credentialValues: action.payload,
      }
    case SET_CREDENTIAL_UPDATES: {
      return {
        ...state,
        credentialUpdate: action.payload
      }
    }
    case SET_NEW_CREDENTIALS:
      return {
        ...state,
        newCredentials      : action.payload.newCredentials,
        selectedCredentials : action.payload.selectedCredential,
        credentials         : action.payload.credentials
      }
    case CLEAR_REDUCER:
      return {
        ...credentialInitialState
      }
    default:
      return state
  }
}