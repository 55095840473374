import DateFnsUtils from '@date-io/date-fns';
import { Backdrop, Box, Button, Container, Fade, Grid, Link, MenuItem, Modal, TextField } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import clsx from 'clsx';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import { Form, Formik } from 'formik';
import cookies from 'js-cookie';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from '../../../components/date-picker';
import Select from '../../../components/select';
import SelectItems from '../../../components/select-items';
import { AuthContext } from '../../../context/authContext';
import { EventsFilterContext } from '../../../context/eventsFIlterContext';
import { ADD_FILTER } from '../../../reducer/eventsFilterReducer';
import { ACCESS_CHECK, ALL, DATE_FORMAT_YYYY_MM_DD, DEFAULT_DATE, DOOR, EVENTS_MODULE, FILTER_EVENT_FORMAT, FILTER_TYPES, LANGUAGE_DE, LANGUAGE_EN, READER, SUB_TYPES, SUBTYPES, TYPE_SUB_TYPES, TYPES } from '../../../utility/constants';
import { isArrayEqual, parseDateParams, parseObjectParams, parseParams } from '../../../utility/helper';
import useStyles from './styles';

const EventAccessPointSelect = (props) => {
  const { value, handleAccessPoint, accessPointList } = props;
  
  const classes = useStyles();
  const { t } = useTranslation();
  
  return (
    <TextField
      className={classes.fullWidth}
      id="eventsAccessPoints"
      label={t(`events-page.accessPointLabel`)}
      name="accessPoints"
      value={value}
      variant="outlined"
      SelectProps={{
        MenuProps: {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }
      }}
      select
    >
      {
        accessPointList.map((accesspoint, index) => {
          const { name, displayName } = accesspoint;
          
          return(
            <MenuItem value={name} key={index} onClick={() => handleAccessPoint(name)}>
              <span id={`eventsAccessPoints${index}Name`}> {displayName}</span>
            </MenuItem>
          );
        })
      }
    </TextField>
  )
}

const EventsFilter = (props) => {
  const { 
    initialValues, 
    subTypeList, 
    setIsNotValid, 
    accessPointList,
    handleClearFilter, 
    handleClose, 
    handleSubmit, 
    handleTypes, 
    handleSubTypes, 
    handleDateUntil, 
    handleDateFrom, 
    handleDeviceIds, 
    handlePermissions, 
    handleAccessPoint,
    handleCredentialNumber,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const typeList = FILTER_TYPES.map((type, index) => {
    return {
      id : index,
      name : type,
    }
  });

  const typeValues = typeList.filter(item => {
    return initialValues.types.includes(item.name)
  })

  const subTypeValues = subTypeList.filter(item => {
    return initialValues.subTypes.includes(item.name)
  })
  
  const showSelectItems = (item) => {
    const types = typeValues.map(type => type.name);
    return types.includes(item);
  }

  const showControllerSelect = showSelectItems(DOOR) || showSelectItems(ALL);
  const showCredentialNumber = showSelectItems(READER) || showSelectItems(ACCESS_CHECK) || showSelectItems(ALL);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
     {
        formik => (
          <Form>
            <Grid container spacing={2} className={`${classes.paper} ${classes.form}`}>
              <Grid item md={6} sm={6}>
                <SelectTypesSubTypes 
                  id="eventFilterTypes"
                  name={TYPES}
                  handleChange={handleTypes} 
                  list={typeList} 
                  values={typeValues}
                />
              </Grid>
              <Grid item md={6} sm={6}>
                <SelectItems
                  id="eventControllerList"
                  name={EVENTS_MODULE}
                  onChange={handleDeviceIds}
                  selectedItems={formik.values.deviceIds}
                  handlePermissions={handlePermissions}
                />
              </Grid>
               <Grid item md={6} sm={6}>
                <DatePicker
                  id="eventsFrom"
                  name="validFrom"
                  disabled={false}
                  until={formik.values.until}
                  label={t(`from`)}
                  value={formik.values.from}
                  handleChange={value => handleDateFrom(value)}
                  touched={formik.touched.from}
                  error={formik.errors.from}
                  NotRequired={true}
                  setIsNotValid={setIsNotValid}
                />
              </Grid>
              <Grid item md={6} sm={6}>
                <DatePicker
                  id="eventsUntil"
                  name="validUntil"
                  disabled={false}
                  label={t(`until`)}
                  min={formik.values.until}
                  from={formik.values.from ? formik.values.from : DEFAULT_DATE}
                  value={formik.values.until}
                  handleChange={value => handleDateUntil(value)}
                  touched={formik.touched.until}
                  error={formik.errors.until}
                  NotRequired={true}
                  setIsNotValid={setIsNotValid}
                />
              </Grid>
              <Grid item md={6} sm={6} className={clsx((subTypeList.length ? '' : 'hidden'))}>
                <SelectTypesSubTypes 
                  id="eventsFilterSubTypes" 
                  name={SUBTYPES} 
                  handleChange={handleSubTypes}
                  list={subTypeList}
                  values={subTypeValues}
                />
              </Grid>
              <Grid item md={6} sm={6} className={clsx((showControllerSelect ? '' : 'hidden'))}>
                <EventAccessPointSelect 
                  handleAccessPoint={handleAccessPoint}
                  deviceIds={formik.values.deviceIds}
                  value={formik.values.accessPoint}
                  accessPointList={accessPointList}
                />
              </Grid>
              <Grid item md={6} sm={6} className={clsx((showCredentialNumber ? '' : 'hidden'))}>
                <TextField
                  className={classes.fullWidth}
                  id="eventsCredentialNumber"
                  label={t(`events-page.credentialNumberLabel`)}
                  name="credentialNumber"
                  onChange={handleCredentialNumber}
                  value={formik.values.credentialNumber}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container className={classes.action}>
              <Grid item sm={6} xs={6}>
                <Link
                  id="eventsClearFilter"
                  className={classes.filter}
                  onClick={handleClearFilter}
                  variant="body2"
                >
                  {t(`events-page.clearFilterButton`)}
                </Link>
              </Grid>
              <Grid item sm={6} xs={6} className={classes.buttons}>
                <Button
                  id="eventsCancelButton"
                  className={classes.button}
                  color="primary"
                  onClick={handleClose}
                  variant="outlined"
                >
                  {t(`events-page.cancelButton`)}
                </Button>
                <Button
                  id="eventsSubmitButton"
                  className={classes.button}
                  color="primary"
                  type="submit"
                  variant="contained"
                >
                  {t('events-page.confirmButton')}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )
      } 
    </Formik>
  )
}

const SelectTypesSubTypes = (props) => {
  const { id, handleChange, values, list, name } = props;
  const [items, setItems] = useState(list);
  const [searchQuery, setSearchQuery]             = useState('');
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  
  const { t } = useTranslation();

  const searchHandler = useCallback((query, pageNumber) => {
    setSearchQuery(query);
    setCurrentPageNumber(pageNumber);
  }, []);

  useEffect(() => {
    if (!searchQuery) {
      setItems(list);
      return;
    }

    const searchItem = list.filter((value) => { 
      const { name } = value;
      return name.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setItems(searchItem);
  }, [searchQuery, currentPageNumber, list]);

  return (
    <Select
      id                = {id}
      search            = {searchHandler}
      selectFrom        = {t(`${name}`)}
      label             = {t(`${name}`)}
      onChange          = {handleChange}
      defaultValue      = {values}
      items             = {items}
      hasMore           = {false}
      currentPageNumber = {currentPageNumber}
      searchQuery       = {searchQuery}
    />
  )
}

const EventsFilterModal = (props) => {
  const { open, handleClose, setSearchParams, searchParams, handlePermissions, accessPointList, 
    getAccessPoints } = props;

  const classes = useStyles();
  const language = cookies.get('i18next') || LANGUAGE_EN;

  const { state } = useContext(AuthContext);
  const { defaultValidFrom } = state;
  
  const { t } = useTranslation();

  const { dispatch } = useContext(EventsFilterContext);

  const [types, setTypes] = useState(parseParams(searchParams.types));
  const [subTypes, setSubTypes] = useState(parseParams(searchParams.subTypes));
  const [subTypeList, setSubTypeList] = useState([]);
  const [isNotValid, setIsNotValid] = useState(false);
  const [until, setUntil] = useState(parseDateParams(searchParams.until));
  const [from, setFrom] = useState(parseDateParams(searchParams.from));
  const [deviceIds, setDeviceIds] = useState(parseObjectParams(searchParams.deviceIds));
  const [accessPoint, setAccessPoint] = useState(searchParams.accessPoint);
  const [credentialNumber, setCredentialNumber] = useState(searchParams.credentialNumber);

  const initialValues = {
    types: types,
    subTypes: subTypes,
    until: until,
    from: from,
    deviceIds: deviceIds,
    accessPoint: accessPoint,
    credentialNumber: credentialNumber
  }

  const prevValues = {
    types: parseParams(searchParams.types),
    subTypes: parseParams(searchParams.subTypes),
    until: parseDateParams(searchParams.until),
    from: parseDateParams(searchParams.from),
    deviceIds: parseObjectParams(searchParams.deviceIds),
    accessPoint: searchParams.accessPoint,
    credentialNumber: searchParams.credentialNumber
  }

  useEffect(() => {
    dispatch({ type: ADD_FILTER, payload: searchParams });
  }, [dispatch, searchParams]);

  const isPresent = useCallback((item) => {
    return types.includes(item);
  }, [types]);

  useEffect(() => {
    const subTypeList = types.includes(ALL)
      ? Object.values(SUB_TYPES).flat()
      : types.flatMap((type) => TYPE_SUB_TYPES[type]);

    const formattedSubTypes = subTypeList.map((item, index) => ({ id: index, name: item }));

    if (!formattedSubTypes.length) {
      setSubTypes([]);
    }

    if (!isPresent(DOOR) && !isPresent(ALL)) {
      setAccessPoint('');
    }

    if (!isPresent(READER) && !isPresent(ACCESS_CHECK) && !isPresent(ALL)) {
      setCredentialNumber('');
    }

    setSubTypeList(formattedSubTypes);
  }, [types, isPresent]);

  const hasChanges = (current, previous) => {
    const currentIds  = current.map(item => parseInt(item.id));
    const previousIds = previous.map(item => parseInt(item.id));

    return !isArrayEqual(currentIds, previousIds);
  }

  const valuesHaveChanged = (values) => {
    const withTypeChange = hasChanges(prevValues.types, values.types);
    const withSubTypeChange = hasChanges(prevValues.subTypes, values.subTypes);
    const withDeviceChange = hasChanges(prevValues.deviceIds, values.deviceIds);

    return withTypeChange ||
      withSubTypeChange ||
      withDeviceChange ||
      values.until !== prevValues.until ||
      values.from !== prevValues.from ||
      values.accessPoint !== prevValues.accessPoint ||
      values.credentialNumber !== prevValues.credentialNumber;
  };

  const handleSubmit = (values) => {
    if (isNotValid) {
      return;
    }

    // Return if no changes have been made
    if (!valuesHaveChanged(values)) {
      handleClose();
      return;
    }

    const params = {
      ...values,
      from: moment(values.from).format(FILTER_EVENT_FORMAT),
      until: moment(values.until).format(FILTER_EVENT_FORMAT),
      deviceIds: values.deviceIds.map(item => `${item.id}:${item.name}`)
    }
    setSearchParams({ ...searchParams, ...params });

    handleClose();
  };

  const handleDeviceIds = (items) => {
    setDeviceIds(items);
    getAccessPoints(items);
  }

  const handleItems = (setter) => (items) => {
    const names = items.map((item) => item.name);
    setter(names);
  };

  const handleTypes = handleItems(setTypes);
  const handleSubTypes = handleItems(setSubTypes);
  
  const handleClearFilter = () => {
    setTypes([]);
    setSubTypes([]);
    setSubTypeList([]);
    setFrom(defaultValidFrom);
    setUntil(moment().format(DATE_FORMAT_YYYY_MM_DD));
    setDeviceIds([]);
    setAccessPoint('');
    setCredentialNumber('');
  }

  const handleDateUntil = (value) => {
    setUntil(value);
  }

  const handleDateFrom = (value) => {
    setFrom(value);
  }

  const handleAccessPoint = (value) => {
    setAccessPoint(prev => prev === value ? '': value);
  }

  const handleCredentialNumber = (item) => {
    setCredentialNumber(item.target.value);
  }
  
  const onClose = () => {
    handleClose();

    setTypes(parseParams(searchParams.types));
    setSubTypes(parseParams(searchParams.subTypes));
    setFrom(parseDateParams(searchParams.from));
    setUntil(parseDateParams(searchParams.until));
    setDeviceIds(parseObjectParams(searchParams.deviceIds));
    setAccessPoint(searchParams.accessPoint);
    setCredentialNumber(searchParams.credentialNumber);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={language === LANGUAGE_DE ? deLocale : enLocale}>
      <Modal
        disableEnforceFocus
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className={classes.filterModal}
        autoFocus={false}
      >
        <Fade in={open}>
          <Container className={`${classes.modalContent}`}>
            <Box className={`${classes.titleFilterModal} ${'bold'} ${classes.form}`}> {t('events-page.filterTitle')} </Box>
            <hr className={classes.hrDividerProfile} />
            <EventsFilter 
              searchParams={searchParams}
              initialValues={initialValues}
              subTypeList={subTypeList}
              handleClose={onClose}
              handleDeviceIds={handleDeviceIds}
              handleSubmit={handleSubmit}
              handleSubTypes={handleSubTypes}
              handleTypes={handleTypes}
              handleClearFilter={handleClearFilter}
              handleDateUntil={handleDateUntil}
              handleDateFrom={handleDateFrom}
              handleCredentialNumber={handleCredentialNumber}
              setIsNotValid={setIsNotValid}
              handleAccessPoint={handleAccessPoint}
              handlePermissions={handlePermissions}
              accessPointList={accessPointList}
            />
          </Container>
        </Fade>
      </Modal>
    </MuiPickersUtilsProvider>
  )
}

export default EventsFilterModal