import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';
import { CheckCircle, ErrorRounded, Sync } from '@material-ui/icons';
import moment from 'moment';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UserFederationContext } from '../../../context/userFederationContext';
import { PARTIAL_SYNC_USER_FEDERATION } from '../../../reducer/userFederationReducer';
import { triggerFullSync } from '../../../service/userSyncConfigurationAPI';
import { SYNC_STATUS, USER_SYNC_FORMAT } from '../../../utility/constants';
import useStyles from './styles';

const SyncGrid = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { title, description } = props;

  const statusDescription = () => {
    if (description === SYNC_STATUS.IN_PROGRESS) {
      return (
        <>
          <Box
            className={classes.boxStyle}
          >
            <Box
              className={classes.boxContentStyle}
            >
              <Sync />
            </Box>
            <Typography className={classes.statusInProgress}>{t(`entra-id-page.${description}`)}</Typography>
          </Box>
        </>
      )
    } else if (description === SYNC_STATUS.SUCCESS) {
      return (
        <Box
          className={`${classes.statusSuccess} ${classes.boxStyle}`}
        >
          <CheckCircle/><Typography className={classes.descriptionField}>{t(`entra-id-page.${description}`)}</Typography>
        </Box>
      )
    } else if (description === SYNC_STATUS.ERROR) {
      return (
        <Box
          className={`${classes.statusError} ${classes.boxStyle}`}
        >
          <ErrorRounded/><Typography className={classes.descriptionField}>{t(`entra-id-page.${description}`)}</Typography>
        </Box>
      )
    }

    return <Typography className={classes.descriptionField2}>{description}</Typography>;
  }

  return (
    <Grid container className={classes.typography}>
      <Grid item xs={4}>
        <Typography className={classes.descriptionField2}>{title}</Typography>
      </Grid>
      <Grid item xs={8}>
        {statusDescription()}
      </Grid>
    </Grid>
  )
}

const EntraSyncUsers = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isSyncing, setHasFederation, syncStatus, setSyncStatus, setIsSyncing, connectionURL } = props;

  const { state: federationState, dispatch: federationDispatch } = useContext(UserFederationContext);
  const { lastSync, federationId, userCount, nextSync } = federationState;

  const handleEditSettings = () => {
    setHasFederation(false);
  }

  const fetchData = useCallback(async () => {
    setIsSyncing(true);
    setSyncStatus(SYNC_STATUS.IN_PROGRESS);

    try {
      const federationData = await triggerFullSync(federationId);

      const { userFederation, userCount } = federationData.data;
      
      const { id, config } = userFederation;
      const { lastSync, changedSyncPeriod } = config;

      const lastUpdate = moment.unix(lastSync).format(USER_SYNC_FORMAT);
      const nextSync = moment.unix(lastSync).add(changedSyncPeriod, 'seconds').format(USER_SYNC_FORMAT);

      const payload = {
        federationId : id,
        lastSync     : lastUpdate,
        nextSync     : nextSync,
        userCount    : userCount
      }

      federationDispatch({ type: PARTIAL_SYNC_USER_FEDERATION , payload: payload});
      setSyncStatus(SYNC_STATUS.SUCCESS);
    } catch {
      setSyncStatus(SYNC_STATUS.ERROR);
    }

    setIsSyncing(false);
  }, [federationId, setIsSyncing, federationDispatch, setSyncStatus]);
  
  const handleSyncNow = () => {
    fetchData();
  }

  return (
    <>
      <Paper elevation={2} className={classes.paper}>
        <SyncGrid title={t('entra-id-page.status')} description={syncStatus}/>
        {syncStatus === SYNC_STATUS.ERROR ? <SyncGrid title={t('entra-id-page.errorDetails')} description={t('entra-id-page.syncErrorDetails')}/> : <></>}
        <SyncGrid title={t('entra-id-page.connectionURL')} description={connectionURL}/>
        {lastSync ? <SyncGrid title={t('entra-id-page.lastUpdate')} description={lastSync}/> : <></>}
        {nextSync ? <SyncGrid title={t('entra-id-page.nextScheduleUpdate')} description={nextSync}/> : <></>}
        {userCount ? <SyncGrid title={t('entra-id-page.userTransferred')} description={userCount}/> : <></>}
      </Paper>
      {
        federationId ?
          <Grid container className={classes.noteStyle}>
            <Grid item xs={12}>
              <Button
                onClick={handleEditSettings}
                variant="outlined"
                color="primary"
                disabled={isSyncing}
                className={`${classes.buttonStyle} ${classes.buttonBorderStyle}`}
              >
                {t('entra-id-page.editSettings')}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.syncButtonStyle}
                disabled={isSyncing}
                onClick={handleSyncNow}
              >
                {t('entra-id-page.syncUsersNow')}
              </Button>
            </Grid>
          </Grid>
        : 
          <></> 
      }
    </>
  )
}

export default EntraSyncUsers;