import { GET } from "../utility/constants";
import api from "./api";
import { request } from "./requests";

export const getUnsyncedDataCount = async (serialNumber, locationId) => {
  const response = await request({
    url     : api.OPEN_SYNC_COMMANDS,
    method  : GET,
    params  : {
      serialNumber : serialNumber,
      locationIds  : locationId,
    }
  });
  
  return response;
}