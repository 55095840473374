import cookies from 'js-cookie';
import moment from "moment/moment";
import { AUDIT_LOG_AREA_OPERATIONS, AUDIT_LOG_LOCATION_OPERATIONS, AUDIT_LOG_TRANSLATION_KEY } from "./constants";

const getAreaKey = (operation) => {
  const areaOperation = AUDIT_LOG_AREA_OPERATIONS[operation];
  return AUDIT_LOG_TRANSLATION_KEY[areaOperation];
}

const getLocationKey = (operation, content) => {
  let operationKey = AUDIT_LOG_LOCATION_OPERATIONS[operation];

  if (typeof operationKey === 'object') {
    const entityName = getEntityObjectAndName(content);
    operationKey = operationKey[entityName.name];
  }

  return AUDIT_LOG_TRANSLATION_KEY[operationKey];
}

const getAuditLogUser = (content) => {
  if (!content.administrator) {
    return content.userId;
  }

  const { lastName, firstName } = content.administrator;
  return `${firstName} ${lastName}`;
}

const removeEmptyProperties = (values) => {
  return Object.fromEntries(Object.entries(values).filter(([_, value]) => value != null));
}

const getEntityObjectAndName = (content) => {
  if (!content.field) {
    return {};
  }
  
  const objectName = content.field === 'access_point_id' ? content.field.replace('_point_id', 'Point') : content.field.replace('_id', '');

  return { 
    name: objectName.toUpperCase(), 
    value: content[objectName]
  };
}

const getEntityName = (content) => {
  const obj = getEntityObjectAndName(content)?.value;
  if (!obj) {
    return content.previousValue ?? content.newValue;
  }

  const entityPropertyKeys = ['name', 'credentialNumber'];
  const result = entityPropertyKeys.map(key => obj[key])
    .filter(value => value !== undefined);
  return result[0];
}

const getAreaValues = (content) => {
  const value = {
    user    : getAuditLogUser(content),
    name    : content.newValue,
    prevName: content.previousValue,
  }

  return removeEmptyProperties(value);
}

const getLocationValues = (content) => {
  const value = {
    user    : getAuditLogUser(content),
    name    : getName(content),
    prevName: content.previousValue,
    entityName: getEntityName(content)
  }

  return removeEmptyProperties(value);
}

const getName = (content) => {
  const { operation, location, newValue } = content;
  const isAssignOrUnassignedLocation = operation === 4 || operation === 5;
  return isAssignOrUnassignedLocation ? location.name : newValue;
}

export const formatAuditLogs = (contents) => {
  const currentLanguageCode = cookies.get('i18next') || 'en';
  moment.locale(currentLanguageCode);

  return contents.map(content => {
    const { timestamp, operation } = content;
    const dateTime = {
      date : moment(timestamp).format('MMMM DD, YYYY'),
      time  : moment(timestamp).format('h:mmA')
    }

    let key = getAreaKey(operation);
    let value = getAreaValues(content)
    if (content.locationId) {
      key = getLocationKey(operation, content);
      value = getLocationValues(content)
    }

    return {
      timestamp: dateTime,
      value    : value,
      key      : key,
    }
  });
}