import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  container: {
    paddingBottom : theme.spacing(2),
    paddingTop    : theme.spacing(2)
  },
  containerEvent: {
    paddingBottom : theme.spacing(1),
    paddingTop    : theme.spacing(6)
  },
  containerLanguage: {
    paddingBottom : theme.spacing(1),
    paddingTop    : theme.spacing(2)
  },
  containerButtonSpace: {
    paddingBottom : theme.spacing(1),
    paddingTop    : theme.spacing(6)
  },
  containerController: {
    paddingBottom : theme.spacing(1),
    paddingTop    : theme.spacing(6)
  },
  root: {
    width : '100%',
  },
  form: {
    display        : 'flex',
    flexDirection  : 'row',
    justifyContent : 'space-between',
    padding        : theme.spacing(2)
  },
  formControl: {
    margin   : theme.spacing(1),
    minWidth : 120
  },
  paper: {
    marginBottom : theme.spacing(2),
    '& .MuiTextField-root': {
      margin : theme.spacing(0)
    }
  },
  icon: {
    color        : theme.palette.primary.dark,
    fontSize     : theme.spacing(3),
    marginBottom : theme.spacing(-.5)
  },
  hrDivider: {
    border    : 0,
    borderTop : '1px dotted #767779',
    float     : 'right',
    width     : '100%'
  },
  event: {
    display        : 'flex',
  },
  updateCancelButtonContainer: {
    paddingBottom : theme.spacing(.5),
    paddingTop    : theme.spacing(.5)
  },
  updateCancelButton: {
    display      : 'inline',
    paddingRight : theme.spacing(1)
  },
  updateButton: {
    display : 'inline',
    width   : '148px'
  },
  paperContent: {
    padding: theme.spacing(2)
  },
  contentSpacing: {
    marginBottom: theme.spacing(1),
    marginRight : theme.spacing(2),
    marginTop   : theme.spacing(1),
  },
  selectWidth: {
    marginRight: theme.spacing(10),
    width      : '250px',
  },
  configurationWidth: {
    marginRight: theme.spacing(3),
    width      : '80px',
  },
  centerText: {
    alignItems    : 'center',
    display       : 'flex',
    justifyContent: 'center',
  },
  errorStyle: {
    fontSize  : '12px',
    marginTop : theme.spacing(1),
  },
  errorText: {
    color     : colors.ERROR,
  }
}));