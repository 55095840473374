import { Container } from '@material-ui/core';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Table, { createColumn } from '../../components/table';
import Title from '../../components/title';
import { LocationContext } from '../../context/locationContext';
import { deleteAccessPoint, getAccessPoint, updateAccessPoint } from '../../service/accessPointsApi';
import { multipleRequest } from '../../service/requests';
import { ACCESS_POINTS, ACCESS_POINTS_MODULE, ACCESS_POINT_MODULE, ALL, API_REQUEST_ERROR_MESSAGE, ASCENDING, DATE_FORMAT, NAME, READ_MODE } from '../../utility/constants';
import { parseIntegerParam, updateURLParams } from '../../utility/helper';
import useStyles from './styles';

const columns = [
  createColumn("name", "accessPointName", true, "string", false, true),
  createColumn("id", "ID", false, "numeric", true),
  createColumn("profiles", "usedInProfiles", false, "component"),
  createColumn("entries", "readers", false, "component"),
  createColumn("location", "location", true, "string"),
  createColumn("doorAccess", "actions", false, "string"),
];

const AccessPoints = (props) => {
  const classes       = useStyles();
  const history       = useHistory();
  const { t }         = useTranslation();

  const { search, pathname } = history.location;

  const params      = new URLSearchParams(search);
  const query       = params.get('keyword') ? params.get('keyword') : '';
  const pageSize    = params.get('size') ? parseIntegerParam(params.get('size'), 10) : 10;
  const pageNumber  = params.get('page') ? parseIntegerParam(params.get('page'), 1) : 1;
  const pageType    = params.get('accessPoints') ? params.get('accessPoints') : ALL.toLowerCase();
  const totalSize   = pageSize > 100 ? 100 : pageSize;

  const { state : locationState }                  = useContext(LocationContext);
  const { selectedLocationIds }                    = locationState;
  const { showToaster, handlePermissions }         = props;
  const [isLoading, setIsLoading]                  = useState(false);
  const [rowsPerPage, setRowsPerPage]              = useState(totalSize);
  const [page, setPage]                            = useState(pageNumber === 0 ? 1 : pageNumber);
  const [accessPoints, setAccessPoints]            = useState([]);
  const [totalAccessPoints, setTotalAccessPoints]  = useState(0);
  const [orderBy, setOrderBy]                      = useState(NAME);
  const [order, setOrder]                          = useState(ASCENDING);
  const [keyword, setKeyword]                      = useState(query);
  const [title, setTitle]                          = useState('');
  const [listType, setListType]                    = useState(pageType);

  const urlParams = useMemo(() => ({
    keywordParam :  keyword ? `&name=${keyword}` : '',
    pageParam    : `page=${page}`,
    sizeParam    : `&size=${rowsPerPage}`,
    sortParam    : `&sort=${orderBy},${order}`,
    history      : history,
    pathname     : pathname
  }), [keyword, page, rowsPerPage, orderBy, order, history, pathname]);

  const getAccessPoints = useCallback(async (status = true) => {
    setIsLoading(status);

    try {
      const response = await getAccessPoint(keyword, rowsPerPage, page, orderBy, order, selectedLocationIds);

      const { number, size, totalElements, totalPages } = response.page;
      const { accessPoints }                            = response;
      
      const totalNumber = number > totalPages ? totalPages : number;

      if (accessPoints.length || number === 0) {
        const newAccessPoints = accessPoints.map(accessPoint => {
          const { name, readers, profiles, controller, location, id } = accessPoint;
  
          let profileData = null;
          let entries     = null;
          if (profiles != null) {
            profileData = (
              profiles.map(profile => {
                return ({
                  name        : profile.name,
                  description : `${moment(profile.validFrom).format(DATE_FORMAT)} - ${moment(profile.validUntil).format(DATE_FORMAT)}`
                })
              })
            );
          }
      
          if (readers != null) {
            entries = (
              readers.map(reader => {
                return ({
                  name : reader.name,
                  description: t(READ_MODE[reader.readMode]) ?? ''
                })
              })
            );
          }
      
          return {
            name        : `${controller.name}:${name}`,
            id          : id,
            profiles    : profileData,
            entries     : entries,
            location    : location ? location.name : '-',
            doorAccess  : true
          }
        });

        setAccessPoints(newAccessPoints);
        setPage(totalNumber + 1);
      } else {
        setPage(totalNumber);
      }
      
      setRowsPerPage(size);
      setTotalAccessPoints(totalElements);
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setIsLoading(false);
    }

  }, [showToaster, t, keyword, page, rowsPerPage, order, orderBy, selectedLocationIds]);

  const getData = useCallback(() => {
    if (listType === ALL.toLowerCase()) {
      setTitle(ACCESS_POINTS);
      getAccessPoints();
    } else {
      setListType(ALL.toLowerCase());
    }
  }, [listType, getAccessPoints]);

  useEffect(() => {
    let delayDebounce;
    
    
    if (keyword) {
      setIsLoading(true);
      delayDebounce = setTimeout(() => {
        updateURLParams(urlParams);
        getData();
      }, 1000);
    } else {
      updateURLParams(urlParams);
      getData();
    }
    
    return () => {
      delayDebounce && clearTimeout(delayDebounce)
    }
  }, [page, rowsPerPage, order, orderBy, keyword, listType, selectedLocationIds, urlParams, getData]);


  const handleSearch = (value) => {
    setPage(1);
    setKeyword(value);
  }

  const handleClearSearch = () => {
    setPage(1);
    setKeyword('');
  }

  const handleSort = (newOrderBy, newOrder) => {
    setPage(1);
    setOrderBy(newOrderBy);
    setOrder(newOrder);
  }

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setPage(1);
    setRowsPerPage(newRowsPerPage);
  }

  const handleChangePage = (newPage) => {
    if (isLoading) {
      return;
    }
    
    setPage(newPage + 1);
  }

  const handleCreate = () => {
    showToaster(t('success'), `${t('underDevelopment')}`, 'success');
  }

  const handleUpdate = (value) => {
    showToaster(t('success'), `${t('underDevelopment')}`, 'success');
  }

  const handleView = (row) => {
    history.push(`/accessPoints/view/${row.id}`);
  }

  const handleChangeStatus = (newValue, rowValue) => {
    showToaster(t('success'), `${t('underDevelopment')}`, 'success');
  }

  const handleDoorMode = async (newValue, rowValue) => {
    const { id, name } = rowValue;
    const requestData = {
      doorMode : newValue
    }
    try {
      await updateAccessPoint(id, requestData)
      getAccessPoints(false);
      showToaster(t('success'), `${name} ${t('doorModeSetTo')} ${newValue} ${t('doorModeSetTo2')} `, 'success');
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }
  }

  const handleDelete = async (value) => {
    try { 
      await deleteAccessPoint(value.id);
      getAccessPoints();
      showToaster(t('success'), `${value.name} ${t('hasBeenDeleted')}`, 'success');
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }
  }

  const handleMultipleDelete = async (values) => {
    const ids   = values.map(value => value.id);
    const names = values.map(value => value.name);
    const lastAccessPointName = names.pop();
  
    try {
      await multipleRequest([
        ids.map(id =>
          deleteAccessPoint(id)
        )
      ]);  
      showToaster(t('success'), `${names.join(', ')}, ${t('and')} ${lastAccessPointName} ${t('hasBeenDeleted')}`, 'success');
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      getAccessPoints();
    }
  }

  const handleType = () => {
    setListType(ALL.toLowerCase());
  }
  
  return (
    <Container maxWidth="xl" className={classes.container}>
      <Title title={t(title)} listType={listType}/>
      <Table
        columns={columns}
        data={accessPoints}
        handleDoorMode={handleDoorMode}
        isLoading={isLoading}
        keyword={keyword}
        label={ACCESS_POINT_MODULE}
        module={ACCESS_POINTS_MODULE}
        onChangeDoorMode={handleDoorMode}
        onChangePage={handleChangePage}
        onChangeStatus={handleChangeStatus}
        onClearSearch={handleClearSearch}
        onCreate={handleCreate}
        onDelete={handleDelete}
        onMultipleDelete={handleMultipleDelete}
        onRowsPerPageChange={handleRowsPerPageChange}
        onSearch={handleSearch}
        onSort={handleSort}
        onUpdate={handleUpdate}
        onView={handleView}
        order={order}
        orderBy={orderBy}
        page={page}
        rowsPerPage={rowsPerPage}
        totalItems={totalAccessPoints}
        viewKey={"name"}
        listType={listType}
        onViewAll={handleType}
        handlePermissions={handlePermissions}
      />
    </Container>
  );
}

export default AccessPoints;