import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../theme/colors';

export default makeStyles((theme) => ({
  input: {
    marginBottom    : theme.spacing(0.5),
    width           : '100%',
  },
  csvItems: {
    height          : '72px',
    margin          : theme.spacing(4),
    marginBottom    : theme.spacing(6),
  },
  csvActions: {
    alignItems      : 'center',
    display         : 'flex',
    height          : theme.spacing(8),
    padding         : theme.spacing(2),
    paddingLeft     : theme.spacing(3),
    paddingRight    : theme.spacing(3),
    margin          : '0 auto',
  },
  dialogActions: {
    alignItems      : 'center',
    backgroundColor : colors.LIGHT_GRAY,
    direction       : 'row',
    display         : 'flex',
    height          : theme.spacing(8),
    padding         : theme.spacing(2),
    margin          : '0 auto',
  },
  actionButton: {
    margin          : '4px',
    width           : '100%',
  }
}));