import React, { useState, useContext, useEffect, forwardRef, useImperativeHandle } from 'react'
import { Container, Paper, Box, TableHead, TableRow, TableCell, TableSortLabel, TableContainer, TableBody, Checkbox, Table } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';
import clsx from 'clsx';
import { DATE_FORMAT } from '../../../../utility/constants';
import moment from 'moment';
import { WizardContext } from '../../../../context/wizardContext';
import { WizardCredentialContext } from '../../../../context/wizardCredentialContext';
import { SET_CREDENTIAL_UPDATES } from '../../../../reducer/wizardCredentialReducer';
import { SET_ERROR } from '../../../../reducer/wizardReducer';
import CredentialsTableSkeleton from '../wizard-skeleton';

const creatHeadCells = (id, disablePadding, label, active) => {
  return {
    id            : id,
    disablePadding: disablePadding,
    label         : label,
    active        : active,
  }
}

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  creatHeadCells('credentialNumber', true, 'credentialNumber', false),
  creatHeadCells('user', false, 'user', true),
  creatHeadCells('validFrom', false, 'validFrom', false),
  creatHeadCells('validUntil', false, 'validUntil', false),
  creatHeadCells('location', false, 'location', false)
];

const SortHeader = (props) => {
  const classes = useStyles();
  const { active, columnId, getSortOrderDirection } = props;

  switch (getSortOrderDirection(columnId)) {
    case 'asc':
      return (
        <ArrowUpward
          size="small"
          className={clsx({
            [classes.activeIcon]: active,
            [classes.inactiveIcon]: !active,
          })}
        />
      )
    case 'desc':
      return (
        <ArrowDownward
          size="small"
          className={clsx({
            [classes.activeIcon]: active,
            [classes.inactiveIcon]: !active,
          })}
        />
      )
    default: 
      return (
        <></>
      )
  }
}

const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const classes = useStyles();
  const { t }   = useTranslation();

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
    const index = headCells.findIndex(obj => obj.id === property)
    headCells.map((column) => {
      column.active = false
      return null
    })
    headCells[index].active = true
  };

  const getSortOrderDirection = (id) => {
    return (orderBy === id) ? order : 'asc';
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              direction={getSortOrderDirection(headCell.id)}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={true}
            >
              <Box className={classes.columnHeader}>
                <Box className={classes.columnItem}>
                  {t(headCell.label)}
                </Box>
                <Box>
                  <SortHeader
                    columnId={headCell.id}
                    active={headCell.active}
                    getSortOrderDirection={getSortOrderDirection}
                  />
                </Box>
              </Box>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const TableContent = (props) => {
  const { data, selected, setSelected }  = props;

  const [order, setOrder]       = useState('asc');
  const [orderBy, setOrderBy]   = useState('name');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <Paper elevation={3}>
      <TableContainer>
        <Table
          aria-labelledby="tableTitle"
          size="medium"
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
          />
          <TableBody>
            {
              data.length > 0 ?
                stableSort(data , getComparator(order, orderBy)).map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          size="small"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                      >
                        {row.name}
                      </TableCell>
                      <TableCell >{row.user}</TableCell>
                      <TableCell >{row.validFrom}</TableCell>
                      <TableCell >{row.validUntil}</TableCell>
                      <TableCell >{row.location}</TableCell>
                    </TableRow>
                  );
                })
              :
                <CredentialsTableSkeleton/>
            }
          </TableBody>

        </Table>
      </TableContainer>
    </Paper>
  );
}

const CredentialWizard = forwardRef((props, ref) => {
  const classes = useStyles();

  const wizardContext       = useContext(WizardContext);
  const credentialContext   = useContext(WizardCredentialContext);
  const { state, dispatch } = credentialContext;

  const { credentialValues, credentialUpdate } = state;

  const [data, setData]          = useState([]);
  const [selected, setSelected]  = useState([]);

  useEffect(() => {
    const dataTable = credentialValues.credentials?.map(credential => {
      const { active, credentialNumber, location, validFrom, validUntil } = credential.data;
      
      return {
        active     : active,
        name       : credentialNumber,
        user       : credentialValues.user.label,
        validFrom  : moment(validFrom).format(DATE_FORMAT),
        validUntil : moment(validUntil).format(DATE_FORMAT),
        location   : location.name
      }
    });

    const activeCredentials = dataTable?.filter(credential => credential.active);

    setData(activeCredentials ?? []);
    setSelected(credentialUpdate ? credentialUpdate : []);
  }, [credentialUpdate, credentialValues]);

  useImperativeHandle(ref, () => ({
    onNextPage() {
      handleSelectCredentials();
      wizardContext.dispatch({ type: SET_ERROR, payload: false });
    }
  }));

  const handleSelectCredentials = () => {
    dispatch({ type: SET_CREDENTIAL_UPDATES , payload: selected });
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <TableContent data={data} selected={selected} setSelected={setSelected}/>
    </Container>
  )
});

export { CredentialWizard }