import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  filter: {
    border      : '1px solid grey',
    borderRadius: theme.spacing(0.5),
    height      : theme.spacing(5),
    width       : '12vw',
    '& .MuiSelect-root': {
      padding : theme.spacing(2),
    },
    '& .MuiSelect-root:focus': {
      backgroundColor: 'transparent'
    }
  },
  selectedItem: {
    backgroundColor: `${colors.PALE_BLUE} !important`,
    width          : '12vw'
  },
}));