import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  container: {
    paddingTop   : theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(1),

    '& .MuiTextField-root': {
      marginTop: theme.spacing(0),
    },
    '& .Mui-disabled': {
      pointerEvents: 'none',
    },
    '& .MuiInputBase-input': {
      color: colors.BLACK,
    }
  },
  editIcon: {
    backgroundColor: theme.palette.primary.main,
    borderRadius   : theme.spacing(0.5),
    color          : theme.palette.common.white,
    display        : 'inline',
  },
  form: {
    padding     : theme.spacing(1),
    paddingLeft : theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  dropdown: {
    zIndex: 10,
  },
  status: {
    '& .MuiInputBase-root:hover':{
      cursor: 'pointer',
    },
    '& .MuiInputBase-input:hover':{
      cursor: 'pointer',
    }
  },
  action: {
    backgroundColor: theme.palette.grey[100],
    padding        : theme.spacing(2),
    textAlign      : 'right',

    '& .MuiButton-root:first-of-type': {
      marginRight: theme.spacing(2)
    }
  },
  alignCheckbox: {
      paddingTop : theme.spacing(1),
  }
}));