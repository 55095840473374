import { GET, PATCH } from "../utility/constants";
import api from "./api";
import { request } from "./requests";

export const getControllerConfigurations = async () => {
  const response = await request({
    url     : api.CONTROLLER_CONFIGURATIONS,
    method  : GET
  });

  return response.data[0].requestLimit
}

export const updateControllerConfiguration = async (newRequestLimit) => {
  await request({
    url     : `${api.CONTROLLER_CONFIGURATIONS}/1`,
    method  : PATCH,
    data  : {
      requestLimit: newRequestLimit,
    }
  });
}