import React, { Children, useContext } from "react";
import { Button, Grid } from '@material-ui/core';
import useStyles from './styles';
import clsx from 'clsx';
import { useTranslation } from "react-i18next";
import { WizardContext } from "../../context/wizardContext";

const Wizard = (props) => {
  const classes = useStyles();
  const { handleClose, handleSaveInfo, children, handleNextPage, handlePreviousPage } = props
  const { state }      = useContext(WizardContext);
  const { t } = useTranslation();

  const pages = Children.toArray(children);
  const currentPage = pages[state.currentPage];

  const ButtonPrev = ({ isHidden }) =>{
    return  (
      <Button
        id="wizardPreviousButton"
        color="primary"
        variant="outlined"
        type="button"
        className={clsx(isHidden && 'hidden')}
        onClick={handlePreviousPage}
      >
        {t('back')}
      </Button>
    )
  }

  const ButtonNext = () => {
    return (
      <>
      {
        state.isEndPage ?
          <Button
            id="wizardSaveButton"
            color="primary"
            variant="contained"
            type="button"
            className={classes.nextButton}
            onClick={handleSaveInfo}>
            {t('save')}
          </Button>
        :
          <>
            <Button
              id="wizardNextButton"
              disabled={state.isNextDisabled}
              className={`${clsx((state.isNextHidden) && 'hidden')} ${classes.nextButton}`}
              color="primary"
              variant="contained"
              type="button"
              onClick={handleNextPage}
            >
              {t('next')}
            </Button>
              <ButtonPrev isHidden={!state.isNextHidden}/>
          </>
      }
      </>
    )
  }

  return (
    <div>
      <Grid className={classes.wizardContent}>{currentPage}</Grid>
      <Grid className={classes.wizardButtons}>
        <Grid item>
          <Button
            id="wizardCancelButton"
            color="primary"
            type="button"
            variant="outlined"
            onClick={handleClose}
          >
            {t('cancel')}
          </Button>
        </Grid>
        <Grid className={classes.wizardButtonsRight} >
          <ButtonPrev isHidden={state.isNextHidden} />
          <ButtonNext/>
        </Grid>
      </Grid>
    </div>
  );
};

export default Wizard;
