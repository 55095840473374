import { Box, Paper } from "@material-ui/core";
import { TEXT_FIELD_SKELETON } from "../../../utility/constants"
import useStyles from './styles';
import { Skeleton } from "@material-ui/lab";

const DeleteModalSkeleton = () => {
  const classes = useStyles()

  return (
    <>
      <Paper className={classes.deleteModal}>
        <Box className={classes.dialogImage}>
          <Skeleton variant="rect" className={classes.image} />
        </Box>
        <Box className={classes.dialogTitle}>
          <Skeleton height={TEXT_FIELD_SKELETON} />
          <Skeleton height={TEXT_FIELD_SKELETON} />
        </Box>
      </Paper>
      <Box className={classes.dialogActions}>
        <Skeleton variant="rect" />
      </Box>
    </>
  )
}

export { DeleteModalSkeleton };