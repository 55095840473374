import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import useStyles from './styles';
import { IconButton, Box, Checkbox, FormControlLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ALL } from '../../utility/constants';

const ParentItem = (props) => {
  const { data, handleParentDropdownChange, handleParentClick, handleChildClick, disabled, id } = props;

  const classes = useStyles();
  const { t }   = useTranslation();

  return (
    <Box>
      {
        data.map((data, i) => {
          return (
            <span key={i}>
              <Box className={classes.parentStyle}>
                <IconButton id={`${id}${data.parentId}CollapseButton`} size="small" onClick={() => handleParentDropdownChange(data.parentId)}>
                  <FontAwesomeIcon icon={data.isCollapsed ? faAngleDown: faAngleRight}/>
                </IconButton>
                <FormControlLabel 
                  id={`${id}${data.parentId}Label`}
                  label={t(data.name)}
                  className={classes.parentTypographStyle}
                  control={
                    <Checkbox
                      id={`${id}${data.parentId}Checkbox`}
                      className={classes.parentCheckbox}
                      size='small'
                      checked={data.state}
                      indeterminate={data.indeterminate}
                      onClick={() => handleParentClick(data.parentId)}
                      color='primary'
                      name={t(data.name)}
                      disabled={disabled}
                    />
                  }
                />
              </Box>
              <ChildItem 
                id={id}
                data={data}
                handleChildClick={handleChildClick}
                disabled={disabled}
                parentId={data.parentId}
              />
            </span>
          )
        })
      }
    </Box>
  )
}

const ChildItem = (props) => {
  const { data, handleChildClick, disabled, parentId, id } = props;

  const classes = useStyles();
  const { t }   = useTranslation();
  
  return (
    <>
      {
        data.isCollapsed ? 
          data.childList.map((list, i) => (
            <Box className={classes.childStyle} key={i}>
              <FormControlLabel
                id={`${id}${parentId}${list.name}Label`}
                label={t(list.name)}
                className={classes.childTypographStyle}
                control={
                  <Checkbox
                  id={`${id}${parentId}${list.name}Checkbox`}
                  size='small'
                  name={t(list.name)}
                  checked={list.state}
                  onClick={() => handleChildClick(parentId, list.name, list.state)}
                  color='primary'
                  disabled={disabled}
                  />
                }
                />
            </Box>
          ))
        :
          <></>
      }
    </>
  )
}

const CollapsibleList = (props) => {
  const { data, handleCategoryDropdownChange, handleCategoryClick, handleParentDropdownChange, handleParentClick, handleChildClick, disabled, id } = props;
  
  const classes = useStyles();
  const { t }   = useTranslation();
  
  const collapsibleListLabel = data.categoryName ? data.categoryName : t(ALL.toLowerCase());
  let collapsibleListLabelElementId = collapsibleListLabel.charAt(0).toUpperCase() + collapsibleListLabel.slice(1);
  collapsibleListLabelElementId = collapsibleListLabelElementId.replace(/\s+/g, '');
  
  return (
    <Box>
      <Box className={classes.categoryStyle}>
        <IconButton id={`${id}${collapsibleListLabelElementId}CollapseButton`} size="small" onClick={() => handleCategoryDropdownChange(data.isCollapsed)}>
          <FontAwesomeIcon icon={data.isCollapsed ? faAngleDown : faAngleRight}/>
        </IconButton>
        <FormControlLabel 
          id={`${id}${collapsibleListLabelElementId}Label`}
          label={collapsibleListLabel}
          className={classes.categoryTypographStyle}
          control={
            <Checkbox
              id={`${id}${collapsibleListLabelElementId}Checkbox`}
              className={classes.categoryCheckbox}
              size='small'
              checked={data.state}
              indeterminate={data.indeterminate}
              onClick={() => handleCategoryClick(data.state)}
              color='primary'
              name={data.category}
              disabled={disabled}
            />
          }
        />
      </Box>
      {
        data.isCollapsed ? 
          <ParentItem 
            id={id}
            data={data.parent}
            handleParentDropdownChange={handleParentDropdownChange}
            handleParentClick={handleParentClick}
            handleChildClick={handleChildClick}
            disabled={disabled}
          />
        :
          <></>
      }
    </Box>
  );
}

export default CollapsibleList;