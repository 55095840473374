import moment from "moment";
import colors from "../../../theme/colors";
import { ACCESS_CHECK, ALL_TYPES, CONTAINER, CONTROLLERS, CONTROLLER_OFFLINE, CREDENTIALS, DATE, DATE_FORMAT_YYYY_MM_DD, DEVICE_REQUEST, DOOR, EMA, EVENTS, IMPORT_EXPORT, INVALID_CREDENTIALS, OFF, READER, REMOTE_CONTROL, SDDL, SERVICE, UPDATE_DAEMON_TYPE, USERS, USER_INVALID_DATE } from "../../../utility/constants";

export const chartType = (chart) => {
  return {
    type: chart,
    spacingTop: 35,
    spacingLeft: 10,
    spacingRight: 10,
    renderTo: CONTAINER,
  }
}

export const moduleName = (chipName) => {
  switch (chipName) {
    case USER_INVALID_DATE:
      return USERS;
    case 'availableCredentials':
      return CREDENTIALS;
    case 'invalidCredentials':
      return CREDENTIALS;
    case CONTROLLER_OFFLINE:
      return CONTROLLERS;
    case 'accessDenied':
      return EVENTS;
    default:
      return;
  }
}

export const chartTitle = (title) => {
  return {
    text: title,
    margin: 30
  }
}

export const modules = (name, url, countUrl ) => {
  return {
    name    : name,
    url     : url,
    countUrl: countUrl,
  }
}

export const createChips = (name, icon, url, params, data) => {
  const parameters = name === INVALID_CREDENTIALS ?
  {
    validUntilDate : moment().format(DATE_FORMAT_YYYY_MM_DD),
    validFromDate  : moment().format(DATE_FORMAT_YYYY_MM_DD)
  }
  :
  {
    date  : moment().format(DATE_FORMAT_YYYY_MM_DD)
  }

  return {
    name     : name,
    icon     : icon,
    url      : url,
    params   : params === DATE ?
      parameters
    :
      {
       status: OFF
      },
    data     : data
  }
}

export const typeOption = [ALL_TYPES, DOOR, READER, ACCESS_CHECK, EMA, SERVICE, REMOTE_CONTROL, IMPORT_EXPORT, SDDL, UPDATE_DAEMON_TYPE, DEVICE_REQUEST]

export const colorObjects = [colors.PRIMARY, colors.CHART_ORANGE_LIGHT, colors.CHART_GREEN_LIGHT, colors.CHART_INDIGO, colors.CHART_OLIVE]
export const colorStats = [colors.PRIMARY, colors.CHART_GREEN,  colors.CHART_ORANGE_LIGHT, colors.CHART_GREEN_LIGHT, colors.CHART_INDIGO , colors.CHART_OLIVE]