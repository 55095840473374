import React from 'react'
import useStyles from './styles';
import { Trans, useTranslation } from 'react-i18next';
import { Container, Grid, Typography } from '@material-ui/core';
import images from '../../utility/images';

const Error401 = (props) => {
  const { email } = props;
  const classes = useStyles();
  const { t }   = useTranslation();

  return (
    <Container className={classes.container}>
      <Grid container className={classes.flex}>
        <Grid item className={classes.imageContainer}>
          <img src={images.NO_ROLES_ASSIGNED} alt="no-roles-assigned" className={classes.image}/>
        </Grid>
        <Grid item className={classes.content}>
          <Typography className={classes.errorCode}>{t('error-401-page.errorCode')}</Typography>
          <Typography className={classes.error}>{t('error-401-page.error')}</Typography>
          <Typography component={'span'} className={classes.subText}>
            <Trans
              i18nKey={'error-401-page.subText'}
              values={{ email: email }}
              components={ <span data-testdata-testid='user-email' className={classes.label}></span> }
            />
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Error401