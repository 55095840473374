import { Avatar, Button, Container, Grid, IconButton, InputAdornment, Link, Paper, TextField, Typography } from '@material-ui/core';
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useKeycloak } from '@react-keycloak/web';
import { Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory } from 'react-router';
import RedirectModal from '../../components/redirect-modal';
import Title from '../../components/title';
import UnsavedModal from '../../components/unsaved-modal';
import Version from '../../components/version';
import { AuthContext } from '../../context/authContext';
import { sendResetPasswordEmail, updateAdministratorPassword } from '../../service/administratorsApi';
import { API_REQUEST_ERROR_MESSAGE } from '../../utility/constants';
import images from '../../utility/images';
import { myAccountPasswordSchema } from '../../validation/schema';
import useStyles from './styles';

const Content = (props) => {
  const classes = useStyles();
  const { t }   = useTranslation();
  const { administrator } = props;

  return (
    <Paper className={classes.paper} elevation={3}>
      <Grid className={classes.paper} container alignItems="center" direction="row">
        <Grid
          item
          container
          direction="column"
          justify="center"
          alignItems="center"
          xs={12} sm={12} md={12} lg={12}
        >
          <Avatar className={classes.avatar}>{administrator?.firstName?.slice(0, 1).toUpperCase()}</Avatar>
          <Version />
        </Grid>
      </Grid>
      <Grid className={classes.formTitle} item xs={12}>
        <Typography className={'bold'} color="secondary">{t('personalInformation')}</Typography>
      </Grid>
      <Grid container spacing={2} className={classes.form}>
        <Grid item xs={12} sm={5} md={4} lg={4}>
          <TextField
            id="myAccountFirstName"
            label={t('firstName')}
            name="firstName"
            autoCapitalize="words"
            value={administrator?.firstName}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={5} md={4} lg={4}>
          <TextField
            id="myAccountLastName"
            label={t('lastName')}
            name="lastName"
            autoCapitalize="words"
            value={administrator?.lastName}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={5} md={4} lg={4}>
          <TextField
            id="myAccountEmail"
            label={t('emailAddress')}
            name="email"
            value={administrator?.email}
            fullWidth
            disabled
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

const Password = (props) => {
  const classes                 = useStyles();
  const { t }                   = useTranslation();
  const { keycloak }            = useKeycloak()
  const { token }               = keycloak;
  const { state: authContext }  = useContext(AuthContext);
  const { administrator }       = authContext;
  
  const { handleSubmitPassword, setCurrentPassword, setNewPassword, setConfirmPassword, initialPasswordValues, handleCancel, handleClickShowPassword, currentPasswordShown, newPasswordShown, confirmPasswordShown, showToaster } = props;
  
  const handleChange = (setter, formik) => {
    return(setter, formik);
  }
  
  const forgotPassword = async () => {
    try {
      await sendResetPasswordEmail(token, administrator.administratorId);
      showToaster(t('success'), t('my-account-page.resetPasswordDescription'), ('success'));
    } catch (error) {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }
  }

  return (
    <Formik
      enableReinitialize
      onSubmit={handleSubmitPassword}
      initialValues={initialPasswordValues}
      validationSchema={myAccountPasswordSchema}
    >
      {
        formik => (
          <Form>
            <Paper className={classes.paper} elevation={3}>
              <Grid className={classes.paper} container alignItems="center" direction="row">
              </Grid>
              <Grid className={classes.formTitle} item xs={12}>
                <Typography className={'bold'} color="secondary">{t('password')}</Typography>
              </Grid>
              <Grid container spacing={2} className={classes.form}>
                <Grid item xs={12} sm={5} md={4} lg={4}>
                  <TextField
                    id="myAccountCurrentPassword"
                    label={t('currentPassword')}
                    type={currentPasswordShown ? 'text' : 'password'}
                    name="currentPassword"
                    onChange={e => handleChange(setCurrentPassword(e.target.value), formik.handleChange(e))}
                    value={formik.values.currentPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword('Current')}
                            edge="end"
                          >
                            {currentPasswordShown ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    error={formik.touched.currentPassword && Boolean(formik.errors.currentPassword)}
                    helperText={t(formik.touched.currentPassword) && t(formik.errors.currentPassword)}
                    fullWidth />
                </Grid>
                <Grid item xs={12} sm={5} md={4} lg={4}>
                  <TextField
                    id="myAccountNewPassword"
                    label={t('newPassword')}
                    type={newPasswordShown ? 'text' : 'password'}
                    name="newPassword"
                    onChange={e => handleChange(setNewPassword(e.target.value), formik.handleChange(e))}
                    value={formik.values.newPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword('New')}
                            edge="end"
                          >
                            {newPasswordShown ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                    helperText={t(formik.touched.newPassword) && t(formik.errors.newPassword)}
                    fullWidth />
                </Grid>
                <Grid item xs={12} sm={5} md={4} lg={4}>
                  <TextField
                    className={classes.confirmPassword}
                    id="myAccountConfirmPassword"
                    label={t('confirmPassword')}
                    type={confirmPasswordShown ? 'text' : 'password'}
                    name="confirmPassword"
                    onChange={e => handleChange(setConfirmPassword(e.target.value), formik.handleChange(e))}
                    value={formik.values.confirmPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword('Confirm')}
                            edge="end"
                          >
                            {confirmPasswordShown ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                    helperText={t(formik.touched.confirmPassword) && t(formik.errors.confirmPassword)}
                    fullWidth />
                </Grid>
                <Grid justify="flex-end" item xs={4} sm={4} md={4} lg={4} className={classes.forgotPassword}>
                  <Link id="myAccountForgotPasswordLink" variant="body2" onClick={forgotPassword} color="secondary">
                    {t('forgotPassword')}?
                  </Link>
                </Grid>
              </Grid>

              <Grid justify="flex-end" className={classes.updateCancelButtonContainer} spacing={2}>
                <Grid className={classes.updateCancelButton} item xs={12}>
                  <Button
                    id="myAccountPasswordCancelButton"
                    className={classes.button}
                    variant="outlined"
                    color="primary"
                    onClick={handleCancel}
                  >
                    {t('cancel')}
                  </Button>
                </Grid>
                <Grid className={classes.updateCancelButton} item xs={12}>
                  <Button
                    id="myAccountPasswordSubmitButton"
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    {t('update')}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Form>
        )
      }
    </Formik>
  )
}

const PasswordContainer = (props) => {
  const classes             = useStyles();
  const { t }               = useTranslation();
  const [ value, setValue ]   = useState(0);
  const { handleShow, handleHide } = props;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  return (
    <Formik
    >
      {
        formik => (
          <Tabs className={classes.tab}
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          >
            <Tab id="myAccountPersonalInfoTab" onClick={handleHide} label={<span className={classes.tabLabel}>{t('personalInformation')}</span>}> </Tab>
            <Tab id="myAccountPasswordTab" onClick={handleShow} label={<span className={classes.tabLabel}>{t('password')}</span>}> </Tab>
          </Tabs>
        )
      }
    </Formik>

  )
}

const MyAccount = (props) => {
  const classes                   = useStyles();
  const { showToaster }           = props;

  const { state: authContext, logout }  = useContext(AuthContext);
  const { administrator }               = authContext;

  const history                   = useHistory();
  const { t }                     = useTranslation();
  const [currentPassword, setCurrentPassword]           = useState('')
  const [newPassword, setNewPassword]                   = useState('')
  const [confirmPassword, setConfirmPassword]           = useState('')
  const [isLoading, setIsLoading]                       = useState(false)
  const [showPassword, setShowPassword]                 = useState(false)
  const [checkChanges, setCheckChanges]                 = useState(false);
  const [showModal, setShowModal]                       = useState(false);
  const [toRedirect, setToRedirect]                     = useState('');
  const [currentPasswordShown, setCurrentPasswordShown] = useState(false);
  const [newPasswordShown, setNewPasswordShown]         = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [showSuccessModal, setShowSuccessModal]         = useState(false);

  const initialPasswordValues = {
    currentPassword : currentPassword,
    newPassword     : newPassword,
    confirmPassword : confirmPassword
  }

  const handleClickShowPassword = (value) => {
    if (value === 'Current') {
      setCurrentPasswordShown(!currentPasswordShown);
    } else if (value === 'New') {
      setNewPasswordShown(!newPasswordShown);
    } else {
      setConfirmPasswordShown(!confirmPasswordShown);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  }

  const handleModalSubmit = () => {
    setCheckChanges(false)
    history.push(toRedirect);
  }

  const handleModalCancel = () => {
    setCheckChanges(true);
    setShowModal(false);
  }

  const handleShow = () => {
    setShowPassword(true)
  }

  const handleHide = () => {
    setShowPassword(false)
  }

  const handleCancel = () => {
    history.push('/dashboard');
  }

  const handleSubmitPassword = async (values, formik) => {
    const { setErrors, setSubmitting} = formik;
    const data = {
      oldPassword: values.currentPassword,
      newPassword: values.newPassword
    }
  
    try {
      await updateAdministratorPassword(data);

      handleOpenSuccessModal();
    } catch(error) {
      if (error?.response?.status === 403) {
        setErrors({
          currentPassword : t('my-account-page.incorrectPassword')
        });
      } else if (error?.response?.status === 400) {
        const { error_description } = error.response.data;
        const errorMessage = error_description.split(': ');

        setErrors({
          newPassword: t('my-account-page.invalidPassword', { errorMessage : errorMessage[1] })
        });
      } else {
        showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
      }
    } finally {
      setIsLoading(false);
      setSubmitting(false);
    }
   }

  const handleOpenSuccessModal = () => {
    setShowSuccessModal(true);
  }

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
  }

  const redirectToLogin = () => {
    logout();
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <RedirectModal
        onClose={handleCloseSuccessModal}
        open={showSuccessModal}
        redirectToLogin={redirectToLogin}
        title={t('success')}
        description={t('updatedSuccessfullyYouWillBe')}
        buttonText={t('confirm')}
        image={images.UPDATE_CONTROLLER_SVG}
      />
      <UnsavedModal
        open={showModal}
        onClose={handleCloseModal}
        handleModalSubmit={handleModalSubmit}
        handleModalCancel={handleModalCancel}
      />
      <Prompt
        when={checkChanges}
        message={(location, action) => {
          if (action === 'PUSH') {
            setShowModal(true)
          }
          setCheckChanges(false);
          setToRedirect(location.pathname);
          return location.pathname.startsWith('/myaccount')
        }}
      />
      <Title title={t('myAccount')} />
      <Grid container>
        <Grid item>
          <PasswordContainer
            handleShow={handleShow}
            handleHide={handleHide}            
          />
        </Grid>
        {showPassword ?
          <Grid item xs={10} sm={10} md={10} lg={12}>
            <Password
              handleSubmitPassword={handleSubmitPassword}
              initialPasswordValues={initialPasswordValues}
              setCurrentPassword={setCurrentPassword}
              setNewPassword={setNewPassword}
              setConfirmPassword={setConfirmPassword}
              handleCancel={handleCancel}
              handleClickShowPassword={handleClickShowPassword}
              currentPasswordShown={currentPasswordShown}
              newPasswordShown={newPasswordShown}
              confirmPasswordShown={confirmPasswordShown}
              showToaster={showToaster}
            />
          </Grid>
          :
          <Grid item xs={10} sm={10} md={10} lg={12}>
            <Content
              administrator={administrator}
              isLoading={isLoading}
              setShowPassword={setShowPassword}
              showPassword={showPassword}
              handleShow={handleShow}
            />
          </Grid>
        }
      </Grid>
    </Container>
  )
}

export default MyAccount