import { Box, Container, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CollapsibleList from '../../components/collapsible-list';
import SearchWithAction from '../../components/search-with-action';
import Title from '../../components/title';
import { DEFAULT_ROLES, PERMISSION_ASSIGN, PERMISSION_MAINTAIN, PERMISSION_READ } from '../../utility/constants';
import useStyles from './styles';

const PermissionList = (props) => {
  const { data, handleCategoryDropdownChange, handleCategoryClick, handleParentDropdownChange, handleParentClick, handleChildClick } = props;
  const classes = useStyles();
  
  return (
    <Box className={classes.minMaxScroll}>
      <CollapsibleList
        id="systemRolesPermissions"
        data={data}
        handleCategoryDropdownChange={handleCategoryDropdownChange}
        handleCategoryClick={handleCategoryClick}
        handleParentDropdownChange={handleParentDropdownChange}
        handleParentClick={handleParentClick}
        handleChildClick={handleChildClick}
        disabled={true}
      />
    </Box>
  );
}

const Content = (props) => {
  const { useSearch, count, pageNumber, setPageNumber, handleItemClick, selectedItem, handleUpdateClick, handleCategoryDropdownChange, handleCategoryClick, handleParentDropdownChange, handleParentClick, handleChildClick, data, setSelectedItem, isLoading } = props;
  const classes = useStyles();
  const { t }   = useTranslation();

  return (
    <Grid container spacing={2} className={classes.form}>
      <Grid item xs={8}>
        <Box id="systemRolesContainer" className={classes.roleContainer}>
          <Box className={classes.boxContainer}>
            <Typography color="secondary">
              {t("allRoles")} (<span id="systemRolesCount">{count}</span>)
            </Typography>
          </Box>
          <SearchWithAction
            id="systemRoles"
            handleItemClick={handleItemClick}
            selectedItem={selectedItem}
            handleUpdateClick={handleUpdateClick}
            useSearch={useSearch}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            setSelectedItem={setSelectedItem}
            isLoading={isLoading}
          />
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box>
          <Box
            id="systemRolesPermissionsContainer"
            className={classes.permissionContainer}
          >
            <Box className={classes.permissionColumn}>
              <Typography
                className={`bold ${classes.gridItemsBorder}`}
                color="secondary"
              >
                {t("permissionList")}
              </Typography>
            </Box>
            <PermissionList
              count={count}
              data={data}
              handleCategoryDropdownChange={handleCategoryDropdownChange}
              handleCategoryClick={handleCategoryClick}
              handleParentDropdownChange={handleParentDropdownChange}
              handleParentClick={handleParentClick}
              handleChildClick={handleChildClick}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

const SYSTEM_ROLES = [
  {
    id          : 0,
    name        : DEFAULT_ROLES.SYSTEM_ADMIN,
    permissions : ['Person', 'Credential', 'Role', 'Profile', 'AccessPoint', 'Schedule', 'Holiday', 'Event', 'Controller', 'User', 'UserRole', 'Location']
  },
  {
    id          : 1,
    name        : DEFAULT_ROLES.DEVICE_ADMIN,
    permissions : ['Controller', 'Location', 'Event']
  },
  {
    id          : 2,
    name        : DEFAULT_ROLES.OPERATOR,
    permissions : ['Role', 'Profile', 'Schedule', 'Holiday', 'Event', 'Location']
  },
  {
    id          : 3,
    name        : DEFAULT_ROLES.PERMISSION_MANAGER,
    permissions : ['Credential', 'Person', 'Role', 'Event', 'Location']
  },
  {
    id          : 4,
    name        : DEFAULT_ROLES.EVENT_VIEWER,
    permissions : ['Event', 'Controller', 'Location']
  },
];

const PERMISSIONS = {
  category      : '',
  state         : false,
  indeterminate : true,
  isCollapsed   : true,
  parent        : [
    {
      parentId      : 'User',
      name          : 'administrator-role-page.user',
      state         : true,
      indeterminate : false,
      isCollapsed   : true,
      childList     : [
        {name: 'administrator-role-page.read', childId: '1', state: true},
        {name: 'administrator-role-page.maintain', childId: '2', state: true},
        {name: 'administrator-role-page.assign', childId: '3', state: true},
      ]
    },
    {
      parentId      : 'Credential',
      name          : 'administrator-role-page.credential',
      state         : true,
      indeterminate : false,
      isCollapsed   : true,
      childList     : [
        {name: 'administrator-role-page.read', childId: '1', state: true},
        {name: 'administrator-role-page.maintain', childId: '2', state: true},
        {name: 'administrator-role-page.assign', childId: '3', state: true},
      ]
    },
    {
      parentId      : 'Role',
      name          : 'administrator-role-page.role',
      state         : true,
      indeterminate : false,
      isCollapsed   : true,
      childList     : [
        {name: 'administrator-role-page.read', childId: '1', state: true},
        {name: 'administrator-role-page.maintain', childId: '2', state: true},
        {name: 'administrator-role-page.assign', childId: '3', state: true},
      ]
    },
    {
      parentId      : 'Profile',
      name          : 'administrator-role-page.profile',
      state         : true,
      indeterminate : false,
      isCollapsed   : true,
      childList     : [
        {name: 'administrator-role-page.read', childId: '1', state: true},
        {name: 'administrator-role-page.maintain', childId: '2', state: true},
        {name: 'administrator-role-page.assign', childId: '3', state: true},
      ]
    },
    {
      parentId      : 'AccessPoint',
      name          : 'administrator-role-page.accessPoint',
      state         : true,
      indeterminate : false,
      isCollapsed   : true,
      childList     : [
        {name: 'administrator-role-page.read', childId: '1', state: true},
        {name: 'administrator-role-page.maintain', childId: '2', state: true},
        {name: 'administrator-role-page.assign', childId: '3', state: true},
      ]
    },
    {
      parentId      : 'Schedule',
      name          : 'administrator-role-page.schedule',
      state         : true,
      indeterminate : false,
      isCollapsed   : true,
      childList     : [
        {name: 'administrator-role-page.read', childId: '1', state: true},
        {name: 'administrator-role-page.maintain', childId: '2', state: true},
        {name: 'administrator-role-page.assign', childId: '3', state: true},
      ]
    },
    {
      parentId      : 'Holiday',
      name          : 'administrator-role-page.holiday',
      state         : true,
      indeterminate : false,
      isCollapsed   : true,
      childList     : [
        {name: 'administrator-role-page.read', childId: '1', state: true},
        {name: 'administrator-role-page.maintain', childId: '2', state: true},
        {name: 'administrator-role-page.assign', childId: '3', state: true},
      ]
    },
    {
      parentId      : 'Event',
      name          : 'administrator-role-page.event',
      state         : true,
      indeterminate : false,
      isCollapsed   : true,
      childList     : [
        {name: 'administrator-role-page.read', childId: '1', state: true},
        {name: 'administrator-role-page.maintain', childId: '2', state: true},
        {name: 'administrator-role-page.assign', childId: '3', state: true},
      ]
    },
    {
      parentId      : 'Controller',
      name          : 'administrator-role-page.controller',
      state         : true,
      indeterminate : false,
      isCollapsed   : true,
      childList     : [
        {name: 'administrator-role-page.read', childId: '1', state: true},
        {name: 'administrator-role-page.maintain', childId: '2', state: true},
        {name: 'administrator-role-page.assign', childId: '3', state: true},
      ]
    },
    {
      parentId      : 'User',
      name          : 'administrator-role-page.administrator',
      state         : true,
      indeterminate : false,
      isCollapsed   : true,
      childList     : [
        {name: 'administrator-role-page.read', childId: '1', state: true},
        {name: 'administrator-role-page.maintain', childId: '2', state: true},
        {name: 'administrator-role-page.assign', childId: '3', state: true},
      ]
    },
    {
      parentId      : 'UserRole',
      name          : 'administrator-role-page.administratorRole',
      state         : true,
      indeterminate : false,
      isCollapsed   : true,
      childList     : [
        {name: 'administrator-role-page.read', childId: '1', state: true},
        {name: 'administrator-role-page.maintain', childId: '2', state: true},
        {name: 'administrator-role-page.assign', childId: '3', state: true},
      ]
    },
    {
      parentId      : 'Location',
      name          : 'administrator-role-page.location',
      state         : true,
      indeterminate : false,
      isCollapsed   : true,
      childList     : [
        {name: 'administrator-role-page.read', childId: '1', state: true},
        {name: 'administrator-role-page.maintain', childId: '2', state: true},
        {name: 'administrator-role-page.assign', childId: '3', state: true},
      ]
    },
  ]
}

const SystemRoles = () => {
  const classes = useStyles();
  const { t }   = useTranslation();

  const [selectedItem, setSelectedItem] = useState(0);
  const [permissions, setPermissions]   = useState(PERMISSIONS);

  useEffect(() => {    
    const updatedPermissions = {
      ...PERMISSIONS,
      parent: PERMISSIONS.parent.map((item) => {
                if (SYSTEM_ROLES[selectedItem].permissions.includes(item.parentId)) {
                  return item;
                } else {
                  return {
                    ...item,
                    state: false,
                    childList: item.childList.map((child) => {
                      return {
                        ...child,
                        state: false,
                      };
                    }),
                  };
                }
              }),
    };

    setPermissions(updatedPermissions);
  }, [selectedItem]);
  

  const useSearch = () => {
    return {
      isLoadingSelect : false,
      error           : false,
      items           : SYSTEM_ROLES
    }
  }

  const handleCategoryDropdownChange = (collapseState) => {
    const newCollapseState = {...permissions, isCollapsed: !collapseState}
    setPermissions(newCollapseState);
  }

  const handleCategoryClick = (currentState) => {
    const newCheckState = permissions.parent.map(parent => {
      const newChildList = parent.childList.map(child => {
        return {
          ...child,
          state: !currentState
        }
      })
      return {
        ...parent,
        state: !currentState,
        indeterminate: false,
        childList: newChildList
      }
    });

    const newCategoryState = {...permissions, state: !currentState, indeterminate: false, parent: newCheckState}
    setPermissions(newCategoryState);
  }

  const handleParentDropdownChange = (id) => {
    const updatedIsCollapsedState = permissions.parent.map(parent => {
      if (parent.parentId === id) {
        return {
          ...parent,
          isCollapsed: !parent.isCollapsed
        }
      }
      return parent;
    });

    setPermissions({...permissions, parent: updatedIsCollapsedState});
  }

  const handleParentClick = (id) => {
    const updatedParentState = permissions.parent.map(item => {
      if (item?.parentId === id) {
        const currentParentState = !item.state;
        const updatedChildState = item.childList.map(child => {
          return {
            ...child,
            state: currentParentState
          }
        })
        return {
          ...item,
          state           : currentParentState,
          indeterminate   : false,
          childList : updatedChildState
        }
      }

      return item;
    });

    updateCategoryState(updatedParentState);
  }

  const handleChildClick = (parentId, childName, currentState) => {
    const updatedParentState = permissions.parent.map(item => {
      if (item?.parentId === parentId) {
        const updatedChildState = item.childList.map(child => {
          if ((childName === t(PERMISSION_READ) && !currentState === false) || (childName === t(PERMISSION_MAINTAIN) && !currentState === false && child.name !== t(PERMISSION_READ))) {
            return {
              ...child,
              state: false
            }
          } else if ((childName === t(PERMISSION_MAINTAIN) && !currentState === true && child.name !== t(PERMISSION_ASSIGN)) || (childName === t(PERMISSION_ASSIGN) && !currentState === true)) {
            return {
              ...child,
              state: true
            }
          } else if (child.name === childName) {
            return {
              ...child,
              state: !currentState
            }
          }
          return child;
        });

        const parentCheckState = getParentCheckState(updatedChildState);
   
        return {
          ...item,
          state         : parentCheckState.parentState,
          indeterminate : parentCheckState.parentIndeterminateState,
          childList     : updatedChildState
        }
      }

      return item;
    });
    
    updateCategoryState(updatedParentState);
  }

  const getParentCheckState = (updatedChildState) => {
    let parentIndeterminateState = true;
    let parentState = true;
    if (updatedChildState.every(child => child.state === true)) {
      parentIndeterminateState = false;
    } else if (updatedChildState.every(child => child.state === false)) {
      parentIndeterminateState = false;
      parentState = false;
    } else {
      parentState = false;
    }

    return {
      parentState             : parentState,
      parentIndeterminateState: parentIndeterminateState
    }
  }

  const updateCategoryState = (newState) => {
    let categoryIndeterminate;
    let categoryState;
    if (newState.every(parent => parent.state === true)) {
      categoryIndeterminate = false;
      categoryState = true;
    } else if (newState.some(parent => parent.state === true) || newState.some(parent => parent.indeterminate === true)) {
      categoryIndeterminate = true;
      categoryState = false;
    } else {
      categoryIndeterminate = false;
      categoryState = false;
    }

    setPermissions({...permissions, state: categoryState, indeterminate: categoryIndeterminate, parent: newState});
  }

  const handleItemClick = (id, name) => {
    if (selectedItem === id) {
      return;
    }

    setSelectedItem(id);
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Title title={t("administratorRoles")} />
      <Content
        count={SYSTEM_ROLES.length}
        selectedItem={selectedItem}
        handleItemClick={handleItemClick}
        data={permissions}
        handleCategoryDropdownChange={handleCategoryDropdownChange}
        handleCategoryClick={handleCategoryClick}
        handleParentDropdownChange={handleParentDropdownChange}
        handleParentClick={handleParentClick}
        handleChildClick={handleChildClick}
        handleUpdateClick={() => {}}
        useSearch={useSearch}
        setSelectedItem={setSelectedItem}
        isLoading={false}
        isLoadingSelect={false}
      />
    </Container>
  );
}

export default SystemRoles;