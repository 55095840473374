import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../../theme/colors';

export default makeStyles((theme) => ({
  container: {
    maxHeight     : '350px',
    minHeight     : theme.spacing(20.635),
    paddingBottom : theme.spacing(2),
    paddingTop    : theme.spacing(1)
  },
  columnHeader: {
    alignContent  : 'center',
    alignItems    : 'center',
    display       : 'contents',
    justifyContent: 'center',
  },
  columnItem : {
    color     : theme.palette.secondary.main,
    fontWeight: '600',
    flexWrap  : 'nowrap',
  },
  activeIcon: {
    color     : colors.PRIMARY,
    flexWrap  : 'nowrap',
    fontSize  : theme.spacing(2),
    fontWeight: '800',
    marginTop : theme.spacing(-0.2),
  },
  inactiveIcon: {
    color     : theme.palette.secondary.main,
    flexWrap  : 'nowrap',
    fontSize  : theme.spacing(2),
    fontWeight: '800',
    marginTop : theme.spacing(0.2),
  },
}));