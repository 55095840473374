import { Backdrop, Box, Button, Container, Fade, Grid, IconButton, Modal, TextField, Tooltip } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from '../../../components/date-picker';
import SelectItems from '../../../components/select-items';
import UnsavedModal from '../../../components/unsaved-modal';
import { AuthContext } from '../../../context/authContext';
import { LocationContext } from '../../../context/locationContext';
import api from '../../../service/api';
import { request } from '../../../service/requests';
import { ACCESS_POINTS_MODULE, API_REQUEST_ERROR_MESSAGE, DATE_FORMAT, GET, HOLIDAYS_MODULE, LOCATIONS_MODULE, MAX_CHARACTER_LIMIT, POST, PUT, SCHEDULES_MODULE } from '../../../utility/constants';
import { GetInitialLocationObject, assignLocation, hasLocationChange } from '../../../utility/location';
import { profileSchema } from '../../../validation/schema';
import { CreateProfileLoadingState } from '../role-skeleton';
import useStyles from './styles';

const CreateProfile = (props) => {
  const classes  = useStyles();
  const { t }    = useTranslation();

  const { handleClose, initialValues, handleSubmit, setValidFrom, setValidUntil, handleSelectChange, showToaster, setName, setDescription, setIsNotValid, showLoading, selectedSchedules, selectedAccess, selectedLocation, disabled, selectedHolidays, handlePermissions } = props

  const handleChange = (setter, formik) => {
    return(setter, formik);
  }

  return(
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={profileSchema}
      onSubmit={handleSubmit}
    >
      {
        formik => {
          return(
            <Form>
              {showLoading(formik.isSubmitting)}
              <Grid container spacing={2} className={`${classes.paper} ${classes.form}`}>
                <Grid item md={12} sm={12} className={classes.grid}>
                  <TextField
                    className={classes.fullWidth}
                    id="roleCreateProfileModalName"
                    label={`${t('name')}*`}
                    name="name"
                    inputProps={{
                      maxlength: MAX_CHARACTER_LIMIT.TEXT_FIELD
                    }}
                    value={formik.values.name}
                    onChange={e => handleChange(setName(e.target.value), formik.handleChange(e))}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={t(formik.touched.name) && t(formik.errors.name)}
                    multiline
                  />
                </Grid>
                <Grid item md={12} sm={12} className={classes.grid}>
                  <TextField
                    className={classes.fullWidth}
                    id="roleCreateProfileModalDescription"
                    label={t('description')}
                    name="description"
                    inputProps={{
                      maxlength: MAX_CHARACTER_LIMIT.TEXT_FIELD
                    }}
                    variant="outlined"
                    placeholder={t('noDescription')}
                    multiline
                    rows={6}
                    value={formik.values.description}
                    onChange={e => handleChange(setDescription(e.target.value), formik.handleChange(e))}
                  />
                  <span
                    className={formik.errors.description && formik.touched.description ? classes.characterLimitError : classes.characterLimit}>
                    { 
                      t('controller-page.characterLimit', { 
                        currentCharacterCount: formik.values.description.length, maxCharacterCount: MAX_CHARACTER_LIMIT.TEXT_FIELD 
                      })
                    }
                  </span>
                </Grid>
                  <Grid item md={6} sm={6}>
                    <DatePicker
                      id="roleCreateProfileModalValidFrom"
                      name="validFrom"
                      disabled={false}
                      until={formik.values.validUntil}
                      label={t('validFrom')}
                      value={formik.values.validFrom}
                      handleChange={setValidFrom}
                      touched={formik.touched.validFrom}
                      error={formik.errors.validFrom}
                      setIsNotValid={setIsNotValid}
                    />
                  </Grid>
                  <Grid item md={6} sm={6}>
                    <DatePicker
                      id="roleCreateProfileModalValidUntil"
                      name="validUntil"
                      disabled={false}
                      label={t('validUntil')}
                      min={formik.values.validUntil}
                      from={formik.values.validFrom}
                      value={formik.values.validUntil}
                      handleChange={setValidUntil}
                      touched={formik.touched.validUntil}
                      error={formik.errors.validUntil}
                      setIsNotValid={setIsNotValid}
                    />
                  </Grid>
                  <Grid item md={12} sm={12} className={clsx(!handlePermissions(SCHEDULES_MODULE, PUT) && 'hidden')}>
                    <SelectItems
                      id="roleCreateProfileModalSchedules"
                      name="Schedules"
                      onChange={event => handleSelectChange(event, 'schedules')}
                      selectedItems={selectedSchedules}
                      showToaster={showToaster}
                      disabled={disabled}
                      handlePermissions={handlePermissions}
                    />
                  </Grid>
                  <Grid item md={12} sm={12} className={clsx(!handlePermissions(HOLIDAYS_MODULE, PUT) && 'hidden')}>
                    <SelectItems
                      id="roleCreateProfileModalHolidays"
                      name="Holidays"
                      onChange={event => handleSelectChange(event, 'holidays')}
                      selectedItems={selectedHolidays}
                      showToaster={showToaster}
                      disabled={disabled}
                      handlePermissions={handlePermissions}
                    />
                  </Grid>
                  <Grid item md={12} sm={12} className={clsx(!handlePermissions(ACCESS_POINTS_MODULE, PUT) && 'hidden')}>
                    <SelectItems
                      id="roleCreateProfileModalAccessPoints"
                      name="Access Points"
                      onChange={event => handleSelectChange(event, 'access')}
                      selectedItems={selectedAccess}
                      showToaster={showToaster}
                      disabled={disabled}
                      handlePermissions={handlePermissions}
                    />
                  </Grid>
                  <Grid item md={12} sm={12} className={clsx(!handlePermissions(LOCATIONS_MODULE, GET) && 'hidden')}>
                    <SelectItems
                      id="roleCreateProfileModalLocations"
                      name="Locations"
                      onChange={event => handleSelectChange(event, 'location')}
                      selectedItems={selectedLocation}
                      showToaster={showToaster}
                      single={true}
                      isValid={formik.touched.location && Boolean(formik.errors.location)}
                      helperText={t(formik.touched.location) && t(formik.errors.location)}
                      disabled={disabled}
                      required={true}
                      handlePermissions={handlePermissions}
                    />
                  </Grid>
                </Grid>
                <Grid container className={classes.action}>
                  <Grid item xs={12}>
                    <Button
                      className={classes.button}
                      type="submit"
                      id="roleCreateProfileModalSubmitButton"
                      variant="contained"
                      color="primary"
                    >
                      {`${t('create')}`}
                    </Button>
                    <Button
                      className={classes.button}
                      onClick={handleClose}
                      id="roleCreateProfileModalCancelButton"
                      variant="outlined"
                      color="primary"
                    >
                      {t('cancel')}
                    </Button>
                  </Grid>
                </Grid>
            </Form>
          )
        }
      }
    </Formik>
  );
}

const ModalCreateProfile = (props) => {
  const classes         = useStyles();
  const { showToaster, setSelectedProfiles, showLoading, setHasSelected, handlePermissions } = props;

  const [open, setOpen]                           = useState(false);
  const { t }                                     = useTranslation();

  const { state: authState }                                   = useContext(AuthContext);
  const { defaultValidFrom, defaultValidUntil, administrator } = authState;
  const { roles, locations }                                   = administrator;

  const { state }  = useContext(LocationContext);

  const initialLocationObject = GetInitialLocationObject();

  const [isLoading, setIsLoading]                 = useState(false);
  const [name, setName]                           = useState('');
  const [description, setDescription]             = useState('');
  const [validFrom, setValidFrom]                 = useState(defaultValidFrom);
  const [validUntil, setValidUntil]               = useState(defaultValidUntil);
  const [selectedSchedules, setSelectedSchedules] = useState([]);
  const [selectedHolidays, setSelectedHolidays]   = useState([]);
  const [accessPoints, setAccessPoints]           = useState([]);
  const [group, setGroup]                         = useState([]);
  const [isNotValid, setIsNotValid]               = useState(false);
  const [showModal, setShowModal]                 = useState(false);
  const [prevValues, setPrevValues]               = useState([]);
  const [location, setLocation]                   = useState(initialLocationObject);
  const [withChanges, setWithChanges]             = useState(false);

  const initialValues = useMemo(() => {
    return {
      name             : name,
      description      : description,
      validFrom        : validFrom,
      validUntil       : validUntil,
      accessPoint      : 'Point 1',
      accessPointGroup : 'Group 2',
      location         : location[0]?.locationId
    }
  }, [location, description, name, validFrom, validUntil])

  useEffect(() => {
    setPrevValues(initialValues);
  }, [initialValues]);

  
  const handleChanges = useCallback(() => {
    if (prevValues.name         === initialValues.name
      && prevValues.description === initialValues.description
      && prevValues.validUntil  === initialValues.validUntil
      && prevValues.validFrom   === initialValues.validFrom
      && !hasLocationChange(location, initialValues.location, locations.length, state)) {
      setWithChanges(false);
    } else {
      setWithChanges(true);
    }
  }, [locations, initialValues, prevValues, location, state]);

  useEffect(() => {
    handleChanges();
  }, [handleChanges]);

  const handleOpen = () => {
    setOpen(true);
    setName('');
    setDescription('');
    setValidFrom(null);
    setValidUntil(null);
    setSelectedSchedules([]);
    setAccessPoints([]);
    setGroup([]);
    setSelectedHolidays([]);
    setLocation(initialLocationObject);
  };

  const handleClose = () => {
    if (!withChanges && selectedSchedules.length === 0 && accessPoints.length === 0 && group.length === 0 && selectedHolidays.length === 0) {
      setShowModal(false);
      setOpen(false);
    } else {
      setShowModal(true);
    }
  };

  const handleSubmit = async (values, formik) => {
    const { setErrors, setSubmitting  } = formik;

    if (isNotValid) {
      setSubmitting(false);
      return;
    }

    try {
      const response = await request({
        url    : api.PROFILES,
        method : POST,
        data   : {
          name        : values.name,
          description : values.description,
          validFrom   : values.validFrom,
          validUntil  : values.validUntil
        }
      });

      const { data }  = response;
      const url       = data._links.self.href;
      const profileId = url.replace(`${api.PROFILES}/`, '');
      
      const profileData = {
        id          : profileId,
        name        : data.name,
        description : moment(data.validFrom).format(DATE_FORMAT) + '-' + moment(data.validUntil).format(DATE_FORMAT),
      }

      await assignLocationToProfile(url);

      const currentSerialNumber  = accessPoints.map(accessPoint => accessPoint.serialNumber);
      const filteredCurrentSerialNumber = [...new Set(currentSerialNumber)];

      const currentAPIds  = accessPoints.map(accessPoint => accessPoint.id);
        
      const currentScheduleIds  = selectedSchedules.map(schedule => schedule.id);

      const currentHolidayIds  = selectedHolidays.map(holiday => holiday.id);

      //check if there's current serial number
      if (!checkLength(filteredCurrentSerialNumber)) {
        const scheduleUrls = selectedSchedules.map(schedule => `${api.SCHEDULES}/${schedule.id}`).join(`\r\n`);

        await request({
          url: `${api.PROFILES}/${profileId}/schedules`,
          method: PUT,
          headers: {
            'Content-Type': 'text/uri-list',
          },
          data: scheduleUrls
        });

        const holidayUrls = selectedHolidays.map(holiday => `${api.HOLIDAYS}/${holiday.id}`).join(`\r\n`);

        await request({
          url: `${api.PROFILES}/${profileId}/holidays`,
          method: PUT,
          headers: {
            'Content-Type': 'text/uri-list',
          },
          data: holidayUrls
        });
      }

      for await (const serialNumber of filteredCurrentSerialNumber) {
        //sync holiday per serial number
        // if serial is in added serial numbers, get current holidays
        await syncRequest(`${api.PROFILES}/${profileId}/syncHolidays`, HolidayDTO(currentHolidayIds, serialNumber));

        //sync schedule per serial number
        // if serial is in added serial numbers, get current schedules
        await syncRequest(`${api.PROFILES}/${profileId}/syncSchedules`, ScheduleDTO(currentScheduleIds, serialNumber));

        //sync profile  get all current
        await syncRequest(`${api.PROFILES}/${profileId}/syncProfiles`, AccessPointScheduleHolidayDTO(currentAPIds, currentScheduleIds, currentHolidayIds, serialNumber));
      }
      
      setSelectedProfiles(prevItems => [...prevItems, profileData]);
      setHasSelected(true);

      setSelectedSchedules([]);
      setSelectedHolidays([]);
      setAccessPoints([]);

      showToaster(t('success'), `${values.name} ${t('hasBeenCreated')}`, 'success');
      setSubmitting(false);
      setOpen(false);
    } catch (error) {
      if (error?.response?.status === 409) {
        setErrors({
          name : t('nameAlreadyExists')
        });
      } else {
        showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
      }
    } finally {
      setSubmitting(false);
    }
  }

  const AccessPointScheduleHolidayDTO = (accessPointIds, scheduleIds, holidayIds, serialNumber) => {
    return {
      accessPointIds : accessPointIds,
      scheduleIds    : scheduleIds,
      holidayIds     : holidayIds,
      serialNumber   : serialNumber
    }
  }

  const ScheduleDTO = (scheduleIds, serialNumber) => {
    return {
      scheduleIds    : scheduleIds,
      serialNumber   : serialNumber
    }
  }

  const HolidayDTO = (holidayIds, serialNumber) => {
    return {
      holidayIds    : holidayIds,
      serialNumber  : serialNumber
    }
  }

  const syncRequest = (url, data) => {
    return request({
      url    : url,
      method : PUT,
      data   : data
    });
  }

  const checkLength = (array) => {
    return Boolean(array.length);
  }

  const handleSelectChange = (newItems, module) => {
    switch (module) {
      case 'access':
        return setAccessPoints(newItems ? newItems : accessPoints);
      case 'location':
        return setLocation(newItems ? newItems : location);
      case 'holidays':
        return setSelectedHolidays(newItems ? newItems : selectedHolidays);
      default:
        return setSelectedSchedules(newItems ? newItems : selectedSchedules);
    }
  }

  const handleCloseModal = () => {
    setShowModal(false);
  }
  const handleModalSubmit = () => {
    setShowModal(false);
    setOpen(false);
  }
  const handleModalCancel = () => {
    setShowModal(false);
  }

  const assignLocationToProfile = async (link) => {
    if (location === '') {
      return;
    }

    await assignLocation(link, location[0]?.locationId);
  }

  return (
    <Box>
      <Box onClick={handleOpen}>
        <Tooltip title={t('createProfile')}>
          <IconButton id="roleCreateProfileModalCreateProfileButton" className={classes.iconButton}>
            <AddIcon className={classes.addIconButton}/>
          </IconButton>
        </Tooltip>
      </Box>
      <UnsavedModal
        open={showModal}
        onClose={handleCloseModal}
        handleModalSubmit={handleModalSubmit}
        handleModalCancel={handleModalCancel}
      />
      <Modal
        disableEnforceFocus
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout : 500,
        }}
        className={classes.createProfileModal}
      >
        <Fade in={open}>
          <Container className={`${classes.modalContent}`}>
            <Box variant="body1" className={`${classes.titleModalCreateProfile} ${'bold'} ${classes.form}`}> {t('createProfile')} </Box>
            <hr className={classes.hrDividerProfile} />
            {
              isLoading ?
                <CreateProfileLoadingState />
              :
                <CreateProfile
                  handleClose={handleClose}
                  initialValues={initialValues}
                  handleSubmit={handleSubmit}
                  setName={setName}
                  setDescription={setDescription}
                  setValidFrom={setValidFrom}
                  setValidUntil={setValidUntil}
                  setIsLoading={setIsLoading}
                  showToaster={showToaster}
                  showLoading={showLoading}
                  setIsNotValid={setIsNotValid}
                  handleSelectChange={handleSelectChange}
                  selectedSchedules={selectedSchedules}
                  selectedHolidays={selectedHolidays}
                  selectedAccess={accessPoints}
                  selectedGroup={group}
                  selectedLocation={location}
                  roles={roles}
                  handlePermissions={handlePermissions}
                />
            }
          </Container>
        </Fade>
      </Modal>
    </Box>
  );
}

export default ModalCreateProfile;