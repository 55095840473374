import React from 'react';
import { Typography, Box } from '@material-ui/core';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

const EmptyTable = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { keyword } = props;

  return (
    <Box id="emptyTable" className={classes.nodata}>
      <Box className={classes.empty} />
      <Box> 
        <Typography variant="h6" className={classes.emptyTitle}>{ keyword === '' ? t('currentlyEmpty'): t('noMatchFound') }</Typography>
      </Box>
    </Box>
  )
}
export default EmptyTable;