import React from 'react'
import useStyles from "./styles";
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const AreaSkeleton = () => {
  const classes  = useStyles();
  return (
    <Box className={classes.locationContainer}>
      {
        [...Array(6)].map((i, index) => {
          return (
            <Skeleton
              key={i + '-skeleton-id-' + index}
              variant="rect" 
              className={classes.loading}
            />
          )
        })
      }
    </Box>
  )
}

export default AreaSkeleton