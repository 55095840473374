import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    paddingTop    : theme.spacing(2),
    paddingBottom : theme.spacing(2),
  },
  paper: {
    marginTop : theme.spacing(1),

    '& .MuiTextField-root': {
      marginTop : theme.spacing(0)
    },
    '& input#accessPointName.Mui-disabled':{
      color: "black"
    },
    '& textarea#description.Mui-disabled':{
      color: "black"
    }
  },
  editIcon: {
    backgroundColor: theme.palette.primary.main,
    borderRadius   : theme.spacing(0.5),
    color          : theme.palette.common.white,
    display        : 'inline',
    margin         : theme.spacing(1.1),
  },
  detailsBox: {
    height : "50px"
  },
  iconImage: {
    height  : '15px',
    opacity : '0.5',
  },
  form: {
    padding       : theme.spacing(1),
    paddingLeft   : theme.spacing(2),
    paddingRight  : theme.spacing(2),
    paddingBottom : theme.spacing(0),
  },
  fullWidth: {
    width : '100%',
  },
  action: { 
    backgroundColor: theme.palette.grey[100],
    marginTop      : theme.spacing(6),
    padding        : theme.spacing(2),
    textAlign      : 'right',

    '& .MuiButton-root:first-of-type': {
      marginRight: theme.spacing(2),
    },
  },
}));