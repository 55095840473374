import { TableBody, TableCell, TableRow } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { AuthContext } from '../../../context/authContext';
import { ACCESS_POINTS_MODULE, CONTROLLERS_MODULE, EVENTS_MODULE, GET, USERS_MODULE } from '../../../utility/constants';
import useStyles from './styles';

const SkeletonCol = (props) => {
  const { x, y, columns, handlePermissions, handleColumnName, module } = props;

  const name = handleColumnName(columns[y].label);
  const hasPermission = handlePermissions(name, GET);

  return (
    <TableCell 
      key={'empty-' + x + '-' + y}
      className={!hasPermission ? 'hidden' : ''}
    >
      {
        columns[y].label === 'actions' && module === ACCESS_POINTS_MODULE ?
          <></>
        :
          <Skeleton variant='text' />
      }
    </TableCell>
  )
}

const SkeletonRow = (props) => {
  const { columnKeys, columns, ROWS_PER_PAGE, module, handlePermissions, hasNoActionPermissions, handleColumnName } = props;
  const { state }  = useContext(AuthContext);
  const { roles }  = state.administrator;
  const classes   = useStyles();

  const isEventsModule       = module === EVENTS_MODULE;
  const isControllersModule  = module === CONTROLLERS_MODULE;
  const isAccessPointsModule = module === ACCESS_POINTS_MODULE;

  const skeletonColumns = columns.filter(column => !column.hidden);

  return (
    <TableBody>
      {
        [...Array(ROWS_PER_PAGE)].map((row, x) =>
          < TableRow key={'empty-tr-' + row + x} >
            <TableCell key={'empty-cb-' + row + x} className={isEventsModule || isControllersModule || isAccessPointsModule ? 'hidden' : ''}>
              <Skeleton key={'skeleton-rect1' + row + x} variant='rect' />
            </TableCell>
            <TableCell key={'empty-icon-' + row + x} className={clsx(classes.actions, module !== USERS_MODULE && 'hidden')}>
              <Skeleton key={'skeleton-rect2' + row + x} variant='rect' width='24px' height='24px'/>
              <Skeleton key={'skeleton-rect3' + row + x} variant='rect' width='24px' height='24px'/>
            </TableCell>
            {
              columnKeys.map((col, y) =>
                <SkeletonCol
                  key={y}
                  x={x}
                  y={y}
                  columns={skeletonColumns}
                  roles={roles}
                  handlePermissions={handlePermissions}
                  handleColumnName={handleColumnName}
                  module={module}
                />
              )
            }
            {
              isEventsModule || isAccessPointsModule || hasNoActionPermissions ?
                <></>
              :
                <TableCell key={'empty-action-' + row + x}>
                  <></>
                </TableCell>
            }
          </TableRow >
        )
      }
    </TableBody>
  )
}

export default SkeletonRow;