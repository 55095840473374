import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../theme/colors';

export default makeStyles((theme) => ({
  iconDesign: {
    color     : theme.palette.primary.main,
    width     : "11px",
    height    : "13px",
    opacity   : '1',
  },
  iconDesignDisabled: {
    color     : colors.SECONDARY,
    width     : "11px",
    height    : "13px",
    opacity   : '1',
  },
  iconBox: {
    marginRight  : theme.spacing(1),
    borderRadius : theme.spacing(0.5),
    border       : `1px solid ${theme.palette.primary.main}`,
    width        : "32px",
    height       : "28px",
  },
  iconBoxDisabled: {
    marginRight  : theme.spacing(1),
    borderRadius : theme.spacing(0.5),
    border       : `1px solid ${colors.SECONDARY}`,
    width        : "32px",
    height       : "28px",
  },
  deleteModal: {
    display       : 'block',
    padding       : theme.spacing(3),
    paddingBottom : theme.spacing(2),
    paddingTop    : theme.spacing(5)
  },
  dialogImage: {
    alignItems: 'center',
    margin    : '0 auto',
    textAlign : 'center',
  },
  dialogTitle: {
    fontWeight: 'bold',
    textAlign : 'center',
  },
  dialogContent: {
    padding  : theme.spacing(0.5),
    textAlign: 'center',
  },
  dialogEmphasis: {
    fontStyle: 'italic'
  },
  dialogActions: {
    alignItems     : 'center',
    backgroundColor: colors.LIGHT_GRAY,
    direction      : 'row',
    display        : 'flex',
    height         : theme.spacing(8),
    justifyContent : 'space-around',
    padding        : theme.spacing(2),
  },
  dialogSubmitButton: {
    display : 'flex',
    float   : 'left',
    margin  : theme.spacing(0.5),
    padding : theme.spacing(0.5),
    width   : "200px",
  },
  dialogCancelButton: {
    display : 'flex',
    float   : 'right',
    margin  : theme.spacing(0.5),
    padding : theme.spacing(0.5),
    width   : "200px",
  },
  url: {
    width: '80%'
  },
  flexStart: {
    display       : 'flex',
    alignItems    : 'flex-start',
    marginTop     : theme.spacing(-1),
    marginBottom  : theme.spacing(-1)
  },
  bulletSize: {
    height  : theme.spacing(1),
    color   : theme.palette.secondary.main
  },
  marginBullet: {
    marginTop : theme.spacing(1.3)
  },
  removeContainer: {
    maxWidth  : theme.spacing(50)
  },
  credentialsButton: {
    border      : `1px ${colors.PRIMARY} solid`,
    borderRadius: '4px',
    marginLeft  : theme.spacing(1),
    marginRight : theme.spacing(1),
  },
  img: {
    width: '13px',
  },
  popper: {
    border   : `1px solid ${colors.SECONDARY}`,
    marginTop: theme.spacing(2),
    position : 'absolute',
    width    : '300px'
  },
  popperHeader: {
    backgroundColor : colors.SECONDARY_LIGHT,
    display         : 'flex',
    justifyContent  : 'space-between',
    padding         : theme.spacing(1),
  },
  popperTitle: {
    fontWeight: 'bold'
  },
  popperContent: {
    flexFlow: 'column',
    padding : theme.spacing(2),
  },
  flexForm: {
    display: 'flex',
  },
  space: {
    paddingTop: theme.spacing(1)
  },
  label: {
    color: colors.PRIMARY
  },
  copyButton: {
    width: '18px'
  },
  iconButton: {
    padding: 0,
    width  : '18px'
  },
  passwordContainer: {
    flexFlow       : 'row',
    justifyContent : 'space-between',
    paddingRight   : theme.spacing(1),
  },
  qr: {
    border : `4px solid ${colors.PRIMARY}`,
    padding: theme.spacing(0.5)
  },
  qrContainer: {
    justifyContent: 'center',
    marginBottom  : theme.spacing(1.5),
    marginTop     : theme.spacing(1)
  },
  visibilityIcon: {
    width: '100%'
  },
  wordBreak: {
    alignItems   : 'left',
    fontSize     : '14px',
    paddingLeft  : theme.spacing(0.7),
    paddingRight : theme.spacing(1),
    wordBreak    : 'break-word',
    '& .MuiTypography-body1': {
      letterSpacing: 0,
      lineHeight   : 0.7
    },
  },
  flexRow: {
    flexWrap: 'unset'
  },
  arrow: {
    backgroundColor: colors.LIGHT_GRAY,
    borderLeft     : `1px solid ${colors.SECONDARY}`,
    borderTop      : `1px solid ${colors.SECONDARY}`,
    display        : 'block',
    position       : 'absolute',
    width          : '10px',
    height         : '10px',
    top            : '11px',
    transform      : 'rotate(45deg)',
    left           : '11px',
    zIndex         : '2'
  }
}));