import { faIdCardAlt, faUserCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid, TextField } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { forwardRef, useContext, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { WizardContext } from '../../../../context/wizardContext';
import { WizardPersonContext } from '../../../../context/wizardPersonContext';
import { SET_NEXT_PAYLOAD } from '../../../../reducer/wizardReducer';
import api from '../../../../service/api';
import { multipleRequest, request } from '../../../../service/requests';
import { assignUserToCredential, assignUserToRoles, createUserApi } from '../../../../service/usersApi';
import { assignLocation } from '../../../../utility/location';
import { API_REQUEST_ERROR_MESSAGE, PATCH, POST } from '../../../../utility/constants';
import Chip from '../../../chip';
import useStyles from './styles';

const Chips = (props) => {
  const classes = useStyles();
  const { items, tagIcon } = props

  return (
    <Box className={classes.inputChips}>
      {
        items.map((option, index) => (
          <Box className={classes.chips} key={'selected-Chip-Box-' + index} >
            <Chip
              key={'Enhanced-Chip-Tag-' + index}
              icon={tagIcon}
              title={option.name}
              description={option.description}
            />
          </Box>
        ))
      }
    </Box>
  )
}

const ChipsTextField = (props) => {
  const { items, label, tagIcon } = props;

  return (
    <TextField
      id="chips-textfield"
      label={label}
      variant="outlined"
      fullWidth
      inputProps={{
        readOnly: true
      }}
      InputProps={{
        startAdornment:
          items.length > 0 ?
            <Chips items={items} tagIcon={tagIcon} />
            :
            null
        ,
        max: 100,
        min: 0,
        step: 2,
      }}
    />
  )
}

const Content = (props) => {
  const { values, formRef, handleSubmit, credentials, roles, location } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      onSubmit={handleSubmit}
      innerRef={formRef}
    >
      {
        formik => (
          <Form className={classes.container}>
            <Grid container spacing={1} className={classes.form}>
              <Grid item xs={5}>
                <TextField
                  id="wizardPersonSummaryPersonalNumber"
                  label={t('help-center-component.personalNumber')}
                  name="personalNumber"
                  size="medium"
                  fullWidth
                  value={formik.values.personalNumber}
                  inputProps={{
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="wizardPersonSummaryPerson"
                  label={t('help-center-component.user')}
                  name="user"
                  size="medium"
                  fullWidth
                  value={`${formik.values.lastName}, ${formik.values.firstName}`}
                  inputProps={{
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="wizardPersonSummaryLocation"
                  label={t('main-layout-component.location')}
                  name="location"
                  size="medium"
                  fullWidth
                  value={location}
                  inputProps={{
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <ChipsTextField
                  id="wizardPersonSummaryCredentials"
                  label={t('help-center-component.credentials')}
                  items={credentials}
                  tagIcon={<FontAwesomeIcon icon={faIdCardAlt} />}
                />
              </Grid>
              <Grid item xs={12}>
                <ChipsTextField
                  id="wizardPersonSummaryRoles"
                  label={t('help-center-component.roles')}
                  items={roles}
                  tagIcon={<FontAwesomeIcon icon={faUserCog} />}
                />
              </Grid>
            </Grid>
          </Form>
        )
      }
    </Formik>
  )
}

const WizardUserSummary = forwardRef((props, ref) => {
  const { showToaster } = props;

  const { t }    = useTranslation();

  const wizardContext = useContext(WizardContext);
  const personContext = useContext(WizardPersonContext);

  const formRef = useRef();

  const { user, selectedRoles, selectedCredentials, newCredentials } = personContext.state;

  const personLocationName = user.location.name;
  const personLocationId   = user.location.id;

  useImperativeHandle(ref, () => ({
    onNextPage() {
      formRef.current.handleSubmit();
    }
  }));

  const getCreateCredentialRequests = () => {
    const requests = [];

    if (newCredentials.length) {
      const credentialValues = newCredentials.map(credential => {
        delete credential.name
        return credential
      });

      requests.push(...credentialValues.map(credential => {
        return request({
          url     : api.CREDENTIALS,
          method  : POST,
          data    : credential
        })
      }));
    }

    return requests;
  }

  const getUpdateCredentialRequests = () => {
    const requests = [];

    if (selectedCredentials.length) {
      const selectedCredentialValues = selectedCredentials.filter(credential => credential.id >= 1);

      requests.push(...selectedCredentialValues.map(credential => {
        return request({
          url     : `${api.CREDENTIALS}/${credential.id}`,
          method  : PATCH,
          data    : {
            validFrom   : user.validFrom,
            validUntil  : user.validUntil
          }
        })
      }));
    }

    return requests;
  }

  const handleSubmit = async () => {
    const values = {
      firstName  : user.firstName,
      lastName   : user.lastName,
      enabled    : false,
      username   : user.personalNumber,
      attributes  : {
        validFrom  : user.validFrom,
        validUntil : user.validUntil,
        location   : personLocationId
      },
      groups     : [personLocationName]
    }
    
    const requests = [];

    requests.push(...getCreateCredentialRequests());
    requests.push(...getUpdateCredentialRequests());

    try {
      const userResponse = await createUserApi(values);
      
      const userId = userResponse.data.user.id;

      const credentialResponses = await multipleRequest(requests);
      
      const credentialIds = credentialResponses.map(item => { return item.data.credentialId });
      const roleIds = selectedRoles.map(role => { return role.id });

      const credentialUrl = credentialResponses[0]?.data._links.self.href;
      const credentialLocation = newCredentials[0]?.location.id;

      if (newCredentials.length) {
        await assignLocation(credentialUrl, credentialLocation);
      }

      await assignUserToRoles(userId, roleIds);
      await assignUserToCredential(userId, credentialIds);

      showToaster(t('success'), `${user.lastName}, ${user.firstName} ${t('hasBeen')} ${t('created')}`, 'success');
      wizardContext.dispatch({ type: SET_NEXT_PAYLOAD, payload: { isNextHidden: true, isNextShow: true, isEndPage: false } });

    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }
  }

  return (
    <Content
      values={user}
      roles={selectedRoles}
      credentials={selectedCredentials}
      formRef={formRef}
      handleSubmit={handleSubmit}
      location={personLocationName}
    />
  )
});

export default WizardUserSummary;