import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  container: {
    paddingTop   : theme.spacing(8),
    paddingLeft  : theme.spacing(6),
    paddingRight : theme.spacing(6)
  },
  flex: {
    display       : 'flex',
    flexDirection : 'row',
    flexWrap      : 'nowrap'
  },
  content: {
    marginLeft : theme.spacing(12),
    textAlign  : 'center'
  },
  errorCode: {
    color        : colors.SECONDARY_TEXT,
    fontSize     : '300px',
    fontWeight   : 'bold',
    letterSpacing: theme.spacing(2.9),
    marginBottom : theme.spacing(-7)
  },
  error: {
    color       : colors.SECONDARY_TEXT,
    fontSize    : '60px',
    fontWeight  : 'bold',
    marginBottom: theme.spacing(4),
    lineHeight  : '90%'
  },
  subText: {
    color         : '#262626',
    fontSize      : '25px',
    letterSpacing : theme.spacing(-0.1)
  },
  label: {
    color         : colors.PRIMARY,
    fontSize      : '25px',
    letterSpacing : theme.spacing(-0.1)
  },
  imageContainer: {
    paddingTop: theme.spacing(18)
  },
  image: {
    width: '550px'
  }
}));