import React from 'react';
import { Box, Button, Paper, Dialog, Typography } from '@material-ui/core';
import images from "../../utility/images";
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

const UnsavedModal = (props) => {
  const { handleModalSubmit, handleModalCancel, withRedirect } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
    <Dialog
      id="unsavedModal"
      modal="false"
      {...props}
      aria-labelledby="row-unsaved-dialog"
      aria-describedby="row-unsaved-dialog-table"
    >
      <Paper id="unsavedModalContainer" className={classes.dialogModal}>
        <Box className={classes.dialogImage}>
          <img src={images.UNSAVED_CHANGES_SVG} alt='unsave-dialog-img' />
        </Box>
        <Box className={classes.dialogImage}>
        </Box>
        <Box className={classes.dialogTitle}>
          <Typography>{t(`unsavedChanges`)}</Typography>
        </Box>
        <Box className={classes.dialogContent}>
          {
            withRedirect ?
            <Typography className={classes.dialogMessage}>{t(`youHaveUnsavedChanges`)}</Typography> :
            <Typography className={classes.dialogMessage}>{t(`youWantToProceed`)}</Typography>
          }
        </Box>
      </Paper>
      <Box id="unsavedModalButtonsContainer" className={classes.dialogActions}>
        <Button id="unsavedModalSubmitButton" color="primary" variant="contained" className={classes.dialogSubmitButton} onClick={handleModalSubmit} >
          {
            withRedirect ? t(`yesLeave`) : t(`yesProceed`)
          }
        </Button>
        <Button id="unsavedModalCancelButton" color="primary" variant="outlined" className={classes.dialogCancelButton} onClick={handleModalCancel} >
          {
            withRedirect ? t(`noStayOnThisPage`) : t(`noContinueWorking`)
          }
        </Button>
      </Box>
    </Dialog>
    </>
  );
}

UnsavedModal.defaultProps = {
  withRedirect: true
}

export default UnsavedModal;