import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  cursorDefault: {
    color     : colors.SECONDARY_TEXT,
    cursor    : 'default',
    fontWeight: 'bold',
    wordBreak : 'break-word'
  },
  cursorPointer: {
    cursor    : 'pointer',
  },
  breadcrumbs: {
    display: 'flex',
    '& .MuiBreadcrumbs-ol': {
      alignItems: 'center',
      flexWrap  : 'nowrap'
    },
    '& .MuiBreadcrumbs-li': {
      display: 'flex'
    }
  }
}));