import { GET } from "../utility/constants"
import api from "./api"
import { request } from "./requests"


export const getAreaAuditLogs = async(areaId, params) => {
 const response = await request({
    url: `${api.AREA_AUDIT_LOGS}/${areaId}`,
    method: GET,
    params: params
 });

 return response.data;
}

export const getLocationAuditLogs = async(locationId, params) => {
 const response = await request({
    url: `${api.LOCATION_AUDIT_LOGS}/${locationId}`,
    method: GET,
    params: params
 });

 return response.data;
}