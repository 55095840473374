import { GET } from "../utility/constants";
import api from "./api";
import { request } from "./requests";

export const getAdministratorLocations = async (adminId) => {
  const response = await request({
    url   : `${api.AUTHORIZATIONS}/search/administrators/${adminId}`,
    method: GET
  });

  return response.data;
}