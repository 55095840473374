import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    paddingTop   : theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(1),

    '& .MuiTextField-root': {
      marginTop: theme.spacing(0)
    }
  },
  form: {
    padding      : theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingLeft  : theme.spacing(2),
    paddingRight : theme.spacing(2)
  },
  boxBackground: {
    backgroundColor: theme.palette.secondary.light
  }
}));