import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
  },
  form: {
    padding         : theme.spacing(1),
    paddingLeft     : theme.spacing(2),
    paddingRight    : theme.spacing(2),

    '& .MuiSkeleton-root': {
      marginBottom  : theme.spacing(1),
      transform     : 'none',
    },
  },
  action: {
    padding         : theme.spacing(2),
    textAlign       : 'right',
    '& .MuiButton-root:first-of-type': {
      marginRight: theme.spacing(2)
    },
    backgroundColor: theme.palette.grey[100],
    display        : 'flex',
    justifyContent : 'flex-end',
    width          : '100%',  
  },
  buttonContainer:{
    backgroundColor: theme.palette.grey[100],
    display        : 'flex',
    justifyContent : 'flex-end',
    width          : '100%',  
  },
  button: {
    height    : '40px',
    marginLeft: theme.spacing(1),
    width     : '120px',
  },
  location: {
    paddingLeft: theme.spacing(1)
  }
}));