import { DELETE, GET, PROJECTION } from "../utility/constants";
import api from "./api";
import { multipleRequest, request } from "./requests";

export const deleteProfile = async (id) => {
  await request({
    url     : `${api.PROFILES}/${id}/deleteProfile`,
    method  : DELETE,
  });
}

export const deleteMultipleProfiles = async (ids) => {
  return multipleRequest(
    ids.map(id =>
      request({
        url     : `${api.PROFILES}/${id}/deleteProfile`,
        method  : DELETE,
      })
    )
  )
}

export const getProfileRoles = async (profileIds) => {
  return multipleRequest(profileIds.map(profileId => 
    request({
      url   : `${api.PROFILES}/${profileId}/roles`,
      method: GET,
      params: {
        projection : PROJECTION.ROLES
      }
    })  
  ));
}