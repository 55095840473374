import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors'

export default makeStyles((theme) => ({
  categoryStyle: {
    color        : theme.palette.secondary.main,
    fontWeight   : 'bold',
    lineHeight   : 1,
    paddingLeft  : theme.spacing(1),
    paddingRight : theme.spacing(1),
  },
  icon: {
    height : theme.spacing(4.25),
    padding: theme.spacing(0.625),
    width  : theme.spacing(4.25),
    "&:hover" : {
      color : colors.PRIMARY
    }
  },
  buttonIcon: {
    bottom      : theme.spacing(0),
    padding     : theme.spacing(1),
    paddingLeft : theme.spacing(0),
    paddingRight: theme.spacing(0),
    position    : 'absolute',
    right       : theme.spacing(-0.5),
    top         : theme.spacing(0),
  },
  searchField: {
    width: '100%',
    paddingTop: theme.spacing(1),
  },
  boxItemContainer: {
    display          : 'flex',
    justifyContent   : 'space-between',
    padding          : theme.spacing(1),
    position         : 'relative',
  },
  boxItemContainerHighlight: {
    display          : 'flex',
    justifyContent   : 'space-between',
    padding          : theme.spacing(1),
    position         : 'relative',
  },
  typographStyle: {
    "&:hover" : {
      color       : colors.PRIMARY,
    },
    color      : colors.BLACK,
    cursor     : 'pointer',
    paddingTop : theme.spacing(1),
  },
  typographStyleHightlight: {
    color      : colors.BLACK,
    cursor     : 'pointer',
    fontWeight : 'bold',
    paddingTop : theme.spacing(1),
  },
  categoryCheckbox: {
    marginLeft  : theme.spacing(1.25),
  },
  categoryTypographStyle: {
    color       : colors.BLACK,
  },
  itemNameBox: {
    display     : 'flex', 
    alignItems  : 'baseline'
  },
  visuallyHidden: {
    visibility: 'hidden'
  },
  scrollable: {
    minHeight : '600px',
    maxHeight : '600px',
    overflowY : 'auto'
  },
  loading: {
    marginLeft: '50%'
  },
  emptyContainer: {
    alignItems    : 'center',
    display       : 'flex',
    height        : '64%',
    justifyContent: 'center',
    minHeight     : '600px',
    maxHeight     : '600px',
  },
  roleChip: {
    borderRadius    : 1,
    backgroundColor : colors.CHIP_BACKGROUND_BLUE,
    color           : colors.CHIP_TEXT_BLUE,
    marginLeft      : theme.spacing(1),
    fontWeight      : 'bold'
  },
  roleChipMore: {
    border      : '0px',
    color       : colors.SECONDARY,
    marginLeft  : theme.spacing(.5),
  },
}));