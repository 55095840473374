import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  menu: {
    height: theme.spacing(22.5),
    '& [aria-hidden="true"]': {
      position: 'static !important'
    }
  },
  iconButton: {
    backgroundColor: theme.palette.primary.main,
    borderRadius   : theme.spacing(0.5),
    color          : theme.palette.common.white,
    display        : 'inline',
  },
  icon: {
    marginRight: theme.spacing(1),
    width      : theme.spacing(1.75),
    height     : theme.spacing(1.75),
  },
  menuItem: {
    fontSize: theme.spacing(1.75)
  }
}));