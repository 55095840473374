import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  time: {
    fontSize: theme.spacing(1.75),
  },
  date: {
    fontSize: theme.spacing(1.75),
    color: colors.LIGHT_GRAY_TEXT
  }
}));