import React from 'react';
import { Box, Typography,  LinearProgress } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

const ProgressBar = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { data, size = 'medium', elementId } = props;

  return (
    data.map((info, index) => {
      const { id, name, value } = info
      const elementIdName = name.charAt(0).toUpperCase() + name.slice(1);

      return(
        <Box key={id + index} className={clsx({
          [classes.spaceSmall]  : (size === 'small'),
          [classes.spaceMedium] : (size === 'medium'),
        })}>
          <Box className={classes.statusBarLabel}>
            <Typography className={clsx({
              [classes.fontSizeSmall]   : (size === 'small'),
              [classes.fontSizeMedium]  : (size === 'medium'),
            })} 
            >
              {t(name)}
            </Typography>
            <Typography className={clsx({
              [classes.fontSizeSmall]   : (size === 'small'),
              [classes.fontSizeMedium]  : (size === 'medium'),
            })} 
            >
              <span id={`${elementId}${elementIdName}`}>{`${Math.round(value,)}`}</span>%
            </Typography>
          </Box>
          <Box>
            <LinearProgress
              classes={{
                colorPrimary: clsx({
                  [classes.colorPrimary]  : true
                }), 
                barColorPrimary: clsx({
                  [classes.colorNormal] : (value < 50),
                  [classes.colorSafe]   : (value > 49 && info.value < 90 ),
                  [classes.colorDanger] : (value >= 90),
                })
              }} 
              variant="determinate" 
              className={clsx({
                [classes.barHeightSmall]   : (size === 'small'),
                [classes.barHeightMedium]  : (size === 'medium'),
              })}
              {...id}
              {...name}
              value={value > 100 ? 100 : value}
              />
          </Box>
        </Box>
      );
    })
  );
}

export default ProgressBar;