import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  stickyTableCell: {
    position       : 'sticky',
    zIndex         : 1,
    backgroundColor: 'white',
    '&:nth-of-type(1)': {
      left       : 0,
      maxWidth   : theme.spacing(12.5),
      padding    : theme.spacing(0),
      paddingLeft: theme.spacing(2.5),
      width      : theme.spacing(12.5),
      zIndex     : 2,
    },
    '&:nth-of-type(2)': {
      left    : theme.spacing(12.5),
      maxWidth: theme.spacing(31.25),
      width   : theme.spacing(31.25),
      zIndex  : 2,
    },
  },
  smallerTableCell: {
    '&:nth-of-type(2)': {
      borderRight: '1px solid ' + colors.LIGHT_GRAY,
      maxWidth   : theme.spacing(22.5),
      width      : theme.spacing(22.5),
    },
  },
  paper: {
    marginBottom: theme.spacing(5),
    width       : '100%',
  },
  cutBar: {
    height   : '100%',
    position : 'relative',
  },
  root: {
    paddingLeft : theme.spacing(0.4),
    paddingRight: theme.spacing(1),
  },
  toolView: {
    display       : 'flex',
    justifyContent: 'space-between',
    margin        : '0 auto',
    position      : 'relative',
    width         : '100%',
    height        : '100%',
    overflowY     : 'auto',
    overflowX     : 'auto'
  },
  toolbar: {
    paddingLeft  : theme.spacing(0.625),
    paddingRight : theme.spacing(0)
  },
  toolBarItemsContainer: {
    alignItems: 'center'
  }, 
  paginationContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'right',
    position: 'relative',
    '& .MuiSkeleton-root' : {
      position: 'absolute',
      right: theme.spacing(15)
    }
  },
  paginationHide: {
    '& .MuiTablePagination-caption': {
      display: 'none'
    }
  },
  filterButton: {
    alignItems   : 'center',
    background   : 'none',
    border       : 'none',
    borderRadius : theme.spacing(1),
    boxShadow    : 'none',
    color        : colors.PRIMARY,
    display      : 'flex',
    height       : theme.spacing(5.625),
    marginLeft   : theme.spacing(1),
    marginTop    : theme.spacing(-1),
    padding      : theme.spacing(1),
    textTransform: 'none',
    width        : 'fit-content',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      boxShadow      : 'none',
    },
  },
  searchField: {
    display : 'block',
    float   : 'left',
    left    : theme.spacing(-0.5),
    margin  : 0,
    position: 'relative',
    top     : theme.spacing(-0.5),
    '& .MuiInputBase-root': {
      height: theme.spacing(5.625)
    }
  },
  activeIcon: {
    color        : colors.DARK_GRAY,
    flexWrap     : 'nowrap',
    fontSize     : theme.spacing(2),
    fontWeight   : '800',
    marginBottom : theme.spacing(-.2),
    marginRight  : theme.spacing(.5),
  },
  inactiveIcon: {
    color        : theme.palette.secondary.main,
    flexWrap     : 'nowrap',
    fontSize     : theme.spacing(2),
    fontWeight   : '800',
    marginBottom : theme.spacing(-.2),
    marginRight  : theme.spacing(.5),
    opacity      : '16%'
  },
  columnHeader: {
    alignContent  : 'center',
    alignItems    : 'center',
    display       : 'contents',
    justifyContent: 'center',
  },
  columnItem: {
    color     : colors.LIGHT_GRAY_TEXT,
    flexWrap  : 'nowrap',
    fontSize  : theme.spacing(1.75),
    fontWeight: '400'
  },
  baseCell: {
    whiteSpace   : 'nowrap', 
    overflow     : 'hidden',
    textOverflow : 'ellipsis',
    maxWidth     : theme.spacing(18.75),
    padding      : theme.spacing(0),
    paddingLeft  : theme.spacing(1),
    paddingRight : theme.spacing(1)
  },
  dataRow: {
    cursor: 'pointer',
    height: theme.spacing(7),
    '&:hover': {
      backgroundColor: colors.COMPONENT_STATE_HOVER,
      '& $stickyTableCell': {
        backgroundColor: colors.COMPONENT_STATE_HOVER, 
      }
    },
  },
  headerStyle: {
    backgroundColor: colors.WHITE,
    height         : theme.spacing(7),
    padding        : theme.spacing(0),
    paddingLeft    : theme.spacing(1),
    position       : 'sticky',
    textAlign      : 'left',
    top            : 0,
    minWidth       : theme.spacing(18.75),
    maxWidth       : theme.spacing(18.75),
    zIndex         : 3,
    '&:nth-of-type(2)': {
      left       : 0,
      minWidth   : theme.spacing(12.5),
      maxWidth   : theme.spacing(12.5),
      width      : theme.spacing(12.5),
      paddingLeft: theme.spacing(2),
      zIndex     : 4,
    },
    '&:nth-of-type(3)': {
      left       : theme.spacing(12.5),
      minWidth   : theme.spacing(31.25),
      maxWidth   : theme.spacing(31.25),
      width      : theme.spacing(31.25),
      zIndex     : 4,
    },
  },
  smallerTableHead: {
    backgroundColor: colors.WHITE,
    height         : theme.spacing(7),
    padding        : theme.spacing(0),
    paddingLeft    : theme.spacing(1),
    position       : 'sticky',
    textAlign      : 'left',
    top            : 0,
    minWidth       : theme.spacing(18.75),
    maxWidth       : theme.spacing(18.75),
    zIndex         : 3,
    '&:nth-of-type(2)': {
      left       : 0,
      minWidth   : theme.spacing(12.5),
      maxWidth   : theme.spacing(12.5),
      width      : theme.spacing(12.5),
      paddingLeft: theme.spacing(2),
      zIndex     : 4,
    },
    '&:nth-of-type(3)': {
      left       : theme.spacing(12.5),
      borderRight: '1px solid ' + colors.LIGHT_GRAY,
      minWidth   : theme.spacing(23.75),
      maxWidth   : theme.spacing(23.75),
      width      : theme.spacing(23.75),
      zIndex     : 4,
    },
  },
    icon: {
    height: `${theme.spacing(3)} !important`,
    width : `${theme.spacing(3)} !important`
  },
  visuallyHidden: {
    border  : 0,
    clip    : 'rect(0 0 0 0)',
    height  : 1,
    margin  : -1,
    overflow: 'hidden',
    padding : 0,
    position: 'absolute',
    top     : 20,
    width   : 1,
  },
  mainTable: {
    minWidth: theme.spacing(93.75),
    width   : '100%',
    borderCollapse: 'unset'
  },
  tableBox: {
    width: '100%',
    height: '82%',
    '@media (max-height: 720px)' : {
        height: '75%'
    },
    '@media (max-height: 540px)' : {
        height: '70%'
    },
    '@media (max-height: 460px)' : {
        height: '60%'
    }
  }
}));