import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Dialog, Grid, Paper, TextField, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import Papa from "papaparse";
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import api from '../../../service/api';
import { request } from '../../../service/requests';
import { API_REQUEST_ERROR_MESSAGE, ENTITY, POST } from '../../../utility/constants';
import images from "../../../utility/images";
import { CsvActionSkeleton, CsvResultSkeleton } from '../users-csv-skeleton';
import useStyles from './styles';

const CSV_HEADER = ['personalNumber', 'firstName', 'lastName', 'credentials', 'validFrom', 'validUntil', 'roles', 'location'];

const TableCsv = () => {
  const classes  = useStyles();
  const { t }    = useTranslation();
  const row      = ['PN-202', 'Astrid', 'Smith', 'cn-202', '25.01.2021', '25.01.2021', 'CSV Role', 'Hamm'];
  const header   = ['#'+t(`personalNumber`), t(`firstName`), t(`lastName`), t(`credential`), t(`validFrom`), t(`validUntil`), t(`roleName`), t('user-page.location')];

  return(
    <Box className={classes.containers}>
      <Typography className={classes.sampleCsvLabel}>
          {t('csvFileSample')}
      </Typography>
      <table className={classes.table}>
        <tr >
          {
            header.map((value) => <td className={classes.th} align="left">{value}</td>)
          }
        </tr>
        <tr>
          {
            row.map((value) => <td className={classes.td}>{value}</td>)
          }
        </tr>
      </table>
      <Typography className={classes.warningText}>
        <FontAwesomeIcon icon={faExclamationCircle} className={classes.warningIcon}/>
         {t('FixedOrder')}
      </Typography>
    </Box>
  )
};

const DownloadReportModal = (props) => {
  const { handleCancelDownload, handleDownload } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Paper id="personsDownloadReportModalContainer" className={classes.downloadModal}>
        <Box className={classes.dialogImage}><img src={images.DOWNLOAD_REPORT} alt='download-dialog-img' /></Box>
        <Box className={classes.downloadTitle}>{t(`downloadReport`)}</Box>
        <Box className={classes.downloadContent}>{t(`downloadReportDescription`)}</Box>
      </Paper>
      <Box className={classes.downloadActions}>
          <Button
            id="personsDownloadReportModalDontDownloadButton"
            className={classes.downloadCancelButton}
            onClick={handleCancelDownload}
            variant="outlined"
            color="primary"
            >
            {t('noDontDownload')}
          </Button>
          <Button
            id="personsDownloadReportModalDownloadButton"
            className={classes.downloadSubmitButton}
            onClick={handleDownload}
            variant="contained"
            color="primary"
          >
            {t('yesDownload')}
          </Button>
      </Box>
    </>
  );
}

const Content = (props) => {
  const classes  = useStyles();
  const { t }    = useTranslation();
  const { initialValues, handleSubmit, closeModal, handleChange, csvFile, credentialSkip, existingPersonSkip, invalidPersonSkip, showCredential, showExistingPerson, showRoles, rolesSkip, isValidating, showInvalidPersons, invalidLocations, showInvalidLocations, unauthorizedLocations, usersWithNoLocation, showLocations, showNoLocations } = props;

  const setPlural = (count, name) => {
    return count > 1 ? t(`${name}s`) : t(name);
  }

  const setPluralPerson = (count, name) => {
    return count > 1 ? t(`existingUsers`) : t(name);
  }

  const setPluralLocation = (count) => {
    return count > 1 ? t('pluralUnauthorizedAndSkip') : t('singleUnauthorizedAndSkip');
  }
  
  const setPluralNoLocation = (count) => {
    return count > 1 ? t('my-account-page.pluralNoLocationAndSkip') : t('my-account-page.singleNoLocationAndSkip');
  }

  return(
  <Formik
      enableReinitialize
      initialValues={initialValues}
      handleChange={handleChange}
      onSubmit={handleSubmit}
  >
      {
        formik => {
          return(
            <Form className={classes.csvForm}>
              <Paper id="personsCSVModalContainer" className={classes.csvModal} sm={8}>
                <Grid container spacing={12}>
                  <Box className={classes.dialogImage}>
                    <img src={images.IMPORT_CSV_SVG} alt='import-dialog-img' />
                    <Typography className={classes.dialogTitle}>{t('fileImport')}</Typography>
                  </Box>
                </Grid>
                <Grid container className={classes.dialogContents} spacing={2}>
                  <Grid item xs={12}>
                    <Box className={classes.csvActions}>
                      <TextField size='small'
                        id="personsCSVModalChooseFile"
                        inputProps={{
                          readOnly: true
                        }}
                        value={csvFile}
                        name="csvInput"
                        error={Boolean(formik.errors.csvInput)}
                        helperText={formik.errors.csvInput}
                        className={classes.chooseFileField}
                      />
                      <Button id="personsCSVModalChooseFileButton" color="secondary" variant="contained" component="label" className={classes.chooseFileButton}>
                      {t('chooseFile')}
                      <input
                        id="file"
                        name="file"
                        variant="outlined"
                        value={formik.values.file}
                        style={{ display: 'none' }}
                        onChange={handleChange}
                        type="file" accept=".csv"
                      />
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <TableCsv/>
                  </Grid>
                </Grid>
                <Grid container>
                  {
                    isValidating ?
                      <CsvResultSkeleton />
                    :
                      <Grid item md={12} sm={12}>
                        <Box className={classes.csvItems}>
                          <Typography id="personsCSVModalPersonSkip" className={clsx({
                            [classes.csvText]     : showExistingPerson,
                            [classes.displayNone] : !showExistingPerson
                          })}>
                            <FontAwesomeIcon icon={faExclamationCircle} className={classes.skipIcon}/>
                              {`${existingPersonSkip} ${setPluralPerson(existingPersonSkip, ENTITY.USER)}`} {t('alreadyExistAndSkip')}
                          </Typography>
                          <Typography className={clsx({
                            [classes.csvText]     : showInvalidPersons,
                            [classes.displayNone] : !showInvalidPersons
                          })}>
                            <FontAwesomeIcon icon={faExclamationCircle} className={classes.skipIcon}/>
                              {`${invalidPersonSkip} ${setPluralPerson(invalidPersonSkip, ENTITY.USER)}`} {t('withInvalidDateAndSkip')}
                          </Typography>
                          <Typography id="personsCSVModalCredentialSkip" className={clsx({
                            [classes.csvText]     : showCredential,
                            [classes.displayNone] : !showCredential
                          })}>
                            <FontAwesomeIcon icon={faExclamationCircle} className={classes.skipIcon} />
                              {`${credentialSkip} ${setPlural(credentialSkip, 'credential')}`} {t('alreadyExistAndSkip')}
                          </Typography>
                          <Typography id="personsCSVModalRoleExist" className={clsx({
                            [classes.csvText]     : showRoles,
                            [classes.displayNone] : !showRoles
                          })}>
                            <FontAwesomeIcon icon={faExclamationCircle} className={classes.skipIcon}/>
                              {`${rolesSkip} ${setPlural(rolesSkip, 'role')}`} {t('doesNotExistAndSkip')}
                          </Typography>
                          <Typography id="personsCSVModalInvalidDatesSkip" className={clsx({
                            [classes.csvText]     : showInvalidLocations,
                            [classes.displayNone] : !showInvalidLocations
                          })}>
                            <FontAwesomeIcon icon={faExclamationCircle} className={classes.skipIcon}/>
                              {`${invalidLocations} ${setPlural(invalidLocations, 'location')}`} {t('doesNotExistAndSkip')}
                          </Typography>
                          <Typography className={clsx({
                            [classes.csvText]     : showLocations,
                            [classes.displayNone] : !showLocations
                          })}>
                            <FontAwesomeIcon icon={faExclamationCircle} className={classes.skipIcon}/>
                              {`${unauthorizedLocations} ${setPlural(unauthorizedLocations, 'location')}`} {setPluralLocation(unauthorizedLocations)}
                          </Typography>
                          <Typography className={clsx({
                            [classes.csvText]     : showNoLocations,
                            [classes.displayNone] : !showNoLocations
                          })}>
                            <FontAwesomeIcon icon={faExclamationCircle} className={classes.skipIcon}/>
                              {`${usersWithNoLocation} ${setPlural(usersWithNoLocation, 'user')}`} {setPluralNoLocation(usersWithNoLocation)}
                          </Typography>
                        </Box>
                      </Grid>
                  }
                </Grid>
                {
                  isValidating ?
                    <CsvActionSkeleton />
                  :
                    <Grid container className={classes.action}>
                      <Grid item xs={12}>
                        <Box className={classes.dialogActions}>
                          <Button id="personsCSVModalImportButton" color="primary" variant="contained" className={classes.dialogSubmitButton}  type="submit">
                            {t('import')}
                          </Button>
                          <Button id="personsCSVModalCancelButton" color="primary" variant="outlined" className={classes.dialogSubmitButton} onClick={closeModal} >
                            {t('cancel')}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                }
              </Paper>
            </Form>
          )
        }
      }
    </Formik>
  );
}

const CsvModal = (props) => {
  const classes = useStyles();
  const { showModal, setShowModal, showToaster, setRemove, showLoading } = props;
  const [csvFile, setCsvFile]                               = useState();
  const [csvData, setCsvData]                               = useState();
  const [credentialSkip, setCredentialSkip]                 = useState();
  const [existingPersonSkip, setExistingPersonSkip]         = useState();
  const [invalidPersonSkip, setInvalidPersonSkip]           = useState();
  const [showCredential, setShowCredential]                 = useState(false);
  const [showExistingPerson, setShowExistingPerson]         = useState(false);
  const [showRoles, setShowRoles]                           = useState(false);
  const [showInvalidPersons, setShowInvalidPersons]         = useState(false);
  const [rolesSkip, setRolesSkip]                           = useState();
  const [invalidLocations, setInvalidLocations]             = useState(false);
  const [showInvalidLocations, setShowInvalidLocations]     = useState(false);
  const [unauthorizedLocations, setUnauthorizedLocations]   = useState(false);
  const [usersWithNoLocation, setUsersWithNoLocation]       = useState(false);
  const [showLocations, setShowLocations]                   = useState(false);
  const [showNoLocations, setShowNoLocations]               = useState(false);
  const [isFilePresent, setIsFilePresent]                   = useState(false);
  const [isFileInvalid, setIsFileInvalid]                   = useState(false);
  const [isValidating, setIsValidating]                     = useState(false);
  const [showDownloadModal, setShowDownloadModal]           = useState(false);
  const [reportData, setReportData]                         = useState('');
  const [importData, setImportData]                         = useState('');
  const csvInstance                                         = useRef(null);

  const { t } = useTranslation();

  const initialValues = {
    file      : '',
    csvFile   : csvFile
  }

  useEffect(() => {
    if (showModal) {
      handleClearModal();
    }
  }, [showModal]);

  useEffect(() => {
    if (reportData && csvInstance && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setReportData();
      });
    }
  }, [reportData]);

  const closeModal = () => {
    setShowModal(false);
  }

  const handleChange = (event) => {
    const csv       = event.target.files;
    const fileType  = csv[0].name.split('.').pop();
    let personData  = {};

    setCsvFile(csv[0].name);

    if (csv) {
      Papa.parse(csv[0], {
        complete: function (results) {
          let values = results.data[0].some((val) => val.charAt(0) === '#') ? results.data.slice(1) : results.data;
          values = values.filter(val => val[0].charAt(0) !== '#');
          let objects = values.map(array => {
            let object = {};
            CSV_HEADER.forEach((key, i) => object[key] = array[i]);
            return object;
          });
          results.data.shift();
          personData = { 
            "persons": objects.filter((object) => object.personalNumber !== '')
          };
          setIsFilePresent(true);
          setCsvData(personData);
          csvCheck(personData, fileType);
        }
      })
    }
  }

  const handleClearModal = () => {
    setShowRoles(false);
    setShowCredential(false);
    setShowExistingPerson(false);
    setShowInvalidPersons(false);
    setShowInvalidLocations(false);
    setShowLocations(false);
    setShowNoLocations(false);
    setCsvFile('');
    setIsFilePresent(false);
    setIsFileInvalid(false);
  }

  const csvCheck = async (data, fileType) => {
    setShowCredential(false);
    setShowExistingPerson(false);
    setShowInvalidPersons(false);
    setShowRoles(false);
    setShowInvalidLocations(false);
    setShowLocations(false);
    setShowNoLocations(false);
  
    const isInvalid = !data.persons.length || fileType !== 'csv' || data.persons.every(items => {
      return Object.values(items).some(value => value === undefined)
    });
    setIsFileInvalid(isInvalid);

    if (!isInvalid) {
      setIsValidating(true);
      try {
        const response = await request({
          url     : api.USER_CSV_CHECK,
          method  : POST,
          data    : data
        });
    
        const { existingCredentials, existingPersons, invalidPersons, nonexistentRoles, nonexistentLocations, unauthorizedLocations, usersWithNoLocation } = response.data;
        
        if (existingCredentials.length > 0 ) {
          setCredentialSkip(existingCredentials.length);
          setShowCredential(true);
        }
        if (existingPersons.length > 0) {
          setExistingPersonSkip(existingPersons.length);
          setShowExistingPerson(true);
        }
        if (invalidPersons.length > 0) {
          setInvalidPersonSkip(invalidPersons.length);
          setShowInvalidPersons(true);
        }
        if (nonexistentRoles.length > 0) {
          setRolesSkip(nonexistentRoles.length);
          setShowRoles(true);
        }
        if (nonexistentLocations.length > 0) {
          setInvalidLocations(nonexistentLocations.length);
          setShowInvalidLocations(true);
        }
        if (unauthorizedLocations.length > 0) {
          setUnauthorizedLocations(unauthorizedLocations.length);
          setShowLocations(true);
        }
        if(usersWithNoLocation.length > 0) {
          setUsersWithNoLocation(usersWithNoLocation.length)
          setShowNoLocations(true);
        }
      } catch (error) {
        if (error?.response?.status !== 500 && !isInvalid) {
          showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
        }
      } finally {
        setIsValidating(false);
      } 
    }
  }

  const handleUpload = async (values, formik) => {
    const { setErrors } = formik;

    if(!isFilePresent || isFileInvalid) {
      setErrors({
        csvInput: isFileInvalid ? t(`invalidCsvFile`) :  t(`csvInputError`)
      });
      return;
    }

    setShowModal(false);
    showLoading(true);
  
    try {
      const response = await request({
        url     : api.USER_UPLOAD_CSV,
        method  : POST,
        data    : {
          ...csvData
        }
      });
      const { data } = response;
      setImportData(data);
      showToaster(t(`success`),t(`uploadSuccess`),'success');
      showLoading(true);
    } catch {
      showToaster(t(`error`),t(`incompatibleFile`),'error');
    } finally {
      showLoading(false);
      handleClearModal();
      setRemove(true);
      setShowDownloadModal(true);
    }
  }

  const handleCancelDownload = () => {
    setShowDownloadModal(false);
  }

  const handleDownload = async () => {
    setShowDownloadModal(false);

    const splitData = importData.split(/\r?\n/).map(data => {
      const dataColumn = data.split(';');
      return {
        state         : dataColumn[0],
        personalNumber: dataColumn[1],
        firstName     : dataColumn[2],
        lastName      : dataColumn[3],
        credential    : dataColumn[4],
        validFrom     : dataColumn[5],
        validUntil    : dataColumn[6],
        roleName      : dataColumn[7],
        location      : dataColumn[8]
      }
    });

    setReportData(splitData.slice(1, splitData.length-1));
  }

  const importReportHeader = [
    {label: `#${t('importState')}`, key: 'state'},
    {label: t('personalNumber'), key: 'personalNumber'},
    {label: t('firstName'), key: 'firstName'},
    {label: t('lastName'), key: 'lastName'},
    {label: t('credential'), key: 'credential'},
    {label: t('validFrom'), key: 'validFrom'},
    {label: t('validUntil'), key: 'validUntil'},
    {label: t('roleName'), key: 'roleName'},
    {label: t('location'), key: 'location'}
  ]

  const getFilename = () => {
    return (`${t('importReport')}.csv`);
  }

  return (
    <>
    { reportData ? <CSVLink data={reportData} ref={csvInstance} headers={importReportHeader} filename={getFilename()} /> : undefined }
      <Dialog
        modal="false"
        open={showModal}
        aria-labelledby="row-delete-dialog"
        aria-describedby="row-delete-dialog-table"
        className={classes.dialog}
      >
        <Content
          initialValues={initialValues}
          handleSubmit={handleUpload}
          closeModal={closeModal}
          handleChange={handleChange}
          csvFile={csvFile}
          existingPersonSkip={existingPersonSkip}
          showExistingPerson={showExistingPerson}
          invalidPersonSkip={invalidPersonSkip}
          credentialSkip={credentialSkip}
          showCredential={showCredential}
          showInvalidPersons={showInvalidPersons}
          rolesSkip={rolesSkip}
          showRoles={showRoles}
          isFilePresent={isFilePresent}
          isValidating={isValidating}
          invalidLocations={invalidLocations}
          showInvalidLocations={showInvalidLocations}
          unauthorizedLocations={unauthorizedLocations}
          usersWithNoLocation={usersWithNoLocation}
          showLocations={showLocations}
          showNoLocations={showNoLocations}
        />
      </Dialog>
      <Dialog
        modal="false"
        open={showDownloadModal}
        aria-labelledby="row-delete-dialog"
        aria-describedby="row-delete-dialog-table"
      >
        <DownloadReportModal
          handleCancelDownload={handleCancelDownload}
          handleDownload={handleDownload}
        />
      </Dialog >
    </>
  );
}

export default CsvModal;