import { Box, Button, Container, Grid, IconButton, Paper, Tab, Tabs, TextField, Tooltip, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import React, { memo, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory } from 'react-router';
import DeleteModal from '../../components/delete-modal';
import SelectItems from '../../components/select-items';
import Table, { createColumn } from '../../components/table';
import Title from '../../components/title';
import UnsavedModal from '../../components/unsaved-modal';
import { AuthContext } from '../../context/authContext';
import { LocationContext } from '../../context/locationContext';
import api from '../../service/api';
import { multipleRequest, request } from '../../service/requests';
import { ACTION_VIEW, API_REQUEST_ERROR_MESSAGE, ASCENDING, DATE_FORMAT, DATE_FORMAT_YYYY_MM_DD, DELETE, DESCENDING, EVENT_FILTER_FORMAT2, GET, HOLIDAYS_MODULE, HOLIDAY_ITEMS_MODULE, LOCATIONS_MODULE, MAX_CHARACTER_LIMIT, PATCH, POST, PROJECTION, PUT } from '../../utility/constants';
import { convertToUTC, parseIntegerParam } from '../../utility/helper';
import { GetInitialLocationId, GetInitialLocationObject, assignLocation, getLocation, hasLocationChange, unassignLocation } from '../../utility/location';
import { holidaySchema } from '../../validation/schema';
import { HolidaySkeleton } from '../holiday/holiday-import-skeleton';
import CreateModal from './holiday-create-modal';
import ImportModal from './holiday-import-modal';
import useStyles from './styles';
import moment from 'moment';

export const columns = [
  createColumn("name", "holidayName", true, "string", false, false),
  createColumn('date', 'date', true, 'numeric', false, true),
  createColumn("holidayItemId", "Holiday Item Id", false, "string", true),
  createColumn("isChanged", "isChanged", false, "boolean", true),
];

const Content = memo((props) => {
  const classes = useStyles();
  const { setAreItemsSelected, disabled, data, isLoading, onChangePage, onDelete, onMultipleDelete, onRowsPerPageChange, onSort, onUpdate, orderBy, order, page, rowsPerPage, totalItems, onOpenImportModal, onOpenCreateModal, handlePermissions } = props;
  return (
    <>
      <Grid container spacing={2} className={classes.form}>
        <Grid container >
          <Grid item container className={classes.form} >
            <Table
              disabled={disabled}
              columns={columns}
              data={data}
              isLoading={isLoading}
              label={HOLIDAY_ITEMS_MODULE}
              module={HOLIDAYS_MODULE}
              onChangePage={onChangePage}
              onDelete={onDelete}
              onMultipleDelete={onMultipleDelete}
              onRowsPerPageChange={onRowsPerPageChange}
              onSort={onSort}
              onUpdate={onUpdate}
              orderBy={orderBy}
              order={order}
              page={page}
              rowsPerPage={rowsPerPage}
              totalItems={totalItems}
              onOpenImportModal={onOpenImportModal}
              onOpenCreateModal={onOpenCreateModal}
              setAreItemsSelected={setAreItemsSelected}
              handlePermissions={handlePermissions}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
})

const TabContainer = (props) => {
  const { match, handleShow, handleHide, handleChangeTab, tabValue } = props;
  const path = match.path;
  const { id } = props.match.params;
  const classes             = useStyles();
  const { t }               = useTranslation();

  return (
    <Tabs className={classes.tab}
      value={tabValue}
      onChange={handleChangeTab}
      indicatorColor="primary">
      <Tab id="holidayCurrentHolidaysTab" onClick={handleHide} className={!id && 'hidden'} label={<span className={classes.tabLabel}>{t('currentHolidays')}</span>}> </Tab>
      <Tab id="holidayNewHolidaysTab" onClick={handleShow} className={path.includes('view') && 'hidden'} label={<span className={classes.tabLabel}>{t('newHolidays')}</span>}> </Tab>
    </Tabs>
  )
}

const Holiday = (props) => {
  const { showToaster, match, showLoading, handlePermissions } = props;

  const classes   = useStyles();
  const history   = useHistory();
  const { t }     = useTranslation();

  const { state: authContext }  = useContext(AuthContext);
  const { administrator }  = authContext;
  
  const { state }  = useContext(LocationContext);

  const { id }   = props.match.params;

  const { search } = history.location;

  const path     = match.path;
  const disabled = path.includes('view');

  const params     = new URLSearchParams(search);
  const pageSize   = params.get('size') ? parseIntegerParam(params.get('size'), 10) : 10;
  const pageNumber = params.get('page') ? parseIntegerParam(params.get('page'), 1) : 1;
  const totalSize  = pageSize > 100 ? 100 : pageSize;

  const initialLocationId = GetInitialLocationId();
  const initialLocationObject = GetInitialLocationObject();

  const [holidays, setHolidays]                                 = useState([]);
  const [createdHolidays, setCreatedHolidays]                   = useState([]);
  const [createdHolidayPage, setCreatedHolidayPage]             = useState([]);
  const [forDelete, setForDelete]                               = useState([]);
  const [forDeleteName, setForDeleteName]                       = useState([]);
  const [order, setOrder]                                       = useState(ASCENDING);
  const [orderBy, setOrderBy]                                   = useState(columns[1].id);
  const [page, setPage]                                         = useState(pageNumber === 0 ? 1 : pageNumber);
  const [remove, setRemove]                                     = useState(false);
  const [rowsPerPage, setRowsPerPage]                           = useState(totalSize);
  const [newHolidayOrderBy, setNewHolidayOrderBy]               = useState(columns[1].id);
  const [newHolidayOrder, setNewHolidayOrder]                   = useState(ASCENDING);
  const [newHolidayPage, setNewHolidayPage]                     = useState(pageNumber === 0 ? 1 : pageNumber);
  const [newHolidayRowsPerPage, setNewHolidayRowsPerPage]       = useState(totalSize);
  const [totalHolidays, setTotalHolidays]                       = useState(0);
  const [type, setType]                                         = useState(''); // for modal, whether update or create 
  const [showImportModal, setShowImportModal]                   = useState(false);
  const [showCreateModal, setShowCreateModal]                   = useState(false);
  const [deleteItemList, setDeleteItemList]                     = useState([]);
  const [isLoading, setIsLoading]                               = useState(false);
  const [prevName, setPrevName]                                 = useState('');
  const [name, setName]                                         = useState('');
  const [itemId, setItemId]                                     = useState({});
  const [itemValue, setItemValue]                               = useState({});
  const [showModal, setShowModal]                               = useState(false);
  const [showUnsavedModal, setShowUnsavedModal]                 = useState(false);
  const [withChanges, setWithChanges]                           = useState(false);
  const [showNewHolidays, setShowNewHolidays]                   = useState(false);
  const [areItemsSelected, setAreItemsSelected]                 = useState();
  const [toRedirect, setToRedirect]                             = useState('');
  const [tabValue, setTabValue]                                 = useState(0);
  const [changesHolidayItem, setChangesHolidayItem]             = useState([]);
  const [holidayItemIdParam, setHolidayItemIdParam]             = useState([0]);
  const [holidayItemIdDelParam, setHolidayItemIdDelParam]       = useState([0]);
  const [holidayItemIdEditParam, setHolidayItemIdEditParam]     = useState([0]);
  const [location, setLocation]                                 = useState('');
  const [selectedLocation, setSelectedLocation]                 = useState(initialLocationId);
  const [locationObject, setLocationObject]                     = useState(initialLocationObject);
  const [profiles, setProfiles]                                 = useState([]);
  
  const initialValues = {
    name      : name,
    item      : holidays,
    newItem   : createdHolidays,
    location  : selectedLocation || selectedLocation === undefined ? selectedLocation : location
  }

  useEffect(() => {
    if (id) {
      remove ? setIsLoading(false) : setIsLoading(true);
      getHolidayAndHolidayItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, order, orderBy, rowsPerPage, holidayItemIdDelParam]);

  useEffect(() => {
    arrangeNewHolidays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newHolidayOrder, newHolidayPage, newHolidayRowsPerPage, createdHolidays]);
  
  useEffect(() => {
    setHolidayItemIdParam(holidayItemIdDelParam.concat(holidayItemIdEditParam));
  }, [holidayItemIdDelParam, holidayItemIdEditParam]);
  
  useEffect(() => {
    setShowNewHolidays(id ? false : true);
    setTabValue(id ? 0 : 1);
    return () => {
      setName();
      setIsLoading();
    };
  }, [id]);
  
  useEffect(() => {
    handleChanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, prevName, holidays, createdHolidays, deleteItemList, location, selectedLocation]);

  const handleCancel = () => {
    history.push('/holidays');
  }

  const getToastMessage = () => {
    return id ? t(`updated`) : t(`created`);
  }
  
  const getHolidayOrderBy = (newHolidayCondition) => {
    if (newHolidayOrder === ASCENDING) {
      return newHolidayCondition ? 1 : -1
    } else {
      return newHolidayCondition ? -1 : 1
    }
  }

  const arrangeNewHolidays = () => {
    if (createdHolidays.length) {
      const newHoliday = createdHolidays?.sort((holidayA, holidayB) => {
        const holidayADate = convertToUTC(holidayA.date, DATE_FORMAT).format(EVENT_FILTER_FORMAT2);
        const holidayBDate = convertToUTC(holidayB.date, DATE_FORMAT).format(EVENT_FILTER_FORMAT2);
        const newHolidayCondition = newHolidayOrderBy === 'date' ? holidayADate > holidayBDate : holidayA.name > holidayB.name;

        return getHolidayOrderBy(newHolidayCondition);
      });

      let dividedHolidays = [];
      for (let i = 0; i < newHoliday.length; i += newHolidayRowsPerPage) {
        const chunk = newHoliday.slice(i, i + newHolidayRowsPerPage);
        dividedHolidays.push(chunk);
      }
      setCreatedHolidayPage(dividedHolidays[newHolidayPage - 1]);
    } else {
      setCreatedHolidayPage([]);
    }
  }

  const getHolidayAndHolidayItems = async () => {
    setIsLoading(true);
    try {
      const responses = await multipleRequest([
        request({
          url    : api.HOLIDAYS_BY_ID,
          method : GET,
          params : {
            holidayId  : id,
            projection : PROJECTION.HOLIDAYS
          }
        }),
        request({
          url     : id ? api.HOLIDAY_ITEMS_FIND_BY_HOLIDAY_ID_NOT : `${api.HOLIDAY_ITEMS}`,
          method  : GET,
          params  : {
            holidayId         : id,
            holidayItemIds    : holidayItemIdDelParam.join(),
            name              : '',
            size              : rowsPerPage,
            page              : page - 1,
            sort              : `${orderBy},${order}`
          }
        })
      ]); 
      
      const { number, size, totalElements } = responses[1].data.page;
      const newHoliday = responses[1].data._embedded.holidayItems.map(item => {
        const link   = item._links.holidayItem.href;
        const idItem = link.replace(`${api.HOLIDAY_ITEMS}/`, '');

        const isHolidayChanged = changesHolidayItem.find(changesItem => changesItem.holidayItemId === idItem);

        if (isHolidayChanged) {
          return isHolidayChanged
        }

        return {
          name              : item.name,
          date              : convertToUTC(item.date).format(DATE_FORMAT),
          holidayItemId     : idItem,
          isChanged         : false,
        }
      });

      if (newHoliday.length || number === 0) {
        setPage(number + 1)
        setHolidays(newHoliday);
      } else {
        setPage(number)
      }

      const { name, profiles, location } = responses[0].data;

      const profileIds = profiles.map(profile => profile.profileId);

      setRowsPerPage(size);
      setTotalHolidays(totalElements);
      setPrevName(name); 
      setProfiles(profileIds);

      if (prevName !== name) {
        setName(name);
        getLocation(responses[0].data, setLocation, setLocationObject);
        setSelectedLocation(location.locationId);
      }
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setIsLoading(false);
    }
  }
  

  const handleSort = (newOrderBy, newOrder) => {
    if (showNewHolidays) {
      setNewHolidayPage(1);
      setNewHolidayOrderBy(newOrderBy);
      setNewHolidayOrder(newHolidayOrder === ASCENDING ? DESCENDING : ASCENDING);
    } else {
      setPage(1);
      setOrderBy(newOrderBy);
      setOrder(newOrder);
    }
  }

  const handleRowsPerPageChange = (newRowsPerPage) => {
    if (showNewHolidays) {
      setNewHolidayPage(1);
      setNewHolidayRowsPerPage(newRowsPerPage);
    } else {
      setPage(1);
      setRowsPerPage(newRowsPerPage);
    }
  }

  const handleChangePage = (newPage) => {
    showNewHolidays ? setNewHolidayPage(newPage + 1) : setPage(newPage + 1);    
  }

  const handleUpdate = (value) => {
    const { holidayItemId } = value;
    setItemValue(value);
    setItemId(holidayItemId);
    setType('update');
    setShowCreateModal(true);
  }

  const handleDelete = (value) => {
      handleOpenDeleteModal(value, value.name, 'single');
  }

  const handleMultipleDelete = (values) => {
    if (values.length === 1) {
      handleOpenDeleteModal(values[0], values[0].name, 'single');
    }
     else {
      handleOpenDeleteModal(values, '', 'multiple');
    }
  }

  const singleDelete = (value) => {
    const delHoliday = {
      name          : value.name,
      date          : value.date,
      holidayItemId : value.holidayItemId,
    }
    if (showNewHolidays) {
      setCreatedHolidays(items => items.filter(item => item.holidayItemId !== delHoliday.holidayItemId));
      setNewHolidayPage(0);
    } else {
      setDeleteItemList(item => item.concat(delHoliday));
      setHolidayItemIdDelParam(item => item.concat(delHoliday.holidayItemId));
    }
  }

  const multipleDelete = (values) => {
    const delHolidays = values.map(value => {
      return {
        name              : value.name,
        date              : value.date,
        holidayItemId     : value.holidayItemId,
      }
    });

    const delHolidaysId = values.map(value => value.holidayItemId);

    if (showNewHolidays) {
      setCreatedHolidays(items => items.filter(item => !delHolidays.find(del => del.holidayItemId === item.holidayItemId)));
      setAreItemsSelected(0);
      setNewHolidayPage(0);
    } else {
      setDeleteItemList(item => item.concat(delHolidays));
      setHolidayItemIdDelParam(item => item.concat(delHolidaysId));
      setAreItemsSelected(0);
    }
  }

  const handleOpenDeleteModal = (value, valueName, paramType) => {
    setForDelete(value);
    setForDeleteName(valueName);
    setType(paramType);
    setShowModal(true);
    setAreItemsSelected(0);
  }

  const handleCloseDeleteModal = () => {
    setShowModal(false);
    setAreItemsSelected(0);
  }

  const handleSubmit = (values, formik) => {
    const { setSubmitting } = formik;

    if (!withChanges) {
      setSubmitting(false);
      setWithChanges(false);
      history.push('/holidays');

      return;
    }
    if (createdHolidays.length || holidays.length) {    
      setWithChanges(false);
      deleteHolidayItem(deleteItemList, values, formik);
    } else {
      setSubmitting(false);
      showToaster('', t('noHolidayItem'), 'warning');
    }
  }

  const checkHolidayItemChange = () => {
    return holidays.some(holidayItem => holidayItem.isChanged);
  }

  const assignLocationToHoliday = async (link) => {
    if (location === locationObject[0]?.locationId) {
      return;
    }

    if (location && selectedLocation) {
      await unassignLocation(link);
    } 

    await assignLocation(link, selectedLocation);
  }

  const assignProfileToHoliday = async (links) => {
    if (profiles == null) {
      return;
    }

    try {
      const profileUrl = profiles.map(profileId => `${api.PROFILES}/${profileId}`).join(`\r\n`);
      await request({
        url     : `${links}/profiles`,
        method  : PUT,
        headers : {
          'Content-Type' : 'text/uri-list',
          'x-type'       : true
        },
        data    : profileUrl
      });
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }
  }

  const createHoliday = async (values, formik) => {
    const { setErrors, setSubmitting } = formik;
    try {
      const response = await request({
        url   : id ? `${api.HOLIDAYS}/${id}` : api.HOLIDAYS,
        method: id ? PATCH : POST,
        data  : {
          name: values.name
        }
      });

      const link = response.data._links.self.href;
      
      await manipulateHolidayItems(values, link);
      await assignLocationToHoliday(link);

      if (holidayDataChanges()) {
        await assignProfileToHoliday(link);
      }

      showToaster(t(`success`), `${values.name} ${t('hasBeen')} ${getToastMessage()}`, 'success');
      setSubmitting(false);
      history.push('/holidays');
    } catch (error) {
      if (error?.response?.status === 409) {
        setErrors({
          name: t(`nameAlreadyExists`)
        });
      } else {
        showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
      }
      setWithChanges(true);
      setSubmitting(false);
    }
  }
    
  const manipulateHolidayItems = async (values, links) => {
    let requests = [];
    if (checkHolidayItemChange()) {
      const changedHolidayItems = holidays.filter(item => item.isChanged);
      requests.push(
        ...changedHolidayItems.map(item => {
        return request({
          url     : `${api.HOLIDAY_ITEMS}/${item.holidayItemId}` ,
          method  : PATCH,
          data    : 
          {
            name : item.name,
            date : moment(item.date, 'DD.MM.YYYY').format(DATE_FORMAT_YYYY_MM_DD),
          }
        })
      }));
    }
    if (createdHolidays.length) {
      const holidayItem = values.newItem.map(value => {
        delete value['holidayItemId'];
        return {
          ...value,
          holidayId: links.replace(`${api.HOLIDAYS}/`, '')
        }
      });

      requests.push(...holidayItem.map(item => {
        return request({
          url     : api.HOLIDAY_ITEMS,
          method  : POST,
          data    : {
            name : item.name,
            date : moment(item.date, 'DD.MM.YYYY').format(DATE_FORMAT_YYYY_MM_DD),
            holiday : {
              holidayId: item.holidayId
            }
          }
        })
      }));
    }

    if (requests.length) {
      try {
        const addUpdateHolidayResponses = await multipleRequest(requests);
        addUpdateHolidayResponses.map(multipleResponse => console.log(`${multipleResponse.config.method.toUpperCase()} ${multipleResponse.config.url}`, multipleResponse));
        showToaster(t(`success`), `${values.name} ${t('hasBeen')} ${getToastMessage()}`, 'success');
      } catch {
        showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
      } finally {
        setWithChanges(false);
      }
    } else {
      showToaster(t(`success`), `${values.name} ${t('hasBeen')} ${getToastMessage()}`, 'success');
      setWithChanges(false);
    }
  }

  const deleteHolidayItem = async (deleteItems, values, formik) => {
    const { setSubmitting } = formik;
    try {
      if (deleteItemList.length) {
        const deleteResponses = await multipleRequest(
          deleteItems.map(item =>
            request({
              url: `${api.HOLIDAY_ITEMS}/${item.holidayItemId}`,
              method: DELETE,
            })
          )
        )
        deleteResponses.map(response => console.log(`${response.config.method.toUpperCase()} ${response.config.url}`, response));
      }
      createHoliday(values, formik);
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setWithChanges(false);
      setSubmitting(true);
    }
  }

  const holidayDataChanges = () => {
    return prevName !== name || checkHolidayItemChange() || createdHolidays.length > 0 || deleteItemList.length > 0;
  }

  const handleChanges = () => {
    if (holidayDataChanges() || hasLocationChange(location, selectedLocation, administrator.locations.length, state)) {
      if (!withChanges) {
        setWithChanges(true);
      }
    } else {
      if (withChanges) {
        setWithChanges(false);
      }
    }
  }

  const onOpenImportModal = () => {
    setTabValue(1);
    handleShowNewHolidayItem();
    setShowImportModal(true);
  }
  
  const onOpenCreateModal = () => {
    setTabValue(1);
    handleShowNewHolidayItem();
    setItemValue();
    setItemId();
    setType('create');
    setShowCreateModal(true);
  }

  const handleCancelUnsavedModal = () => {
    handleChanges();
    setShowUnsavedModal(false);
  }

  const handleCloseUnsavedModal = () => {
    setShowUnsavedModal(false);
  }

  const handleSubmitUnsavedModal = () => {
    setWithChanges(false);
    history.push(toRedirect);
  }

  const handleShowNewHolidayItem = () => {
    setShowNewHolidays(true);
  }

  const handleHideNewHolidayItem = () => {
    setShowNewHolidays(false);
  }

  const getButtonLabel = () => {
    return id ? `${t('update')}` : `${t('create')}`;
  }

  const handleChangeTab = (_event, newValue) => {
    setTabValue(newValue);
  }

  const handleSelectedLocation = (value) => {
    setSelectedLocation(value[0]?.locationId);
    setLocationObject(value);
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <UnsavedModal
        open={showUnsavedModal}
        onClose={handleCloseUnsavedModal}
        handleModalSubmit={handleSubmitUnsavedModal}
        handleModalCancel={handleCancelUnsavedModal}
      />
      <Prompt
        when={withChanges}
        message={(location, action) => {
          if (action === 'PUSH') {
            setShowUnsavedModal(true)
          }
          setWithChanges(false);
          setToRedirect(location.pathname);
          return location.pathname === '/' || location.pathname.startsWith('/holidays/update') || location.pathname.startsWith('/holidays/create')
        }}
      />
      <DeleteModal
        assocValues={''}
        forDeleteName={forDeleteName}
        initialValues={forDelete}
        module={HOLIDAYS_MODULE}
        multipleDelete={multipleDelete}
        onClose={handleCloseDeleteModal}
        open={showModal}
        showToaster={showToaster}
        singleDelete={singleDelete}
        type={type}
      />
      <ImportModal
        showToaster={showToaster}
        showModal={showImportModal}
        setShowModal={setShowImportModal}
        setRemove={setRemove}
        showLoading={showLoading}
        setHolidays={setCreatedHolidays}
        holidays={holidays}
        createdHolidays={createdHolidays}
        holidayId={id}
        holidayItemIdDelParam={holidayItemIdDelParam}
        holidayItemIdParam={holidayItemIdParam}
        changesHolidayItem={changesHolidayItem}
      />
      <CreateModal
        showToaster={showToaster}
        showModal={showCreateModal}
        setShowModal={setShowCreateModal}
        showLoading={showLoading}
        setHolidays={setHolidays}
        setCreatedHolidays={setCreatedHolidays}
        holidayId={id}
        id={itemId}
        itemValue={itemValue}
        type={type}
        tabSelected={showNewHolidays ? 'NewHolidays' : 'CurrentHolidays'}
        holidays={holidays}
        createdHolidays={createdHolidays}
        setChangesHolidayItem={setChangesHolidayItem}
        changesHolidayItem={changesHolidayItem}
        setHolidayItemIdEditParam={setHolidayItemIdEditParam}
        holidayItemIdParam={holidayItemIdParam}
      />
      <Box>
        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={holidaySchema}
        >
        {
          formik => {
            return(
              <Form>
                {showLoading(formik.isSubmitting)}
                <Title title={t(`holidayList`)} subtitle={name} />
                <Typography className={'bold'} color='secondary'>{t('details')}</Typography>
                <Paper className={classes.paper} elevation={3}>
                  {
                    isLoading ? <HolidaySkeleton/> : 
                    <Grid container spacing={1} className={path.includes(ACTION_VIEW) && classes.header}>
                      <Grid item xs={6}>
                        <TextField
                          inputProps={{
                            readOnly: disabled,
                            maxlength: MAX_CHARACTER_LIMIT.TEXT_FIELD
                          }}
                          disabled={disabled}
                          fullWidth
                          multiline
                          id="holidayCalendarName"
                          label={`${t('holidayCalendarName')}*`}
                          name="name"
                          value={name}
                          onChange={event => setName(event.target.value)}
                          error={formik.touched.name && Boolean(formik.errors.name)}
                          helperText={t(formik.touched.name) && t(formik.errors.name)}
                        />
                      </Grid>
                      <Grid item xs={4} className={clsx(!handlePermissions(LOCATIONS_MODULE, GET) && 'hidden')}>
                        <SelectItems 
                          id="holidayLocation"
                          disabled={disabled}
                          helperText={formik.touched.location && t(formik.errors.location)}
                          isValid={formik.touched.location && Boolean(formik.errors.location)}
                          name="Locations"
                          onChange={handleSelectedLocation} 
                          selectedItems={locationObject}
                          showToaster={showToaster} 
                          single={true}
                          required={true}
                          handlePermissions={handlePermissions}
                        />
                      </Grid>
                      <Grid item xs={2} align="right" className={clsx(path.includes('view') ? '': 'hidden')}>
                        <Tooltip title={t('update')}  className={clsx(handlePermissions(HOLIDAYS_MODULE, PATCH) && disabled ? '' : 'hidden')}>
                          <IconButton id="holidayUpdateButton" aria-label="Update" onClick={() => history.push(`../update/${id}`)}>
                            <Edit className={classes.editIcon} />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  }
                  <Grid container className={clsx(areItemsSelected > 0 ? 'hidden' :classes.tabContainer)}>
                    <Grid item>
                      <TabContainer className={classes.tabCon}
                        match={match}
                        handleShow={handleShowNewHolidayItem}
                        handleHide={handleHideNewHolidayItem}
                        handleChangeTab={handleChangeTab}
                        tabValue={tabValue}         
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} className={classes.holidayTable}>
                    <Content
                      disabled={disabled}
                      data={showNewHolidays ? createdHolidayPage : holidays}
                      isLoading={isLoading}
                      onChangePage={handleChangePage}
                      onDelete={handleDelete}
                      onMultipleDelete={handleMultipleDelete}
                      onRowsPerPageChange={handleRowsPerPageChange}
                      onSort={handleSort}
                      onUpdate={handleUpdate}
                      orderBy={showNewHolidays ? newHolidayOrderBy : orderBy}
                      order={showNewHolidays ? newHolidayOrder : order}
                      page={showNewHolidays ? newHolidayPage : page}
                      rowsPerPage={showNewHolidays ? newHolidayRowsPerPage : rowsPerPage}
                      totalItems={showNewHolidays ? createdHolidays.length : totalHolidays}
                      onOpenImportModal={onOpenImportModal}
                      onOpenCreateModal={onOpenCreateModal}
                      setAreItemsSelected={setAreItemsSelected}
                      handlePermissions={handlePermissions}
                    />
                  </Grid>
                  <Grid container className={clsx(disabled ? 'hidden' : classes.action)}>
                    <Grid item xs={12}>
                      <Button
                        id="holidayCancelButton"
                        className={classes.button}
                        onClick={handleCancel}
                        variant="outlined"
                        color="primary"
                      >
                        {t('cancel')}
                      </Button>
                      <Button
                        id="holidaySubmitButton"
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        { getButtonLabel() }
                      </Button>
                    </Grid>  
                  </Grid>
                </Paper>
              </Form>
            )
          }
        }
        </Formik>
      </Box>
    </Container>
  );
}

export default Holiday;