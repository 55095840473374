import { Box, Container, IconButton, Paper, Tooltip } from '@material-ui/core';
import { Add as AddIcon } from "@material-ui/icons";
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import AreaItem from '../../components/area-item';
import Empty from '../../components/empty';
import LocationItem from '../../components/location-item';
import LocationMenu from '../../components/location-menu';
import LocationTextField from '../../components/location-text-field';
import Title from '../../components/title';
import { AuthContext } from '../../context/authContext';
import { LocationContext } from '../../context/locationContext';
import { UPDATE_ACCOUNT } from '../../reducer/authReducer';
import { SET_COUNT } from '../../reducer/locationReducer';
import { createArea, createLocation, getLocationCount, getParentAreasAndLocations } from '../../service/locationsAPI';
import { ACTION_CREATE, LOCATIONS_ERROR_TOASTER, LOCATIONS_SUCCESS_TOASTER, LOCATIONS_TEXT_FIELD_LABEL } from '../../utility/constants';
import { formatLocationsSubAreas, setLocalStorageItem } from '../../utility/helper';
import LocationsSkeleton from './locations-skeleton';
import useStyles from "./styles";

const Locations = (props) => {
  const { t } = useTranslation();
  const { showToaster, showLoading } = props;
  const classes  = useStyles();
  const { state : administratorState, dispatch : dispatchAdministrator } = useContext(AuthContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [showTextField, setShowTextField] = useState(false);
  const [label, setLabel] = useState('');
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [parentId, setParentId] = useState(1);
  const [open, setOpen] = useState(false);
  const { dispatch } = useContext(LocationContext);
  
  const wrapperRef = useRef(null);
  
  const history    = useHistory();

  const initialValue = {
    name: ''
  }

  const handleGetAreas = useCallback(async() => {
    try {
      const response = await getParentAreasAndLocations();
      const { areas } = response._embedded;
      if (!areas.length) {
        setData(areas);
        return;
      }

      const { locations, subAreas, areaId } = areas[0];
      
      const locationsSubAreas = locations.concat(subAreas);
      
      locationsSubAreas.sort((a, b) => a.name.localeCompare(b.name));
      const formattedData = formatLocationsSubAreas(locationsSubAreas);

      const count = await getLocationCount();
      dispatch({type: SET_COUNT, payload: count });

      setData(formattedData);
      setParentId(areaId);
    } catch {
      showToaster(
        t(LOCATIONS_ERROR_TOASTER.TITLE), 
        t(LOCATIONS_ERROR_TOASTER.GET_LOCATION_DESCRIPTION), 
        LOCATIONS_ERROR_TOASTER.SEVERITY
      );
    } finally {
      setIsLoading(false);
    }
  }, [t, showToaster, dispatch]);

  useEffect(() => {
    const location = {
      pathname: history.location.pathname
    };

    history.replace(location);
    setIsLoading(true);
    handleGetAreas();
  }, [handleGetAreas, history, dispatch]);
  
  const handleCreateButton = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(prev => !prev);
  }

  const handleCloseCreateButton = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleCreateLocation = () => {
    setOpen(false);
    setAnchorEl(null);
    setLabel(t(LOCATIONS_TEXT_FIELD_LABEL.LOCATION_NAME));
    setShowTextField(true);
  }
  const handleCreateArea = () => {
    setOpen(false);
    setAnchorEl(null);
    setLabel(t(LOCATIONS_TEXT_FIELD_LABEL.AREA_NAME));
    setShowTextField(true);
  }

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showTextField]);

  const handleHideTextFiled = useCallback(() => {
    setShowTextField(false);
  }, []);

  const showCreateToaster = (name, type) => {
    const { CREATE_LOCATION_DESCRIPTION, CREATE_AREA_DESCRIPTION, TITLE, SEVERITY} = type;
    const isLocation = label === t(LOCATIONS_TEXT_FIELD_LABEL.LOCATION_NAME);
    const message =  isLocation ? CREATE_LOCATION_DESCRIPTION : CREATE_AREA_DESCRIPTION;

    showToaster(t(TITLE), <Trans i18nKey={message} values={{ name }}/>, SEVERITY);
  }

  const handleSubmit = async (values, formik) => {
    const { setErrors } = formik;

    const errorMessage = label === t(LOCATIONS_TEXT_FIELD_LABEL.LOCATION_NAME) ? 
      t('locations-page.locationNameAlreadyExists') : t('locations-page.areaNameAlreadyExists');

    try {
      let locationsSubAreas = [];
      if (label === t(LOCATIONS_TEXT_FIELD_LABEL.LOCATION_NAME)) {
        locationsSubAreas = await createLocation(values.name, parentId, data);
        updateAdministratorState(locationsSubAreas, values.name);
      } else {
        locationsSubAreas = await createArea(values.name, parentId, data);
      }
      
      await handleGetAreas();
      setData(locationsSubAreas);
      setShowTextField(false);

      showCreateToaster(values.name, LOCATIONS_SUCCESS_TOASTER);
    } catch (error) {
      if (error?.response?.status === 409) {
        setErrors({
          name: errorMessage
        });
      } else {
        showCreateToaster(values.name, LOCATIONS_ERROR_TOASTER);
      }
    }
  }

  const updateAdministratorState = (locationsSubAreas, locationName) => {
    const location = locationsSubAreas.find(location => location.name === locationName && location.locationId);
    if (!location) {
      return;
    }

    const administrator = {
      ...administratorState.administrator,
      locations: [
        ...administratorState.administrator.locations,
        {
          administratorId: administratorState.administrator.administratorId,
          name: locationName,
          locationId: location.locationId
        }
      ]
    }
    
    setLocalStorageItem("locations", administrator.locations);

    const administratorPayload = {
       ...administratorState,
       administrator: administrator
    }
     
    dispatchAdministrator({type: UPDATE_ACCOUNT, payload: administratorPayload});
  }

  const handleUpdateList = async (values) => {
    await handleGetAreas();
  }  

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Title title={t('locations-page.title')}/>
      <Box className={classes.iconBox}>
      <Tooltip title={t('addNew')}>
        <IconButton
          id="locationCreateButton"
          aria-label={`${ACTION_CREATE}`} 
          onClick={handleCreateButton}
        >
          <AddIcon className={classes.iconButton} />
        </IconButton>
      </Tooltip>
      <LocationMenu
        handleCreateLocation={handleCreateLocation}
        handleCreateArea={handleCreateArea}
        handleCloseCreateButton={handleCloseCreateButton}
        anchorEl={anchorEl}
        open={open}
        areaLabel={t('locations-page.addArea')}
      />
      </Box>
      <Paper className={classes.paper} elevation={3}>
        {
          isLoading ?
            <LocationsSkeleton />
          :
            <>
              {
                data.length ?
                  <Box className={classes.locationContainer}>
                    {
                      data.map((item, index) => (
                        item.areaId ?
                          <AreaItem
                            key={`${item.areaId}-${item.name}-${index}`} 
                            name={item.name}
                            showToaster={showToaster}
                            showLoading={showLoading}
                            id={item.areaId}
                            data={item.data}
                            level={item.level}
                            handleUpdateList={handleUpdateList}
                          />
                        :
                          <LocationItem 
                            key={`${item.locationId}-${item.name}-${index}`} 
                            id={item.locationId}
                            name={item.name}
                            showToaster={showToaster}
                            showLoading={showLoading}
                            handleUpdateList={handleUpdateList}
                          />
                        )
                      )
                    }
                    {
                      showTextField ?
                        <LocationTextField 
                          wrapperRef={wrapperRef}
                          label={label}
                          handleSubmit={handleSubmit}
                          initialValue={initialValue}
                          handleHideTextFiled={handleHideTextFiled}
                        />
                      :
                      <></>
                    }
                  </Box>
                :   
                  <Empty variant={'h6'}/>
              }
            </>
        }
      </Paper>
    </Container>
  )
}

export default Locations