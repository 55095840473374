import { createContext, useEffect, useReducer } from "react";
import { initialState, userFederationReducer } from "../reducer/userFederationReducer";
import { setLocalStorageItem } from "../utility/helper";

export const UserFederationContext = createContext();

const UserFederationContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userFederationReducer, initialState);

  useEffect(() => {
    setLocalStorageItem('usersDN', state.usersDN);
    setLocalStorageItem('connectionURL', state.connectionURL);
    setLocalStorageItem('bindDN', state.bindDN);
    setLocalStorageItem('federationLocation', state.federationLocation);
    setLocalStorageItem('lastSync', state.lastSync);
    setLocalStorageItem('nextSync', state.nextSync);
    setLocalStorageItem('userCount', state.userCount);
    setLocalStorageItem('federationId', state.federationId);
  }, [state])

  return (
    <UserFederationContext.Provider value={{ state, dispatch }}>
      { children }
    </UserFederationContext.Provider>
  )
}

export default UserFederationContextProvider;
