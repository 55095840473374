import { Container } from '@material-ui/core';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import DeleteModal from '../../components/delete-modal';
import Table, { createColumn } from '../../components/table';
import Title from '../../components/title';
import { LocationContext } from '../../context/locationContext';
import api from '../../service/api';
import { multipleRequest, request } from '../../service/requests';
import { getUsersByRoleId } from '../../service/rolesApi';
import { ALL, API_REQUEST_ERROR_MESSAGE, ASCENDING, DATE_FORMAT, DELETE, GET, LOCATION, MULTIPLE, PROJECTION, ROLE, ROLES, ROLES_MODULE, SINGLE, USERS_MODULE } from '../../utility/constants';
import { formatAssociated, getUserByUserRole, parseIntegerParam, updateURLParams } from '../../utility/helper';
import useStyles from './styles';

const columns = [
  createColumn("name", "roleName", true, "string", false, true),
  createColumn("id", "ID", false, "numeric", true),
  createColumn("roleProfiles", "profiles", false, "component"),
  createColumn("location", "location", true, "string")
];

const Roles = (props) => {
  const { t }                = useTranslation();
  const classes              = useStyles();
  const history              = useHistory();
  const { search, pathname } = history.location;

  const params      = new URLSearchParams(search);
  const query       = params.get('keyword') ? params.get('keyword') : '';
  const pageSize    = params.get('size') ? parseIntegerParam(params.get('size'), 10) : 10;
  const pageNumber  = params.get('page') ? parseIntegerParam(params.get('page'), 1) : 1;
  const pageType    = params.get('roles') ? params.get('roles') : ALL.toLowerCase();
  const totalSize   = pageSize > 100 ? 100 : pageSize;

  const { showToaster, handlePermissions }    = props;
  const { state : locationState }             = useContext(LocationContext);
  const { selectedLocationIds }               = locationState;
  const [forDelete, setForDelete]             = useState([]);
  const [forDeleteName, setForDeleteName]     = useState([]);
  const [isLoading, setIsLoading]             = useState(false);
  const [keyword, setKeyword]                 = useState(query);
  const [order, setOrder]                     = useState(ASCENDING);
  const [orderBy, setOrderBy]                 = useState(columns[0].id);
  const [page, setPage]                       = useState(pageNumber === 0 ? 1 : pageNumber);
  const [remove, setRemove]                   = useState(false);
  const [roles, setRoles]                     = useState([]);
  const [rowsPerPage, setRowsPerPage]         = useState(totalSize);
  const [showModal, setShowModal]             = useState(false);
  const [totalRoles, setTotalRoles]           = useState(0);
  const [type , setType]                      = useState(SINGLE);
  const [assocValues, setAssocValues]         = useState('');
  const [title, setTitle]                     = useState('');
  const [listType, setListType]               = useState(pageType);

  const urlParams = useMemo(() => ({
    keywordParam :  keyword ? `&keyword=${keyword}` : '',
    entityParam  : `roles=${listType}`,
    pageParam    : `&page=${page}`,
    sizeParam    : `&size=${rowsPerPage}`,
    history      : history,
    pathname     : pathname
  }), [keyword, listType, page, rowsPerPage, history, pathname]);

  const getUrl = useCallback(() => {
    if (orderBy === LOCATION) {
      return (order === ASCENDING) ? api.ROLES_SEARCH_ORDER_BY_LOCATION_NAME_ASC : api.ROLES_SEARCH_ORDER_BY_LOCATION_NAME_DESC;
    } else {
      return (keyword === '') ? api.ROLES : api.ROLES_BY_NAME;
    }
  }, [keyword, order, orderBy])

  const getFormattedProfile = (profile) => {
    return ({
      name        : profile.name,
      description : `${moment(profile.validFrom).format(DATE_FORMAT)} - ${moment(profile.validUntil).format(DATE_FORMAT)}`
    })
  }

  const getFormattedProfiles = useCallback((items) => {
    return items.map(profile => {
      return getFormattedProfile(profile);
    });
  }, [])

  const getFormattedRole = useCallback((item) => {
    const { name, roleId, profiles, location } = item;
    const formattedProfiles = getFormattedProfiles(profiles);

    return {
      name      : name,
      id        : roleId,
      profiles  : formattedProfiles,
      location  : location ? location.name : '-'
    };
  }, [getFormattedProfiles])

  const formattedRoles = useCallback((items) => {
    return items.map(item => {
      return getFormattedRole(item);
    });
  }, [getFormattedRole])

  const setRolesResponse = useCallback((response) => {
    const { data }                                    = response;
    const { number, size, totalElements, totalPages } = data.page;

    const newRoles    = formattedRoles(data._embedded.roles);
    const totalNumber = number > totalPages ? totalPages : number;

    if (newRoles.length || number === 0) {
      setPage(totalNumber + 1);
      setRoles(newRoles);
    } else {
      setPage(totalNumber);
    }

    setRowsPerPage(size);
    setTotalRoles(totalElements);
  }, [formattedRoles])

  const getRoles = useCallback(async () => {
    remove ? setIsLoading(false) : setIsLoading(true);
    
    try {
      const response = await request({
        url           : getUrl(),
        method        : GET,
        params        : {
          name        : keyword,
          size        : rowsPerPage,
          page        : page - 1,
          sort        : `${orderBy},${order}`,
          projection  : PROJECTION.ROLES
        }
      })
      setRolesResponse(response);
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setIsLoading(false);
    }
  }, [getUrl, keyword, order, orderBy, page, remove, rowsPerPage, setRolesResponse, showToaster, t])

  const getData = useCallback(() => {
    if (listType === ALL.toLowerCase()){
      setTitle(ROLES);
      getRoles();
    } else {
      setListType(ALL.toLowerCase());
    }
  }, [getRoles, listType]);

  useEffect(() => {
    let delayDebounce;

    if (keyword) {
      setIsLoading(true);
      delayDebounce = setTimeout(() => {
        updateURLParams(urlParams);
        getData();
      }, 1000);
    } else {
      updateURLParams(urlParams);
      getData();
    }

    return () => {
      delayDebounce && clearTimeout(delayDebounce);
    }
  }, [page, rowsPerPage, order, orderBy, keyword, listType, selectedLocationIds, getData, urlParams]);

  useEffect(() => {
    if (remove ) {
      getRoles();
    }
    setRemove(false);
  }, [remove, selectedLocationIds, getRoles])

  const handleSearch = (value) => {
    setPage(1);
    setKeyword(value);
  }

  const handleClearSearch = () => {
    setPage(1);
    setKeyword('');
  }

  const handleSort = (newOrderBy, newOrder) => {
    setPage(1);
    setOrderBy(newOrderBy);
    setOrder(newOrder);
  }

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setPage(1);
    setRowsPerPage(newRowsPerPage);
  }

  const handleChangePage = (newPage) => {
    if (isLoading) {
      return;
    }
    
    setPage(newPage + 1);
  }

  const handleCreate = () => {
    history.push("/roles/create");
  }

  const handleUpdate = (value) => {
    history.push(`/roles/update/${value.id}`);
  }

  const handleView = (row) => {
    history.push(`/roles/view/${row.id}`);
  }

  const handleDelete = async (value) => {
    const roleusers = await getRolesUsers(value, SINGLE);
    if (roleusers.length > 0) {
      const users = handlePermissions(USERS_MODULE, GET) && await getUserByUserRole(roleusers);
      let formattedUsers = [];
      if (users) {
        formattedUsers = formatAssociated(users.map(val => { return (val.name)}), t);
      }
      setAssocValues(formattedUsers);
      handleOpenDeleteModal(value, value.name, 'singleWithAssociated');
    } else {
      handleOpenDeleteModal(value, value.name, SINGLE);
    }
  }

  const singleDelete = async (value) => {
    try { 
      await request({
        url     : `${api.ROLES}/${value.id}`,
        method  : DELETE
      })
      setIsLoading(false);
      showToaster(t('success'), `${value.name} ${t('hasBeenDeleted')}`, 'success');
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setRemove(true);
    }
  } 

  const handleMultipleDelete = async (values,) => {
    const roleusers = await getRolesUsers(values, MULTIPLE);
    const hasAssoc  = roleusers?.some((value) => (value).length > 0);

    if (hasAssoc) {
      const formattedRoleUsers = roleusers.filter(item => item.length > 0).flat();
      const users = handlePermissions(USERS_MODULE, GET) && await getUserByUserRole(formattedRoleUsers);
      let formattedUsers = [];
      if (users) {
        formattedUsers = formatAssociated(users.map(val => { return (val.name)}), t);
      }
      setAssocValues(formattedUsers);
      if (values.length === 1) {
        handleOpenDeleteModal(values[0], values[0].name, 'singleWithAssociated');
      } else {
        handleOpenDeleteModal(values, '', 'multipleWithAssociated');
      }
    } else if (values.length === 1) {
      handleOpenDeleteModal(values[0], values[0].name, SINGLE);
    }
    else {
      handleOpenDeleteModal(values, '', MULTIPLE);
    }
  }

  const getRolesUsers = async (data, count) => {
    if (count === MULTIPLE) {
      const rolesUsersResponse = await multipleRequest(
        data.map(role => {
          const users = getUsersByRoleId(role.id);
          return users;
        })
      );

      return rolesUsersResponse;
    } else {
      const rolesUsersResponse = await getUsersByRoleId(data.id);
      return rolesUsersResponse;
    }
  }

  const multipleDelete = async (values) => {
    const ids          = values.map(value => value.id);
    const roleNames    = values.map(value => value.name);
    const lastRoleName = roleNames.pop();
    
    try {
      await multipleRequest(
        ids.map(id => 
          request({
            url    : `${api.ROLES}/${id}`,
            method : DELETE,
          })
        )
      )

    showToaster(t('success'), `${roleNames.join(', ')}, ${t('and')} ${lastRoleName} ${t('hasBeenDeleted')}`, 'success');
            
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setRemove(true);
    }
}

  const handleOpenDeleteModal = (value, name, paramType) => {
    setForDelete(value);
    setForDeleteName(name);
    setType(paramType)
    setShowModal(true);
  }
  const handleCloseDeleteModal = () => {
    setShowModal(false);
  }

  const handleType = () => {
    setListType(ALL.toLowerCase());
  }

  return (

    <Container maxWidth="xl" className={classes.container}>
      <DeleteModal
        assocValues={assocValues}
        forDeleteName={forDeleteName}
        initialValues={forDelete}
        module={ROLES_MODULE}
        multipleDelete={multipleDelete}
        onClose={handleCloseDeleteModal}
        open={showModal}
        showToaster={showToaster}
        singleDelete={singleDelete}
        type={type}
      />
      <Title title={t(title)} listType={listType}/>
      <Table
        columns={columns}
        data={roles}
        isLoading={isLoading}
        keyword={keyword}
        label={ROLE}
        module={ROLES_MODULE}
        onChangePage={handleChangePage}
        onClearSearch={handleClearSearch}
        onCreate={handleCreate}
        onDelete={handleDelete}
        onMultipleDelete={handleMultipleDelete}
        onRowsPerPageChange={handleRowsPerPageChange}
        onSearch={handleSearch}
        onSort={handleSort}
        onUpdate={handleUpdate}
        onView={handleView}
        orderBy={orderBy}
        order={order}
        page={page}
        rowsPerPage={rowsPerPage}
        totalItems={totalRoles}
        viewKey={"name"}
        listType={listType}
        onViewAll={handleType}
        handlePermissions={handlePermissions}
      />
    </Container>
  );
}

export default Roles;