import { Typography } from "@material-ui/core"
import { Trans, useTranslation } from "react-i18next"
import clsx from 'clsx';
import useStyles from "./styles";

export const ContentTitle = (props) => {
  const { content, isSubTitle = false, isTableOfContentsTitle = false, removeBottomSpace = false } = props;
  const { t }       = useTranslation();
  const classes     = useStyles();
  return (
    <Typography 
      className={
        clsx('bold',
          {
          [classes.tableOfContentsTitle] : isTableOfContentsTitle, 
          [classes.contentSubTitle]      : isSubTitle, 
          [classes.contentTitle]         : !isSubTitle, 
          [classes.contentNoSpace]       : removeBottomSpace
          }
        )
      }>    
      {t(content)}
    </Typography>
  )
}

export const ContentDescription = (props) => {
  const { content, addSpace = 0, indent = 1, isI18n = false } = props;
  const { t }       = useTranslation();
  const classes     = useStyles();


  return (
    <Typography variant="body2" 
      className={
        clsx(
          classes.contentDescription,
          (indent === 0) && classes.contentDescriptionIndent0, 
          (indent === 1) && classes.contentDescriptionIndent1, 
          (indent === 2) && classes.contentDescriptionIndent2, 
          (addSpace === 1) && classes.contentDescriptionNewLine1,
          (addSpace === 2) && classes.contentDescriptionNewLine2,
        )
      }
    >
      {
        isI18n ?
          <Trans i18nKey={content} />
        :
          t(content)
      }
    </Typography>
  )
}