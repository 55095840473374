import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  contentTitle: {
    marginBottom: theme.spacing(1),
    fontSize    : theme.spacing(2.75)
  },
  contentSubTitle: {
    marginBottom: theme.spacing(1),
    fontSize  : theme.spacing(1.875),
    marginLeft: theme.spacing(1)
  },
  tableOfContentsTitle: {
    marginBottom: theme.spacing(1),
    fontSize  : theme.spacing(2),
  },
  contentDescription: {
    fontSize  : theme.spacing(1.875),
    marginLeft: theme.spacing(1)
  },
  contentNoSpace: {
    marginBottom: theme.spacing(0)
  },
  contentDescriptionIndent0: {
    marginLeft: theme.spacing(1)
  },
  contentDescriptionIndent1: {
    marginLeft: theme.spacing(2)
  },
  contentDescriptionIndent2: {
    marginLeft: theme.spacing(5)
  },
  contentDescriptionNewLine1: {
    marginBottom: theme.spacing(2)
  },
  contentDescriptionNewLine2: {
    marginBottom: theme.spacing(3)
  },
}));