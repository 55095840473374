import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { generateURLQueryParam, parseIntegerParam } from '../utility/helper';

const useParams = (defaultParams) => {
  const history = useHistory();
  const { search, pathname } = history.location;

  const queryParams = new URLSearchParams(search);
  const objQueryParams = Object.fromEntries(queryParams.entries());
  
  const newParams = useMemo(() => {
    return {
      listType: objQueryParams.listType || defaultParams.listType,
      size: parseIntegerParam(objQueryParams.size, defaultParams.size),
      page: parseIntegerParam(objQueryParams.page, defaultParams.page),
      ...defaultParams,
      ...objQueryParams,
    }
  }, [defaultParams, objQueryParams]);

  const [searchParams, setSearchParams] = useState(newParams);

  const handleURLQueryParam = useCallback((params) => {
    const queryParam = generateURLQueryParam(params);
    const location = {
      pathname,
      search: queryParam,
    };
    history.replace(location);
  }, [pathname, history]);

  useEffect(() => {
    handleURLQueryParam(searchParams);
  }, [searchParams, handleURLQueryParam]);

  const handleSearchParams = (params) => {
    const newParams = {
      ...params,
      size: parseIntegerParam(params.size, defaultParams.size),
      page: parseIntegerParam(params.page, defaultParams.page),
    }
    setSearchParams(newParams);
    handleURLQueryParam(newParams);
  };

  return [searchParams, handleSearchParams];
}

export default useParams