import { Box, Breadcrumbs, Link, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { useContext } from "react";
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router';
import { EventsFilterContext } from '../../context/eventsFIlterContext';
import { UPDATE_LIST_TYPE } from '../../reducer/eventsFilterReducer';
import { ACTION_ADD, ACTION_CREATE, ALL, CONTROLLERS, EVENTS, PAGE_TYPE } from "../../utility/constants";
import useStyles from './styles';
import { TitleSkeleton, SubTitleSkeleton } from './title-skeleton';

const State = (prop) => {
  const classes = useStyles();
  const { index, isLast, name, routeTo, mainPage, subtitle, history, pathname, isLoading } = prop;
  const isCreate = name === ACTION_CREATE;
  const crumb = name.charAt(0) + name.slice(1);
  const { dispatch } = useContext(EventsFilterContext);

  const handleRoute = () => {
    if (pathname.includes(EVENTS) && pathname.includes(PAGE_TYPE.VIEW)) {
      const location = {
        pathname: routeTo,
        state   : {
          ...history.location.state,
          prevLocation: pathname
        }
      }

      history.push(location);
    } else {
      if (pathname.includes(EVENTS)) {
        history.push(`?${routeTo.replace('/', '')}=${ALL.toLowerCase()}&page=0&size=10`);
        dispatch({
          type    : UPDATE_LIST_TYPE,
          payload : ALL
        })
      } else {
        history.push(routeTo);
      }
    }
  }
  
  if (mainPage) {
    return null;
  } else if (isLast) {
      if (isCreate) {
        const createSubtitle = pathname.includes(CONTROLLERS) ? ACTION_ADD : crumb;
        return <Typography variant="body2" component={'span'} color="textPrimary" className={classes.cursorDefault}> <span id="breadcrumbCurrent"> <Trans i18nKey={`title-component.${createSubtitle}`}/> </span> </Typography>
      } else if (index === 0) {
        return <Typography variant="body2" component={'span'} color="textPrimary" className={classes.cursorDefault}> <span id="breadcrumbCurrent"> <Trans i18nKey={`title-component.${crumb}`}/> </span> </Typography>
      }
      if (isLoading) {
        return <SubTitleSkeleton />
      }
      return <Typography variant="body2" component={'span'} color="textPrimary" className={classes.cursorDefault}> <span id="breadcrumbCurrent">{subtitle}</span> </Typography>
  }
  return <Link id="breadCrumbPrevious" variant="body2" color="primary" className={classes.cursorPointer} onClick={handleRoute}> <Trans i18nKey={`title-component.${crumb}`}/> </Link>
}

const EnhancedTitle = (props) => {
  const { title, subtitle, history, location: { pathname }, listType, isLoading } = props;
  const pathnames = listType ? [EVENTS, title] : pathname.split('/').filter(x => x);
  const subTitle = listType ? title : subtitle;
  const classes = useStyles();

  const removeItemFromPathname = (item) => {
    const index = pathnames.indexOf(item);

    if (index > -1 ) {
      pathnames.splice(index, 1);
      return item;
    } else {
      return null;
    }
  }

  removeItemFromPathname('view');
  removeItemFromPathname('update');
  removeItemFromPathname('area');

  return (
    <Box>
      <>
        <Typography className={classes.breadcrumbs} component={'span'} variant="body2">
          <Breadcrumbs separator={listType === ALL.toLowerCase() ? '' :   '/'} aria-label="breadcrumb">
            {
              pathnames.map((name, index) => {
                const isLast   = index === pathnames.length - 1;
                const mainPage   = pathnames.length === 1 || listType === ALL.toLowerCase();
                const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
  
                return (
                  <State
                    key={index}
                    index={index}
                    mainPage={mainPage}
                    isLast={isLast}
                    name={name}
                    routeTo={routeTo}
                    subtitle={subTitle}
                    history={history}
                    pathname={pathname}
                    isLoading={isLoading}
                  />
                )
              })
            }
          </Breadcrumbs>
        </Typography>
        {
          isLoading ?
            <TitleSkeleton />
          :
            <Typography variant="h5" component="h5" className={clsx('bold')}>
              {title}
            </Typography>
        }
      </>
    </Box>
  )
}

export default withRouter(EnhancedTitle);