import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  iconBox: {
    marginRight: theme.spacing(2)
  },
  dialogModal: {
    display   : 'block',
    padding   : theme.spacing(2),
    paddingTop: theme.spacing(5),
  },
  dialogImage: {
    alignItems: 'center',
    margin    : '0 auto',
    textAlign : 'center',
  },
  dialogTitle: {
    fontWeight: 'bold',
    textAlign : 'center',
  },
  dialogMessage: {
    width: '75%'
  },
  dialogContent: {
    display       : 'flex',
    justifyContent: 'center',
    padding       : theme.spacing(1),
    textAlign     : 'center',
  },
  dialogEmphasis: {
    fontStyle: 'italic'
  },
  dialogActions: {
    alignItems     : 'center',
    backgroundColor: colors.LIGHT_GRAY,
    direction      : 'row',
    display        : 'flex',
    height         : theme.spacing(8),
    justifyContent : 'space-around',
    padding        : theme.spacing(2),
  },
  dialogSubmitButton: {
    display : 'flex',
    float   : 'left',
    margin  : theme.spacing(0.5),
    padding : theme.spacing(0.5),
    width   : "230px",
  },
  dialogCancelButton: {
    display : 'flex',
    float   : 'right',
    margin  : theme.spacing(0.5),
    padding : theme.spacing(0.5),
    width   : "200px",
  },
}));