import React from 'react';
import { Typography, Container, AppBar, Toolbar, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import useStyles from './styles';
import Version from '../../components/version';

const Footer = (props) => {
	const classes                   = useStyles();
  const { position = 'absolute' }   = (props);
  const { t }                     = useTranslation();
  const history                   = useHistory();

  const dataPrivacy = () => {
    history.push('/data-privacy');
  }

  const imprint = () => {
    history.push('/imprint');
  }
	
	return (
    <Container maxWidth="md">
      <AppBar className={clsx(position === 'fixed' ? classes.appBarFixed : classes.appBarAbsolute)}>
        <Toolbar>
          <Typography variant="body2" >
            <Link id="dataPrivacyButton" className={classes.link} onClick={dataPrivacy}>
              {t('dataPrivacy')}
            </Link>
            {' | '}
            <Link id="imprintButton" className={classes.link} onClick={imprint} >
              {t('imprint')}
            </Link>
            {' | '}
          </Typography>
          <Version />
          <div className={classes.grow} />
          <Typography variant="body2" color="inherit">
            {t('copyRight')}
            {' '} {new Date().getFullYear()} {t('allRights')}
          </Typography>
        </Toolbar>
      </AppBar>
    </Container>
	)
}

export default Footer;