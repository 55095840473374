import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import Routes from '../src/routers/index';
import './App.css';

const browserHistory = createBrowserHistory();

const App = () => {
  console.log('ENV : ', window.ENVIRONMENT); 

  return (
    <Router history={browserHistory}>
      <Routes />
    </Router>
  );
}

export default App;
