import { Grow } from '@material-ui/core';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Stomp from 'react-stomp';
import { toast } from 'react-toastify';
import { toastConfig } from './components/toaster';
import { AuthContext } from './context/authContext';
import wsFactory from './service/websocket';
import { WSTOPIC } from './utility/constants';

const Websocket = (props) => {
  const { children } = props;
  
  const { t } = useTranslation();

  const [newEvent, setNewEvent]     = useState(false);
  const [newTopic, setNewTopic]     = useState('');
  const [newMessage, setNewMessage] = useState({});

  const { state }         = useContext(AuthContext);
  const { administrator } = state;

  const administratorLocationIds  = administrator?.locations?.map(location => location.locationId);

  const showToaster = useCallback((toasterTitle, toasterDescription, toasterSeverity, details) => {
    const hasDetails = toasterSeverity === 'error' && details !== undefined;
    enqueueSnackbar(toasterDescription, {
      variant: 'toaster',
      isOpen: true,
      title: toasterTitle,
      description: toasterDescription,
      severity: toasterSeverity,
      details: details,
      autoHideDuration: hasDetails ? null : 6000,
      persist: hasDetails,
      TransitionComponent: Grow
    })
  }, []);

  const handleWSMessage = (message, topic) => {
    if (topic.includes(WSTOPIC.EVENTS)) {
      const isEventForUser = administratorLocationIds.includes(message.locationId);

      if (isEventForUser) {
        setNewEvent(true);
      }
    }

    if (topic.includes(WSTOPIC.CONTROLLER)) {
      setNewTopic(topic)
      setNewMessage(message)
      if (topic === WSTOPIC.NEW_CONTROLLER) {
        const { deviceType, deviceId } = message.controllerOverallStateDTO;
        toast.info(`New ${deviceType} detected. Device ID: ${deviceId}`, toastConfig.info);
      }
    }
    
    if (topic.includes(WSTOPIC.DEVICE_REQUEST)) {
      const { requestType, responseCode, serialNumber, objectResponse } = message;
      if (responseCode < 0) {
        const responseList = JSON.parse(objectResponse);
        responseList.forEach(item => {
          if (item.responseCode !== 0) {
            const message = item.responseMessage ? item.responseMessage : t(`toaster-component.responseCode${item.responseCode}`);
            const details = t('toaster-component.responseErrorDetails', {
              serialNumber: serialNumber,
              requestType : requestType,
              code        : item.responseCode,
              message     : message
            })
            showToaster(t('error'), t('toaster-component.responseErrorDescription'), 'error', details);
          }
        });
      }
    }
    
    if (topic.includes(WSTOPIC.ERROR)) {
      console.log("ERROR: received from ", topic, message);
    }
  }

  const renderChildren = () => {
    return React.Children.map(children, (child) => {
      return React.cloneElement(child, {
        newTopic,
        newMessage,
        setNewTopic,
        setNewMessage,
        newEvent,
        setNewEvent,
        handleWSMessage,
        showToaster
      });
    });
  };


  return (
    <>
      <Stomp
        {...wsFactory.notification}
        onMessage={handleWSMessage}
        />
      { renderChildren() }
    </>
  )
}

export default Websocket