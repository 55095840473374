import { ADMINISTRATOR_ATTRIBUTES, BEARER, DELETE, GET, KEYCLOAK_ACTIONS, POST, PUT } from "../utility/constants";
import api from "./api";
import { multipleRequest, request } from "./requests";

const AUTH_BASE_PATH = window.KEYCLOAK_URL;
const ADMINISTRATORS_API =
  AUTH_BASE_PATH + "/realms/" + window.KEYCLOAK_REALM + "/resources/administrators";

  const ADMINISTRATORS_RESOURCE_API =
  AUTH_BASE_PATH + "/admin/realms/" + window.KEYCLOAK_REALM + "/users";

const UPDATE_PASSWORD_API = 
  AUTH_BASE_PATH + "/realms/" + window.KEYCLOAK_REALM + "/resources/updatePassword";

const ADMIN_ACCOUNT_API = AUTH_BASE_PATH + "/realms/" + window.KEYCLOAK_REALM + "/account";

const CLIENTS_ROLES_MAPPING_PATH = "role-mappings/clients";
const WEB_CLIENT_API = AUTH_BASE_PATH + "/admin/realms/" + window.KEYCLOAK_REALM + "/clients";
const WEB_CLIENT_NAME = "pacaas-web-client";

const CREATE_PASSWORD = "CREATE_PASSWORD";
const EXECUTION_ACTION_EMAIL = "execute-actions-email";

export const GetAllAdministrators = async (pageOffset, sort) => {
  const administratorsResponse = await request({
    url: ADMINISTRATORS_API,
    method: GET,
    params: {
      first : pageOffset,
      q: ADMINISTRATOR_ATTRIBUTES.QUERY,
      sort : sort
    },
  });
  const administratorsData = administratorsResponse.data;
  const administrators = administratorsData.map(administratorData => {
    const { firstName, lastName, email, id, clientRoles } = administratorData;
    const systemRoles = clientRoles.roles.map((name, index) => {
      return {
        id: index,
        name: name
      }
    });
    return {
      name: `${lastName}, ${firstName}`,
      email: email,
      systemRoles: systemRoles,
      id: id,
    };
  });
  return {
    administrators: administrators,
    administratorsCount: administratorsResponse.data.length,
  };
};

export const SearchAdministrators = async (keyword, pageOffset, sort) => {
  const administratorsResponse = await request({
    url: ADMINISTRATORS_API,
    method: GET,
    params: {
      search: keyword,
      q: ADMINISTRATOR_ATTRIBUTES.QUERY,
      sort: sort
    },
  });
  const administratorsData = administratorsResponse.data;
  const administrators = administratorsData.map(administratorData => {
    const { firstName, lastName, email, id, clientRoles } = administratorData;
    const systemRoles = clientRoles.roles.map((name, index) => {
      return {
        id: index,
        name: name
      }
    });
    return {
      name: `${lastName}, ${firstName}`,
      email: email,
      systemRoles: systemRoles,
      id: id,
    };
  });
  return {
    administrators: administrators,
    administratorsCount: administrators.length
  };
};

export const GetAdministratorById = async (id) => {
  const administratorsResponse = await request({
    url: `${ADMINISTRATORS_RESOURCE_API}/${id}`,
    method: GET,
  });
  const administratorData = administratorsResponse.data;
  return administratorData;
};

export const CreateAdministrator = async (administrator) => {
  const attribute = {
    type: [ADMINISTRATOR_ATTRIBUTES.ADMINISTRATOR]
  };

  const administratorsResponse = await request({
    url: ADMINISTRATORS_RESOURCE_API,
    method: POST,
    data: {
      email: administrator.email,
      firstName: administrator.firstName,
      lastName: administrator.lastName,
      enabled: true,
      username: administrator.email,
      attributes: attribute
    },
  });

  if (administratorsResponse.status !== 201) {
    throw new Error(administratorsResponse.status);
  }

  const administratorURL = administratorsResponse.headers.location;
  const newAdministratorId = administratorURL.replace(`${ADMINISTRATORS_RESOURCE_API}/`, "");

  await AssignAdministratorSystemRoles(newAdministratorId, administrator.role);

  await inviteAdministrator(newAdministratorId);

  return newAdministratorId;
};

export const inviteAdministrator = (id) => {
  return request({
    url: `${ADMINISTRATORS_RESOURCE_API}/${id}/${EXECUTION_ACTION_EMAIL}`,
    method: PUT,
    headers: {
      'Content-Type': 'application/json'
    },
    data: [CREATE_PASSWORD]
  });
}

export const UpdateAdministrator = async (id, administrator) => {
  await request({
    url: `${ADMINISTRATORS_RESOURCE_API}/${id}`,
    method: PUT,
    data: administrator
  });
};

export const DeleteAdministrator = async (id) => {
  await request({
    url: `${ADMINISTRATORS_RESOURCE_API}/${id}`,
    method: DELETE
  });
};

export const DeleteMultipleAdministrators = async (ids) => {
  await multipleRequest(
    ids.map(id =>
      request({
        url: `${ADMINISTRATORS_RESOURCE_API}/${id}`,
        method: DELETE
      })
    )
  );
};

export const GetAdministratorsCount = async (keyword) => {
  const administratorsResponse = await request({
    url: ADMINISTRATORS_RESOURCE_API,
    method: GET,
    params: {
      search : keyword,
      q: ADMINISTRATOR_ATTRIBUTES.QUERY
    },
  });
  const administratorsCount = administratorsResponse.data.length;
  return administratorsCount;
};

export const GetWebClientID = async () => {
  const clientsResponse = await request({
    url: WEB_CLIENT_API,
    method: GET,
    params: {
      clientId : WEB_CLIENT_NAME
    },
  });

  if (clientsResponse.status !== 200) {
    throw new Error(clientsResponse.status);
  }

  const clientsData = clientsResponse.data;
  if (!clientsData || clientsData.length === 0){
    throw new Error("API Client not found");
  }

  return clientsData[0].id;
}

export const GetAllSystemRoles = async (search) => {
  const clientId = await GetWebClientID();
  const rolesResponse = await request({
    url: `${WEB_CLIENT_API}/${clientId}/roles`,
    method: GET,
  });
  const rolesData = rolesResponse.data;
  var roles = rolesData.map((roleData) => {
    const { id, name, description } = roleData;
    return {
      name: name,
      description: description ? description : "",
      id: id,
    };
  });
  return roles;
};

export const GetSystemRolesForAdministrator = async (id, clientId) => {
  const rolesResponse = await request({
    url: `${ADMINISTRATORS_RESOURCE_API}/${id}/${CLIENTS_ROLES_MAPPING_PATH}/${clientId}`,
    method: GET,
    params: {},
  });

  const rolesData = rolesResponse.data;
  var roles = rolesData.map((roleData) => {
    const { id, name, description } = roleData;
    return {
      id: id,
      name: name,
      description: description,
    };
  });
  return roles;
};

export const AssignAdministratorSystemRoles = async (id,roles) => {
  const clientId = await GetWebClientID();

  await request({
    url: `${ADMINISTRATORS_RESOURCE_API}/${id}/${CLIENTS_ROLES_MAPPING_PATH}/${clientId}`,
    method: POST,
    data: roles,
  });
  return;
};

export const DeleteAdministratorSystemRoles = async (id,roles) => {
  const clientId = await GetWebClientID();

  await request({
    url: `${ADMINISTRATORS_RESOURCE_API}/${id}/${CLIENTS_ROLES_MAPPING_PATH}/${clientId}`,
    method: DELETE,
    data: roles,
  });
  return;
};

export const updateAdministratorPassword = async (data) => {
  await request({
    url   : UPDATE_PASSWORD_API,
    method: PUT,
    data  : data
  });
}

export const sendResetPasswordEmail = async (token, administratorId) => {
  await request({
    url     : `${ADMINISTRATORS_RESOURCE_API}/${administratorId}/execute-actions-email`,
    method  : PUT,
    headers : {
      'Content-Type' : 'application/json',
      'Authorization': BEARER + token
    },
    data: `["` + KEYCLOAK_ACTIONS.UPDATE_PASSWORD + `"]`
  })
}

export const GetCurrentAdminAccount = async () => {
  return await request({
    url     : ADMIN_ACCOUNT_API,
    method  : GET,
    headers : {
      'Content-Type' : 'application/json'
    }
  })
}

export const UpdateCurrentAdminAccount = async (data) => {
  const admin = await GetCurrentAdminAccount();
  
  await request({
    url     : ADMIN_ACCOUNT_API,
    method  : POST,
    headers : {
      'Content-Type' : 'application/json'
    },
    data    : {
      ...admin.data,
      attributes: data
    }
  });
}

export const createOrDeleteAuthorizations = async (locationIds, administratorId, isSystemAdmin = false) => {
  await request({
    url    : `${api.AUTHORIZATIONS}/${administratorId}`,
    method : PUT,
    headers: {
      'Content-Type' : 'application/json'
    },
    data   : {
      isSystemAdmin : isSystemAdmin,
      locationIds   : locationIds
    }
  });
};