import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../../theme/colors';

export default makeStyles((theme) => ({
  searchField: {
    top             : theme.spacing(-4),
    '& .MuiOutlinedInput-adornedEnd': {
      paddingLeft   : theme.spacing(3),
      paddingRight  : '24px !important'
    }
  },
  searchContainer: {
    alignItems      : 'center',
    display         : 'flex',
    height          : '230px',
    justifyContent  : 'center',
    paddingBottom   : theme.spacing(6),
    width           : '100%',
    '& .MuiAutocomplete-popper': {
      height        : '0px',
      width         : '60% !important',
    },
    '& .MuiAutocomplete-listbox': {
      maxHeight       : '130px !important',
      '& .MuiAutocomplete-option': {
        backgroundColor: 'transparent'
      },
      '& .MuiAutocomplete-option:hover': {
        backgroundColor: colors.SECONDARY_LIGHT
      }
    }
  },
  autoComplete: {
    width           : '60%',
  },
  popOver: {
    '& .MuiPopover-paper': {
      minWidth      : 'calc(40% - 16px)',
      marginTop     : '3px'
    }
  },
}));