import { Box, IconButton, InputAdornment, Popper, TextField } from '@material-ui/core';
import { CloseOutlined, SearchOutlined } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import clsx from 'clsx';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WizardContext } from '../../../../context/wizardContext';
import { WizardCredentialContext } from '../../../../context/wizardCredentialContext';
import { CLEAR_REDUCER, SET_CREDENTIAL_VALUES } from '../../../../reducer/wizardCredentialReducer';
import { SET_ERROR, SET_NEXT_BUTTON } from '../../../../reducer/wizardReducer';
import { getCredentialByCredentialNumber } from '../../../../service/credentialApi';
import { GetUsersWithCredentials, getUserApi } from '../../../../service/usersApi';
import { API_REQUEST_ERROR_MESSAGE, PROJECTION } from '../../../../utility/constants';
import useStyles from './styles';

const PopperComponent = (props) => {
  return <Popper {...props} placement='top' />
}

const WizardSearchPerson = forwardRef((props, ref) => {
  const wizardContext     = useContext(WizardContext);
  const credentialContext = useContext(WizardCredentialContext);

  const { showToaster } = props;
  const classes = useStyles();
  const { t }   = useTranslation();

  const [keyword, setKeyword]             = useState('');
  const [userLists, setUserLists]         = useState([]);
  const [open, setOpen]                   = useState(false);
  const [isSelected, setIsSelected]       = useState(false);

  useEffect(() => {
    credentialContext.dispatch({ type: CLEAR_REDUCER });
    getCredentialNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (keyword === '') {
      setUserLists([]);
      setOpen(false);
      return;
    }

    setOpen(!isSelected);

    let delayDebounce;
    const isIncludes = userLists.map(list => list.label.toLowerCase().includes(keyword.toLowerCase()));

    if (isIncludes.some(include => include)) {
      const filterList = userLists.filter(list => list.label.toLowerCase().includes(keyword.toLowerCase()))
      setUserLists(filterList);
      return;
    }
    
    delayDebounce = setTimeout(() => {
      getCredentialNames();
    }, 1000);

    return () => {
      delayDebounce && clearTimeout(delayDebounce);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword])

  const getCredentialNames = async () => {
    setUserLists([]);
    try {
      const users = await GetUsersWithCredentials(keyword);
      setUserLists(users);
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }
  }

  useImperativeHandle(ref, () => ({
    onNextPage() {
      if (isSelected) {
        wizardContext.dispatch({ type: SET_ERROR, payload: false });
      }
    }
  }));

  const handleSearch = async (_event, value) => {
    if (value) {
      setIsSelected(true);
      setKeyword(value.label ? value.label : value);
      const selectedUser = userLists.find(list => list.label === value.label);

      const user = await getUserApi(selectedUser.id);

      const credentialNumbers = user.credentials?.map(credential => credential.name);

      const credentialList = await getCredentialByCredentialNumber(credentialNumbers, PROJECTION.CREDENTIALS);

      const userPayload =  {
        ...user,
        ...selectedUser,
        name : selectedUser.label
      }

      const payload = { 
        credentials: credentialList,
        user     : userPayload,
      };

      credentialContext.dispatch({ type: SET_CREDENTIAL_VALUES , payload: payload });
    }
  }

  const handleClearSearch = () => {
    setKeyword('');
    setIsSelected(false);
    wizardContext.dispatch({ type: SET_NEXT_BUTTON, payload: false });
  }

  const handleOpen = () => {
    setOpen(Boolean(keyword));
  };

  const handleAutoFill = (event) => {
    if (event) {
      setIsSelected(false);
      setKeyword(event.target.value);
    }
  }

  const handleOptionalValue = (option, value) => {
    return option.label.toLowerCase().includes(value.toLowerCase());
  }

  return (
    <Box className={classes.searchContainer}>
      <Autocomplete
        disablePortal
        className={classes.autoComplete}
        id="combo-box-demo"
        options={userLists}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={handleOpen}
        onInputChange={handleAutoFill}
        onChange={handleSearch}
        PopperComponent={PopperComponent}
        getOptionSelected={handleOptionalValue}
        getOptionLabel={(option) => option.label ? option.label : option}
        value={keyword}
        renderInput={(params) =>
          <TextField
            {...params}
            className={classes.searchField}
            id="outlined-basic"
            placeholder={t('search')}
            variant="outlined"
            fullWidth
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined color="secondary" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    id="wizardSearchPersonClearSearchButton"
                    edge="end"
                    onClick={handleClearSearch}
                    size="small"
                    className={
                      clsx({
                        [classes.visuallyHidden]: (keyword === null || keyword === "")
                      })
                    }
                  >
                    <CloseOutlined />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        }
      />
    </Box>
  )
});

export default WizardSearchPerson