import DateFnsUtils from '@date-io/date-fns';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fade, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import clsx from 'clsx';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import { Form, Formik } from 'formik';
import cookies from 'js-cookie';
import moment from 'moment';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../../context/authContext';
import { WizardContext } from '../../../../context/wizardContext';
import { WizardPersonContext } from '../../../../context/wizardPersonContext';
import { SET_SELECT_CREDENTIALS } from '../../../../reducer/wizardCredentialReducer';
import { SET_PERSON } from '../../../../reducer/wizardPersonReducer';
import { SET_ERROR, SET_NEXT_PAYLOAD } from '../../../../reducer/wizardReducer';
import { GetUserByPersonalNumber } from '../../../../service/usersApi';
import { API_REQUEST_ERROR_MESSAGE, DATE_FORMAT, GET, LANGUAGE_DE, LANGUAGE_EN, LOCATIONS_MODULE, MAX_CHARACTER_LIMIT } from '../../../../utility/constants';
import { GetInitialLocationId, GetInitialLocationObject } from '../../../../utility/location';
import { wizardPersonSchema } from '../../../../validation/schema';
import DatePicker from '../../../date-picker';
import SelectItems from '../../../select-items';
import useStyles from './styles';

const Content = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { initialValues, handleSubmit, setValidFrom, setValidUntil, setPersonalNumber, setFirstName, setLastName, setIsNotValid, formRef, selectedLocation, handleSelectedLocation, showToaster, handlePermissions } = props;
  const [showDates, setShowDates] = useState(false);

  const handleChange = (setter, formik) => {
    return (setter, formik);
  }

  const handleShowDateFields = () => {
    setShowDates(isShow => !isShow);
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={wizardPersonSchema}
      onSubmit={handleSubmit}
      innerRef={formRef}
    >
      {
        formik => (
          <Form className={classes.formContent}>
            <Grid container spacing={1} className={classes.form}>
              <Grid item xs={12} md={12} lg={4}>
                <TextField
                  id="wizardCreatePersonPersonalNumber"
                  label={`${t('personalNumber')}*`}
                  name="personalNumber"
                  size="medium"
                  fullWidth
                  inputProps={{
                    maxlength: MAX_CHARACTER_LIMIT.TEXT_FIELD
                  }}
                  value={formik.values.personalNumber}
                  onChange={e => handleChange(setPersonalNumber(e.target.value), formik.handleChange(e))}
                  error={formik.touched.personalNumber && Boolean(formik.errors.personalNumber)}
                  helperText={t(formik.touched.personalNumber) && t(formik.errors.personalNumber)}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}></Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField
                  id="wizardCreatePersonFirstName"
                  label={`${t('firstName')}*`}
                  name="firstName"
                  size="medium"
                  fullWidth
                  multiline
                  inputProps={{
                    maxlength: MAX_CHARACTER_LIMIT.TEXT_FIELD
                  }}
                  value={formik.values.firstName}
                  onChange={e => handleChange(setFirstName(e.target.value), formik.handleChange(e))}
                  error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                  helperText={t(formik.touched.firstName) && t(formik.errors.firstName)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField
                  id="wizardCreatePersonLastName"
                  label={`${t('lastName')}*`}
                  name="lastName"
                  size="medium"
                  fullWidth
                  multiline
                  inputProps={{
                    maxlength: MAX_CHARACTER_LIMIT.TEXT_FIELD
                  }}
                  value={formik.values.lastName}
                  onChange={e => handleChange(setLastName(e.target.value), formik.handleChange(e))}
                  error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                  helperText={t(formik.touched.lastName) && t(formik.errors.lastName)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} className={clsx(!handlePermissions(LOCATIONS_MODULE, GET) && 'hidden')}>
                <SelectItems
                  id="wizardCreatePersonLocations" 
                  name="Locations"
                  onChange={handleSelectedLocation}
                  selectedItems={selectedLocation}
                  showToaster={showToaster}
                  single={true}
                  helperText={t(formik.touched.location?.name) && t(formik.errors.location?.name)}
                  isValid={formik.touched.location && Boolean(formik.errors.location)}
                  required={true}
                  handlePermissions={handlePermissions}
                />
              </Grid>
              <Grid item xs={12}>
                <IconButton id="wizardCreatePersonDefaultValidityDatesButton" disableRipple aria-label="help" onClick={handleShowDateFields} className={classes.showDates}>
                  <FontAwesomeIcon className={classes.showIcon} icon={faPlus} size="sm" />
                  <Typography className={classes.showLabel} color="primary">{t('defaultValidityDates')}</Typography>
                </IconButton>
              </Grid>
              <Fade in={showDates} timeout={300}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <DatePicker
                    name="wizardCreatePersonValidFrom"
                    label={t('validFrom')}
                    until={formik.values.validUntil}
                    defaultValue={formik.values.validFrom}
                    value={formik.values.validFrom}
                    handleChange={setValidFrom}
                    touched={formik.touched.validFrom}
                    error={formik.errors.validFrom}
                    setIsNotValid={setIsNotValid}
                  />
                </Grid>
              </Fade>
              <Fade in={showDates} timeout={300}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <DatePicker
                    name="wizardCreatePersonValidUntil"
                    label={t('validUntil')}
                    min={formik.values.validUntil}
                    from={formik.values.validFrom}
                    defaultValue={formik.values.validUntil}
                    value={formik.values.validUntil}
                    handleChange={setValidUntil}
                    touched={formik.touched.validUntil}
                    error={formik.errors.validUntil}
                    setIsNotValid={setIsNotValid}
                  />
                </Grid>
              </Fade>
            </Grid>
          </Form>
        )
      }
    </Formik>
  );
}

const CreateUserWizard = forwardRef((props, ref) => {
  const { showToaster, handlePermissions } = props;
  const wizardContext   = useContext(WizardContext);
  const personContext   = useContext(WizardPersonContext);
  const authContext     = useContext(AuthContext);

  const { user, selectedCredentials }           = personContext.state;
  const { defaultValidFrom, defaultValidUntil } = authContext.state;

  const { dispatch } = personContext;

  const { t } = useTranslation();

  const initialLocationId = GetInitialLocationId();
  const initialLocationObject = GetInitialLocationObject();

  const locationName = initialLocationObject[0]?.name ? initialLocationObject[0].name : '';

  const [credentials, setCredentials]                 = useState(selectedCredentials);
  const [firstName, setFirstName]                     = useState('');
  const [lastName, setLastName]                       = useState('');
  const [personalNumber, setPersonalNumber]           = useState('');
  const [validFrom, setValidFrom]                     = useState(defaultValidFrom);
  const [validUntil, setValidUntil]                   = useState(defaultValidUntil);
  const [isNotValid, setIsNotValid]                   = useState(false);
  const [selectedLocation, setSelectedLocation]       = useState(locationName);
  const [locationObject, setLocationObject]           = useState(initialLocationObject);
  const [locationId, setLocationId]                   = useState(initialLocationId);
  
  const language = cookies.get('i18next') || LANGUAGE_EN;

  const formRef = useRef();

  const initialValues = useMemo(() => {
    return {
      personalNumber: personalNumber,
      firstName     : firstName,
      lastName      : lastName,
      validFrom     : validFrom,
      validUntil    : validUntil,
      location      : {
        name : selectedLocation,
        id   : locationId
      }
    }
  }, [personalNumber, firstName, lastName, validFrom, validUntil, selectedLocation, locationId]);

  useEffect(() => {
    if (user) {
      setPersonalNumber(user.personalNumber);
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setValidFrom(user.validFrom);
      setValidUntil(user.validUntil);
      setSelectedLocation(user.location.name);
      setLocationId(user.location.id);
      setCredentials(credentials => credentials);
    }
  }, [user]);

  useEffect(() => {
    if (initialValues.location.name) {
      setLocationObject([initialValues.location]);
    }
  }, [initialValues]);


  useEffect(() => {
    if (credentials.length) {
      const newCredentialDates = credentials.map((credential) => {
        return {
          ...credential,
          description: formatTime(validFrom) + '-' + formatTime(validUntil),
        }
      });

      dispatch({ type: SET_SELECT_CREDENTIALS, payload: newCredentialDates });
    }
  }, [validFrom, validUntil, dispatch, credentials]);

  const formatTime = (time) => {
    return moment(time).format(DATE_FORMAT);
  }

  useImperativeHandle(ref, () => ({
    onNextPage() {
      formRef.current.handleSubmit();
    }
  }));

  const handleSelectedLocation = (value) => {
    setSelectedLocation(value[0]?.name);
    setLocationId(value[0]?.locationId);
    setLocationObject(value);
  }

  const handleSubmit = async () => {
    if (isNotValid) {
      return;
    }

    try {
      const response = await GetUserByPersonalNumber(personalNumber);
  
      if (response.users.length) {
        formRef.current.setErrors({
          personalNumber: t('personalNumberAlreadyExists')
        });
        wizardContext.dispatch({ type: SET_ERROR, payload: true });
      } else {
        wizardContext.dispatch({ type: SET_NEXT_PAYLOAD, payload: { isNextHidden: true } });
        personContext.dispatch({ type: SET_PERSON, payload: formRef.current.values });
        wizardContext.dispatch({ type: SET_ERROR, payload: false });
      }
    } catch {
      wizardContext.dispatch({ type: SET_ERROR, payload: true });
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={language === LANGUAGE_DE ? deLocale : enLocale}>
      <Content
        id={1}
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        setValidFrom={setValidFrom}
        setValidUntil={setValidUntil}
        setPersonalNumber={setPersonalNumber}
        setFirstName={setFirstName}
        setLastName={setLastName}
        setIsNotValid={setIsNotValid}
        formRef={formRef}
        handleSelectedLocation={handleSelectedLocation}
        selectedLocation={locationObject}
        location={selectedLocation}
        handlePermissions={handlePermissions}
      />
    </MuiPickersUtilsProvider>
  )
})

export default CreateUserWizard