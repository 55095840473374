import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  deleteModal: {
    padding   : theme.spacing(2),
    paddingTop: theme.spacing(5),
  },
  dialogImage: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(2),
  },
  dialogTitle: {
    fontWeight: 'bold',
    textAlign : 'center',
  },
  dialogContent: {
    padding  : theme.spacing(0.5),
    textAlign: 'center',
  },
  dialogActions: {
    alignItems     : 'center',
    backgroundColor: colors.LIGHT_GRAY,
    direction      : 'row',
    display        : 'flex',
    height         : theme.spacing(8),
    justifyContent : 'space-around',
    padding        : theme.spacing(2),
    width           : 600
  },
  dialogSubmitButton: {
    display : 'flex',
    float   : 'left',
    margin  : theme.spacing(0.5),
    padding : theme.spacing(0.5),
    width   : "300px",
  },
  dialogCancelButton: {
    display : 'flex',
    float   : 'right',
    margin  : theme.spacing(0.5),
    padding : theme.spacing(0.5),
    width   : "300px",
  },
  wordBreak: {
    alignItems   : 'left',
    paddingLeft  : theme.spacing(0.7),
    paddingRight : theme.spacing(1),
    wordBreak    : 'break-word',
    '& .MuiTypography-body1': {
      letterSpacing: 0,
      lineHeight   : 0.7
    },
  },
}));