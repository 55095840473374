import { Paper } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react'
import useStyles from './styles';

const AuditLogSkeleton = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Skeleton height={328} />
    </Paper>
  )
}

export default AuditLogSkeleton