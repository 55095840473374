import { useContext } from 'react';
import { AuthContext } from '../context/authContext';
import { LocationContext } from '../context/locationContext';
import api from '../service/api';
import { multipleRequest, request } from '../service/requests';
import { ACCESS_POINTS, CONTROLLERS, CREDENTIALS, DELETE, GET, HOLIDAYS, PERSONS, PROFILES, PUT, ROLES, SCHEDULES } from './constants';

export const getLocation = (data, setLocation, setLocationObject) => {
  if (data.controllerId) {
    setLocation(data.location.location_id);
    setLocationObject([
      {
        locationId : parseInt(data.location.location_id),
        name       : data.location.name
      }
    ]);
  } else if (data.location) {
    setLocation(data.location.locationId);
    setLocationObject([
      {
        locationId : parseInt(data.location.locationId),
        name       : data.location.name
      }
    ]);
  }
}

export const assignLocation = async (link, locationId) => {
  await request({
    url     : `${link}/location`,
    method  : PUT,
    headers : {
      'Content-Type': 'text/uri-list'
    },
    data    : `${api.LOCATIONS}/${locationId}`
  });
}

export const unassignLocation = async (link) => {
  await request({
    url     : `${link}/location`,
    method  : DELETE
  });
}

export const getLocationsByAdministratorId = async (userId) => {
  const response = await request({
    url     : `${api.LOCATIONS_FIND_BY_ADMINISTRATOR_ID}/${userId}`,
    method  : GET
  });
  return response.data;
}

export const hasLocationChange = (currentLocation, previousLocation, userLocations, globalLocationFilter) => {
  const prevLocation = previousLocation ? previousLocation : [];
  if ((currentLocation.length === 0 && prevLocation.length === 0) || userLocations === 1) {
    return false;
  }

  if (globalLocationFilter.selectedLocations[0]?.locationId === parseInt(prevLocation) && parseInt(currentLocation) !== '') {
    return false;
  }

  return parseInt(currentLocation) !== parseInt(prevLocation); 
}

export const getLocationAssociatedRecords = async (locationsToDelete, setIsFetchingAssociatedModuleData) => {
  setIsFetchingAssociatedModuleData(true);
  const locationAssociatedModules = [ROLES, 
                                      PERSONS, 
                                      CONTROLLERS,
                                      HOLIDAYS, 
                                      ACCESS_POINTS, 
                                      PROFILES,
                                      SCHEDULES,
                                      CREDENTIALS];

  let moduleData = [];

  for (const a of locationAssociatedModules) {
    const response = await getLocationData(locationsToDelete, a);
    moduleData.push(response);
  }

  setIsFetchingAssociatedModuleData(false);
  return moduleData.flat().filter(item => item);
}

const getLocationData = async (locations, module) => {
  const moduleDataResponse = await multipleRequest(
    locations.map(location =>
      request({
        url    : `${api.LOCATIONS}/${location.id}/${module}`,
        method : GET,
      })
    )
  )

  return moduleDataResponse.map(moduleData => {
    const { _embedded : associatedModuleData } = moduleData?.data;
    switch (module) {
      case ROLES:
        return associatedModuleData?.roles?.map(item => item.name);
      case PERSONS:
        return associatedModuleData?.persons?.map(item => item.personalNumber);
      case CONTROLLERS:
        return associatedModuleData?.controllers?.map(item => item.deviceId);
      case HOLIDAYS:
        return associatedModuleData?.holidays?.map(item => item.name);
      case ACCESS_POINTS:
        return associatedModuleData?.accessPoints?.map(item => item.name);
      case PROFILES:
        return associatedModuleData?.profiles?.map(item => item.name);
      case SCHEDULES:
        return associatedModuleData?.schedules?.map(item => item.name);
      case CREDENTIALS:
        return associatedModuleData?.credentials?.map(item => item.credentialNumber);
      default:
        return null;
    }
  }).flat();
}

export const handleDeleteLocationLevel = async (location) => {
  return await request({
    url    : `${api.LOCATION_LEVELS}/${location.id}`,
    method : DELETE,
  })
}

export const GetInitialLocationId = () => {
  const { state : authState }     = useContext(AuthContext);
  const { state : locationState } = useContext(LocationContext);

  const administrator = authState.administrator;
  
  if (administrator.locations?.length === 1) {
    return administrator.locations[0].locationId;
  } else if (locationState.selectedLocations?.length === 1) {
    return locationState.selectedLocations[0].locationId;
  } else {
    return '';
  }
}

export const GetInitialLocationObject = () => {
  const { state : authState }     = useContext(AuthContext);
  const { state : locationState } = useContext(LocationContext);

  const administrator = authState.administrator;
  
  if (administrator.locations?.length === 1) {
    return administrator.locations;
  } else if (locationState.selectedLocations?.length === 1) {
    return locationState.selectedLocations;
  } else {
    return [];
  }
}