import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors'

export default makeStyles((theme) => ({
  container: {
    paddingBottom : theme.spacing(2),
    paddingTop    : theme.spacing(2),
  },
  iconBox: {
    alignItems    : 'center',
    display       : 'flex',
    justifyContent: 'flex-end',
    marginRight   : theme.spacing(1),
    marginTop     : theme.spacing(1)
  },
  iconButton: {
    backgroundColor: theme.palette.primary.main,
    borderRadius   : theme.spacing(0.5),
    color          : theme.palette.common.white,
    display        : 'inline',
  },
  form: {
    height       :'100%',
    paddingBottom: theme.spacing(3),
  },
  roleContainer: {
    border           : `1px solid ${colors.GRAY}`,
    borderRadius     : theme.spacing(0.5),
    height           : 'auto',
    padding          : theme.spacing(1),
    paddingTop       : theme.spacing(2),
    paddingLeft      : theme.spacing(2),
    paddingRight     : theme.spacing(2),
  },
  boxContainer: {
    borderBottom     : `1px solid ${colors.GRAY}`,
    display          : 'flex',
    justifyContent   : 'space-between',
    paddingBottom    : theme.spacing(2),
  },
  permissionContainer: {
    border              : `1px solid ${colors.GRAY}`,
    borderBottom        : '0',
    borderTopLeftRadius : theme.spacing(0.5),
    borderTopRightRadius: theme.spacing(0.5),
    padding             : theme.spacing(1),
    paddingTop          : theme.spacing(2),
  },
  permissionColumn: {
    width         : '100%',
    borderBottom  : `1px solid ${colors.GRAY}`,
    paddingBottom : theme.spacing(2),
  },
  deleteIcon: {
    "&:hover" : {
      color : colors.PRIMARY
    }
  },
  action: {
    backgroundColor        : theme.palette.grey[100],
    borderBottom           : `1px solid ${colors.GRAY}`,
    borderBottomLeftRadius : theme.spacing(0.5),
    borderBottomRightRadius: theme.spacing(0.5),
    borderLeft             : `1px solid ${colors.GRAY}`,
    borderRight            : `1px solid ${colors.GRAY}`,
    height                 : theme.spacing(8.6875),
    padding                : theme.spacing(2),
    textAlign              : 'right',
  },
  emptyContainer: {
    alignItems     : 'center',
    display        : 'flex',
    height         : '100%',
    justifyContent : 'center',
    maxHeight      : '600px',
    minHeight      : '619px'
  },
  gridItemsBorder: {
    paddingLeft: theme.spacing(1),
  },
  minMaxScroll: {
    display     : 'flex',
    minHeight   : '620px',
    padding     : theme.spacing(1),
    paddingLeft : theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingTop  : theme.spacing(2),
  },
}));