import React from 'react';
import { Paper, Box, Grid } from '@material-ui/core';
import { TEXT_FIELD_SKELETON } from '../../../utility/constants';
import clsx from 'clsx';
import Skeleton from '@material-ui/lab/Skeleton';
import useStyles from './styles';

const ScheduleSkeleton = (props) => {
  const { disabled } = props;
  const classes      = useStyles();
  return(
    <Box>  
      <Paper className={classes.paper} elevation={3}>
        <Grid container spacing={2} className={classes.form}>
          <Grid item xs={disabled ? 12 : 8}>
            <Grid container spacing={2}>
              <Grid item xs={5} className={classes.name}>
                <Skeleton height={TEXT_FIELD_SKELETON}/>
              </Grid>
              <Grid item className={clsx(disabled ? 'hidden' : classes.actions)} xs={6} align="right">
              </Grid>
              <Grid item xs={5} className={classes.name}>
                <Skeleton height={TEXT_FIELD_SKELETON}/>
              </Grid>
              <Grid item xs={12} md={12} lg={12} className={classes.header}>
                <Box className={classes.tableBody}>
                  <Skeleton height={824}/>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={clsx(disabled ? 'hidden' : classes.timePicker)} item xs={4}>
            <Grid item xs={12}>
                <Skeleton height={824}/>
            </Grid>
          </Grid> 
        </Grid>
        <Grid container className={classes.action}>
          <Grid item xs={12} className={clsx(disabled ? 'hidden' : classes.buttonContainer)}>
            <Skeleton variant="rect" className={classes.button} />
            <Skeleton variant="rect" className={classes.button} />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export default ScheduleSkeleton;