import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  container: {
    paddingTop    : theme.spacing(2),
    paddingBottom : theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(1),

    '& .MuiTextField-root': {
      marginTop: theme.spacing(0)
    },
    '& input#credentialNumber.Mui-disabled':{
      color: "black"
    },
    '& textarea#description.Mui-disabled':{
      color: "black"
    },
    '& input#validFrom.Mui-disabled':{
      color: "black"
    },
    '& input#validUntil.Mui-disabled':{
      color: "black"
    },
    '& input#companyCode.Mui-disabled':{
      color: "black"
    },
    '& p.MuiTypography-root.MuiTypography-body2':{
      color: "black"
    },
  },
  editIcon: {
    backgroundColor: theme.palette.primary.main,
    borderRadius   : theme.spacing(0.5),
    color          : theme.palette.common.white,
    display        : 'inline',  
  },
  form: {
    padding       : theme.spacing(1),
    paddingLeft   : theme.spacing(2),
    paddingRight  : theme.spacing(2),
  },
  switchContainer: {
    alignSelf       : 'center',
    display         : 'flex',
    height          : '100%',
    justifyContent  : 'flex-end',
  },
  action: {
    backgroundColor : theme.palette.grey[100],
    padding: theme.spacing(2),
    textAlign: 'right',

    '& .MuiButton-root:first-of-type': {
      marginRight: theme.spacing(2)
    }
  },
  characterLimit: {
    color   : colors.SECONDARY,
    float   : 'right',
    fontSize: '12px',
    position: 'relative',
    top     : '-0.3em'
  },
  characterLimitError: {
    color   : colors.SECONDARY,
    float   : 'right',
    fontSize: '12px',
    position: 'relative',
    top     : '-2.205em'
  },
  grid: {
    paddingBottom: `0 !important`
  }
}));