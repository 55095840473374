import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

const useStyles = makeStyles(theme => ({
	calendarTextField: {
		'& .MuiOutlinedInput-adornedEnd':{
			paddingRight : '0'
		}
	},
  hover: {
    '& .MuiIconButton-root': {
      backgroundColor : 'transparent',
      cursor          : 'default'
    }
  },
  toolbar: {
    alignItems     : 'flex-start',
    backgroundColor: colors.PRIMARY,
    display        : 'flex',
    flexDirection  : 'column',
    justifyContent : 'center',
    padding        : theme.spacing(2),
    paddingTop     : theme.spacing(1),
    paddingBottom  : theme.spacing(1),
  },
  yearLabel: {
    color          : colors.WHITE,
    fontSize       : theme.spacing(2),
    fontWeight     : '400',
    paddingLeft    : '0',
    paddingBottom  : '0',
    width          : 'fit-content',
  },
  dateLabel: {
    color          : colors.WHITE,
    fontSize       : theme.spacing(4.25),
    fontWeight     : '400',
    paddingTop     : '0',
    paddingBottom  : '0',
    height         : 'fit-content',
    width          : 'fit-content',
  },
  activeLabel: {
    opacity        : '0.54'
  }
}))

export default useStyles;