import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  container: {
    paddingTop   : theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  root: {
    width: '100%',
  },
  paper: {
    marginTop : theme.spacing(1),
    '& .MuiTextField-root': {
      marginTop: theme.spacing(0)
    },
    '& input#scheduleName.Mui-disabled':{
      color: "black"
    }
  },
  restrictedPaper: {
    minWidth  : '1300px',
    marginTop : theme.spacing(1),
    '& .MuiTextField-root': {
      marginTop: theme.spacing(0)
    },
    '& input#name.Mui-disabled':{
      color: "black"
    }
  },
  name: {
    marginLeft    : '67px',
    paddingBottom : '0 !important'
  },
  tooltip : {
    backgroundColor: '#ffff',
    marginRight    : theme.spacing(2),
    height         : 'fit-content',
    padding        : theme.spacing(1),
    "&:hover"      : {
      backgroundColor: "#ffff",
      color          : '#000'
    }
  },
  editIcon: {
    backgroundColor: theme.palette.primary.main,
    borderRadius   : theme.spacing(0.5),
    color          : theme.palette.common.white,
    display        : 'inline',
  },
  actions: {
    display       : 'flex',
    justifyContent: 'flex-end',
    '& .MuiButton-root': {
      backgroundColor: colors.PRIMARY_LIGHT,
    }
  },
  nestedMenu: {
    alignItems    : 'center',
    display       : 'flex',
    justifyContent: 'space-between',
  },
  form: {
    paddingTop   : theme.spacing(1),
    paddingLeft  : theme.spacing(2),
    paddingRight : theme.spacing(2),
    paddingBottom: theme.spacing(3)
  },
  action: {
    backgroundColor: theme.palette.grey[100],
    padding        : theme.spacing(2),
    textAlign      : 'right',
    '& .MuiButton-root:first-of-type': {
      marginRight: theme.spacing(2)
    }
  },
  hidden: {
    display : 'none',
  },
}));
