
const url      = window.WEBSOCKET_PATH;
const wsPrefix = '/websocket';

const wsFactory = {
  notification: {
    url   : url + wsPrefix,
    topics: [
      '/queue/errors',
      '/topic/events',
      '/topic/device-request',
      '/topic/devices',
      '/topic/get',
      '/topic/personById',
      '/topic/persons',
      '/user/queue/changes',
      '/topic/controllers/overallMisc',
      '/topic/controllers/overallState',
      '/topic/controllers/status',
      '/topic/controllers/newController',
      '/topic/controllers/newAccessPoints',
    ],
    onConnect: () => {
      console.log("Connected to Notifications...", url + wsPrefix);
    },
    onDisconnect: () => {
      console.log("Disconnected to Notifications...");
    },
    heartbeatIncoming: 0,
    heartbeatOutgoing: 20000,
    autoReconnect    : true,
    onConnectFailure : (err) => {
      console.log("connection failed: ", err)
    }
  },
}

export default wsFactory;
