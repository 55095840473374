import React from 'react';
import clsx from 'clsx';
import { Container, Paper, Typography, IconButton, AppBar, Toolbar, Grid, Box } from '@material-ui/core';
import useStyles from './styles';
import images from "../../utility/images";
import { useHistory } from 'react-router';
import Footer from '../../components/footer';
import { Trans, useTranslation } from 'react-i18next';

const Imprint = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t }   = useTranslation();

  const sesamsec = {
    email      : "info@sesamsec.com",
    faxNumber  : "+49 8233 79445-20",
    phoneNumber: "+49 8233 79445-0"
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <AppBar position="absolute" className={clsx(classes.appBar)}>
        <Toolbar className={classes.toolbar}>
          <IconButton size="small" onClick={() => history.push("/")}>
            <img src={images.SESAMSEC_LOGO} className={classes.logo} alt="elatec-logo"></img>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Paper className={`${classes.paper} ${classes.form}`}>
        <Grid container spacing={2} className={classes.form}>
          <Grid item md={12} className={classes.title}>
            <Typography variant="h2" className={`${'bold'} ${classes.title}`}>{t('imprint-page.title')}</Typography>
          </Grid>
        </Grid>
        <br/>
        <Grid container spacing={2} className={classes.form}>
          <Grid item md={12}>
            <Typography variant="h4" className={`${'bold'} ${classes.paragTitle}`}>{t('imprint-page.contact')}</Typography>
          </Grid>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('imprint-page.sesamsec')}
          </Typography>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('imprint-page.address1')}
          </Typography>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            {t('imprint-page.address2')}
          </Typography>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            <Trans i18nKey={'imprint-page.sesamsecPhoneNumber'} values={{ phoneNumber: sesamsec.phoneNumber }}/>
          </Typography>
        </Grid>
        <Grid className={classes.paragraph}>
          <Typography>
            <Trans i18nKey={'imprint-page.sesamsecFaxNumber'} values={{ faxNumber: sesamsec.faxNumber }}/>
          </Typography>
        </Grid>
        <Box className={classes.flexForm}>
          <Typography className={classes.paragraph} noWrap>
            {t('imprint-page.email')}&nbsp;  
            <a href={`mailto:${sesamsec.email}`} className={classes.link}>{sesamsec.email}</a>
          </Typography>
        </Box><br/>
        <Grid container spacing={2} className={`${classes.form} ${classes.space}`}>
          <Grid item md={12}>
            <Typography>
              {t('imprint-page.managingDirectors')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.form}>
          <Grid item md={12}>
            <Typography variant="h4" className={`${'bold'} ${classes.paragTitle}`}>{t('imprint-page.registrationAndVat')}</Typography>
          </Grid>
        </Grid>
        <Grid className={classes.form}>
          <Typography>{t('imprint-page.commercialRegister')}</Typography>
          <Typography>{t('imprint-page.vatNumber')}</Typography><br/>
        </Grid>
      </Paper>
      <Footer position='fixed'/>
    </Container>
  );
}

export default Imprint;