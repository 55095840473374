import React, { memo } from "react";
import { Box, Typography } from "@material-ui/core";
import useStyles from "./styles";
import images from "../../utility/images";
import { useTranslation } from 'react-i18next';

const Empty = (props) => {
  const classes = useStyles();
  const { t }   = useTranslation();
  const { variant } = props;

  return (
    <>
      <Box id="emptyComponent" className={classes.container}>
        <img src={images.EMPTY_TABLE} alt='empty-chip-container' />
        <Typography variant={variant} className={classes.emptyTitle}>{t('currentlyEmpty')}</Typography>
      </Box>
    </>
  )    
}

export default memo(Empty);