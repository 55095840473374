import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton, Typography } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from "@material-ui/lab";
import clsx from 'clsx';
import { closeSnackbar } from "notistack";
import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import useStyles from "./styles";

export const toastConfig = {
  info: {
    autoClose      : 3000,
    closeOnClick   : true,
    draggable      : true,
    hideProgressBar: true,
    pauseOnHover   : true,
    position       : toast.POSITION.TOP_RIGHT,
  }
}

const Toaster = forwardRef(({id, isOpen, title, description, severity, details, ...props}, ref) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [ expanded, setExpanded ] = useState(false);

  const handleExpandDetails = () => {
    setExpanded(!expanded);
  }

  const handleClose = useCallback(() => {
    closeSnackbar(id);
  }, [id]);

  useEffect(() => {
    if (!severity) {
      return;
    }

    if (severity === 'success') {
      setTimeout(() => {
        handleClose();
      }, 6000);
    }
  }, [handleClose, severity])

  if (!isOpen) return null;

  return (
    <Box ref={ref}>
      <Alert severity={severity || 'error'} variant={'filled'} className={classes.alert}>
        <Typography variant='body2'>{title}</Typography>
        <Typography variant='caption' className={classes.description}>{description}</Typography>
      </Alert>
      <Box className={!details && 'hidden'}>
        <IconButton
          onClick={handleClose} 
          className={clsx(classes.closeButton, {'hidden': !details} )}
        >
          <CloseIcon />
        </IconButton>
        <Box className={clsx(expanded ? classes.buttonExpanded : classes.button, {'hidden': !details} )}>
          <IconButton
            aria-label="moreDetails"
            className={classes.icon}
            onClick={handleExpandDetails}
          >
            <FontAwesomeIcon icon={expanded ? faAngleDown : faAngleRight}/>
          </IconButton>
          <Typography className={classes.buttonLabel}>{t('toaster-component.moreDetails')}</Typography>
        </Box>
      </Box>
      {
        expanded ?
          <Box className={classes.detailsContainer}>
            <Typography className={classes.details}>{details}</Typography>
          </Box>
        :
          <></>
      }
    </Box>
  );
});

export default Toaster;