import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../../theme/colors';

export default makeStyles((theme) => ({
  optionContainer: {
    alignItems    : 'center',
    display       : 'flex',
    height        : '350px',
    justifyContent: 'center',
    paddingBottom : theme.spacing(4),
    width         : '100%',
    '& .Mui-selected': {
      backgroundColor: colors.PRIMARY + ' !important',
      color          : colors.WHITE + ' !important',
    },
    '& .MuiToggleButtonGroup-root': {
      '& :hover': {
        backgroundColor: colors.PRIMARY + ' !important',
        color          : colors.WHITE + ' !important',
      },
    }
  },
  optionText: {
    marginLeft    : theme.spacing(1.5)
  },
  optionButton: {
    border         : '1px solid '+ colors.PRIMARY + ' !important',
    color          : colors.PRIMARY + ' !important',
    fontSize       : theme.spacing(2),
    height         : '60px',
    margin         : theme.spacing(1),
    padding        : theme.spacing(0),
    width          : '260px',
    textTransform  : 'none',

  }
}));