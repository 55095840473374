import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../theme/colors';

export default makeStyles((theme) => ({
  administratorModal: {
    alignItems      : 'center',
    borderRadius    : theme.spacing(1),
    display         : 'flex',
    justifyContent  : 'center',
  },
  modalContent: {
    backgroundColor : theme.palette.background.paper,
    boxShadow       : theme.shadows[5],
    padding         : theme.spacing(0),
    width           : '50%'
  },
  modalTitle:{
    alignItems   : 'center',
    display      : 'flex',
    fontSize     : '20px',
    padding      : theme.spacing(2),
    paddingBottom: theme.spacing(1),
    justifyContent: `space-between`
  },
  hrDivider:{
    border        : 0,
    borderBottom  : '1px solid' + colors.PRIMARY,
    height        : theme.spacing(-1)
  },
  paper: {
    display       : 'flex',
    justifyContent: 'space-between',
    marginTop     : theme.spacing(1),
    paddingBottom : theme.spacing(3),
    paddingTop    : theme.spacing(1),
    paddingLeft   : theme.spacing(2),
    paddingRight  : theme.spacing(2),
    '& .MuiTextField-root': {
      marginTop: theme.spacing(0)
    },
  },
  action: {
    backgroundColor: theme.palette.grey[100],
    padding        : theme.spacing(2),
    textAlign      : 'right',
    '& .MuiButton-root:first-of-type': {
      marginRight: theme.spacing(1)
    }
  },
  grid: {
    paddingBottom: '0 !important'
  },
  closeButton: {
    paddingRight: 0,
    paddingTop: 0,
    '&:hover': {
      background: 'transparent',
    },
    '& .MuiSvgIcon-root': {
      color   : colors.SECONDARY_DARK,
      fontSize: theme.spacing(3),
    }
  },
  icon: {
    marginLeft : theme.spacing(1),
    padding    : 0,
  }
}));