import { DELETE, GET, PATCH, POST } from "../utility/constants";
import api from "./api";
import { request } from "./requests";

export const getControllers = async (params) => {
  const response = await request({
    url     : api.CONTROLLERS,
    method  : GET,
    params  : params
  });
  
  return response;
}

export const getControllerById = async (id) => {
  const response = await request({
    url   : `${api.CONTROLLERS}/${id}`,
    method: GET
  });

  return response.data;
}

export const createController = async (name, locationId) => {
  return await request({
    url   : api.CONTROLLERS,
    method: POST,
    data  : {
      name       : name,
      locationId : locationId
    }
  });
}

export const updateController = async (id, data) => {
  await request({
    url   : `${api.CONTROLLERS}/${id}`,
    method: PATCH,
    data  : data
  });
}

export const deleteController = async (id) => {
  await request({
    url   : `${api.CONTROLLERS}/${id}`,
    method: DELETE
  });
}

export const rebootController = async (id) => {
  await request({
    url   : `${api.CONTROLLERS}/${id}/reboot`,
    method: POST
  });
}

export const updateControllerSoftware = async (id, url) => {
  await request({
    url   : `${api.CONTROLLERS}/${id}/update`,
    method: POST,
    data  : {
      url: url
    }
  });
}

export const rebuildController = async (id) => {
  await request({
    url   : `${api.CONTROLLERS}/${id}/rebuild`,
    method: POST
  });
}