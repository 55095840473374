import { Box, IconButton, TextField } from '@material-ui/core';
import { Form, Formik } from 'formik/dist';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import images from '../../utility/images';
import { locationSchema } from '../../validation/schema';
import useStyles from "./styles";

const LocationTextField = (props) => {
  const { wrapperRef, label, handleSubmit, initialValue, handleHideTextFiled} = props; 
  const { t } = useTranslation();
  const classes = useStyles();
  const formRef = useRef(null);
  const inputRef = useRef(null);

  // Hide text field when clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      const ref = wrapperRef ?? inputRef;
      if (ref.current && !ref.current?.contains(event.target)) {
        if (event.target?.id === label) {
          return;
        }
        handleHideTextFiled();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, inputRef, label, handleHideTextFiled]);

  const handleCreateButton = () => {
    formRef.current?.handleSubmit();
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValue}
      validationSchema={locationSchema}
      onSubmit={handleSubmit}
      innerRef={formRef}
    >
      {
        formik => (
         <Form>
            <Box className={classes.formContainer}> 
              <TextField
                id={label}
                label={`${label}*`}
                name='name'
                size='small'
                value={formik.values.name}
                onChange={formik.handleChange}
                ref={wrapperRef ?? inputRef}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={t(formik.touched.name) && t(formik.errors.name)}
              />
              <IconButton innerRef={wrapperRef ?? inputRef} className={classes.actionButton} size="small" onClick={handleCreateButton}>
                <img src={images.CHECK_ICON} className={classes.icon} alt="edit-icon"/> 
              </IconButton>
            </Box>
          </Form>
        )
      }
    </Formik>
  )
}

export default LocationTextField