import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../theme/colors';

export const legend = {
  align           : 'right',
  backgroundColor : colors.SECONDARY_LIGHT,
  enable          : true,
  layout          : 'vertical',
  padding         : 16,
  itemMarginTop   : 5,
  verticalAlign   : 'middle',
}

export const labelStyles = {
  color           : colors.SECONDARY_TEXT,
  fontSize        : '14px',
}

export const tooltip = {
  borderColor: colors.PRIMARY_TEXT,
  borderRadius: 4,
  borderWidth: 0,
}

export const xAxis = {
  lineColor : colors.SECONDARY_DARK,
  tickColor : colors.SECONDARY_DARK,
  minorGridLineWidth:1,
  minorTicks: true,
  minorTickInterval: 80,
  lineWidth : 2,
  tickWidth : 1,
  tickLength: 8,
  startOnTick: false,
  endOnTick: false,
  gridLineWidth: 1
}

export const yAxis = {
  lineColor : colors.SECONDARY_DARK,
  tickColor : colors.SECONDARY_DARK,
  lineWidth : 2,
  tickWidth : 1,
  tickLength: 8,
  startOnTick: true,
  endOnTick: true,
}

export default makeStyles((theme) => ({
  datalabels: {
    '& text':{
      fontSize      : '16px !important',
      fontStyle     : 'Normal',
      fontFamily    : 'Arial',
    },
    '& .highcharts-text-outline': {
      strokeWidth   : 0,
    }
  },
  eventChart: {
    minWidth       : '500px',
    padding        : theme.spacing(3),
    paddingLeft    : theme.spacing(6),
    paddingRight   : theme.spacing(6),
    paddingBottom  : theme.spacing(1),
    '& .highcharts-container': {
      border: '1px solid black'
    },
    '& .highcharts-legend': {
      '& text': {
        fill     : colors.PRIMARY + '!important',
      }
    }
  },
  empty: {
    height         : '400px',
    paddingBottom  : theme.spacing(7),
    paddingLeft    : theme.spacing(9),
    paddingRight   : theme.spacing(9.5),
    paddingTop     : theme.spacing(8)
  }
}));