import { TableCell, TableRow } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const CredentialsTableSkeleton = () => {
  return (
    <TableRow>
      {
        [...Array(6)].map((_, index) => <TableCell key={index}> <Skeleton variant='rect'/> </TableCell>)
      }
    </TableRow>
  )
}

export default CredentialsTableSkeleton;