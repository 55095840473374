import React from 'react';
import { Box, Button, Paper, Dialog, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import clsx from 'clsx';
import cookies from 'js-cookie';
import { LANGUAGE_EN } from '../../../utility/constants';

const CredentialsModal = (props) => {
  const { initialValues, onClose, open, handleChangeActive } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const currentLanguageCode = cookies.get('i18next') || LANGUAGE_EN;

  return (
    <>
    <Dialog
      id="credentialModal"
      modal="false"
      open={open}
      onClose={onClose}
      aria-labelledby="row-credential-dialog"
      aria-describedby="row-credential-dialog-table"
      className={classes.dialog}
    >
      <Paper className={classes.credentialModal}>
        <Box className={classes.dialogImage}>
          <Box className={clsx(initialValues.active ? classes.imageDelete : classes.imageUpdate)}/>
        </Box>
        <Box className={classes.dialogImage}>
        </Box>
        <Box className={classes.dialogTitle}>
          <Typography><b>{initialValues.active ? t(`deactivateCredential`) : t(`activateCredential`) }</b></Typography>
        </Box>
        <Box className={classes.dialogContent}>
        {(currentLanguageCode === LANGUAGE_EN)?
          <Typography className={classes.text}>
            {initialValues.active ? t(`areYouSureYouWantToDeactivate`) : t(`areYouSureYouWantToActivate`) } {initialValues.credentialNumber}?           
          </Typography>         
        :
          <Typography className={classes.text}>
            {t(`areYouSureYouWantTo`)}{initialValues.credentialNumber}{initialValues.active ? t(`deactivate`) : t(`activate`)}? 
          </Typography>        
        }
        </Box>
      </Paper>
      <Box className={classes.dialogActions}>
        <Button id="credentialActivate" color="primary" variant="contained" className={classes.dialogCancelButton} onClick={handleChangeActive}>
          {initialValues.active ? t(`yesDeactivate`) : t(`yesActivate`)} 
        </Button>
        <Button id="credentialDontActivate" color="primary" variant="outlined" className={classes.dialogSubmitButton} onClick={onClose}>
          {initialValues.active ? t(`NoDontDeactivate`) : t(`NoDontActivate`)}
        </Button>
      </Box>
    </Dialog >
    </>
  );
}

export default CredentialsModal;