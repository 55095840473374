import { Box, Button, Container, Grid, Link, Tooltip, Typography } from "@material-ui/core";
import useStyles from './styles';
import { useTranslation } from "react-i18next";
import images from "../../../utility/images";
import { ContentDescription, ContentTitle } from "../../../components/typography";
import { ArrowBack } from "@material-ui/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect, useRef, useState } from "react";
import clsx from 'clsx';
import EnhancedTitle from "../../../components/enhanced-title";
import { PRODUCTION, STAGING } from "../../../utility/constants";

const VisualAid = (props) => {
  const { src, alt } = props;
  const classes      = useStyles();

  return (
    <img src={src} className={classes.guideImg} alt={alt} />
  )
}

const Content = (props) => {
  const { contentRef, serverInfo } = props;
  const classes = useStyles();
  const { t }   = useTranslation();
  const history = useHistory();

  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    if (!isHovered) {
      setIsClicked(false);
    }
    setTimeout(() => {
      if (isClicked) {
        setIsClicked(false);
      }
    }, 2000);
  }, [isClicked, isHovered])

  const azurePortalLink             = 'https://portal.azure.com/';
  const msEntraDomainServicesLink   = 'https://portal.azure.com/#browse/Microsoft.AAD%2FdomainServices';
  const configureVirtualNetworkLink = 'https://learn.microsoft.com/en-us/entra/identity/domain-services/tutorial-configure-networking';

  const handleBackToSyncing = () => {
    history.push('/syncing-users');
  }

  const handleCopyServerInfo = () => {
    setIsClicked(true);
    navigator.clipboard.writeText(serverInfo);
  }

  const domainAndLdapPrerequisite = [
    'entraIdUserGuide.domainAndLdap-prerequisiteItem1',
    'entraIdUserGuide.domainAndLdap-prerequisiteItem2',
    'entraIdUserGuide.domainAndLdap-prerequisiteItem3'
  ]

  const createManagedDomain = [
    'entraIdUserGuide.domainAndLdap-item-3-subItem1',
    'entraIdUserGuide.domainAndLdap-item-3-subItem2',
    'entraIdUserGuide.domainAndLdap-item-3-subItem3'
  ]

  return (
    <Box className={classes.content}>
      {/* Setup domain and create LDAP */}
      <Box className={classes.sectionContainer} ref={contentRef.manageDomainRef}>
        <ContentTitle content={'entraIdUserGuide.domainAndLdap-header'} />
        <ContentTitle content={'entraIdUserGuide.domainAndLdap-prerequisiteTitle'} isSubTitle={true} removeBottomSpace={true} />
        <ContentDescription content={'entraIdUserGuide.domainAndLdap-prerequisiteSubTitle'} indent={0} />        
        {
          domainAndLdapPrerequisite.map((item, index) => {
            const isLastItem = index === domainAndLdapPrerequisite.length - 1 ? 1 : 0;
            return (
              <ContentDescription content={`• ${t(item)}`} indent={1} addSpace={isLastItem}/>
            )
          })
        }

        <ContentTitle content={'entraIdUserGuide.domainAndLdap-title'} isSubTitle={true}/>
        <Typography variant='body2' className={classes.stepWithLink}>
          {t('entraIdUserGuide.domainAndLdap-item-1')} <Link className={classes.textWithLink} href={azurePortalLink} target="_blank" rel="noopener noreferrer">{t('entraIdUserGuide.domainAndLdap-item-1-link')}</Link>
        </Typography>
        <Typography variant='body2' className={classes.stepWithLink}>
          {t('entraIdUserGuide.domainAndLdap-item-2')} <Link className={classes.textWithLink} href={msEntraDomainServicesLink} target="_blank" rel="noopener noreferrer">{t('entraIdUserGuide.domainAndLdap-item-2-link')}</Link>
        </Typography>
        <VisualAid src={images.ENTRAIDGUIDE.CREATEDOMAIN_STEP2} alt="entra ID guide Setup domain and create LDAP step2" />
        <ContentDescription content={'entraIdUserGuide.domainAndLdap-item-3'} indent={1} />
        {
          createManagedDomain.map(item => (
              <ContentDescription content={`• ${t(item)}`} indent={2} />
            )
          )
        }
        <ContentDescription content={'entraIdUserGuide.domainAndLdap-item-3-note'} />
        <VisualAid src={images.ENTRAIDGUIDE.CREATEDOMAIN_STEP3} alt="entra ID guide Setup domain and create LDAP step3" />
        <Typography variant='body2' className={classes.stepWithLink}>
          {t('entraIdUserGuide.domainAndLdap-item-4')} <Link className={classes.textWithLink} href={configureVirtualNetworkLink} target="_blank" rel="noopener noreferrer">{t('entraIdUserGuide.domainAndLdap-item-4-link')}</Link>
        </Typography>
        <VisualAid src={images.ENTRAIDGUIDE.CREATEDOMAIN_STEP4} alt="entra ID guide Setup domain and create LDAP step4" />
        <ContentDescription content={'entraIdUserGuide.domainAndLdap-item-5'} />
        <VisualAid src={images.ENTRAIDGUIDE.CREATEDOMAIN_STEP5} alt="entra ID guide Setup domain and create LDAP step5" />
        <ContentDescription content={'entraIdUserGuide.domainAndLdap-item-6'} />
        <VisualAid src={images.ENTRAIDGUIDE.CREATEDOMAIN_STEP6} alt="entra ID guide Setup domain and create LDAP step6" />
        <ContentDescription content={'entraIdUserGuide.domainAndLdap-item-7'} />
        <VisualAid src={images.ENTRAIDGUIDE.CREATEDOMAIN_STEP7} alt="entra ID guide Setup domain and create LDAP step7" />
        <ContentDescription content={'entraIdUserGuide.domainAndLdap-item-8'} />
        <VisualAid src={images.ENTRAIDGUIDE.CREATEDOMAIN_STEP8} alt="entra ID guide Setup domain and create LDAP step8" />
        <ContentDescription content={'entraIdUserGuide.domainAndLdap-item-9'} addSpace={1}/>
        <ContentDescription content={'entraIdUserGuide.domainAndLdap-item-10'} />
        <VisualAid src={images.ENTRAIDGUIDE.CREATEDOMAIN_STEP10} alt="entra ID guide Setup domain and create LDAP step10" />
      </Box>

      {/* Configure Secure LDAP */}
      <Box className={classes.sectionContainer} ref={contentRef.secureLdapRef}>
        <ContentTitle content={'entraIdUserGuide.secureLdapAndTenant-header'} />
        <ContentTitle content={'entraIdUserGuide.secureLdapAndTenant-prerequisiteTitle'} isSubTitle={true} removeBottomSpace={true} />
        <ContentDescription content={'entraIdUserGuide.secureLdapAndTenant-prerequisiteSubTitle'} indent={0} addSpace={1} />        

        <ContentTitle content={'entraIdUserGuide.secureLdapAndTenant-title'} isSubTitle={true}/>
        <Typography variant='body2' className={classes.stepWithLink} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
          {t('entraIdUserGuide.secureLdapAndTenant-item-1')}
          {`${serverInfo}.`}
          <Tooltip title={isClicked && isHovered ? t('controller-page.copied') : t('controller-page.copy')}>
            <span className={classes.textToClipboard} onClick={() => handleCopyServerInfo()}>
              {t('entraIdUserGuide.secureLdapAndTenant-item-1-link')}
            </span>
          </Tooltip>
        </Typography>
        <ContentDescription content={'entraIdUserGuide.secureLdapAndTenant-item-2'} indent={1} />
        <Box>
          <VisualAid src={images.ENTRAIDGUIDE.SECURELDAP_STEP2_1} alt="entra ID guide Configure secure LDAP step2-1" />
        </Box>
        <Box>
          <VisualAid src={images.ENTRAIDGUIDE.SECURELDAP_STEP2_2} alt="entra ID guide Configure secure LDAP step2-2" />
        </Box>
        <ContentDescription content={'entraIdUserGuide.secureLdapAndTenant-item-3'} indent={1} />
        <VisualAid src={images.ENTRAIDGUIDE.SECURELDAP_STEP3} alt="entra ID guide Configure secure LDAP step5" />
      </Box>

      {/* Create group and add members */}
      <Box className={classes.sectionContainer} ref={contentRef.createGroupRef}>
        <ContentTitle content={'entraIdUserGuide.createGroupAddMembers-header'} />
        <ContentDescription content={'entraIdUserGuide.createGroupAddMembers-description'} indent={0} addSpace={1} />

        <ContentTitle content={'entraIdUserGuide.createGroup-title'} isSubTitle={true}/>
        <ContentDescription content={'entraIdUserGuide.createGroup-item-1'} indent={1} addSpace={1} />
        <ContentDescription content={'entraIdUserGuide.createGroup-item-2'} indent={1} />
        <VisualAid src={images.ENTRAIDGUIDE.CREATEGROUP_STEP2} alt="entra ID guide Create group step2" />
        <ContentDescription content={'entraIdUserGuide.createGroup-item-3'} isI18n={true} indent={1} />
        <VisualAid src={images.ENTRAIDGUIDE.CREATEGROUP_STEP3} alt="entra ID guide Create group step3" />

        <ContentTitle content={'entraIdUserGuide.addMember-title'} isSubTitle={true} />
        <ContentDescription content={'entraIdUserGuide.addMember-item-1'} indent={1} addSpace={1} />
        <ContentDescription content={'entraIdUserGuide.addMember-item-2'} indent={1} />
        <VisualAid src={images.ENTRAIDGUIDE.ADDMEMBER_STEP2} alt="entra ID guide Add members step2" />
        <ContentDescription content={'entraIdUserGuide.addMember-item-3'} indent={1} />
        <VisualAid src={images.ENTRAIDGUIDE.ADDMEMBER_STEP3} alt="entra ID guide Add members step3" />
        <ContentDescription content={'entraIdUserGuide.addMember-item-4'} indent={1} />
        <VisualAid src={images.ENTRAIDGUIDE.ADDMEMBER_STEP4} alt="entra ID guide Add members step4" />
        <ContentDescription content={'entraIdUserGuide.addMember-item-5'} indent={1} />
        <VisualAid src={images.ENTRAIDGUIDE.ADDMEMBER_STEP5} alt="entra ID guide Add members step5" />
        <ContentDescription content={'entraIdUserGuide.addMember-reminder'} isI18n={true} indent={0} addSpace={2} />
      </Box>

      {/* Syncing users configuration in the sesamsec Portal */}
      <Box ref={contentRef.syncConfigRef}>
        <ContentTitle content={'entraIdUserGuide.sync-user-configuration-header'} />
        <ContentDescription content={'entraIdUserGuide.sync-user-configuration-item-1'} isI18n={true} indent={1} />
        <VisualAid src={images.ENTRAIDGUIDE.SYNCCONFIG_STEP1} alt="entra ID guide sync user configuration step1" />
        <ContentDescription content={'entraIdUserGuide.sync-user-configuration-item-2'} isI18n={true} indent={1} />
        <Box>
          <VisualAid src={images.ENTRAIDGUIDE.SYNCCONFIG_STEP2_1} alt="entra ID guide sync user configuration step2-1" />
        </Box>
        <Box>
          <VisualAid src={images.ENTRAIDGUIDE.SYNCCONFIG_STEP2_2} alt="entra ID guide sync user configuration step2-2" />
        </Box>
        <ContentDescription content={'entraIdUserGuide.sync-user-configuration-item-3'} isI18n={true} indent={1} />
        <VisualAid src={images.ENTRAIDGUIDE.SYNCCONFIG_STEP3} alt="entra ID guide sync user configuration step3" />
        <ContentDescription content={'entraIdUserGuide.sync-user-configuration-item-4'} isI18n={true} indent={1} />
        <VisualAid src={images.ENTRAIDGUIDE.SYNCCONFIG_STEP4} alt="entra ID guide sync user configuration step4" />
        <ContentDescription content={'entraIdUserGuide.sync-user-configuration-item-5'} isI18n={true} indent={1} />
      </Box>
      <Grid container className={classes.backToSyncButtonContainer}>
        <Grid item xs={12}>
          <Button
            aria-label="backToSyncUsers"
            id="backToSyncingUsersButton"
            variant="contained"
            className={classes.backToSyncButton}
            startIcon={<ArrowBack />}
            onClick={() => handleBackToSyncing()}
          >
            {t("entraIdUserGuide.backToSyncingUsers")}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

const TableOfContents = (props) => {
  const { contentRef } = props;
  const classes = useStyles();
  const { t }   = useTranslation();

  const handleScrollToContent = (currentRef) => {
    if (currentRef.current) {
      currentRef.current.scrollIntoView({ behavior: 'smooth'});
    }
  }

  return (
    <Grid container className={classes.tableOfContents}>
      <Grid item xs={12}>
        <ContentTitle content={'entraIdUserGuide.tableOfContent-title'} isTableOfContentsTitle={true} />
      </Grid>
      <Box component="blockquote" className={classes.blockQuoteContainer}>
        <Typography variant="body2" className={clsx(classes.blockQuoteContentAdditive, classes.blockQuoteContent)} onClick={() => handleScrollToContent(contentRef.manageDomainRef)}>
          {t('entraIdUserGuide.tableOfContent-domainAndLdap')}
        </Typography>
        <Typography variant="body2" className={clsx(classes.blockQuoteContentAdditive, classes.blockQuoteContent)} onClick={() => handleScrollToContent(contentRef.secureLdapRef)}>
          {t('entraIdUserGuide.tableOfContent-securingLdapAndTenant')}
        </Typography>
        <Typography variant="body2" className={clsx(classes.blockQuoteContentAdditive, classes.blockQuoteContent)} onClick={() => handleScrollToContent(contentRef.createGroupRef)}>
          {t('entraIdUserGuide.tableOfContent-createGroupAndMembers')}
        </Typography>
        <Typography variant="body2" className={clsx(classes.blockQuoteContent)} onClick={() => handleScrollToContent(contentRef.syncConfigRef)}>
          {t('entraIdUserGuide.tableOfContent-syncUserConfiguration')}
        </Typography>
      </Box>
    </Grid>
  );
}

const EntraIdUserGuide = () => {
  const classes = useStyles();
  const { t }   = useTranslation();

  const [serverInfo, setServerInfo] = useState('');

  useEffect(() => {
    getServerInfo();
  }, []);

  const getServerInfo = async () => {
    const tenantIp = window.ENVIRONMENT === PRODUCTION || window.ENVIRONMENT === STAGING ? '20.113.53.116' : '20.113.116.28';
    setServerInfo(tenantIp);
  }

  const manageDomainRef = useRef(null);
  const secureLdapRef   = useRef(null);
  const createGroupRef  = useRef(null);
  const syncConfigRef   = useRef(null);

  const contentRef = {
    manageDomainRef,
    secureLdapRef,
    createGroupRef,
    syncConfigRef
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <EnhancedTitle title={t('entraIdUserGuide.title')} subtitle={t('entraIdUserGuide.title')}/>
      <TableOfContents contentRef={contentRef}/>
      <Content
        contentRef={contentRef}
        serverInfo={serverInfo}
      />
    </Container>
  );
};
  
export default EntraIdUserGuide;