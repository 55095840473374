import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  paper: {
    padding     : theme.spacing(1),
    paddingLeft : theme.spacing(3),
    paddingRight: theme.spacing(3),
    width       : '100%',
    '& .MuiSkeleton-root': {
      marginBottom  : theme.spacing(1),
      transform     : 'none',
    },
  }
}));