import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, IconButton, Link, Tooltip } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { LocationContext } from '../../context/locationContext';
import { DECREMENT, INCREMENT } from '../../reducer/locationReducer';
import { createArea, createLocation, deleteArea, getAreasAndLocations, updateArea } from '../../service/locationsAPI';
import { ACTION_VIEW, LOCATION, LOCATIONS_ERROR_TOASTER, LOCATIONS_SUCCESS_TOASTER, LOCATIONS_TEXT_FIELD_LABEL, LOCATION_PROJECTIONS } from '../../utility/constants';
import { formatLocationsSubAreas, setLocalStorageItem } from '../../utility/helper';
import images from '../../utility/images';
import LocationDeleteModal from '../location-delete-modal';
import LocationItem from '../location-item';
import LocationMenu from '../location-menu';
import LocationTextField from '../location-text-field';
import useStyles from "./styles";
import { AuthContext } from '../../context/authContext';
import { UPDATE_ACCOUNT } from '../../reducer/authReducer';

const AreaItem = (props) => {
  const { t } = useTranslation();
  const { name, id, level, data: subArea, handleUpdateList, showToaster, showLoading } = props;
  
  const { state : administratorState, dispatch : dispatchAdministrator } = useContext(AuthContext);

  const classes = useStyles();

  const history  = useHistory();
  const { pathname } = history.location;

  const isView = pathname?.includes(ACTION_VIEW);

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [areaName, setAreaName] = useState(name); 
  const [label, setLabel] = useState('');

  const [showTextField, setShowTextField] = useState(false);
  const [showUpdateTextField, setShowUpdateTextField] = useState(false);
  const [initialValue, setInitialValue] = useState({ name : '' });
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(subArea);

  const dispatch = useContext(LocationContext)?.dispatch;

  const wrapperRef = useRef(null);

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  }

  const handleCreateButton = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(prev => !prev);
  }

  const handleUpdateButton = () => {
    setInitialValue({ name: areaName, id: id });
    setShowUpdateTextField(!showUpdateTextField);
    setLabel('');
  }

  const handleDeleteButton = (event) => {
    setOpenModal(true);
  }

  const handleCloseCreateButton = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleTextField = () => {
    setAnchorEl(null);
    setInitialValue({ name: '' });
    setShowTextField(true);
    setIsCollapsed(false);
  }

  const handleCreateLocation = () => {
    setOpen(false);
    setAnchorEl(null);
    setLabel(t(LOCATIONS_TEXT_FIELD_LABEL.LOCATION_NAME));
    handleTextField();
  }

  const handleCreateArea = () => {
    setOpen(false);
    setAnchorEl(null);
    setLabel(t(LOCATIONS_TEXT_FIELD_LABEL.AREA_NAME));
    handleTextField();
  }

  const showCreateUpdateToaster = (values, type) => {
    const { UPDATE_LOCATION_DESCRIPTION, CREATE_LOCATION_DESCRIPTION, UPDATE_AREA_DESCRIPTION, 
      CREATE_AREA_DESCRIPTION, TITLE, SEVERITY} = type;

    let message = values.id ? UPDATE_AREA_DESCRIPTION: CREATE_AREA_DESCRIPTION;

    const isLocation = label === t(LOCATIONS_TEXT_FIELD_LABEL.LOCATION_NAME);
    if (isLocation) {
      message = values.id ? UPDATE_LOCATION_DESCRIPTION: CREATE_LOCATION_DESCRIPTION;
    }

    showToaster(t(TITLE), <Trans i18nKey={message} values={{ name: values.name }}/>, SEVERITY);
  }

  const showDeleteToaster = (type, messageError) => {
    const { DELETE_LOCATION_DESCRIPTION, DELETE_AREA_DESCRIPTION, TITLE, SEVERITY} = type;
    const isLocation = label === t(LOCATIONS_TEXT_FIELD_LABEL.LOCATION_NAME);

    let message = isLocation ? DELETE_LOCATION_DESCRIPTION: DELETE_AREA_DESCRIPTION;
    
    message = messageError ? LOCATIONS_ERROR_TOASTER.DELETE_AREA_LOCATION_DESCRIPTION : message;

    showToaster(t(TITLE), <Trans i18nKey={message} values={{ name }}/>, SEVERITY);
  }

  const handleSubmit = async (values, formik) => {
    const { setErrors } = formik;
    if (values.id && areaName === values.name) {
      handleHideTextFiled();
      return;
    }

    const errorMessage = label === t(LOCATIONS_TEXT_FIELD_LABEL.LOCATION_NAME) ? 
      t('locations-page.locationNameAlreadyExists') : t('locations-page.areaNameAlreadyExists');

    try {
      let locationsSubAreas = [];
      if (label === t(LOCATIONS_TEXT_FIELD_LABEL.LOCATION_NAME)) {
        locationsSubAreas = await createLocation(values.name, id, data);

        dispatch({type: INCREMENT, payload: 1 });
        updateAdministratorState(locationsSubAreas, values.name);
      } else {
        if (level > 6) {
          return;
        }

        if (values.id) {
          const response = await updateArea(values.name, values.id);
          const { name, areaId } = response;
          setAreaName(name);
          await handleUpdateList({ areaId, name });
        } else {
          locationsSubAreas = await createArea(values.name, id, data);
        }
      }

      await handleGetArea();
      setData(locationsSubAreas);
      setShowTextField(false);
      showCreateUpdateToaster(values, LOCATIONS_SUCCESS_TOASTER);
    } catch (error) {
      if (error?.response?.status === 409) {
        setErrors({
          name: errorMessage
        });
      } else {
        showCreateUpdateToaster(values, LOCATIONS_ERROR_TOASTER);
      }
    }
  }

  const updateAdministratorState = (locationsSubAreas, locationName) => {
    const location = locationsSubAreas.find(location => location.name === locationName && location.locationId);
    if (!location) {
      return;
    }

    const administrator = {
      ...administratorState.administrator,
      locations: [
        ...administratorState.administrator.locations,
        {
          administratorId: administratorState.administrator.administratorId,
          name: locationName,
          locationId: location.locationId
        }
      ]
    }
    
    setLocalStorageItem("locations", administrator.locations);

    const administratorPayload = {
       ...administratorState,
       administrator: administrator
    }
     
    dispatchAdministrator({type: UPDATE_ACCOUNT, payload: administratorPayload});
  }

  const handleGetArea = async () => {
    try {
      const response = await getAreasAndLocations(id, LOCATION_PROJECTIONS.NESTER_PARENT_CHILDREN);
      const { locations, subAreas } = response;
      
      const locationsSubAreas = locations.concat(subAreas);
      
      locationsSubAreas.sort((a, b) => a.name.localeCompare(b.name));
      const formattedData = formatLocationsSubAreas(locationsSubAreas);
      
      setData(formattedData);
    } catch (error){
      showToaster(
        t(LOCATIONS_ERROR_TOASTER.TITLE), 
        t(LOCATIONS_ERROR_TOASTER.GET_AREA_DESCRIPTION), 
        LOCATIONS_ERROR_TOASTER.SEVERITY
      );
    }
  }

  const handleDelete = async () => {
    setOpenModal(false);
    try {
      await deleteArea(id);

      await handleUpdateList({ areaId : id, isDelete: true });
      showDeleteToaster(LOCATIONS_SUCCESS_TOASTER, null)
    } catch (error) {
      const data = error.response?.data;
      const messageError = data.includes(LOCATION); 
      showDeleteToaster(LOCATIONS_ERROR_TOASTER, messageError);
    }
  }

  const handleDeleteCancel = () => {
    setOpenModal(false);
  }

  const handleHideTextFiled = useCallback(() => {
    setShowTextField(false);
    setShowUpdateTextField(false);
  }, []);

  const handleAreaView = () => {
    history.push(`/locations/view/area/${id}`);
  }

  const handleSubUpdateList = async (values) => {
    await handleGetArea();
    if (values.locationId && values.isDelete) {
      dispatch({type: DECREMENT });
    }
  } 

  useEffect(() => {
    setAnchorEl(null);
    if (wrapperRef.current) {
      wrapperRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showTextField]);

  return (
    <>
      <LocationDeleteModal
        openModal={openModal} 
        title={t('locations-page.deleteAreaTitle')}
        description={t('locations-page.deleteAreaDescription')}
        handleDelete={handleDelete}
        handleCancel={handleDeleteCancel}
      />
      <Accordion
        expanded={!isCollapsed}
        data-testid="accordion"
      >
        <AccordionSummary >
          <Grid container className={classes.areaItemcontainer}>
            <Grid onClick={() => handleCollapse()} className={classes.name} item md={isView ? 12 : 11} xs={isView ? 12 : 11}>
              {
                showUpdateTextField ? 
                  <LocationTextField 
                    label={t('locations-page.areaName')}
                    handleSubmit={handleSubmit}
                    initialValue={initialValue}
                    handleHideTextFiled={handleHideTextFiled}
                  />
                :
                  <>
                    <IconButton
                      data-testid="accordion-button"
                      id={`${id}CollapseButton`}
                      size="small"
                      onClick={() => handleCollapse()}
                      className={`${classes.collapseIcon} ${classes.icon}`}
                    >
                      <FontAwesomeIcon data-testid="collapse-icon" icon={isCollapsed ? faAngleRight: faAngleDown}/>
                    </IconButton>
                    <Link 
                      data-testid="area-link" 
                      onClick={() => handleAreaView()} 
                      className={classes.areaLink}>{name}</Link>
                  </>
              }
            </Grid>
            {
              !isView ?
                <Grid className={classes.actionIcons} item md={1} xs={1}>
                  <Tooltip title={t('addNew')}>
                    <IconButton className={classes.actionButton} size="small" onClick={handleCreateButton}>
                      <img src={images.ADD_ICON} className={classes.icon} alt="add-icon"/> 
                      <LocationMenu
                        handleCreateLocation={handleCreateLocation}
                        handleCreateArea={handleCreateArea}
                        handleCloseCreateButton={handleCloseCreateButton}
                        anchorEl={anchorEl}
                        open={open}
                        areaLabel={t('locations-page.addSubArea')}
                        level={level}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('locations-page.updateAreaTitle')}>
                    <IconButton 
                      data-testid="edit-button" 
                      className={classes.actionButton} 
                      size="small" 
                      onClick={handleUpdateButton}
                    >
                      <img src={images.EDIT_ICON} className={classes.icon} alt="edit-icon"/> 
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('locations-page.removeAreaTitle')}>
                    <IconButton
                      data-testid="edit-button" 
                      className={classes.actionButton} 
                      size="small" 
                      onClick={handleDeleteButton}
                    >
                      <img src={images.DELETE_ICON} className={classes.icon} alt="delete-icon"/> 
                    </IconButton>
                  </Tooltip>
                </Grid>
              :
                <></>
            }
          </Grid>
        </AccordionSummary>
        <AccordionDetails data-testid="accordion-details">
          {
            data.map((item, index) => (
              item.areaId ?
                <Grid key={`${item.areaId}-${item.name}-${index}`} container className={classes.subContainer}>
                    <AreaItem
                      name={item.name}
                      showToaster={showToaster}
                      showLoading={showLoading}
                      id={item.areaId}
                      data={item.data}
                      level={item.level}
                      handleUpdateList={handleSubUpdateList}
                    />
                </Grid>
              :
                <Grid key={`${item.locationId}-${item.name}-${index}`} container className={classes.subContainer}>
                  <LocationItem 
                    id={item.locationId}
                    name={item.name}
                    showToaster={showToaster}
                    showLoading={showLoading}
                    handleUpdateList={handleSubUpdateList}
                  />
                </Grid>
              )
            )
          }
          {
            showTextField ?
              <Box className={classes.locationTextField}>
                <LocationTextField 
                  wrapperRef={wrapperRef}
                  label={label}
                  handleSubmit={handleSubmit}
                  initialValue={initialValue}
                  handleHideTextFiled={handleHideTextFiled}
                />
              </Box>
            :
            <></>
          }
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default AreaItem