import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './locales/en/translationEN.json';
import translationDE from './locales/de/translationDE.json';
import translationI18n from './locales/i18n/translationI18N.json';
import { LANGUAGE_DE, LANGUAGE_EN } from './utility/constants';

const resources = {
  en: {
    translation: translationEN
  },
  de: {
    translation: translationDE
  },
  i18n: {
    translation: translationI18n
  }
}

i18n
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .use(LanguageDetector)
  .init({
    resources,
    supportedLngs: [LANGUAGE_EN, LANGUAGE_DE],
    // lng: document.querySelector('html').lang,
    fallbackLng: LANGUAGE_EN,
    interpolation: {
      escapeValue: false
    },
    detection: {
      order: ['localStorage', 'cookie', 'navigator', 'htmlTag', 'localStorage', 'sessionStorage', 'navigator', 'querystring', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie']
    }

  });

export default i18n;