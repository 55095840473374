import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  container: {
    paddingTop   : theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(1),

    '& .MuiTextField-root': {
      marginTop: theme.spacing(0)
    },
    '& input#profileValidFrom.Mui-disabled':{
      color: "black"
    },
    '& input#profileValidUntil.Mui-disabled':{
      color: "black"
    },
    '& input#profileName.Mui-disabled':{
      color: "black"
    },
    '& textarea#profileDescription.Mui-disabled':{
      color: "black"
    }
  },
  details: {
    color: 'gray',
  },
  detailsHeader: {
    display       : 'flex',
    justifyContent: 'space-between',
  },
  iconButton: {
    backgroundColor: theme.palette.primary.main,
    borderRadius   : theme.spacing(0.5),
    color          : theme.palette.common.white,
    display        : 'inline',
  },
  grid: {
    padding     : theme.spacing(1),
    paddingLeft : theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  action: { 
    backgroundColor: theme.palette.grey[100],
    marginTop      : theme.spacing(6),
    padding        : theme.spacing(2),
    textAlign      : 'right',

    '& .MuiButton-root:first-of-type': {
      marginRight: theme.spacing(2),
    },
  },
  view: {
    marginTop: theme.spacing(2)
  },
  skeleton: {
    display       : 'flex',
    justifyContent: 'center',
    alignItems    : 'center',
    height        : '100%',
  },
  selectContainer: {
    border      : 'thin',
    borderStyle : 'solid',
    borderRadius: '4px',
    borderColor : '#c4c4c4',
    marginTop   : theme.spacing(-1),
  },
  fieldsetLegend:{
    color   : '#767779',
    fontSize: '12px',
  },
  selectGrid: {
    padding: theme.spacing(1),
  },
  accessGroup: {
    padding       : theme.spacing(1),
  },
  chipContainer: {
    padding       : theme.spacing(2),
    paddingTop    : theme.spacing(3.8)
  },  
  empty: {
    alignContent  : 'center',
    alignItems    : 'center',
    color         : theme.palette.text.secondary,
    display       : 'flex',
    flexDirection : 'column',
    justifyContent: 'center',
  },
  chipHeader: {
    height        : 'fit-content'
  },
  chipBody : {
    height        : '100%'
  },
  headerHidden: {
    marginTop     : theme.spacing(3)
  },
  characterLimit: {
    color   : colors.SECONDARY,
    float   : 'right',
    fontSize: '12px',
    position: 'relative',
    top     : '-0.3em'
  },
  characterLimitError: {
    color   : colors.SECONDARY,
    float   : 'right',
    fontSize: '12px',
    position: 'relative',
    top     : '-2.205em'
  },
  gridItem: {
    paddingBottom: '0 !important'
  }
}));
