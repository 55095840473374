import React, { useContext, useEffect, useRef, useState } from "react";
import useStyles from './styles';
import { Box, Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import Wizard from "./wizard";
import { Timeline, TimelineConnector, TimelineDot, TimelineItem, TimelineSeparator } from "@material-ui/lab";
import clsx from 'clsx';
import { useTranslation } from "react-i18next";
import { CREATE, SAVE, EXISTING_CREDENTIAL, NEW_CREDENTIAL, NEXT, SEARCH } from "../../utility/constants";
import { WizardContext } from "../../context/wizardContext";
import { WizardPersonContext } from "../../context/wizardPersonContext";
import { DECREMENT, INCREMENT, SET_NEXT_BUTTON, SET_NEXT_PAYLOAD } from "../../reducer/wizardReducer";

const Content = (props) => {
  const { title, description, body: Component, showToaster, nextButtonRef, handlePermissions } = props;
  const classes                               = useStyles();
  const { t }                                 = useTranslation();
  const [pageDescription, setPageDescription] = useState(t(description))
  const { currentPage }                       = useContext(WizardContext).state;
  const personContext                         = useContext(WizardPersonContext);

  useEffect(() => {
    setPageDescription(setHighLightedString(t(description)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setHighLightedString = (tag) => {
    let newTag;
    const handleReplace = (desc, str) => {
      return desc.replace(t(str), match => `<span>${match}</span>`)
    }
    switch (currentPage) {
      case 0:
        return personContext ? handleReplace(tag, NEXT) : handleReplace(tag, SEARCH);
      case 1:
        newTag = handleReplace(tag, EXISTING_CREDENTIAL);
        return personContext ? handleReplace(newTag, NEW_CREDENTIAL) : handleReplace(tag, NEXT);
      case 2:
        newTag = handleReplace(tag, EXISTING_CREDENTIAL);
        return personContext ? handleReplace(tag, NEXT) : handleReplace(newTag, NEW_CREDENTIAL);
      case 3:
        newTag = handleReplace(tag, '*');
        return personContext ? handleReplace(newTag, CREATE) : handleReplace(tag, NEXT);
      case 4:
        newTag = handleReplace(tag, '*');
        return personContext ? tag : handleReplace(newTag, NEXT);
      case 5:
        return handleReplace(tag, SAVE);
      default:
        return tag;
    }
  }

  return (
    <>
      <Box className={classes.onBoardingHeader}>
        <Typography className={classes.onBoardingTitle} variant="h5">{t(title)}</Typography>
        <Typography className={classes.onBoardingDesc} variant="h6">
          <div dangerouslySetInnerHTML={{
            __html: pageDescription
            }}>
          </div>
        </Typography>
      </Box>
      <Box className={classes.onBoardingContent}>
        <Component showToaster={showToaster} ref={nextButtonRef} handlePermissions={handlePermissions}/>
      </Box>
    </>
  );
};

const TimeLineFiller = ({isHidden}) => {
  return (
    <TimelineItem className={clsx(!isHidden && 'hidden')}>
      <TimelineSeparator>
        <TimelineConnector />
      </TimelineSeparator>
    </TimelineItem>
  )
}

const TimeLineContent = (props) => {
  const { item, max } = props;
  const { state } = useContext(WizardContext);
  const setMax = (state.checkPage - 1) < max ? state.checkPage : max;
  const isHidden = setMax > item;

  return (
    <>
      <TimeLineFiller isHidden={(item < max) && (max === 3)} />
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector className={clsx(item !== 1 && 'hidden')}/>
          <TimelineDot color={state.checkPage > (item - 1) ? 'primary' : 'secondary'}>
            <Check fontSize="medium" className={clsx(!isHidden && 'hidden')} />
            <p className={clsx(isHidden && 'hidden')}>{item}</p>
            </TimelineDot>
          <TimelineConnector className={clsx(item === 1 && 'hidden')}/>
        </TimelineSeparator>
      </TimelineItem>
      <TimeLineFiller isHidden={item !== 1}  />
    </>
  )
}

const TimeLineComponent = ({ children, setPageNumber }) => {
  useEffect(() => {
    setPageNumber(children.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return <Timeline>{children}</Timeline>
}

const SetupWizard = (props) => {
  const classes = useStyles();
  const { handleClose, steps, contents, setContent, showToaster, handlePermissions } = props;
  const [pageNumber, setPageNumber] = useState(1);
  const nextButtonRef               = useRef();
  const { state, dispatch }         = useContext(WizardContext);

  useEffect(() => {
    if (!state.error) {
      if (state.isNextShow) {
        const payload = state.nextPage ? state.nextPage : state.currentPage + 1;
        dispatch({ type: INCREMENT, payload: payload });
        dispatch({ type: SET_NEXT_BUTTON, payload: false });
        return;
      }
      dispatch({ type: INCREMENT, payload: state.currentPage + 1 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.error, dispatch]);

  useEffect(() => {
    if (!state.isEndPage && state.isNextShow) {
      setTimeout(() => handleClose(), 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isEndPage]);

  const handleNextPage = () => {
    nextButtonRef.current.onNextPage();
  }

  const handleSaveInfo = () => {
    nextButtonRef.current.onNextPage();
  }

  const handlePreviousPage = () => {
    if (state.currentPage) {
      const payload = {
        isNextShow: false,
        isEndPage : false,
        isSetup   : false
      }
      dispatch({ type: DECREMENT });
      dispatch({ type: SET_NEXT_PAYLOAD, payload: payload });
    } else {
      handleClose();
    }
  }

  return (
    <>
      <Box className={classes.timeline}>
        <TimeLineComponent setPageNumber={setPageNumber}>
          {
            [...new Array(steps)].map((_, index) => {
              return (
                <TimeLineContent
                  key={"timeline-content-" + index}
                  item={index + 1}
                  max={pageNumber} />
                )
              }).reverse()
            }
        </TimeLineComponent>
      </Box>
      <Wizard handleSaveInfo={handleSaveInfo} handleClose={handleClose} setContent={setContent} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage}>
        {
          contents?.map((content, index) => {
            const { title, description, body} = content
            return (
              <Content
                key={"content-onboarding-" + index}
                showToaster={showToaster}
                title={title}
                description={description}
                body={body}
                nextButtonRef={nextButtonRef}
                handlePermissions={handlePermissions}
              />
            )
          })
        }
      </Wizard>
    </>
  );
};

export default SetupWizard;