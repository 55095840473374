import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

const useStyles = makeStyles(theme => ({
  paper: {
    position      : 'absolute',
    top           : '50%',
    left          : '50%',
    transform     : 'translate(-50%, -50%)',
    width         : '65%',
    '& .MuiTimeline-root': {
      alignItems  : 'center',
      height      : 'fit-content',
      margin      : theme.spacing(0),
      padding     : theme.spacing(0),
      transform   : 'rotate(90deg)',
      '& .MuiTimelineDot-root': {
        alignItems: 'center',
        display   : 'flex',
        transform : 'rotate(270deg)',
        height    : '42px',
        width     : '42px',
        '& p': {
          fontSize   : '25px',
          fontWeight : 'bold',
          margin     : theme.spacing(0),
          marginLeft : theme.spacing(1),
          marginRight: theme.spacing(1),
          padding    : theme.spacing(0)
        }
      },
      '& .MuiSvgIcon-root' : {
        fontSize     : '30px',
      },
      '& .MuiTimelineItem-missingOppositeContent:before':{
        padding: theme.spacing(0)
      }
    }
  },
  helpCenter: {
    padding      : theme.spacing(5),
    paddingBottom: theme.spacing(0)
  },
  helpCenterMenu: {
    height       : '250px',
    marginTop    :  theme.spacing(2),
    marginBottom :  theme.spacing(2),
    '& :hover': {
      backgroundColor: colors.SECONDARY_LIGHT,
    }
  },
  menu: {
    cursor        : 'pointer',
    display       : 'flex',
    borderBottom  : '2px solid' + colors.SECONDARY_LIGHT,
    justifyContent: 'space-between',
    padding       : theme.spacing(2),
    width         : '100%',
    '& :hover': {
      backgroundColor: 'transparent',
    }
  },
  menuContent: {
    textAlign     : 'start'
  },
  menuTitle: {
    fontWeight    : 'bold',
  },
  helperButton: {
    background    : colors.SECONDARY_LIGHT,
    padding       : theme.spacing(5),
    paddingTop    : theme.spacing(2),
    paddingBottom : theme.spacing(2),
    textAlign     : 'right',
  },
  timeline : {
    alignItems    : 'center',
    display       : 'flex',
    height        : '110px',
    paddingTop    : theme.spacing(1)
  },
  onBoardingHeader: {
    display       : 'flex',
    flexDirection : 'column',
    height        : '75px',
  },
  onBoardingTitle: {
    fontSize      : '30px',
    fontWeight    : 'bold',
    paddingTop    : theme.spacing(0),
    marginBottom  : theme.spacing(1),
  },
  onBoardingDesc: {
    fontSize      : theme.spacing(2),
    lineHeight    : '20px',
    '& span': {
      color       : colors.PRIMARY
    }
  },
  onBoardingContent: {
    alignItems    : 'center',
    display       : 'flex',
    justifyContent: 'center',
    height        : 'fit-content',
    marginBottom  : theme.spacing(1.5),
    marginTop     : theme.spacing(2.5),
    overflowY     : 'auto',
    padding       : theme.spacing(0),
    position      : 'relative',
  },
  wizardContent: {
    padding       : theme.spacing(5),
    paddingTop    : theme.spacing(0),
    paddingBottom : theme.spacing(0)
  },
  wizardButtons: {
    alignItems    : 'center',
    background    : colors.SECONDARY_LIGHT,
    display       : 'flex',
    justifyContent: 'space-between',
    padding       : theme.spacing(2),
    paddingRight  : theme.spacing(4),
    paddingLeft   : theme.spacing(4),
  },
  wizardButtonsRight: {
    float         : 'right',
    justifyContent: 'space-between',
    gridArea      : 'right',
  },
  nextButton:  {
    marginLeft   : theme.spacing(1)
  },
  textDecor: {
    color         : colors.PRIMARY,
    cursor        : 'pointer',
    fontSize      : theme.spacing(2),
    width         : 'fit-content',
    textDecoration: 'none',
    '& .MuiButton-root:hover': {
      backgroundColor: 'transparent'
    }
  },
}))

export default useStyles;