import React from 'react';
import useStyles from './styles';
import { Container } from '@material-ui/core'
import Title from '../../components/title';
import ComingSoon from '../../components/coming-soon';

const BackupAndRestore = () => {
  const classes = useStyles();
  
  return (
    <Container maxWidth="xl" className={classes.container}>
      <Title title={`Backup and Restore`}/>
      <ComingSoon/>
    </Container>
  );
}

export default BackupAndRestore;