import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../theme/colors';

export default makeStyles((theme) => ({
  container: {
    border          : '1px solid #727272',
    borderRadius    : '4px',
    height          : '100%',
    padding         : theme.spacing(0),
    overflowY       : 'scroll'
  },
  root: {
    width           : '100%',
  },
  paper: {
    marginTop       : theme.spacing(1),
    '& .MuiTextField-root': {
      marginTop: theme.spacing(0)
    }
  },
  header: {
    backgroundColor : colors.SECONDARY_LIGHT,
    padding         : theme.spacing(1),
    paddingLeft     : theme.spacing(2),
    paddingRight    : theme.spacing(2)
  },
  headerContent: {
    alignItems      : 'center',
    display         : 'flex',
  },
  actionTools: {
    backgroundColor  : 'transparent',
    "&:hover": {
      color          : colors.PRIMARY_TEXT
    },
    "&:active": {
      color          : colors.PRIMARY
    }
  },
  timePickerContainer: {
    borderBottom     : '1px solid #727272',
    padding          : theme.spacing(2),
    paddingTop       : theme.spacing(3),
  },
  timePicker: {
    marginLeft        : theme.spacing(2)
  },
  timePickerDuration: {
    '& .MuiTypography-root': {
      backgroundColor  : colors.SECONDARY_LIGHT,
      color            : colors.SECONDARY,
      fontSize         : '12px',
      width            : 'fit-content',
      padding          : theme.spacing(0.5)
    }
  },
  iconContainer : {
    alignItems        : 'center',
    display           : 'flex',
    padding           : theme.spacing(0)
  },
  deleteIcon: {
    "&:hover": {
      color           : colors.PRIMARY
    }
  },
  menu: {
    '& .MuiList-root' : {
      padding         : theme.spacing(0),
      paddingTop      : theme.spacing(2),
      textAlign       : 'center',
      width           : 'fit-content'
    },
  },
  option: {
    marginBottom      : theme.spacing(3),
    padding           : theme.spacing(0),
    width             : '220px',
    '& .MuiMenuItem-root': {
      padding            : theme.spacing(3),
      paddingBottom      : theme.spacing(0.5),
      paddingTop         : theme.spacing(0.5),
      "&:hover": {
        backgroundColor  : colors.PRIMARY_LIGHT
      },
      "&:focus": {
        backgroundColor  : colors.PRIMARY_LIGHT
      },
    },
    '& .MuiCheckbox-root': {
      color              : colors.SECONDARY,
      marginRight        : theme.spacing(1),
      padding            : theme.spacing(0),
    },
    '& .Mui-checked': {
      color              : colors.PRIMARY,
    },
    '& .MuiCheckbox-indeterminate': {
      color              : colors.PRIMARY
    }
  },
  hrDivider: {
    borderTop       : '2px solid #70717319',
    margin          : theme.spacing(2)
  },
  buttonContainer   : {
    padding         : theme.spacing(2),
    paddingTop      : theme.spacing(0)
  }

}));
