import DateFnsUtils from '@date-io/date-fns';
import { createTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './i18n';
import './index.css';
import reportWebVitals from './reportWebVitals';
import colors from './theme/colors';
import { PRODUCTION } from './utility/constants';

export const theme = createTheme({
  palette: {
    primary: {
      main: colors.PRIMARY,
      dark: colors.PRIMARY_DARK,
      light: colors.PRIMARY_LIGHT
    },
    secondary: {
      main: colors.SECONDARY,
      light: colors.SECONDARY_LIGHT,
    },
    error: {
      main: colors.ERROR
    },
    // warning: {
    //   main: ''
    // },
    // info: {
    //   main: ''
    // },
    success: {
      main: colors.SUCCESS,
      dark: colors.SUCCESS_DARK,
    },
    text: {
      primary : colors.PRIMARY_TEXT,
      seconday: colors.SECONDARY_TEXT,
    }
  },
  overrides: {
    MuiButton: {
      root: {
        width: 120,
      },
      label: {
        textTransform: 'none'
      }
    },
    MuiPickersToolbarButton: {
      toolbarBtn: {
        width : 'fit-content',
      }
    },
    MuiTextField: {
      root: {
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            borderColor: colors.PRIMARY_TEXT,
            borderWidth: 1,
          },
        },
      }
    },
    MuiMenu: {
      list: {
        '& .MuiListItem-root.Mui-selected': {
          backgroundColor: colors.PRIMARY_LIGHT
        },
        '& .MuiListItem-root.Mui-selected:hover': {
          backgroundColor: colors.PRIMARY_LIGHT
        },
        '& .MuiListItem-button:hover': {
          backgroundColor: colors.PRIMARY_LIGHT
        }
      }
    },
  },
  props: {
    MuiButton: {
      size : 'medium',
    },
    MuiDialog: {
      disableBackdropClick : true,
      disableEscapeKeyDown : true,
    },
    MuiTextField: {
      margin  : 'normal',
      size    : 'medium',
      variant : 'outlined',
    }
  }
});

if (window.ENVIRONMENT === PRODUCTION) {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <App/>
        </MuiPickersUtilsProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
