import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  container: {
    marginLeft: '0px !important',
    paddingBottom: theme.spacing(2),
    paddingTop   : theme.spacing(2),
  },
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
  buttonStyle: {
    whiteSpace: 'nowrap',
    marginRight: theme.spacing(2),
    width: '165px',
    height: '40px',
    borderRadius: '8px'
  },
  buttonBorderStyle: {
    borderColor: 'black'
  },
  uploadButton: {
    width: '165px',
    height: '40px',
    borderRadius: '8px',
    backgroundColor: '#99D6FF',
    color: '#000',

    '&:hover': {
      backgroundColor: '#8DC8F0',
    },
  },
  noteStyle: {
    marginTop: theme.spacing(2)
  },
  reminderStyle: {
    marginBottom: theme.spacing(2),
  },
  textFieldStyle: {
    width: '75%',
  },
  textFieldSuccessStyle: {
    width: '75%',
    '& #connectionURL-helper-text': {
      color: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'green',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'darkgreen',
      }
    },
    '& .MuiInputLabel-root': {
      color: 'green',
    },
  },
  alertError: {
    borderLeft: '5px solid #972B21',
    backgroundColor: '#f8f8f8',
    borderRadius: '4px',
    color: '#333',
    marginTop: theme.spacing(4),
    margin: '10px 0',
    padding: theme.spacing(2),
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    width: '75%'
  },
  alertSuccess: {
    borderLeft: '5px solid #269255',
    backgroundColor: '#f8f8f8',
    borderRadius: '4px',
    color: '#333',
    marginTop: theme.spacing(4),
    margin: '10px 0',
    padding: theme.spacing(2),
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    width: '75%'
  },
  guideButton: {
    color  : colors.PRIMARY,
    cursor : 'pointer'
  }
}));