import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  container: {
    paddingTop   : theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  mainPaper: {
    marginTop: theme.spacing(3),

    '& .MuiTextField-root': {
      marginTop: theme.spacing(0)
    }
  },
  detailsPaper: {
    marginTop : theme.spacing(1),
    width     : '100%',
    
    '& .MuiTextField-root': {
      marginTop : theme.spacing(0)
    }
  },
  options:{
    alignSelf: 'flex-start',
    direction: 'rtl'
  },
  form: {
    padding: theme.spacing(3),
    '& .MuiInputBase-input.Mui-disabled':{
      color: colors.BLACK
    }
  },
  detailsForm: {
    display : 'flex',
    flex    : '0 50%',
    flexFlow: 'column',
    fontSize: '16px',
    padding : theme.spacing(1)
  },
  createForm: {
    padding       : theme.spacing(3),
    paddingBottom : theme.spacing(8),
    '& .MuiInputBase-input.Mui-disabled':{
      color: colors.BLACK
    }
  },
  squareImage: {
    height    : theme.spacing(27.5),
    marginLeft: theme.spacing(1.8),
    marginTop : theme.spacing(0.5),
    width     : theme.spacing(30)
  },
  rectangularImage: {
    height    : theme.spacing(20),
    marginLeft: theme.spacing(3),
    marginTop : theme.spacing(1.5),
    width     : theme.spacing(32)
  },
  flexForm: {
    display   : 'flex',
    wordBreak : 'break-word'
  },
  space: {
    paddingTop : theme.spacing(1)
  },
  action: {
    backgroundColor : colors.SECONDARY_LIGHT,
    padding         : theme.spacing(2),
    textAlign       : 'right',

    '& .MuiButton-root:first-of-type': {
      marginRight: theme.spacing(2)
    }
  },
  chipStatus: {
    marginTop     : theme.spacing(-.5),
    marginLeft    : theme.spacing(1),
  },
  containerDetails: {
    paddingBottom : theme.spacing(1),
    paddingTop    : theme.spacing(4)
  },
  editIcon: {
    color    : theme.palette.common.grey,
    fontSize : '1.4rem'
  },
  containerButton:{
    height        : '100%',
    paddingBottom : theme.spacing(1),
    paddingTop    : theme.spacing(4)
  },
  editButton: {
    padding: theme.spacing(0),
  },
  header: {
    color     : colors.SECONDARY,
    fontSize  : '20px',
    fontWeight: 'bold'
  },
  accessPointList: {
    marginTop: theme.spacing(1)
  },
  text: {
    color     : colors.PRIMARY,
    flexShrink: 0
  },
  textDisabled: {
    color: colors.SECONDARY_TEXT
  },
  status: {
    alignItems: 'baseline',
    display   : 'flex'
  }
}));