import { Box, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from "./styles";
import { Trans } from 'react-i18next';

const AuditLogItem = (props) => {
  const classes = useStyles();
  const { timestamp, translationKey, value } = props;

  return (
    <Box className={classes.container}>
      <Typography component={'span'} className={classes.date}>
        <Trans i18nKey={'audit-log.timestamp'} values={timestamp}/>
      </Typography>
      <Typography component={'span'} className={classes.logText}>
        <Trans i18nKey={translationKey} values={value} />
      </Typography>
    </Box>
  )
}

export default AuditLogItem