import { getLocalStorageItem } from '../utility/helper';

export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const UPDATE_DEFAULT_VALIDITY = 'UPDATE_DEFAULT_VALIDITY';
export const LOGOUT                  = 'LOGOUT';

export const initialState = {
  administrator : {
    administratorId     : '',
    email               : '',
    firstName           : '',
    lastName            : '',
    roles               : [],
    locations           : [],
    permissions         : [],
    keycloakAttributes  : [],
  },
  defaultValidUntil : undefined,
  defaultValidFrom  : undefined,
  token             : getLocalStorageItem('token'),
};

const AuthReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_ACCOUNT:
      return {
        ...state,
        administrator  : action.payload.administrator
      };

      case UPDATE_DEFAULT_VALIDITY:
        return {
          ...state,
          defaultValidFrom  : action.payload.defaultValidFrom,
          defaultValidUntil : action.payload.defaultValidUntil
      };

      case LOGOUT:
        return {
          ...state,
          defaultValidFrom  : undefined,
          defaultValidUntil : undefined,
      }

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export default AuthReducer;