import { Box, Grid, Paper } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import { Form } from 'formik';
import React from 'react';
import { CREDENTIALS_MODULE, DESCRIPTION_SKELETON, GET, LOCATIONS_MODULE, PUT, ROLES_MODULE, TEXT_FIELD_SKELETON } from '../../../utility/constants';
import useStyles from './styles';

const UserSkeleton = (props) => {
  const { disabled, path, handlePermissions } = props;
  const classes = useStyles();

  return (
    <Box>
      <Paper className={classes.paper} elevation={3}>
        <Grid container spacing={2} className={classes.form}>
          <Grid item xs={12} md={12} lg={2}>
            <Skeleton height={TEXT_FIELD_SKELETON}/>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Skeleton height={TEXT_FIELD_SKELETON}/>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Skeleton height={TEXT_FIELD_SKELETON}/>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2}>
            <Skeleton height={TEXT_FIELD_SKELETON}/>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2}>
            <Skeleton height={TEXT_FIELD_SKELETON}/>
          </Grid>
          <Grid container spacing={2} className={clsx(path.includes('update') || path.includes('create') ? 'hidden' : classes.location)}>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Skeleton height={TEXT_FIELD_SKELETON}/>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Skeleton height={TEXT_FIELD_SKELETON}/>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} className={clsx(disabled && 'hidden')}>
            <Skeleton height={308}/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} className={clsx((!handlePermissions(CREDENTIALS_MODULE, PUT) || disabled) && 'hidden')}>
            <Skeleton height={TEXT_FIELD_SKELETON} className={clsx(!handlePermissions(ROLES_MODULE, PUT) && 'hidden')}/>
            <Skeleton height={TEXT_FIELD_SKELETON} className={clsx(!handlePermissions(LOCATIONS_MODULE, GET) && 'hidden')}/>
            <Skeleton height={TEXT_FIELD_SKELETON} />
          </Grid>
        </Grid>
        <Grid container className={clsx(disabled ? 'hidden' : classes.action)}>
          <Grid item xs={12} className={`${classes.buttonContainer}`}>
            <Skeleton variant="rect" className={classes.button} />
            <Skeleton variant="rect" className={classes.button} />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

const CreateCredentialsLoadingState = () => {
  const classes  = useStyles();
  return(
    <Form>
      <Grid container spacing={2} className={classes.form}>
        <Grid item xs={12} sm={5} md={4} lg={3}> 
          <Skeleton height={TEXT_FIELD_SKELETON}/>
        </Grid>
        <Box component={Grid} item lg display={{ xs: 'none', sm: 'none', md: 'none' }}></Box>
        <Grid item xs={12}>
          <Skeleton height={DESCRIPTION_SKELETON}/>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Skeleton height={TEXT_FIELD_SKELETON}/>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Skeleton height={TEXT_FIELD_SKELETON}/>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Skeleton height={TEXT_FIELD_SKELETON}/>
        </Grid>
      </Grid>
      <Grid container className={classes.action}>
        <Grid item xs={12} className={`${classes.buttonContainer}`}>
          <Skeleton variant="rect" className={classes.button} />
          <Skeleton variant="rect" className={classes.button} />
        </Grid>
      </Grid>
    </Form>
  );
}

export {
  CreateCredentialsLoadingState, UserSkeleton
};

