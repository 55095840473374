import { makeStyles } from '@material-ui/core/styles';


export default makeStyles((theme) => ({
  paper: {
    marginTop   : theme.spacing(0),
  },
  form: {
    '& .MuiSkeleton-root': {
      transform     : 'none',
      marginBottom  : theme.spacing(1)
    },
  },
  attributePaper: {
    marginTop   : theme.spacing(0),
    width       : '100%'
  },
  modalPaper: {
    marginTop   : theme.spacing(1),
    padding     : theme.spacing(1),
    paddingLeft : theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  action: {
    backgroundColor: theme.palette.grey[100],
    padding        : theme.spacing(2),
    textAlign      : 'right',
    '& .MuiButton-root:first-of-type': {
      marginRight: theme.spacing(2)
    }
  },
  actionBox: {
    display       : 'flex',
    justifyContent: 'flex-end',
  }, 
  button:{
    height      : theme.spacing(4.5625),
    marginLeft  : theme.spacing(1),
    width       : theme.spacing(15),
  },
}));
