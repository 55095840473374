import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    paddingTop    : theme.spacing(2),
    paddingBottom : theme.spacing(2),
  },
  paper: {
    borderTopLeftRadius : theme.spacing(0),
    borderTopRightRadius: theme.spacing(0),
    borderTopColor      : theme.palette.primary.main,
    borderTopWidth      : '1px',
    borderTopStyle      : 'solid',
    '& .MuiTextField-root': {
      marginTop: theme.spacing(0)
    },
    '& input#myAccountFirstName.Mui-disabled':{
      color: "black"
    },
    '& input#myAccountLastName.Mui-disabled':{
      color: "black"
    },
    '& input#myAccountEmail.Mui-disabled':{
      color: "black"
    },
  },
  editIcon: {
    direction : 'rtl'
  },
  form: {
    padding       : theme.spacing(1),
    paddingLeft   : theme.spacing(2),
    paddingRight  : theme.spacing(2),
    textAlign     : 'right'
  },
  switchContainer: {
    alignSelf       : 'center',
    display         : 'flex',
    height          : '100%',
    justifyContent  : 'flex-end',
  },
  action: {
    backgroundColor : theme.palette.grey[100],
    padding: theme.spacing(2),
    textAlign: 'right',

    '& .MuiButton-root:first-of-type': {
      marginRight: theme.spacing(2)
    }
  },
  avatar: {
    height    : '100px',
    width     : '100px',
    fontSize  : '50px',
    marginTop : theme.spacing(5)
  },
  formTitle: {
    marginTop       : theme.spacing(3),
    marginBottom    : theme.spacing(3),
    backgroundColor : theme.palette.grey[100],
    width           : '100%',
    paddingLeft     : theme.spacing(2),
    paddingTop      : theme.spacing(1),
    paddingBottom   : theme.spacing(1),
  },
  formTitlePassword: {
    marginTop       : theme.spacing(1),
    marginBottom    : theme.spacing(1),
    width           : '100%',
    paddingLeft     : theme.spacing(2),
    paddingTop      : theme.spacing(1),
    paddingBottom   : theme.spacing(1),
    cursor: 'default',
    '&:hover': {
      cursor: 'default'
    }
  },
  formTitlePasswordHighlight: {
    marginTop       : theme.spacing(1),
    marginBottom    : theme.spacing(1),
    width           : '100%',
    paddingLeft     : theme.spacing(2),
    paddingTop      : theme.spacing(1),
    paddingBottom   : theme.spacing(1),
    fontSize        : '15px',
    cursor: 'default',
    '&:hover': {
      cursor: 'default'
    }
  },
  // forgotPassword: {
  //   marginTop       : theme.spacing(1),
  //   marginBottom    : theme.spacing(1),
  //   width           : '100%',
  //   paddingLeft     : theme.spacing(2),
  //   paddingTop      : theme.spacing(1),
  //   paddingBottom   : theme.spacing(1),
  //   justify         : 'flex-end',
  //   fontSize        : '15px',
  //   cursor: 'default',
  //   '&:hover': {
  //     cursor: 'default'
  //   }
  // },
  // confirmPassword: {
  //   display: 'inline-block'
  // },
  forgotPassword: {
    width           : '100%',
    justify         : 'flex-end',
    marginTop       : theme.spacing(-2),
    cursor: 'default',
    '&:hover': {
      cursor: 'default'
    }
  },
  activeStatus: {
    marginTop : theme.spacing(2),
    display   : 'inline-grid'
  },
  gridInline: {
    marginleft : theme.spacing(2),
    display   : 'inline-grid',
    gridColumnGap : '50px'
  },
  updateCancelButtonContainer: {
    paddingRight    : theme.spacing(2),
    paddingTop      : theme.spacing(2),
    paddingBottom   : theme.spacing(2),
    backgroundColor : theme.palette.grey[100],
    justifyContent  : 'flex-end',
    color           : 'error',
    justifyItems    : 'flex-end',
    textAlign       : 'right'

  },
  updateCancelButton: {
    paddingRight    : theme.spacing(1),
    display         : 'inline'
  },
  tab: {
    '& .MuiTab-root'  : {
      backgroundColor : theme.palette.grey[100],
      color           : theme.palette.common.black,
      borderTopLeftRadius : theme.spacing(0.5),
      borderTopRightRadius: theme.spacing(0.5),
      marginRight         : theme.spacing(0.5),
    },
    '& .Mui-selected' : {
      backgroundColor : theme.palette.primary.main,
      color           : theme.palette.common.white,
      cursor          : 'default',
    },
  },
  tabLabel: {
    fontSize      : '16px',
    textTransform : 'capitalize',
  }
}));