import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../../theme/colors';

export default makeStyles((theme) => ({
  formContent: {
    alignItems    : 'center',
    display       : 'flex',
    height        : '350px',
    justifyContent: 'center',
    paddingTop    : theme.spacing(13),
    width         : '65%',
  },
  form: {
    '& .MuiFormControl-root': {
      '& .MuiBox-root': {
        paddingTop    : 0,
        paddingBottom : 0
      },
    },
    '& .MuiFormControl-marginNormal': {
      marginTop       : theme.spacing(0)
    },
    '& .MuiGrid-spacing': {
      margin          : '-8px',
    },
  },
  nameTextField : {
    pointerEvents     : 'none',
  },
  description: {
    alignItems        : 'flex-start',
    display           : 'flex',
    width             : '100%'
  },
  icon: {
    color             : colors.PRIMARY,
    marginTop         : theme.spacing(0.5)
  },
  text: {
    fontSize          : theme.spacing(1.75),
    marginLeft        : theme.spacing(1),
    '& span': {
      fontWeight      : 'bold',
      marginLeft      : theme.spacing(1),
    }
  },
  characterLimit: {
    color   : colors.SECONDARY,
    float   : 'right',
    fontSize: '12px',
    position: 'relative',
    top     : '-0.3em'
  },
  characterLimitError: {
    color   : colors.SECONDARY,
    float   : 'right',
    fontSize: '12px',
    position: 'relative',
    top     : '-2.205em'
  },
}));