import React from 'react';
import { Box, Container, Typography} from '@material-ui/core';
import useStyles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import colors from '../../../theme/colors';
import { useTranslation } from 'react-i18next';

const ChipContainer = (props) => {
  const { t }   = useTranslation();
  const classes = useStyles();
  const { icon, name, description, count, selected } = props;

  let dashboardChipId = name.charAt(0).toUpperCase()+name.slice(1);

  return (
    <Container id={`dashboardChip${dashboardChipId}Container`} className={`${clsx(count > 0 ? classes.chipContainer:classes.chipContainerGray)} ${name === selected ? classes.chipsClicked : ''}`}>
      <Box className={classes.module}>
        <Box className={classes.icon}>
          <FontAwesomeIcon icon={icon} size="lg" color={count > 0 ? "primary": colors.GRAY}/>
        </Box>
        <Box className={classes.text}>
          <Typography className={classes.name}><span id={`dashboardChip${dashboardChipId}Name`}>{t(name)}</span></Typography>
          <Typography className={classes.description}><span id={`dashboardChip${dashboardChipId}Description`}>{description}</span></Typography>
        </Box>
      </Box>
      <Typography className={classes.count}><span id={`dashboardChip${dashboardChipId}Count`}>{count}</span></Typography>
    </Container>
  )
}

export default ChipContainer;