import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  chipsContainer: {
    height          : '98px',
    maxWidth        : '100%',
    padding         : theme.spacing(0),
    '& .MuiSkeleton-root': {
      transform     : 'none',
      marginBottom  : theme.spacing(0)
    },
  },
  chartContainer: {
    maxWidth        : '100%',
    padding         : theme.spacing(0),
    '& .MuiSkeleton-root': {
      transform     : 'none',
      marginBottom  : theme.spacing(0)
    },
  },
  eventsContainer: {
    '& .MuiTableRow-root': {
      display       : 'flex',
      justifyContent: 'space-between',
    },
  },
  contents: {
    '& .MuiTableCell-root': {
      borderBottom    : 'none',
      paddingTop      : theme.spacing(2),
    },
  },
  chipSkeleton: {
    height: '100%'
  },
  chipDetailSkeleton: {
    height   : '100%',
    minWidth : '33%'
  },
  eventSkeleton: {
    minWidth: '50%'
  },
  chipDetailsContainer: {
    height          : '100%',
    padding         : theme.spacing(0),
    '& .MuiSkeleton-root': {
      transform     : 'none',
      marginBottom  : theme.spacing(0)
    },
  },
  chipListContainer: {
    height          : '100%',
    paddingBottom   : theme.spacing(0),
    paddingLeft     : theme.spacing(0),
    paddingRight    : theme.spacing(0),
    paddingTop      : theme.spacing(0.5),
    '& .MuiSkeleton-root': {
      borderRadius  : 0,
      transform     : 'none',
      marginBottom  : theme.spacing(0)
    },
  },
}));