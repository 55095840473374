import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  paper: {
    marginTop : theme.spacing(1),
  },
  form: {
    padding       : theme.spacing(1),
    paddingLeft   : theme.spacing(2),
    paddingRight  : theme.spacing(2),
  },
  formSkel: {
    '& .MuiSkeleton-root': {
      transform: 'none',
      marginBottom: theme.spacing(.3)
    },
  },
  space:{
    content : ''
  },
}));