import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../theme/colors';

export default makeStyles((theme) => ({
  paper: {
    marginTop : theme.spacing(1),

    '& .MuiTextField-root': {
      marginTop : theme.spacing(0)
    }
  },
  form: {
    padding       : theme.spacing(1),
    paddingLeft   : theme.spacing(2),
    paddingRight  : theme.spacing(2),
    '& .MuiFormControl-marginNormal': {
      marginTop : theme.spacing(0)
    },
   '& .MuiGrid-spacing': {
      margin: '-8px',
    }
  },  
  fullWidth:{
    width           : '100%'
  },
  action: {
    backgroundColor : theme.palette.grey[100],
    padding         : theme.spacing(2),
    textAlign       : 'right',

    '& .MuiButton-root:first-of-type': {
      marginRight : theme.spacing(2)
    }
  },
  switchContainer: {
    alignSelf       : 'center',
    display         : 'flex',
    justifyContent  : 'flex-end',
  },
  iconButton:{
    float           : 'right',
    marginTop       : '-10px',
    margin          : '-6px',
  },
  addIconButton:{
    backgroundColor : theme.palette.primary.main,
    borderRadius    : theme.spacing(0.5),
    color           : theme.palette.common.white,
  },
  createProfileModal: {
    alignItems      : 'center',
    display         : 'flex',
    justifyContent  : 'center'
  },
  modalContent: {
    backgroundColor : theme.palette.background.paper,
    boxShadow       : theme.shadows[5],
    padding         : theme.spacing(0),
    width           : '50%'
  },
  titleModalCreateProfile:{
    alignItems      : 'center',
    display         : 'flex',
    fontSize        : '20px',
    paddingTop      : theme.spacing(2)
  },
  hrDividerProfile:{
    border          : 0,
    borderBottom    : `1px solid ${theme.palette.primary.main}`,
    height          : theme.spacing(-1)
  },
  characterLimit: {
    color   : colors.SECONDARY,
    float   : 'right',
    fontSize: '12px',
    position: 'relative',
    top     : '-0.3em'
  },
  characterLimitError: {
    color   : colors.SECONDARY,
    float   : 'right',
    fontSize: '12px',
    position: 'relative',
    top     : '-2.205em'
  },
  grid: {
    paddingBottom: '0 !important'
  }
}));