import { useContext } from 'react';
import { AuthContext } from '../context/authContext';

const usePermissions = (props) => {
  const { name, scope } = props;

  const { state }         = useContext(AuthContext);
  const { administrator } = state;
  const { permissions }   = administrator;

  if (!name) {
    return true;
  }
  
  const names = permissions.map(permission => permission.name);
  const hasPermission = names.includes(name);
  
  const handleActionPermission = (name, scope) => {
    const findNameScopes = permissions.find(permission => permission.name === name);

    return findNameScopes.scopes.includes(scope);
  }

  if (hasPermission) {
    const hasActionPermission = handleActionPermission(name, scope);
    return hasActionPermission;
  }

  return hasPermission;
}

export default usePermissions