import { Paper, Box, Button, Grid, Typography, TextField, Modal, Fade, Container, Backdrop, MenuItem } from '@material-ui/core';
import images from "../../../utility/images";
import React, { useState, useEffect } from 'react';
import api from '../../../service/api';
import { request } from '../../../service/requests';
import { POST, STATE_VALUES, API_REQUEST_ERROR_MESSAGE, DATE_FORMAT, EVENT_FILTER_FORMAT2, GET, LANGUAGE_EN, LANGUAGE_DE } from '../../../utility/constants';
import useStyles from './styles';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ImportResultSkeleton, ImportActionSkeleton } from '../holiday-import-skeleton';
import cookies from 'js-cookie';

const Content = (props) => {
  const classes             = useStyles();
  const { t }               = useTranslation();  
  const [states, setStates] = useState([]);
  const { initialValues, handleSubmit, closeModal, handleChange, importHoliday, duplicateHoliday, nonDuplicateHoliday, isValidating, numberOfDuplicate, showImportDetails } = props;


  useEffect(() => {
    setStates(STATE_VALUES.map(type => {
      const{ name, state } = type;
      return(<MenuItem value={state}> {name}</MenuItem>);
    }));
  }, []);

  const setPluralHoliday = (count) => {
    return count > 1 ? t('holidayItems') : t('holidayItem');
  }

  return(
  <Formik
      enableReinitialize
      initialValues={initialValues}
      handleChange={handleChange}
      onSubmit={handleSubmit}
      resetForm
  >
      {
        formik => {
          return(
            <Form className={classes.holidayImportForm}>
              <Paper className={classes.holidayImportModal} sm={8}>
                <Grid container spacing={12}>
                  <Box className={classes.dialogImage}>
                    <img src={images.IMPORT_CSV_SVG} alt='import-dialog-img' />
                    <Typography className={classes.dialogTitle}>{t('chooseStateHolidayImport')}</Typography>
                  </Box>
                </Grid>
                <Grid container className={classes.dialogContents} spacing={12}>
                  <Grid item xs={12}>
                    <Box className={classes.holidayImportActions}>
                      <TextField
                        className={classes.importField}
                        id="holidayImportModalState"
                        label={t('state')+'*'}
                        name="state"
                        variant="outlined"
                        onChange={(e) => {
                          handleChange(e);
                          formik.handleChange(e);
                        }}
                        placeholder={t(`state`)}
                        value={formik.values.type}
                        error={formik.touched.type && Boolean(formik.errors.type)}
                        helperText={t(formik.touched.type) && t(formik.errors.type)}
                        select >
                        {states}
                      </TextField>
                      <Button id="holidayImportModalImportButton" color="secondary" variant="contained" className={classes.importButton} type="submit" >
                      {t('import')}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container>
                  {
                    isValidating ?
                      <ImportResultSkeleton />
                    :
                      <Grid item md={12} sm={12}>
                        <Box className={classes.importItems}>
                          <Typography id="holidayImportModalAddedHolidays" className={clsx(showImportDetails ? classes.importText : 'hidden')}>
                            <FontAwesomeIcon icon={faExclamationCircle} className={classes.skipIcon}/>
                              {` ${nonDuplicateHoliday.length} ${setPluralHoliday(nonDuplicateHoliday.length)}`} {t('added')}
                          </Typography>
                          <Typography id="holidayImportModalDuplicateHolidaySkip" className={clsx(showImportDetails ? classes.importText : 'hidden')}>
                            <FontAwesomeIcon icon={faExclamationCircle} className={classes.skipIcon} />
                              {` ${numberOfDuplicate} ${setPluralHoliday(duplicateHoliday.length)}`} {t('alreadyExistAndSkip')}
                          </Typography>
                        </Box>
                      </Grid>
                  }
                </Grid>
                {
                  isValidating ?
                    <ImportActionSkeleton />
                  :
                    <Grid container>
                      <Grid item xs={12}>
                        <Box className={classes.dialogActions}>
                          <Button id="holidayImportModalSubmitButton" color="primary" variant="contained" className={classes.dialogSubmitButton} onClick={importHoliday} disabled={Boolean(!nonDuplicateHoliday.length)}>
                            {t('submitImportHoliday')}
                          </Button>
                          <Button id="holidayImportModalCancelButton" color="primary" variant="outlined" className={classes.dialogSubmitButton} onClick={closeModal} >
                            {t('cancel')}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                }
              </Paper>
            </Form>
          )
        }
      }
    </Formik>
  );
}

const ImportModal = (props) => {
  const classes   = useStyles();
  const { t }     = useTranslation();
  const { showModal, showLoading, setShowModal, showToaster, setHolidays, createdHolidays, holidayId, holidayItemIdParam, changesHolidayItem } = props;
  const [duplicateHoliday, setDuplicateHoliday]         = useState([]);
  const [nonDuplicateHoliday, setNonDuplicateHoliday]   = useState([]);
  const [numberOfDuplicate, setNumberOfDuplicate]       = useState([]);
  const [isValidating, setIsValidating]                 = useState(false);
  const [showImportDetails, setShowImportDetails]       = useState(false);
  const language = cookies.get('i18next') || LANGUAGE_EN;
  

  const initialValues = {
    year : new Date().getFullYear()
  }

  const closeModal = () => {
    setShowModal(false);
    setShowImportDetails(false);
    setNonDuplicateHoliday([]);
  }

  const handleChange = (setter, formik) => {
    return(setter, formik);
  }

  const importHoliday = () => {
    setHolidays(item => item.concat(nonDuplicateHoliday));
    setNonDuplicateHoliday([]);
    setShowModal(false);
    setShowImportDetails(false);
  }

  const handleSubmit = async (values, formik) => {
    const { setErrors, setSubmitting } = formik;
    const { year, state } = values;
    if (typeof state !== 'undefined'){
      setIsValidating(true);
      
      try {
        const response = await request({
          url     : `${api.HOLIDAY_ITEMS}/import?year=${year}&state=${state}`,
          method  : POST,
        });

        showToaster(t(`success`), language === LANGUAGE_DE ? `${t('stateHolidays')} ${values.state} ${t('hasBeen')} ${t('imported')}` : `${values.state} ${t('stateHolidays')} ${t('hasBeen')} ${t('imported')}`, 'success');

        const { data } = response;
        let namesParam = [];
        let datesParam = [];
        const newHoliday = data.map(item => {
          namesParam = namesParam.concat(item.name);
          datesParam = datesParam.concat( moment(item.date).format(EVENT_FILTER_FORMAT2));
          return {
            name              : item.name,
            date              : moment(item.date).format(DATE_FORMAT),
            holidayItemId     : Math.random(),
            isChanged         : true,
          }
        });

        try {
          const itemsResponse = await request({
            url     : api.HOLIDAY_ITEMS_IMPORT_UPDATE_CHECK,
            method  : GET,
            params  : {
              holidayId       : holidayId,
              dates           : datesParam.join(),
              names           : namesParam.join(),
              holidayItemIds  : holidayItemIdParam.join()
            }
          });
          //Duplicates from API
          const duplicatedHoliday = itemsResponse.data._embedded.holidayItems.map(item => {
            return {
              name: item.name,
              date: moment(item.date).format(DATE_FORMAT)
            }
          });
          //Separating non Duplicate
          const nonduplicatedHoliday = newHoliday.filter(item => !duplicatedHoliday.find(findItem => (findItem.name === item.name && findItem.date === item.date)));
          const createdAndChangesHoliday = createdHolidays.concat(changesHolidayItem);
          //Checking duplicates from created holiday items using non duplicate result
          const duplicatedCreatedHoliday = nonduplicatedHoliday.filter(item => createdAndChangesHoliday.find(findItem => (findItem.name === item.name && findItem.date === item.date)));
          //Non Duplicate from API and created holiday
          const finalNonduplicatedHoliday = nonduplicatedHoliday.filter(item => !duplicatedCreatedHoliday.find(findItem => (findItem.name === item.name && findItem.date === item.date)));
          setShowImportDetails(true);
          setDuplicateHoliday(duplicatedHoliday);
          setNumberOfDuplicate(duplicatedHoliday.length + duplicatedCreatedHoliday.length);
          setNonDuplicateHoliday(finalNonduplicatedHoliday);
          setIsValidating(false);
        } catch {
          showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
        }
      } catch (error) {
        if (error?.response?.status === 409) {
          setErrors({
            name: t(`nameAlreadyExists`)
          });
        } else {
          showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
        }
      } finally {
        setSubmitting(false);
      }
    }
  }

  return (
    <Box>
      <Modal
        disableEnforceFocus
        open={showModal}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className={classes.filterModal}
        autoFocus={false}
      >
        <Fade in={showModal}>
          <Container id="holidayImportModalContainer" className={`${classes.modalContent}`}>
            <Box className={`${classes.titleFilterModal} ${'bold'} ${classes.form}`}> {t('importHoliday')} </Box>
            <hr className={classes.hrDividerProfile} />
              <Content
                initialValues={initialValues}
                handleSubmit={handleSubmit}
                closeModal={closeModal}
                handleChange={handleChange}
                showLoading={showLoading}
                duplicateHoliday={duplicateHoliday}
                nonDuplicateHoliday={nonDuplicateHoliday}
                numberOfDuplicate={numberOfDuplicate}
                isValidating={isValidating}
                importHoliday={importHoliday}
                showImportDetails={showImportDetails}
              />
          </Container>
        </Fade>
      </Modal>
    </Box>
  );

}

export default ImportModal;