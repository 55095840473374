import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  successModal: {
    display   : 'block',
    padding   : theme.spacing(2),
    paddingTop: theme.spacing(5),
  },
  dialogImage: {
    alignItems: 'center',
    margin    : '0 auto',
    textAlign : 'center',
  },
  dialogTitle: {
    fontWeight: 'bold',
    textAlign : 'center',
  },
  dialogContent: {
    padding  : theme.spacing(0.5),
    textAlign: 'center',
    '& span': {
      color       : colors.PRIMARY
    }
  },
  dialogEmphasis: {
    fontStyle: 'italic'
  },
  dialogActions: {
    alignItems     : 'center',
    backgroundColor: colors.SECONDARY_LIGHT,
    direction      : 'row',
    display        : 'flex',
    height         : theme.spacing(8),
    justifyContent : 'space-around',
    padding        : theme.spacing(2),
  },
  dialogSubmitButton: {
    display : 'flex',
    float   : 'left',
    margin  : theme.spacing(0.5),
    padding : theme.spacing(0.5),
    width   : "300px",
  }
}));