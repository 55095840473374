
import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

const useStyles = makeStyles(theme => ({
  alert: {
    maxWidth: 350,
    minWidth: 250,
    '& .MuiAlert-icon': {
      alignItems: 'center'
    }
  },
  button: {
    display         : 'flex',
    backgroundColor : colors.ERROR,
    borderRadius    : '0 0 4px 4px',
    padding         : theme.spacing(1.5),
    paddingLeft     : theme.spacing(2.75),
    marginTop       : theme.spacing(-1.5)
  },
  buttonExpanded: {
    backgroundColor : colors.ERROR,
    borderRadius    : 0,
    display         : 'flex',
    maxWidth        : 350,
    padding         : theme.spacing(1.5),
    paddingLeft     : theme.spacing(2.75),
    marginTop       : theme.spacing(-1.5)
  },
  icon: {
    color       : 'white',
    fontSize    : theme.spacing(1.75),
    padding     : 0,
    marginRight : theme.spacing(1),
    width       : theme.spacing(1)
  },
  detailsContainer: {
    backgroundColor : theme.palette.error.dark,
    borderRadius    : '0 0 4px 4px',
    maxWidth        : 350,
    overflowWrap    : 'break-word',
    padding         : theme.spacing(1.5),
    paddingLeft     : theme.spacing(3)
  },
  details: {
    color     : 'white',
    fontSize  : '12px',
    lineHeight: 1.75,
    whiteSpace: 'pre-line'
  },
  closeButton: {
    position    : 'absolute',
    right       : '-5px',
    stroke      : 'white',
    strokeWidth : 2,
    top         : '-6px',
    '&:hover': {
      background: 'transparent',
    },
    '& .MuiSvgIcon-root': {
      color   : colors.WHITE,
      fontSize: theme.spacing(2.25),
    }
  },
  description: {
    lineHeight   : 0,
    wordBreak    : 'break-word'
  },
  buttonLabel: {
    color    : 'white',
    fontSize : '12px'
  }
}))

export default useStyles;