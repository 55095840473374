import DateFnsUtils from '@date-io/date-fns';
import { Box, Button, Container, Grid, IconButton, Paper, TextField, Tooltip, Typography } from '@material-ui/core';
import { Edit, Person as PersonIcon, RecentActors as RecentActorsIcon } from '@material-ui/icons/';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import clsx from 'clsx';
import deLocale from "date-fns/locale/de";
import enLocale from 'date-fns/locale/en-US';
import { Form, Formik } from 'formik';
import cookies from 'js-cookie';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory } from 'react-router';
import Chips from '../../components/chips';
import SelectItems from '../../components/select-items';
import Title from '../../components/title';
import UnsavedModal from '../../components/unsaved-modal';
import { AuthContext } from '../../context/authContext';
import { LocationContext } from '../../context/locationContext';
import api from '../../service/api';
import { request } from '../../service/requests';
import { getUsersByRoleId } from '../../service/rolesApi';
import { ACTION_VIEW, API_REQUEST_ERROR_MESSAGE, CHIP_COLOR, DATE_FORMAT, GET, LANGUAGE_DE, LANGUAGE_EN, LOCATIONS_MODULE, MAX_CHARACTER_LIMIT, PATCH, POST, PROFILES_MODULE, PROJECTION, PUT, ROLES_MODULE, USERS_MODULE } from '../../utility/constants';
import { getUserByUserRole, isArrayEqual } from '../../utility/helper';
import { GetInitialLocationId, GetInitialLocationObject, assignLocation, getLocation, hasLocationChange, unassignLocation } from '../../utility/location';
import { roleSchema } from '../../validation/schema';
import ModalCreateProfile from './role-create-profile-modal';
import { RoleSkeleton } from './role-skeleton';
import useStyles from './styles';

const Content = (props) => {
  const classes = useStyles();
  const { t }   = useTranslation();
  const { path, initialValues, handleSubmit, id, showToaster, disabled, handleSelectedChange, selectedProfiles, setSelectedProfiles, showLoading, handleCancel, setRoleName, setRoleDescription, hasSelected, setHasSelected, handleSelectedLocation, selectedLocation, handlePermissions } = props;

  const isViewPage = path.includes(ACTION_VIEW);

  const getButtonLabel = () => {
    return id ? `${t('update')}` : `${t('create')}`;
  }

  const handleChange = (setter, formik) => {
    return(setter, formik);
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={roleSchema}
      onSubmit={handleSubmit}
    >
      {
        formik => (
          <Form>
            {showLoading(formik.isSubmitting)}
            <Paper className={classes.paper} elevation={3}>
              <Grid container spacing={2} className={classes.form}>
                <Grid item xs={12} className={classes.grid}>
                  <TextField
                    inputProps={{
                      readOnly : disabled,
                      maxlength: MAX_CHARACTER_LIMIT.TEXT_FIELD
                    }}
                    disabled={disabled}
                    id="roleName"
                    label={`${t('name')}*`}
                    name="roleName"
                    variant="outlined"
                    fullWidth
                    multiline
                    value={formik.values.roleName}
                    onChange={e => handleChange(setRoleName(e.target.value), formik.handleChange(e))}
                    error={formik.touched.roleName && Boolean(formik.errors.roleName)}
                    helperText={t(formik.touched.roleName) && t(formik.errors.roleName)}
                  />
                </Grid>
                <Grid item xs={12} lg={disabled ? 12 : 6}>
                  <TextField
                    inputProps={{
                      readOnly : disabled,
                      maxlength: MAX_CHARACTER_LIMIT.TEXT_FIELD
                    }}
                    disabled={disabled}
                    id="roleDescription"
                    label={t('description')}
                    name="roleDescription"
                    variant="outlined"
                    placeholder={t('noDescription')}
                    fullWidth
                    multiline
                    rows={6}
                    value={formik.values.roleDescription}
                    onChange={e => handleChange(setRoleDescription(e.target.value), formik.handleChange(e))}
                    error={formik.touched.roleDescription && Boolean(formik.errors.roleDescription)}
                    helperText={t(formik.touched.roleDescription) && t(formik.errors.roleDescription)}
                  />
                  {
                    !isViewPage &&
                      <span
                        className={formik.errors.roleDescription && formik.touched.roleDescription ? classes.characterLimitError : classes.characterLimit}>
                        { 
                          t('controller-page.characterLimit', { 
                            currentCharacterCount: formik.values.roleDescription.length, maxCharacterCount: MAX_CHARACTER_LIMIT.TEXT_FIELD 
                          })
                        }
                      </span>
                  }
                  {
                    handlePermissions(LOCATIONS_MODULE, GET) &&
                      <SelectItems
                        disabled={disabled}
                        helperText={t(formik.touched.location) && t(formik.errors.location)}
                        isValid={formik.touched.location && Boolean(formik.errors.location)}
                        id="roleLocation"
                        name="Locations"
                        onChange={handleSelectedLocation}
                        selectedItems={selectedLocation}
                        showToaster={showToaster}
                        single={true}
                        required={true}
                        handlePermissions={handlePermissions}
                      />
                  }
                </Grid>
                <Grid hidden={disabled} item xs={12} lg={6} className={clsx(!handlePermissions(PROFILES_MODULE, PUT) ? 'hidden' : classes.dropdown)}>
                  <fieldset className={classes.containerProfiles}>
                    <legend className={classes.fieldsetLegend}>{t('profiles')}</legend>
                    <ModalCreateProfile showToaster={showToaster} setSelectedProfiles={setSelectedProfiles} showLoading={showLoading} setHasSelected={setHasSelected} handlePermissions={handlePermissions}/>
                    <hr className={classes.hrDivider} />
                    <SelectItems 
                      id="roleProfiles"
                      name="Profiles"
                      onChange={handleSelectedChange}
                      selectedProfiles={selectedProfiles}
                      showToaster={showToaster}
                      hasSelected={hasSelected} 
                      setHasSelected={setHasSelected}
                      handlePermissions={handlePermissions}
                    />
                  </fieldset>
                </Grid>
              </Grid>
              <Grid className={clsx(disabled ? 'hidden' : classes.action)}>
                <Grid item xs={12}>
                  <Button
                    className={classes.button}
                    id="roleCancelButton"
                    onClick={handleCancel}
                    variant="outlined"
                    color="primary"
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    className={classes.button}
                    id="roleSubmitButton"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {getButtonLabel()}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Form>
        )
      }
    </Formik>
  )
}

const Role = (props) => {
  const { t } = useTranslation();
  const { showToaster, match, showLoading, handlePermissions } = props;
  const path    = match.path;
  const { id }  = match.params;
  const classes = useStyles();
  const history = useHistory();

  const { state: authContext }  = useContext(AuthContext);
  const { administrator }       = authContext;
  
  const { state }  = useContext(LocationContext);

  const initialLocationId = GetInitialLocationId();
  const initialLocationObject = GetInitialLocationObject();

  const [isLoading, setIsLoading]                 = useState(false);
  const [users, setUsers]                         = useState([]);
  const [prevValues, setPrevValues]               = useState([]);
  const [prevProfile, setPrevProfile]             = useState([]);
  const [profiles, setProfiles]                   = useState([]);
  const [roleDescription, setRoleDescription]     = useState('');
  const [roleName, setRoleName]                   = useState('');
  const [hasSelected, setHasSelected]             = useState(false);
  const [showModal, setShowModal]                 = useState(false);
  const [withChanges, setWithChanges]             = useState(false);
  const [toRedirect, setToRedirect]               = useState('');
  const [location, setLocation]                   = useState('');
  const [selectedLocation, setSelectedLocation]   = useState(initialLocationId);
  const [locationObject, setLocationObject]       = useState(initialLocationObject);

  const language = cookies.get('i18next') || LANGUAGE_EN;

  const hasViewUsers = handlePermissions(USERS_MODULE, GET);

  const initialValues = useMemo(() => {
    return {
      roleDescription : roleDescription,
      roleName        : roleName,
      location       : selectedLocation || selectedLocation === undefined ? selectedLocation : location
    }
  }, [roleDescription, roleName, selectedLocation, location]);

  const handleCloseModal = () => {
    setShowModal(false);
  }

  const handleModalSubmit = () => {
    setWithChanges(false);
    history.push(toRedirect);
  }

  const handleModalCancel = () => {
    handleChanges();
    setShowModal(false);
  }

  const handleCancel = () => {
    history.push('/roles');
  }

  const getProfiles = (profiles) => {
    if (profiles) {
      const formattedProfiles = profiles.map(profile => {
        return {
          id          : profile.profileId,
          name        : profile.name,
          description : `${moment(profile.validFrom).format(DATE_FORMAT)} - ${moment(profile.validUntil).format(DATE_FORMAT)}`
        }
      });
  
      setProfiles(formattedProfiles);
      setPrevProfile(formattedProfiles);
    }
  }

  const getUsers = useCallback(async () => {
    try {
      const usersRole = await getUsersByRoleId(id);
      const users = getUserByUserRole(usersRole);

      setUsers(users);
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }
  }, [id, showToaster, t]);

  const getRole = useCallback(async () => {
    setIsLoading(true);
    
    try {
      const response = await request({
          url    : api.ROLES_BY_ID,
          method : GET,
          params : {
            roleId     : id,
            projection : PROJECTION.ROLES
          }
      });

      if (hasViewUsers) {
        await getUsers();
      }

      getProfiles(response.data.profiles);
      getLocation(response.data, setLocation, setLocationObject);
  
      setRoleName(response.data.name);
      setRoleDescription(response.data.description);
      setPrevValues({
        roleDescription : response.data.description,
        roleName        : response.data.name,
      });
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setIsLoading(false);
    }
  }, [hasViewUsers, getUsers, id, showToaster, t]);

  const getToasterMessage = () => {
    return id ? t('updated') : t('created');
  }

  const handleSelectedChange = (value) => {
    setProfiles(value);
  }

  // Return true when any of the following has changes
  // name, profiles
  const hasRoleSyncChange = () => {
    const previousProfileIds    = prevProfile.map(profile => profile.id);
    const selectedProfileIds    = profiles.map(profile => profile.id);
    const withoutProfileChanges = isArrayEqual(selectedProfileIds, previousProfileIds);

    const hasRoleSyncChange = prevValues.roleName === initialValues.roleName && withoutProfileChanges;

    return hasRoleSyncChange;
  };

  const handleSubmit = async (values, formik) => {
    const { setErrors, setSubmitting } = formik;

    if (!withChanges) {
      setSubmitting(false);
      setWithChanges(false);
      history.push('/roles');
      return;
    }

    const previousProfileIds    = prevProfile.map(profile => profile.id);
    const selectedProfileIds    = profiles.map(profile => profile.id);
    const withoutProfileChanges = isArrayEqual(selectedProfileIds, previousProfileIds);

    try {
      const response = await request({
        url     : id ? `${api.ROLES}/${id}` : api.ROLES,
        method  : id ? PATCH : POST,
        headers : {
          'withoutProfileChanges' : withoutProfileChanges,
          'withoutRoleChanges'    : hasRoleSyncChange(),
        },
        data    : {
          name        : values.roleName,
          description : values.roleDescription
        }
      });
      const link  = response.data._links.self.href;
      
      await assignLocationToRole(link);
      await assignProfileToRole(link);

      showToaster(t('success'), `${values.roleName} ${t('hasBeen')} ${getToasterMessage()}`, 'success');
      setWithChanges(false);
      setSubmitting(false);
      history.push('/roles');
    } catch (error) {
      if (error?.response?.status === 409) {
        setErrors({
          roleName : t(`nameAlreadyExists`)
        });
      } else {
        showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
      }
    }
  }

  const assignProfileToRole = async (link) => {
    const previousProfileIds   = prevProfile.map(profile => profile.id);
    const profileIds           = profiles.map(profile => profile.id);
    const selectedProfileIds   = profileIds.filter(profileId => !previousProfileIds.includes(profileId));
    const profileUrls          = profileIds.map(profileId => `${api.PROFILES}/${profileId}`).join(`\r\n`);
    const unassignedProfileIds = previousProfileIds.filter(previousProfileId => !profileIds.includes(previousProfileId));

    if (selectedProfileIds.length === 0 && unassignedProfileIds.length === 0) {
      return;
    }

    await request({
      url     : `${link}/profiles`,
      method  : PUT,
      data    : profileUrls,
      headers : {
        'Content-Type'          : 'text/uri-list',
        'unassignedProfileIds'  : unassignedProfileIds,
        'assignedProfileIds'    : selectedProfileIds,
      }
    });  
  }

  const checkRoleAndProfileChanges = useCallback(() => {
    const previousProfileIds    = prevProfile.map(profile => profile.id);
    const selectedProfileIds    = profiles.map(profile => profile.id);
    const withoutProfileChanges = isArrayEqual(selectedProfileIds, previousProfileIds);

    const hasChanges = prevValues.roleDescription === initialValues.roleDescription
    && prevValues.roleName === initialValues.roleName
    && withoutProfileChanges;

    return hasChanges;
  }, [initialValues, prevProfile, prevValues, profiles]);

  const handleChanges = useCallback(() => {
    if (checkRoleAndProfileChanges() 
    && !hasLocationChange(location, locationObject[0]?.locationId, administrator.locations.length, state)) {
      setWithChanges(false);
    } else {
      setWithChanges(true);
    }
  }, [location, checkRoleAndProfileChanges, administrator, locationObject, state]);

  const handleSelectedLocation = (value) => {
    setSelectedLocation(value[0]?.locationId);
    setLocationObject(value);
  }

  const assignLocationToRole = async (link) => {
    if (location === locationObject[0]?.locationId) {
      return;
    }

    if (location && selectedLocation) {
      await unassignLocation(link);
    } 

    await assignLocation(link, selectedLocation);
  }

  useEffect(() => {
    setPrevValues({
      roleDescription : '',
      roleName        : ''
    });

    if (id) {
      getRole();
    }
  }, [id, getRole]);

  useEffect(() => {
    handleChanges();
  }, [handleChanges]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={language === LANGUAGE_DE ? deLocale : enLocale}>
      <Container maxWidth="xl" className={classes.container}>
        <UnsavedModal
          open={showModal}
          onClose={handleCloseModal}
          handleModalSubmit={handleModalSubmit}
          handleModalCancel={handleModalCancel}
        />
        <Prompt
          when={withChanges}
          message={(location, action) => {
            if (action === 'PUSH') {
              setShowModal(true);
            }
            setWithChanges(false);
            setToRedirect(location.pathname);
            return location.pathname === '/' || location.pathname.startsWith('/roles/update') || location.pathname.startsWith('/roles/create')
          }}
        />
        <Title title={t('role')} subtitle={`${roleName}`}/>
        <Box className={classes.details}>
          <Grid container>
            <Grid item xs={6}>
                <Typography className={'bold'} color="secondary">{t('details')}</Typography>
            </Grid>
            <Grid item xs={6} align="right" className={clsx(path.includes('view') ? '' : 'hidden')}>
              <Tooltip title={t('update')} className={path.includes('view') && handlePermissions(ROLES_MODULE, PATCH) ? '' : 'hidden'}>
                <IconButton id="roleEditButton" aria-label="Update" onClick={() => history.push(`../update/${id}`)}>
                  <Edit className={classes.editIcon} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          {
            isLoading ?
              <RoleSkeleton disabled={path.includes('view')} handlePermissions={handlePermissions}/>
            :
              <Content
                id={id}
                disabled={path.includes('view')}
                initialValues={initialValues}
                handleCancel={handleCancel}
                handleSubmit={handleSubmit}
                handleSelectedChange={handleSelectedChange}
                showToaster={showToaster}
                path={path}
                selectedProfiles={profiles}
                setRoleDescription={setRoleDescription}
                setRoleName={setRoleName}
                setSelectedProfiles={setProfiles}
                showLoading={showLoading}
                setHasSelected={setHasSelected}
                hasSelected={hasSelected}
                selectedLocation={locationObject}
                handleSelectedLocation={handleSelectedLocation}
                roles={administrator.roles}
                handlePermissions={handlePermissions}
              />
          }
          <Grid container spacing={2} className={clsx(path.includes('view') ? classes.paper : 'hidden')}>
            <Grid item xs={12} md={6} lg={4} className={clsx(handlePermissions(PROFILES_MODULE, PUT) ? '' : 'hidden')}>
              <Chips
                id="roleProfiles"
                color={CHIP_COLOR.LIGHT}
                icon={<PersonIcon/>}
                isLoading={isLoading}
                header={t('profiles')}
                data={profiles}
                type={'Profiles'}
              />
            </Grid>
            {
              hasViewUsers &&
              <Grid item xs={12} md={6} lg={4}>
                <Chips
                  id="roleUsers"
                  color={CHIP_COLOR.LIGHT}
                  icon={<RecentActorsIcon/>}
                  isLoading={isLoading}
                  header={t('users')}
                  data={users}
                  type={'Users'}
                />
              </Grid>
            }
          </Grid>
        </Box>
      </Container>
    </MuiPickersUtilsProvider>
  );
}

export default Role;